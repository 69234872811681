import { dateOnly, E2gSelectOption, IMultiSelectInputConfig } from '@equityeng/e2g-ng-ui';
import { GridApi, GridReadyEvent, ICellRendererParams } from 'ag-grid-community';
import { BaseSlideoutOption } from 'src/app/asset-module/models/base-slideout-option';
import { FindingStatus } from 'src/app/asset-module/models/enums/finding-status';
import { InspectionMethodTypes } from 'src/app/asset-module/models/enums/inspection-method-types';
import { FindingDto } from 'src/app/asset-module/models/finding-dto';
import { AttachmentTableType } from 'src/app/attachments-module/models/attachment-table-type';
import { GridCellLinkParams } from 'src/app/grid-module/cell-renders/grid-cell-link/grid-cell-link-params';
import { GridCellLinkComponent } from 'src/app/grid-module/cell-renders/grid-cell-link/grid-cell-link.component';
import { GridCellTooltipComponent } from 'src/app/grid-module/cell-renders/grid-cell-tooltip/grid-cell-tooltip.component';
import { buildEnumColDef } from 'src/app/grid-module/column-builders/build-enum';
import { GridBaseOptions } from 'src/app/grid-module/e2g-ag-grid/e2g-ag-grid.component';
import { convertNodesToSlideoutOptions } from 'src/app/grid-module/grid-api-extenstion';
import { NEW_ITEM_KEY } from 'src/app/shared-module/models/new-item-key';

import { ActivityDetailsDomainModel } from '../models/domain/activity-details-dm';
import { ActivityDetailsTab } from './activity-details.component';

export interface ActivityDetailsViewModelConfig {
  readonly initialTab: ActivityDetailsTab;
  readonly hideFindings: boolean;
  readonly openFindingsSlideout: (
    initialActivityId: string,
    gridContext: string,
    optionsData: Record<string, BaseSlideoutOption>
  ) => void;
  readonly setTabId: (tabId: ActivityDetailsTab) => void;
}

export class ActivityDetailsViewModel {
  public previewMode: boolean = false;
  private dateIsTouched: boolean = false;

  public readonly findingsGridOptions: GridBaseOptions = {
    exportFileName: 'Activity Findings',
    context: 'findings-activity',
    suppressCellFocus: true,
    rowSelection: 'single',
    defaultColDef: {
      resizable: true,
      sortable: true,
      tooltipComponent: GridCellTooltipComponent,
      filter: 'agSetColumnFilter'
    },
    columnDefs: [
      {
        headerName: 'Name',
        headerTooltip: 'Name',
        field: 'name',
        tooltipField: 'name',
        comparator: (a, b): number => a.localeCompare(b, 'en', { numeric: true, sensitivity: 'base' }),
        minWidth: 250,
        width: 250,
        cellRenderer: GridCellLinkComponent,
        cellRendererParams: {
          isLink: (): boolean => true,
          linkCommand: (params: ICellRendererParams): void => this.editFinding(params)
        } as GridCellLinkParams
      },
      {
        headerName: 'Description',
        headerTooltip: 'Description',
        field: 'description',
        tooltipField: 'description',
        minWidth: 250,
        width: 250
      },
      {
        headerName: 'Component',
        headerTooltip: 'Component',
        field: 'componentName',
        tooltipField: 'componentName',
        minWidth: 200,
        width: 200
      },
      {
        headerName: 'Maintenance Reference',
        headerTooltip: 'Maintenance Reference',
        field: 'maintenanceReference',
        tooltipField: 'maintenanceReference',
        minWidth: 250,
        width: 250
      },
      {
        ...buildEnumColDef('Status', 'status', FindingStatus),
        width: 125
      }
    ]
  };
  public readonly primaryInspectionMethodOptions: Array<E2gSelectOption> = [
    {
      value: InspectionMethodTypes.UT,
      label: InspectionMethodTypes[InspectionMethodTypes.UT]
    },
    {
      value: InspectionMethodTypes.RT,
      label: InspectionMethodTypes[InspectionMethodTypes.RT]
    }
  ];

  public constructor(private domainModel: ActivityDetailsDomainModel, private config: ActivityDetailsViewModelConfig) {}

  public readonly readOnly = !this.domainModel.canEdit || this.config.hideFindings;
  public readonly findingsData = this.domainModel.findingsData;
  public readonly dataHandler = this.domainModel.dataHandler;
  public readonly readingsGridDataHandler = this.domainModel.readingsGridDataHandler;

  public readonly showComponentSelection: boolean = !this.domainModel.isOnPrd;
  public readonly showInspectionGradesInput: boolean =
    this.domainModel.inspectionGrades !== undefined && this.domainModel.inspectionGrades.length > 0;
  public readonly showFindingsTab: boolean = this.domainModel.editing && !this.config.hideFindings;
  public readonly showAddFindingButton: boolean = !this.readOnly;
  public readonly tableType = AttachmentTableType.ActivityItem;
  public readonly attachmentKey = this.domainModel.editing ? this.domainModel.id! : '';
  public readonly showAttachments: boolean = this.domainModel.editing;

  public readonly showSurveyFormInput: boolean = this.domainModel.isSurvey;
  public readonly showReadingsTab: boolean = this.domainModel.isSurvey && this.domainModel.editing;

  private _activeTabId: ActivityDetailsTab = this.config.initialTab;
  public get activeTabId(): ActivityDetailsTab {
    return this._activeTabId;
  }
  public set activeTabId(value: ActivityDetailsTab) {
    this._activeTabId = value;
    this.config.setTabId(value);
  }

  public readonly inspectorsInputConfig: IMultiSelectInputConfig<string> = {
    getValue: () => this.domainModel.inspectors,
    setValue: (value) => {
      this.domainModel.inspectors = value;
    },
    options: this.domainModel.inspectorOptions,
    heading: 'Inspector',
    readonly: this.readOnly
  };

  public componentSelectOptions = this.domainModel.componentSelectOptions;

  public previewModeChange(value: boolean): void {
    this.previewMode = value;
  }

  public onDateBlur(): void {
    this.dateIsTouched = true;
  }

  public editFinding(params: ICellRendererParams): void {
    this.openFindingsSlideout(params.data.id);
  }

  public addFinding(): void {
    this.openFindingsSlideout(NEW_ITEM_KEY);
  }

  private gridApi!: GridApi;

  public onGridReady(event: GridReadyEvent): void {
    this.gridApi = event.api;
  }

  private openFindingsSlideout(id: string): void {
    const optionsData = convertNodesToSlideoutOptions<FindingDto, BaseSlideoutOption>(this.gridApi, (data) => {
      return {
        name: data.name!,
        assetId: data.assetId!
      };
    });
    this.config.openFindingsSlideout(id, this.findingsGridOptions.context, optionsData);
  }

  public get date(): dateOnly | undefined {
    return this.domainModel.date;
  }
  public set date(value: dateOnly | undefined) {
    this.domainModel.date = value;
  }

  public get campaign(): string {
    return this.domainModel.campaign;
  }
  public set campaign(value: string) {
    this.domainModel.campaign = value;
  }

  public get description(): string {
    return this.domainModel.description;
  }
  public set description(value: string) {
    this.domainModel.description = value;
  }

  public get componentId(): string {
    return this.domainModel.componentId;
  }
  public set componentId(value: string) {
    this.domainModel.componentId = value;
  }

  public get comments(): string {
    return this.domainModel.comments;
  }
  public set comments(value: string) {
    this.domainModel.comments = value;
  }

  //TODO Split out survey data
  public get primaryInspectionMethod(): InspectionMethodTypes | undefined {
    return this.domainModel.primaryInspectionMethod;
  }
  public set primaryInspectionMethod(value: InspectionMethodTypes | undefined) {
    this.domainModel.primaryInspectionMethod = value;
  }

  public get isBaseline(): boolean {
    return this.domainModel.isBaseline;
  }
  public set isBaseline(value: boolean) {
    this.domainModel.isBaseline = value;
  }

  public get instrumentId(): string {
    return this.domainModel.instrumentId;
  }
  public set instrumentId(value: string) {
    this.domainModel.instrumentId = value;
  }

  public get probeId(): string {
    return this.domainModel.probeId;
  }
  public set probeId(value: string) {
    this.domainModel.probeId = value;
  }

  public get dateErrors(): Array<string> {
    return this.dateIsTouched ? this.domainModel.dateErrors : [];
  }
}
