import { CDK_DRAG_PARENT, CdkDragHandle } from '@angular/cdk/drag-drop';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Optional,
  Output,
  ViewChild
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { faDownload, faFile, faGripDotsVertical, faTrashXmark } from '@fortawesome/pro-regular-svg-icons';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OnDestroyBaseComponent } from 'src/app/on-destroy-base-component/on-destroy-base-component';

import { AttachmentDto } from '../models/attachment-dto';
import { AttachmentFileType } from '../models/attachment-file-type';

@Component({
  selector: 'app-attachment-display',
  templateUrl: './attachment-display.component.html',
  styleUrls: ['./attachment-display.component.css']
})
export class AttachmentDisplayComponent extends OnDestroyBaseComponent implements OnInit, AfterViewInit {
  @Input() public data!: AttachmentDto;
  @Input() public previewDataReader!: (data: AttachmentDto) => Observable<Blob>;
  @Input() public readonly!: boolean;
  @Output() public onDelete = new EventEmitter<AttachmentDto>();
  @Output() public onPreview = new EventEmitter<AttachmentDto>();
  @Output() public onDownload = new EventEmitter<AttachmentDto>();
  @ViewChild(CdkDragHandle, { static: true }) public handle: CdkDragHandle | undefined;

  public displayImage?: SafeResourceUrl;
  public fileName: string = '';

  public faTrashXmark = faTrashXmark;
  public faFile = faFile;
  public faGripDotsVertical = faGripDotsVertical;
  public faDownload = faDownload;

  public constructor(private sanitizer: DomSanitizer, @Optional() @Inject(CDK_DRAG_PARENT) public cdk: any) {
    super();
  }

  public ngOnInit(): void {
    this.fileName = this.data.fileName;

    if (this.data.canPreview) {
      this.previewDataReader(this.data)
        .pipe(takeUntil(this.destroy))
        .subscribe((x: Blob) => {
          this.displayImage = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(x));
        });
    }
  }

  public ngAfterViewInit(): void {
    this.cdk._handles.length = 1;
    this.cdk._handles._results = [this.handle];
    this.cdk._handles.changes.next();
  }

  public delete(): void {
    this.onDelete.emit(this.data);
  }

  public preview(): void {
    this.onPreview.emit(this.data);
  }

  public download(): void {
    this.onDownload.emit(this.data);
  }

  public downloadOrPreview(): void {
    if (
      this.data.fileType === AttachmentFileType.IMAGE ||
      this.data.fileType === AttachmentFileType.PDF ||
      this.data.fileType === AttachmentFileType.TEXT
    ) {
      this.onPreview.emit(this.data);
    } else {
      this.onDownload.emit(this.data);
    }
  }
}
