import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppSettingsService } from 'src/app/app-settings/app-settings.service';
import { getToastDisplayProperties, ToastType } from 'src/app/shared/toast-module/models/toast-type';

import { ToastService } from '../../shared/toast-module/toast.service';
import { ActionTypes } from '../models/action-types';
import { TopToastComponent } from '../top-toast/top-toast.component';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private readonly persistentNotifsFeatureToggle: boolean = false;

  public constructor(
    private snackBar: MatSnackBar,
    private toastService: ToastService,
    private appSettingsService: AppSettingsService
  ) {
    this.persistentNotifsFeatureToggle = this.appSettingsService.settings.persistentNotifsFeatureToggle;
  }

  public showToast(type: ToastType, message: string): void {
    this.toastService.show({ message: message, displayProperties: getToastDisplayProperties(type) });
  }

  public showJobStarted(message: string): void {
    if (this.persistentNotifsFeatureToggle) {
      this.showToast(ToastType.JobStarted, message);
    } else {
      this.showInfo(message);
    }
  }

  public showActionFailed(message: string): void {
    if (this.persistentNotifsFeatureToggle) {
      this.showToast(ToastType.ActionFailed, message);
    } else {
      this.showError(message);
    }
  }

  public showInfo(message: string): void {
    if (this.snackBar._openedSnackBarRef === null) {
      this.snackBar.openFromComponent(TopToastComponent, {
        data: message,
        panelClass: ['snackbar-gray']
      });
    }
  }

  public showError(errorMessage: string): void {
    if (this.snackBar._openedSnackBarRef === null) {
      this.snackBar.openFromComponent(TopToastComponent, {
        data: errorMessage,
        panelClass: ['snackbar-red']
      });
    }
  }

  public showSaveResult(success: boolean, action: string = 'Save'): void {
    this.snackBar.openFromComponent(TopToastComponent, {
      data: `${action} ${success ? 'Successful' : 'Failed'}`,
      panelClass: success ? ['snackbar-green'] : ['snackbar-red']
    });
  }

  //TODO start using this version to match Mark's desired messages
  public showActionResult(success: boolean, actionType: ActionTypes, name: string): void {
    this.snackBar.openFromComponent(TopToastComponent, {
      data: `${this.getMessageStart(success, actionType)} ${name}${success ? '' : ' Failed'}`,
      panelClass: success ? ['snackbar-green'] : ['snackbar-red']
    });
  }

  private getMessageStart(success: boolean, actionType: ActionTypes): string {
    switch (actionType) {
      case ActionTypes.Add:
        return success ? 'Added' : 'Add';
      case ActionTypes.Update:
        return success ? 'Saved' : 'Save';
      case ActionTypes.Rename:
        return success ? 'Renamed' : 'Rename';
      case ActionTypes.Copy:
        return success ? 'Copied' : 'Copy';
      case ActionTypes.Delete:
        return success ? 'Deleted' : 'Delete';
      case ActionTypes.Move:
        return success ? 'Moved' : 'Move';
      case ActionTypes.Upload:
        return success ? 'Uploaded' : 'Upload';
    }
  }
}
