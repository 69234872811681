<div *ngIf="materialDomainModel.isInitialized; else loading">
  <div class="moc-container">
    <div id="moc-col-1-1" class="col-sm-8 mb-2">
      <div class="d-flex justify-content-between">
        <span>Material</span>
        <span>
          <a (click)="openCustomMaterialModal()" class="modal-link">Edit Properties</a
          ><span *ngIf="!readOnly"
            >&nbsp; | &nbsp;<a (click)="openMaterialPickerModal()" class="modal-link">Advanced Filter</a></span
          >
        </span>
      </div>
      <e2g-material-picker-autocomplete
        [initialSelection]="materialDomainModel.getMaterial()"
        (materialSelected)="materialDomainModel.setMaterial($event)"
        [readonly]="readOnly"
      ></e2g-material-picker-autocomplete>
    </div>
  </div>
</div>
<ng-template #loading>
  <div class="loading"></div>
</ng-template>
