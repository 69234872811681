<div class="d-flex flex-col col-lg-3 pl-4 mt-2">
  <div class="mt-2 mb-2 row">
    <div class="col-md-6 col-sm-12">
      <e2g-date-input
        heading="Date"
        id="inp-date"
        [(ngModel)]="vm.date"
        placeholder="mm/dd/yyyy"
        [required]="true"
        [errors]="vm.dateErrors"
        [readonly]="vm.readOnly"
        (blur)="vm.onDateBlur()"
      ></e2g-date-input>
    </div>
    <div class="col-md-6 col-sm-12">
      <e2g-text-input
        heading="Campaign"
        id="inp-campaign"
        [(ngModel)]="vm.campaign"
        [readonly]="vm.readOnly"
      ></e2g-text-input>
    </div>
  </div>
  <div class="mt-2 mb-2 row">
    <div class="col-12">
      <e2g-textarea-input
        heading="Description"
        [rows]="2"
        id="inp-description"
        [(ngModel)]="vm.description"
        [readonly]="vm.readOnly"
        [maxlength]="255"
      ></e2g-textarea-input>
    </div>
  </div>
  <div class="mt-2 mb-2 row" *ngIf="vm.showComponentSelection">
    <div class="col-12">
      <e2g-ng-select-input
        id="select-finding-component"
        heading="Component"
        placeholder="Select Component"
        [options]="vm.componentSelectOptions"
        [(ngModel)]="vm.componentId"
        [readonly]="vm.readOnly"
        [clearable]="true"
      ></e2g-ng-select-input>
    </div>
  </div>
  <div class="mt-2 mb-2 row">
    <div class="col-md-12">
      <e2g-multi-select-input-vm id="select-inspector" [config]="vm.inspectorsInputConfig"> </e2g-multi-select-input-vm>
    </div>
  </div>
  <div *ngIf="vm.showSurveyFormInput">
    <div class="mt-2 mb-2 row">
      <div class="col-md-6">
        <e2g-ng-select-input
          heading="Inspection Method"
          id="select-primaryInspMeth"
          placeholder="Select Inspection Method"
          [(ngModel)]="vm.primaryInspectionMethod"
          [options]="vm.primaryInspectionMethodOptions"
          [required]="true"
          [readonly]="vm.readOnly"
        >
        </e2g-ng-select-input>
      </div>
      <div class="col-md-6">
        <e2g-check-input heading="Baseline" id="inp-baseline" [(ngModel)]="vm.isBaseline" [readonly]="vm.readOnly">
        </e2g-check-input>
      </div>
    </div>
    <div class="mt-2 mb-2 row">
      <div class="col-md-6 col-sm-12">
        <e2g-text-input
          heading="Instrument ID"
          id="inp-instrumentId"
          [(ngModel)]="vm.instrumentId"
          [readonly]="vm.readOnly"
        ></e2g-text-input>
      </div>
      <div class="col-md-6 col-sm-12">
        <e2g-text-input
          heading="Probe ID"
          id="inp-probeId"
          [(ngModel)]="vm.probeId"
          [readonly]="vm.readOnly"
        ></e2g-text-input>
      </div>
    </div>
  </div>
</div>

<div id="div-tabs" class="d-flex flex-col w-100 col-lg-9 mt-2">
  <ul ngbNav #nav="ngbNav" [(activeId)]="vm.activeTabId" class="sub-nav-tabs">
    <li [ngbNavItem]="ActivityDetailsTab.Readings" *ngIf="vm.showReadingsTab">
      <a ngbNavLink>Readings</a>
      <ng-template ngbNavContent>
        <app-activity-details-readings
          [readingsGridDataHandler]="vm.readingsGridDataHandler!"
          [readonly]="vm.readOnly"
        ></app-activity-details-readings>
      </ng-template>
    </li>
    <li [ngbNavItem]="ActivityDetailsTab.Details">
      <a ngbNavLink>Details</a>
      <ng-template ngbNavContent>
        <div class="h-100 sub-tab-styles">
          <div *ngIf="!vm.previewMode">
            <div class="mt-2 mb-2 row mb-1" *ngIf="vm.showInspectionGradesInput">
              <div class="col-xl-12">
                <app-grade-activity [dataHandler]="vm.dataHandler" [readonly]="vm.readOnly"> </app-grade-activity>
              </div>
            </div>
            <div class="mt-2 mb-2 row">
              <div class="col-12">
                <e2g-textarea-input
                  heading="Comments"
                  [rows]="5"
                  id="inp-comments"
                  [(ngModel)]="vm.comments"
                  [readonly]="vm.readOnly"
                ></e2g-textarea-input>
              </div>
            </div>
          </div>
          <div [class]="vm.previewMode ? 'h-100' : 'mt-3'" *ngIf="vm.showAttachments">
            <app-attachments
              [tableType]="vm.tableType"
              [attachmentKey]="vm.attachmentKey"
              [readonly]="vm.readOnly"
              (previewMode)="vm.previewModeChange($event)"
            ></app-attachments>
          </div>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="ActivityDetailsTab.Findings" *ngIf="vm.showFindingsTab">
      <a ngbNavLink>Findings</a>
      <ng-template ngbNavContent>
        <div class="d-flex flex-column h-100 sub-tab-styles">
          <div class="d-flex flex-row mt-2">
            <div class="d-flex flex-row p-0 mb-2">
              <button
                *ngIf="vm.showAddFindingButton"
                id="btnAddFinding"
                class="btn e2g-btn-outline-primary"
                (click)="vm.addFinding()"
              >
                Add Finding
              </button>
            </div>
          </div>
          <div class="row findings-content mr-0 h-100 flex-111">
            <div class="col-xl-12 pr-0 flex-111">
              <e2g-ag-grid
                [options]="vm.findingsGridOptions"
                [data]="vm.findingsData"
                (gridReady)="vm.onGridReady($event)"
              ></e2g-ag-grid>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav" id="tab-content" class="pt-2"></div>
</div>
