import { TemplateRef } from '@angular/core';

export interface SlideOutFooterItem {
  position: SlideOutFooterSection;
  template: TemplateRef<any>;
}

export enum SlideOutFooterSection {
  Left = 1,
  Center = 2,
  Right = 3
}
