import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

interface ButtonOption {
  value: number;
  label: string;
}

@Component({
  selector: 'app-low-to-high-input',
  templateUrl: './low-to-high-input.component.html',
  styleUrls: ['./low-to-high-input.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LowToHighInputComponent),
      multi: true
    }
  ]
})
export class LowToHighInputComponent implements OnInit, ControlValueAccessor {
  public value?: number;
  public isDisabled?: boolean = false;
  @Input() public hasVeryLow?: boolean = false;

  public ngOnInit(): void {
    if (this.value === undefined) {
      this.value = 1;

      if (this.onChange !== undefined) {
        this.onChange(this.value);
      }
    }
  }

  public buttonOptions: Array<ButtonOption> = [
    {
      value: 1,
      label: 'None'
    },
    {
      value: 2,
      label: 'Very Low'
    },
    {
      value: 3,
      label: 'Low'
    },
    {
      value: 4,
      label: 'Medium'
    },
    {
      value: 5,
      label: 'High'
    }
  ];

  public onChange: ((value: number) => void) | undefined;
  public onTouched: (() => void) | undefined;

  public registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public writeValue(value: number): void {
    this.value = value || 1;
  }

  public onClick(value: number): void {
    this.value = value;

    if (this.onChange !== undefined) {
      this.onChange(value);
    }

    if (this.onTouched !== undefined) {
      this.onTouched();
    }
  }

  public setDisabledState(disabled: boolean): void {
    this.isDisabled = disabled;
  }

  public getButtonOptions(): Array<ButtonOption> {
    return this.buttonOptions.filter((x) => x.label !== 'Very Low' || this.hasVeryLow);
  }
}
