import { ColDef, GridOptions, ValueGetterParams } from 'ag-grid-community';

export function removeColumnsFromGrid(gridOptions: GridOptions, fieldNames: string[]): void {
  fieldNames.forEach((name) => {
    const index = gridOptions.columnDefs?.findIndex((x) => (x as ColDef).field === name);
    gridOptions.columnDefs?.splice(index!, 1);
  });
}

export function stringListGetter(params: ValueGetterParams): string {
  return (params.data[params.colDef.field || ''] as string[]).join(', ');
}
