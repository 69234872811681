import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { E2gSelectOption } from '@equityeng/e2g-ng-ui';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AppSettingsService } from '../app-settings/app-settings.service';
import { HubValueRequestDto } from './models/hub-value-request-dto';

@Injectable({
  providedIn: 'root'
})
export class ValueDataService {
  private serverUri: string;

  public constructor(private http: HttpClient, private settingsService: AppSettingsService) {
    this.serverUri = this.settingsService.settings.apiUri as string;
  }

  public getValidValues(dto: HubValueRequestDto): Observable<Array<E2gSelectOption>> {
    const uri = `${this.serverUri}/values/valid`;
    return this.http.post<Array<E2gSelectOption>>(uri, JSON.stringify(dto)).pipe(catchError(() => of([])));
  }

  public getDefaultValue(dto: HubValueRequestDto): Observable<string | undefined> {
    const uri = `${this.serverUri}/values/default`;

    return this.http.post<string>(uri, JSON.stringify(dto)).pipe(
      map((x) => {
        if (x.length === 0) {
          return undefined;
        } else if (!isNaN(Number(x))) {
          return Number(x).toFixed(3);
        }
        return x;
      }),
      catchError(() => of(''))
    );
  }
}
