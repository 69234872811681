import { Component, ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MaterialSelection } from '@equityeng/material-picker';

import { OnDestroyBaseComponent } from '../../on-destroy-base-component/on-destroy-base-component';
import { CustomMaterialModalComponent } from '../custom-material-modal/custom-material-modal.component';
import { MaterialBusinessRulesService } from '../moc-business-rules';
import { MocModalComponent } from '../moc-modal/moc-modal.component';

@Component({
  selector: 'app-moc',
  templateUrl: './moc.component.html',
  styleUrls: ['./moc.component.css']
})
export class MocComponent extends OnDestroyBaseComponent {
  public readOnly: boolean = false;

  public constructor(
    public materialDomainModel: MaterialBusinessRulesService,
    private dialog: MatDialog,
    private viewContainerRef: ViewContainerRef
  ) {
    super();
    this.readOnly = materialDomainModel.readOnly;
  }

  public openMaterialPickerModal(): void {
    this.dialog
      .open(MocModalComponent, {
        data: this.materialDomainModel.getMaterial(),
        maxWidth: '95vw',
        height: '600px'
      })
      .afterClosed()
      .subscribe((selectedMaterial: MaterialSelection) => {
        if (selectedMaterial) {
          this.materialDomainModel.setMaterial(selectedMaterial);
        }
      });
  }

  public openCustomMaterialModal(): void {
    this.dialog
      .open(CustomMaterialModalComponent, {
        viewContainerRef: this.viewContainerRef, // This lets the modal component use providers injected from its ancestors
        maxWidth: '95vw',
        disableClose: true
      })
      .afterClosed()
      .subscribe();
  }
}
