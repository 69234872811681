import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { BusinessRulesServiceBase } from 'src/app/sage-common-module/BusinessRulesServiceBase';
import { BusinessRuleValueList } from 'src/app/sage-common-module/models/business-rule-value-list';
import { BusinessRulesDefinition } from 'src/app/sage-common-module/models/business-rules-definition';
import { INSULATION_TYPE_VALUES } from 'src/app/utilities/hardcoded-lists';

@Injectable()
export class CmlBusinessRulesService extends BusinessRulesServiceBase implements BusinessRulesDefinition {
  public get valueData(): BusinessRuleValueList {
    if (this.data === undefined) {
      throw 'init must be called before valueData()';
    } else {
      return {
        insulationType: {
          getValidValues: () => of(INSULATION_TYPE_VALUES)
        },
      };
    }
  }
}
