import { Component, Input } from '@angular/core';
import { LabelPosition } from '@equityeng/e2g-ng-ui/lib/e2g-ui-component-config';

import { BrBaseComponent } from '../br-base.component';

@Component({
  selector: 'app-br-date',
  templateUrl: './br-date.component.html'
})
export class BrDateComponent extends BrBaseComponent {
  @Input() public labelPosition?: LabelPosition;

  public getLabelPosition(): LabelPosition {
    return this.labelPosition === undefined ? <LabelPosition>this.businessRules.labelPosition : this.labelPosition ;
  }
}
