export enum ActionTypes {
  Add = 1,
  Update = 2,
  Rename = 3,
  Copy = 4,
  Delete = 5,
  Move = 6,
  Upload = 7,
  Send = 8,
  Load = 9,
  Download = 10,
  Import = 11,
  Share = 12,
  Calculate = 13,
  Generate = 14,
  Export = 15
}
