export enum AssetDesignTabs { //TODO Move into folder models/enums/tabs?
  Overview = 1,
  Design = 2,
  Sage = 3
}

export enum AssetDmlTabs { //TODO Move into folder models/enums/tabs?
  Findings = 1,
  DMLs = 2
}
