import { Injectable } from '@angular/core';
import { AuthService, User } from '@equityeng/auth';
import { BehaviorSubject, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take, tap } from 'rxjs/operators';

import { CompanyDataService } from './company-data.service';
import { Company } from './models/company.model';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  private updateCompany = new BehaviorSubject<string | undefined>(undefined);
  private companies: Company[] = [];

  // this observable should be subscribed to by any component wishing to know the currently selected company
  // this will continue to update if the company changes
  public readonly selectedCompany: Observable<Company> = new ReplaySubject<Company>(1);

  // this observable will fire immediatly after a new company is seleted
  // before the token is refreshed
  public readonly selectionChanging: Observable<void> = new ReplaySubject<void>(1);

  // this observable will be true while the change company process is running
  public readonly selectorDisabled: Observable<boolean> = new ReplaySubject<boolean>(1);

  public readonly selectorHidden: Observable<boolean> = new ReplaySubject<boolean>(1);

  public constructor(private authService: AuthService, private dataService: CompanyDataService) {
    // - Starts with the update company observable which holds the requested company Key. It starts as undefined
    //   until something is added with the next() method
    // - We do not want to trigger this unless we really have a company so we filter out all but not undefined
    // - Then we map the variable to type string to keep tslint happy. it doesn't understand that we are filtering
    //   out undefined so it thinks that the varialbe could be (string | undefined)
    // - We don't want to trigger anything if somewhere we select the same company again so we distinctUntilChanged
    // - If the company really has changed we:
    //     > look up the Company by key
    //     > return the newly selected Company
    // - Store the new company key in local storage so we can get it again if the user refreshes
    // - Fire the selectedCompany observable to tell any subscribers that a new company has been selected
    this.updateCompany
      .pipe(
        filter((x) => x !== undefined),
        map((x) => x as string),
        distinctUntilChanged(),
        tap(() => {
          (this.selectionChanging as Subject<void>).next();
          (this.selectorDisabled as Subject<boolean>).next(true);
        }),
        switchMap((companyKey) => {
          this.authService.setTenant(companyKey);
          this.authService.refreshTokens();
          return this.authService.userLoaded.pipe(
            take(1),
            map(() => this.getCompanyByKey(companyKey))
          );
        })
      )
      .subscribe((company) => {
        (this.selectorDisabled as Subject<boolean>).next(false);
        if (company) {
          this.setCurrentCompanyKey(company.key);
          (this.selectedCompany as Subject<Company>).next(company);
        } else {
          console.log('ERROR: Company could not be loaded');
          // TODO: Show error about company key being invalid for this customer or something
        }
      });
  }

  // Set the initial state of the app as soon as Authentication completes
  // Get all companies this user can access, and store them in an array
  // Set the initial company to either the one found in the user local storage, or the first one in the array
  public initialize(user: User): Observable<User> {
    return of(user).pipe(
      switchMap(() => this.dataService.getCompaniesFromApi()),
      tap((companies) => {
        this.companies = companies;
        const initialCompany = companies.find((x) => x.key === this.getCurrentCompanyKey()) || companies[0];
        this.triggerCurrentCompany(initialCompany.key);
        if (companies.length === 1) {
          this.setHidden(true);
        }
      }),
      map(() => user)
    );
  }

  // returns a list of all the companies the user has access to based on their EEP_ product list
  public getCompanies(): Array<Company> {
    return this.companies;
  }

  // requests that a new company be selected
  // - this happens when the app is initialized
  // - this can happen when the user switches the company in the select list in the header
  public triggerCurrentCompany(companyKey: string): void {
    this.updateCompany.next(companyKey);
  }

  // Looks up the Company based on company Key
  // Returns undefined if the company key is not valid for the user
  private getCompanyByKey(companyKey: string): Company | undefined {
    return this.companies.find((f) => f.key === companyKey);
  }

  // Gets the current company key out of the user local storage
  private getCurrentCompanyKey(): string | undefined {
    return localStorage.getItem('companyKey') || undefined;
  }

  // Sets the current company key into the user local storage
  private setCurrentCompanyKey(companyKey: string): void {
    localStorage.setItem('companyKey', companyKey);
  }

  public setDisabled(disabled: boolean): void {
    (this.selectorDisabled as Subject<boolean>).next(disabled);
  }

  public setHidden(hidden: boolean): void {
    if (this.companies.length > 1 || hidden) {
      (this.selectorHidden as Subject<boolean>).next(hidden);
    }
  }
}
