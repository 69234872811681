import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { DialogService } from 'src/app/shared-module/dialog.service';
import { DialogButtons } from 'src/app/shared-module/models/dialog-buttons';
import { DialogData } from 'src/app/shared-module/models/dialog-data';
import { DialogResult } from 'src/app/shared-module/models/dialog-result';
import { NotificationService } from 'src/app/shared-module/services/notification.service';

import { JobNotificationService } from '../../notifications-module/services/job-notifications.service';
import { CmlDetailReportDialogComponent } from '../cml-detail-report-dialog/cml-detail-report-dialog.component';
import { CmlDetailReportDto } from '../models/cml-detail-report-dto';
import { ReportingDataService } from '../reporting.data.service';

@Injectable({
  providedIn: 'root'
})
export class CmlDetailReportService {
  public constructor(
    private dialogService: DialogService,
    private dataService: ReportingDataService,
    private notificationService: NotificationService,
    private jobNotificationsService: JobNotificationService
  ) {}

  public run(): void {
    const formData: CmlDetailReportDto = {
      assetIds: [],
      timeZoneOffset: new Date().getTimezoneOffset()
    };

    const dlgData: DialogData = {
      title: 'CML Detail Report',
      buttons: DialogButtons.YesCancel,
      component: CmlDetailReportDialogComponent,
      componentData: formData,
      yesButtonText: 'Save Report',
      width: '750px',
      height: '600px'
    };

    this.dialogService
      .display(dlgData)
      .pipe(
        concatMap((dlgResult) => {
          if (dlgResult === DialogResult.yes) {
            this.notificationService.showInfo('Generating CML Detail Report');
            return this.dataService.generateCmlDetailReport(formData);
          }
          return of(false);
        })
      )
      .subscribe(() => {
        this.jobNotificationsService.refreshNotifications();
      });
  }
}
