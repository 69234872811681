import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GridApi, ValueFormatterParams } from 'ag-grid-community';
import { Observable, tap } from 'rxjs';
import { GridCellTooltipComponent } from 'src/app/grid-module/cell-renders/grid-cell-tooltip/grid-cell-tooltip.component';
import { buildCheckboxColDef } from 'src/app/grid-module/column-builders/build-checkbox';
import { buildDateOnlyColDef } from 'src/app/grid-module/column-builders/build-date-only';
import { decimalFormatter } from 'src/app/grid-module/column-formatters/decimal-formatter';
import { GridBaseOptions } from 'src/app/grid-module/e2g-ag-grid/e2g-ag-grid.component';
import { ActionTypes } from 'src/app/shared-module/models/action-types';
import { DialogType } from 'src/app/shared-module/models/name-dialog-data';
import { NotificationService } from 'src/app/shared-module/services/notification.service';

import { CompanyService } from '../../../company.service';
import { doubleWithNullComparator } from '../../../grid-module/column-data-comparers/double-with-null-comparator';
import { UpdateType } from '../../../models/enums/update-type';
import { OnDestroyBaseComponent } from '../../../on-destroy-base-component/on-destroy-base-component';
import { ISaveChanges } from '../../../save-changes';
import { ListDataHandler } from '../../../shared-module/list-data-handler';
import { InspectionMethodTypes } from '../../models/enums/inspection-method-types';
import { ReadingDto } from '../../models/reading-dto';
import { ReadingDataService } from '../../services/reading-data.service';

export interface AllReadingsData {
  name: string;
  readings: Array<ReadingDto>;
}

@Component({
  selector: 'app-all-cml-readings',
  templateUrl: './all-cml-readings.component.html'
})
export class AllCmlReadingsComponent extends OnDestroyBaseComponent implements OnInit, ISaveChanges {
  @Input() public data?: AllReadingsData;
  @Input() public readonly!: boolean;
  @Output() public clearAllReadingsData = new EventEmitter<void>();

  public rowHeight: number = 24;

  public gridDataHandler: ListDataHandler<ReadingDto>;
  public saving: boolean = false;
  public layoutModified: boolean = false;

  public gridOptions!: GridBaseOptions;

  public constructor(
    private companyService: CompanyService,
    private readingService: ReadingDataService,
    private notificationService: NotificationService
  ) {
    super();
    this.gridDataHandler = new ListDataHandler<ReadingDto>('id', 'updateType', this.destroy, this.companyService);
  }

  public ngOnInit(): void {
    this.gridOptions = {
      context: 'readings',
      enterNavigatesVertically: true,
      enterNavigatesVerticallyAfterEdit: true,
      onCellValueChanged: this.dataChanged.bind(this),
      setDefaultSort: (columnApi: GridApi): void => {
        columnApi.applyColumnState({
          state: [
            {
              colId: 'date',
              sort: 'asc'
            }
          ]
        });
      },
      defaultColDef: {
        resizable: true,
        sortable: true,
        filter: true,
        minWidth: 125,
        width: 150,
        tooltipComponent: GridCellTooltipComponent
      },
      columnDefs: [
        buildDateOnlyColDef('Survey Date', 'date'),
        {
          headerName: 'Thickness',
          headerTooltip: 'Thickness',
          field: 'thickness',
          tooltipField: 'thickness',
          valueGetter: decimalFormatter,
          comparator: doubleWithNullComparator
        },
        buildCheckboxColDef(
          'Exclude Thickness',
          'excludeFromCalculation',
          {
            readonly: () => this.readonly
          },
          150
        ),
        {
          headerName: 'Method',
          headerTooltip: 'Method',
          field: 'inspectionMethod',
          tooltipField: 'inspectionMethod',
          valueFormatter: (params: ValueFormatterParams): string =>
            InspectionMethodTypes[params.data[params.colDef.field || '']]
        },
        buildCheckboxColDef(
          'Baseline',
          'isBaseline',
          {
            readonly: () => this.readonly
          },
          150
        )
      ]
    };

    this.gridDataHandler.clearData();
    if (this.data) {
      this.gridDataHandler.setInitialData(this.data.readings);
    }
  }

  public goBack(): void {
    this.clearAllReadingsData.emit();
  }

  public isDirty(): boolean {
    return this.gridDataHandler.dirty;
  }

  public revertChanges(): void {
    this.gridDataHandler.revertChanges();
  }

  public saveChanges(): Observable<boolean> {
    this.saving = true;
    const rowsToUpdate: Array<ReadingDto> = this.gridDataHandler
      .getChangedData()
      .filter((reading) => reading.updateType === UpdateType.Update);

    return this.readingService.updateReadings(rowsToUpdate).pipe(
      tap((success) => {
        this.notificationService.showActionResult(success, ActionTypes.Update, DialogType.CMLs);
        if (success) {
          this.gridDataHandler.promoteChanges();
        }
        this.saving = false;
      })
    );
  }

  public dataChanged(event: any): void {
    if (event.type === 'cellValueChanged' && event.colDef.field === 'excludeFromCalculation') {
      const changedRowData = this.gridDataHandler.getChangedData()?.find((x: ReadingDto) => x.id === event.node.id);

      if (changedRowData) {
        changedRowData.excludeFromCalculation = event.node.data.excludeFromCalculation;
      }
    }

    this.gridDataHandler.dataChanged();
  }
}
