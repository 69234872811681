<app-slide-out-container
  ><div *ngIf="!loading; else load" class="h-100">
    <div class="row m-0 h-100">
      <div class="col-4 mt-2">
        <div class="row">
          <div class="col-12 pl-4">
            <div class="mb-2">
              <e2g-textarea-input
                id="inp-description"
                heading="Description"
                [rows]="2"
                [(ngModel)]="dataHandler.data.description"
                maxlength="255"
                [readonly]="readOnly"
              ></e2g-textarea-input>
            </div>
            <div class="mb-2">
              <div *ngIf="readOnly" class="col p-0">
                <div>
                  <label for="finding-recommended-corrective-action-readonly" class="m-0 pb-2"
                    >Recommended Corrective Action</label
                  >
                </div>
                <div>
                  <div
                    id="finding-recommended-corrective-action-readonly"
                    class="mt-2 w-100 findingRecommendedCorrectiveAction"
                  >
                    {{
                      dataHandler.data.recommendedCorrectiveAction !== undefined
                        ? dataHandler.data.recommendedCorrectiveAction
                        : '&nbsp;'
                    }}
                  </div>
                </div>
              </div>
              <e2g-textarea-input
                *ngIf="!readOnly"
                id="inp-finding-recommended-corrective-action"
                heading="Recommended Corrective Action"
                class="recommendedCorrectiveAction"
                maxlength="255"
                [rows]="2"
                [(ngModel)]="dataHandler.data.recommendedCorrectiveAction"
                [readonly]="readOnly"
              ></e2g-textarea-input>
            </div>
            <div class="mb-2">
              <e2g-ng-select-input
                id="select-finding-component"
                heading="Component"
                placeholder="Select Component"
                [isLoading]="!componentsForAssetList"
                [options]="componentsForAssetList || []"
                [(ngModel)]="dataHandler.data.componentId"
                [readonly]="readOnly"
                [clearable]="true"
              ></e2g-ng-select-input>
            </div>
            <div class="mb-2">
              <e2g-ng-select-input
                id="select-finding-activity"
                heading="Activity"
                placeholder="Select Activity"
                [isLoading]="!activityList"
                [options]="activityList || []"
                [(ngModel)]="dataHandler.data.activityId"
                [readonly]="readOnly || isOpenedFromActivity()"
              ></e2g-ng-select-input>
              <div
                *ngIf="!isOpenedFromActivity() && editing"
                id="link-view-activity"
                (click)="viewActivity()"
                [ngClass]="{
                  'pointer e2g-blue': dataHandler.data.activityId !== undefined,
                  'e2g-disabled-grey cursor-disabled': dataHandler.data.activityId === undefined
                }"
              >
                View Activity
              </div>
            </div>
            <div class="mb-2">
              <e2g-date-input
                heading="Date"
                id="inp-finding-date"
                placeholder="mm/dd/yyyy"
                [(ngModel)]="dataHandler.data.date"
                [errors]="dateErrors"
                [readonly]="readOnly"
              ></e2g-date-input>
            </div>
            <div class="mb-2">
              <e2g-text-input
                heading="Code Reference"
                id="inp-finding-code-reference"
                maxlength="255"
                [(ngModel)]="dataHandler.data.codeReference"
                [readonly]="readOnly"
              ></e2g-text-input>
            </div>
            <div class="mb-2">
              <e2g-ng-select-input
                id="select-finding-priority"
                heading="Priority"
                placeholder="Select Priority"
                [isLoading]="!priorityList"
                [options]="priorityList || []"
                [(ngModel)]="dataHandler.data.priority"
                [readonly]="readOnly"
              ></e2g-ng-select-input>
            </div>
            <div class="mb-2">
              <e2g-text-input
                heading="Maintenance Reference"
                id="inp-finding-maintenance-reference"
                maxlength="255"
                [(ngModel)]="dataHandler.data.maintenanceReference"
                [readonly]="readOnly"
              ></e2g-text-input>
            </div>
            <div class="mb-2">
              <e2g-ng-select-input
                id="select-finding-status"
                heading="Status"
                placeholder="Select Status"
                [isLoading]="!statusList"
                [options]="statusList || []"
                [(ngModel)]="dataHandler.data.status"
                [readonly]="readOnly"
              ></e2g-ng-select-input>
            </div>

            <div>
              <e2g-textarea-input
                id="inp-comments"
                class="w-100 pr-2"
                heading="Comments"
                [rows]="5"
                [readonly]="readOnly"
                [maxlength]="512"
                [(ngModel)]="dataHandler.data.comments"
              ></e2g-textarea-input>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="attachmentKey" class="col mt-2">
        <app-attachments
          [tableType]="tableType"
          [attachmentKey]="attachmentKey"
          [readonly]="readOnly"
        ></app-attachments>
      </div>
    </div></div></app-slide-out-container
><ng-template #load><div class="loading"></div></ng-template>
