export enum ActivityStatus {
  None = 0,
  Planned = 1,
  InProgress = 2,
  Completed = 3
}

export function getActivityStatus(input: ActivityStatus): string {
  switch (input) {
    case ActivityStatus.Planned:
      return 'Planned';
    case ActivityStatus.InProgress:
      return 'In Progress';
    case ActivityStatus.Completed:
      return 'Complete';
    default:
      return '';
  }
}
