import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { E2gSelectOption } from '@equityeng/e2g-ng-ui';
import { faMemo, faPencilAlt } from '@fortawesome/pro-regular-svg-icons';
import { faChevronDown, faTrashXmark } from '@fortawesome/pro-solid-svg-icons';
import { ICellRendererParams } from 'ag-grid-community';
import { concatMap, forkJoin, Observable, of, startWith, Subject, switchMap, take, takeUntil, tap } from 'rxjs';
import { BaseSlideoutOption } from 'src/app/asset-module/models/base-slideout-option';
import { Breadcrumb } from 'src/app/breadcrumb-module/breadcrumbs/breadcrumbs.component';
import { DmlSlideoutOption } from 'src/app/dmls-module/models/dml-slideout-option';
import { DmlDataService } from 'src/app/dmls-module/services/dml-data.service';
import { GridCellLinkParams } from 'src/app/grid-module/cell-renders/grid-cell-link/grid-cell-link-params';
import { GridCellLinkComponent } from 'src/app/grid-module/cell-renders/grid-cell-link/grid-cell-link.component';
import { GridCellTooltipComponent } from 'src/app/grid-module/cell-renders/grid-cell-tooltip/grid-cell-tooltip.component';
import { buildDefaultColDef } from 'src/app/grid-module/column-builders/build-default';
import { getDmlGridColumns } from 'src/app/grid-module/column-definitions/dml-grid-columns';
import { getFindingGridColumns } from 'src/app/grid-module/column-definitions/finding-grid-columns';
import { E2gAgGridComponent, GridBaseOptions } from 'src/app/grid-module/e2g-ag-grid/e2g-ag-grid.component';
import { AssetTypes } from 'src/app/models/enums/asset-types';
import { OnDestroyBaseComponent } from 'src/app/on-destroy-base-component/on-destroy-base-component';
import { NEW_ITEM_KEY } from 'src/app/shared-module/models/new-item-key';
import {
  AssetFeature,
  AssetPermissionModel,
  GeneralFeature,
  NO_PERMISSION
} from 'src/app/shared-module/permission/permission-models';
import { PermissionService } from 'src/app/shared-module/permission/permission.service';
import { NotificationService } from 'src/app/shared-module/services/notification.service';
import { StateService } from 'src/app/state.service';

import { CompanyService } from '../../company.service';
import { DmlSlideoutComponent } from '../../dmls-module/dml-slideout/dml-slideout.component';
import { DmlDto } from '../../dmls-module/models/dml-dto';
import { DmlSlideoutInput } from '../../dmls-module/models/dml-slideout-input';
import { DmlTypes } from '../../dmls-module/models/Enums/dml-types';
import { E2gMenuItem } from '../../grid-module/cell-renders/grid-cell-menu/e2g-menu-item';
import { GridCellMenuComponent } from '../../grid-module/cell-renders/grid-cell-menu/grid-cell-menu-component';
import { EquipmentUnitInput } from '../../models/equipment-unit-input';
import { DialogService } from '../../shared-module/dialog.service';
import { ListDataHandler } from '../../shared-module/list-data-handler';
import { DialogButtons } from '../../shared-module/models/dialog-buttons';
import { DialogData } from '../../shared-module/models/dialog-data';
import { DialogResult } from '../../shared-module/models/dialog-result';
import { SlideOutService } from '../../slide-out-module/slide-out.service';
import { FindingSlideoutComponent } from '../finding-slideout/finding-slideout.component';
import { FindingDto } from '../models/finding-dto';
import { FindingSlideoutInput } from '../models/finding-slideout-input';
import { FindingDataService } from '../services/finding-data.service';

@Component({
  selector: 'app-findings',
  templateUrl: './findings.component.html',
  styleUrls: ['./findings.component.css']
})
export class FindingsComponent extends OnDestroyBaseComponent implements OnInit {
  @Input() public assetType!: AssetTypes;
  @Input() public breadcrumbs: Breadcrumb[] = [];
  @ViewChild('deleteDialog', { static: true }) public deleteDialogTemplate!: TemplateRef<any>;
  @ViewChild('findingsGrid', { static: false }) public findingsGrid?: E2gAgGridComponent;
  @ViewChild('dmlsGrid', { static: false }) public dmlsGrid?: E2gAgGridComponent;

  public faTrashXmark = faTrashXmark;
  private faPencilAlt = faPencilAlt;
  public activeTabId: number = 1;
  public faChevronDown = faChevronDown;
  private faMemo = faMemo;

  private menuDefinitionFindings: Array<E2gMenuItem> = [
    {
      text: 'View',
      command: (params: ICellRendererParams): void => this.addOrEditFinding(params),
      iconDefinition: { icon: this.faPencilAlt },
      visible: (): boolean => this.findingPermissions.edit
    },
    {
      text: 'Delete',
      command: (params: ICellRendererParams): void => this.deleteFinding(params),
      iconDefinition: { icon: this.faTrashXmark, color: 'var(--system-fg-error)' },
      visible: (): boolean => this.findingPermissions.delete
    },
    {
      text: 'Show Detail',
      command: (params: ICellRendererParams): void => this.addOrEditFinding(params),
      iconDefinition: { icon: this.faMemo },
      visible: (): boolean => !this.findingPermissions.edit
    }
  ];

  private menuDefinitionDamageMonitoringLocations: Array<E2gMenuItem> = [
    {
      text: 'View',
      command: (params: ICellRendererParams): void => this.addOrEditDamageMonitoringLocation(params.data.type, params),
      iconDefinition: { icon: this.faPencilAlt },
      visible: (): boolean => this.dmlPermissions.edit
    },
    {
      text: 'Delete',
      command: (params: ICellRendererParams): void => this.deleteDamageMonitoringLocation(params),
      iconDefinition: { icon: this.faTrashXmark, color: 'var(--system-fg-error)' },
      visible: (): boolean => this.dmlPermissions.delete
    },
    {
      text: 'Show Detail',
      command: (params: ICellRendererParams): void => this.addOrEditDamageMonitoringLocation(params.data.type, params),
      iconDefinition: { icon: this.faMemo },
      visible: (): boolean => !this.dmlPermissions.edit
    }
  ];

  public findingsGridOptions: GridBaseOptions = {
    exportFileName: 'Findings',
    context: 'findings',
    suppressCellFocus: true,
    rowSelection: 'single',
    defaultColDef: buildDefaultColDef(),
    columnDefs: [
      {
        headerName: 'Name',
        headerTooltip: 'Name',
        field: 'name',
        tooltipField: 'name',
        sortable: true,
        comparator: (a, b): number => a.localeCompare(b, 'en', { numeric: true, sensitivity: 'base' }),
        width: 250,
        cellRenderer: GridCellLinkComponent,
        filter: 'agSetColumnFilter',
        cellRendererParams: {
          isLink: (): boolean => true,
          linkCommand: (params: ICellRendererParams): void => this.addOrEditFinding(params)
        } as GridCellLinkParams
      },
      {
        headerName: 'Actions',
        headerTooltip: 'Actions',
        width: 112,
        cellStyle: { textAlign: 'center' },
        headerClass: 'ag-header-center',
        cellRenderer: GridCellMenuComponent,
        cellRendererParams: { menuItems: this.menuDefinitionFindings },
        filter: false
      },
      ...getFindingGridColumns(),
      {
        headerName: 'Comments',
        headerTooltip: 'Comments',
        field: 'comments',
        tooltipField: 'comments',
        width: 300,
        hide: true
      }
    ]
  };

  public dmlsGridOptions: GridBaseOptions = {
    exportFileName: 'DMLs',
    context: 'dmls',
    suppressCellFocus: true,
    rowSelection: 'single',
    defaultColDef: {
      resizable: true,
      sortable: true,
      minWidth: 125,
      tooltipComponent: GridCellTooltipComponent,
      filter: 'agSetColumnFilter'
    },
    columnDefs: [
      {
        headerName: 'Name',
        headerTooltip: 'Name',
        field: 'name',
        tooltipField: 'name',
        comparator: (a, b): number => a.localeCompare(b, 'en', { numeric: true, sensitivity: 'base' }),
        width: 250,
        cellRenderer: GridCellLinkComponent,
        cellRendererParams: {
          isLink: (): boolean => true,
          linkCommand: (params: ICellRendererParams): void =>
            this.addOrEditDamageMonitoringLocation(params.data.type, params)
        } as GridCellLinkParams
      },
      {
        headerName: 'Actions',
        headerTooltip: 'Actions',
        width: 112,
        cellStyle: { textAlign: 'center' },
        headerClass: 'ag-header-center',
        cellRenderer: GridCellMenuComponent,
        cellRendererParams: {
          menuItems: this.menuDefinitionDamageMonitoringLocations
        },
        filter: false
      },
      ...getDmlGridColumns(),
      {
        headerName: 'Comments',
        headerTooltip: 'Comments',
        field: 'comments',
        tooltipField: 'comments',
        width: 300,
        hide: true
      }
    ]
  };

  public gridDataHandlerFindings: ListDataHandler<FindingDto>;
  public gridDataHandlerDmls: ListDataHandler<DmlDto>;

  public inputData!: EquipmentUnitInput;
  public equipmentKey: string = '';
  public refreshData = new Subject<void>();
  public findings: Array<FindingDto> | undefined;

  public findingPermissions: AssetPermissionModel = NO_PERMISSION;
  public dmlPermissions: AssetPermissionModel = NO_PERMISSION;
  public dmlEnabled: boolean = false;

  public DmlTypes: Array<E2gSelectOption> | undefined = [] as Array<E2gSelectOption>;
  public constructor(
    private permissionService: PermissionService,
    private route: ActivatedRoute,
    private slideOutService: SlideOutService,
    private dialogService: DialogService,
    private notificationService: NotificationService,
    private findingService: FindingDataService,
    private stateService: StateService,
    private dmlDataService: DmlDataService,
    private companyService: CompanyService
  ) {
    super();

    this.gridDataHandlerFindings = new ListDataHandler<FindingDto>('id', 'id', this.destroy, this.companyService);
    this.gridDataHandlerDmls = new ListDataHandler<DmlDto>('id', 'id', this.destroy, this.companyService);
  }

  public ngOnInit(): void {
    this.route.params
      .pipe(
        take(1),
        tap((params) => {
          this.setInputData(params);
        }),
        switchMap(() => this.assignAccess()),
        concatMap(() =>
          this.refreshData.pipe(startWith(null)).pipe(
            tap(() => {
              this.gridDataHandlerFindings.clearData();
              this.gridDataHandlerDmls.clearData();
            }),
            switchMap(() =>
              forkJoin([
                this.findingService.getFindings(this.inputData.equipmentKey),
                this.dmlEnabled ? this.dmlDataService.getDamageMonitoringLocations(this.inputData.equipmentKey) : of([])
              ])
            ),
            takeUntil(this.destroy)
          )
        )
      )
      .subscribe(([findings, damageMonitoringLocations]) => {
        this.gridDataHandlerFindings.setInitialData(findings);
        this.gridDataHandlerDmls.setInitialData(damageMonitoringLocations);

        if (this.stateService.staticState.flow.dmlKey) {
          const dml = this.gridDataHandlerDmls.data!.find((x) => x.id == this.stateService.staticState.flow.dmlKey)!;

          const params = { data: dml };

          this.addOrEditDamageMonitoringLocation(dml.type, params);
          this.stateService.setFlowDml(undefined);
        }

        if (this.stateService.staticState.flow.findingKey) {
          const finding = findings.find((x) => x.id == this.stateService.staticState.flow.findingKey);

          this.stateService.setFlowFinding(undefined);
          this.addOrEditFinding({ data: { id: finding?.id } });
        }
      });

    this.DmlTypes = Object.entries(DmlTypes)
      .filter((e) => !isNaN(e[0] as any))
      .map((e) => ({ value: Number(e[0]), label: e[1] }))
      .sort((a, b) =>
        (a.label.toString() || '').localeCompare(b.label.toString(), 'en', { numeric: false, sensitivity: 'base' })
      ) as Array<E2gSelectOption>;
  }

  private assignAccess(): Observable<any> {
    return this.permissionService
      .getPermissions({
        asset: [
          { feature: AssetFeature.Finding, assetId: this.inputData.equipmentKey! },
          { feature: AssetFeature.Dml, assetId: this.inputData.equipmentKey! }
        ],
        general: [{ feature: GeneralFeature.Dmls }]
      })
      .pipe(
        tap((permissions) => {
          this.findingPermissions = permissions.asset[AssetFeature.Finding];
          this.dmlPermissions = permissions.asset[AssetFeature.Dml];
          this.dmlEnabled =
            permissions.general[GeneralFeature.Dmls] &&
            ![AssetTypes.StorageTank, AssetTypes.PressureReliefDevice].includes(this.assetType);
        })
      );
  }

  private setInputData(params: Params): void {
    this.inputData = {
      equipmentKey: params.equipmentKey,
      unitKey: params.unitKey,
      unitOfMeasure: params.unitOfMeasure
    };
  }

  public addOrEditFinding(params: any): void {
    const optionsData = this.findingsGrid!.convertNodesToSlideoutOptions<FindingDto, BaseSlideoutOption>((data) => {
      return {
        name: data.name!,
        assetId: data.assetId!
      };
    });

    const slideoutData: FindingSlideoutInput = {
      id: params !== undefined ? params.data.id : NEW_ITEM_KEY,
      assetId: this.inputData.equipmentKey,
      optionsData: optionsData,
      breadcrumbs: this.breadcrumbs,
      gridContext: this.findingsGridOptions.context
    };

    this.slideOutService
      .open<boolean, FindingSlideoutComponent>(FindingSlideoutComponent, {
        data: slideoutData
      })
      .closed.pipe(take(1))
      .subscribe(() => this.refreshData.next());
  }

  public deleteFinding(params: ICellRendererParams): void {
    const deleteFindingDialogData = {
      title: 'Delete Finding',
      message: 'Are you sure you want to delete this finding and its data?',
      buttons: DialogButtons.YesCancel,
      template: this.deleteDialogTemplate,
      yesButtonText: 'Delete'
    } as DialogData;

    this.dialogService
      .display(deleteFindingDialogData)
      .pipe(
        switchMap((dialogResult) => {
          if (dialogResult === DialogResult.yes) {
            this.doDeleteFinding(params.data.id);
          }
          return of(false);
        })
      )
      .subscribe();
  }

  public deleteDamageMonitoringLocation(params: ICellRendererParams): void {
    const deleteDamageMonitoringLocationDialogData = {
      title: 'Delete Damage Management Location',
      message: 'Are you sure you want to delete this DML and its data?',
      buttons: DialogButtons.YesCancel,
      template: this.deleteDialogTemplate,
      yesButtonText: 'Delete'
    } as DialogData;

    this.dialogService
      .display(deleteDamageMonitoringLocationDialogData)
      .pipe(
        switchMap((dialogResult) => {
          if (dialogResult === DialogResult.yes) {
            this.doDeleteDamageMonitoringLocation(params.data.id, params.data.fileId);
          }
          return of(false);
        })
      )
      .subscribe();
  }

  private doDeleteFinding(id: string): void {
    this.findingService.deleteFinding(id).subscribe((result) => {
      this.showDeleteToast(result);

      this.refreshData.next();
    });

    this.dialogService.closeAll();
  }

  private doDeleteDamageMonitoringLocation(id: string, fileId: string): void {
    this.dmlDataService.deleteDamageMonitoringLocation(id, fileId).subscribe((result) => {
      this.showDeleteToast(result);

      this.refreshData.next();
    });

    this.dialogService.closeAll();
  }

  private showDeleteToast(result: boolean): void {
    this.notificationService.showSaveResult(result, 'Delete');
  }

  public addOrEditDamageMonitoringLocation(
    damageMonitoringLocationType: number | string | undefined,
    params?: any
  ): void {
    const optionsData = this.dmlsGrid!.convertNodesToSlideoutOptions<DmlDto, DmlSlideoutOption>((data) => {
      return {
        name: data.name!,
        assetId: this.inputData.equipmentKey!,
        type: data.type!,
        fileName: data.fileName,
        fileId: data.fileId,
        reportFileId: data.reportFileId
      };
    });

    if (damageMonitoringLocationType !== undefined) {
      const slideoutData: DmlSlideoutInput = {
        id: params !== undefined ? params.data.id! : NEW_ITEM_KEY,
        assetId: this.inputData.equipmentKey,
        type: damageMonitoringLocationType as DmlTypes,
        unitOfMeasure: this.inputData.unitOfMeasure,
        optionsData: optionsData,
        breadcrumbs: this.breadcrumbs,
        gridContext: this.dmlsGridOptions.context
      };

      this.slideOutService
        .open<boolean, DmlSlideoutComponent>(DmlSlideoutComponent, {
          data: slideoutData
        })
        .closed.subscribe(() => {
          this.refreshData.next();
        });
    }
  }
}
