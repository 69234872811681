import { Component, Inject, OnInit } from '@angular/core';
import { E2gSelectOption } from '@equityeng/e2g-ng-ui';
import { forkJoin, Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { getDefaultComponent } from 'src/app/asset-module/components/component-slideout-utility';
import { CompDataService } from 'src/app/comp-data.service';
import { ComponentRecordDto } from 'src/app/models/component-record-dto';
import { EquipmentDto } from 'src/app/models/equipment-dto';
import { BUSINESS_RULES } from 'src/app/sage-common-module/business-rules-injection-token';
import { BusinessRulesDefinition } from 'src/app/sage-common-module/models/business-rules-definition';
import { NEW_ITEM_KEY } from 'src/app/shared-module/models/new-item-key';

import {
  CompDetailBaseComponent,
  CompDetailBaseComponentProviders
} from '../../asset-module/components/comp-detail-base-component';
import { ComponentSlideoutInput } from '../../asset-module/models/component-slideout-input';
import { ISaveChanges } from '../../save-changes';
import { SLIDE_OUT_DATA } from '../../slide-out-module/slide-out-data-injection-token';
import { TankCompBusinessRulesService } from '../tank-comp-business-rules.service';

@Component({
  selector: 'app-tank-comp',
  templateUrl: './tank-comp.component.html',
  styleUrls: ['./tank-comp.component.css'],
  providers: [
    {
      provide: BUSINESS_RULES,
      useClass: TankCompBusinessRulesService
    },
    {
      provide: ISaveChanges,
      useExisting: TankCompComponent
    },
    ...CompDetailBaseComponentProviders
  ]
})
export class TankCompComponent extends CompDetailBaseComponent implements OnInit, ISaveChanges {
  public constructor(
    private compDataService: CompDataService,
    @Inject(SLIDE_OUT_DATA) slideOutData: ComponentSlideoutInput,
    @Inject(BUSINESS_RULES) businessRulesService: BusinessRulesDefinition
  ) {
    super(slideOutData, businessRulesService);
  }

  public ngOnInit(): void {
    this.setupComponentData();
  }

  protected allowRename(): boolean {
    return !this.isCourse() && super.allowRename();
  }

  protected override getNewComponent(): Observable<ComponentRecordDto> {
    // Since this is a new component we need to determine if it is a course component or not.
    // If it is a course component we need to get the new course number.
    return forkJoin([
      this.equipmentDataService.getSingleEquipment(this.slideOutData.assetId),
      this.getCourseData(),
      this.slideOutService.options.pipe(take(1))
    ]).pipe(map(([asset, courseNumber, options]) => this.setupNewTankComponent(asset, courseNumber, options)));
  }

  private setupNewTankComponent(
    asset: EquipmentDto,
    courseNumber: number | undefined,
    options: E2gSelectOption[]
  ): ComponentRecordDto {
    const component = getDefaultComponent();
    this.setComponentDefaultProperties(component);

    component.assetId = asset.id;
    component.assetType = asset.type;
    component.compType = this.slideOutData.newType;

    component.isRetirementThickness = this.assetConfig.isRetirementThickness;
    component.isStructuralRequiredThickness = this.assetConfig.isStructuralRequiredThickness;
    component.specifiedMinimumThickness = this.assetConfig.specifiedMinimumThickness;
    component.isCorrodedThickness = this.assetConfig.isCorrodedThickness;
    component.isSpecifiedThickness = this.assetConfig.isSpecifiedThickness;

    if (component.compType === 'ANNULAR PLATE' || component.compType === 'FLOOR' || component.compType === 'ROOF') {
      component.isRetirementThickness = false;
      component.isStructuralRequiredThickness = false;
      component.isSpecifiedThickness = true;
    }

    component.designCode = asset.designCode;
    component.eqDesignCode = asset.designCode;

    component.inServiceDate = asset.inServiceDate;

    component.designPressure = asset.designPressure;
    component.designTemperature = asset.designTemperature;

    component.eqDesignPressure = asset.designPressure;
    component.eqDesignTemperature = asset.designTemperature;

    component.tankNominalDiameter = asset.tankNominalDiameter;

    if (this.isCourse()) {
      component.courseNumber = courseNumber;
      component.name = `Course-${component.courseNumber}`;
      component.geomType = 'CY';

      // If this is a new course component we cannot change the name.
      // It is pre-determined based on the course number.  So it needs
      // added to the Name in the dropdown header and not editable.
      options.push({ label: component.name, value: NEW_ITEM_KEY });
      this.slideOutService.setOptions(options);
    }

    return component;
  }

  private getCourseData(): Observable<number | undefined> {
    return this.isCourse() ? this.compDataService.getNewCourseNumber(this.slideOutData.assetId) : of(undefined);
  }

  private setComponentDefaultProperties(component: ComponentRecordDto): void {
    component.defaultProperties = [];

    component.defaultProperties.push('designCode');
    component.defaultProperties.push('inServiceDate');
    component.defaultProperties.push('designPressure');
    component.defaultProperties.push('designTemperature');
    component.defaultProperties.push('specifiedMinimumThickness');
    component.defaultProperties.push('governingMinimumThickness');

    if (this.isCourse()) {
      component.defaultProperties.push('courseNumber');
      component.defaultProperties.push('geomType');
    }
  }

  private isCourse(): boolean {
    const compType = this.editing ? this.dataHandler.data.compType : this.slideOutData.newType;

    return compType === 'COURSE';
  }

  public rbiTabVisible(): boolean {
    return (this.rbiEnabled && this.dataHandler.data.eqRbi && this.dataHandler.data.compType !== 'ROOF') ?? false;
  }

  public rbiDiagVisible(): boolean {
    return this.rbiTabVisible() && (this.dataHandler.data.rbi ?? false);
  }
}
