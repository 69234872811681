import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

export interface ScraRateData {
  startChar: string;
  rate: string;
  code: string;
}

@Component({
  selector: 'app-grid-cell-scra-rate',
  templateUrl: './grid-cell-scra-rate.component.html',
  styleUrls: ['./grid-cell-scra-rate.component.css']
})
export class GridCellScraRateComponent implements ICellRendererAngularComp {
  public cellValue?: ScraRateData;

  public params!: ICellRendererParams;

  public agInit(params: ICellRendererParams): void {
    this.params = params;
    this.processCellValue(params);
  }

  public refresh(params: ICellRendererParams): boolean {
    this.processCellValue(params);
    return true;
  }

  private processCellValue(params: ICellRendererParams): void {
    this.cellValue = params.value as ScraRateData;
  }
}
