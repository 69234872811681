import { ColDef } from 'ag-grid-community';

import { GridCheckboxComponent, IGridCheckboxParams } from '../cell-renders/grid-checkbox/grid-checkbox.component';
import { GridHeaderSelectAllComponent } from '../cell-renders/grid-header-select-all/grid-header-select-all.component';
import { assignHeaderAndTooltip } from './build-default';

export function buildCheckboxColDef(
  header: string,
  field: string,
  checkboxParams: IGridCheckboxParams,
  width?: number,
  useHeaderParams: boolean = true,
  allowSelectAll: boolean = true,
  hide: boolean = false
): ColDef {
  const valueGetter = (params: any): string => (checkboxParams.invert ? !params.data[field] : params.data[field]);

  return {
    ...assignHeaderAndTooltip(header, field),
    tooltipField: checkboxParams.labelField,
    headerComponent: useHeaderParams ? GridHeaderSelectAllComponent : undefined,
    headerComponentParams: useHeaderParams
      ? { enableSelectAll: allowSelectAll, invert: checkboxParams.invert, readonly: checkboxParams.readonly }
      : undefined,
    filter: false,
    sortable: false,
    cellRenderer: GridCheckboxComponent,
    cellRendererParams: checkboxParams,
    valueFormatter: (params: any): string => {
      if (checkboxParams.labelField) {
        return params.data[checkboxParams.labelField];
      }
      return valueGetter(params);
    },
    valueGetter: valueGetter,
    width: width,
    cellClassRules: {
      'ag-cell-hover-outline': () =>
        typeof checkboxParams.readonly === 'function' ? !checkboxParams.readonly() : !checkboxParams.readonly ?? true
    },
    hide: hide
  };
}
