<div class="d-flex flex-column pr-4">
  <div class="d-flex align-middle">
    <e2g-check-input
      id="inp-halfLife"
      heading="Half-Life"
      labelPosition="right"
      [readonly]="readonly"
      [(ngModel)]="data.halfLife"
      (change)="onChangeHalfLife()"
    >
    </e2g-check-input>
  </div>
  <div class="d-flex flex-row align-items-center">
    <e2g-check-input
      id="inp-maxInt"
      heading="Maximum Interval"
      labelPosition="right"
      [readonly]="readonly"
      [(ngModel)]="isMaximumInterval"
      (change)="onChangeMaximumInterval()"
    >
    </e2g-check-input>
    <e2g-input-heading
      *ngIf="isMaximumInterval"
      heading="Months"
      [required]="isMaximumInterval"
      labelPosition="right"
    ></e2g-input-heading>
  </div>
  <div class="inp-under-check">
    <e2g-text-input
      *ngIf="isMaximumInterval"
      id="inp-maximum-interval"
      type="PositiveInteger"
      [(ngModel)]="data.maximumInterval"
      (input)="validateMaximumInterval()"
      [errors]="maximumIntervalErrors"
      [readonly]="readonly"
      (change)="onChangeIsMaximumInterval()"
    ></e2g-text-input>
  </div>
</div>
