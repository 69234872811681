import { ColDef, ValueGetterParams } from 'ag-grid-community';
import { assignHeaderAndTooltip } from 'src/app/grid-module/column-builders/build-default';
import { doubleWithNullComparator } from 'src/app/grid-module/column-data-comparers/double-with-null-comparator';
import { decimalFormatter } from 'src/app/grid-module/column-formatters/decimal-formatter';
import { toFixedThreeFormatter } from 'src/app/grid-module/column-formatters/fixed-three-formatter';
import { DecimalEditor } from 'src/app/shared-module/ag-grid/editor-components/decimal-editor';

export function buildEditableNumberColDef(header: string, field: string): ColDef {
  return {
    ...assignHeaderAndTooltip(header, field),
    editable: true,
    valueFormatter: toFixedThreeFormatter,
    comparator: doubleWithNullComparator,
    filter: 'agNumberColumnFilter',
    filterValueGetter: (params: ValueGetterParams): number => Number(decimalFormatter(params)),
    cellEditor: DecimalEditor
  };
}
