import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { CompanyService } from '../company.service';
import { NotifyPropertyChanged } from './notify-property-changed';

export interface DataHandlerValidation {
  [key: string]: (value: any) => Observable<string | undefined>;
}

interface DataHandlerValidationSub {
  [key: string]: Subscription;
}

interface DataHandlerValidationErrors {
  [key: string]: string;
}

export class SingleDataHandler<T extends object> {
  public data: T = {} as T;
  public valid: boolean = true;
  private valid$: Observable<boolean> = new BehaviorSubject<boolean>(false).pipe(distinctUntilChanged());
  public initialized: boolean = false;

  public dirty: boolean = false;
  private dirty$: Observable<boolean> = new BehaviorSubject<boolean>(false).pipe(distinctUntilChanged());

  protected initData: T | undefined;
  private dataChange = new Subject<void>();
  private listeners = new Map<string, Array<() => void>>(); // Each property can have multiple listeners

  private validation?: DataHandlerValidation;
  private validationSubs: DataHandlerValidationSub = {};
  public validationErrors: DataHandlerValidationErrors = {};

  public constructor(
    private destroy: Observable<any>,
    private dirtyCallback?: (dirty: boolean) => void,
    private companyService?: CompanyService,
    private validationCallback?: () => void
  ) {
    this.dataChange.pipe(takeUntil(this.destroy)).subscribe(() => {
      this.triggerDirty(this.isDirty());
    });
    if (this.dirtyCallback !== undefined || this.companyService !== undefined) {
      this.dirty$.pipe(takeUntil(this.destroy)).subscribe((dirty) => {
        if (this.dirtyCallback) {
          this.dirtyCallback(dirty);
        }
        if (this.companyService) {
          this.companyService.setDisabled(dirty);
        }
      });
    }
  }

  private dataChangedWithProp(prop: any, notify: boolean = true): void {
    if (this.hasValidation(prop)) {
      this.doValidation(prop);
    } else {
      this.dataChanged();
    }

    this.notifyListeners(prop);

    if (notify) {
      this.onPropChange(prop);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  protected onPropChange(prop: string): void {}

  public addListener(prop: string, onChangeCallback: () => void): void {
    if (this.listeners.has(prop)) {
      this.listeners.get(prop)!.push(onChangeCallback);
    } else {
      this.listeners.set(prop, [onChangeCallback]);
    }
  }

  public notifyListeners(prop: string): void {
    if (this.listeners.has(prop)) {
      this.listeners.get(prop)!.forEach((callback) => callback());
    }
  }

  public setInitialData(data: T): void {
    this.setupInitialData(data);
    this.clearAllErrors();
    this.triggerDirty(false);
    this.doInitialValidation();
    this.initialized = true;
  }

  public clear(): void {
    this.data = {} as T;
    this.valid = true;
    this.initialized = false;
    this.triggerDirty(false);
  }

  public setValidation(validation: DataHandlerValidation): void {
    this.validation = validation;
  }

  public promoteChanges(): void {
    if (this.data) {
      this.setupInitialData(this.getRawData());
      this.dataChanged();
    }
  }

  public revertChanges(): void {
    if (this.initData) {
      this.setInitialData(this.initData);
    }
  }

  public getRawData(): T {
    return this.data as T;
  }

  public getInitData(): T {
    return this.initData as T;
  }

  protected dataChanged(): void {
    this.dataChange.next();
  }

  public updateData(data: T): void {
    this.data = this.initWrappedData(data);
    this.dataChanged();
  }

  public isValueChanged(prop: string): boolean {
    return (this.data as any)[prop] !== (this.initData as any)[prop];
  }

  public isObjectValueChanged(prop: string): boolean {
    return JSON.stringify((this.data as any)[prop]) !== JSON.stringify((this.initData as any)[prop]);
  }

  public isValueChangedInList(props: Array<string>): boolean {
    return props.some((prop) => this.isValueChanged(prop));
  }

  //This is a workaround for cases that the datahandler does not detect changes
  //This should be removed when changes in object and array properties are detected automatically
  public arrayOrObjectChanged(): void {
    this.dataChanged();
  }

  private initWrappedData(data: any): T {
    return new NotifyPropertyChanged<typeof data>().create(data, this.dataChangedWithProp.bind(this));
  }

  private triggerDirty(dirty: boolean): void {
    this.dirty = dirty;
    this.valid = !this.hasErrors();
    (this.dirty$ as BehaviorSubject<boolean>).next(dirty);
  }

  private isDirty(): boolean {
    return JSON.stringify(this.data) !== JSON.stringify(this.initData);
  }

  protected setupInitialData(data: T): void {
    this.initData = JSON.parse(JSON.stringify(data));
    this.data = this.initWrappedData(data);
  }

  private hasValidation(propertyName: string): boolean {
    return !!this.validation && !!this.validation[propertyName];
  }

  private hasErrors(): boolean {
    return Object.keys(this.validationErrors).length > 0;
  }

  private doInitialValidation(): void {
    Object.keys(this.data)
      .filter((prop) => this.hasValidation(prop))
      .forEach((prop) => {
        this.doValidation(prop);
      });
  }

  private doValidation(propertyName: string): void {
    this.cancelValidationSub(propertyName);

    const validator = this.validation![propertyName];
    const initValue = (this.initData as any)[propertyName];
    const currentValue = (this.data as any)[propertyName];
    if (initValue === currentValue) {
      this.clearError(propertyName);
      this.dataChanged();
    }

    this.validationSubs[propertyName] = validator(currentValue)
      .pipe(takeUntil(this.destroy))
      .subscribe((result) => {
        if (result) {
          this.setError(propertyName, result);
        } else {
          this.clearError(propertyName);
        }
        this.dataChanged();
        if (this.validationCallback) {
          this.validationCallback();
        }
        (this.valid$ as BehaviorSubject<boolean>).next(this.valid);
      });
  }

  private cancelValidationSub(propertyName: string): void {
    if (this.validationSubs[propertyName]) {
      this.validationSubs[propertyName].unsubscribe();
      delete this.validationSubs[propertyName];
    }
  }

  private setError(propertyName: string, error: string): void {
    this.validationErrors[propertyName] = error;
  }

  private clearError(propertyName: string): void {
    delete this.validationErrors[propertyName];
  }

  private clearAllErrors(): void {
    this.validationErrors = {};
  }

  private setEmptyStringsToNull(obj: object): void {
    // The problem that this function solves is that if an object has a property that is an empty string,
    // usually a numeric property, and that property is set to an empty string,
    // then we will want it will be set to undefined. The reason is that when passing the object
    // through a request we want the properties to be null and not an empty string.
    Object.entries(obj).forEach(([prop, value]) => {
      if (value !== undefined && value !== null && value.length === 0) {
        (obj as any)[prop] = undefined;
      }
    });
  }

  public getDataWithEmptyStringsRemoved(): T {
    this.setEmptyStringsToNull(this.data);
    return this.data;
  }
}
