import { Observable, ReplaySubject, Subject } from 'rxjs';

import { SageFormActionType } from './models/sage-form-action-type';

export class BrSubjects {
  public update$: Observable<SageFormActionType> = new ReplaySubject<SageFormActionType>(1);

  public init(): void {
    (this.update$ as Subject<SageFormActionType>).next(SageFormActionType.init);
  }

  public set(): void {
    (this.update$ as Subject<SageFormActionType>).next(SageFormActionType.set);
  }

  public reset(): void {
    (this.update$ as Subject<SageFormActionType>).next(SageFormActionType.reset);
  }
}
