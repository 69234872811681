<div class="d-flex justify-content-center menu-padding">
  <ng-container *ngIf="isLink; else noLink">
    <div class="pointer" (click)="onLinkClick()">{{ cellValue }}</div>
  </ng-container>
  <fa-icon
    [id]="'menu-'+menuId"
    *ngIf="menu && isVisibleValues"
    [icon]="ellipsis"
    class="icon-secondary"
    #menuTrigger="matMenuTrigger"
    [matMenuTriggerFor]="menu"
    (click)="isMenuIconClicked=true"
  ></fa-icon>
</div>
<mat-menu #menu="matMenu">
  <ng-container *ngIf="isMenuIconClicked">
    <ng-container *ngFor="let item of menuItems">
      <ng-container *ngIf="visible(item) | async">
        <button *ngIf="!isDivider(item); else divider" mat-menu-item (click)="click(item)" [disabled]="disabled(item)">
          <div class="d-flex flex-row">
            <div class="pr-2">
              <fa-icon
                *ngIf="item.iconDefinition"
                [fixedWidth]="true"
                [icon]="item.iconDefinition.icon"
                [style]="getStyle(item)"
              ></fa-icon>
            </div>
            <div>
              <span>{{item.text}}</span>
            </div>
          </div>
        </button>
      </ng-container>
    </ng-container>
  </ng-container>
</mat-menu>
<ng-template #divider>
  <mat-divider></mat-divider>
</ng-template>

<ng-template #noLink>
  <div>{{ cellValue }}</div>
</ng-template>
