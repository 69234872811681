import { Component, Input } from '@angular/core';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'app-card-button',
  templateUrl: './card-button.component.html',
  styleUrls: ['./card-button.component.css']
})
export class CardButtonComponent {
  @Input() public text: string = '';
  @Input() public icon?: IconDefinition;
  @Input() public action?: () => void;
  @Input() public color?: string;
  @Input() public disabled?: boolean;

  public click(): void {
    if (this.action) {
      this.action();
    }
  }
}
