import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { InspectionGradesDto } from 'src/app/models/activity-effectiveness-dto';

import { FutureActivityDto } from '../../activity-details-module/models/future-activity-dto';
import { AppSettingsService } from '../../app-settings/app-settings.service';
import { AcceptFutureActivityDto } from '../models/accept-future-activity-dto';
import { ActivityStatus } from '../models/enums/activity-status';
import { FutureActivityDetailDto } from '../models/future-activity-detail-dto';

@Injectable({
  providedIn: 'root'
})
export class FutureActivityDataService {
  private serverUri: string;

  public constructor(private http: HttpClient, private settingsService: AppSettingsService) {
    this.serverUri = this.settingsService.settings.apiUri as string;
  }

  public getFutureActivities(assetId: string): Observable<Array<FutureActivityDetailDto>> {
    return this.http.get<Array<FutureActivityDetailDto>>(`${this.serverUri}/assets/${assetId}/activities/future`);
  }

  public getFutureActivity(id: string): Observable<FutureActivityDto> {
    return this.http.get<FutureActivityDto>(`${this.serverUri}/activities/future/${id}`);
  }

  public addFutureActivity(dto: FutureActivityDto): Observable<string | boolean> {
    if (!dto.status) {
      dto.status = ActivityStatus.None;
    }

    return this.http.post<string>(`${this.serverUri}/activities/future`, dto).pipe(
      map((x: string) => x),
      catchError(() => of(false))
    );
  }

  public editFutureActivity(dto: FutureActivityDto): Observable<boolean> {
    if (!dto.status) {
      dto.status = ActivityStatus.None;
    }

    return this.http.put(`${this.serverUri}/activities/future`, dto).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  public deleteFutureActivity(id: string): Observable<boolean> {
    return this.http.delete(`${this.serverUri}/activities/future/${id}`).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  public acceptFutureActivity(dto: AcceptFutureActivityDto): Observable<string> {
    return this.http.post<string>(`${this.serverUri}/activities/complete`, dto).pipe(catchError(() => of('')));
  }

  public getInspectionRecommendation(dto: InspectionGradesDto): Observable<string> {
    return this.http
      .post<string>(`${this.serverUri}/activities/future/inspectionText`, dto)
      .pipe(catchError(() => of('')));
  }
}
