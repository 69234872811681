<div [ngClass]="{ disabled: isDisabled }">
  <div
    *ngFor="let button of getButtonOptions()"
    class="badge badge-pill badge-secondary lowHigh"
    style="cursor: pointer"
    [ngClass]="{ selected: button.value == value }"
    (click)="onClick(button.value)"
  >
    {{ button.label }}
  </div>
</div>
