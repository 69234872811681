<app-slide-out-container>
  <div class="h-100">
    <div *ngIf="!loading; else load" class="h-100 flex-col flex-111">
      <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav-tabs header-table mt-0">
        <li [ngbNavItem]="1">
          <a ngbNavLink>Group</a>
          <ng-template ngbNavContent>
            <div class="container-padding h-100">
              <div class="row h-100">
                <div class="col-6 h-100 overflow-auto">
                  <div class="row align-items-end mt-2 m-0">
                    <div class="col pl-0">
                      <div class="mt-2">
                        <e2g-textarea-input
                          id="inp-description"
                          heading="Description"
                          [rows]="2"
                          [(ngModel)]="dataHandler.data.description"
                          maxlength="255"
                          [readonly]="readOnly"
                        ></e2g-textarea-input>
                      </div>
                      <div class="mt-2">
                        <app-br-text
                          heading="Operating Pressure"
                          id="inp-operating-pressure"
                          type="number"
                          [readOnly]="readOnly"
                          propName="operatingPressure"
                        >
                        </app-br-text>
                      </div>

                      <div class="mt-2">
                        <app-br-text
                          heading="Operating Temperature"
                          id="inp-operating-temperature"
                          type="number"
                          [readOnly]="readOnly"
                          propName="operatingTemperature"
                        >
                        </app-br-text>
                      </div>

                      <div class="d-flex justify-content-between mt-2">
                        <span class="fluid-stream-label"> Fluid Stream </span>
                        <app-fluid
                          (onNewFluidStream)="refreshFluidStreams($event)"
                          (onEditFluidStream)="refreshFluidStreams($event)"
                          (onDeleteFluidStream)="onDeleteFluidStream()"
                          [readOnly]="readOnly"
                          [selectedStream]="selectedStream"
                          [streams]="streams"
                        >
                        </app-fluid>
                      </div>

                      <div class="mt-2 mb-0">
                        <e2g-ng-select-input
                          id="select-stream"
                          [requiredForCalc]="rbi"
                          placeholder="Select Fluid"
                          [options]="streamOptions!"
                          [readonly]="readOnly"
                          [(ngModel)]="dataHandler.data.fluidStreamId"
                          (change)="onStreamChange()"
                          [clearable]="true"
                        >
                        </e2g-ng-select-input>
                      </div>

                      <div class="mt-2">
                        <e2g-text-input
                          heading="Drawing Name"
                          id="inp-drawing-name"
                          maxlength="255"
                          [readonly]="readOnly"
                          [required]="false"
                          [(ngModel)]="dataHandler.data.drawingName"
                        >
                        </e2g-text-input>
                      </div>
                    </div>

                    <div class="col align-self-start">
                      <div class="mt-2">
                        <div class="heading">Insulation</div>
                        <div id="gpl-insulation" class="grouped-list">
                          <div class="grouped-list-item" *ngFor="let uniqueInsuluation of uniqueInsulationNames">
                            <e2g-text-input
                              [disabled]="true"
                              [readonly]="readOnly"
                              [ngModel]="uniqueInsuluation"
                            ></e2g-text-input>
                          </div>
                        </div>
                      </div>

                      <div class="mt-2">
                        <div class="heading">Materials of Construction</div>
                        <div id="gpl-materials" class="grouped-list">
                          <div class="grouped-list-item" *ngFor="let cm of uniqueMaterialNames">
                            <e2g-text-input [disabled]="true" [readonly]="readOnly" [ngModel]="cm"></e2g-text-input>
                          </div>
                        </div>
                      </div>

                      <div class="mt-2 mb-0">
                        <div class="heading">Lining Types</div>
                        <div id="gpl-lining-types" class="grouped-list">
                          <div class="grouped-list-item" *ngFor="let uniqueLining of uniqueLiningNames">
                            <e2g-text-input [disabled]="true" [readonly]="readOnly" [ngModel]="uniqueLining">
                            </e2g-text-input>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mt-2 ml-0 pr-3 mb-0">
                    <e2g-textarea-input
                      id="inp-comments"
                      class="w-100 pr-2"
                      heading="Comments"
                      [rows]="5"
                      [readonly]="readOnly"
                      [maxlength]="512"
                      [(ngModel)]="dataHandler.data.comments"
                    ></e2g-textarea-input>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </li>

        <li [ngbNavItem]="2">
          <a ngbNavLink>CML</a>
          <ng-template ngbNavContent>
            <div class="container-padding h-100">
              <div class="row h-100">
                <div class="col-6 h-100 overflow-auto">
                  <div class="col align-items-end mt-2 m-0">
                    <div *ngFor="let cmlItem of cmlDataHandler.data">
                      <e2g-check-input
                        [(ngModel)]="cmlItem.checked"
                        [readonly]="readOnly"
                        [heading]="cmlItem.name"
                        [ngClass]="{ associatedWithOtherCircuit: cmlItem.isAssociatedWithOtherCircuit }"
                        labelPosition="right"
                        (change)="onCmlChange(cmlItem)"
                      >
                        {{ cmlItem.name }}
                      </e2g-check-input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </li>

        <li [ngbNavItem]="3">
          <a ngbNavLink>Damage Mechanisms</a>
          <ng-template ngbNavContent>
            <div class="container-padding h-100">
              <div class="row h-100">
                <div class="col h-100 overflow-auto">
                  <div class="row m-0 mt-2">
                    <h3 class="title m-0"><b>Internal Thinning</b></h3>
                  </div>

                  <div class="row m-0">
                    <div class="col-3 pl-0">
                      <e2g-ng-select-input
                        id="select-internal-thinning-type"
                        heading="Internal Thinning Type"
                        placeholder="Select Internal Thinning Type"
                        [requiredForCalc]="true"
                        [readonly]="readOnly"
                        [isLoading]="!internalThinningTypeList"
                        [options]="internalThinningTypeList"
                        [(ngModel)]="dataHandler.data.internalThinningType"
                      ></e2g-ng-select-input>
                    </div>

                    <div class="col-3">
                      <app-br-text
                        id="estimated-corrosion-rate"
                        propName="estimatedCorrosionRate"
                        heading="Estimated Corrosion Rate"
                        type="number"
                        [readOnly]="readOnly"
                      ></app-br-text>
                    </div>

                    <div class="col-6 pr-0">
                      <e2g-multi-select-input
                        id="select-thinning-damage-mechanism"
                        heading="Thinning Damage Mechanism(s)"
                        placeholder="Select Thinning Damage Mechanism"
                        [required]="false"
                        [readonly]="readOnly"
                        [isLoading]="!thinningDamageMechanismList"
                        [options]="thinningDamageMechanismList"
                        [(ngModel)]="dataHandler.data.thinningDamageMechanisms"
                      ></e2g-multi-select-input>
                    </div>
                  </div>

                  <div class="row m-0">
                    <div class="col-12 p-0">
                      <hr class="seperator" />
                    </div>
                  </div>

                  <div class="row m-0">
                    <h3 class="title m-0"><b>External Damage</b></h3>
                  </div>

                  <div class="row m-0">
                    {{ externalTypeMessage() }}
                  </div>

                  <div class="row m-0 mt-3">
                    <div class="col-3 pl-0">
                      <e2g-ng-select-input
                        id="select-external-environment"
                        heading="External Environment"
                        placeholder="Select External Environment"
                        [readonly]="readOnly"
                        [required]="false"
                        [isLoading]="!externalEnvironmentList"
                        [options]="externalEnvironmentList"
                        [(ngModel)]="dataHandler.data.externalEnvironment"
                      ></e2g-ng-select-input>
                    </div>
                    <div class="col-3">
                      <div class="row m-0">
                        <label>External Corrosion Rate {{ externalCorrosionRateUom | async }}</label>
                      </div>
                      <div class="row mb-2 m-0">
                        <input
                          class="e2g-radio"
                          type="radio"
                          id="rb-calculated-external-corrosion-rate"
                          name="calculatedOrEstimatedCorrosionRate"
                          [value]="false"
                          [disabled]="readOnly"
                          [(ngModel)]="dataHandler.data.externalCorrosionRateBasis!"
                        />
                        <label for="lbl-calculated-external-corrosion-rate" class="ml-2 mr-3 m-0">Calculated</label>
                        <input
                          class="e2g-radio"
                          type="radio"
                          id="rb-estimated-external-corrosion-rate"
                          name="calculatedOrEstimatedCorrosionRate"
                          [value]="true"
                          [disabled]="readOnly"
                          [(ngModel)]="dataHandler.data.externalCorrosionRateBasis!"
                        />
                        <label for="lbl-estimated-external-corrosion-rate" class="ml-2 m-0">Estimated</label>
                      </div>
                      <div class="row m-0">
                        <e2g-text-input
                          *ngIf="dataHandler.data.externalCorrosionRateBasis === true"
                          class="w-100"
                          heading=""
                          id="inp-calculated-estimated-external-corrosion-rate"
                          maxlength="255"
                          [readonly]="readOnly"
                          [required]="false"
                          [(ngModel)]="dataHandler.data.estimatedExternalCorrosionRate!"
                        >
                        </e2g-text-input>

                        <e2g-text-input
                          *ngIf="dataHandler.data.externalCorrosionRateBasis === false"
                          class="w-100"
                          heading=""
                          id="inp-calculated-external-corrosion-rate"
                          maxlength="255"
                          [readonly]="true"
                          [required]="false"
                          [(ngModel)]="dataHandler.data.calculatedExternalCorrosionRate!"
                        >
                        </e2g-text-input>
                      </div>
                    </div>
                    <div class="col-3 pr-0">
                      <div class="row m-0">
                        <label>External Cracking Susceptibility</label>
                      </div>
                      <div class="row mb-2 m-0">
                        <input
                          class="e2g-radio"
                          type="radio"
                          id="calculated-cracking-susceptibility"
                          name="calculatedOrEstimatedCrackingSusceptibility"
                          [value]="false"
                          [disabled]="readOnly"
                          [(ngModel)]="dataHandler.data.externalCrackingSusceptibilityBasis!"
                        />
                        <label for="lbl-calculated-cracking-susceptibility" class="ml-2 mr-3 m-0">Calculated</label>
                        <input
                          class="e2g-radio"
                          type="radio"
                          id="rb-estimated-cracking-susceptibility"
                          name="calculatedOrEstimatedCrackingSusceptibility"
                          [value]="true"
                          [disabled]="readOnly"
                          [(ngModel)]="dataHandler.data.externalCrackingSusceptibilityBasis!"
                        />
                        <label for="lbl-estimated-cracking-susceptibility" class="ml-2 m-0">Estimated</label>
                      </div>
                      <div class="row m-0">
                        <app-low-to-high-input
                          *ngIf="dataHandler.data.externalCrackingSusceptibilityBasis === true"
                          id="estimated-external-cracking-susceptibility"
                          [(ngModel)]="dataHandler.data.estimatedExternalCrackingSusceptibility!"
                          [disabled]="readOnly"
                          [hasVeryLow]="false"
                        ></app-low-to-high-input>
                      </div>
                    </div>
                  </div>

                  <div class="row m-0">
                    <div class="col-12 p-0">
                      <hr class="seperator" />
                    </div>
                  </div>

                  <div class="row m-0">
                    <h3 class="title m-0"><b>Cracking Susceptibility</b></h3>
                  </div>

                  <div class="row m-0 mb-2">
                    <div class="col-3 pl-0">
                      <div class="row m-0 mb-1">Caustic</div>
                      <div class="row m-0">
                        <app-low-to-high-input
                          id="caustic-cracking-susceptibility"
                          [(ngModel)]="dataHandler.data.causticCrackingSusceptibility!"
                          (ngModelChange)="changeSusceptibility($event, 'Caustic')"
                          [disabled]="readOnly"
                          [hasVeryLow]="false"
                        ></app-low-to-high-input>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="row m-0 mb-1">HIC SOHIC H2S</div>
                      <div class="row m-0">
                        <app-low-to-high-input
                          id="hic-sohic-h2s-cracking-susceptibility"
                          [(ngModel)]="dataHandler.data.hicSohicH2sCrackingSusceptibility!"
                          (ngModelChange)="changeSusceptibility($event, 'HIC SOHIC H2S')"
                          [disabled]="readOnly"
                          [hasVeryLow]="true"
                        ></app-low-to-high-input>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="row m-0 mb-1">Chloride</div>
                      <div class="row m-0">
                        <app-low-to-high-input
                          id="chloride-cracking-susceptibility"
                          [(ngModel)]="dataHandler.data.chlorideCrackingSusceptibility!"
                          (ngModelChange)="changeSusceptibility($event, 'Chloride')"
                          [disabled]="readOnly"
                          [hasVeryLow]="false"
                        ></app-low-to-high-input>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="row m-0 mb-1">HSC HF</div>
                      <div class="row m-0">
                        <app-low-to-high-input
                          id="hsc-hf-cracking-susceptibility"
                          [(ngModel)]="dataHandler.data.hscHfCrackingSusceptibility!"
                          (ngModelChange)="changeSusceptibility($event, 'HSC HF')"
                          [disabled]="readOnly"
                          [hasVeryLow]="false"
                        ></app-low-to-high-input>
                      </div>
                    </div>
                  </div>

                  <div class="row m-0 mb-2">
                    <div class="col-3 pl-0">
                      <div class="row m-0 mb-1">Amine</div>
                      <div class="row m-0">
                        <app-low-to-high-input
                          id="amine-cracking-susceptibility"
                          [(ngModel)]="dataHandler.data.amineCrackingSusceptibility!"
                          (ngModelChange)="changeSusceptibility($event, 'Amine')"
                          [disabled]="readOnly"
                          [hasVeryLow]="false"
                        ></app-low-to-high-input>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="row m-0 mb-1">Carbonate</div>
                      <div class="row m-0">
                        <app-low-to-high-input
                          id="carbonate-cracking-susceptibility"
                          [(ngModel)]="dataHandler.data.carbonateCrackingSusceptibility!"
                          (ngModelChange)="changeSusceptibility($event, 'Carbonate')"
                          [disabled]="readOnly"
                          [hasVeryLow]="false"
                        ></app-low-to-high-input>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="row m-0 mb-1">HIC SOHIC HF</div>
                      <div class="row m-0">
                        <app-low-to-high-input
                          id="hic-sohic-hf-cracking-susceptibility"
                          [(ngModel)]="dataHandler.data.hicSohicHfCrackingSusceptibility!"
                          (ngModelChange)="changeSusceptibility($event, 'HIC SOHIC HF')"
                          [disabled]="readOnly"
                          [hasVeryLow]="false"
                        ></app-low-to-high-input>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="row m-0 mb-1">Other</div>
                      <div class="row m-0">
                        <app-low-to-high-input
                          id="other-cracking-susceptibility"
                          [(ngModel)]="dataHandler.data.otherCrackingSusceptibility!"
                          (ngModelChange)="changeSusceptibility($event, 'Other')"
                          [disabled]="readOnly"
                          [hasVeryLow]="false"
                        ></app-low-to-high-input>
                      </div>
                    </div>
                  </div>

                  <div class="row m-0">
                    <div class="col-3 pl-0">
                      <div class="row m-0 mb-1">SSC H2S</div>
                      <div class="row m-0">
                        <app-low-to-high-input
                          id="ssc-h2s-cracking-susceptibility"
                          [(ngModel)]="dataHandler.data.sscH2sCrackingSusceptibility!"
                          (ngModelChange)="changeSusceptibility($event, 'SSC H2S')"
                          [disabled]="readOnly"
                          [hasVeryLow]="false"
                        ></app-low-to-high-input>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="row m-0 mb-1">Polythionic Acid</div>
                      <div class="row m-0">
                        <app-low-to-high-input
                          id="polythionic-acid-cracking-susceptibility"
                          [(ngModel)]="dataHandler.data.polythionicAcidCrackingSusceptibility!"
                          (ngModelChange)="changeSusceptibility($event, 'Polythionic Acid')"
                          [disabled]="readOnly"
                          [hasVeryLow]="false"
                        ></app-low-to-high-input>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </li>
      </ul>

      <div [ngbNavOutlet]="nav" class="h-100 flex-col"></div>
    </div>
  </div>
  <ng-template #load>
    <div class="loading"></div>
  </ng-template>
</app-slide-out-container>
