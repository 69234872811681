import { ICellRendererParams } from 'ag-grid-community';

export interface IGridCellRbiParams {
  rbiColor: (data: ICellRendererParams) => string;
  rbiRenderType?: RbiRenderType;
}

export enum RbiRenderType {
  rbiIcon = 1,
  circleIcon = 2,
  coloredDataField = 3
}
