import { Injectable } from '@angular/core';
import { E2gSelectOption } from '@equityeng/e2g-ng-ui';
import { filter, Observable, of, switchMap } from 'rxjs';

import { CircuitDialogComponent } from './asset-module/circuits/circuit-dialog/circuit-dialog.component';
import { CircuitAddDialogData } from './asset-module/models/circuit-dialog-data';
import { CircuitDataService } from './circuit-data.service';
import { getNewCircuitDto } from './models/circuit-dto';
import { ComponentRecordDto } from './models/component-record-dto';
import { AssetTypes } from './models/enums/asset-types';
import { BusinessRuleValueList } from './sage-common-module/models/business-rule-value-list';
import { DialogService } from './shared-module/dialog.service';
import { DialogButtons } from './shared-module/models/dialog-buttons';
import { DialogData } from './shared-module/models/dialog-data';
import { DialogResult } from './shared-module/models/dialog-result';
import { getCodeValues, getDefaultDesignCode } from './utilities/design-code-helper';
import { AVERAGE_VALUES, INSULATION_TYPE_VALUES } from './utilities/hardcoded-lists';

@Injectable({
  providedIn: 'root'
})
export class ComponentHelperService {
  public constructor(private dialogService: DialogService, private circuitDataService: CircuitDataService) {}

  public addNewCircuit(name: string, assetId: string, unitId: string): Observable<string | boolean> {
    const formData = {
      name: name,
      assetId: assetId,
      description: ''
    } as CircuitAddDialogData;

    const dialogData: DialogData = {
      title: 'Add Group',
      buttons: DialogButtons.YesCancel,
      component: CircuitDialogComponent,
      componentData: formData,
      yesButtonText: 'Add Group',
      width: '400px'
    } as DialogData;

    return this.dialogService.display(dialogData).pipe(
      filter((dialogResult) => dialogResult === DialogResult.yes),
      switchMap(() => {
        const newCircuit = getNewCircuitDto(unitId, assetId);
        newCircuit.name = formData.name!;
        newCircuit.description = formData.description!;
        return this.circuitDataService.saveCircuit(newCircuit);
      })
    );
  }
}

export const getSharedRbiBusinessRules = (data: ComponentRecordDto): BusinessRuleValueList => {
  return {
    designCode: {
      getValidValues: () => of(getCodeValues(data.assetType, data.compType)),
      getDefaultValue: () => of(getDefaultDesignCode(data)),
      getRequired: () => true
    },
    designPressure: {
      getRequired: () => compTypeCanBeCalculated(data),
      getDefaultValue: () => of(data.eqDesignPressure)
    },
    designTemperature: {
      getRequired: () => compTypeCanBeCalculated(data),
      getDefaultValue: () => of(data.eqDesignTemperature)
    },
    inServiceDate: {
      getDefaultValue: () => of(data.eqInServiceDate),
      getRequired: () => true
    },
    insulationType: {
      getValidValues: () => of(INSULATION_TYPE_VALUES)
    },
    insulationCondition: {
      getValidValues: () => of(AVERAGE_VALUES),
      getDefaultValue: () => of('BELOW AVERAGE')
    },
    insulationComplexity: {
      getValidValues: () => of(AVERAGE_VALUES),
      getDefaultValue: () => of('AVERAGE')
    },
    gffType: {
      getValidValues: () => of(getGffTypeValues(data.assetType!, data.compType!)),
      getDefaultValue: () => of(getGffDefault(data.assetType!, data.compType!))
    },
    estimatedLiquidPercent: {
      getDefaultValue: () => of(getPliqDefault(data.gffType!))
    }
  };
};

export const compTypeCanBeCalculated = (data: ComponentRecordDto): boolean => {
  return (
    data.compType !== 'FITTING' &&
    (data.compType !== 'NOZZLE' || data.useAdvancedCalculation) &&
    data.compType !== 'COURSE' &&
    data.compType !== 'FLOOR' &&
    data.compType !== 'ROOF' &&
    data.compType !== 'ANNULAR PLATE'
  );
};

const getGffTypeValues = (assetType: AssetTypes, componentType: string): Array<E2gSelectOption> => {
  switch (assetType) {
    case AssetTypes.HorizontalVessel:
      return [
        { value: 'DRUM', label: 'Drum' },
        { value: 'KODRUM', label: 'KO Drum' },
        { value: 'FILTER', label: 'Filter' },
        { value: 'REACTOR', label: 'Reactor' }
      ];
    case AssetTypes.VerticalVessel:
      return [
        { value: 'COLTOP', label: 'Column Top' },
        { value: 'COLMID', label: 'Column Middle' },
        { value: 'COLBOT', label: 'Column Bottom' },
        { value: 'DRUM', label: 'Drum' },
        { value: 'KODRUM', label: 'KO Drum' },
        { value: 'FILTER', label: 'Filter' },
        { value: 'REACTOR', label: 'Reactor' }
      ];
    case AssetTypes.ShellTubeHeatExchanger:
      return [
        { value: 'HEXSS', label: 'Shellside' },
        { value: 'HEXTS', label: 'Tubeside' }
      ];
    case AssetTypes.AirCooler:
      switch (componentType) {
        case 'HEADER BOX':
        case 'NOZZLE':
          return [{ value: 'FINFAN', label: 'Air Cooler Header Box' }];
        case 'TUBE':
          return [{ value: 'FINFANTUBE', label: 'Air Cooler Tube' }];
        default:
          return [{ value: '', label: '' }];
      }
    case AssetTypes.Sphere:
      return [
        { value: 'DRUM', label: 'Drum' },
        { value: 'KODRUM', label: 'KO Drum' },
        { value: 'REACTOR', label: 'Reactor' }
      ];
    case AssetTypes.StorageTank:
      switch (componentType) {
        case 'ANNULAR PLATE':
          return [{ value: 'TANKBOTTOMEDGE', label: 'Tank Bottom Edge' }];
        case 'FLOOR':
          return [{ value: 'TANKBOTTOM', label: 'Tank Bottom' }];
        default:
          return [{ value: 'COURSE', label: 'Tank Course' }];
      }
    case AssetTypes.PipingSystem:
      return [{ value: 'PIPE', label: 'Pipe or Tube' }];
    case AssetTypes.FiredHeater:
      return [
        { value: 'CONTUBE', label: 'Con. Tube' },
        { value: 'RADTUBE', label: 'Rad. Tube' }
      ];
    case AssetTypes.Boiler:
      switch (componentType) {
        case 'BOILER TUBE':
          return [{ value: 'PIPE', label: 'Boiler Tube' }];
        default:
          return [{ value: 'DRUM', label: 'Drum' }];
      }
    case AssetTypes.Compressor:
      return [
        { value: 'COMPC', label: 'Compressor' },
        { value: 'COMPR', label: 'R. Compressor' }
      ];
    case AssetTypes.Pump:
      return [
        { value: 'PUMP1S', label: '1S Pump' },
        { value: 'PUMP2S', label: '2S Pump' },
        { value: 'PUMPR', label: 'R. Pump' }
      ];
    default:
      return [];
  }
};

const getGffDefault = (assetType: AssetTypes, componentType: string): string => {
  switch (assetType) {
    case AssetTypes.AirCooler:
      return componentType === 'TUBE' ? 'FINFANTUBE' : 'FINFAN';
    case AssetTypes.Boiler:
      return componentType === 'BOILER TUBE' ? 'PIPE' : 'DRUM';
    case AssetTypes.FiredHeater:
      return 'RADTUBE';
    case AssetTypes.PipingSystem:
      return 'PIPE';
    case AssetTypes.Compressor:
      return 'COMPR';
    case AssetTypes.Pump:
      return 'PUMPR';
    case AssetTypes.ShellTubeHeatExchanger:
      return 'HEXSS';
    case AssetTypes.StorageTank:
      switch (componentType) {
        case 'FLOOR':
          return 'TANKBOTTOM';
        case 'ANNULAR PLATE':
          return 'TANKBOTEDGE';
        default:
          return 'COURSE';
      }
    case AssetTypes.VerticalVessel:
      return 'COLMID';
    case AssetTypes.HorizontalVessel:
    case AssetTypes.Sphere:
    default:
      return 'DRUM';
  }
};

const getPliqDefault = (gffType: string): number => {
  switch (gffType) {
    case 'DRUM':
    case 'HEXSS':
    case 'FILTER':
      return 50;
    case 'KODRUM':
      return 10;
    case 'REACTOR':
      return 15;
    case 'COLTOP':
    case 'COLMID':
    case 'HEXTS':
      return 25;
    case 'COLBOT':
      return 37;
    default:
      return 100;
  }
};
