<div *ngIf="!loading; else load" class="row h-100 mr-0">
  <div class="col-6 flex-fill">
    <div class="row align-items-end mt-2 mb-2 m-0">
      <div class="col-6 pl-0">
        <e2g-ng-select-input
          id="select-damage-monitoring-location-status"
          heading="Status"
          placeholder="Select Status"
          [disabled]="disableStatus()"
          [required]="false"
          [options]="statusList!"
          [(ngModel)]="dataHandler.data.status"
          [readonly]="readOnly"
        ></e2g-ng-select-input>
      </div>

      <div class="col-6 pr-0">
        <app-evaluation-date-field [evaluationDate]="dataHandler.data.evaluationDate"></app-evaluation-date-field>
      </div>
    </div>

    <div class="form-section-header my-1">DML Information</div>

    <div class="row align-items-end mb-2 m-0">
      <div class="col-12 p-0">
        <e2g-ng-select-input
          id="select-damage-monitoring-location-component"
          heading="Component"
          placeholder="Select Component"
          [disabled]="false"
          [required]="true"
          [options]="componentsOptions!"
          [(ngModel)]="dataHandler.data.componentId"
          (change)="componentChange()"
          [readonly]="readOnly"
        ></e2g-ng-select-input>
      </div>
    </div>

    <div *ngIf="dataHandler.data.componentId">
      <div class="row align-items-end mb-2 m-0">
        <div class="col-12 p-0">
          <e2g-ng-select-input
            id="select-damage-monitoring-location-assessment-level"
            heading="API 579 Assessment"
            placeholder="Select API 579 Assessment"
            [disabled]="false"
            [required]="true"
            [options]="assessmentLevelList!"
            [(ngModel)]="dataHandler.data.assessmentLevel"
            [readonly]="readOnly"
          ></e2g-ng-select-input>
        </div>
      </div>

      <div class="row align-items-end mb-0 m-0">
        <div class="col-12 p-0">
          <hr class="seperator" />
        </div>
      </div>

      <div *ngIf="dataHandler.data.componentId">
        <div class="row align-items-end mb-2 m-0">
          <div class="col-12 p-0">
            <e2g-ng-select-input
              id="select-damage-monitoring-location-weld-type"
              heading="Weld Type"
              placeholder="Select Weld Type"
              [disabled]="false"
              [required]="true"
              [options]="weldTypeList!"
              [(ngModel)]="dataHandler.data.weldType"
              [readonly]="readOnly"
            ></e2g-ng-select-input>
          </div>
        </div>

        <div class="row align-items-end mb-0 m-0">
          <div class="col-12 p-0">
            <hr class="seperator" />
          </div>
        </div>

        <div class="col p-0 mb-2">
          <h3 class="title m-0"><b>Operating Conditions Grid</b></h3>
        </div>

        <div class="d-flex mb-2 m-0">
          <button
            *ngIf="!readOnly"
            id="btnAdd"
            class="btn e2g-btn-outline-primary"
            (click)="addCreepOperatingConditionsData()"
            [disabled]="false"
          >
            Add Row
          </button>
          <button
            *ngIf="!readOnly"
            id="btnDelete"
            class="btn e2g-btn-outline-primary ml-2"
            (click)="deleteCreepOperatingConditionsData()"
            [disabled]="!rowSelected()"
          >
            Delete Row
          </button>

          <app-download-spreadsheet
            class="ml-auto"
            (onDownload)="gridOptions.downloadExcelFile!()"
          ></app-download-spreadsheet>
        </div>
        <div class="d-flex m-0 mb-2">
          <e2g-ag-grid
            #creepOperatingConditionsDataGrid
            class="w-100"
            [options]="gridOptions"
            [data]="dataHandler.data.creepOperatingConditionsData"
          ></e2g-ag-grid>
        </div>

        <div class="row align-items-end mb-0 m-0">
          <div class="col-12 p-0">
            <hr class="seperator" />
          </div>
        </div>

        <div class="col p-0 mb-2">
          <e2g-textarea-input
            id="inp-comments"
            class="w-100 pr-2"
            heading="Comments"
            [rows]="5"
            [readonly]="readOnly"
            [maxlength]="512"
            [(ngModel)]="dataHandler.data.comments"
          ></e2g-textarea-input>
        </div>
      </div>
    </div>
    <div class="col-6 flex-col flex-fill my-2"></div>
  </div>
</div>

<ng-template #load>
  <div class="loading"></div>
</ng-template>
