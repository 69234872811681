<div class="h-100">
  <div class="row">
    <div class="col-sm-6">
      <div>
        <e2g-multi-select-input
          heading="Plant"
          id="select-plants"
          [(ngModel)]="selectedPlants"
          [isLoading]="allPlants === undefined"
          [options]="allPlants"
          [required]="true"
          [displayLimit]="displayLimit"
          (change)="onPlantChange($event)"
          [isSelectAll]="true"
        >
        </e2g-multi-select-input>
      </div>
      <div class="mt-2">
        <e2g-multi-select-input
          heading="Unit"
          id="select-units"
          [(ngModel)]="selectedUnits"
          [isLoading]="filteredUnits === undefined"
          [options]="filteredUnits"
          [displayLimit]="displayLimit"
          [required]="true"
          [isSelectAll]="true"
        >
        </e2g-multi-select-input>
      </div>
      <div class="mt-2">
        <e2g-multi-select-input
          heading="Asset Type"
          id="select-asset-types"
          [(ngModel)]="formData.assetTypeIds"
          [options]="assetTypeList"
          [displayLimit]="displayLimit"
          [isSelectAll]="true"
        >
        </e2g-multi-select-input>
      </div>
      <div class="mt-2">
        <e2g-date-input
          heading="Start Date"
          id="inp-start-date"
          placeholder="mm/dd/yyyy"
          [(ngModel)]="formData.startDate"
        ></e2g-date-input>
      </div>

      <div class="mt-2">
        <e2g-date-input
          heading="End Date"
          id="inp-end-date"
          placeholder="mm/dd/yyyy"
          [(ngModel)]="formData.endDate"
        ></e2g-date-input>
      </div>
    </div>

    <div class="col-sm-6">
      <div>
        <e2g-input-heading heading="Report Type"></e2g-input-heading>
        <mat-radio-group [(ngModel)]="formData.reportType">
          <mat-radio-button id="rb-report-type-thickness" [value]="dueDateReportTypes.Thickness"
            >Thickness</mat-radio-button
          >
          <mat-radio-button id="rb-report-type-activity" [value]="dueDateReportTypes.Activity"
            >Activity</mat-radio-button
          >
        </mat-radio-group>
      </div>
      <div *ngIf="formData.reportType === dueDateReportTypes.Activity" class="mt-2">
        <e2g-multi-select-input
          heading="Activity Type"
          id="select-activity-types"
          [isLoading]="!activityTypeList"
          [(ngModel)]="selectedActivityTypes"
          [options]="activityTypeList!"
          [displayLimit]="displayLimit"
          [required]="true"
          [isSelectAll]="true"
        >
        </e2g-multi-select-input>
      </div>
      <div class="mt-2">
        <e2g-input-heading heading="Below Retirement"></e2g-input-heading>
        <mat-radio-group [(ngModel)]="formData.belowRetirement">
          <mat-radio-button id="rb-below-retirement-include" [value]="BelowRetirements.Include"
            >Include</mat-radio-button
          >
          <mat-radio-button id="rb-below-retirement-omit" [value]="BelowRetirements.Omit">Omit</mat-radio-button>
          <mat-radio-button id="rb-below-retirement-bro" [value]="BelowRetirements.BelowRetirementOnly"
            >Below Retirement Only</mat-radio-button
          ></mat-radio-group
        >
      </div>
      <div *ngIf="formData.reportType === dueDateReportTypes.Thickness" class="mt-2">
        <e2g-input-heading heading="Report Level"></e2g-input-heading>
        <mat-radio-group [(ngModel)]="formData.reportLevel">
          <mat-radio-button id="rb-report-level-asset" [value]="reportLevelTypes.Asset">Asset</mat-radio-button>
          <mat-radio-button id="rb-report-level-cml" [value]="reportLevelTypes.CML">CML</mat-radio-button>
        </mat-radio-group>
      </div>
      <div
        *ngIf="formData.reportType === dueDateReportTypes.Thickness && formData.reportLevel === reportLevelTypes.CML"
        class="mt-2"
      >
        <e2g-input-heading heading="Inspection Driver" [required]="true"></e2g-input-heading>
        <div class="d-flex flex-row">
          <e2g-check-input
            id="check-max-interval"
            heading="Maximum Interval"
            labelPosition="right"
            [(ngModel)]="isMaxInterval"
          ></e2g-check-input>
          <div class="ml-4">
            <e2g-check-input
              id="check-half-life"
              heading="Half Life"
              labelPosition="right"
              [(ngModel)]="isHalfLife"
            ></e2g-check-input>
          </div>
          <div class="ml-4">
            <e2g-check-input
              id="check-specifed"
              heading="Specified"
              labelPosition="right"
              [(ngModel)]="isSpecifiedDate"
            ></e2g-check-input>
          </div>
        </div>
      </div>
      <div class="mt-2">
        <e2g-input-heading heading="Export Type"></e2g-input-heading>
        <mat-radio-group [(ngModel)]="formData.reportFileType">
          <mat-radio-button id="rb-export-pdf" [value]="exportTypes.PDF">PDF</mat-radio-button>
          <mat-radio-button id="rb-export-spreadsheet" [value]="exportTypes.Spreadsheet">Spreadsheet</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  </div>
</div>
