export interface BaseNameDialogData {
  name: string;
  dialogType: DialogType;
}

export interface NameDialogData extends BaseNameDialogData {
  invalidNames?: Array<string>;
  allowSameName?: boolean;
}

export enum DialogType {
  Plant = 'Plant',
  Unit = 'Unit',
  Asset = 'Asset',
  Component = 'Component',
  CML = 'CML',
  CMLs = 'CMLs',
  Group = 'Group',
  Finding = 'Finding',
  Activity = 'Activity',
  Reading = 'Reading',
  DML = 'DML',
  PRD = 'PRD',
  Module = 'Module',
  Folder = 'Folder',
  Attachment = 'Attachment',
  Role = 'Role'
}
