import { Observable, Subject } from 'rxjs';

export class SlideOutRef<R = unknown, C = unknown> {
  public componentInstance!: C;
  public readonly closed: Observable<R | undefined> = new Subject<R | undefined>();

  public close<R>(data?: R | unknown): void {
    (this.closed as Subject<R | unknown>).next(data);
  }
}
