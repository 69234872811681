import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppSettingsService } from '../app-settings/app-settings.service';
import { ReleaseNoteAreaDto } from './models/release-note-area-dto';
import { ReleaseNoteDto } from './models/release-note-dto';
import { ReleaseNoteUpdateDto } from './models/release-note-upate-dto';

@Injectable({
  providedIn: 'root'
})
export class ReleaseNotesDataService {
  private serverUri: string;

  public constructor(private http: HttpClient, private settingsService: AppSettingsService) {
    this.serverUri = this.settingsService.settings.apiUri as string;
  }

  public getReleaseNoteAreas(): Observable<Array<ReleaseNoteAreaDto>> {
    return this.http.get<Array<ReleaseNoteAreaDto>>(`${this.serverUri}/release-notes/areas`, {});
  }

  public getReleaseNotes(): Observable<Array<ReleaseNoteDto>> {
    return this.http.get<Array<ReleaseNoteDto>>(`${this.serverUri}/release-notes`, {});
  }

  public getReleaseNoteDates(): Observable<Array<Date>> {
    return this.http.get<Array<Date>>(`${this.serverUri}/release-notes/date`, {});
  }

  public saveReleaseNotes(data: Array<ReleaseNoteUpdateDto>): Observable<void> {
    const body = JSON.stringify(data);
    return this.http.post<void>(`${this.serverUri}/release-notes`, body);
  }
}
