import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { CalcUpdateDto, PlantManagerImpactedIdsDto } from '../models/calculation-impacted-ids-dto';

@Injectable({
  providedIn: 'root'
})
export class RefreshService {
  public updates: Observable<PlantManagerImpactedIdsDto> = new Subject<PlantManagerImpactedIdsDto>();
  public calcUpdates: Observable<CalcUpdateDto> = new Subject<CalcUpdateDto>();
  //Modify when more than just calculations triggering updates
  public triggerDataUpdate(calcUpdate: CalcUpdateDto): void {
    (this.calcUpdates as Subject<CalcUpdateDto>).next(calcUpdate);
    if (calcUpdate.impactedIds) {
      (this.updates as Subject<PlantManagerImpactedIdsDto>).next(calcUpdate.impactedIds);
    }
  }

  // public getImpactedIdFilter(item: CalculationIdTypes, id: string): MonoTypeOperatorFunction<UpdateData> {
  //   return filter((updateData: UpdateData) => {
  //     if (updateData.impactedIds == undefined) {
  //       return false;
  //     }
  //     switch (item) {
  //       case CalculationIdTypes.Asset:
  //         return updateData.impactedIds.assetIds.includes(id);
  //       case CalculationIdTypes.Component:
  //         return updateData.impactedIds.componentIds.includes(id);
  //       case CalculationIdTypes.Cml:
  //         return updateData.impactedIds.cmlIds.includes(id);
  //       case CalculationIdTypes.Activity:
  //         return updateData.impactedIds.activityIds.includes(id);
  //     }
  //   });
  // }

  // public getImpactedIdsFilter(item: CalculationIdTypes, ids: Array<string>): MonoTypeOperatorFunction<UpdateData> {
  //   return filter((updateData: UpdateData) => {
  //     if (updateData.impactedIds == undefined) {
  //       return false;
  //     }
  //     switch (item) {
  //       case CalculationIdTypes.Asset:
  //         return updateData.impactedIds.assetIds.some(id => ids.includes(id));
  //       case CalculationIdTypes.Component:
  //         return updateData.impactedIds.componentIds.some(id => ids.includes(id));
  //       case CalculationIdTypes.Cml:
  //         return updateData.impactedIds.cmlIds.some(id => ids.includes(id));
  //       case CalculationIdTypes.Activity:
  //         return updateData.impactedIds.activityIds.some(id => ids.includes(id));
  //     }
  //   });
  // }
}
