<div *ngIf="!loading; else load">
  <div class="row mb-4 mt-2">
    <div class="col-12">
      <div class="card h-100">
        <h4 class="card-header mb-0">Dent-Gouge Material Limitations</h4>
        <div class="card-body">
          <div class="row">
            <div class="col-6">
              <div class="d-flex align-items-middle">
                <div class="sage-label">Material Yield Stress :</div>
                <div
                  id="materialYieldStress"
                  class="sage-value"
                  [innerHTML]="toFixedFourFormater(dataHandler.data.materialYieldStress!)"
                ></div>
                <div class="sage-units">{{ materialYieldStressUom | async }}</div>
              </div>
              <div class="d-flex align-items-middle">
                <div class="sage-label">Maximum Yield Stress :</div>
                <div
                  id="maximumYieldStress"
                  class="sage-value"
                  [innerHTML]="toFixedFourFormater(dataHandler.data.maximumYieldStress!)"
                ></div>
                <div class="sage-units">{{ maximumYieldStressUom | async }}</div>
              </div>
              <div class="d-flex align-items-middle">
                <div class="sage-label">Material Tensile Stress :</div>
                <div
                  id="materialTensileStress"
                  class="sage-value"
                  [innerHTML]="toFixedFourFormater(dataHandler.data.materialTensileStress!)"
                ></div>
                <div class="sage-units">{{ materialTensileStressUom | async }}</div>
              </div>
              <div class="d-flex align-items-middle">
                <div class="sage-label">Maximum Tensile Stress :</div>
                <div
                  id="maximumTensileStress"
                  class="sage-value"
                  [innerHTML]="toFixedFourFormater(dataHandler.data.maximumTensileStress!)"
                ></div>
                <div class="sage-units">{{ maximumTensileStressUom | async }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-4 mt-2">
    <div class="col-12">
      <div class="card h-100">
        <h4 class="card-header mb-0">Dent-Gouge Geometric Limitations</h4>
        <div class="card-body">
          <div class="row">
            <div class="col-6">
              <div class="d-flex align-items-middle">
                <div class="sage-label">Shell Outside Diameter :</div>
                <div
                  id="shellOutsideDiameter"
                  class="sage-value"
                  [innerHTML]="toFixedFourFormater(dataHandler.data.shellOutsideDiameter!)"
                ></div>
                <div class="sage-units">{{ shellOutsideDiameterUom | async }}</div>
              </div>

              <div class="d-flex align-items-middle">
                <div class="sage-label">Minimum Outside Diameter :</div>
                <div
                  id="minimumOutsideDiameter"
                  class="sage-value"
                  [innerHTML]="toFixedFourFormater(dataHandler.data.minimumOutsideDiameter!)"
                ></div>
                <div class="sage-units">{{ minimumOutsideDiameterUom | async }}</div>
              </div>

              <div class="d-flex align-items-middle">
                <div class="sage-label">Maximum Outside Diameter :</div>
                <div
                  id="maximumOutsideDiameter"
                  class="sage-value"
                  [innerHTML]="toFixedFourFormater(dataHandler.data.maximumOutsideDiameter!)"
                ></div>
                <div class="sage-units">{{ maximumOutsideDiameterUom | async }}</div>
              </div>

              <div class="d-flex align-items-middle">
                <div class="sage-label">Current Thickness :</div>
                <div
                  id="currentThickness"
                  class="sage-value"
                  [innerHTML]="toFixedFourFormater(dataHandler.data.currentThickness!)"
                ></div>
                <div class="sage-units">{{ currentThicknessUom | async }}</div>
              </div>

              <div class="d-flex align-items-middle">
                <div class="sage-label">Minimum Current Thickness :</div>
                <div
                  id="minimumCurrentThickness"
                  class="sage-value"
                  [innerHTML]="toFixedFourFormater(dataHandler.data.minimumCurrentThickness!)"
                ></div>
                <div class="sage-units">{{ minimumCurrentThicknessUom | async }}</div>
              </div>

              <div class="d-flex align-items-middle">
                <div class="sage-label">Maximum Current Thickness :</div>
                <div
                  id="maximumCurrentThickness"
                  class="sage-value"
                  [innerHTML]="toFixedFourFormater(dataHandler.data.maximumCurrentThickness!)"
                ></div>
                <div class="sage-units">{{ maximumCurrentThicknessUom | async }}</div>
              </div>

              <div class="d-flex align-items-middle">
                <div class="sage-label">Diameter to Thickness Ratio :</div>
                <div
                  id="diameterToThicknessRatio"
                  class="sage-value"
                  [innerHTML]="toFixedFourFormater(dataHandler.data.diameterToThicknessRatio!)"
                ></div>
                <div class="sage-units">{{ diameterToThicknessRatioUom | async }}</div>
              </div>

              <div class="d-flex align-items-middle">
                <div class="sage-label">Minimum DOT Ratio :</div>
                <div
                  id="minimumDotRatio"
                  class="sage-value"
                  [innerHTML]="toFixedFourFormater(dataHandler.data.minimumDotRatio!)"
                ></div>
                <div class="sage-units">{{ minimumDotRatioUom | async }}</div>
              </div>
            </div>

            <div class="col-6">
              <div class="d-flex align-items-middle">
                <div class="sage-label">Maximum DOT Ratio :</div>
                <div
                  id="maximumDotRatio"
                  class="sage-value"
                  [innerHTML]="toFixedFourFormater(dataHandler.data.maximumDotRatio!)"
                ></div>
                <div class="sage-units">{{ maximumDotRatioUom | async }}</div>
              </div>

              <div class="d-flex align-items-middle">
                <div class="sage-label">Dent Radius :</div>
                <div
                  id="dentRadius"
                  class="sage-value"
                  [innerHTML]="toFixedFourFormater(dataHandler.data.dentRadius!)"
                ></div>
                <div class="sage-units">{{ dentRadiusUom | async }}</div>
              </div>

              <div class="d-flex align-items-middle">
                <div class="sage-label">Minimum Dent Radius :</div>
                <div
                  id="minimumDentRadius"
                  class="sage-value"
                  [innerHTML]="toFixedFourFormater(dataHandler.data.minimumDentRadius!)"
                ></div>
                <div class="sage-units">{{ minimumDentRadiusUom | async }}</div>
              </div>

              <div class="d-flex align-items-middle">
                <div class="sage-label">Corroded Minimum Measured Thickness :</div>
                <div
                  id="corrodedMinimumMeasuredThickness"
                  class="sage-value"
                  [innerHTML]="toFixedFourFormater(dataHandler.data.corrodedMinimumMeasuredThickness!)"
                ></div>
                <div class="sage-units">{{ corrodedMinimumMeasuredThicknessUom | async }}</div>
              </div>

              <div class="d-flex align-items-middle">
                <div class="sage-label">Thickness Limit - Structural :</div>
                <div
                  id="thicknessLimitStructural"
                  class="sage-value"
                  [innerHTML]="toFixedFourFormater(dataHandler.data.thicknessLimitStructural!)"
                ></div>
                <div class="sage-units">{{ thicknessLimitStructuralUom | async }}</div>
              </div>

              <div class="d-flex align-items-middle">
                <div class="sage-label">Pressurized Dent Depth :</div>
                <div
                  id="pressurizedDentDepth"
                  class="sage-value"
                  [innerHTML]="toFixedFourFormater(dataHandler.data.pressurizedDentDepth!)"
                ></div>
                <div class="sage-units">{{ pressurizedDentDepthUom | async }}</div>
              </div>

              <div *ngIf="dataHandler.data.assessmentLevel === 1" class="d-flex align-items-middle">
                <div class="sage-label">Gouge Depth to Thickness Ratio :</div>
                <div
                  id="gougeDepthToThicknessRatio"
                  class="sage-value"
                  [innerHTML]="toFixedFourFormater(dataHandler.data.gougeDepthToThicknessRatio!)"
                ></div>
                <div class="sage-units">{{ gougeDepthToThicknessRatioUom | async }}</div>
              </div>

              <div *ngIf="dataHandler.data.assessmentLevel === 1" class="d-flex align-items-middle">
                <div class="sage-label">Maximum Gouge Depth Ratio :</div>
                <div
                  id="maximumGougeDepthRatio"
                  class="sage-value"
                  [innerHTML]="toFixedFourFormater(dataHandler.data.maximumGougeDepthRatio!)"
                ></div>
                <div class="sage-units">{{ maximumGougeDepthRatioUom | async }}</div>
              </div>

              <div *ngIf="dataHandler.data.assessmentLevel === 2" class="d-flex align-items-middle">
                <div class="sage-label">Maximum Allowable Dent Depth :</div>
                <div
                  id="maximumAllowableDentDepth"
                  class="sage-value"
                  [innerHTML]="toFixedFourFormater(dataHandler.data.maximumAllowableDentDepth!)"
                ></div>
                <div class="sage-units">{{ maximumAllowableDentDepthUom | async }}</div>
              </div>

              <div *ngIf="dataHandler.data.assessmentLevel === 2" class="d-flex align-items-middle">
                <div class="sage-label">Gouge Depth :</div>
                <div
                  id="gougeDepth"
                  class="sage-value"
                  [innerHTML]="toFixedFourFormater(dataHandler.data.gougeDepth!)"
                ></div>
                <div class="sage-units">{{ gougeDepthUom | async }}</div>
              </div>

              <div *ngIf="dataHandler.data.assessmentLevel === 2" class="d-flex align-items-middle">
                <div class="sage-label">Maximum Allowable Gouge Depth :</div>
                <div
                  id="maximumAllowableGougeDepth"
                  class="sage-value"
                  [innerHTML]="toFixedFourFormater(dataHandler.data.maximumAllowableGougeDepth!)"
                ></div>
                <div class="sage-units">{{ maximumAllowableGougeDepthUom | async }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="dataHandler.data.assessmentLevel === 2" class="row mb-4 mt-2">
    <div class="col-12">
      <div class="card h-100">
        <h4 class="card-header mb-0">Damage MAWP (Including FCA)</h4>
        <div class="card-body">
          <div class="row">
            <div class="col-6">
              <div class="d-flex align-items-middle">
                <div class="sage-label">Design Pressure :</div>
                <div
                  id="designPressure"
                  class="sage-value"
                  [innerHTML]="toFixedFourFormater(dataHandler.data.designPressure!)"
                ></div>
                <div class="sage-units">{{ designPressureUom | async }}</div>
              </div>
              <div class="d-flex align-items-middle">
                <div class="sage-label">Undamaged MAWP :</div>
                <div
                  id="undamagedMawp"
                  class="sage-value"
                  [innerHTML]="toFixedFourFormater(dataHandler.data.undamagedMawp!)"
                ></div>
                <div class="sage-units">{{ undamagedMawpUom | async }}</div>
              </div>
              <div class="d-flex align-items-middle">
                <div class="sage-label">Damaged MAWP :</div>
                <div
                  id="damagedMawp"
                  class="sage-value"
                  [innerHTML]="toFixedFourFormater(dataHandler.data.damagedMawp!)"
                ></div>
                <div class="sage-units">{{ damagedMawpUom | async }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-4">
    <div class="col-12">
      <div class="card h-100">
        <h4 class="card-header mb-0">Dent-Gouge Assessment Messages</h4>
        <div class="card-body">
          <ng-container *ngFor="let msg of dataHandler.data.dentGougeAssessmentMessages!">
            <p [innerHTML]="parseMarkdown(msg)"></p>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-4">
    <div class="col-12">
      <div class="card h-100">
        <h4 class="card-header mb-0">Dent-Gouge Applicability and Limitations</h4>
        <div class="card-body">
          <ul class="mb-0 p-0 pl-3">
            <li *ngFor="let item of dataHandler.data.dentGougeApplicabilityAndLimitationsMessages!">{{ item }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #load>
  <div class="loading"></div>
</ng-template>
