import { Component, Inject, inject, Input, ViewChild } from '@angular/core';
import { E2gSelectOption } from '@equityeng/e2g-ng-ui';
import { filter, forkJoin, map, Observable, of, switchMap, take, takeUntil, tap } from 'rxjs';
import { AssetDataCache, AssetDataDto } from 'src/app/asset-module/models/asset-data-dto';
import { ComponentSlideoutOption } from 'src/app/asset-module/models/component-slideout-option';
import { IdNameDto } from 'src/app/asset-module/models/id-name-dto';
import { CompDataService } from 'src/app/comp-data.service';
import { EquipmentDto } from 'src/app/models/equipment-dto';
import { CompRbiComponent } from 'src/app/sage-common-module/comp-rbi/comp-rbi.component';
import { NEW_ITEM_KEY } from 'src/app/shared-module/models/new-item-key';
import { AssetFeature, GeneralFeature } from 'src/app/shared-module/permission/permission-models';
import { PermissionService } from 'src/app/shared-module/permission/permission.service';
import { NotificationService } from 'src/app/shared-module/services/notification.service';
import { SLIDE_OUT_DATA } from 'src/app/slide-out-module/slide-out-data-injection-token';
import { enumToSelectOptions } from 'src/app/utilities/enum-helper';
import { circuitMissingFluidMessage } from 'src/app/utilities/validation-messages';

import { CircuitDataService } from '../../circuit-data.service';
import { ComponentHelperService } from '../../component-helper-service';
import { EquipmentDataService } from '../../equipment-data.service';
import { MaterialBusinessRulesService } from '../../moc-module/moc-business-rules';
import { ComponentRecordDto } from '../../models/component-record-dto';
import { ComponentStatuses } from '../../models/enums/component-statuses';
import { EquipmentConfigurationDto } from '../../models/equipment-configuration-dto';
import { OnDestroyBaseComponent } from '../../on-destroy-base-component/on-destroy-base-component';
import { BUSINESS_RULES } from '../../sage-common-module/business-rules-injection-token';
import { BusinessRulesDefinition } from '../../sage-common-module/models/business-rules-definition';
import { SingleDataHandlerDefault } from '../../shared-module/single-data-handler-default';
import { SlideOutContainerService } from '../../slide-out-module/slide-out-container.service';
import { ComponentSlideoutInput } from '../models/component-slideout-input';
import { GroupDetailsDto } from '../models/group-details-dto';
import { CompDetailDataService } from '../services/comp-detail-data.service';

export const CompDetailBaseComponentProviders = [
  MaterialBusinessRulesService // Each subclass will create a new instance to be shared with its descendants
];

@Component({
  template: ''
})
export abstract class CompDetailBaseComponent extends OnDestroyBaseComponent {
  @Input() public refreshComponentData!: Observable<void>;
  @Input() public assetCache!: AssetDataCache;
  @ViewChild('rbiComponent', { static: false }) public rbiComponent!: CompRbiComponent;

  protected slideOutService: SlideOutContainerService = inject(SlideOutContainerService);
  private notificationService: NotificationService = inject(NotificationService);
  private dataService: CompDetailDataService = inject(CompDetailDataService);
  private componentDataService: CompDataService = inject(CompDataService);
  private circuitDataService: CircuitDataService = inject(CircuitDataService);
  protected equipmentDataService: EquipmentDataService = inject(EquipmentDataService);
  private componentHelperService: ComponentHelperService = inject(ComponentHelperService);
  private permissionService: PermissionService = inject(PermissionService);
  private materialBusinessRulesService: MaterialBusinessRulesService = inject(MaterialBusinessRulesService);

  public dataHandler: SingleDataHandlerDefault<ComponentRecordDto>;
  public assetConfig!: EquipmentConfigurationDto;
  public statusList: Array<E2gSelectOption> = enumToSelectOptions(ComponentStatuses);
  public compId: string = '';
  public activeTabId: number = 1;
  public rbiActiveTabId: number = 1;
  public circuitWarnings: Array<string> = [];
  public circuits: Array<GroupDetailsDto> = [];
  public circuitList: Array<E2gSelectOption> = [];

  public loading: boolean = true;
  public diagVisible: boolean = false;
  public rbiEnabled: boolean = false;
  public readOnly: boolean = false;
  public canAddCircuit: boolean = false;
  private dirty: boolean = false;
  public mawpWarnings: Array<string> = [];

  protected editing: boolean = false;

  public constructor(
    @Inject(SLIDE_OUT_DATA) protected slideOutData: ComponentSlideoutInput,
    @Inject(BUSINESS_RULES) protected businessRulesService: BusinessRulesDefinition
  ) {
    super();

    this.slideOutService.nameUpdated.pipe(takeUntil(this.destroy)).subscribe((newName) => {
      this.dataHandler.data.name = newName;
    });

    this.dataHandler = new SingleDataHandlerDefault<ComponentRecordDto>(this.destroy, (dirty: boolean) => {
      this.slideOutService.setDirty(dirty);
      this.dirty = dirty;
    });

    this.businessRulesService.init(this.dataHandler);
    this.materialBusinessRulesService.init(this.dataHandler);

    if (this.slideOutData.showDiags) {
      this.diagVisible = true;
      this.activeTabId = 4;
    } else if (this.slideOutData.startOnRbi) {
      this.activeTabId = 3;
    }
  }

  protected setupComponentData(afterComponentDataLoad?: () => void): void {
    this.refreshComponentData
      .pipe(
        tap(() => this.initializeForm(this.slideOutData.id)),
        switchMap(() => forkJoin(this.loadAssetData())),
        switchMap(() => this.getComponentNamesForAsset(this.slideOutData.assetId)), // We don't want to cache this because on a save it needs to update.
        switchMap(() => this.getComponent(this.slideOutData.id)),
        takeUntil(this.destroy)
      )
      .subscribe(() => {
        this.slideOutService.setAllowRename(this.allowRename());

        this.createCircuitWarnings();

        afterComponentDataLoad?.();

        this.loading = false;
      });
  }

  protected allowRename(): boolean {
    return !this.readOnly;
  }

  private initializeForm(componentId: string): void {
    this.editing = componentId !== NEW_ITEM_KEY;
    this.compId = componentId;
    this.dataHandler.clear();
  }

  protected getNewComponent(): Observable<ComponentRecordDto> {
    return this.equipmentDataService
      .getSingleEquipment(this.slideOutData.assetId)
      .pipe(map((asset: EquipmentDto) => this.setupNewComponent!(asset)));
  }

  protected setupNewComponent?(asset: EquipmentDto): ComponentRecordDto;

  private loadAssetData(): Array<Observable<any>> {
    const useCache: boolean = this.assetCache.data !== undefined;

    if (!useCache) {
      this.assetCache.data = {} as AssetDataDto;
    }

    return [
      this.getPermissions(this.slideOutData.assetId, useCache),
      this.getAssetConfiguration(this.slideOutData.assetId, useCache),
      this.getCircuits(this.slideOutData.assetId, useCache)
    ];
  }

  private getPermissions(assetId: string, useCache: boolean): Observable<void> {
    if (useCache) {
      this.setPermissions(
        this.assetCache.data!.permissions.readOnly,
        this.assetCache.data!.permissions.rbiEnabled,
        this.assetCache.data!.permissions.canAddCircuit
      );

      return of(undefined);
    } else {
      return this.permissionService
        .getPermissions({
          asset: [
            { feature: AssetFeature.Component, assetId },
            { feature: AssetFeature.Circuit, assetId }
          ],
          general: [{ feature: GeneralFeature.Rbi }]
        })
        .pipe(
          tap((permissions) => {
            const readOnly = !permissions.asset[AssetFeature.Component].edit;
            const rbiEnabled = permissions.general[GeneralFeature.Rbi];
            const canAddCircuit = permissions.asset[AssetFeature.Circuit].edit;

            this.setPermissions(readOnly, rbiEnabled, canAddCircuit);

            this.assetCache.data!.permissions = {
              readOnly,
              rbiEnabled,
              canAddCircuit
            };
          }),
          map(() => undefined),
          take(1)
        );
    }
  }

  private setPermissions(readOnly: boolean, rbiEnabled: boolean, canAddCircuit: boolean): void {
    this.readOnly = readOnly;
    this.businessRulesService.readOnly = readOnly;
    this.materialBusinessRulesService.readOnly = readOnly;
    this.rbiEnabled = rbiEnabled;
    this.canAddCircuit = canAddCircuit;
  }

  private getAssetConfiguration(assetId: string, useCache: boolean): Observable<void> {
    const data = useCache
      ? of(this.assetCache.data!.assetConfig)
      : this.equipmentDataService.getEquipmentConfiguration(assetId);

    return data.pipe(
      tap((config) => {
        this.assetConfig = config;
        this.assetCache.data!.assetConfig = config;
        this.rbiEnabled = this.rbiEnabled ? config.rbi ?? false : false;
      }),
      map(() => undefined)
    );
  }

  private getCircuits(assetId: string, useCache: boolean): Observable<void> {
    const data = useCache ? of(this.assetCache.data!.circuits) : this.circuitDataService.getCircuits(assetId);

    return data.pipe(
      tap((circuits) => {
        this.circuits = circuits;
        this.circuitList = circuits.nameIdToE2gSelectOptions();

        this.assetCache.data!.circuits = circuits;
      }),
      map(() => undefined)
    );
  }

  private getComponent(id: string): Observable<any> {
    return (this.editing ? this.dataService.getComponent(id) : this.getNewComponent()).pipe(
      tap((component) => {
        this.handleMawpWarningsAndFormat(component);

        this.dataHandler.setInitialData(component);

        if (this.rbiComponent) {
          this.rbiComponent.refreshCalc(); // Child component.
        }

        setTimeout(() => {
          this.startBusinessRules();
        });
      })
    );
  }

  private startBusinessRules(): void {
    this.businessRulesService.start();
    this.materialBusinessRulesService.start();
  }

  public isDirty(): boolean {
    return this.dirty;
  }

  public setValid(isValid: boolean): void {
    this.slideOutService.setBodyValid(isValid);
  }

  public createCircuitWarnings(): void {
    this.circuitWarnings = [];

    if (
      this.rbiEnabled &&
      this.dataHandler.data.rbi &&
      this.dataHandler.data.circuitId &&
      this.circuits.find((x) => x.id == this.dataHandler.data.circuitId)?.fluidStreamId === undefined
    ) {
      this.circuitWarnings.push(circuitMissingFluidMessage);
    }
  }

  public addNewCircuit(): void {
    this.componentHelperService
      .addNewCircuit(this.dataHandler.data.name!, this.dataHandler.data.assetId!, this.slideOutData.routeData!.unitKey)
      .pipe(
        filter((result) => typeof result === 'string'),
        tap((circuitId) => {
          this.dataHandler.data.circuitId = circuitId as string;
        }),
        switchMap(() => this.getCircuits(this.dataHandler.data.assetId!, false))
      )
      .subscribe();
  }

  private handleMawpWarningsAndFormat(component: ComponentRecordDto): void {
    this.mawpWarnings = [];

    if (component.hasMawpCalculationError) {
      this.mawpWarnings.push('Review nominal thickness and CML readings');
    }

    if (component.maximumAllowableWorkingPressure !== undefined) {
      component.maximumAllowableWorkingPressure = parseFloat(component.maximumAllowableWorkingPressure).toFixed(3);
    }
  }

  protected addOrUpdateHeaderDropdownItem(id: string): void {
    this.slideOutData.optionsData![id] = {
      name: this.dataHandler.data.name,
      assetId: this.dataHandler.data.assetId,
      type: this.dataHandler.data.compType
    } as ComponentSlideoutOption;
  }

  public saveChanges(): Observable<boolean> {
    let dto = {
      ...this.dataHandler.getDataWithEmptyStringsRemoved(),
      defaultProperties: this.dataHandler.getDefaultPropNames()
    } as ComponentRecordDto;

    if (this.modifyDtoBeforeSave) {
      dto = this.modifyDtoBeforeSave(dto);
    }

    return this.dataService.saveData(dto).pipe(
      tap((id: string) => {
        if (!this.editing) {
          this.slideOutData.id = id;
        }

        this.addOrUpdateHeaderDropdownItem(id);

        this.notificationService.showSaveResult(true);
      }),
      map(() => true)
    );
  }

  protected modifyDtoBeforeSave?(dto: ComponentRecordDto): ComponentRecordDto;

  public revertChanges(): void {
    this.dataHandler.revertChanges();
    this.createCircuitWarnings();

    this.afterRevertChanges?.();
  }

  protected afterRevertChanges?(): void;

  private getComponentNamesForAsset(assetId: string): Observable<void> {
    return this.componentDataService.getComponentNamesForAsset(assetId).pipe(
      tap((componentNames: Array<IdNameDto>) => {
        this.slideOutService.setHeaderInvalidNameList(
          componentNames.filter((x) => x.id !== this.slideOutData.id).map((x) => x.name)
        );
      }),
      map(() => undefined),
      takeUntil(this.destroy)
    );
  }
}
