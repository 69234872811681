import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { getTextColor } from 'src/app/utilities/risk-matrix-helper';

import { IGridCellRbiParams, RbiRenderType } from './grid-cell-rbi-params';

@Component({
  selector: 'app-grid-cell-rbi',
  templateUrl: './grid-cell-rbi.component.html',
  styleUrls: ['./grid-cell-rbi.component.css']
})
export class GridCellRbiComponent implements ICellRendererAngularComp {
  public id: string = '';
  public rbiCalculated: boolean = false;
  public rbiColor: string = '';
  public textColor: string = 'var(--interactive-fg-primary-inverse)';
  public RbiRenderTypes = RbiRenderType;
  public rbiRenderType: RbiRenderType = RbiRenderType.rbiIcon;
  public value: string = '';

  public agInit(params: ICellRendererParams & IGridCellRbiParams): void {
    this.setParams(params);
  }

  public refresh(params: ICellRendererParams & IGridCellRbiParams): boolean {
    this.setParams(params);
    return true;
  }

  private getTextColor(bgColor: string): string {
    return getTextColor(bgColor);
  }

  private setParams(params: ICellRendererParams & IGridCellRbiParams): void {
    this.id = params.data.itemId;
    this.rbiColor = params.rbiColor(params);

    if (this.rbiColor !== undefined && this.rbiColor.length > 0) {
      this.rbiCalculated = true;
      this.textColor = this.getTextColor(this.rbiColor);

      if (params.rbiRenderType) {
        this.rbiRenderType = params.rbiRenderType;
      }
    }
  }
}
