import { ColDef, ISetFilterParams, ValueFormatterParams } from 'ag-grid-community';

import { GridCellScraRateComponent } from '../cell-renders/grid-cell-scra-calc/grid-cell-scra-rate.component';
import { scraRateComparer } from '../cell-renders/grid-cell-scra-calc/scra-rate-comparer';
import { assignHeaderAndTooltip } from './build-default';

export function buildScraRateColDef(header: string, field: string): ColDef {
  return {
    ...assignHeaderAndTooltip(header, field),
    cellRenderer: GridCellScraRateComponent,
    comparator: scraRateComparer,
    filterParams: {
      valueFormatter: (params: ValueFormatterParams): string => {
        const scraRate = params.value || '';
        return scraRate === undefined
          ? ''
          : `${scraRate.startChar === undefined ? '' : scraRate.startChar}${scraRate.rate} ${scraRate.code}`;
      }
    } as ISetFilterParams,
    valueFormatter: (params: ValueFormatterParams): string => {
      const scraRate = params.data[params.colDef.field || ''];
      return scraRate === undefined
        ? ''
        : `${scraRate.startChar === undefined ? '' : scraRate.startChar}${scraRate.rate} ${scraRate.code}`;
    }
  };
}
