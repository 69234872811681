import { IRowNode } from 'ag-grid-community';

import { ScraRateData } from './grid-cell-scra-rate.component';

export function scraRateComparer(
  valueA: ScraRateData,
  valueB: ScraRateData,
  nodeA: IRowNode,
  nodeB: IRowNode,
  isInverted: boolean
): number {
  if (!valueA) {
    return isInverted ? -1 : 1;
  }

  if (!valueB) {
    return isInverted ? 1 : -1;
  }

  if (valueA === valueB) {
    return 0;
  }

  //---------------------------------------------------------------------------------------------
  // we can re-enable this if we decide something different about the < char at the beginning
  //---------------------------------------------------------------------------------------------
  // if (valueA.startChar !== undefined && valueB.startChar !== undefined) {
  //   return 0;
  // } else if (valueA.startChar !== undefined || valueB.startChar !== undefined) {
  //   return valueA.startChar !== undefined ? -1 : 1;
  // }
  //---------------------------------------------------------------------------------------------

  const a = parseFloat(valueA.rate);
  const b = parseFloat(valueB.rate);

  if (a === b) {
    return 0;
  }

  return a < b ? -1 : 1;
}
