import { PlotlyColors } from './plotly-colors';

export class PlotlyCommonStyles {
  public static tickfont = {
    family: 'Roboto',
    size: 12,
    color: PlotlyColors.fgGrey
  };

  public static defaultAxis = {
    tickfont: this.tickfont,
    gridcolor: PlotlyColors.gridLines,
    zerolinecolor: PlotlyColors.fgGrey
  };
}
