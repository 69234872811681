import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AppSettingsService } from '../app-settings/app-settings.service';
import { CommentTypes } from '../models/enums/comment-types';
import { CommentsDto } from './models/comments-dto';

@Injectable({
  providedIn: 'root'
})
export class CommentsDataService {
  private serverUri: string;

  public constructor(private http: HttpClient, private settingsService: AppSettingsService) {
    this.serverUri = this.settingsService.settings.apiUri as string;
  }

  public getComments(parentId: string, type: CommentTypes): Observable<Array<CommentsDto>> {
    return this.http.get<Array<CommentsDto>>(`${this.serverUri}/comments/${parentId}/type/${type}`, {});
  }

  public saveComments(parentId: string, type: CommentTypes, data: Array<CommentsDto>): Observable<boolean> {
    const body = JSON.stringify(data);
    return this.http.post(`${this.serverUri}/comments/${parentId}/type/${type}`, body).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }
}
