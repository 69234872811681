import { dateOnly } from '@equityeng/e2g-ng-ui';
import { RowClassRules } from 'ag-grid-community';
import { LATEST } from 'src/app/shared/constants/date-constants';

export function rowDrivingCmlRule(
  selectedDateFunc: () => dateOnly,
  lowestRetirementDateFunc: () => string | undefined,
  bHighlightDrivingCmlFunc: () => boolean
): RowClassRules {
  return {
    'row-driving-cml': (params): boolean => {
      const retirementDateValue = params.api.getCellValue({ colKey: 'retirementDate', rowNode: params.node });
      return (
        retirementDateValue === lowestRetirementDateFunc() &&
        retirementDateValue !== undefined &&
        bHighlightDrivingCmlFunc() &&
        selectedDateFunc() === LATEST
      );
    }
  };
}
