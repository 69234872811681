<div class="d-flex justify-content-between align-items-center pl-4 pr-4 slideout-footer">
  <div class="d-flex align-items-center">
    <button id="btn-cancel" class="btn e2g-btn-outline-primary" (click)="onCancel()">Close</button>
    <div *ngIf="extraLeftItems as leftItems" class="d-flex flex-row align-items-center ml-2">
      <ng-container *ngFor="let template of leftItems">
        <ng-container *ngTemplateOutlet="template"></ng-container>
      </ng-container>
    </div>
  </div>

  <div class="d-flex align-items-center">
    <div *ngIf="extraCenterItems as centerItems" class="d-flex flex-row align-items-center">
      <ng-container *ngFor="let template of centerItems">
        <ng-container *ngTemplateOutlet="template"></ng-container>
      </ng-container>
    </div>
  </div>

  <div class="d-flex align-items-center">
    <div *ngIf="!saving && extraRightItems as rightItems" class="d-flex flex-row align-items-center mr-2">
      <ng-container *ngFor="let template of rightItems">
        <ng-container *ngTemplateOutlet="template"></ng-container>
      </ng-container>
    </div>
    <button
      *ngIf="(dirty | async) && !(new | async)"
      id="btn-revert"
      class="btn e2g-btn-outline-primary mr-2"
      (click)="onRevertChanges()"
      [disabled]="saving"
    >
      Revert
    </button>
    <button
      *ngIf="(dirty | async) || (new | async)"
      id="btn-save"
      class="btn e2g-btn-solid-primary"
      (click)="onSave()"
      [disabled]="!(valid | async) || saving"
    >
      {{ saveButtonText }}
    </button>
  </div>
</div>
