import { Injectable, TemplateRef } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  public extraEndMenuItems: Observable<Array<TemplateRef<any>>> = new BehaviorSubject<Array<TemplateRef<any>>>([]);
  public extraStartMenuItems: Observable<Array<TemplateRef<any>>> = new BehaviorSubject<Array<TemplateRef<any>>>([]);

  public addEndMenuItem(template: TemplateRef<any>): void {
    const values = this.getEndItemsObservable().value;

    if (!values.includes(template)) {
      values.push(template);
      this.getEndItemsObservable().next(values);
    }
  }

  public removeEndMenuItem(template: TemplateRef<any>): void {
    const values = this.getEndItemsObservable().value;

    const index = values.indexOf(template);
    if (index > -1) {
      values.splice(index, 1);
      this.getEndItemsObservable().next(values);
    }
  }

  public addStartMenuItem(template: TemplateRef<any>): void {
    const values = this.getStartItemsObservable().value;

    if (!values.includes(template)) {
      values.push(template);
      this.getStartItemsObservable().next(values);
    }
  }

  public removeStartMenuItem(template: TemplateRef<any>): void {
    const values = this.getStartItemsObservable().value;

    const index = values.indexOf(template);
    if (index > -1) {
      values.splice(index, 1);
      this.getStartItemsObservable().next(values);
    }
  }

  private getStartItemsObservable(): BehaviorSubject<Array<TemplateRef<any>>> {
    return this.extraStartMenuItems as BehaviorSubject<Array<TemplateRef<any>>>;
  }

  private getEndItemsObservable(): BehaviorSubject<Array<TemplateRef<any>>> {
    return this.extraEndMenuItems as BehaviorSubject<Array<TemplateRef<any>>>;
  }
}
