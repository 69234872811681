import { Injectable } from '@angular/core';
import { E2gSelectOption } from '@equityeng/e2g-ng-ui';
import { Observable, of } from 'rxjs';

import { compTypeCanBeCalculated, getSharedRbiBusinessRules } from '../component-helper-service';
import { BusinessRulesServiceBase } from '../sage-common-module/BusinessRulesServiceBase';
import { BusinessRuleValueList } from '../sage-common-module/models/business-rule-value-list';
import { BusinessRulesDefinition } from '../sage-common-module/models/business-rules-definition';
import { FunctionalDefaultRequestDto } from '../sage-common-module/models/functional-default-request-dto';
import { SageDataService } from '../sage-common-module/sage-data.service';
import { UnitsOfMeasureEvaluator } from '../units-of-measure/units-of-measure-evaluator';
import { FittingTypes } from './fitting-types';

@Injectable({
  providedIn: 'root'
})
export class PipeBusinessRulesService extends BusinessRulesServiceBase implements BusinessRulesDefinition {
  private defReq: FunctionalDefaultRequestDto = {
    module: 'apipe',
    column: 1,
    unitSystem: this.unitSystem,

    keywordName: '',
    function: '',
    data: {}
  };

  private compReq: FunctionalDefaultRequestDto = {
    module: 'component',
    column: 1,
    unitSystem: this.unitSystem,

    keywordName: '',
    function: '',
    data: {}
  };

  public constructor(private dataService: SageDataService, uomEvaluator: UnitsOfMeasureEvaluator) {
    super(uomEvaluator);
  }

  public get valueData(): BusinessRuleValueList {
    if (this.data === undefined) {
      throw 'init must be called before valueData()';
    } else {
      return {
        ...getSharedRbiBusinessRules(this.data),
        jointEfficiency: {
          getDefaultValue: () => of('1.0')
        },
        underTolerance: {
          getValidValues: () =>
            of([
              {
                value: 'NO',
                label: 'No'
              },
              {
                value: 'YES',
                label: 'Yes'
              }
            ]),
          getDefaultValue: () =>
            this.dataService.getFunctionalDefaultValue({
              ...this.compReq,
              keywordName: 'SEAMLESS',
              function: 'SEAMLESS_FROM_SPEC(B_MSPEC)',
              data: {
                B_MSPEC: [
                  this.data.specification,
                  this.data.grade,
                  this.data.year,
                  this.data.uns,
                  this.data.cct,
                  this.data.st,
                  this.data.modifier
                ]
              }
            })
        },
        futureCorrosionAllowance: {
          getRequired: () => true
        },
        geomType: {
          getValidValues: () => this.getGeomTypeValues(),
          getRequired: () => compTypeCanBeCalculated(this.data) && this.data.compType !== 'TUBE'
        },
        nps: {
          getRequired: (): boolean => {
            if (this.data.nonStandardTube || this.data.compType === 'TUBE') {
              return false;
            }
            return compTypeCanBeCalculated(this.data);
          },
          getValidValues: () => this.dataService.getNpsOptions()
        },
        nps2: {
          //Seems redundant. Can we remove?
          getValidValues: () => this.dataService.getNpsOptions()
        },
        pipeSchedule: {
          getRequired: (): boolean => {
            if (this.data.nonStandardTube || this.data.compType === 'TUBE') {
              return false;
            }
            return compTypeCanBeCalculated(this.data);
          },
          getValidValues: () =>
            this.data.nps
              ? this.dataService.getFunctionalValidValues({
                  ...this.defReq,
                  keywordName: 'THK',
                  function: 'THK_FROM_NPS(NPS)',
                  data: { NPS: this.data.nps }
                })
              : of([])
        },
        outerDiameter: {
          getRequired: () => this.isNonStandardTube(),
          getDefaultValue: () =>
            this.data.nps
              ? this.dataService.getFunctionalDefaultValue({
                  ...this.defReq,
                  keywordName: 'OD',
                  function: 'OD_FROM_NPS(NPS)',
                  data: { NPS: this.dataHandler.data.nps }
                })
              : of(undefined)
        },
        outerDiameter2: {
          getRequired: () => this.isNonStandardTube(),
          getDefaultValue: () =>
            this.data.nps
              ? this.dataService.getFunctionalDefaultValue({
                  ...this.defReq,
                  keywordName: 'OD',
                  function: 'OD_FROM_NPS(NPS)',
                  data: { NPS: this.dataHandler.data.nps2 }
                })
              : of(undefined)
        },
        nominalThickness: {
          getRequired: () => this.isNonStandardTube(),
          getDefaultValue: () =>
            this.dataHandler.data.nps && this.dataHandler.data.pipeSchedule
              ? this.dataService.getFunctionalDefaultValue({
                  ...this.defReq,
                  keywordName: 'ST',
                  function: 'ST_FROM_THK(NPS,THK)',
                  data: {
                    NPS: this.dataHandler.data.nps,
                    THK: this.dataHandler.data.pipeSchedule
                  }
                })
              : of(undefined)
        },
        nominalThickness2: {
          getRequired: () => this.isNonStandardTube(),
          getDefaultValue: () =>
            this.dataHandler.data.nps2 && this.dataHandler.data.pipeSchedule
              ? this.dataService.getFunctionalDefaultValue({
                  ...this.defReq,
                  keywordName: 'ST',
                  function: 'ST_FROM_THK(NPS,THK)',
                  data: {
                    NPS: this.dataHandler.data.nps2,
                    THK: this.dataHandler.data.pipeSchedule
                  }
                })
              : of(undefined)
        },
        bendRadius: {
          getRequired: () => this.data.geomType === 'PE' || this.data.geomType === 'MI'
        },
        miterAngle: {
          getRequired: () => this.data.geomType === 'MI'
        },
        length: {
          getRequired: () => this.data.rbi === true
        },
        fittingType: {
          getValidValues: () =>
            of([
              {
                value: FittingTypes.TEE,
                label: 'Tee'
              },
              {
                value: FittingTypes.REDUCER,
                label: 'Reducer'
              },
              {
                value: FittingTypes.RED_ELBOW,
                label: 'Reducing Elbow'
              },
              {
                value: FittingTypes.RED_TEE,
                label: 'Reducing Tee'
              }
            ])
        },
        facility: {
          getValidValues: () =>
            of([
              {
                value: 1,
                label: 'Pipelines'
              },
              {
                value: 2,
                label: 'R&R w/o casing: private'
              },
              {
                value: 3,
                label: 'R&R w/o casing: unimproved'
              },
              {
                value: 4,
                label: 'R&R w/o casing: hard'
              },
              {
                value: 5,
                label: 'R&R w/ casing: private'
              },
              {
                value: 6,
                label: 'R&R w/ casing: unimproved'
              },
              {
                value: 7,
                label: 'R&R w/ casing: hard'
              },
              {
                value: 8,
                label: 'Parallel: private'
              },
              {
                value: 9,
                label: 'Parallel: unimproved'
              },
              {
                value: 10,
                label: 'Parallel: hard'
              },
              {
                value: 11,
                label: 'Fabricated assemblies'
              },
              {
                value: 12,
                label: 'Bridges'
              },
              {
                value: 13,
                label: 'Control and metering'
              },
              {
                value: 14,
                label: 'Compressor'
              },
              {
                value: 15,
                label: 'Liquid removal'
              },
              {
                value: 16,
                label: 'People'
              }
            ]),
          getDefaultValue: () => of(1)
        },
        locationClass: {
          getValidValues: () =>
            of([
              {
                value: 1,
                label: 'Class 1, Division 1'
              },
              {
                value: 2,
                label: 'Class 1, Division 2'
              },
              {
                value: 3,
                label: 'Class 2'
              },
              {
                value: 4,
                label: 'Class 3'
              },
              {
                value: 5,
                label: 'Class 4'
              }
            ]),
          getDefaultValue: () => of(2)
        },
        siteFactor: {
          getRequired: () => this.dataHandler.data.designCode == 'B31_8',
          getDefaultValue: () =>
            this.dataService.getFunctionalDefaultValue({
              ...this.defReq,
              keywordName: 'FSITE',
              function: 'GET_SITE_FACTOR(FACILITY,LOCATION)',
              data: {
                FACILITY: this.dataHandler.data.facility,
                LOCATION: this.dataHandler.data.locationClass
              }
            })
        },
        componentAttachedTo: {
          getRequired: () => this.dataHandler.data.useAdvancedCalculation
        },
        nozzleNeckMinimumThicknessBasis: {
          getValidValues: () => this.getNozzleNeckMinimumThicknessBasisValues(),
          getDefaultValue: () => this.getNozzleNeckMinimumThicknessBasisDefault()
        },
        nozzleNeckMinimumThickness: {
          getRequired: () => this.dataHandler.data.useAdvancedCalculation,
          getDefaultValue: () =>
            this.dataHandler.data.nozzleNeckMinimumThicknessBasis && this.getNid()
              ? this.dataService.getFunctionalDefaultValue({
                  ...this.defReq,
                  module: 'NOZZLE',
                  keywordName: 'TMINN',
                  function: 'TMIN(CODE,NID,TMINBASN)',
                  data: {
                    CODE: this.dataHandler.data.designCode,
                    NID: this.getNid(),
                    TMINBASN: this.dataHandler.data.nozzleNeckMinimumThicknessBasis
                  }
                })
              : of(undefined)
        },
        nozzleReinforcementMinimumThicknessBasis: {
          getValidValues: () =>
            this.dataService.getValidValuesWithLabels({
              ...this.defReq,
              module: 'NOZZLE',
              keywordName: 'TMINBASR'
            }),
          getDefaultValue: () => this.getNozzleReinforcementMinimumThicknessBasisDefault()
        },
        grooveWelds: {
          getValidValues: () =>
            of([
              { value: 'FULLPEN', label: 'Full penetration' },
              { value: 'PARTIAL', label: 'Partial penetration' }
            ]),
          getDefaultValue: () => of('FULLPEN')
        },
        outsideFilletWeld: {
          getRequired: () => this.dataHandler.data.useAdvancedCalculation
        },
        nozzleGrooveWeld: {
          getRequired: () =>
            this.dataHandler.data.useAdvancedCalculation && this.dataHandler.data.grooveWelds == 'PARTIAL'
        }
      };
    }
  }

  private getGeomTypeValues(): Observable<Array<E2gSelectOption>> {
    let options: Array<E2gSelectOption> = [];
    if (this.data.compType === 'FITTING') {
      return of(options);
    } else if (this.data.compType === 'BOILER TUBE') {
      options = [...options, { value: 'BT', label: 'Boiler Tube' }];
    } else if (this.data.compType === 'TUBE') {
      options = [...options, { value: 'CY', label: 'Tube' }];
    } else {
      if (this.data.compType === 'PIPE') {
        options = [...options, { value: 'CY', label: 'Straight Pipe' }];
      } else if (this.data.compType === 'ELBOW') {
        options = [...options, { value: 'PE', label: 'Pipe Bend or Elbow' }, { value: 'MI', label: 'Miter Bend' }];
      }
    }

    return of(options);
  }

  private isNonStandardTube(): boolean {
    if (this.data.nonStandardTube) {
      return true;
    }
    return false;
  }

  private getNozzleNeckMinimumThicknessBasisValues(): Observable<Array<E2gSelectOption>> {
    const options: Array<E2gSelectOption> = [];
    switch (this.dataHandler.data.designCode) {
      case 'Section8D1':
        options.push(
          { value: 'S8D1SV', label: 'ASME VIII-1, Standard' },
          { value: 'S8D1USB', label: 'ASME VIII-1, Boiler' },
          { value: 'S8D1ASW', label: 'ASME VIII-1, Air, Water' }
        );
        break;
      case 'Section8D2':
        options.push(
          { value: 'S8D2ASW', label: 'ASME VIII-2, Air, Water' },
          { value: 'S8D2SV', label: 'ASME VIII-2, Standard' }
        );
        break;
      case 'SectionI':
        options.push({ value: 'S1PB', label: 'ASME I' });
        break;
    }

    options.push({ value: 'APIFFS', label: 'API 579' }, { value: 'SPECIFY', label: 'Specified' });
    return of(options);
  }

  private getNozzleNeckMinimumThicknessBasisDefault(): Observable<string | undefined> {
    switch (this.dataHandler.data.designCode) {
      case 'Section8D1':
        return of('S8D1SV');
      case 'Section8D2':
        return of('S8D2SV');
      case 'SectionI':
        return of('S1PB');
    }
    return of(undefined);
  }

  private getNid(): number | undefined {
    if (this.dataHandler.data.outerDiameter && this.dataHandler.data.nominalThickness) {
      return this.dataHandler.data.outerDiameter - this.dataHandler.data.nominalThickness * 2;
    }
    return undefined;
  }

  private getNozzleReinforcementMinimumThicknessBasisDefault(): Observable<string | undefined> {
    switch (this.dataHandler.data.designCode) {
      case 'Section8D1':
        return of('NOZZLE');
      case 'Section8D2':
      case 'SectionI':
        return of('UG45');
    }
    return of(undefined);
  }
}
