import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, startWith, switchMap } from 'rxjs/operators';
import { EquipmentBreadcrumbsDto } from 'src/app/models/equipment-breadcrumbs-dto';
import { NameAndId } from 'src/app/models/name-and-id';
import { UnitBreadcrumbsDto } from 'src/app/models/unit-breadcrumbs-dto';
import { SlideOutService } from 'src/app/slide-out-module/slide-out.service';

import { DataService } from '../dataservice';
import { EquipmentDataService } from '../equipment-data.service';
import { UnitOfMeasure } from '../models/enums/unit-of-measure';
import { SourceLocation } from '../shared-module/models/Enums/source-location';
import { StateService } from '../state.service';
import { UnitDataService } from '../unit-data.service';
import { RouterHelper } from '../utilities/route-helper';
import { Breadcrumb } from './breadcrumbs/breadcrumbs.component';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbsService {
  private sourceLocation?: SourceLocation;
  private uom?: UnitOfMeasure;

  public constructor(
    private equipDataService: EquipmentDataService,
    private unitDataService: UnitDataService,
    private sageDataService: DataService,
    private router: Router,
    private route: ActivatedRoute,
    private stateService: StateService,
    private slideOutService: SlideOutService
  ) {
    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        startWith(undefined),
        switchMap(() => this.getLastChild(this.route)?.params || of([]))
      )
      .subscribe((params: Params) => {
        this.sourceLocation = params['sourceLocation'] as SourceLocation;
        this.uom = params['unitOfMeasure'] as UnitOfMeasure;
      });
  }

  public getAssetBreadcrumbs(assetId: string): Observable<Array<Breadcrumb>> {
    return this.equipDataService.getEquipmentBreadcrumbs(assetId).pipe(
      map((breadCrumbs: EquipmentBreadcrumbsDto) => [
        {
          name: breadCrumbs.plant.name,
          id: breadCrumbs.plant.id,
          linkCommand: (): void => {
            this.slideOutService.close();

            this.router.navigate(['assets']);
            this.stateService.setFlowForHeirarchyTree(breadCrumbs.plant.id, undefined, undefined);
          }
        },
        {
          name: breadCrumbs.unit.name,
          id: breadCrumbs.unit.id,
          linkCommand: (): void => {
            this.slideOutService.close();

            if (Number(this.sourceLocation) === SourceLocation.AssetTree) {
              this.router.navigate(['assets']);
              this.stateService.setFlowForHeirarchyTree(breadCrumbs.plant.id, breadCrumbs.unit.id, undefined);
            } else {
              this.router.navigate(
                RouterHelper.getUnitDetailsRouteCommands(
                  breadCrumbs.plant.id,
                  breadCrumbs.unit.id,
                  this.uom!,
                  this.stateService.getActiveTabId()
                )
              );
            }
          }
        },
        {
          name: breadCrumbs.asset.name,
          id: breadCrumbs.asset.id,
          linkCommand: () => this.slideOutService.close()
        }
      ]),
      catchError(() => of([]))
    );
  }

  public getUnitBreadcrumbs(unitId: string): Observable<Array<Breadcrumb>> {
    return this.unitDataService.getUnitBreadcrumbs(unitId).pipe(
      map((x: UnitBreadcrumbsDto) => [
        {
          name: x.plant.name,
          id: x.plant.id,
          linkCommand: (): void => {
            this.slideOutService.close();

            this.router.navigate(['assets']);
            this.stateService.setFlowForHeirarchyTree(x.plant.id, undefined, undefined);
          }
        },
        {
          name: x.unit.name,
          id: x.unit.id,
          linkCommand: (): void => {
            this.slideOutService.close();
          }
        }
      ]),
      catchError(() => of([]))
    );
  }

  public getSageModuleBreadcrumbs(moduleId: string): Observable<Array<Breadcrumb>> {
    return this.sageDataService.getModuleBreadcrumbs(moduleId).pipe(
      map((modules: NameAndId[]) =>
        modules.map<Breadcrumb>((module) => ({
          name: module.name,
          id: module.id,
          linkCommand: (): void => {
            this.router.navigate(['sage']);
          }
        }))
      )
    );
  }

  private getLastChild(route: ActivatedRoute): ActivatedRoute | undefined {
    let currentRoute = route;
    while (currentRoute.firstChild) {
      currentRoute = currentRoute.firstChild;
    }
    return currentRoute;
  }
}
