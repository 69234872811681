<ng-container *ngIf="!hasError; else error">
  <ng-container *ngIf="url; else loading">
    <div class="d-flex flex-column h-100">
      <div class="doc-header">
        <app-pdf-paging [totalPages]="pageCount" [(ngModel)]="page"></app-pdf-paging>
        <div style="margin-left: 30px">
          <button id="btn-zoom-out" class="btn btn-nav" (click)="zoomOut()">
            <fa-icon [icon]="faSearchMinus" size="lg" class="icon-secondary"></fa-icon>
          </button>
          <button id="btn-zoom-in" class="btn btn-nav" (click)="zoomIn()">
            <fa-icon [icon]="faSearchPlus" size="lg" class="icon-secondary"></fa-icon>
          </button>
        </div>
        <div style="margin-left: 30px">
          <button id="btn-download" class="btn btn-nav" (click)="download()">
            <fa-icon [icon]="faDownload" size="lg" class="icon-secondary"></fa-icon>
          </button>
        </div>
      </div>
      <div class="h-100">
        <div style="position: relative; height: 100%">
          <pdf-viewer
            [show-all]="true"
            [src]="url"
            [zoom]="zoom"
            [render-text]="true"
            [original-size]="false"
            [fit-to-page]="true"
            [autoresize]="true"
            class="viewer"
            [(page)]="page"
            (after-load-complete)="loadComplete($event)"
          ></pdf-viewer>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
<ng-template #loading><div id="ico-loading" class="loading"></div></ng-template>
<ng-template #error>
  <div class="row">
    <div class="col-xs-12">
      <div class="alert alert-warning" role="alert">
        <p id="txt-error" class="text-center" style="font-size: larger; font-weight: bold">Report Unavailable</p>
      </div>
    </div>
  </div>
</ng-template>
