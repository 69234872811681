import { GridApi } from 'ag-grid-community';

export function convertNodesToSlideoutOptions<TIn, TOut>(
  gridApi: GridApi,
  optionBuilderFunc: (data: TIn) => TOut
): Record<string, TOut> {
  const options: Record<string, TOut> = {};

  gridApi.forEachNodeAfterFilterAndSort((node) => {
    options[node.data.id!] = optionBuilderFunc(node.data);
  });

  return options;
}
