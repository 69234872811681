import { E2gSelectOption } from '@equityeng/e2g-ng-ui';

export enum PrdDischargeLocation {
  Flare = 1,
  Atmosphere = 2,
  ClosedSystem = 3
}

export const PRD_DISCHARGE_LOCATION_VALUES: Array<E2gSelectOption> = [
  { value: PrdDischargeLocation.Flare, label: 'Flare' },
  { value: PrdDischargeLocation.Atmosphere, label: 'Atmosphere' },
  { value: PrdDischargeLocation.ClosedSystem, label: 'Closed System' }
];

export function getPrdDischargeLocation(type: PrdDischargeLocation): string {
  switch (type) {
    case PrdDischargeLocation.Flare:
      return 'Flare';
    case PrdDischargeLocation.Atmosphere:
      return 'Atmosphere';
    case PrdDischargeLocation.ClosedSystem:
      return 'Closed System';
  }
}
