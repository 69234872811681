import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@equityeng/auth';
import { E2gSelectOption } from '@equityeng/e2g-ng-ui';
import { faFilePdf } from '@fortawesome/pro-light-svg-icons';
import {
  faArrowUpRightFromSquare,
  faBell,
  faBook,
  faChevronLeft,
  faCircleInfo,
  faCircleQuestion,
  faFileImport,
  faFileLines,
  faGear
} from '@fortawesome/pro-regular-svg-icons';
import { faBell as faSolidBell, faCog, faSignOutAlt } from '@fortawesome/pro-solid-svg-icons';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import { CompanyService } from 'src/app/company.service';
import {
  ADMIN,
  CAN_IMPORT,
  CAN_MANAGE_INSPECTION_ACTIVITIES,
  CAN_MANAGE_INSPECTIOR_LIST,
  CAN_MANAGE_ROLES,
  COMPANY_ADMIN,
  IDMS,
  PRODUCT_NAME,
  SAGE
} from 'src/app/models/auth-constants';
import { ReleaseNotesDataService } from 'src/app/release-notes-module/release-notes-data.service';
import { ReportService } from 'src/app/report.service';
import { DialogData } from 'src/app/shared-module/models/dialog-data';
import { UserService } from 'src/app/user.service';

import { MenuService } from '../../menu.service';
import { JobNotificationService } from '../../notifications-module/services/job-notifications.service';
import { State, StateService } from '../../state.service';
import { VersionService } from '../version.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  private destroy: Subject<void> = new Subject<void>();

  public isNavbarCollapsed: boolean = true;

  public companies: Array<E2gSelectOption> = [] as Array<E2gSelectOption>;
  public selectedCompanyKey: string | undefined;
  public selectedCompanyName: string | undefined;
  public selectorDisabled = this.companyService.selectorDisabled;
  public selectorHidden = this.companyService.selectorHidden;

  public userName: Observable<string>;
  public hasSettingsAccess: Observable<boolean>;
  public isAdmin: Observable<boolean>;
  public canImport: Observable<boolean>;
  public clientVersionInfo: Observable<string>;
  public serverVersionInfo: Observable<string>;
  public state: Observable<State>;
  public extraStartMenuItems: Observable<Array<TemplateRef<any>>>;
  public extraEndMenuItems: Observable<Array<TemplateRef<any>>>;
  public notificationCount: Observable<number>;
  public releaseNoteNewCount: number = 0;
  public auditReportDialogData!: DialogData;
  public cmlDetailReportDialogData!: DialogData;

  public faCircleQuestion = faCircleQuestion;
  public faGear = faGear;
  public faSignOutAlt = faSignOutAlt;
  public faBell = faBell;
  public faSolidBell = faSolidBell;
  public faBook = faBook;
  public faCircleInfo = faCircleInfo;
  public faChevronLeft = faChevronLeft;
  public faFilePdf = faFilePdf;
  public faCog = faCog;
  public faFileLines = faFileLines;
  public faFileImport = faFileImport;
  public faArrowUpRightFromSquare = faArrowUpRightFromSquare;

  public reportOptions = this.reportService.reportOptionList;
  public importDisabled = this.reportService.viewingSage;

  public hasIdms = this.companyService.selectedCompany.pipe(
    switchMap(() => this.authService.userAuthenticated$),
    map((user) => user.hasAccessToProduct(IDMS))
  );

  public constructor(
    private authService: AuthService,
    private versionService: VersionService,
    private companyService: CompanyService,
    private jobNotificationService: JobNotificationService,
    private menuService: MenuService,
    private stateService: StateService,
    private reportService: ReportService,
    private releaseNotesService: ReleaseNotesDataService,
    private userService: UserService,
    private router: Router
  ) {
    this.companies = this.companyService.getCompanies().map((x) => ({
      value: x.key,
      label: x.name
    })) as Array<E2gSelectOption>;

    this.clientVersionInfo = this.versionService.clientVersion;
    this.serverVersionInfo = this.versionService.serverVersion;

    this.state = this.stateService.state;
    this.extraStartMenuItems = this.menuService.extraStartMenuItems;
    this.extraEndMenuItems = this.menuService.extraEndMenuItems;

    this.userName = this.authService.userAuthenticated$.pipe(switchMap((user) => of(user.username)));
    this.hasSettingsAccess = this.companyService.selectedCompany.pipe(
      switchMap(() => this.authService.userAuthenticated$),
      map((user) =>
        [
          user.hasAccessToFeature(PRODUCT_NAME, COMPANY_ADMIN),
          user.hasPermission(CAN_MANAGE_ROLES),
          user.hasPermission(CAN_MANAGE_INSPECTIOR_LIST),
          user.hasPermission(CAN_MANAGE_INSPECTION_ACTIVITIES)
        ].some((x) => x === true)
      )
    );

    this.canImport = this.companyService.selectedCompany.pipe(
      switchMap(() => this.authService.userAuthenticated$),
      map((user) => user.hasPermission(CAN_IMPORT))
    );

    this.isAdmin = this.companyService.selectedCompany.pipe(
      switchMap(() => this.authService.userAuthenticated$),
      map((user) => user.hasAccessToFeature(PRODUCT_NAME, ADMIN))
    );

    this.notificationCount = this.jobNotificationService.activeNotificationsCount;
  }

  public ngOnInit(): void {
    this.companyService.selectedCompany.pipe(takeUntil(this.destroy)).subscribe((company) => {
      this.selectedCompanyKey = company.key;
      this.selectedCompanyName = company.name;
    });

    combineLatest([this.releaseNotesService.getReleaseNoteDates(), this.userService.userData]).subscribe(
      ([releaseNotesDates, userData]) => {
        this.releaseNoteNewCount = releaseNotesDates.filter((x) => x > userData.latestReleaseNotesView).length;
      }
    );
  }

  public ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  public logout(): void {
    this.authService.signOut();
  }

  public onCompanyChange(): void {
    if (this.selectedCompanyKey) {
      this.selectedCompanyName = this.companies?.find((x) => x.value === this.selectedCompanyKey)?.label;

      this.companyService.triggerCurrentCompany(this.selectedCompanyKey);
    }
  }

  public company(dropDown: NgbDropdown): void {
    dropDown.toggle();
  }

  public toggleNotifications(event: Event): void {
    event.stopPropagation();
    this.stateService.setNotificationsDisplay(!this.stateService.staticState.notifications.display);
  }

  public blockMenu(): boolean {
    return this.stateService.staticState.slideOut.display;
  }

  public navigateToLandingPage(): void {
    this.authService.userAuthenticated$.pipe(take(1)).subscribe((user) => {
      const hasIdms = user.hasAccessToProduct(IDMS);
      const hasSage = user.hasAccessToProduct(SAGE);

      if (hasSage && !hasIdms) {
        this.router.navigate(['sage']);
      } else {
        this.router.navigate(['assets']);
      }
    });
  }
}
