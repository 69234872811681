<e2g-input-heading [heading]="heading"></e2g-input-heading>
<div class="d-flex flex-row align-items-center mt-1">
  <input
    class="e2g-radio"
    type="radio"
    [value]="false"
    [disabled]="readOnly"
    [(ngModel)]="value"
    (change)="handleChange()"
  />
  <label class="">{{ falseLabel }}</label>
  <input
    class="e2g-radio"
    type="radio"
    [value]="true"
    [disabled]="readOnly"
    [(ngModel)]="value"
    (change)="handleChange()"
  />
  <label class="">{{ trueLabel }}</label>
</div>
<!-- <mat-radio-group [(ngModel)]="value">
  <mat-radio-button [value]="false">{{ falseLabel }}</mat-radio-button>
  <mat-radio-button [value]="true">{{ trueLabel }}</mat-radio-button></mat-radio-group
> -->
