import { Component, OnInit } from '@angular/core';
import { combineLatest, of, switchMap, take, takeUntil } from 'rxjs';
import { AlertService } from 'src/app/alert.service';
import { OnDestroyBaseComponent } from 'src/app/on-destroy-base-component/on-destroy-base-component';

import { DialogService } from '../../shared-module/dialog.service';
import { DialogButtons } from '../../shared-module/models/dialog-buttons';
import { DialogResult } from '../../shared-module/models/dialog-result';
import { SlideOutContainerAction } from '../models/constants/slide-out-container-action';
import { SlideOutContainerService } from '../slide-out-container.service';

@Component({
  selector: 'app-slide-out-container',
  templateUrl: './slide-out-container.component.html',
  styleUrls: ['./slide-out-container.component.css']
})
export class SlideOutContainerComponent extends OnDestroyBaseComponent implements OnInit {
  public hasBreadcrumbs: boolean = false;
  public alert: string = '';
  public constructor(
    private slideOutService: SlideOutContainerService,
    private dialogService: DialogService,
    private alertService: AlertService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.slideOutService.breadcrumbs.pipe(takeUntil(this.destroy)).subscribe((breadcrumbs) => {
      this.hasBreadcrumbs = breadcrumbs.length > 0;
    });

    this.alertService.alert.subscribe((x) => (this.alert = x ?? ''));
  }

  public triggerAction(action: SlideOutContainerAction): void {
    if (action !== SlideOutContainerAction.Cancel) {
      this.slideOutService.triggerAction(action);
    } else {
      combineLatest([
        this.slideOutService.dirty.pipe(take(1)),
        this.slideOutService.bodyValid.pipe(take(1)),
        this.slideOutService.headerValid.pipe(take(1))
      ])
        .pipe(
          switchMap(([dirty, bodyValid, headerValid]) => {
            if (dirty) {
              return this.dialogService.display({
                title: 'Save Changes?',
                message: 'Unsaved changes will be lost.',
                yesButtonText: 'Save Changes',
                noButtonText: 'Abandon Changes',
                buttons: DialogButtons.YesNoCancel,
                validityCallback: () => bodyValid && headerValid
              });
            } else {
              return of(false);
            }
          }),
          take(1)
        )
        .subscribe((action) => {
          if (typeof action == 'boolean') {
            this.slideOutService.triggerAction(SlideOutContainerAction.Cancel);
          } else if (action === DialogResult.yes) {
            this.slideOutService.triggerAction(SlideOutContainerAction.SaveAndClose);
          } else if (action === DialogResult.no) {
            this.slideOutService.triggerAction(SlideOutContainerAction.Cancel);
          }
        });
    }
  }
}
