import { Injectable } from '@angular/core';
import { E2gSelectOption } from '@equityeng/e2g-ng-ui';
import { Observable, of } from 'rxjs';

import { getSharedRbiBusinessRules } from '../component-helper-service';
import { AssetTypes } from '../models/enums/asset-types';
import { BusinessRulesServiceBase } from '../sage-common-module/BusinessRulesServiceBase';
import { BusinessRuleValueList } from '../sage-common-module/models/business-rule-value-list';
import { BusinessRulesDefinition } from '../sage-common-module/models/business-rules-definition';
import { UnitsOfMeasureEvaluator } from '../units-of-measure/units-of-measure-evaluator';
import { LINING_TYPE_VALUES } from '../utilities/hardcoded-lists';

@Injectable()
export class FcompBusinessRulesService extends BusinessRulesServiceBase implements BusinessRulesDefinition {
  public constructor(uomEvaluator: UnitsOfMeasureEvaluator) {
    super(uomEvaluator);
  }

  public get valueData(): BusinessRuleValueList {
    if (this.data === undefined) {
      throw 'init must be called before valueData()';
    } else {
      return {
        ...getSharedRbiBusinessRules(this.data),
        rbi: {
          getDefaultValue: () => of(this.data.eqRbi)
        },
        futureCorrosionAllowance: {
          getRequired: () => true
        },
        jointEfficiency: {
          getRequired: () => true
        },
        geomType: {
          getValidValues: () => this.getGeomTypeValues(),
          getRequired: () => true
        },
        plateType: {
          getValidValues: () =>
            of([
              { value: 'ROUND', label: 'Round plate' },
              { value: 'NONCIRC', label: 'Non-circular plate' }
            ]),
          getDefaultValue: () => of('ROUND'),
          getRequired: () => this.data.geomType === 'PW'
        },
        coneAngle: {
          getRequired: () => this.data.geomType === 'CO' || this.data.geomType === 'FL' || this.data.geomType === 'KN'
        },
        innerDiameter: {
          getRequired: () => !(this.data.geomType === 'DI')
        },
        largeInnerDiameter: {
          getRequired: () => this.data.geomType === 'PW' && this.data.plateType === 'NONCIRC'
        },
        crownRadius: {
          getRequired: () => this.data.geomType === 'DI' || this.data.geomType === 'TO'
        },
        knuckleRadius: {
          getRequired: () => this.data.geomType === 'TO' || this.data.geomType === 'FL' || this.data.geomType === 'KN'
        },
        axisRatio: {
          getRequired: () => this.data.geomType === 'EL',
          getDefaultValue: () => of(2)
        },
        nominalThickness: {
          getRequired: () => true
        },
        liningType: {
          getValidValues: () => of(LINING_TYPE_VALUES)
        }
      };
    }
  }

  private getGeomTypeValues(): Observable<Array<E2gSelectOption>> {
    let options: Array<E2gSelectOption> = [];

    if (this.data.compType === 'SHELL') {
      options = [
        ...options,
        { value: 'CY', label: 'Cylindrical shell' },
        { value: 'SP', label: 'Spherical shell' },
        { value: 'CO', label: 'Conical shell' }
      ];
    } else if (this.data.compType === 'HEAD') {
      options = [
        ...options,
        { value: 'SP', label: 'Hemispherical head' },
        { value: 'DI', label: 'Dished head' },
        { value: 'PW', label: 'Flat welded head' },
        { value: 'PB', label: 'Flat bolted head' }
      ];

      if (this.data.assetType != AssetTypes.Boiler) {
        options = [
          ...options,
          { value: 'EL', label: 'Ellipsoidal head' },
          { value: 'TO', label: 'Torispherical head' }
        ];
      }
    }

    return of(options.sort((a, b) => a.label.localeCompare(b.label)));
  }
}
