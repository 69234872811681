export enum CalculationTypes {
  All = 1,
  Cmls = 2,
  Activities = 3,
  Components = 4,
  Unit = 5,
  Asset = 6,
  SageModule = 7,
  Component = 8
}


export enum CalculationIdTypes {
  Asset = 1,
  Component = 2,
  Cml = 3,
  Activity = 4,
}
