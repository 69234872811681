import { Component, EventEmitter, Output } from '@angular/core';
import { faDownload } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-download-spreadsheet',
  templateUrl: './download-spreadsheet.component.html',
  styleUrls: ['./download-spreadsheet.component.css']
})
export class DownloadSpreadsheetComponent{
  @Output() public onDownload = new EventEmitter<void>();
  public faDownload = faDownload;
}
