import { Component, Input, OnInit } from '@angular/core';
import { E2gSelectOption } from '@equityeng/e2g-ng-ui';
import { takeUntil } from 'rxjs';
import {
  ActivityEffectiveness,
  ActivityPhase,
  InspectionApproach,
} from 'src/app/asset-module/models/enums/activity-effectiveness';
import { FutureActivityDataService } from 'src/app/asset-module/services/activity-future-data.service';
import { ActivityDto } from 'src/app/models/activity-dto';
import { InspectionGradesDto } from 'src/app/models/activity-effectiveness-dto';
import { OnDestroyBaseComponent } from 'src/app/on-destroy-base-component/on-destroy-base-component';
import { SingleDataHandler } from 'src/app/shared-module/single-data-handler';
import { EFFECTIVENESS_VALUES } from 'src/app/utilities/hardcoded-lists';

import { FutureActivityDto } from '../models/future-activity-dto';
import { InspectionTypeKeys } from '../models/inspection_type_keys';

@Component({
  selector: 'app-grade-activity',
  templateUrl: './grade-activity.component.html',
  styleUrls: ['./grade-activity.component.css']
})
export class GradeActivityComponent extends OnDestroyBaseComponent implements OnInit {
  //TODO change to just passing in inspection grades
  @Input() public dataHandler!: SingleDataHandler<FutureActivityDto | ActivityDto>;
  @Input() public readonly: boolean = false;
  @Input() public displayRecommendation: boolean = false;
  @Input() public activityPhase: ActivityPhase | undefined;

  private inspectionData: Record<string, string> = {};

  public effectivenessOptions: Array<E2gSelectOption> = EFFECTIVENESS_VALUES;
  public inspectionApproachOptions: Array<E2gSelectOption> = [
    { label: 'Intrusive', value: InspectionApproach.Intrusive },
    { label: 'Non-Intrusive', value: InspectionApproach.NonIntrusive }
  ];
  public inspectionTypeKeys: Array<InspectionTypeKeys> = [
    {
      label: 'General Thinning',
      idName: 'generalThinning',
      activeProp: 'generalThinningActive',
      gradeProp: 'generalThinningGrade'
    },
    {
      label: 'Local Thinning',
      idName: 'localThinning',
      activeProp: 'localThinningActive',
      gradeProp: 'localThinningGrade'
    },
    {
      label: 'Internal Thickness',
      idName: 'internalThickness',
      activeProp: 'internalThickness',
      gradeProp: 'internalThickness',
      thicknessField: true
    },
    {
      label: 'Atm Corrosion',
      idName: 'externalAtm',
      activeProp: 'externalAtmActive',
      gradeProp: 'externalAtmGrade'
    },
    {
      label: 'External CUI',
      idName: 'externalCui',
      activeProp: 'externalCuiActive',
      gradeProp: 'externalCuiGrade'
    },
    {
      label: 'External Metal Loss',
      idName: 'metalLoss',
      activeProp: 'externalMetalLoss',
      gradeProp: 'externalMetalLoss',
      thicknessField: true
    },
    {
      label: 'External Cracking',
      idName: 'externalCrackingActive',
      activeProp: 'externalCrackingActive',
      gradeProp: 'externalCrackingGrade'
    },
    {
      label: 'External CUIA',
      idName: 'externalCuia',
      activeProp: 'externalCuiaActive',
      gradeProp: 'externalCuiaGrade'
    },
    {
      label: 'Amine',
      idName: 'amineCracking',
      activeProp: 'amineCrackingActive',
      gradeProp: 'amineCrackingGrade'
    },
    {
      label: 'Caustic',
      idName: 'causticCracking',
      activeProp: 'causticCrackingActive',
      gradeProp: 'causticCrackingGrade'
    },
    {
      label: 'SSC H2S',
      idName: 'ssch2s',
      activeProp: 'ssch2sActive',
      gradeProp: 'ssch2sGrade'
    },
    {
      label: 'HIC/SOHIC H2S',
      idName: 'hicsohich2s',
      activeProp: 'hicsohich2sActive',
      gradeProp: 'hicsohich2sGrade'
    },
    {
      label: 'Carbonate',
      idName: 'carbonateCracking',
      activeProp: 'carbonateCrackingActive',
      gradeProp: 'carbonateCrackingGrade'
    },
    {
      label: 'PTA Cracking',
      idName: 'ptaCracking',
      activeProp: 'ptaCrackingActive',
      gradeProp: 'ptaCrackingGrade'
    },
    {
      label: ' CLSCC',
      idName: 'clscc',
      activeProp: 'clsccActive',
      gradeProp: 'clsccGrade'
    },
    {
      label: 'HIC/SOHIC HF',
      idName: 'hicsohichf',
      activeProp: 'hicsohichfActive',
      gradeProp: 'hicsohichfGrade'
    },
    {
      label: 'hsc-HF',
      idName: 'hschf',
      activeProp: 'hschfActive',
      gradeProp: 'hschfGrade'
    },
    {
      label: 'Other Cracking',
      idName: 'otherCracking',
      activeProp: 'otherCrackingActive',
      gradeProp: 'otherCrackingGrade'
    }
  ];

  public constructor(private dataService: FutureActivityDataService) {
    super();
  }

  public ngOnInit(): void {
    this.dataHandler.data.inspectionGrades!.forEach((x) => {
      this.setRecommendationText(x);
    });
  }

  public showRecommendation(componentId: string): boolean {
    const recommendation = this.inspectionData[componentId];
    return recommendation != undefined && recommendation.trim().length > 0 && this.displayRecommendation;
  }

  public getActive(inspection: InspectionGradesDto, prop: keyof InspectionGradesDto): boolean {
    if (prop === 'internalThickness') {
      return (
        this.activityPhase !== ActivityPhase.Planning &&
        ((inspection.generalThinningActive &&
          (inspection.generalThinningGrade === ActivityEffectiveness.A ||
            inspection.generalThinningGrade === ActivityEffectiveness.B)) ||
          (inspection.localThinningActive &&
            (inspection.localThinningGrade === ActivityEffectiveness.A ||
              inspection.localThinningGrade === ActivityEffectiveness.B)))
      );
    }
    if (prop === 'externalMetalLoss') {
      return (
        this.activityPhase !== ActivityPhase.Planning &&
        ((inspection.externalAtmActive &&
          (inspection.externalAtmGrade === ActivityEffectiveness.A ||
            inspection.externalAtmGrade === ActivityEffectiveness.B)) ||
          (inspection.externalCuiActive &&
            (inspection.externalCuiGrade === ActivityEffectiveness.A ||
              inspection.externalCuiGrade === ActivityEffectiveness.B)))
      );
    }
    return inspection![prop] as any;
  }

  public showReset(inspection: InspectionGradesDto, prop: keyof InspectionGradesDto): boolean {
    return inspection![prop] !== undefined && !this.readonly;
  }

  public getRecommendation(componentId: string): string | undefined {
    return this.inspectionData[componentId];
  }

  public reset(inspection: InspectionGradesDto, gradeProp: keyof InspectionGradesDto): void {
    (inspection as any)[gradeProp] = undefined;
    this.dataHandler.data.inspectionGrades = JSON.parse(JSON.stringify(this.dataHandler.data.inspectionGrades));
    this.setRecommendationText(inspection);
  }

  public onGradeChange(
    inspection: InspectionGradesDto,
    gradeProp: keyof InspectionGradesDto,
    value: string | number
  ): void {
    if ((inspection as any)[gradeProp] != value) {
      (inspection as any)[gradeProp] = value;
      this.dataHandler.data.inspectionGrades = JSON.parse(JSON.stringify(this.dataHandler.data.inspectionGrades));
      this.setRecommendationText(inspection);
    }
  }

  public onValueChange(
    value: string | number,
    inspection: InspectionGradesDto,
    gradeProp: keyof InspectionGradesDto
  ): void {
    if ((inspection as any)[gradeProp] != value) {
      if (value === '') {
        (inspection as any)[gradeProp] = undefined;
      } else {
        (inspection as any)[gradeProp] = value;
      }
    }
  }

  public onClickOutside(): void {
    this.dataHandler.data.inspectionGrades = JSON.parse(JSON.stringify(this.dataHandler.data.inspectionGrades));
  }

  public onApproachChange(inspection: InspectionGradesDto): void {
    this.dataHandler.data.inspectionGrades = JSON.parse(JSON.stringify(this.dataHandler.data.inspectionGrades));
    this.setRecommendationText(inspection);
  }

  private setRecommendationText(inspection: InspectionGradesDto): void {
    if (this.displayRecommendation && inspection.inspectionApproach?.valueOf() !== 0) {
      this.dataService
        .getInspectionRecommendation(inspection)
        .pipe(takeUntil(this.destroy))
        .subscribe((inspectionRecommendation) => {
          this.inspectionData[inspection.componentId!] = inspectionRecommendation;
        });
    }
  }

  public editorOptions = {
    toolbar: false
  };
}
