import { Injectable } from '@angular/core';
import { map, of } from 'rxjs';

import { UnitsOfMeasureEvaluator } from '../units-of-measure/units-of-measure-evaluator';
import { BusinessRulesServiceBase } from './BusinessRulesServiceBase';
import { BusinessRuleValueList } from './models/business-rule-value-list';
import { BusinessRulesDefinition } from './models/business-rules-definition';
import { DefaultValueLookup } from './models/enums/default-value-lookup';
import { HubValueRequestDto } from './models/hub-value-request-dto';
import { ValueDataService } from './value-data.service';

@Injectable({
  providedIn: 'root'
})
export class TubeBusinessRulesService extends BusinessRulesServiceBase implements BusinessRulesDefinition {
  private defaultRequest: HubValueRequestDto = {
    lookupType: DefaultValueLookup.StandardTube,
    keywordName: '',
    data: {},
    unitSystem: this.unitSystem
  };

  public constructor(private dataService: ValueDataService, uomEvaluator: UnitsOfMeasureEvaluator) {
    super(uomEvaluator);
  }

  public get valueData(): BusinessRuleValueList {
    if (this.data === undefined) {
      throw 'init must be called before valueData()';
    } else {
      return {
        outerDiameter: {
          getRequired: () => true,
          getValidValues: () =>
            this.dataService
              .getValidValues({
                ...this.defaultRequest,
                keywordName: 'outerDiameter'
              })
              .pipe(map((x) => x.map((y) => ({ label: y.label, value: Number(y.value) }))))
        },
        bwg: {
          getRequired: () => !this.data.nonStandardTube,
          getValidValues: () =>
            this.data.outerDiameter != undefined
              ? this.dataService
                  .getValidValues({
                    ...this.defaultRequest,
                    keywordName: 'bwg',
                    data: {
                      outerDiameter: this.data.outerDiameter.toString()
                    }
                  })
                  .pipe(map((x) => x.map((y) => ({ label: y.label, value: Number(y.value) }))))
              : of([])
        },
        nominalThickness: {
          getRequired: () => !!this.data.nonStandardTube,
          getDefaultValue: () =>
            this.data.outerDiameter && this.data.bwg && !this.data.nonStandardTube
              ? this.dataService.getDefaultValue({
                  ...this.defaultRequest,
                  keywordName: 'nominalThickness',
                  data: {
                    outerDiameter: this.data.outerDiameter.toString(),
                    bwg: this.data.bwg.toString()
                  }
                })
              : of(undefined)
        },
        innerDiameter: {
          getRequired: () => false,
          // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
          getDefaultValue: () => {
            if (this.data.outerDiameter) {
              if (!this.data.nonStandardTube && this.data.bwg) {
                return this.dataService.getDefaultValue({
                  ...this.defaultRequest,
                  keywordName: 'innerDiameter',
                  data: {
                    outerDiameter: this.data.outerDiameter.toString(),
                    bwg: this.data.bwg.toString()
                  }
                });
              } else if (this.data.nominalThickness) {
                const innerDiameter = this.data.outerDiameter - this.data.nominalThickness * 2;
                if (innerDiameter > 0.001) {
                  return of(innerDiameter.toFixed(3));
                }
              }
            }

            return of('');
          }
        },
        futureCorrosionAllowance: {
          getRequired: () => true
        }
      };
    }
  }
}
