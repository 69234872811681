import { ChangeDetectorRef, Component, Inject, QueryList, ViewChildren } from '@angular/core';
import { E2gSelectOption } from '@equityeng/e2g-ng-ui';
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';
import { BehaviorSubject, filter, MonoTypeOperatorFunction, pipe, switchMap, takeUntil, tap } from 'rxjs';
import { AlertService } from 'src/app/alert.service';
import { AssetDataCache } from 'src/app/asset-module/models/asset-data-dto';
import { ComponentSlideoutOption } from 'src/app/asset-module/models/component-slideout-option';
import { DashboardSlideoutService } from 'src/app/dashboard-module/dashboard.service';
import { RefreshService } from 'src/app/notifications-module/refresh.service';
import { OnDestroyBaseComponent } from 'src/app/on-destroy-base-component/on-destroy-base-component';
import { StateService } from 'src/app/state.service';
import { duplicateItemNameMessage } from 'src/app/utilities/validation-messages';

import { CompDataService } from '../../../comp-data.service';
import { ISaveChanges } from '../../../save-changes';
import { NEW_ITEM_KEY } from '../../../shared-module/models/new-item-key';
import { SlideOutContainerAction } from '../../../slide-out-module/models/constants/slide-out-container-action';
import { SlideOutContainerService } from '../../../slide-out-module/slide-out-container.service';
import { SLIDE_OUT_DATA } from '../../../slide-out-module/slide-out-data-injection-token';
import { SlideOutRef } from '../../../slide-out-module/slide-out-ref';
import { ComponentSlideoutInput } from '../../models/component-slideout-input';

@Component({
  selector: 'app-component-detail',
  templateUrl: './component-detail.component.html'
})
export class ComponentDetailComponent extends OnDestroyBaseComponent {
  @ViewChildren(ISaveChanges) public saveComponents!: QueryList<ISaveChanges>;

  public refreshComponentData: BehaviorSubject<void> = new BehaviorSubject<void>(undefined);

  public baseComponentType?: string;

  public editing: boolean = false;
  public assetCache: AssetDataCache = new AssetDataCache();

  public faExclamationTriangle = faExclamationTriangle;

  private saving: boolean = false;
  private assetId: string = '';
  private previousComponentType: string = '';

  public constructor(
    private slideOutRef: SlideOutRef,
    private slideOutContainerService: SlideOutContainerService,
    private alertService: AlertService,
    private refreshService: RefreshService,
    private dashBoardSlideoutService: DashboardSlideoutService,
    private compService: CompDataService,
    private stateService: StateService,
    private changeDetector: ChangeDetectorRef,
    @Inject(SLIDE_OUT_DATA) private slideOutData: ComponentSlideoutInput
  ) {
    super();
    this.configureSlideOut();
  }

  public ngOnInit(): void {
    this.initializeSlideoutHeader();

    this.slideOutContainerService.optionId
      .pipe(
        tap((id) => {
          this.initializeForm(id);
        }),
        filter((id) => id !== NEW_ITEM_KEY),
        this.refreshCalculationErrors(this.slideOutData.id),
        takeUntil(this.destroy)
      )
      .subscribe();

    // Responds to calculation changes either by the current user or by other users on other screens.
    this.refreshService.updates
      .pipe(
        filter((impactedIds) => impactedIds.componentIds!.includes(this.slideOutData.id)),
        tap(() => {
          if (!this.saving) {
            this.refreshComponentData.next();
          }
        }),
        this.refreshCalculationErrors(this.slideOutData.id),
        takeUntil(this.destroy)
      )
      .subscribe();
  }

  private refreshCalculationErrors(id: string): MonoTypeOperatorFunction<any> {
    return pipe(
      tap(() => {
        this.slideOutContainerService.setCalcError(undefined);
      }),
      switchMap(() => this.compService.getCalculationStatusComponent(id)),
      filter((calcStatus) => calcStatus.success === false),
      tap((calcStatus) => this.slideOutContainerService.setCalcError(calcStatus.statusMessage))
    );
  }

  private getTypeById(id: string): string {
    return id !== NEW_ITEM_KEY
      ? (this.slideOutData.optionsData as Record<string, ComponentSlideoutOption>)[id].type
      : this.slideOutData.newType || 'UNKNOWN';
  }

  private determineBaseComponentType(compType: string): void {
    // Since we are using ngSwitch to change component types
    // we need to clear the baseComponentType and wait for the DOM to update
    // before setting it again. Change detection forces the DOM to update.
    // Otherwise, both components will exist at the same time in the DOM.
    this.baseComponentType = '';
    this.changeDetector.detectChanges();

    if (['BOILER TUBE', 'PIPE', 'ELBOW', 'TUBE', 'FITTING', 'NOZZLE'].includes(compType)) {
      this.baseComponentType = 'pipe';
    } else if (['SHELL', 'HEAD'].includes(compType!)) {
      this.baseComponentType = 'fcomp';
    } else if (['COURSE', 'FLOOR', 'ROOF', 'ANNULAR PLATE'].includes(compType)) {
      this.baseComponentType = 'tank';
    } else if ('HEADER BOX' === compType) {
      this.baseComponentType = 'ncpv';
    } else {
      throw 'Unknown component type';
    }
  }

  private close(): void {
    if (this.slideOutData.dashboardSlideoutData === undefined) {
      this.slideOutRef.close();
    } else {
      this.dashBoardSlideoutService.openSlideout(this.slideOutData.dashboardSlideoutData);
    }
  }

  private configureSlideOut(): void {
    this.slideOutContainerService.action
      .pipe(
        filter((action) => action === SlideOutContainerAction.Revert),
        takeUntil(this.destroy)
      )
      .subscribe(() => this.saveComponents.first.revertChanges());

    this.slideOutContainerService.action
      .pipe(
        filter((action) => action === SlideOutContainerAction.Save),
        tap(() => (this.saving = true)),
        switchMap(() => this.saveComponents.first.saveChanges()),
        tap(() => {
          this.slideOutContainerService.setOptions(this.buildDropdownOptions(this.slideOutData.optionsData!));

          if (this.editing) {
            this.refreshComponentData.next();
          } else {
            this.slideOutContainerService.setOptionId(this.slideOutData.id);
          }

          this.saving = false;
        }),
        takeUntil(this.destroy)
      )
      .subscribe();

    this.slideOutContainerService.action
      .pipe(
        filter((action) => action === SlideOutContainerAction.SaveAndClose),
        switchMap(() => this.saveComponents.first.saveChanges()),
        tap(() => this.close()),
        takeUntil(this.destroy)
      )
      .subscribe();

    this.slideOutContainerService.action
      .pipe(
        filter((action) => action === SlideOutContainerAction.Cancel),
        takeUntil(this.destroy)
      )
      .subscribe(() => {
        this.close();
      });
  }

  private buildDropdownOptions(componentData: Record<string, ComponentSlideoutOption>): Array<E2gSelectOption> {
    return Object.entries(componentData).map(([key, value]) => ({ value: key, label: value.name, type: value.type }));
  }

  private initializeSlideoutHeader(): void {
    this.slideOutContainerService.setUseDefaultHeaderBorder(false);
    this.slideOutContainerService.setOptions(this.buildDropdownOptions(this.slideOutData.optionsData!));
    this.slideOutContainerService.setOptionId(this.slideOutData.id);
    this.slideOutContainerService.setBreadcrumbs(this.slideOutData.breadcrumbs!);
    this.slideOutContainerService.setCustomNameError(duplicateItemNameMessage('Component'));
  }

  private initializeForm(id: string): void {
    const previousAssetId = this.assetId;
    const componentType: string = this.getTypeById(id);

    this.editing = id !== NEW_ITEM_KEY;
    this.slideOutData.id = id;
    this.assetId = this.editing ? this.slideOutData.optionsData![id].assetId : this.slideOutData.assetId;

    const assetChanged: boolean = previousAssetId !== this.assetId;

    if (assetChanged) {
      // We set this here so that the child component can use this.
      this.slideOutData.assetId = this.assetId;
      this.assetCache.data = undefined;
    }

    // If the component type has not changed, do not change the base component type.
    if (componentType !== this.previousComponentType) {
      this.determineBaseComponentType(componentType);
      this.previousComponentType = componentType;
    }

    // For the calculation to show the banner.
    this.alertService.updateIdsInEdit({ componentIds: [id] });

    if (this.editing) {
      this.stateService.setSelectedGridId(this.slideOutData.gridContext!, id);
    }

    this.refreshComponentData.next();
  }
}
