import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AppSettingsService } from './app-settings/app-settings.service';
import { Company } from './models/company.model';

@Injectable({
  providedIn: 'root'
})
export class CompanyDataService {
  public constructor(private http: HttpClient, private settingsService: AppSettingsService) {}

  // Gets a list of companies from the EEP server based on the list of products gotten from the user token
  public getCompaniesFromApi(): Observable<Array<Company>> {
    return this.http
      .get<Array<Company>>(`${this.settingsService.settings.apiUri}/companies`)
      .pipe(catchError(() => of(new Array<Company>())));
  }
}
