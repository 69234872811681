<div class="h-100">
  <div *ngIf="dataHandler.initialized && !loading; else load" class="h-100 flex-col flex-111">
    <ul ngbNav #nav="ngbNav" [(activeId)]="activeTabId" class="nav-tabs header-table mt-0">
      <li [ngbNavItem]="1">
        <a ngbNavLink>Design</a>
        <ng-template ngbNavContent>
          <div class="h-100 flex-col">
            <div class="h-100 flex-111 overflow-auto mt-2 ml-4 pr-4">
              <div class="row mt-2 mb-2">
                <div id="status-col-1" class="col-sm-4">
                  <e2g-ng-select-input
                    id="select-status"
                    heading="Status"
                    placeholder="Select Status"
                    [readonly]="readOnly"
                    [options]="statusList"
                    [(ngModel)]="dataHandler.data.status"
                    [required]="false"
                  ></e2g-ng-select-input>
                </div>
                <div id="status-col-1" class="col-sm-4">&nbsp;</div>
              </div>

              <div class="row mt-2 mb-2">
                <div id="description-col-1" class="col-sm-4">
                  <e2g-textarea-input
                    id="inp-description"
                    heading="Description"
                    [rows]="2"
                    [(ngModel)]="dataHandler.data.description"
                    maxlength="255"
                    [readonly]="readOnly"
                  ></e2g-textarea-input>
                </div>
                <div id="description-col-1" class="col-sm-4">&nbsp;</div>
              </div>

              <div class="form-section-header">Design</div>
              <div class="row mt-2">
                <div id="design-col-1" class="col-sm-4">
                  <app-br-date propName="inServiceDate" heading="In Service Date"></app-br-date>
                  <div class="mt-2">
                    <app-br-select propName="designCode" heading="Design Code"></app-br-select>
                  </div>
                  <div class="mt-2">
                    <app-br-text propName="designPressure" heading="Design Pressure" type="number"></app-br-text>
                  </div>
                  <div class="mt-2">
                    <app-br-text
                      propName="designTemperature"
                      heading="Design Temperature"
                      type="number"
                      [debounce]="true"
                    ></app-br-text>
                  </div>
                  <div class="mt-2">
                    <app-br-text
                      propName="shortSideJointEfficiency"
                      heading="Top/Bottom Joint Efficiency"
                      type="number"
                    ></app-br-text>
                  </div>
                  <div class="mt-2">
                    <app-br-text
                      propName="longSideJointEfficiency"
                      heading="Side Joint Efficiency"
                      type="number"
                    ></app-br-text>
                  </div>
                  <div class="mt-2">
                    <app-br-text
                      propName="endPlateJointEfficiency"
                      heading="End Plate Joint Efficiency"
                      type="number"
                    ></app-br-text>
                  </div>
                </div>
                <div id="design-col-2" class="col-sm-4">
                  <app-br-select propName="insulationType" heading="Insulated" placeholder="Select Insulation Type">
                  </app-br-select>
                  <div class="mt-2">
                    <div class="d-flex justify-content-between mt-2">
                      <e2g-input-heading heading="Group"></e2g-input-heading>
                      <a (click)="addNewCircuit()" class="modal-link" *ngIf="canAddCircuit && !readOnly">Add Group</a>
                    </div>
                    <e2g-ng-select-input
                      id="select-circuit"
                      placeholder="Select Group"
                      [disabled]="circuitList.length === 0"
                      [options]="circuitList || []"
                      [(ngModel)]="dataHandler.data.circuitId"
                      [required]="false"
                      [requiredForCalc]="dataHandler.data.rbi ?? false"
                      [warnings]="circuitWarnings"
                      (change)="createCircuitWarnings()"
                      [clearable]="true"
                      [readonly]="readOnly"
                    ></e2g-ng-select-input>
                  </div>
                  <div class="mt-2" *ngIf="dataHandler.data.maximumAllowableWorkingPressure || mawpWarnings.length > 0">
                    <app-br-text
                      propName="maximumAllowableWorkingPressure"
                      heading="MAWP"
                      type="number"
                      [disabled]="true"
                      [warnings]="mawpWarnings"
                    ></app-br-text>
                  </div>
                </div>
              </div>
              <hr class="separator" />
              <div class="form-section-header">Geometry</div>
              <div class="row mt-2">
                <div id="geom-col-1" class="col-sm-4">
                  <app-br-text propName="axialLength" heading="Axial Length" type="number"></app-br-text>
                  <div class="mt-2">
                    <app-br-text
                      propName="shortPlateInsideLength"
                      heading="Top/Bottom Plate Inside Length"
                      type="number"
                    ></app-br-text>
                  </div>
                  <div class="mt-2">
                    <app-br-text
                      propName="longPlateInsideLength"
                      heading="Side Plate Inside Length"
                      type="number"
                    ></app-br-text>
                  </div>
                </div>
                <div id="geom-col-2" class="col-sm-4">
                  <app-br-text propName="endPlateThickness" heading="End Plate Thickness" type="number"></app-br-text>
                  <div class="mt-2">
                    <app-br-text
                      propName="shortPlateThickness"
                      heading="Top/Bottom Plate Thickness"
                      type="number"
                    ></app-br-text>
                  </div>
                  <div class="mt-2">
                    <app-br-text
                      propName="longPlateThickness"
                      heading="Side Plate Thickness"
                      type="number"
                    ></app-br-text>
                  </div>
                </div>
                <div id="geom-col-3" class="col-sm-4">
                  <div>
                    <app-br-text
                      propName="endPlateCorrosionAllowance"
                      heading="End Plate Future Corrosion Allowance {{ corrosionAllowanceUom | async }}"
                      type="number"
                    ></app-br-text>
                  </div>

                  <div class="mt-2">
                    <app-br-text
                      propName="shortPlateCorrosionAllowance"
                      heading="Top/Bottom Plate Future Corrosion Allowance {{ corrosionAllowanceUom | async }}"
                      type="number"
                    ></app-br-text>
                  </div>

                  <div class="mt-2">
                    <app-br-text
                      propName="longPlateCorrosionAllowance"
                      heading="Side Plate Future Corrosion Allowance {{ corrosionAllowanceUom | async }}"
                      type="number"
                    ></app-br-text>
                  </div>
                </div>
              </div>

              <hr class="separator" />

              <div class="form-section-header">Material</div>
              <div class="row mt-2">
                <div class="h-100 flex-111">
                  <app-moc></app-moc>
                </div>
              </div>

              <hr class="separator" />

              <div class="d-flex flex-row mt-2 mb-2">
                <e2g-textarea-input
                  id="inp-comments"
                  class="w-100"
                  heading="Comments"
                  [rows]="5"
                  [readonly]="readOnly"
                  [maxlength]="512"
                  [(ngModel)]="dataHandler.data.comments"
                ></e2g-textarea-input>
              </div>
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink>Minimum Thickness</a>
        <ng-template ngbNavContent>
          <div class="flex-111 e2g-padding pt-2">
            <div class="row">
              <div class="col-4">
                <div class="row">
                  <div class="required dashboard-data-header">
                    Governing Minimum Thickness {{ thicknessUom | async }}: {{ getTmin() }}
                  </div>
                </div>

                <div class="row">
                  <div class="d-flex col align-items-center">
                    <div>
                      <app-br-check
                        heading="Nominal - CA"
                        labelPosition="right"
                        propName="isCorrodedThickness"
                        (change$)="checkIsValid()"
                        labelWidth="200px"
                      ></app-br-check>
                    </div>
                  </div>
                  <div class="d-flex col-lg-6 align-items-center">
                    <div>
                      {{
                        formatTminLabel(
                          dataHandler.data.shortPlateCorrodedThickness,
                          dataHandler.data.longPlateCorrodedThickness,
                          dataHandler.data.endPlateCorrodedThickness
                        )
                      }}
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="d-flex col align-items-center">
                    <div>
                      <app-br-check
                        heading="Specified Minimum Thickness"
                        labelPosition="right"
                        propName="isSpecifiedThickness"
                        (change$)="checkIsValid()"
                        labelWidth="200px"
                      ></app-br-check>
                    </div>
                  </div>
                  <div class="d-flex col-lg-6 align-items-center">
                    <div>
                      {{
                        formatTminLabel(
                          dataHandler.data.shortPlateSpecifiedMinimumThickness,
                          dataHandler.data.longPlateSpecifiedMinimumThickness,
                          dataHandler.data.endPlateSpecifiedMinimumThickness
                        )
                      }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4">
                <app-br-text
                  *ngIf="dataHandler.data.isSpecifiedThickness"
                  propName="shortPlateSpecifiedMinimumThickness"
                  heading="Top/Bottom Plate Specified Minimum Thickness {{ thicknessUom | async }}"
                  type="number"
                ></app-br-text>

                <div class="mt-2">
                  <app-br-text
                    *ngIf="dataHandler.data.isSpecifiedThickness"
                    propName="longPlateSpecifiedMinimumThickness"
                    heading="Side Plate Specified Minimum Thickness {{ thicknessUom | async }}"
                    type="number"
                  ></app-br-text>
                </div>
                <div class="mt-2">
                  <app-br-text
                    *ngIf="dataHandler.data.isSpecifiedThickness"
                    propName="endPlateSpecifiedMinimumThickness"
                    heading="End Plate Specified Minimum Thickness {{ thicknessUom | async }}"
                    type="number"
                  ></app-br-text>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="3" *ngIf="dataHandler.data.eqRbi && rbiEnabled">
        <a ngbNavLink>RBI</a>
        <ng-template ngbNavContent>
          <div class="h-100 flex-col">
            <div class="h-100 flex-111 overflow-auto mt-2 ml-4">
              <app-comp-rbi
                [compDataHandler]="dataHandler"
                [readOnly]="readOnly"
                [componentId]="dataHandler.data.id!"
                [(rbiActiveTabId)]="rbiActiveTabId"
                (rbiChange)="createCircuitWarnings()"
              ></app-comp-rbi>
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="4" *ngIf="diagVisible">
        <a ngbNavLink>Diagnostics</a>
        <ng-template ngbNavContent>
          <div class="h-100 flex-col">
            <div class="flex-111 overflow-auto mt-2 ml-4">
              <app-sage-diag
                [componentId]="compId"
                [showShell]="false"
                [showRbi]="dataHandler.data.rbi && dataHandler.data.eqRbi && rbiEnabled"
              ></app-sage-diag>
            </div>
          </div>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="flex-col h-100"></div>
  </div>
</div>

<ng-template #load>
  <div class="loading"></div>
</ng-template>
