export function createNotifyHandler<T extends object>(data: T, notifyPropertyChanged: (propName: any) => void): T {
  const handler: NotifyHandler<any> = {
    set(target: any, path: Array<string | number | symbol>) {
      notifyPropertyChanged(path.join('.'));
    },

    deleteProperty(target: any, path: Array<string | number | symbol>) {
      notifyPropertyChanged(path.join('.'));
    }
  };

  return createDeepProxy(data, handler);
}

interface NotifyHandler<T extends object> {
  set?: (target: T, path: Array<string | number | symbol>, newValue: any, receiver: any) => void;
  deleteProperty?: (target: T, path: Array<string | number | symbol>) => void;
}

function createDeepProxy<T extends object>(target: T, handler: NotifyHandler<T>): any {
  const preproxy = new WeakMap();

  function makeHandler(path: Array<string | number | symbol>): any {
    return {
      set(target: T, key: string | symbol, value: any, receiver: any): boolean {
        if (value !== null && typeof value === 'object') {
          value = proxify(value, [...path, key]);
        }
        target[key as keyof T] = value;

        if (handler.set) {
          handler.set(target, [...path, key], value, receiver);
        }
        return true;
      },

      deleteProperty(target: T, key: string | symbol): boolean {
        if (Reflect.has(target, key)) {
          unproxy(target, key as keyof T);
          const deleted = Reflect.deleteProperty(target, key);
          if (deleted && handler.deleteProperty) {
            handler.deleteProperty(target, [...path, key]);
          }
          return deleted;
        }
        return false;
      }
    };
  }

  function unproxy(obj: any, key: any): void {
    if (Array.isArray(obj)) {
      if (preproxy.has(obj)) {
        obj = preproxy.get(obj);
        preproxy.delete(obj);
      }
    } else if (Array.isArray(obj[key])) {
      if (preproxy.has(obj[key])) {
        obj = preproxy.get(obj[key]);
        preproxy.delete(obj[key]);
      }
      for (let i = 0; i < obj.length; i++) {
        if (obj[i] !== null && typeof obj[i] === 'object') {
          unproxy(obj, i);
        }
      }
    } else {
      if (preproxy.has(obj[key])) {
        obj[key] = preproxy.get(obj[key]);
        preproxy.delete(obj[key]);
      }

      for (const k of Object.keys(obj[key]) as Array<keyof T>) {
        if (obj[key][k] !== null && typeof obj[key][k] === 'object') {
          unproxy(obj[key], k);
        }
      }
    }
  }

  function proxify(obj: any, path: Array<string | number | symbol>): any {
    for (const key of Object.keys(obj)) {
      if (obj[key] !== null && typeof obj[key] === 'object') {
        if (Array.isArray(obj[key])) {
          for (let i = 0; i < (obj[key] as Array<any>).length; i++) {
            if (obj[key][i] !== null && typeof obj[key][i] === 'object') {
              obj[key][i] = proxify(obj[key][i], [...path, key, i]);
            }
          }
        }
        if (!Array.isArray(obj)) {
          obj[key] = proxify(obj[key], [...path, key]);
        }
      }
    }

    const p = new Proxy(obj, makeHandler(path));
    preproxy.set(p, obj);
    return p;
  }

  return proxify(target, []);
}
