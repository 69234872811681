import { Component } from '@angular/core';

import { ToastService } from '../toast.service';

@Component({
  selector: 'app-toasts-container',
  templateUrl: './toasts-container.component.html'
})
export class ToastsContainerComponent {
  public constructor(public toastService: ToastService) {}
}
