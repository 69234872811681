<div class="d-inline-flex align-items-center">
  <button id="btn-first" class="btn btn-nav" (click)="first()" [disabled]="prevDisabled()">
    <fa-icon [icon]="faFastBackward" size="lg" class="icon-secondary"></fa-icon>
  </button>
  <button id="btn-prev" class="btn btn-nav" (click)="prev()" [disabled]="prevDisabled()">
    <fa-icon [icon]="faStepBackward" size="lg" class="icon-secondary"></fa-icon>
  </button>
  <div>
    <input
      id="inp-page"
      class="text-center border rounded font-weight-bold"
      style="max-width: 80px"
      type="number"
      [(ngModel)]="currentPage"
      [min]="'1'"
      [max]="totalPages"
      (change)="updateData($event)"
      id="pageNumber"
    />
  </div>
  <div class="badge badge-light mx-2" style="font-size: 1rem">/</div>
  <div id="txt-total-pages" class="badge badge-light mr-2" style="font-size: 1rem">{{ totalPages }}</div>
  <button id="btn-next" class="btn btn-nav" (click)="next()" [disabled]="nextDisabled()">
    <fa-icon [icon]="faStepForward" size="lg" class="icon-secondary"></fa-icon>
  </button>
  <button id="btn-last" class="btn btn-nav" (click)="last()" [disabled]="nextDisabled()">
    <fa-icon [icon]="faFastForward" size="lg" class="icon-secondary"></fa-icon>
  </button>
</div>
