import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';

import { StateService } from '../state.service';
import { SlideOutConfig } from './models/slide-out-config';
import { SlideOutRef } from './slide-out-ref';

export interface ComponentType<T> {
  new (...args: any[]): T;
}

@Injectable({
  providedIn: 'root'
})
export class SlideOutService {
  public openCallback!: <R, C>(component: ComponentType<C>, config?: SlideOutConfig) => SlideOutRef<R, C>;
  public closeCallback!: () => void;
  private slideOutRef?: SlideOutRef<any, any>;

  public constructor(private state: StateService) {}

  public open<R, C>(component: ComponentType<C>, config?: SlideOutConfig): SlideOutRef<R, C> {
    this.slideOutRef = this.openCallback<R, C>(component, config);

    this.state.setSlideOutDisplay(true, config?.widthPercent);

    this.slideOutRef.closed.pipe(take(1)).subscribe(() => {
      this.closeCallback();
      this.state.setSlideOutDisplay(false);
    });

    return this.slideOutRef;
  }

  public close(): void {
    this.slideOutRef?.close();
  }
}
