import { MinimumThicknessChoice } from 'src/app/models/enums/minimum-thickness-choice';

export function getMinimumThicknessLabels(): Record<MinimumThicknessChoice, string> {
  return {
    [MinimumThicknessChoice.Retirement]: 'Code Retirement Thickness',
    [MinimumThicknessChoice.Structural]: 'Structural Required Thickness',
    [MinimumThicknessChoice.Corroded]: 'Nominal - CA',
    [MinimumThicknessChoice.Specified]: 'Component Specified',
    [MinimumThicknessChoice.CmlSpecified]: 'CML Specified',
    [MinimumThicknessChoice.MawpApproach]: 'Use MAWP Approach'
  };
}
