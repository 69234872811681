import { Injectable } from '@angular/core';
import { NotificationService } from 'src/app/shared-module/services/notification.service';
import { of } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { NameAndId } from 'src/app/models/name-and-id';
import { DueDateReportDto } from 'src/app/reporting-module/models/due-date-report-dto';
import { DialogService } from 'src/app/shared-module/dialog.service';
import { DialogButtons } from 'src/app/shared-module/models/dialog-buttons';
import { DialogData } from 'src/app/shared-module/models/dialog-data';
import { DialogResult } from 'src/app/shared-module/models/dialog-result';

import { JobNotificationService } from '../../notifications-module/services/job-notifications.service';
import { DueDateReportDialogComponent } from '../due-date-report-dialog/due-date-report-dialog.component';
import { DueDateReportBelowRetirements } from '../models/enums/due-date-report-below-retirements';
import { DueDateReportFileTypes } from '../models/enums/due-date-report-file-types';
import { DueDateReportReportLevels } from '../models/enums/due-date-report-report-levels';
import { DueDateReportTypes } from '../models/enums/due-date-report-types';
import { ReportingDataService } from '../reporting.data.service';

@Injectable({
  providedIn: 'root'
})
export class DueDateReportService {
  public constructor(
    private dialogService: DialogService,
    private dataService: ReportingDataService,
    private notificationService: NotificationService,
    private jobNotificationsService: JobNotificationService
  ) {}

  public run(): void {
    const formData: DueDateReportDto = {
      startDate: undefined,
      endDate: undefined,
      plants: new Array<NameAndId>(),
      units: new Array<NameAndId>(),
      reportType: DueDateReportTypes.Thickness,
      activityTypes: new Array<NameAndId>(),
      belowRetirement: DueDateReportBelowRetirements.Include,
      assetTypeIds: new Array<number>(),
      reportLevel: DueDateReportReportLevels.Asset,
      reportFileType: DueDateReportFileTypes.PDF,
      timeZoneOffset: new Date().getTimezoneOffset()
    };

    const dlgData: DialogData = {
      title: 'Due Date Report',
      buttons: DialogButtons.YesCancel,
      component: DueDateReportDialogComponent,
      componentData: formData,
      yesButtonText: 'Save Report',
      width: '930px',
      height: '600px'
    };

    this.dialogService
      .display(dlgData)
      .pipe(
        concatMap((dlgResult: DialogResult) => {
          if (dlgResult === DialogResult.yes) {
            this.notificationService.showInfo('Generating Due Date Report');
            return this.dataService.generateDueDateReport(formData);
          }
          return of(false);
        })
      )
      .subscribe(() => {
        this.jobNotificationsService.refreshNotifications();
      });
  }
}
