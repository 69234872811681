<div class="flex-col flex-111 e2g-padding pl-0 pr-0 pb-0 h-100" [ngClass]="{ readonly: readOnly }">
  <ul ngbNav #nav="ngbNav" [(activeId)]="activeTabId" class="py-0 d-inline-flex sub-nav-tabs w-100">
    <li [ngbNavItem]="1">
      <a ngbNavLink>Overview</a>
      <ng-template ngbNavContent>
        <div class="h-100 flex-col">
          <div class="flex-111 overflow-auto">
            <div class="d-flex row h-100 m-0 p-0 pl-4">
              <div class="col-sm-4 pl-0 pt-2">
                <e2g-text-input
                  heading="Name"
                  id="inp-name"
                  [(ngModel)]="dataHandler.data.name"
                  [readonly]="true"
                ></e2g-text-input>

                <div class="mt-2">
                  <e2g-textarea-input
                    heading="Description"
                    [rows]="2"
                    id="inp-description"
                    [(ngModel)]="dataHandler.data.description"
                    maxlength="255"
                    [readonly]="readOnly"
                  ></e2g-textarea-input>
                </div>

                <div class="mt-2">
                  <e2g-text-input
                    heading="Type"
                    id="inp-name"
                    [(ngModel)]="equipType"
                    [readonly]="true"
                  ></e2g-text-input>
                </div>

                <div class="mt-2">
                  <e2g-ng-select-input
                    id="select-service-status"
                    heading="Service Status"
                    placeholder="Select Service Status"
                    [disabled]="false"
                    [required]="false"
                    [readonly]="readOnly"
                    [isLoading]="!serviceStatusList"
                    [options]="serviceStatusList || []"
                    [(ngModel)]="dataHandler.data.serviceStatus"
                  ></e2g-ng-select-input>
                </div>

                <div class="mt-2">
                  <e2g-text-input
                    heading="External Asset ID"
                    id="inp-ext-asset-id"
                    [(ngModel)]="dataHandler.data.extAssetId"
                    [readonly]="readOnly"
                    [maxlength]="64"
                  ></e2g-text-input>
                </div>

                <div class="mt-2">
                  <e2g-text-input
                    heading="Serial Number"
                    id="inp-serial-number"
                    [(ngModel)]="dataHandler.data.serialNumber"
                    [readonly]="readOnly"
                    [maxlength]="255"
                  ></e2g-text-input>
                </div>

                <div class="mt-2">
                  <e2g-text-input
                    heading="Jurisdictional Registration Number"
                    id="inp-jurisdictional-registration-number"
                    [(ngModel)]="dataHandler.data.jurisdictionalRegistrationNumber"
                    [readonly]="readOnly"
                    [maxlength]="20"
                  ></e2g-text-input>
                </div>

                <div class="mt-2">
                  <e2g-text-input
                    heading="Manufacturer"
                    id="inp-manufacturer"
                    [(ngModel)]="dataHandler.data.manufacturer"
                    [readonly]="readOnly"
                    [maxlength]="255"
                  ></e2g-text-input>
                </div>

                <div class="mt-2">
                  <e2g-date-input
                    heading="In Service Date"
                    id="inp-inServiceDate"
                    [(ngModel)]="dataHandler.data.inServiceDate"
                    [readonly]="readOnly"
                    placeholder="mm/dd/yyyy"
                  ></e2g-date-input>
                </div>

                <div class="mt-2">
                  <e2g-text-input
                    heading="P&ID"
                    id="inp-pid"
                    [(ngModel)]="dataHandler.data.pid"
                    [readonly]="readOnly"
                    [maxlength]="255"
                  ></e2g-text-input>
                </div>

                <div class="mt-2">
                  <e2g-text-input
                    heading="Access Limitations"
                    id="inp-access-limitations"
                    [(ngModel)]="dataHandler.data.accessLimitations"
                    [readonly]="readOnly"
                    [maxlength]="255"
                  ></e2g-text-input>
                </div>
                <div class="mt-2">
                  <e2g-check-input
                    heading="Internal Access"
                    id="inp-internal-access"
                    labelPosition="right"
                    [(ngModel)]="dataHandler.data.hasInternalAccess"
                    [readonly]="readOnly"
                  ></e2g-check-input>
                </div>
              </div>

              <div class="col-sm-8 pt-2">
                <app-attachments
                  [tableType]="tableType"
                  [attachmentKey]="assetId"
                  [readonly]="readOnly"
                ></app-attachments>
              </div>
            </div>
          </div>
          <div class="control-flow-container" *ngIf="!readOnly">
            <div class="d-flex justify-content-end">
              <div class="d-flex">
                <button class="btn e2g-btn-outline-primary mr-2" (click)="revertChanges()" *ngIf="isDirty()">
                  Revert Changes
                </button>
                <button id="btn-save" class="btn e2g-btn-solid-primary" (click)="save()" [disabled]="!isDirty()">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="2">
      <a ngbNavLink>Design</a>
      <ng-template ngbNavContent>
        <div class="h-100 flex-col">
          <div class="flex-111 overflow-auto">
            <div class="col h-100 pl-2 pt-2">
              <div id="design-col-1" class="col-sm-4">
                <e2g-ng-select-input
                  id="sel-design-code"
                  [(ngModel)]="dataHandler.data.designCode"
                  heading="Design Code"
                  [options]="codeValues"
                  placeholder="Select Design Code"
                  [requiredForCalc]="true"
                  [readonly]="readOnly"
                >
                </e2g-ng-select-input>
                <div class="mt-2" *ngIf="isPipingSystem()">
                  <e2g-ng-select-input
                    heading="Class"
                    id="sel-api-service-class"
                    placeholder="Select API Pipe Class"
                    [required]="false"
                    [options]="apiServiceClassList"
                    [(ngModel)]="dataHandler.data.apiServiceClassId"
                    [warnings]="apiClassWarnings"
                    (change)="apiClassSelected()"
                    [readonly]="readOnly"
                    [requiredForCalc]="true"
                  ></e2g-ng-select-input>
                </div>
                <div class="mt-2">
                  <app-br-text
                    id="txt-design-pressure"
                    propName="designPressure"
                    heading="Design Pressure"
                    type="number"
                    [readOnly]="readOnly"
                  >
                  </app-br-text>
                </div>

                <div class="mt-2">
                  <app-br-text
                    id="txt-design-temperature"
                    propName="designTemperature"
                    heading="Design Temperature"
                    type="number"
                    [readOnly]="readOnly"
                  >
                  </app-br-text>
                </div>

                <div class="mt-2">
                  <app-br-text
                    id="txt-fluid-mass"
                    propName="fluidMass"
                    heading="Fluid Mass"
                    type="number"
                    [readOnly]="true"
                  ></app-br-text>
                </div>

                <div class="mt-2">
                  <e2g-date-input
                    id="txt-inservice-date"
                    heading="In Service Date"
                    id="inp-inServiceDate"
                    [(ngModel)]="dataHandler.data.inServiceDate"
                    [readonly]="readOnly"
                    placeholder="mm/dd/yyyy"
                  ></e2g-date-input>
                </div>
              </div>
              <div *ngIf="isTank()" id="design-col-2" class="col-sm-4">
                <app-br-text
                  id="txt-tank-fill-height"
                  propName="tankFillHeight"
                  heading="Fill Height"
                  type="number"
                  [readOnly]="readOnly"
                ></app-br-text>
                <div class="mt-2">
                  <e2g-text-input
                    id="txt-tank-liquid-specific-gravity"
                    [(ngModel)]="dataHandler.data.tankLiquidSpecificGravity"
                    heading="Liquid Specific Gravity"
                    type="number"
                    [requiredForCalc]="true"
                    [readonly]="readOnly"
                  ></e2g-text-input>
                </div>
                <div class="mt-2">
                  <app-br-text
                    id="txt-tank-nominal-diameter"
                    propName="tankNominalDiameter"
                    heading="Tank Nominal Diameter"
                    type="number"
                    [readOnly]="readOnly"
                  ></app-br-text>
                </div>
                <div class="mt-2">
                  <e2g-text-input
                    id="txt-tank-joint-efficiency"
                    [(ngModel)]="dataHandler.data.tankJointEfficiency"
                    heading="Joint Efficiency"
                    type="number"
                    [requiredForCalc]="true"
                    [readonly]="readOnly"
                  ></e2g-text-input>
                </div>
                <!-- <div class="d-flex align-items-middle mt-3">
              <input
                type="checkbox"
                class="form-checkbox"
                [(ngModel)]="dataHandler.data.tank653"
                [disabled]="readonly"
              />
              <div class="ml-2">Manage as API 653</div>
            </div> -->
                <div class="mt-2">
                  <e2g-text-input
                    id="txt-tank-courses"
                    [(ngModel)]="dataHandler.data.tankCourses"
                    heading="Number Of Courses"
                    [disabled]="true"
                  ></e2g-text-input>
                </div>
              </div>
            </div>
          </div>
          <div class="control-flow-container" *ngIf="!readOnly">
            <div class="d-flex justify-content-end">
              <div class="d-flex">
                <button class="btn e2g-btn-outline-primary mr-2" (click)="revertChanges()" *ngIf="isDirty()">
                  Revert Changes
                </button>
                <button id="btn-save" class="btn e2g-btn-solid-primary" (click)="save()" [disabled]="!isDirty()">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </li>

    <li [ngbNavItem]="3" *ngIf="sageEnabled">
      <a ngbNavLink>SAGE</a>
      <ng-template ngbNavContent>
        <div class="h-100 flex-col">
          <div class="flex-111 overflow-auto">
            <div class="col h-100 pl-2 pt-2">
              <app-sage-asset-tree></app-sage-asset-tree>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav" id="tab-content" class="h-100 flex-col flex-111"></div>
</div>
