<div class="row mt-2" *ngIf="initialized">
  <div id="geom-col-1" class="col-sm-4">
    <div *ngIf="!dataHandler.data.nonStandardTube">
      <app-br-select propName="outerDiameter" heading="Outer Diameter" (change$)="odTrigger.next()"></app-br-select>

      <div class="mt-2">
        <app-br-select
          propName="bwg"
          heading="Tube Gauge (BWG)"
          (change$)="bwgTrigger.next()"
          placeholder="Select Gauge"
          [otherTriggers]="[odTrigger]"
          [triggerChangeOnSingleValidValueSelect]="true"
          [disabled]="dataHandler.data.outerDiameter == undefined"
        ></app-br-select>
      </div>
    </div>
    <app-br-text
      *ngIf="!!dataHandler.data.nonStandardTube"
      propName="outerDiameter"
      heading="Outer Diameter"
      (change$)="odTextTrigger.next()"
      type="number"
    ></app-br-text>

    <div class="mt-2">
      <app-br-text
        propName="nominalThickness"
        heading="Nominal Thickness"
        [disabled]="!dataHandler.data.nonStandardTube"
        type="number"
        (change$)="nominalTrigger.next()"
        [otherTriggers]="[odTrigger, bwgTrigger]"
      ></app-br-text>
    </div>
    <div class="mt-2">
      <app-br-text propName="futureCorrosionAllowance" heading="Corrosion Allowance" type="number"></app-br-text>
    </div>
    <div class="mt-2">
      <app-br-text
        propName="innerDiameter"
        heading="Inner Diameter"
        [disabled]="true"
        type="number"
        [otherTriggers]="[odTrigger, bwgTrigger, nominalTrigger, odTextTrigger]"
      ></app-br-text>
    </div>
    <div class="mt-2">
      <app-br-check
        heading="Non Standard Tube"
        propName="nonStandardTube"
        (change$)="changeNsTube()"
        labelPosition="right"
      >
      </app-br-check>
    </div>
  </div>
  <div id="geom-col-2" class="col-sm-4">
    <app-br-text propName="length" heading="Length {{ lengthUom }}" type="number"></app-br-text>
    <div class="mt-2">
      <app-br-text propName="quantity" heading="Quantity" type="number"></app-br-text>
    </div>
  </div>
</div>
