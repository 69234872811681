import { Component, Inject, ViewChild } from '@angular/core';
import { E2gSelectOption } from '@equityeng/e2g-ng-ui';
import { AgGridAngular } from 'ag-grid-angular';
import { CellEditingStoppedEvent, RowDataUpdatedEvent, StartEditingCellParams } from 'ag-grid-community';
import { DmlDetailBaseComponent } from 'src/app/dmls-module/dml-slideout/dml-detail-base-component';
import { DmlAssessmentLevels } from 'src/app/dmls-module/models/Enums/dml-assessment-levels';
import { DmlStatuses } from 'src/app/dmls-module/models/Enums/dml-statuses';
import { DmlTypes } from 'src/app/dmls-module/models/Enums/dml-types';
import { buildDefaultColDef } from 'src/app/grid-module/column-builders/build-default';
import {
  getDmlCreepOperatingConditionsGridColumns
} from 'src/app/grid-module/column-definitions/dml-creep-operating-conditions-grid-columns';
import { GridBaseOptions } from 'src/app/grid-module/e2g-ag-grid/e2g-ag-grid.component';
import { SLIDE_OUT_DATA } from 'src/app/slide-out-module/slide-out-data-injection-token';
import { enumToSelectOptions } from 'src/app/utilities/enum-helper';

import { CreepOperatingConditions } from '../../models/creep-operating-conditions';
import { DmlCreepDto } from '../../models/dml-creep-dto';
import { DmlSlideoutInput } from '../../models/dml-slideout-input';
import { DmlCreepWeldTypes } from '../../models/Enums/dml-creep-weld-types';
import { PitCouple } from '../../models/pit-couple';

@Component({
  selector: 'app-dml-creep',
  templateUrl: './dml-creep.component.html'
})
export class DmlCreepComponent extends DmlDetailBaseComponent<DmlCreepDto> {
  @ViewChild('creepOperatingConditionsDataGrid', { static: false })
  public creepOperatingConditionsDataGrid?: AgGridAngular;

  protected componentsShellModuleOnly = true;

  public nameErrors = [] as string[];

  public weldTypeList: Array<E2gSelectOption> = enumToSelectOptions(DmlCreepWeldTypes);

  public pinnedData: Array<CreepOperatingConditions> = this.getPinnedTopData();
  public setFirstCellAsEditable: boolean = false;
  public gridOptions: GridBaseOptions = {
    exportFileName: 'Creep DML',
    getRowId: undefined,
    context: 'creepOperatingConditionsData',
    pinnedTopRowData: this.pinnedData,
    enterNavigatesVertically: false,
    enterNavigatesVerticallyAfterEdit: false,
    enableRangeSelection: false,
    rowSelection: 'single',
    stopEditingWhenCellsLoseFocus: true,
    onCellEditingStopped: this.onCellEditingStopped.bind(this),
    onRowDataUpdated: this.onRowDataUpdated.bind(this),
    domLayout: 'autoHeight',
    defaultColDef: {
      ...buildDefaultColDef,
      editable: true,
      width: 200,
      wrapHeaderText: true,
      autoHeaderHeight: true
    },
    columnDefs: getDmlCreepOperatingConditionsGridColumns()
  };

  public constructor(@Inject(SLIDE_OUT_DATA) protected slideOutData: DmlSlideoutInput) {
    super(slideOutData);
  }

  private onCellEditingStopped(event: CellEditingStoppedEvent): void {
    this.dataHandler!.data.creepOperatingConditionsData = [...this.dataHandler!.data.creepOperatingConditionsData!];
    event.api.refreshCells({ force: true, rowNodes: [event.api.getDisplayedRowAtIndex(event.rowIndex!)!] });
  }

  private onRowDataUpdated(event: RowDataUpdatedEvent): void {
    if (this.setFirstCellAsEditable) {
      const params: StartEditingCellParams = {
        rowIndex: 0,
        colKey: 'conditionDuration'
      };

      event.api.startEditingCell(params);

      this.setFirstCellAsEditable = false;
    }
  }

  public addCreepOperatingConditionsData(): void {
    this.setFirstCellAsEditable = true;

    this.dataHandler!.data.creepOperatingConditionsData = [
      {
        id: undefined,
        conditionDuration: 0.0,
        temperature: 0.0,
        pressure: 0.0,
        innerDiameterCorrosionRate: 0.0,
        outerDiameterCorrosionRate: 0.0,
        axialForce: 0.0,
        bendingMoment: 0.0
      } as CreepOperatingConditions,
      ...this.dataHandler!.data.creepOperatingConditionsData!
    ];
  }

  public deleteCreepOperatingConditionsData(): void {
    const selectedData = this.creepOperatingConditionsDataGrid!.api.getSelectedRows()[0] as PitCouple;

    if (selectedData !== undefined) {
      this.dataHandler!.data.creepOperatingConditionsData = [
        ...this.dataHandler!.data.creepOperatingConditionsData!.filter((x) => x !== selectedData)
      ];
    }
  }

  private getPinnedTopData(): Array<CreepOperatingConditions> {
    return [];
  }

  public rowSelected(): boolean {
    if (this.creepOperatingConditionsDataGrid !== undefined) {
      if (this.dataHandler.data.creepOperatingConditionsData!.length > 0) {
        const selectedData = this.creepOperatingConditionsDataGrid.api?.getSelectedRows() as Array<PitCouple>;

        if (selectedData !== undefined) {
          return selectedData[0] !== undefined ? true : false;
        }
      }
    }

    return false;
  }

  public componentChange(): void {
    this.slideOutService.setBodyValid(true);
  }

  protected makeNewDml(): DmlCreepDto {
    return {
      name: '',
      type: DmlTypes['Creep'],
      creepOperatingConditionsData: [] as Array<CreepOperatingConditions>,
      hasCalculationErrors: false,
      status: DmlStatuses.New,
      assessmentLevel: DmlAssessmentLevels['Level 1'],
      weldType: DmlCreepWeldTypes['None']
    };
  }
}
