import { ValueFormatterParams } from 'ag-grid-community';
import { HeaderBoxPlateType } from 'src/app/asset-module/models/enums/header-box-plate-type';

export function compTypeValueFormatter(params: ValueFormatterParams): string {
  const compType = params.data.componentType;
  if (compType == 'HEADER BOX') {
    switch (params.data.headerBoxPlate as HeaderBoxPlateType) {
      case HeaderBoxPlateType.Top:
        return compType + '-TOP';
      case HeaderBoxPlateType.Side:
        return compType + '-SIDE';
      case HeaderBoxPlateType.End:
        return compType + '-END';
    }
  }
  return compType;
}
