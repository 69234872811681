import { TemplateRef, Type } from '@angular/core';

import { DialogButtons } from './dialog-buttons';
import { DialogResult } from './dialog-result';

export interface DialogData {
  dialogId?: string;
  title?: string;
  message?: string;
  template?: TemplateRef<any>;
  component?: Type<any>;
  componentData?: any;
  buttons: DialogButtons;

  yesButtonText?: string;
  noButtonText?: string;
  cancelButtonText?: string;

  width?: string;
  height?: string;

  validityCallback?: () => boolean;
  beforeCloseCallback?: (result: DialogResult) => void;
}

export const SaveChangesDialog: DialogData = {
  title: 'Save Changes?',
  message: 'Unsaved changes will be lost. Save?',
  yesButtonText: 'Save Changes',
  noButtonText: 'Abandon Changes',
  buttons: DialogButtons.YesNoCancel
};
