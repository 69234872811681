<div class="row">
  <div class="col-md-6 col-sm-12">
    <e2g-ng-select-input
      id="select-plant"
      heading="Plant"
      placeholder="Select Plant"
      [required]="true"
      [isLoading]="!plantList"
      [errors]="plantErrors"
      [options]="plantList || []"
      [(ngModel)]="selectedPlant"
      (change)="onChangePlant()"
    ></e2g-ng-select-input>
  </div>
  <div class="col-md-6 col-sm-12">
    <e2g-multi-select-input
      heading="Units"
      id="select-units"
      [(ngModel)]="selectedUnitIds"
      [isLoading]="!unitList"
      [options]="unitList ?? []"
      [required]="true"
      [displayLimit]="displayLimit"
      (change)="onUnitChange()"
      [isSelectAll]="true"
    >
    </e2g-multi-select-input>
  </div>
</div>
<div class="row mt-2">
  <div class="col-sm-12">
    <e2g-multi-select-input
      heading="Asset"
      id="select-equipment"
      [(ngModel)]="formData.assetIds"
      [isLoading]="!equipmentOptions"
      [options]="equipmentOptions!"
      [required]="true"
      [displayLimit]="displayLimit"
      [isSelectAll]="true"
    >
    </e2g-multi-select-input>
  </div>
</div>

<ng-template #loadingInline>
  <div>
    <div class="loading-inline"></div>
  </div>
</ng-template>
