import { Injectable } from '@angular/core';
import { E2gSelectOption } from '@equityeng/e2g-ng-ui';
import { MaterialSelection } from '@equityeng/material-picker';
import { map, Observable, of, take } from 'rxjs';

import { BusinessRulesServiceBase } from '../sage-common-module/BusinessRulesServiceBase';
import { BusinessRuleValueList } from '../sage-common-module/models/business-rule-value-list';
import { BusinessRuleValues } from '../sage-common-module/models/business-rule-values';
import { BusinessRulesDefinition } from '../sage-common-module/models/business-rules-definition';
import { DefaultValueLookup } from '../sage-common-module/models/enums/default-value-lookup';
import { FunctionalDefaultRequestDto } from '../sage-common-module/models/functional-default-request-dto';
import { HubValueRequestDto } from '../sage-common-module/models/hub-value-request-dto';
import { SageDataService } from '../sage-common-module/sage-data.service';
import { ValueDataService } from '../sage-common-module/value-data.service';
import { UnitsOfMeasureEvaluator } from '../units-of-measure/units-of-measure-evaluator';
import { DESIGN_CODE_SAGE_VALUES } from '../utilities/design-code-helper';
import { getToughnessCurveOptions, getToughnessCurveValue } from './toughness-curve-options';

@Injectable()
export class MaterialBusinessRulesService extends BusinessRulesServiceBase implements BusinessRulesDefinition {
  private compReq: FunctionalDefaultRequestDto = {
    module: 'component',
    column: 1,
    unitSystem: this.unitSystem,

    keywordName: '',
    function: '',
    data: {}
  };

  private defaultRequest: HubValueRequestDto = {
    lookupType: DefaultValueLookup.MaterialGroups,
    keywordName: '',
    data: {},
    unitSystem: this.unitSystem
  };

  public constructor(
    private sageDataService: SageDataService,
    private valueDataService: ValueDataService,
    uomEvaluator: UnitsOfMeasureEvaluator
  ) {
    super(uomEvaluator);
  }

  protected override afterInit(): void {
    this.dataHandler.addListener('designCode', () => this.updateExistingMaterialProperties());
    this.dataHandler.addListener('designTemperature', () => this.updateExistingMaterialProperties());
  }

  private getCodeAndMaterial(data: any): any {
    return {
      CONST_CODE: DESIGN_CODE_SAGE_VALUES[data.designCode!],
      B_MSPEC: [data.specification, data.grade, data.year, data.uns, data.cct, data.st, data.modifier]
    };
  }

  private getCodeMaterialAndTemperature(data: any): any {
    return {
      ...this.getCodeAndMaterial(data),
      DTEMP: this.data.designTemperature
    };
  }

  public get valueData(): BusinessRuleValueList {
    if (this.data === undefined) {
      throw 'init must be called before valueData()';
    } else {
      return {
        allowableTensileStress: {
          getDefaultValue: () =>
            this.sageDataService.getFunctionalDefaultValue({
              ...this.compReq,
              keywordName: 'SA',
              function: 'SA_FROM_SPEC(CONST_CODE,B_MSPEC,DTEMP)',
              data: this.getCodeMaterialAndTemperature(this.data)
            })
        },
        yieldStress: {
          getDefaultValue: () =>
            this.sageDataService.getFunctionalDefaultValue({
              ...this.compReq,
              keywordName: 'SY',
              function: 'SY_FROM_SPEC(CONST_CODE,B_MSPEC,DTEMP)',
              data: this.getCodeMaterialAndTemperature(this.data)
            })
        },
        ultimateTensileStress: {
          getDefaultValue: () =>
            this.sageDataService.getFunctionalDefaultValue({
              ...this.compReq,
              keywordName: 'UTS',
              function: 'UTS_FROM_SPEC(CONST_CODE,B_MSPEC,DTEMP)',
              data: this.getCodeMaterialAndTemperature(this.data)
            })
        },
        youngsModulus: {
          getDefaultValue: () =>
            this.sageDataService.getFunctionalDefaultValue({
              ...this.compReq,
              keywordName: 'EY',
              function: 'EY_FROM_SPEC(CONST_CODE,B_MSPEC,DTEMP)',
              data: this.getCodeMaterialAndTemperature(this.data)
            })
        },
        externalPressureChart: {
          getDefaultValue: () =>
            this.sageDataService.getFunctionalDefaultValue({
              ...this.compReq,
              keywordName: 'EXTCHRT',
              function: 'CHART_FROM_SPEC(CONST_CODE,B_MSPEC)',
              data: this.getCodeAndMaterial(this.data)
            }),
          getValidValues: () =>
            this.sageDataService.getFunctionalValidValues({
              ...this.compReq,
              keywordName: 'EXTCHRT',
              function: 'CHART_FROM_CODE(CONST_CODE,B_MSPEC)',
              data: this.getCodeAndMaterial(this.data)
            })
        },
        toughnessCurveDesignation: {
          getDefaultValue: () =>
            this.sageDataService
              .getFunctionalDefaultValue({
                ...this.compReq,
                keywordName: 'TCUR',
                function: 'TCUR_FROM_SPEC(CONST_CODE,B_MSPEC,FURN_THK)',
                data: {
                  ...this.getCodeAndMaterial(this.data),
                  FURN_THK:
                    this.data.compType === 'HEADER BOX' ? this.data.longPlateThickness : this.data.nominalThickness
                }
              })
              .pipe(map((x) => getToughnessCurveValue(x, this.data.designCode, this.data.assetType))),
          getValidValues: (): Observable<Array<E2gSelectOption>> => {
            return of(getToughnessCurveOptions(this.data.designCode, this.data.assetType));
          }
        },
        unsGroup: {
          getDefaultValue: () =>
            this.sageDataService.getFunctionalDefaultValue({
              ...this.compReq,
              keywordName: 'B_MATTYPE',
              function: 'MATTYPE_1_FROM_SPEC(CONST_CODE,B_MSPEC)',
              data: this.getCodeAndMaterial(this.data)
            }),
          getValidValues: () =>
            this.valueDataService.getValidValues({
              ...this.defaultRequest,
              keywordName: 'unsGroup'
            })
        },
        unsSubGroup: this.unsSubGroup,
        materialCommonName: this.materialCommonName
      };
    }
  }

  public unsSubGroup: BusinessRuleValues = {
    getDefaultValue: () =>
      this.sageDataService.getFunctionalDefaultValue({
        ...this.compReq,
        keywordName: 'B_MATTYPE',
        column: 2,
        function: 'MATTYPE_2_FROM_SPEC(CONST_CODE,B_MSPEC)',
        data: this.getCodeAndMaterial(this.data)
      }),
    getValidValues: () =>
      this.data.unsGroup !== undefined
        ? this.valueDataService.getValidValues({
            ...this.defaultRequest,
            keywordName: 'unsSubGroup',
            data: {
              unsGroup: this.data.unsGroup
            }
          })
        : of([]),
    getDisableReset: () => !this.dataHandler.isDefaultValue('unsGroup')
  };

  public materialCommonName: BusinessRuleValues = {
    getDefaultValue: () =>
      this.sageDataService.getFunctionalDefaultValue(
        {
          ...this.compReq,
          keywordName: 'B_MATTYPE',
          column: 3,
          function: 'MATTYPE_3_FROM_SPEC(CONST_CODE,B_MSPEC))',
          data: this.getCodeAndMaterial(this.data)
        },
        true
      ),
    getValidValues: () =>
      this.data.unsGroup !== undefined && this.data.unsSubGroup !== undefined
        ? this.valueDataService.getValidValues({
            ...this.defaultRequest,
            keywordName: 'materialCommonName',
            data: {
              unsGroup: this.data.unsGroup,
              unsSubGroup: this.data.unsSubGroup
            }
          })
        : of([]),
    getDisableReset: () =>
      !this.dataHandler.isDefaultValue('unsGroup') || !this.dataHandler.isDefaultValue('unsSubGroup')
  };

  public get isMaterialChosen(): boolean {
    return this.data.specification !== undefined;
  }

  public getMaterial(): MaterialSelection {
    const materialSelection = new MaterialSelection();
    materialSelection.code = DESIGN_CODE_SAGE_VALUES[this.data.designCode!];
    materialSelection.spec = this.data.specification;
    materialSelection.grade = this.data.grade;
    materialSelection.year = this.data.year;
    materialSelection.uns = this.data.uns;
    materialSelection.cct = this.data.cct;
    materialSelection.size = this.data.st;
    materialSelection.fullModifierCompactFormat = this.data.modifier;
    return materialSelection;
  }

  public setMaterial(selectedMaterial: MaterialSelection): void {
    this.data.specification = selectedMaterial.spec;
    this.data.grade = selectedMaterial.grade;
    this.data.year = selectedMaterial.year;
    this.data.uns = selectedMaterial.uns;
    this.data.cct = selectedMaterial.cct;
    this.data.st = selectedMaterial.size;
    this.data.modifier = selectedMaterial.fullModifierCompactFormat;
    this.setAllDefaultValues();
  }

  private updateExistingMaterialProperties(): void {
    if (this.isMaterialChosen) {
      this.setAllDefaultValues();
    }
  }

  private setAllDefaultValues(): void {
    Object.keys(this.valueData).forEach((name) => {
      const valueData = this.valueData[name];
      if (valueData.getDefaultValue && this.canSetDefaultValue(valueData, name)) {
        valueData
          .getDefaultValue()
          .pipe(take(1))
          .subscribe((data) => {
            this.dataHandler.silentClearValue(name);
            this.dataHandler.silentSetValue(name, data, true);
          });
      }
    });
  }

  private canSetDefaultValue(valueData: BusinessRuleValues, prop: string): boolean {
    // It's safe to set a new default value when the field is undefined, or contains a prior default value
    // Don't overwrite a field where the user modified the value, except for dropdown fields
    // The previous user selection might not be in the new list of valid values
    // In the future, we could potentially fetch the valid values and see if the previous user selection is still valid
    return (
      this.dataHandler.data[prop] == undefined ||
      valueData.getValidValues != undefined ||
      this.dataHandler.isDefaultValue(prop)
    );
  }
}
