import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { filter, map, Observable, of, startWith, switchMap } from 'rxjs';
import { AssetDetailTabs } from 'src/app/asset-module/detail/detail.component';
import { UnitOfMeasure } from 'src/app/models/enums/unit-of-measure';

import { SourceLocation } from '../models/Enums/source-location';
import { ModuleRouteData } from './sage-nav.service';

@Injectable({
  providedIn: 'root'
})
export class RouteParamsService {
  private activatedRoute: Observable<Params>;

  public constructor(private router: Router, private route: ActivatedRoute) {
    this.activatedRoute = this.router.events.pipe(
      filter((e) => e instanceof NavigationEnd),
      startWith(undefined),
      switchMap(() => this.getLastChild(this.route)?.params || of([]))
    );
  }

  public getAssetRouteData(): Observable<AssetRouteData> {
    return this.activatedRoute.pipe(
      map<Params, AssetRouteData>((params: Params) => ({
        unitKey: params.unitKey,
        unitOfMeasure: params.unitOfMeasure === 'UsStandard' ? UnitOfMeasure.UsStandard : UnitOfMeasure.Metric,
        equipmentKey: params.equipmentKey,
        activeTabId: params.activeTabId ? Number.parseInt(params.activeTabId) : AssetDetailTabs.Asset,
        subTab: params.subTab ? Number.parseInt(params.subTab) : 1,
        unitNav: params.unitNav ?? false,
        sourceLocation: params.sourceLocation
      }))
    );
  }

  public getSageRouteData(): Observable<ModuleRouteData> {
    const delimeter = '_';
    const keyValueSeparator = ':';
    const routeData: any = { moduleId: '' };

    return this.activatedRoute.pipe(
      filter((params) => params.id),
      map<Params, ModuleRouteData>((params: Params) => {
        params.id
          .split(delimeter)
          .map((x: string) => x.split(keyValueSeparator))
          .forEach((x: string) => {
            routeData[x[0]] = x[1];
          });

        return routeData;
      })
    );
  }

  private getLastChild(route: ActivatedRoute): ActivatedRoute | undefined {
    let currentRoute = route;
    while (currentRoute.firstChild) {
      currentRoute = currentRoute.firstChild;
    }
    return currentRoute;
  }
}

//TODO Simplify/rename params
export interface AssetRouteData {
  unitKey: string;
  equipmentKey: string;
  unitOfMeasure: UnitOfMeasure;
  activeTabId?: number;
  subTab?: number;
  sourceLocation?: SourceLocation; //TODO Replace with unitNav
  unitNav?: boolean; //Only used when navigating from unit details
}
