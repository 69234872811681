import { ColDef, ValueGetterParams } from 'ag-grid-community';

import { GridCellLinkParams } from '../cell-renders/grid-cell-link/grid-cell-link-params';
import { GridCellLinkComponent } from '../cell-renders/grid-cell-link/grid-cell-link.component';
import { stringWithNullComparer } from '../column-data-comparers/string-with-blank-comparator';
import { assignHeaderAndTooltip } from './build-default';

export function buildArrayColDef(header: string, field: string, params?: GridCellLinkParams): ColDef {
  return {
    ...assignHeaderAndTooltip(header, field),
    cellRenderer: params != undefined ? GridCellLinkComponent : undefined,
    cellRendererParams: params,
    valueGetter: commaDelimitedListGetter,
    comparator: stringWithNullComparer
  };
}

function commaDelimitedListGetter(params: ValueGetterParams): string {
  //Alphabetize??
  return (params.data[params.colDef.field!] as string[]).join(', ');
}
