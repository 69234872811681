import { Component, Input } from '@angular/core';

import { ActivityDetailsViewModel } from './activity-details-vm';

export enum ActivityDetailsTab {
  Readings = 1,
  Details = 2,
  Findings = 3
}

@Component({
  selector: 'app-activity-details',
  templateUrl: './activity-details.component.html',
  styleUrls: ['./activity-details.component.css']
})
export class ActivityDetailsComponent {
  @Input() public vm!: ActivityDetailsViewModel;

  public ActivityDetailsTab = ActivityDetailsTab;
}
