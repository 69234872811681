import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AppSettingsService } from './app-settings/app-settings.service';
import { AssetDefaultsConfigurationDto } from './asset-module/models/asset-defaults-configuration-dto';
import { EquipmentFormViewModel } from './hierarchy-tree-module/models/equipment-form-view-model';
import { CmlDefaultValuesFromAsset } from './models/cml-default-values-from-asset';
import { EquipmentBreadcrumbsDto } from './models/equipment-breadcrumbs-dto';
import { EquipmentConfigurationDto } from './models/equipment-configuration-dto';
import { EquipmentDto } from './models/equipment-dto';
import { AddModuleData } from './sandbox-tree-module/models/add-module-data';
import { AssetCopyFormData } from './shared-module/models/asset-copy-form-data';

@Injectable({
  providedIn: 'root'
})
export class EquipmentDataService {
  private serverUri: string;

  public constructor(private http: HttpClient, private settingsService: AppSettingsService) {
    this.serverUri = this.settingsService.settings.apiUri as string;
  }

  public getEquipment(unitId: string): Observable<Array<EquipmentDto>> {
    return this.http
      .get<Array<EquipmentDto>>(`${this.serverUri}/equipment/unit/${unitId}`)
      .pipe(catchError(() => of(new Array<EquipmentDto>())));
  }

  public getEquipmentForUnitIds(unitIds: Array<string>): Observable<Array<EquipmentDto>> {
    return this.http
      .post<Array<EquipmentDto>>(`${this.serverUri}/equipment/units`, unitIds)
      .pipe(catchError(() => of(new Array<EquipmentDto>())));
  }

  public getSingleEquipment(equipmentId: string): Observable<EquipmentDto> {
    return this.http.get<EquipmentDto>(`${this.serverUri}/equipment/${equipmentId}`);
  }

  public getEquipmentBreadcrumbs(equipmentId: string): Observable<EquipmentBreadcrumbsDto> {
    return this.http
      .get<EquipmentBreadcrumbsDto>(`${this.serverUri}/equipment/${equipmentId}/breadcrumbs`)
      .pipe(catchError(() => of({} as EquipmentBreadcrumbsDto)));
  }

  public saveEquipment(dto: EquipmentDto): Observable<boolean> {
    return this.http.put<boolean>(`${this.serverUri}/equipment`, dto).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  public getEquipmentType(equipmentId: string): Observable<string> {
    return this.http.get<string>(`${this.serverUri}/equipment/type/${equipmentId}`);
  }

  public addAsset(newAsset: EquipmentFormViewModel): Observable<string> {
    return this.http.post<string>(`${this.serverUri}/equipment`, newAsset).pipe(catchError(() => of('')));
  }

  public renameAsset(id: string, name: string): Observable<boolean> {
    return this.http.put<boolean>(`${this.serverUri}/equipment/rename`, { id, name }).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  public deleteAsset(assetId: string): Observable<boolean> {
    return this.http.delete<boolean>(`${this.serverUri}/equipment/${assetId}`).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  public updateConfiguration(configuration: EquipmentConfigurationDto, equipmentId: string): Observable<boolean> {
    // Fixes issue if field is blank but not undefined
    if (!configuration.maximumInterval) {
      configuration.maximumInterval = undefined;
    } else {
      configuration.maximumInterval = Number(configuration.maximumInterval);
    }

    // Fixes issue if field is blank but not undefined
    if (!configuration.estimatedCorrosionRate) {
      configuration.estimatedCorrosionRate = undefined;
    } else {
      configuration.estimatedCorrosionRate = Number(configuration.estimatedCorrosionRate);
    }

    return this.http.put<boolean>(`${this.serverUri}/equipment/${equipmentId}/configuration`, configuration).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  public getEquipmentConfiguration(equipmentId: string): Observable<EquipmentConfigurationDto> {
    return this.http.get<EquipmentConfigurationDto>(`${this.serverUri}/equipment/${equipmentId}/configuration`);
  }

  public getCmlDefaults(assetId: string): Observable<CmlDefaultValuesFromAsset> {
    return this.http.get<CmlDefaultValuesFromAsset>(`${this.serverUri}/equipment/${assetId}/cml-defaults`);
  }

  public moveAsset(equipmentId: string, unitId: string): Observable<boolean> {
    return this.http.put<boolean>(`${this.serverUri}/equipment/${equipmentId}/move`, { unitId }).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  public copyAsset(data: AssetCopyFormData): Observable<boolean> {
    return this.http.post<string>(`${this.serverUri}/equipment/copy`, data).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  public getAssetDefaultsConfiguration(assetId: string): Observable<AssetDefaultsConfigurationDto> {
    return this.http.get<AssetDefaultsConfigurationDto>(`${this.serverUri}/equipment/${assetId}/defaults`);
  }

  public addModule(
    parentId: string,
    sageModule: string,
    newModuleData: AddModuleData,
    assetId: string
  ): Observable<string> {
    const dto = {
      parentId,
      module: sageModule,
      name: newModuleData.name,
      version: newModuleData.version,
      unitOfMeasure: Number(newModuleData.unitOfMeasure)
    };

    return this.http.post<string>(`${this.serverUri}/equipment/${assetId}/sage-module`, dto);
  }
}
