export enum ActivityEffectiveness
{
    A = 1,
    B = 2,
    C = 3,
    D = 4,
    E = 5
}

export enum ActivityPhase
{
    Planning = 1,
    Execution = 2,
}

export enum InspectionApproach
{
    Intrusive = 1,
    NonIntrusive = 2,
}
