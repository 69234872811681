<app-slide-out-container>
  <div class="h-100 overflow-auto" *ngIf="!loading; else loadingTemplate">
    <div class="row h-100 w-100 m-0 p-0 pl-2 pr-0 mr-0 mt-2">
      <div class="col-lg-6">
        <div class="row mb-2" *ngIf="!editing">
          <div class="col-12 pr-0">
            <e2g-check-input
              id="cmlToggle"
              labelPosition="right"
              heading="Add Multiple CMLs"
              [readonly]="readOnly"
              [(ngModel)]="dataHandler.data.createMultipleCmls"
              (change)="toggleMultipleEntry(); checkIsValid()"
            >
            </e2g-check-input>
          </div>
        </div>

        <div class="row mb-1"><label class="pl-3 m-0">Status</label></div>
        <div class="row mb-2">
          <input
            class="e2g-radio ml-3"
            type="radio"
            id="rb-status-active"
            name="status"
            [value]="true"
            [disabled]="readOnly"
            [(ngModel)]="dataHandler.data.active!"
          />
          <label for="rb-status-active" class="ml-2 mr-3 m-0">Active</label>
          <input
            class="e2g-radio"
            type="radio"
            id="rb-status-inactive"
            name="status"
            [value]="false"
            [disabled]="readOnly"
            [(ngModel)]="dataHandler.data.active!"
          />
          <label for="rb-status-inactive" class="ml-2 m-0">Inactive</label>
        </div>

        <div class="d-flex flex-row">
          <e2g-textarea-input
            id="inp-description"
            Class="w-100"
            heading="Description"
            [rows]="2"
            [(ngModel)]="dataHandler.data.description"
            maxlength="255"
            [readonly]="readOnly"
          ></e2g-textarea-input>
        </div>

        <div class="row mb-2" [hidden]="dataHandler.data.createMultipleCmls">
          <!-- Single CML-->
          <div class="col-12 mt-2">
            <e2g-text-input
              heading="Orientation"
              id="inp-orientation"
              [(ngModel)]="dataHandler.data.orientationString"
              [required]="false"
              [readonly]="readOnly"
              [errors]="singleOrientationErrors"
              (input)="orientationIsTouched = true"
              (change)="checkIsValid()"
            ></e2g-text-input>
          </div>
        </div>
        <!--Multiple cml Section-->
        <div class="row mb-2" [hidden]="!dataHandler.data.createMultipleCmls">
          <div class="col-12">
            <e2g-text-input
              heading="Orientations- Comma Separated"
              id="inp-orientations"
              [(ngModel)]="dataHandler.data.orientationString"
              [required]="true"
              [readonly]="readOnly"
              [errors]="multipleOrientationsErrors"
              (input)="orientationIsTouched = true"
              (change)="updateInvalidNameList(); checkIsValid()"
            ></e2g-text-input>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-12">
            <e2g-ng-select-input
              id="select-comp"
              heading="Component"
              placeholder="Select Component"
              [disabled]="noComponents"
              [readonly]="readOnly"
              [required]="true"
              [errors]="componentErrors"
              [options]="compSelectOptions"
              [(ngModel)]="componentId_headerBox"
              (change)="onComponentChange()"
            ></e2g-ng-select-input>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-6">
            <e2g-date-input
              heading="In Service Date:"
              id="inp-inServiceDate"
              [(ngModel)]="dataHandler.data.inServiceDate"
              placeholder="mm/dd/yyyy"
              [isDefaultValue]="dataHandler.isDefaultValue('inServiceDate')"
              [readonly]="readOnly"
              [showReset]="!dataHandler.isDefaultValue('inServiceDate')"
              (reset)="resetInServiceDate()"
              (change)="checkIsValid()"
            ></e2g-date-input>
          </div>
          <div class="col-6">
            <e2g-date-input
              heading="Last Replacement Date:"
              id="inp-replacementDate"
              [(ngModel)]="dataHandler.data.replacementDate"
              [readonly]="readOnly"
              placeholder="mm/dd/yyyy"
              (change)="checkIsValid()"
            ></e2g-date-input>
          </div>
        </div>
        <div class="row mb-0">
          <div class="col-6">
            <app-br-select
              propName="insulationType"
              heading="Insulated"
              placeholder="Select Insulation Type"
              [readOnly]="readOnly"
            >
            </app-br-select>
          </div>
        </div>

        <ng-container *ngIf="selectedComponent != undefined">
          <div class="row align-items-end m-0">
            <div class="col-12">
              <hr class="seperator" />
            </div>
          </div>

          <div class="row mb-0">
            <div class="col-12">
              <div class="row m-0">
                <section class="w-100 m-0">
                  <div id="div-heading">
                    <div id="div-thk" class="d-flex flex-row align-items-center">
                      <div>Minimum Thickness:&nbsp;</div>
                      <div *ngIf="!cmlUsingMawpApproach; else mawpApproach">
                        {{ minimumThickness }} {{ minimumThicknessUom | async }}
                      </div>
                    </div>
                    <div>Component Setting: {{ selectedThicknessLabel }}</div>
                  </div>

                  <div class="d-flex align-middle">
                    <e2g-check-input
                      heading="Override"
                      [readonly]="readOnly"
                      labelPosition="right"
                      [(ngModel)]="dataHandler.data.isMinimumThicknessOverridden"
                      (change)="updateSelectedThickness()"
                    >
                    </e2g-check-input>
                  </div>

                  <div class="row" *ngIf="dataHandler.data.isMinimumThicknessOverridden">
                    <div class="col-12 mt-2">
                      <e2g-ng-select-input
                        id="inp-select-thickness"
                        placeholder="Select Override Thickness"
                        heading="Source {{ minimumThicknessUom | async }}"
                        [required]="true"
                        [disabled]="!thicknessList || thicknessList.length === 0"
                        [readonly]="readOnly"
                        [options]="thicknessList"
                        [(ngModel)]="dataHandler.data.governingMinimumThickness"
                        (change)="updateSelectedThickness()"
                      ></e2g-ng-select-input>
                    </div>

                    <div class="col-12 mt-2" *ngIf="dataHandler.data.governingMinimumThickness === inputThickness">
                      <e2g-text-input
                        id="inp-specified-thickness"
                        heading="Specified Min Thk {{ minimumThicknessUom | async }}"
                        [(ngModel)]="dataHandler.data.specifiedMinimumThickness"
                        [errors]="specifiedThicknessErrors"
                        [required]="true"
                        (input)="setOverrideThickness()"
                        type="number"
                        [readonly]="readOnly"
                      ></e2g-text-input>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>

          <div class="row align-items-end m-0">
            <div class="col-12">
              <hr class="seperator" />
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-6">
              <e2g-input-heading heading="Override Next Inspection Date"></e2g-input-heading>
              <div class="d-flex align-items-middle">
                <e2g-check-input
                  id="inp-overrideNextInsp"
                  [readonly]="readOnly"
                  [(ngModel)]="dataHandler.data.overrideNextInspection"
                  (change)="checkIsValid(); nextInspectionDateErrors = []"
                ></e2g-check-input>
                <e2g-date-input
                  class="ml-2"
                  id="inp-nextInspDate"
                  [(ngModel)]="dataHandler.data.specifiedNextInspectionDate"
                  placeholder="mm/dd/yyyy"
                  [disabled]="!dataHandler.data.overrideNextInspection"
                  [readonly]="readOnly"
                  [errors]="nextInspectionDateErrors"
                  (isValidDate)="nextInspectionDateValidation($event)"
                  (change)="checkIsValid()"
                ></e2g-date-input>
              </div>
            </div>
            <div class="col-6">
              <e2g-text-input
                *ngIf="dataHandler.data.overrideNextInspection"
                id="inp-nextInspDateComment"
                heading="Next Inspection Date Comments"
                [(ngModel)]="dataHandler.data.nextInspectionDateComments!"
                [readonly]="readOnly"
              ></e2g-text-input>
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-6">
              <e2g-check-input
                id="ckb-injection-point"
                labelPosition="right"
                heading="Injection Point"
                [readonly]="readOnly"
                [(ngModel)]="dataHandler.data.injectionPoint!"
                (change)="checkMaximumIntervalDefault()"
              >
              </e2g-check-input>
            </div>
            <div class="col-6">
              <e2g-check-input
                id="ckb-soil-to-air"
                labelPosition="right"
                heading="Soil to Air"
                [readonly]="readOnly"
                [(ngModel)]="dataHandler.data.soilToAir!"
                (change)="checkMaximumIntervalDefault()"
              >
              </e2g-check-input>
            </div>
          </div>
          <div class="row mb-2" *ngIf="dataHandler.data.injectionPoint! || dataHandler.data.soilToAir!">
            <div class="col-12">
              <e2g-text-input
                id="inp-maximum-interval"
                type="number"
                heading="Maximum Interval"
                [readonly]="readOnly"
                [(ngModel)]="dataHandler.data.maximumInterval!"
                [errors]="maximumIntervalErrors"
                [isDefaultValue]="dataHandler.isDefaultValue('maximumInterval')"
                [showReset]="!dataHandler.isDefaultValue('maximumInterval')"
                (reset)="resetMaximumInterval()"
              ></e2g-text-input>
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-12">
              <e2g-ng-select-input
                id="select-circuit"
                heading="Group"
                [disabled]="circuitDisabled()"
                [readonly]="readOnly"
                [options]="circuitList"
                [(ngModel)]="dataHandler.data.circuitId"
                [isDefaultValue]="dataHandler.isDefaultValue('circuitId')"
                [showReset]="!dataHandler.isDefaultValue('circuitId')"
                (reset)="resetCircuit()"
                (change)="updateInvalidNameList()"
              ></e2g-ng-select-input>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-6">
              <e2g-text-input
                heading="Inspection Group"
                id="inp-inspection-group"
                [readonly]="readOnly"
                [(ngModel)]="dataHandler.data.inspectionGroup"
                [required]="false"
                maxlength="255"
              ></e2g-text-input>
            </div>
            <div class="col-6">
              <e2g-text-input
                id="inp-line-number"
                heading="Line Number"
                [(ngModel)]="dataHandler.data.lineNumber"
                [required]="false"
                maxlength="60"
                [readonly]="readOnly"
              ></e2g-text-input>
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-6">
              <e2g-text-input
                id="inp-drawing"
                heading="Drawing"
                [(ngModel)]="dataHandler.data.drawing"
                [required]="false"
                maxlength="255"
                [readonly]="readOnly"
              ></e2g-text-input>
            </div>
            <div class="col-6">
              <e2g-text-input
                id="inp-access-limitations"
                heading="Access Limitations"
                [(ngModel)]="dataHandler.data.accessLimitations"
                [maxlength]="255"
                [readonly]="readOnly"
                [isDefaultValue]="dataHandler.isDefaultValue('accessLimitations')"
                [showReset]="!dataHandler.isDefaultValue('accessLimitations')"
                (reset)="resetAccessLimitations()"
              ></e2g-text-input>
            </div>
          </div>

          <div class="row">
            <div class="d-flex flex-column ml-3 pr-1 mb-2 w-100">
              <e2g-textarea-input
                id="inp-comments"
                class="w-100 pr-2"
                heading="Comments"
                [rows]="5"
                [readonly]="readOnly"
                [maxlength]="512"
                [(ngModel)]="dataHandler.data.comments"
              ></e2g-textarea-input>
            </div>
          </div>
        </ng-container>
      </div>

      <div class="h-100 col-lg-6">
        <ng-container *ngIf="editing">
          <ul ngbNav #nav="ngbNav" [(activeId)]="activeTabId" class="sub-nav-tabs">
            <li [ngbNavItem]="1">
              <a ngbNavLink>Documents</a>
              <ng-template ngbNavContent>
                <app-attachments
                  *ngIf="attachmentKey"
                  [tableType]="tableType"
                  [attachmentKey]="attachmentKey"
                  [readonly]="readOnly"
                ></app-attachments>
              </ng-template>
            </li>
            <li [ngbNavItem]="2" [destroyOnHide]="false">
              <a ngbNavLink>Readings</a>
              <ng-template ngbNavContent>
                <e2g-ag-grid class="mt-2 h-100" [options]="readingGridOptions!" [data]="readingsGridDataHandler.data">
                </e2g-ag-grid>
              </ng-template>
            </li>
            <li [ngbNavItem]="3">
              <a ngbNavLink>Statistics</a>
              <ng-template ngbNavContent>
                <div class="table-responsive">
                  <table class="table">
                    <tbody>
                      <tr class="border-top-0">
                        <td class="border-top-0">Min. Thickness {{ minimumThicknessUom | async }}</td>
                        <td class="border-top-0">&nbsp;</td>
                        <td class="border-top-0">
                          <div *ngIf="!cmlUsingMawpApproach; else mawpApproach">{{ minimumThickness }}</div>
                        </td>
                        <td class="border-top-0">&nbsp;&nbsp;</td>
                        <td class="border-top-0">Gov. Corr. Rate {{ governingCorrosionRateUom | async }}</td>
                        <td class="border-top-0">&nbsp;</td>
                        <td class="border-top-0">{{ dataHandler.data.governingCorrosionRate?.toFixed(3) }}</td>
                      </tr>
                      <tr>
                        <td>Latest Thickness {{ latestThicknessUom | async }}</td>
                        <td>&nbsp;</td>
                        <td>{{ getLatestThickness() }}</td>
                        <td class="border-top-0">&nbsp;&nbsp;</td>
                        <td>Gov. Corr. Rate Type</td>
                        <td>&nbsp;</td>
                        <td>{{ getGoverningCorrosionRateType() }}</td>
                      </tr>
                      <tr>
                        <td>Latest Date</td>
                        <td>&nbsp;</td>
                        <td>{{ getLatestDate() }}</td>
                        <td class="border-top-0">&nbsp;&nbsp;</td>
                        <td class="border-bottom">Long Rate {{ longRateUom | async }}</td>
                        <td class="border-bottom">&nbsp;</td>
                        <td class="border-bottom">{{ dataHandler.data.longRate?.toFixed(3) }}</td>
                      </tr>
                      <tr>
                        <td>Next Insp. Date</td>
                        <td>&nbsp;</td>
                        <td>{{ getNextInspectionDate() }}</td>
                        <td class="border-top-0">&nbsp;&nbsp;</td>
                        <td class="border-top-0">Short Rate {{ shortRateUom | async }}</td>
                        <td class="border-top-0">&nbsp;</td>
                        <td class="border-top-0">{{ dataHandler.data.shortRate?.toFixed(3) }}</td>
                      </tr>
                      <tr>
                        <td>Next Insp. Date Driver</td>
                        <td>&nbsp;</td>
                        <td>{{ getInspDriver(dataHandler.data.inspectionDriver!) }}</td>
                        <td class="border-top-0">&nbsp;&nbsp;</td>
                        <td>Best Fit Rate {{ linearRateUom | async }}</td>
                        <td>&nbsp;</td>
                        <td>{{ dataHandler.data.linearRate?.toFixed(3) }}</td>
                      </tr>
                      <tr>
                        <td>Retirement Date</td>
                        <td>&nbsp;</td>
                        <td>{{ getRetirementDate() }}</td>
                        <td class="border-top-0">&nbsp;&nbsp;</td>
                        <ng-container *ngIf="hasScra; else emptyTableEntry">
                          <td>SCRA Long Rate {{ scraLongRateUom | async }}</td>
                          <td>&nbsp;</td>
                          <td>
                            {{ getScraRate(dataHandler.data.scraLongRate!, dataHandler.data.scraLongRateCode!) }}
                          </td>
                        </ng-container>
                      </tr>
                      <tr>
                        <td>MAWP {{ maximumAllowableWorkingPressureUom | async }}</td>
                        <td>&nbsp;</td>
                        <td>{{ dataHandler.data.maximumAllowableWorkingPressure?.toFixed(3) }}</td>
                        <td class="border-top-0">&nbsp;&nbsp;</td>
                        <ng-container *ngIf="hasScra; else emptyTableEntry">
                          <td>SCRA Short Rate {{ scraShortRateUom | async }}</td>
                          <td>&nbsp;</td>
                          <td>
                            {{ getScraRate(dataHandler.data.scraShortRate!, dataHandler.data.scraShortRateCode!) }}
                          </td>
                        </ng-container>
                      </tr>
                      <tr>
                        <td>Design Temperature {{ recommendedMinimumTemperatureUom | async }}</td>
                        <td>&nbsp;</td>
                        <td>{{ getDesignTemperatureFromComponent() }}</td>
                        <td class="border-top-0">&nbsp;&nbsp;</td>
                        <ng-container *ngIf="hasScra; else emptyTableEntry">
                          <td>Stat Long Rate {{ scraLongRateStatisticalUom | async }}</td>
                          <td>&nbsp;</td>
                          <td>{{ dataHandler.data.scraLongRateStatistical?.toFixed(3) }}</td>
                        </ng-container>
                      </tr>
                      <tr *ngIf="hasScra">
                        <ng-template [ngTemplateOutlet]="emptyTableEntry"></ng-template>
                        <td class="border-bottom-0 border-top-0">&nbsp;&nbsp;</td>
                        <td>Stat Short Rate {{ scraShortRateStatisticalUom | async }}</td>
                        <td>&nbsp;</td>
                        <td>{{ dataHandler.data.scraShortRateStatistical?.toFixed(3) }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </ng-template>
            </li>
            <li [ngbNavItem]="4">
              <a ngbNavLink>Plot</a>
              <ng-template ngbNavContent>
                <plotly-plot
                  *ngIf="cmlGraph.data !== undefined; else loadingTemplate"
                  [data]="cmlGraph.data"
                  [layout]="cmlGraph.graphLayout"
                  [config]="cmlGraph.graphConfig"
                  [useResizeHandler]="true"
                  class="w-100 h-100"
                ></plotly-plot>
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="nav" id="tab-content" class="pt-2"></div>
        </ng-container>
      </div>
    </div>
  </div>
</app-slide-out-container>

<ng-template #loadingTemplate> <div class="loading"></div> </ng-template>
<ng-template #emptyTableEntry>
  <td class="border-bottom-0 border-top-0">&nbsp;&nbsp;</td>
  <td class="border-bottom-0 border-top-0">&nbsp;</td>
  <td class="border-bottom-0 border-top-0">&nbsp;</td>
</ng-template>

<ng-template #mawpApproach>
  <div>MAWP Approach</div>
</ng-template>
