import { IRowNode } from 'ag-grid-community';

import { defaultWithNullComparer } from './default-with-null-comparator';

export function doubleWithNullComparator(
  valueA: number,
  valueB: number,
  nodeA: IRowNode,
  nodeB: IRowNode,
  isInverted: boolean
): number {
  return defaultWithNullComparer(valueA, valueB, isInverted, (a, b) => (a < b ? -1 : 1));
}
