<app-slide-out-container>
  <div class="row h-100 w-100 m-0" *ngIf="!loading; else load">
    <div class="col-lg-3 pl-4 mt-2">
      <div class="form-group row">
        <div class="col-12">
          <e2g-textarea-input
            heading="Description"
            [rows]="2"
            id="inp-description"
            [(ngModel)]="dataHandler.data.description"
            [readonly]="readonly"
            [maxlength]="255"
          ></e2g-textarea-input>
        </div>
      </div>
      <div class="form-group row" *ngIf="!isPrd()">
        <div class="col-12">
          <e2g-ng-select-input
            id="select-finding-component"
            heading="Component"
            placeholder="Select Component"
            [isLoading]="!componentsForAssetList"
            [options]="componentsForAssetList || []"
            [(ngModel)]="dataHandler.data.componentId"
            [readonly]="readonly"
            [clearable]="true"
          ></e2g-ng-select-input>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-12">
          <e2g-ng-select-input
            heading="Status"
            id="inp-status"
            [(ngModel)]="dataHandler.data.status"
            [options]="statusSelectOptions"
            (change)="onChangeStatus()"
            [readonly]="readonly"
          ></e2g-ng-select-input>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-xl-12">
          <div class="mt-2">
            <e2g-input-heading heading="Due Date"></e2g-input-heading>
          </div>

          <div class="d-flex align-items-middle" *ngIf="dataHandler.data.calculatedDueDate || isAdding">
            <input
              type="radio"
              id="gov-thk-1"
              [(ngModel)]="dataHandler.data.governingDueDate"
              name="governingThicknessRadio"
              [value]="1"
              [disabled]="readonly"
            />
            <div class="ml-2 label-width" for="gov-thk-1">Calculated Due Date:</div>
            <div class="ml-2" *ngIf="!isAdding">{{ dataHandler.data.calculatedDueDate | date }}</div>
            <div class="ml-2" *ngIf="isAdding">Uncalculated</div>
          </div>

          <div class="d-flex align-items-left mt-2 mr-2" *ngFor="let dueDate of this.dataHandler.data.dueDateList">
            <div class="text-muted ml-2" style="min-width: 20px"></div>
            <div class="text-muted ml-2" style="min-width: 60px">{{ dueDate.dueDate | date }}</div>
            <div class="text-muted ml-2" style="min-width: 120px">{{ dueDate.description }}</div>
          </div>

          <div class="d-flex align-items-middle mt-2">
            <input
              type="radio"
              id="gov-thk-3"
              [(ngModel)]="dataHandler.data.governingDueDate"
              name="governingThicknessRadio"
              [value]="3"
              [disabled]="readonly"
            />
            <div class="ml-2 mt-1 label-width" for="gov-thk-3">
              <e2g-input-heading
                heading="Specified Due Date:"
                [required]="specifiedDueDateRequired()"
              ></e2g-input-heading>
            </div>
          </div>

          <div class="ml-4 mt-2">
            <e2g-date-input
              *ngIf="specifiedDueDateRequired()"
              id="inp-dueDate"
              [(ngModel)]="dataHandler.data.specifiedDueDate"
              [errors]="dueDateErrors"
              placeholder="mm/dd/yyyy"
              [readonly]="readonly"
              [required]="specifiedDueDateRequired()"
            ></e2g-date-input>
          </div>

          <div class="ml-4 mt-2">
            <e2g-textarea-input
              *ngIf="specifiedDueDateRequired()"
              heading="Due Date Comments"
              [rows]="5"
              [maxlength]="255"
              id="inp-specified-due-date-comments"
              [(ngModel)]="dataHandler.data.specifiedDueDateComments"
              placeholder="Add comments..."
              [readonly]="readonly"
            ></e2g-textarea-input>
          </div>
        </div>
      </div>
    </div>
    <div id="div-tabs" class="col-lg-9 mt-2">
      <ul ngbNav #nav="ngbNav" [(activeId)]="activeTabId" class="sub-nav-tabs">
        <li [ngbNavItem]="futureActivityTabs.Plan">
          <a ngbNavLink>Plan</a>
          <ng-template ngbNavContent>
            <div class="h-100 w-100 sub-tab-styles">
              <div *ngIf="!previewMode">
                <div class="form-group row mt-2">
                  <div class="col-sm-3 text-nowrap">
                    <e2g-date-input
                      heading="Scheduled Date"
                      id="inp-scheduleDate"
                      [(ngModel)]="dataHandler.data.scheduledDate"
                      [errors]="scheduledDateErrors"
                      placeholder="mm/dd/yyyy"
                      [readonly]="readonly"
                    ></e2g-date-input>
                  </div>
                  <div class="col-sm-6">
                    <e2g-multi-select-input
                      heading="Assigned"
                      id="select-inspector-assigned"
                      [(ngModel)]="assignedInspectorIds"
                      [isLoading]="loadingInspectorInfo && !loading"
                      [options]="inspectorOptions!"
                      (change)="onAssignedInspectorChange()"
                      [readonly]="readonly"
                    >
                    </e2g-multi-select-input>
                  </div>
                  <div class="col-sm-3">
                    <e2g-text-input
                      heading="Campaign"
                      id="inp-campaign"
                      [(ngModel)]="dataHandler.data.campaign"
                      [readonly]="readonly"
                    ></e2g-text-input>
                  </div>
                </div>
                <div class="slideout-line-spacer"></div>
                <div class="form-group row mb-1" *ngIf="effectivenessVisible()">
                  <div class="col-xl-12">
                    <app-grade-activity
                      [dataHandler]="dataHandler"
                      [displayRecommendation]="true"
                      [readonly]="readonly"
                      [activityPhase]="activityPhase[0]"
                    >
                    </app-grade-activity>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-xl-12">
                    <e2g-textarea-input
                      heading="Additional Planning Comments"
                      [rows]="5"
                      id="inp-comments"
                      [(ngModel)]="dataHandler.data.planningComments"
                      placeholder="Add comments..."
                      [readonly]="readonly"
                    ></e2g-textarea-input>
                  </div>
                </div>
              </div>
              <div [class]="!previewMode ? 'mt-3' : 'preview-attachments'" *ngIf="attachmentKey">
                <app-attachments
                  [tableType]="tableTypePlanning"
                  [attachmentKey]="attachmentKey"
                  [readonly]="readonly"
                  (previewMode)="previewModeChange($event)"
                ></app-attachments>
              </div>
            </div>
          </ng-template>
        </li>
        <li [ngbNavItem]="futureActivityTabs.Execute" *ngIf="isTabVisible(futureActivityTabs.Execute)">
          <a ngbNavLink>Execute</a>
          <ng-template ngbNavContent>
            <div class="h-100 w-100 sub-tab-styles">
              <div *ngIf="!previewMode">
                <div class="form-group row mt-2">
                  <div class="col-sm-3">
                    <e2g-date-input
                      heading="Completion Date"
                      id="inp-completionDate"
                      [(ngModel)]="dataHandler.data.completionDate"
                      [errors]="completionDateErrors"
                      [required]="isComplete()"
                      [readonly]="readonly"
                    ></e2g-date-input>
                  </div>
                  <div class="col-sm-9">
                    <e2g-multi-select-input
                      heading="Completed By"
                      id="select-inspector-completed"
                      [(ngModel)]="completedByInspectorIds"
                      [isLoading]="loadingInspectorInfo && !loading"
                      [options]="inspectorOptions!"
                      [required]="isComplete()"
                      (change)="onCompletedByInspectorChange()"
                      [readonly]="readonly"
                      [errors]="completedByErrors"
                    >
                    </e2g-multi-select-input>
                  </div>
                </div>
                <div class="form-group row mt-2" *ngIf="isSurvey()">
                  <div class="col-sm-6">
                    <e2g-ng-select-input
                      heading="Inspection Method"
                      id="inp-primary-inspection-method"
                      [(ngModel)]="dataHandler.data.primaryInspectionMethod"
                      [options]="primaryInspectionMethodOptions"
                      [required]="isComplete()"
                      [readonly]="readonly"
                      [errors]="inspectionMethodErrors"
                    ></e2g-ng-select-input>
                  </div>
                  <div class="col-sm-6">
                    <e2g-check-input
                      heading="Baseline"
                      id="inp-baseline"
                      [(ngModel)]="dataHandler.data.isBaseline"
                      [readonly]="readonly"
                    ></e2g-check-input>
                  </div>
                  <div class="col-sm-6">
                    <e2g-text-input
                      heading="Instrument Id"
                      id="inp-instrument-id"
                      [(ngModel)]="dataHandler.data.instrumentId"
                      [readonly]="readonly"
                    >
                    </e2g-text-input>
                  </div>
                  <div class="col-sm-6">
                    <e2g-text-input
                      heading="Probe Id"
                      id="select-inspector-completed"
                      [(ngModel)]="dataHandler.data.probeId"
                      [readonly]="readonly"
                    >
                    </e2g-text-input>
                  </div>
                </div>
                <div class="slideout-line-spacer"></div>
                <div class="form-group row">
                  <div class="col-12">
                    <e2g-textarea-input
                      heading="Execution Summary"
                      [rows]="5"
                      id="inp-comments"
                      [(ngModel)]="dataHandler.data.executionComments"
                      placeholder="Add summary..."
                      [readonly]="readonly"
                    ></e2g-textarea-input>
                  </div>
                </div>
              </div>
              <div [class]="!previewMode ? 'mt-3' : 'preview-attachments'" *ngIf="attachmentKey">
                <app-attachments
                  [tableType]="tableTypeInProgress"
                  [attachmentKey]="attachmentKey"
                  [readonly]="readonly"
                  (previewMode)="previewModeChange($event)"
                ></app-attachments>
              </div>
            </div>
          </ng-template>
        </li>
        <li [ngbNavItem]="futureActivityTabs.Review" *ngIf="isTabVisible(futureActivityTabs.Review)">
          <a ngbNavLink>Review</a>
          <ng-template ngbNavContent>
            <div class="h-100 w-100 sub-tab-styles">
              <div *ngIf="!previewMode">
                <div class="form-group row mt-2 mb-0">
                  <div class="col-12">
                    <e2g-textarea-input
                      heading="Execution Summary"
                      [rows]="5"
                      id="inp-comments"
                      [(ngModel)]="dataHandler.data.executionComments"
                      placeholder="Execution summary is blank."
                      [readonly]="true"
                    ></e2g-textarea-input>
                  </div>
                </div>
              </div>
              <div [class]="!previewMode ? 'mb-2' : 'preview-attachments'" *ngIf="attachmentKey">
                <app-attachments
                  [tableType]="tableTypePlanning"
                  [attachmentKey]="attachmentKey"
                  [readonly]="true"
                  (previewMode)="previewModeChange($event)"
                ></app-attachments>
              </div>
              <div *ngIf="!previewMode">
                <div class="slideout-line-spacer" *ngIf="effectivenessVisible()"></div>
                <div class="form-group row" *ngIf="effectivenessVisible()">
                  <div class="col-xl-12">
                    <app-grade-activity
                      [dataHandler]="dataHandler"
                      [readonly]="readonly"
                      [activityPhase]="activityPhase[1]"
                    >
                    </app-grade-activity>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </li>
        <li [ngbNavItem]="futureActivityTabs.Findings" *ngIf="isTabVisible(futureActivityTabs.Findings)">
          <a ngbNavLink>Findings</a>
          <ng-template ngbNavContent>
            <div class="h-100 w-100 sub-tab-styles">
              <div class="form-group row mt-2">
                <div class="col-xl-12">
                  <button
                    *ngIf="!readonly"
                    id="btnAddFinding"
                    class="btn e2g-btn-outline-primary"
                    (click)="addOrEditFinding(undefined)"
                  >
                    Add Finding
                  </button>
                </div>
              </div>
              <div class="form-group row findings-content">
                <div class="col-xl-12">
                  <e2g-ag-grid
                    #findingsGrid
                    [options]="findingsGridOptions"
                    [data]="findingsData"
                    (gridReady)="gridReady.next()"
                  ></e2g-ag-grid>
                </div>
              </div>
            </div>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav" id="tab-content" class="pt-2"></div>
    </div>
  </div>
</app-slide-out-container>

<ng-template #load>
  <div class="loading"></div>
</ng-template>

<ng-template #extraFooterButtons>
  <button
    id="btn-accept"
    *ngIf="isComplete()"
    (click)="accept()"
    class="btn e2g-btn-solid-primary ml-2"
    id="btn-accept"
    [disabled]="!isValid() || saving"
  >
    Accept
  </button>
</ng-template>
