import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AppSettingsService } from '../app-settings/app-settings.service';

export interface VersionInfo {
  version: string;
  major: number;
  minor: number;
  build: number;
}

@Injectable({
  providedIn: 'root'
})
export class VersionService {
  public clientVersion: Observable<string>;
  public serverVersion: Observable<string>;

  constructor(private http: HttpClient, private settings: AppSettingsService) {

    this.clientVersion = this.http.get<VersionInfo>('assets/version.json').pipe(
      catchError(() => of({ version: 'dev' })),
      map((x) => `${this.settings.settings.version}.${x.version}`)
    );

    this.serverVersion = this.http.get<VersionInfo>(`${this.settings.settings.apiUri}/version`).pipe(
      catchError(() => of({ version: 'dev', major: 0, minor: 0, build: 0 })),
      map((x) => `${x.major}.${x.minor}.${x.build}`)
    );
  }
}
