import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DateOnlyUtility } from '@equityeng/e2g-ng-ui';
import {
  AgGridEvent,
  CellClassRules,
  ColDef,
  ColGroupDef,
  ColumnPinnedEvent,
  ColumnResizedEvent,
  GetMainMenuItemsParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  IRowNode,
  MenuItemDef,
  SortChangedEvent
} from 'ag-grid-community';
import { distinctUntilChanged, filter, map, takeUntil } from 'rxjs';
import { OnDestroyBaseComponent } from 'src/app/on-destroy-base-component/on-destroy-base-component';
import { StateService } from 'src/app/state.service';

import { UnitsOfMeasureEvaluator } from '../../units-of-measure/units-of-measure-evaluator';
import { GridCellButtonComponent } from '../cell-renders/grid-cell-button/grid-cell-button.component';
import { GridCellMenuComponent } from '../cell-renders/grid-cell-menu/grid-cell-menu-component';
import { GridCellRbiComponent } from '../cell-renders/grid-cell-rbi/grid-cell-rbi.component';

export interface GridBaseOptions extends GridOptions {
  exportFileName?: string;
  deselectAll?: () => void;
  getSelectRowId?: (node: IRowNode<any>) => string;
  downloadExcelFile?: () => void;
  setDefaultSort?: (api: GridApi) => void;
  setDefaultOrder?: (api: GridApi) => void;
}

@Component({
  selector: 'e2g-ag-grid',
  templateUrl: './e2g-ag-grid.component.html',
  styleUrls: ['./e2g-ag-grid.component.css']
})
export class E2gAgGridComponent extends OnDestroyBaseComponent implements OnInit {
  @Input() public options!: GridBaseOptions;
  @Input() public data?: any;
  @Output() public gridReady = new EventEmitter<GridReadyEvent>();

  public gridOptions!: GridOptions;
  public gridData: any;

  public api!: GridApi;

  private layoutModified: boolean = false;
  private gridStateKey: string = '';

  private defaultGridOptions: GridOptions = {
    columnMenu: 'new',
    suppressMenuHide: true,
    rowHeight: 24,
    getRowId: (params) => params.data.id,
    onGridReady: this.onGridReady.bind(this),
    onColumnMoved: this.saveGridState.bind(this),
    onColumnResized: this.columnResized.bind(this),
    onSortChanged: this.sortChanged.bind(this),
    onColumnPinned: this.columnPinned.bind(this),
    onColumnVisible: this.columnPinned.bind(this),
    getMainMenuItems: this.setupMenuItems.bind(this),
    onRowDataUpdated: this.onRowDataUpdated.bind(this),
    onSelectionChanged: this.onSelectionChanged.bind(this)
  };

  public constructor(private uomEvaulator: UnitsOfMeasureEvaluator, private stateService: StateService) {
    super();
  }

  public ngOnInit(): void {
    if (!this.options?.context) {
      throw 'grid options missing context name';
    } else {
      this.uomEvaulator.setGridUnits(this.options).subscribe((options) => {
        this.gridOptions = this.applyDefaultGridOptions(options);
      });
    }
  }

  public onGridReady(params: GridReadyEvent): void {
    this.api = params.api;
    this.setDefaultSort(params.api);
    if (this.setDefaultOrder !== undefined) {
      this.setDefaultOrder(params.api);
    }
    this.loadGridState(params.api);

    this.setSelectedRowOnChange();

    this.gridReady.emit(params);
  }

  private setSelectedRowOnChange(): void {
    this.stateService.state
      .pipe(
        map(() => this.stateService.getSelectedGridId(this.gridOptions.context)),
        filter((x) => x !== undefined),
        distinctUntilChanged(),
        takeUntil(this.destroy)
      )
      .subscribe((selectedId) => this.selectRow(selectedId!));
  }

  private setupMenuItems(params: GetMainMenuItemsParams): (string | MenuItemDef)[] {
    const menuItems: (MenuItemDef | string)[] = [];
    const itemsToExclude = ['resetColumns'];

    params.defaultItems.forEach((item) => {
      if (itemsToExclude.indexOf(item) < 0) {
        menuItems.push(item);
      }
    });

    if (this.layoutModified) {
      menuItems.push({
        name: 'Reset Columns',
        action: () => this.resetGridLayout()
      });
    }

    return menuItems;
  }

  private applyDefaultGridOptions(options: GridBaseOptions): GridBaseOptions {
    this.options!.deselectAll = this.deselectAll.bind(this);
    this.options!.downloadExcelFile = this.downloadExcelFile.bind(this);

    options!.columnDefs?.forEach((col: ColDef) => {
      if (
        !(col.cellRenderer instanceof GridCellMenuComponent) &&
        !(col.cellRenderer instanceof GridCellRbiComponent) &&
        !(col.cellRenderer instanceof GridCellButtonComponent)
      ) {
        if (col.editable === true) {
          col.cellClassRules = { ...(col.cellClassRules as CellClassRules | undefined), ...this.editableCellHover };
        }
      }
    });

    if (this.options.setDefaultSort !== undefined) {
      this.setDefaultSort = this.options.setDefaultSort!;
    }

    if (this.options.setDefaultOrder !== undefined) {
      this.setDefaultOrder = this.options.setDefaultOrder!;
    }

    if (this.options.getSelectRowId) {
      this.selectRowId = this.options.getSelectRowId;
    }

    this.setGridStateName(this.options.context);

    const exportFileName = options.exportFileName || '';
    const exportParams = {
      fileName: `${exportFileName} ${DateOnlyUtility.formatAsE2gDate(DateOnlyUtility.today())}`
    };

    const columns = (
      [...options.columnDefs!].sort((a, b) =>
        a.headerName! < b.headerName! ? -1 : a.headerName! > b.headerName! ? 1 : 0
      ) ?? []
    ).filter((col: ColDef) => col.field !== undefined);

    options.columnDefs = options.columnDefs!.map((col: ColDef) => ({
      ...col,
      columnChooserParams: {
        suppressSyncLayoutWithGrid: true,
        contractColumnSelection: true,
        columnLayout: [...columns]
      }
    }));

    return {
      ...this.defaultGridOptions,
      ...this.stripExtraOptions(options),
      defaultExcelExportParams: exportParams,
      defaultCsvExportParams: exportParams
    };
  }

  private editableCellHover = {
    'ag-cell-hover-outline': (): boolean => {
      return true;
    }
  };

  private stripExtraOptions(baseOptions: GridBaseOptions): GridOptions {
    const options = { ...baseOptions };

    delete options.exportFileName;
    delete options.deselectAll;
    delete options.getSelectRowId;
    delete options.downloadExcelFile;
    delete options.setDefaultSort;
    delete options.setDefaultOrder;

    return options;
  }

  private deselectAll(): void {
    this.api.deselectAll();
  }

  public selectRow(rowId: string): void {
    this.selectRows((node) => this.selectRowId(node) === rowId);
  }

  public selectRows(isRowSelected: (node: IRowNode<any>) => boolean): void {
    setTimeout(() => {
      let lastRowIndex: number | undefined;
      this.api.forEachNode((node) => {
        const isSelected = isRowSelected(node);
        node.setSelected(isSelected, false);

        if (isSelected && node.rowIndex) {
          lastRowIndex = node.rowIndex;
        }
      });
      if (lastRowIndex !== undefined) {
        this.api.ensureIndexVisible(lastRowIndex, null);
      }
    });
  }

  public convertNodesToSlideoutOptions<TIn, TOut>(optionBuilderFunc: (data: TIn) => TOut): Record<string, TOut> {
    const options: Record<string, TOut> = {};

    this.api.forEachNodeAfterFilterAndSort((node) => {
      options[node.data.id!] = optionBuilderFunc(node.data);
    });

    return options;
  }

  private selectRowId = (node: IRowNode<any>): string => {
    return node.id ?? '';
  };

  private setGridStateName(stateName: string): void {
    stateName = stateName.replace(/ /g, '');
    this.gridStateKey = `grid-state-${stateName}`;
  }

  private columnResized(event: ColumnResizedEvent): void {
    if (event.finished && event.source !== 'flex') {
      this.saveGridState(event);
    }
  }

  private columnPinned(event: ColumnPinnedEvent): void {
    if (event.source !== 'api') {
      this.saveGridState(event);
    }
  }

  private sortChanged(event: SortChangedEvent): void {
    if (event.source !== 'api') {
      this.saveGridState(event);
    }
  }

  private onRowDataUpdated(): void {
    const selectedId = this.stateService.getSelectedGridId(this.gridOptions.context);
    if (selectedId) {
      this.selectRow(selectedId);
    }
  }

  private onSelectionChanged(): void {
    const selectedRow = this.api.getSelectedNodes()[0];
    if (selectedRow) {
      this.stateService.setSelectedGridId(this.gridOptions.context, this.selectRowId(selectedRow));
    }
  }

  private saveGridState(event: AgGridEvent<any>): void {
    this.layoutModified = true;
    const state = event.api.getColumnState();
    localStorage.setItem(this.gridStateKey, JSON.stringify(state));
  }

  private loadGridState(api: GridApi): void {
    const savedState = localStorage.getItem(this.gridStateKey);
    if (savedState) {
      this.layoutModified = true;

      const state = JSON.parse(savedState);

      api.applyColumnState({ state, applyOrder: true });
    }
  }

  private resetGridLayout(): void {
    localStorage.removeItem(this.gridStateKey);

    this.api!.resetColumnState();
    this.setDefaultSort(this.api);
    if (this.setDefaultOrder !== undefined) {
      this.setDefaultOrder(this.api);
    }

    setTimeout(() => (this.layoutModified = false));
  }

  private setDefaultSort = (api: GridApi): void => {
    api.applyColumnState({
      state: [
        {
          colId: 'name',
          sort: 'asc'
        }
      ]
    });
  };

  private setDefaultOrder?: (api: GridApi) => void;

  public downloadExcelFile = (): void => {
    this.setExportColumns(this.gridOptions);
    this.api.exportDataAsExcel();
  };

  private setExportColumns(gridOptions: GridOptions<any>): void {
    const keys = this.getAllChildrenRecursive(this.api.getColumnDefs())
      .map((x) => x as ColDef<any>)
      .filter((x) => x.headerName?.toUpperCase() !== 'ACTIONS' && !x.hide)
      .map((x) => x.colId!);

    gridOptions.defaultExcelExportParams!.columnKeys = keys!;
  }

  private getAllChildrenRecursive(columns: Array<ColDef<any> | ColGroupDef<any>> | undefined): Array<ColDef<any>> {
    let allChildren: Array<ColDef<any>> = [];

    if (columns) {
      columns.forEach((column: any) => {
        if (column.children) {
          allChildren = allChildren.concat(this.getAllChildrenRecursive(column.children));
        }

        allChildren.push(column);
      });
    }

    return allChildren;
  }

  public redrawRows(): void {
    this.api.redrawRows();
  }
}
