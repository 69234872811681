import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { parseMarkdown } from '../../utilities/string-methods';
import { SageDataService } from '../sage-data.service';

@Component({
  selector: 'app-sage-diag',
  templateUrl: './sage-diag.component.html',
  styleUrls: ['./sage-diag.component.css']
})
export class SageDiagComponent implements OnInit {
  @Input() public componentId?: string;
  @Input() public showShell: boolean = true;
  @Input() public showRbi?: boolean;

  public outputTextTmin: SafeHtml = '';
  public outputTextFlash: SafeHtml = '';
  public outputTextApirbi: SafeHtml = '';
  public outputTextApipe: SafeHtml = '';
  public activeTabId: number = 1;

  public constructor(private ds: DomSanitizer, private dataService: SageDataService) {}

  public ngOnInit(): void {
    this.setupTabId();

    this.dataService.loadDiag(this.componentId!, 'SHELL').subscribe((x) => {
      this.outputTextTmin = this.parseMarkdown(x);
    });
    this.dataService.loadDiag(this.componentId!, 'FLASH').subscribe((x) => {
      this.outputTextFlash = this.parseMarkdown(x);
    });
    this.dataService.loadDiag(this.componentId!, 'APIRBI').subscribe((x) => {
      this.outputTextApirbi = this.parseMarkdown(x);
    });
    this.dataService.loadDiag(this.componentId!, 'APIPE').subscribe((x) => {
      this.outputTextApipe = this.parseMarkdown(x);
    });
  }

  private setupTabId(): void {
    if (this.showShell) {
      this.activeTabId = 1;
    } else if (this.showRbi) {
      this.activeTabId = 2;
    } else {
      this.activeTabId = 4;
    }
  }

  private parseMarkdown(msg: string): SafeHtml {
    return this.ds.bypassSecurityTrustHtml(parseMarkdown(msg));
  }
}
