import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';

import { AppSettingsService } from '../app-settings/app-settings.service';
import { AttachmentCacheService } from './attachment-cache.service';
import { AttachmentDto } from './models/attachment-dto';
import { AttachmentReorderDto } from './models/attachment-reorder-data';
import { AttachmentTableType } from './models/attachment-table-type';

@Injectable({
  providedIn: 'root'
})
export class AttachmentDataService {
  private serverUri: string;

  public constructor(
    private http: HttpClient,
    private settingsService: AppSettingsService,
    private cache: AttachmentCacheService
  ) {
    this.serverUri = this.settingsService.settings.apiUri as string;
  }

  public getAttachments(tableType: AttachmentTableType, id: string): Observable<Array<AttachmentDto>> {
    return this.http.get<Array<AttachmentDto>>(`${this.serverUri}/attachments/${tableType}/key/${id}`);
  }

  public updateAttachmentsSortOrder(
    tableType: AttachmentTableType,
    id: string,
    newOrder: Array<AttachmentReorderDto>
  ): Observable<void> {
    return this.http.put<void>(`${this.serverUri}/attachments/${tableType}/key/${id}/sortorder`, newOrder);
  }

  public getAttachmentPreview(tableType: AttachmentTableType, id: string, fileId: string): Observable<Blob> {
    const cacheKey = `${tableType}-${id}-${fileId}`;
    return this.cache.get(cacheKey, () =>
      this.http
        .get<Blob>(`${this.serverUri}/attachments/${tableType}/key/${id}/file/${fileId}/preview`, {
          responseType: 'blob' as 'json'
        })
        .pipe(shareReplay(1))
    );
  }

  public getAttachment(tableType: AttachmentTableType, id: string, fileId: string): Observable<Blob> {
    return this.http.get<Blob>(`${this.serverUri}/attachments/${tableType}/key/${id}/file/${fileId}`, {
      responseType: 'blob' as 'json'
    });
  }

  public deleteAttachment(tableType: AttachmentTableType, id: string, fileId: string): Observable<boolean> {
    return this.http.delete<boolean>(`${this.serverUri}/attachments/${tableType}/key/${id}/file/${fileId}`).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }
}
