import { IRowNode } from 'ag-grid-community';

import { defaultWithNullComparer } from './default-with-null-comparator';

export function stringWithNullComparer(
  valueA: string,
  valueB: string,
  nodeA: IRowNode,
  nodeB: IRowNode,
  isDescending: boolean
): number {
  return defaultWithNullComparer(valueA, valueB, isDescending, (a, b) => a.localeCompare(b));
}
