export enum InspectionMethodTypes {
  UNKOWN = 0,
  UT = 1,
  RT = 2,
  N = 3,
  I = 4,
  S = 5,
  H = 6,
  D = 7,
  C = 8,
  P = 9,
  V = 10,
  Y = 11,
  M = 12
}
