import { ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';

import { headerBoxFormatter } from './header-box-formatter';

export function toFixedThreeFormatter(params: ValueFormatterParams | ValueGetterParams): string {
  const dataItem = params.data[params.colDef.field || ''] as string | number;

  if (!dataItem) {
    return '';
  } else if (typeof dataItem === 'number') {
    return dataItem.toFixed(3);
  } else if (dataItem.includes('x')) {
    return headerBoxFormatter(dataItem);
  }

  return Number(dataItem).toFixed(3);
}
