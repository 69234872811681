<div class="d-flex flex-col h-100 ml-4 mr-4">
  <h2 mat-dialog-title class="e2g-slideout-title pl-0">{{ data.title }}</h2>

  <e2g-fluid-designer
    class="h-100 mb-2"
    gridCssClass="ag-theme-balham"
    [initialSelection]="data.inputStream"
    [loadingTemplate]="loading"
    [nameValidationCallback]="isValid()"
    (fluidSelected)="onFluidSelected($event)"
    (cancel)="onCancel($event)"
  ></e2g-fluid-designer>
</div>

<ng-template #loading>
  <div class="loading"></div>
</ng-template>
