<section>
  <div class="box">
    <div class="box__ghost">
      <div class="symbol"></div>
      <div class="symbol"></div>
      <div class="symbol"></div>
      <div class="symbol"></div>
      <div class="symbol"></div>
      <div class="symbol"></div>

      <div class="box__ghost-container">
        <div
          class="box__ghost-eyes"
          [ngStyle]="{ transform: ghostEyeCoordinates }"
        >
          <div class="box__eye-left"></div>
          <div class="box__eye-right"></div>
        </div>
        <div class="box__ghost-bottom">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div class="box__ghost-shadow"></div>
    </div>
    <div class="box__description">
      <div class="box__description-container">
        <div class="box__description-title">Whoops!</div>
        <div class="box__description-text">
          It seems like we couldn't find the page you were looking for
        </div>
      </div>

      <!-- Go Back Button-->
      <a (click)="onGoBack()" class="box__button pointerCursor">
        Go back
      </a>
    </div>
  </div>
</section>
