import { Component, OnInit } from '@angular/core';
import { CircuitDataService } from 'src/app/circuit-data.service';
import { OnDestroyBaseComponent } from 'src/app/on-destroy-base-component/on-destroy-base-component';
import { IDialogFormData } from 'src/app/shared-module/dialog/dialog-form-data';
import { DialogData } from 'src/app/shared-module/models/dialog-data';
import { requiredMessage, valueInListMessage } from 'src/app/utilities/validation-messages';

import { CircuitAddDialogData } from '../../models/circuit-dialog-data';

@Component({
  selector: 'app-circuit-dialog',
  templateUrl: './circuit-dialog.component.html',
  styleUrls: ['./circuit-dialog.component.css'],
  providers: [
    {
      provide: IDialogFormData,
      useExisting: CircuitDialogComponent
    }
  ]
})
export class CircuitDialogComponent extends OnDestroyBaseComponent implements OnInit, IDialogFormData {
  public circuitList!: string[];
  public description?: string;
  public dialogData!: DialogData;
  public circuitFormData!: CircuitAddDialogData;

  public nameErrors: string[] = [];
  private isloaded = false;

  public constructor(private circuitService: CircuitDataService) {
    super();
  }

  public ngOnInit(): void {
    this.dialogData.validityCallback = this.isValid;
  }

  public setDialogData(dialogData: DialogData): void {
    this.dialogData = dialogData;
  }

  public setFormData(formData: CircuitAddDialogData): void {
    this.circuitFormData = formData;
    this.circuitService.getCircuits(formData.assetId).subscribe((x) => {
      this.circuitList = x.map((x) => x.name!.trim().toLocaleLowerCase());
      this.updateNameErrorsArray();
      this.isloaded = true;
    });
  }

  public updateNameErrorsArray(): void {
    this.nameErrors = [];
    if (this.circuitFormData.name.trim().length === 0) {
      this.nameErrors.push(requiredMessage());
    }
    if (this.isNameDuplicate()) {
      this.nameErrors.push(valueInListMessage('Group'));
    }
  }

  private isNameDuplicate(): boolean {
    return this.circuitList.includes(this.circuitFormData.name.trim().toLocaleLowerCase());
  }

  public isValid = (): boolean => {
    return this.circuitFormData.name.trim().length > 0 && this.nameErrors.length === 0 && this.isloaded;
  };
}
