import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { DmlDetailSummaryBaseComponent } from 'src/app/dmls-module/dml-slideout/dml-detail-summary-base-component';

import { DmlDentOutputDto } from '../../models/dml-dent-output-dto';
import { DmlTypes } from '../../models/Enums/dml-types';

@Component({
  selector: 'app-dml-dent-summary',
  templateUrl: './dml-dent-summary.component.html',
  styleUrls: ['./dml-dent-summary.component.css']
})
export class DmlDentSummaryComponent extends DmlDetailSummaryBaseComponent<DmlDentOutputDto> {
  protected dmlType = DmlTypes['Dent'];

  public materialYieldStressUom: Observable<string> = this.uomEvaluator.getUnits('DmlDent_MaterialYieldStress');
  public maximumYieldStressUom: Observable<string> = this.uomEvaluator.getUnits('DmlDent_MaximumYieldStress');
  public materialTensileStressUom: Observable<string> = this.uomEvaluator.getUnits('DmlDent_MaterialTensileStress');
  public maximumTensileStressUom: Observable<string> = this.uomEvaluator.getUnits('DmlDent_MaximumTensileStress');

  public shellOutsideDiameterUom: Observable<string> = this.uomEvaluator.getUnits('DmlDent_ShellOutsideDiameter');
  public minimumOutsideDiameterUom: Observable<string> = this.uomEvaluator.getUnits('DmlDent_MinimumOutsideDiameter');
  public maximumOutsideDiameterUom: Observable<string> = this.uomEvaluator.getUnits('DmlDent_MaximumOutsideDiameter');
  public currentThicknessUom: Observable<string> = this.uomEvaluator.getUnits('DmlDent_CurrentThickness');
  public minimumCurrentThicknessUom: Observable<string> = this.uomEvaluator.getUnits('DmlDent_MinimumCurrentThickness');
  public maximumCurrentThicknessUom: Observable<string> = this.uomEvaluator.getUnits('DmlDent_MaximumCurrentThickness');
  public diameterToThicknessRatioUom: Observable<string> = this.uomEvaluator.getUnits(
    'DmlDent_DiameterToThicknessRatio'
  );
  public minimumDotRatioUom: Observable<string> = this.uomEvaluator.getUnits('DmlDent_MinimumDotRatio');
  public maximumDotRatioUom: Observable<string> = this.uomEvaluator.getUnits('DmlDent_MaximumDotRatio');
  public dentRadiusUom: Observable<string> = this.uomEvaluator.getUnits('DmlDent_DentRadius');
  public minimumDentRadiusUom: Observable<string> = this.uomEvaluator.getUnits('DmlDent_MinimumDentRadius');
  public corrodedMinimumMeasuredThicknessUom: Observable<string> = this.uomEvaluator.getUnits(
    'DmlDent_CorrodedMinimumMeasuredThickness'
  );
  public thicknessLimitStructuralUom: Observable<string> = this.uomEvaluator.getUnits(
    'DmlDent_ThicknessLimitStructural'
  );
  public pressurizedDentDepthUom: Observable<string> = this.uomEvaluator.getUnits('DmlDent_PressurizedDentDepth');
  public gougeDepthToThicknessRatioUom: Observable<string> = this.uomEvaluator.getUnits(
    'DmlDent_GougeDepthToThicknessRatio'
  );
  public maximumGougeDepthRatioUom: Observable<string> = this.uomEvaluator.getUnits('DmlDent_MaximumGougeDepthRatio');
  public maximumAllowableDentDepthUom: Observable<string> = this.uomEvaluator.getUnits(
    'DmlDent_MaximumAllowableDentDepth'
  );
  public gougeDepthUom: Observable<string> = this.uomEvaluator.getUnits('DmlDent_GougeDepth');
  public maximumAllowableGougeDepthUom: Observable<string> = this.uomEvaluator.getUnits(
    'DmlDent_MaximumAllowableGougeDepth'
  );
  public designPressureUom: Observable<string> = this.uomEvaluator.getUnits('DmlDent_DesignPressure');
  public undamagedMawpUom: Observable<string> = this.uomEvaluator.getUnits('DmlDent_UndamagedMawp');
  public damagedMawpUom: Observable<string> = this.uomEvaluator.getUnits('DmlDent_DamagedMawp');

  public constructor() {
    super();
  }

  protected displaySummary(output: DmlDentOutputDto): void {
    if (output.dentGougeAssessmentMessages !== undefined) {
      output.dentGougeAssessmentMessages = this.formatSummarySection(output.dentGougeAssessmentMessages);
    }

    if (output.dentGougeApplicabilityAndLimitationsMessages !== undefined) {
      output.dentGougeApplicabilityAndLimitationsMessages = this.formatSummarySection(
        output.dentGougeApplicabilityAndLimitationsMessages
      );
    }
  }
}
