import { Component, Input } from '@angular/core';
import { faX } from '@fortawesome/pro-solid-svg-icons';
import { ModuleRouteData, SageNavService } from 'src/app/shared-module/services/sage-nav.service';

import { Toast } from '../models/toast';
import { ToastService } from '../toast.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.css']
})
export class ToastComponent {
  @Input() public toast!: Toast;
  public faX = faX;

  public constructor(private toastService: ToastService, private sageNavService: SageNavService) {}

  public close(): void {
    this.toastService.remove(this.toast);
  }

  public goToLink(moduleRouteData: ModuleRouteData): void {
    this.sageNavService.navSageModuleFromRouteData(moduleRouteData, this.toast.childIndex);
    this.close();
  }
}
