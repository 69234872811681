import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';

import { ToastComponent } from './toast/toast.component';
import { ToastsContainerComponent } from './toasts-container/toasts-container.component';

@NgModule({
  declarations: [ToastsContainerComponent, ToastComponent],
  imports: [NgbModule, CommonModule, NgbToastModule, FontAwesomeModule],
  exports: [NgbModule, ToastsContainerComponent, NgbToastModule]
})
export class ToastModule {}
