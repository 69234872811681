import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { filter, finalize, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { CalculationDataService } from 'src/app/calculation-data.service';
import { AssetTypes } from 'src/app/models/enums/asset-types';
import { UnitOfMeasure } from 'src/app/models/enums/unit-of-measure';
import { AssetGoverningInspectionDomainModel } from 'src/app/shared-module/asset-governing-inspection-settings/asset-governing-inspection-domain-model';
import { AssetFeature, GeneralFeature } from 'src/app/shared-module/permission/permission-models';
import { PermissionService } from 'src/app/shared-module/permission/permission.service';
import { NotificationService } from 'src/app/shared-module/services/notification.service';
import { SingleDataHandlerDefault } from 'src/app/shared-module/single-data-handler-default';

import { CompanyService } from '../../company.service';
import { EquipmentDataService } from '../../equipment-data.service';
import { EquipmentConfigurationDto } from '../../models/equipment-configuration-dto';
import { EquipmentUnitInput } from '../../models/equipment-unit-input';
import { OnDestroyBaseComponent } from '../../on-destroy-base-component/on-destroy-base-component';
import { ISaveChanges } from '../../save-changes';
import { AssetDefaultsConfigurationDto } from '../models/asset-defaults-configuration-dto';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.css'],
  providers: [
    {
      provide: ISaveChanges,
      useExisting: ConfigurationComponent
    }
  ]
})
export class ConfigurationComponent extends OnDestroyBaseComponent implements OnInit, ISaveChanges {
  @Input() public assetType!: AssetTypes;

  public assetGoverningInspectionDomainModel!: AssetGoverningInspectionDomainModel<EquipmentConfigurationDto>;
  public assetDefaultsConfigurationValid: boolean = false;

  public dataHandler: SingleDataHandlerDefault<EquipmentConfigurationDto>;

  public saving: boolean = false;
  public inputData!: EquipmentUnitInput;
  public loading: boolean = true;

  public showMawpApproach = true;

  public refreshData = new Subject<void>();

  public readOnly: boolean = true;
  public rbiEnabled: boolean = false;
  public canCalculate: boolean = false;
  public scraEnabled: boolean = false;

  public assetDefaultsConfiguration!: AssetDefaultsConfigurationDto;

  public constructor(
    private permissionService: PermissionService,
    private readonly route: ActivatedRoute,
    private readonly companyService: CompanyService,
    private readonly equipService: EquipmentDataService,
    private notificationService: NotificationService,
    private calcService: CalculationDataService
  ) {
    super();
    this.dataHandler = new SingleDataHandlerDefault<EquipmentConfigurationDto>(
      this.destroy,
      undefined,
      this.companyService
    );
  }

  public ngOnInit(): void {
    this.showMawpApproach = this.assetType != AssetTypes.StorageTank;
    this.route.params
      .pipe(
        tap((params) => {
          this.setInputData(params);
        }),
        switchMap(() => this.assignPermissions()),
        switchMap(() => this.refreshData.pipe(startWith(null))),
        tap(() => {
          this.dataHandler.clear();
        }),
        filter(() => this.inputData.equipmentKey !== undefined),
        switchMap(() => this.equipService.getAssetDefaultsConfiguration(this.inputData.equipmentKey)),
        tap((configuration) => {
          this.assetDefaultsConfiguration = configuration;
        }),
        switchMap(() => this.equipService.getEquipmentConfiguration(this.inputData.equipmentKey)),
        takeUntil(this.destroy)
      )
      .subscribe((configuration) => {
        this.dataHandler.setInitialData(configuration);

        this.assetGoverningInspectionDomainModel = new AssetGoverningInspectionDomainModel(this.dataHandler);

        this.loading = false;
      });
  }

  private assignPermissions(): Observable<any> {
    return this.permissionService
      .getPermissions({
        asset: [{ feature: AssetFeature.Asset, assetId: this.inputData.equipmentKey! }],
        general: [
          { feature: GeneralFeature.Rbi },
          { feature: GeneralFeature.ScraRates },
          { feature: GeneralFeature.ForceCalculation }
        ]
      })
      .pipe(
        tap((permissions) => {
          this.readOnly = !permissions.asset[AssetFeature.Asset].edit;
          this.rbiEnabled = permissions.general[GeneralFeature.Rbi];
          this.scraEnabled = permissions.general[GeneralFeature.ScraRates];
          this.canCalculate = permissions.general[GeneralFeature.ForceCalculation];
        })
      );
  }

  public onSave(): void {
    this.saveChanges().subscribe();
  }

  public calculate(): void {
    this.calcService.calculateAsset(this.inputData.equipmentKey).subscribe(() => {
      this.notificationService.showInfo('Calculating Asset');
    });
  }

  public isDirty(): boolean {
    return this.dataHandler.dirty;
  }

  public saveChanges(): Observable<any> {
    this.saving = true;

    const savedData = this.dataHandler.getDataWithEmptyStringsRemoved();

    savedData.defaultProperties = this.dataHandler.getDefaultPropNames();

    return this.equipService.updateConfiguration(savedData, this.inputData.equipmentKey).pipe(
      tap((success) => {
        this.notificationService.showSaveResult(success);
        if (success) {
          this.refreshData.next();
        }
      }),
      finalize(() => (this.saving = false))
    );
  }

  public revertChanges(): void {
    this.dataHandler.revertChanges();
  }

  public canSaveChanges(): boolean {
    return this.isDirty() && this.assetDefaultsConfigurationValid;
  }

  public isValid(): void {
    this.canSaveChanges();
  }

  private setInputData(params: Params): void {
    this.inputData = {
      equipmentKey: params.equipmentKey,
      unitKey: params.unitKey,
      unitOfMeasure: params.unitOfMeasure === 'UsStandard' ? UnitOfMeasure.UsStandard : UnitOfMeasure.Metric
    };
  }
}
