import { Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { faFastBackward, faFastForward, faStepBackward, faStepForward } from '@fortawesome/pro-solid-svg-icons';
import { OnDestroyBaseComponent } from 'src/app/on-destroy-base-component/on-destroy-base-component';

interface PaginationData {
  page: number;
  selected: boolean;
}

@Component({
  selector: 'app-pdf-paging',
  templateUrl: './pdf-paging.component.html',
  styleUrls: ['./pdf-paging.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PdfPagingComponent),
      multi: true
    }
  ]
})
export class PdfPagingComponent extends OnDestroyBaseComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input()
  public totalPages: number = 0;
  public currentPage: number = 0;

  public faStepBackward = faStepBackward;
  public faStepForward = faStepForward;
  public faFastBackward = faFastBackward;
  public faFastForward = faFastForward;

  private currentPageInternal: number = 1;
  private pages: Array<PaginationData> = new Array<PaginationData>();

  private onChange: ((value: any) => void) | undefined;
  public onTouched: (() => void) | undefined;

  public registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public writeValue(value: any): void {
    this.internalUpdateData(value);
  }

  public ngOnChanges(change: SimpleChanges): void {
    if (change.value !== undefined && change.value !== null) {
      const currentValue = change.value.currentValue;
      this.currentPage = currentValue;
    }
  }

  public ngOnInit(): void {
    this.configPages();
  }

  public prevDisabled(): boolean {
    return this.currentPageInternal <= 1;
  }

  public nextDisabled(): boolean {
    return this.currentPageInternal > this.totalPages - 1;
  }

  public first(): void {
    if (this.prevDisabled()) {
      return;
    }
    this.currentPageInternal = 1;
    this.handleInput(this.currentPageInternal);
    this.updateSelected();
  }

  public prev(): void {
    if (this.prevDisabled()) {
      return;
    }
    this.currentPageInternal--;
    this.handleInput(this.currentPageInternal);
    this.updateSelected();
  }

  public next(): void {
    if (this.nextDisabled()) {
      return;
    }
    this.currentPageInternal++;
    this.handleInput(this.currentPageInternal);
    this.updateSelected();
  }

  public last(): void {
    if (this.nextDisabled()) {
      return;
    }
    this.currentPageInternal = this.totalPages;
    this.handleInput(this.currentPageInternal);
    this.updateSelected();
  }

  private handleInput(target: number): void {
    this.currentPage = target;
    if (this.onChange) {
      this.onChange(target);
    }
  }

  public updateData(event: Event): void {
    let value = parseInt((event.target as HTMLInputElement).value);
    if (isNaN(value)) {
      value = 0;
    }
    this.internalUpdateData(value);
  }

  private internalUpdateData(value: number): void {
    value = Math.min(Math.max(1, value), Math.max(this.totalPages, 1));

    this.currentPageInternal = value;
    this.handleInput(this.currentPageInternal);
    this.updateSelected();
  }

  private updateSelected(): void {
    if (this.currentPageInternal < 3) {
      for (const item of this.pages) {
        item.selected = this.currentPageInternal === item.page;
      }
    } else if (this.currentPageInternal > this.totalPages - 2) {
      for (const item of this.pages) {
        item.selected = this.currentPageInternal === item.page;
      }
    } else {
      this.configPages();
    }
  }

  private configPages(): void {
    this.pages = new Array<PaginationData>();
    let page: number = Math.max(1, this.currentPageInternal - 2);

    const maxPages = Math.min(5, this.totalPages);
    for (let i = 0; i < maxPages; i++) {
      const selected: boolean = this.currentPageInternal === page;
      this.pages.push({ page: page++, selected });
    }
  }
}
