import { CellClassRules, ColDef } from 'ag-grid-community';
import {
  getGoverningCorrosionRateType,
  GoverningCorrosionRateTypes
} from 'src/app/asset-module/models/enums/governing-corrosion-rate-types';
import { InspectionMethodTypes } from 'src/app/asset-module/models/enums/inspection-method-types';
import { buildCheckboxColDef } from 'src/app/grid-module/column-builders/build-checkbox';
import { buildComponentSizeColDef } from 'src/app/grid-module/column-builders/build-component-size';
import { buildDateOnlyColDef } from 'src/app/grid-module/column-builders/build-date-only';
import { buildDoubleColDef } from 'src/app/grid-module/column-builders/build-double';
import { buildEnumColDef } from 'src/app/grid-module/column-builders/build-enum';
import { buildRemainingLifeColDef } from 'src/app/grid-module/column-builders/build-remaining-life';
import { buildScraRateColDef } from 'src/app/grid-module/column-builders/build-scra-rate';
import { columnCellRateRule } from 'src/app/grid-module/column-cell-css-rules/column-cell-rate-rule';
import { stringWithNullComparer } from 'src/app/grid-module/column-data-comparers/string-with-blank-comparator';
import { getMinimumThicknessColumn } from 'src/app/grid-module/column-definitions/minimum-thickness-column';
import { compTypeValueFormatter } from 'src/app/grid-module/column-formatters/component-type-formatter';
import { emptyDoubleValueFormatter } from 'src/app/grid-module/column-formatters/empty-double-formatter';
import { getInspectionDriver, InspectionDrivers } from 'src/app/reporting-module/models/enums/Inspection-drivers';
import { DecimalEditor } from 'src/app/shared-module/ag-grid/editor-components/decimal-editor';

export function getBaseCmlColumns(
  thicknessClassRules: CellClassRules,
  readOnly: () => boolean,
  hasScra: boolean,
  canEditReadingProperties: () => boolean
): Array<ColDef> {
  return [
    {
      headerName: 'Id',
      field: 'id',
      hide: true
    },
    {
      headerName: 'Description',
      headerTooltip: 'Description',
      field: 'description',
      tooltipField: 'description',
      width: 200,
      hide: true
    },
    {
      headerName: 'Comments',
      headerTooltip: 'Comments',
      field: 'comments',
      tooltipField: 'comments',
      width: 300,
      hide: true
    },
    {
      headerName: 'Drawing',
      headerTooltip: 'Drawing',
      field: 'drawing',
      tooltipField: 'drawing',
      width: 175,
      comparator: stringWithNullComparer,
      hide: true
    },
    {
      headerName: 'Access Limitations',
      headerTooltip: 'Access Limitations',
      field: 'accessLimitations',
      tooltipField: 'accessLimitations',
      width: 190,
      hide: true
    },
    {
      headerName: 'Line Number',
      headerTooltip: 'Line Number',
      field: 'lineNumber',
      tooltipField: 'lineNumber',
      width: 190,
      hide: true
    },
    buildCheckboxColDef(
      'Inactive',
      'active',
      {
        readonly: readOnly,
        invert: true
      },
      100,
      undefined,
      false
    ),
    {
      headerName: 'Group',
      headerTooltip: 'Group',
      field: 'circuitName',
      tooltipField: 'circuitName'
    },
    {
      headerName: 'Orientation',
      headerTooltip: 'Orientation',
      field: 'orientation',
      tooltipField: 'orientation',
      width: 115
    },
    {
      ...buildEnumColDef('Method', 'inspectionMethod', InspectionMethodTypes),
      comparator: stringWithNullComparer,
      width: 100
    },
    buildRemainingLifeColDef('Remaining Life', 'remainingLife'),
    {
      headerName: 'Component',
      headerTooltip: 'Component',
      field: 'componentName',
      tooltipField: 'componentName',
      width: 125
    },
    {
      headerName: 'Component Type',
      headerTooltip: 'Component Type',
      field: 'componentType',
      tooltipField: 'componentType',
      width: 175,
      valueFormatter: compTypeValueFormatter
    },
    {
      headerName: 'ID',
      headerTooltip: 'ID',
      field: 'innerDiameter',
      tooltipField: 'innerDiameter',
      width: 75,
      filter: 'agNumberColumnFilter'
    },
    {
      headerName: 'OD',
      headerTooltip: 'OD',
      field: 'outerDiameter',
      tooltipField: 'outerDiameter',
      width: 75,
      valueFormatter: emptyDoubleValueFormatter,
      filter: 'agNumberColumnFilter'
    },
    {
      headerName: 'Material',
      headerTooltip: 'Material',
      field: 'material',
      tooltipField: 'material'
    },
    buildDateOnlyColDef('InService Date', 'inServiceDate'),
    buildDateOnlyColDef('Survey Date', 'date'),
    {
      ...buildDoubleColDef('Thickness', 'thickness', thicknessClassRules),
      width: 110
    },
    getMinimumThicknessColumn(),
    buildCheckboxColDef(
      'Exclude',
      'excludeFromCalculation',
      {
        readonly: readOnly
      },
      150,
      undefined,
      undefined,
      false
    ),
    {
      ...buildDateOnlyColDef('Next Inspection', 'nextInspection'),
      width: 150
    },
    buildDateOnlyColDef('Retirement', 'retirementDate'),
    {
      ...buildEnumColDef(
        'Gov. Corr. Rate Type',
        'governingCorrosionRateType',
        GoverningCorrosionRateTypes,
        getGoverningCorrosionRateType
      ),
      width: 150
    },
    {
      ...buildDoubleColDef('Long Rate', 'longRate', columnCellRateRule(), true),
      width: 110
    },
    {
      ...buildDoubleColDef('Short Rate', 'shortRate', columnCellRateRule(), true),
      width: 110
    },
    {
      ...buildDoubleColDef('Best Fit', 'linearRate', columnCellRateRule(), true),
      width: 100
    },
    {
      ...buildDoubleColDef('MAWP', 'maximumAllowableWorkingPressure'),
      width: 125
    },
    {
      ...buildComponentSizeColDef('Size', 'size'),
      width: 75,
      hide: true
    },
    {
      ...buildDoubleColDef('Thickness (TEMP CORRECTED)', 'thickness', thicknessClassRules),
      colId: 'thicknessCorrected',
      hide: true,
      cellEditor: DecimalEditor,
      suppressColumnsToolPanel: true
    },
    {
      ...buildDoubleColDef('Original Thickness', 'origMeasThickness', thicknessClassRules),
      hide: true,
      suppressColumnsToolPanel: true
    },

    buildCheckboxColDef(
      'Baseline',
      'isBaseline',
      {
        readonly: canEditReadingProperties
      },
      undefined,
      undefined,
      undefined,
      true
    ),
    buildCheckboxColDef(
      'Injection Point',
      'injectionPoint',
      {
        readonly: readOnly
      },
      175,
      undefined,
      undefined,
      true
    ),
    buildCheckboxColDef(
      'Soil To Air',
      'soilToAir',
      {
        readonly: readOnly
      },
      150,
      undefined,
      undefined,
      true
    ),
    {
      ...buildDateOnlyColDef('Replacement Date', 'replacementDate'),
      width: 110,
      hide: true
    },
    { headerName: 'ReadingId', field: 'readingId', hide: true },

    {
      ...buildEnumColDef('Next Insp. Date Driver', 'inspectionDriver', InspectionDrivers, getInspectionDriver),
      width: 150,
      hide: true
    },
    {
      headerName: 'Inspection Group',
      headerTooltip: 'Inspection Group',
      field: 'inspectionGroup',
      tooltipField: 'inspectionGroup',
      width: 175,
      comparator: stringWithNullComparer,
      hide: true
    },
    ...(hasScra ? getScraColumns() : [])
  ];
}

export function getScraColumns(): Array<ColDef> {
  return [
    {
      ...buildScraRateColDef('SCRA Long Rate', 'scraLongRate'),
      width: 150
    },
    {
      ...buildScraRateColDef('SCRA Short Rate', 'scraShortRate'),
      width: 150
    },
    {
      ...buildDoubleColDef('Stat Long Rate', 'scraLongRateStatistical', columnCellRateRule(), true),
      width: 150
    },
    {
      ...buildDoubleColDef('Stat Short Rate', 'scraShortRateStatistical', columnCellRateRule(), true),
      width: 150
    }
  ];
}
