import { ColDef, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { assignHeaderAndTooltip } from 'src/app/grid-module/column-builders/build-default';
import { Enum } from 'src/app/utilities/enum-helper';

export function buildEnumColDef(
  header: string,
  field: string,
  enumType: Enum,
  enumTranslationFunc?: (key: number) => string
): ColDef {
  return {
    ...assignHeaderAndTooltip(header, field),

    // Value Getter is needed to make the export work.
    valueGetter: (params: ValueGetterParams): string => getStringFromEnum(params, enumType, enumTranslationFunc),
    // Value Formatter is needed to make the tooltip work.
    valueFormatter: (params: ValueFormatterParams): string => getStringFromEnum(params, enumType, enumTranslationFunc)
  };
}

function getStringFromEnum(
  params: ValueGetterParams | ValueFormatterParams,
  enumType: Enum,
  translationFunc?: (key: number) => string
): string {
  const value = params.data[params.colDef.field!];
  return translationFunc ? translationFunc(value) : enumType[value];
}
