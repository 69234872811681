import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { dateOnly, DateOnlyUtility, E2gSelectOption } from '@equityeng/e2g-ng-ui';
import { faClone, faInputText, faMemo } from '@fortawesome/pro-regular-svg-icons';
import { faPencilAlt, faTable, faTrashXmark } from '@fortawesome/pro-solid-svg-icons';
import {
  CellClickedEvent,
  CellValueChangedEvent,
  FirstDataRenderedEvent,
  GridApi,
  ICellRendererParams,
  IRowNode,
  ProcessCellForExportParams,
  RangeSelectionChangedEvent,
  StartEditingCellParams,
  ValueFormatterParams
} from 'ag-grid-community';
import { combineLatest, forkJoin, Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { AlertService } from 'src/app/alert.service';
import { CmlSlideoutOption } from 'src/app/asset-module/models/cml-name-identifier';
import { CmlViewModel } from 'src/app/asset-module/models/cml-view-model';
import { Breadcrumb } from 'src/app/breadcrumb-module/breadcrumbs/breadcrumbs.component';
import { EquipmentDataService } from 'src/app/equipment-data.service';
import { GridCellLinkParams } from 'src/app/grid-module/cell-renders/grid-cell-link/grid-cell-link-params';
import { GridCellLinkComponent } from 'src/app/grid-module/cell-renders/grid-cell-link/grid-cell-link.component';
import { ScraRateData } from 'src/app/grid-module/cell-renders/grid-cell-scra-calc/grid-cell-scra-rate.component';
import { buildDefaultColDef } from 'src/app/grid-module/column-builders/build-default';
import { columnCellThicknessRule } from 'src/app/grid-module/column-cell-css-rules/column-cell-thickness-rule';
import { getBaseCmlColumns } from 'src/app/grid-module/column-definitions/cml-grid-columns';
import { E2gAgGridComponent, GridBaseOptions } from 'src/app/grid-module/e2g-ag-grid/e2g-ag-grid.component';
import { rowDrivingCmlRule } from 'src/app/grid-module/row-class-rules/row-driving-cml-rule';
import { UnitOfMeasure } from 'src/app/models/enums/unit-of-measure';
import { OnDestroyBaseComponent } from 'src/app/on-destroy-base-component/on-destroy-base-component';
import { ISaveChanges } from 'src/app/save-changes';
import { ActionTypes } from 'src/app/shared-module/models/action-types';
import { DialogType, NameDialogData } from 'src/app/shared-module/models/name-dialog-data';
import { NEW_ITEM_KEY } from 'src/app/shared-module/models/new-item-key';
import {
  AssetFeature,
  AssetPermissionModel,
  GeneralFeature,
  NO_PERMISSION
} from 'src/app/shared-module/permission/permission-models';
import { PermissionService } from 'src/app/shared-module/permission/permission.service';
import { NotificationService } from 'src/app/shared-module/services/notification.service';
import { SingleDataHandler } from 'src/app/shared-module/single-data-handler';
import { LATEST } from 'src/app/shared/constants/date-constants';
import { StateService } from 'src/app/state.service';
import { UnitsOfMeasureEvaluator } from 'src/app/units-of-measure/units-of-measure-evaluator';
import { UserService } from 'src/app/user.service';
import { anyIdsInCommon, flattenArray } from 'src/app/utilities/array-helper';
import { areObjectsEqual } from 'src/app/utilities/compare-helper';
import { formatScraRate } from 'src/app/utilities/format-scra-rate';

import { CompanyService } from '../../../company.service';
import { E2gMenuItem } from '../../../grid-module/cell-renders/grid-cell-menu/e2g-menu-item';
import { GridCellMenuComponent } from '../../../grid-module/cell-renders/grid-cell-menu/grid-cell-menu-component';
import { AssetTypes } from '../../../models/enums/asset-types';
import { UpdateType } from '../../../models/enums/update-type';
import { EquipmentUnitInput } from '../../../models/equipment-unit-input';
import { RefreshService } from '../../../notifications-module/refresh.service';
import { DialogService } from '../../../shared-module/dialog.service';
import { ListDataHandler } from '../../../shared-module/list-data-handler';
import { SlideOutService } from '../../../slide-out-module/slide-out.service';
import { SurveyService } from '../../../survey.service';
import { CmlDto } from '../../models/cml-dto';
import { CmlSlideoutInput } from '../../models/cml-slideout-input';
import { ReadingDto } from '../../models/reading-dto';
import { SurveyDto } from '../../models/survey-dto';
import { CmlDataService } from '../../services/cml-data.service';
import { ReadingDataService } from '../../services/reading-data.service';
import { AllReadingsData } from '../all-cml-readings/all-cml-readings.component';
import { CmlSlideoutComponent } from '../cml-slideout/cml-slideout.component';

@Component({
  selector: 'app-cml',
  templateUrl: './cml.component.html',
  styleUrls: ['./cml.component.css'],
  providers: [
    {
      provide: ISaveChanges,
      useExisting: CmlComponent
    }
  ]
})
export class CmlComponent extends OnDestroyBaseComponent implements OnInit, OnDestroy, ISaveChanges {
  @Input() public breadcrumbs: Breadcrumb[] = [];
  @Input() public equipmentType!: AssetTypes;
  @ViewChild('cmlGrid', { static: false }) public cmlGrid?: E2gAgGridComponent;

  public hasSurveyTempCorrections?: boolean = false;

  public inputData!: EquipmentUnitInput;

  public refreshData = new Subject<void>();
  public gridReady = new Subject<void>();
  public cmlViewModel: Array<CmlViewModel> = [];
  public initialDataCopy: Array<CmlDto> = [];

  private cmls: Array<CmlDto> | undefined;
  public surveys: Array<SurveyDto> | undefined;
  public gridDataHandler: ListDataHandler<CmlViewModel>;
  public surveyTempDataHandler: SingleDataHandler<SurveyDto>; //Currently this is only being used for temp corrections.

  public saving: boolean = false;
  public bHighlightDrivingCml: boolean = true;
  private lowestRetirementDate: string | undefined;

  private readonly ALL = 'All';
  public selectedDate: dateOnly | string = LATEST;
  public dateSelectOptions: Array<E2gSelectOption> = [];
  public temperatureUom?: string;
  public currUnitSys?: UnitOfMeasure;

  public singleCmlSelected = false;
  public editExistingCmlForm = false;

  private faPencilAlt = faPencilAlt;
  private faTable = faTable;
  public faTrashXmark = faTrashXmark;
  public faInputText = faInputText;
  private faClone = faClone;
  private faMemo = faMemo;

  public cmlPermissions: AssetPermissionModel = NO_PERMISSION;

  private colsToKeep = ['name', 'circuitName', 'componentType', 'orientation', 'minimumThickness', 'thickness'];

  public loadingGridOptions = true;

  private calcRefresh: Observable<void> = this.refreshService.updates.pipe(
    filter(
      (impactedIds) =>
        !this.gridDataHandler.dirty &&
        anyIdsInCommon(
          impactedIds.cmlIds,
          this.gridDataHandler.data?.map((x) => x.id)
        )
    ),
    map(() => undefined),
    startWith(undefined),
    takeUntil(this.destroy)
  );

  private menuDefinition: Array<E2gMenuItem> = [
    {
      text: 'Edit',
      command: (params: ICellRendererParams): void => this.openCml(params.data.id),
      iconDefinition: { icon: this.faPencilAlt },
      visible: (): boolean => this.cmlPermissions.edit
    },
    {
      text: 'Show All Readings',
      command: (params: ICellRendererParams): void => this.showAllReadings(params),
      iconDefinition: { icon: this.faTable }
    },
    {
      text: 'Rename',
      command: (params: ICellRendererParams): void =>
        this.renameCml(params.data.id, params.data.name, params.data.componentId, params.data.circuitId),
      iconDefinition: { icon: this.faInputText },
      visible: (): boolean => this.cmlPermissions.edit
    },
    {
      text: 'Delete',
      command: (params: ICellRendererParams): void => this.deleteCml(params.data.id, params.data.name),
      iconDefinition: { icon: this.faTrashXmark, color: 'var(--system-fg-error)' },
      visible: (): boolean => this.cmlPermissions.delete
    },
    {
      text: 'Delete Reading',
      command: (params: ICellRendererParams): void => this.deleteReading(params.data.readingId, params.data.name),
      iconDefinition: { icon: this.faTrashXmark, color: 'var(--system-fg-error)' },
      visible: (params: ICellRendererParams): boolean => this.canDeleteReading(params.data.id)
    },
    {
      text: 'Copy',
      command: (params: ICellRendererParams): void =>
        this.copyCml(params.data.id, params.data.name, params.data.componentId, params.data.circuitId),
      iconDefinition: { icon: this.faClone },
      visible: (): boolean => this.cmlPermissions.edit
    },
    {
      text: 'Show Detail',
      command: (params: ICellRendererParams): void => this.openCml(params.data.id),
      iconDefinition: { icon: this.faMemo },
      visible: (): boolean => !this.cmlPermissions.edit
    }
  ];

  public cmlGridOptions!: GridBaseOptions;
  public allReadingsData?: AllReadingsData;
  public canEditThickness = false;
  public canEditActivity = false;

  public constructor(
    private permissionService: PermissionService,
    private route: ActivatedRoute,
    private companyService: CompanyService,
    private cmlService: CmlDataService,
    private surveyService: SurveyService,
    private notificationService: NotificationService,
    private dialogService: DialogService,
    private readingService: ReadingDataService,
    private slideOutService: SlideOutService,
    private refreshService: RefreshService,
    private uomEvaluator: UnitsOfMeasureEvaluator,
    private assetService: EquipmentDataService,
    private stateService: StateService,
    private alertService: AlertService,
    private userService: UserService
  ) {
    super();

    this.gridDataHandler = new ListDataHandler<CmlViewModel>('id', 'updateType', this.destroy, this.companyService);

    this.surveyTempDataHandler = new SingleDataHandler<SurveyDto>(this.destroy);
  }

  public ngOnInit(): void {
    this.route.params
      .pipe(
        take(1),
        tap((params) => {
          this.setInputData(params);
        }),
        switchMap(() => this.assignAccess()),
        switchMap(() => combineLatest([this.refreshData.pipe(startWith(null)), this.calcRefresh, this.gridReady])),
        tap(() => {
          this.gridDataHandler.clearData();
        }),
        filter(() => this.inputData.equipmentKey !== undefined && this.inputData.unitKey !== undefined),
        switchMap(() =>
          forkJoin([
            this.cmlService.getCmls(this.inputData.equipmentKey),
            this.surveyService.getSurveys(this.inputData.equipmentKey),
            this.assetService.getSingleEquipment(this.inputData.equipmentKey),
            this.userService.userData.pipe(take(1))
          ])
        ),
        takeUntil(this.destroy)
      )
      .subscribe(([cmls, surveys, equipment, userData]) => {
        this.cmls = cmls;
        this.bHighlightDrivingCml = userData.highlightDrivingCml;
        this.hasSurveyTempCorrections = equipment.hasSurveyTempCorrections;
        this.surveys = surveys.sort((a, b) => DateOnlyUtility.sortAsc(a.date, b.date));
        const surveyDates = [...new Set(this.surveys!.map((item) => item.date!))].sort((a, b) =>
          DateOnlyUtility.sortAsc(a, b)
        );

        this.dateSelectOptions = [
          { label: LATEST },
          { label: this.ALL },
          ...surveyDates.map((x) => ({ label: DateOnlyUtility.formatAsE2gDate(x), value: x }))
        ];

        if (!this.allReadingsData) {
          this.showCorrectColumns();
          this.setupSurveyData();
        }

        this.alertService.updateIdsInEdit({ cmlIds: cmls.map((x) => x.id) });
      });

    this.stateService.state
      .pipe(
        map(() => this.stateService.getSelectedGridId(this.getGridContext())),
        filter((x) => x !== undefined),
        distinctUntilChanged(),
        takeUntil(this.destroy)
      )
      .subscribe((selectedId) => {
        const exists = this.cmlGrid?.data.find((x: CmlDto) => x.id === selectedId);

        //TODO Find better way to refresh grid when when new CML is added
        if (exists === undefined) {
          this.refreshData.next();
        }
      });

    combineLatest([
      this.uomEvaluator.getUnits('operatingTemperature'),
      this.uomEvaluator.getCurrentUnitSystem()
    ]).subscribe(([temp, currUnitSys]) => {
      this.temperatureUom = temp;
      this.currUnitSys = currUnitSys;
    });
  }

  private assignAccess(): Observable<any> {
    return this.permissionService
      .getPermissions({
        asset: [
          { feature: AssetFeature.Cml, assetId: this.inputData.equipmentKey! },
          { feature: AssetFeature.Activity, assetId: this.inputData.equipmentKey! }
        ],
        general: [{ feature: GeneralFeature.ScraRates }]
      })
      .pipe(
        tap((permissions) => {
          this.cmlPermissions = permissions.asset[AssetFeature.Cml];
          this.canEditActivity = permissions.asset[AssetFeature.Activity].edit;
          this.canEditThickness = this.cmlPermissions.edit;

          this.cmlGridOptions = this.getGridOptions(permissions.general[GeneralFeature.ScraRates]);
          this.loadingGridOptions = false;
        })
      );
  }

  private getGridContext(): string {
    return this.loadingGridOptions ? '' : this.cmlGridOptions.context;
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this.surveyService.newSurveyDate = undefined;
  }

  private setupSurveyData(): void {
    this.applyColumnVisibility();

    if (this.surveyService.newSurveyDate !== undefined) {
      this.selectedDate = this.surveyService.newSurveyDate;
      this.processViewModel(this.selectedDate);
    } else {
      this.selectedDate = LATEST;
      this.dateSelected();
    }

    this.gridDataHandler.setInitialData(this.cmlViewModel);
  }

  private showCorrectColumns(): void {
    if (this.equipmentType == AssetTypes.PipingSystem) {
      this.colsToKeep.push('size');
    } else {
      this.colsToKeep.push('innerDiameter');
      this.colsToKeep.push('outerDiameter');
    }
    this.setSizeColumnVisibilty();
  }

  private setSizeColumnVisibilty(): void {
    if (this.equipmentType == AssetTypes.PipingSystem) {
      this.cmlGrid!.api.setColumnsVisible(['innerDiameter', 'outerDiameter'], false);
    } else {
      this.cmlGrid!.api.setColumnsVisible(['size'], false);
    }
  }

  private applyColumnVisibility(): void {
    if (this.surveyService.newSurveyDate !== undefined) {
      const colsToHide =
        this.cmlGrid!.api.getColumnDefs()
          ?.filter((x) => 'field' in x)
          .filter((col: any) => !this.colsToKeep.includes(col.field))
          .map((col: any) => col.field) || [];

      this.cmlGrid!.api.setColumnsVisible(this.colsToKeep, true);
      this.cmlGrid!.api.setColumnsVisible(colsToHide, false);
    } else {
      this.setSizeColumnVisibilty();
      this.setCalcsColVisibility(this.selectedDate === LATEST);
    }
  }

  private beginEditOnSurveyDate(api: GridApi): void {
    api.getColumnDef('thickness')!.editable = true;

    // Set the focus to the first row thickness column.
    api.setFocusedCell(0, 'thickness');

    const params: StartEditingCellParams = {
      rowIndex: 0,
      colKey: 'thickness'
    };

    api.startEditingCell(params);
  }

  public onFirstDataRendered(event: FirstDataRenderedEvent): void {
    if (this.surveyService.newSurveyDate !== undefined) {
      this.beginEditOnSurveyDate(event.api);
      this.surveyService.newSurveyDate = undefined;
    }
  }

  private processViewModel(selectedDate: Date | string | undefined): void {
    if (!this.cmls) {
      return;
    }

    this.cmls.map((x) => {
      x.maximumAllowableWorkingPressure =
        x.maximumAllowableWorkingPressure !== undefined
          ? Number(Number(x.maximumAllowableWorkingPressure).toFixed(3))
          : undefined;

      x.longRate = x.longRate !== undefined ? Number(x.longRate).toFixed(4) : undefined;
      x.shortRate = x.shortRate !== undefined ? Number(x.shortRate).toFixed(4) : undefined;
      x.linearRate = x.linearRate !== undefined ? Number(x.linearRate).toFixed(4) : undefined;
    });

    if (selectedDate !== this.ALL) {
      this.cmlViewModel = this.cmls
        .map((x: CmlDto) => {
          const result = JSON.parse(JSON.stringify(x)) as typeof x as CmlViewModel;

          if (x.material) {
            if (x.materialGrade) {
              result.material = x.material + ' ' + x.materialGrade;
            } else {
              result.material = x.material;
            }
          } else {
            result.material = '';
          }

          if (x.scraResults) {
            result.scraLongRate = formatScraRate(x.scraResults.longRate, x.scraResults.longRateCode);
            result.scraShortRate = formatScraRate(x.scraResults.shortRate, x.scraResults.shortRateCode);
            result.scraLongRateStatistical = x.scraResults.longRateStatistical;
            result.scraShortRateStatistical = x.scraResults.shortRateStatistical;
          }

          if (selectedDate === LATEST) {
            const reading = this.getLatestReading(x.readings);

            if (reading) {
              result.readingId = reading.id;
              result.date = reading.date;
              result.thickness = reading.thickness;
              result.inspectionMethod = reading.inspectionMethod.toString();
              result.isBaseline = reading.isBaseline;
              result.origMeasThickness = reading.origMeasThickness ?? reading.thickness;
            }
          } else {
            if (x.readings.length !== 0) {
              const reading = x.readings.find((x) => x.date === selectedDate) as ReadingDto | undefined;

              if (reading) {
                result.readingId = reading.id;
                result.date = reading.date;
                result.thickness = reading.thickness;
                result.inspectionMethod = reading.inspectionMethod.toString();
                result.isBaseline = reading.isBaseline;
                result.excludeFromCalculation = reading.excludeFromCalculation;
                result.origMeasThickness = reading.origMeasThickness ?? reading.thickness;
              } else {
                result.readingId = undefined;
              }
            }
          }

          return result;
        })
        .sort((a: CmlViewModel, b: CmlViewModel) =>
          (a.name || '').localeCompare(b.name, 'en', { numeric: true, sensitivity: 'base' })
        );
    }

    // All CMLs and All readings
    if (selectedDate === this.ALL) {
      this.cmlViewModel = flattenArray(
        this.cmls.map((x: CmlDto) => {
          if (x.readings.length !== 0) {
            return x.readings
              .filter((y: ReadingDto) => y.thickness !== undefined)
              .map((z: ReadingDto) => {
                const result = JSON.parse(JSON.stringify(x)) as typeof x as CmlViewModel;

                if (x.material) {
                  if (x.materialGrade) {
                    result.material = x.material + ' ' + x.materialGrade;
                  } else {
                    result.material = x.material;
                  }
                } else {
                  result.material = '';
                }

                if (x.scraResults) {
                  result.scraLongRate = formatScraRate(x.scraResults.longRate, x.scraResults.longRateCode);
                  result.scraShortRate = formatScraRate(x.scraResults.shortRate, x.scraResults.shortRateCode);
                  result.scraLongRateStatistical = x.scraResults.longRateStatistical;
                  result.scraShortRateStatistical = x.scraResults.shortRateStatistical;
                }

                result.readingId = z.id;
                result.date = z.date;
                result.thickness = z.thickness;
                result.inspectionMethod = z.inspectionMethod.toString();
                result.isBaseline = z.isBaseline;
                result.excludeFromCalculation = z.excludeFromCalculation;
                result.origMeasThickness = z.origMeasThickness ?? z.thickness;

                return result;
              });
          } else {
            return [] as Array<CmlViewModel>;
          }
        })
      ).sort((a: CmlViewModel, b: CmlViewModel) =>
        (a.name || '').localeCompare(b.name, 'en', { numeric: true, sensitivity: 'base' })
      );
    }
  }

  private getLatestReading(readings: Array<ReadingDto>): ReadingDto | undefined {
    if (readings.length === 0) {
      return undefined;
    }

    const sortedReadings = readings.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
    return sortedReadings[0];
  }

  public dateSelected(): void {
    this.cmlGrid!.api.clearFocusedCell();

    this.surveyTempDataHandler.clear();
    this.processViewModel(this.selectedDate);
    this.setCalcsColVisibility(this.selectedDate === LATEST);

    if (this.isDateCurrentlySelected()) {
      if (DateOnlyUtility.isValid(this.selectedDate)) {
        const survey = this.getSurveyForSelectedDate();

        if (survey) {
          this.surveyTempDataHandler.setInitialData(survey!);
        }
      }
    }

    this.initialDataCopy = JSON.parse(JSON.stringify(this.cmlViewModel!));

    this.gridDataHandler.clearData();
    this.gridDataHandler.setInitialData(this.cmlViewModel!);

    this.updateHighlightedRows();
  }

  private setCalcsColVisibility(unfiltered: boolean): void {
    const cols = ['remainingLife', 'longRate', 'shortRate', 'linearRate', 'nextInspection', 'retirementDate'];
    this.cmlGrid!.api.setColumnsVisible(cols, unfiltered);

    const cols2 = ['material', 'date', 'Type', 'active', 'inspectionMethod'];
    this.cmlGrid!.api.setColumnsVisible(cols2, true);

    const cols3 = ['excludeFromCalculation'];
    this.cmlGrid!.api.setColumnsVisible(cols3, !unfiltered);

    this.setThicknessColVis(unfiltered);
    this.setScraColumnVisibility(unfiltered);
  }

  private setThicknessColVis(show: boolean): void {
    if (this.hasSurveyTempCorrections) {
      this.cmlGrid!.api.setColumnsVisible(['origMeasThickness', 'thicknessCorrected'], !show);
      this.cmlGrid!.api.setColumnsVisible(['thickness'], show);
    } else {
      //Default condition
      this.cmlGrid!.api.setColumnsVisible(['thickness'], true);
      this.cmlGrid!.api.setColumnsVisible(['origMeasThickness', 'thicknessCorrected'], false);
    }
  }

  private setScraColumnVisibility(show: boolean): void {
    const cols = ['scraLongRate', 'scraShortRate', 'scraLongRateStatistical', 'scraShortRateStatistical'];
    this.cmlGrid!.api.setColumnsVisible(cols, show);
  }

  private onRangeSelectionChanged(event: RangeSelectionChangedEvent): void {
    //Forces the thickness column to be the only column to allow range selection
    const cellRanges = event.api.getCellRanges();

    if (!cellRanges || cellRanges.length === 0 || cellRanges[0].columns.length == 0) {
      return;
    }

    const cellRange = cellRanges[0];
    const columnsToInclude = cellRange.columns.filter((col) => col.getColId() == 'thicknessCorrected');

    if (cellRange.columns.length > columnsToInclude.length) {
      event.api.clearRangeSelection();

      if (columnsToInclude.length > 0) {
        const rangeParams = {
          rowStartIndex: cellRange.startRow!.rowIndex,
          rowStartPinned: cellRange.startRow!.rowPinned,
          rowEndIndex: cellRange.endRow!.rowIndex,
          rowEndPinned: cellRange.endRow!.rowPinned,
          columns: columnsToInclude
        };
        event.api.addCellRange(rangeParams);
      }
    }
  }

  public onCellClicked(event: CellClickedEvent): void {
    if (event.colDef.field === 'thickness' && this.canEditThickness) {
      if (this.isDateCurrentlySelected()) {
        event.colDef.editable = true;
      } else {
        event.colDef.editable = false;
        const message =
          this.selectedDate === LATEST
            ? 'Add/Edit thickness is not possible when "Latest" is selected.'
            : 'Add/Edit Thickness is not available when "All" is selected.';

        this.notificationService.showInfo(message);
      }
    }
  }

  public isDirty(): boolean {
    return this.gridDataHandler.dirty || this.surveyTempDataHandler.dirty;
  }

  public updateThicknessFromTemp(): void {
    if (this.surveyTempDataHandler.data.temperatureCorrection) {
      this.gridDataHandler.data?.forEach((cml) => {
        if (!cml.origMeasThickness) {
          cml.origMeasThickness = cml.thickness;
        }

        if (cml.thickness) {
          if (this.currUnitSys == UnitOfMeasure.Metric) {
            const tempInDegF = this.surveyTempDataHandler.data.temperatureCorrection! * (9 / 5) + 32;

            cml.thickness = this.applyTempFormula(cml.origMeasThickness!, tempInDegF);
          } else {
            cml.thickness = this.applyTempFormula(
              cml.origMeasThickness!,
              this.surveyTempDataHandler.data.temperatureCorrection!
            );
          }
        }
      });

      const survey = this.getSurveyForSelectedDate();
      this.gridDataHandler.getChangedData()?.forEach((x: CmlViewModel) => {
        const result = x?.readings!.find((x: ReadingDto) => x.surveyId == survey?.surveyId);

        if (result) {
          result.thickness = x.thickness === undefined ? 0 : x.thickness;
          result.origMeasThickness = x.origMeasThickness;
          result.excludeFromCalculation = x.excludeFromCalculation;
          result.isBaseline = x.isBaseline;
        }
      });

      this.cmlGrid!.api!.refreshCells();
    }
  }

  private applyTempFormula(ogThickness: number, temperatureCorrection: number): number {
    return ogThickness * (1.007 - 0.0001 * temperatureCorrection!);
  }

  private getSurveyForSelectedDate(): SurveyDto | undefined {
    if (this.isDateCurrentlySelected() && this.surveys) {
      const survey = this.surveys.find((x) => {
        return x.date == this.selectedDate;
      });

      return survey;
    }

    return undefined;
  }

  public saveChanges(): Observable<boolean> {
    this.saving = true;

    let rowsToUpdate: Array<CmlDto> = this.gridDataHandler
      .getChangedData()
      .filter((x: any) => x.updateType === UpdateType.Update) as Array<CmlDto>;

    const selectedSurvey = this.getSurveyForSelectedDate();

    rowsToUpdate.map((x) => {
      const initCml = this.cmls!.find((y) => y.id == x.id)!;

      x.updateCml = !areObjectsEqual(this.getTestCml(x), this.getTestCml(initCml));
      x.updateReading = !areObjectsEqual(x.readings, initCml.readings);
    });

    if (selectedSurvey) {
      rowsToUpdate = rowsToUpdate.map((x) => ({
        ...x,
        readings: x.readings.map((y) => ({
          ...y,
          surveyId: y.surveyId || selectedSurvey.id!,
          origMeasThickness: this.hasSurveyTempCorrections ? y.origMeasThickness : y.thickness
        }))
      }));
    }

    return forkJoin([this.cmlService.updateCmls(rowsToUpdate), this.possiblyUpdateSurvey()]).pipe(
      map(([res1, res2]) => res1 && res2),
      tap((success) => this.showResultAndRefresh(success, ActionTypes.Update, DialogType.CMLs))
    );
  }

  public revertChanges(): void {
    this.gridDataHandler.revertChanges();
    this.surveyTempDataHandler.revertChanges();
    this.cmlGrid!.api.refreshHeader();
  }

  public delete(index: number): void {
    this.gridDataHandler.delete(index);
  }

  public dataChanged(event: CellValueChangedEvent): void {
    if (
      event.type === 'cellValueChanged' &&
      ['thickness', 'excludeFromCalculation', 'isBaseline'].includes(event.colDef.field ?? '')
    ) {
      if (event.node.data?.date === undefined && event.node.data.thickness != 0) {
        // If there is no survey date we need to add the currently selected one.
        event.node.data.date = this.selectedDate;

        const survey = this.surveys?.find(
          (x) => new Date(x.date!).getTime() === new Date(this.selectedDate!).getTime()
        );

        // Add a new entry to readings for the new date.
        event.node.data.readings.push({
          id: undefined,
          thickness: event.node.data.thickness,
          minimumThickness: 0.0,
          inspectionMethod: survey?.primaryInspectionMethod,
          isBaseline: survey?.isBaseline,
          surveyId: survey?.surveyId,
          date: JSON.parse(JSON.stringify(this.selectedDate)),
          excludeFromCalculation: false
        } as ReadingDto);

        this.cmlViewModel.find((x) => x.id === event.node.data.id)!.readingId = undefined;
        this.cmlViewModel.find((x) => x.id === event.node.data.id)!.date = this.selectedDate;
        this.cmlGrid!.api.refreshCells();
      } else {
        const result = event.node.data?.readings!.find((x: ReadingDto) => x.id === event.node.data.readingId);
        if (result) {
          result.thickness = event.node.data.thickness === 0 ? undefined : event.node.data.thickness;
          result.excludeFromCalculation = event.node.data.excludeFromCalculation;
          result.isBaseline = event.node.data.isBaseline;
        }
      }
    }

    this.gridDataHandler.dataChanged();
    this.cmlGrid?.api?.refreshCells();
  }

  private possiblyUpdateSurvey(): Observable<boolean> {
    return this.surveyTempDataHandler.dirty
      ? this.surveyService.updateSurvey(this.surveyTempDataHandler.data)
      : of(true);
  }

  public addCml(): void {
    this.openCml(NEW_ITEM_KEY);
  }

  private openCml(id: string): void {
    const optionsData = this.cmlGrid!.convertNodesToSlideoutOptions<CmlViewModel, CmlSlideoutOption>((data) => {
      return {
        name: data.name,
        assetId: this.inputData.equipmentKey,
        componentId: data.componentId,
        circuitId: data.circuitId
      };
    });

    const data: CmlSlideoutInput = {
      assetId: this.inputData.equipmentKey,
      id: id,
      gridContext: this.cmlGrid?.gridOptions.context,
      breadcrumbs: this.breadcrumbs,
      optionsData: optionsData
    };

    this.slideOutService
      .open<boolean, CmlSlideoutComponent>(CmlSlideoutComponent, {
        data
      })
      .closed.pipe(take(1))
      .subscribe(() => this.refreshData.next());
  }

  private setInputData(params: Params): void {
    this.inputData = {
      equipmentKey: params.equipmentKey,
      unitKey: params.unitKey
    };
  }

  public compareSelectDateFn(date1: Date | string, date2: Date | string): boolean {
    return date1 instanceof Date && date2 instanceof Date
      ? new Date(date1).getTime() === new Date(date2).getTime()
      : date1 === date2;
  }

  public clearAllReadingsData(): void {
    this.allReadingsData = undefined;
  }

  private showAllReadings(params: ICellRendererParams): void {
    this.allReadingsData = {
      name: params.data.name,
      readings: [...params.data.readings]
    };
  }

  private canDeleteReading(id: string): boolean {
    const readIdInCml = this.cmlViewModel.find((x) => x.id === id)?.readingId;
    return this.cmlPermissions.edit && this.isDateCurrentlySelected() && readIdInCml !== undefined;
  }

  private deleteReading(id: string, cmlName: string): void {
    this.dialogService
      .displayDelete({ dialogType: DialogType.Reading, name: `${cmlName} ${DialogType.Reading}` })
      .pipe(switchMap(() => this.readingService.deleteReading(id)))
      .subscribe((success: boolean) => this.showResultAndRefresh(success, ActionTypes.Delete, cmlName));
  }

  private deleteCml(id: string, name: string): void {
    this.dialogService
      .displayDelete({ dialogType: DialogType.CML, name })
      .pipe(switchMap(() => this.cmlService.deleteCml(id)))
      .subscribe((success: boolean) => this.showResultAndRefresh(success, ActionTypes.Delete, name));
  }

  private renameCml(id: string, originalName: string, componentId: string, circuitId: string): void {
    const dialogData = this.buildDialogData(originalName, componentId, circuitId);
    this.dialogService
      .displayRename(dialogData)
      .pipe(switchMap((newName) => this.cmlService.renameCml(id, newName)))
      .subscribe((success) => this.showResultAndRefresh(success, ActionTypes.Rename, originalName));
  }

  private buildDialogData(name: string, componentId: string, circuitId: string): NameDialogData {
    return {
      invalidNames:
        this.cmls === undefined
          ? []
          : this.cmls
              .filter((cml) => cml.componentId == componentId && cml.circuitId == circuitId)
              .map((cml) => cml.name),
      name: name,
      dialogType: DialogType.CML
    };
  }

  public processCellForClipboard(params: ProcessCellForExportParams): any | undefined {
    const colDef = params.column.getColDef();
    if (colDef.valueFormatter && typeof colDef.valueFormatter !== 'string') {
      return colDef.valueFormatter({
        ...params,
        data: params.node?.data,
        colDef: colDef
      } as ValueFormatterParams);
    } else if (colDef.cellRenderer && colDef.cellRenderer.name === 'GridCellScraRateComponent') {
      const scraRate = params.value as ScraRateData | undefined;
      if (scraRate) {
        return `${scraRate.startChar === undefined ? '' : scraRate.startChar}${scraRate.rate} ${scraRate.code}`;
      }
      return '';
    }

    return params.value;
  }

  public processCellFromClipboard(params: ProcessCellForExportParams): any | undefined {
    const data: any | undefined = params.value;

    if (data.length === 0 || data === '') {
      return params.node?.data.thickness;
    }

    if (Number.isNaN(Number(data)) || Number(data) <= 0) {
      return undefined;
    }

    return data;
  }

  private copyCml(id: string, originalName: string, componentId: string, circuitId: string): void {
    const dialogData = this.buildDialogData(originalName, componentId, circuitId);
    this.dialogService
      .displayCopy(dialogData)
      .pipe(switchMap((newName) => this.cmlService.copyCml(id, newName)))
      .subscribe((newId?: string) => {
        const success = newId !== undefined;
        this.showResultAndRefresh(success, ActionTypes.Copy, originalName);
        if (success) {
          this.stateService.setSelectedGridId(this.getGridContext(), newId); //TODO fix select after refresh. Doesn't seem to work
        }
      });
  }

  private showResultAndRefresh(success: boolean, actionType: ActionTypes, itemName: string): void {
    this.notificationService.showActionResult(success, actionType, itemName);
    if (success) {
      this.refreshData.next();
    }
    this.saving = false;
  }

  private getTestCml(cml: CmlDto): any {
    return {
      active: cml.active,
      injectionPoint: cml.injectionPoint,
      soilToAir: cml.soilToAir
    };
  }

  public onChangeHighlightDrivingCml(): void {
    this.userService.setHighlightDrivingCml(this.bHighlightDrivingCml);

    this.updateHighlightedRows();
  }

  private updateHighlightedRows(): void {
    this.lowestRetirementDate = this.getLowestRetirementDate();
    setTimeout(() => {
      this.cmlGrid?.redrawRows();
    });
  }

  private getLowestRetirementDate(): string | undefined {
    const data = (this.gridDataHandler.data ?? []).filter((x) => x.retirementDate !== undefined);
    return data.length > 0
      ? data.reduce(function (prev, current) {
          return DateOnlyUtility.compare(prev.retirementDate, current.retirementDate) <= 0 ? prev : current;
        }).retirementDate
      : undefined;
  }

  private getGridOptions(hasScra: boolean): GridBaseOptions {
    return {
      exportFileName: 'CMLs',
      context: 'cmls',
      enterNavigatesVertically: true,
      enterNavigatesVerticallyAfterEdit: true,
      suppressClipboardPaste: false,
      enableRangeSelection: true,
      rowSelection: 'multiple',
      alwaysShowHorizontalScroll: true, //Accounts for ag-grid bug where horizontalscroll bar does not appear when changing the columns programatically
      getRowId: (params) =>
        params.data.readingId === undefined ? params.data.id : params.data.id + '_' + params.data.readingId,
      getSelectRowId: (node: IRowNode) => node.data.id,
      onFirstDataRendered: this.onFirstDataRendered.bind(this),
      onRowDataUpdated: this.dataChanged.bind(this).bind(this),
      onCellValueChanged: this.dataChanged.bind(this),
      onCellClicked: this.onCellClicked.bind(this),
      processCellFromClipboard: this.processCellFromClipboard.bind(this),
      processCellForClipboard: this.processCellForClipboard.bind(this),
      onRangeSelectionChanged: this.onRangeSelectionChanged.bind(this),
      rowClassRules: rowDrivingCmlRule(
        () => this.selectedDate,
        () => this.lowestRetirementDate,
        () => this.bHighlightDrivingCml
      ),
      defaultColDef: { ...buildDefaultColDef(), suppressPaste: true, minWidth: 125 },
      columnDefs: [
        { headerName: 'CmlId', field: 'id', hide: true },
        { headerName: 'ComponentId', field: 'componentId', hide: true },
        {
          headerName: 'CML',
          headerTooltip: 'CML',
          field: 'name',
          tooltipField: 'name',
          comparator: (a, b): number => a.localeCompare(b, 'en', { numeric: true, sensitivity: 'base' }),
          cellRenderer: GridCellLinkComponent,
          cellRendererParams: {
            isLink: (): boolean => true,
            linkCommand: (params: ICellRendererParams): void => this.openCml(params.data.id)
          } as GridCellLinkParams
        },
        {
          headerName: 'Actions',
          headerTooltip: 'Actions',
          width: 112,
          cellStyle: { textAlign: 'center' },
          headerClass: 'ag-header-center',
          cellRenderer: GridCellMenuComponent,
          cellRendererParams: {
            menuItems: this.menuDefinition,
            isLink: (): boolean => this.canEditThickness,
            linkCommand: (params: ICellRendererParams): void => this.openCml(params.data.id)
          },
          filter: false
        },
        ...getBaseCmlColumns(
          columnCellThicknessRule(
            () => this.isDateCurrentlySelected(),
            this.cmlPermissions.edit,
            () => this.selectedDate
          ),
          () => !this.cmlPermissions.edit,
          hasScra,
          () => !this.canEditReadingProperties()
        )
      ]
    };
  }

  private canEditReadingProperties(): boolean {
    return this.isDateCurrentlySelected() && this.cmlPermissions.edit;
  }

  private isDateCurrentlySelected(): boolean {
    return this.selectedDate !== LATEST && this.selectedDate !== this.ALL;
  }
}
