import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { E2gNgUiModule } from '@equityeng/e2g-ng-ui';
import { NextInspectionSelectorComponent } from './next-inspection-selector/next-inspection-selector.component';

@NgModule({
  declarations: [NextInspectionSelectorComponent],
  imports: [CommonModule, FormsModule, E2gNgUiModule, MatRadioModule],
  exports: [NextInspectionSelectorComponent]
})
export class InspectionPlanningModule {}
