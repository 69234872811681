import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { DmlDetailSummaryBaseComponent } from 'src/app/dmls-module/dml-slideout/dml-detail-summary-base-component';
import { DmlTypes } from 'src/app/dmls-module/models/Enums/dml-types';

import { DmlCrackingOutputDto } from '../../models/dml-cracking-output-dto';

@Component({
  selector: 'app-dml-cracking-summary',
  templateUrl: './dml-cracking-summary.component.html',
  styleUrls: ['./dml-cracking-summary.component.css']
})
export class DmlCrackingSummaryComponent extends DmlDetailSummaryBaseComponent<DmlCrackingOutputDto> {
  protected dmlType = DmlTypes['Cracking'];

  public primaryReferenceStressUom: Observable<string> = this.uomEvaluator.getUnits(
    'DmlCracking_PrimaryReferenceStress'
  );
  public yieldStrengthUom: Observable<string> = this.uomEvaluator.getUnits('DmlCracking_YieldStrength');
  public primaryLoadRatioUom: Observable<string> = this.uomEvaluator.getUnits('DmlCracking_PrimaryLoadRatio');
  public totalCrackTipSifPhiZeroUom: Observable<string> = this.uomEvaluator.getUnits(
    'DmlCracking_TotalCrackTipSifPhiZero'
  );
  public totalCrackTipSifPhiNinetyUom: Observable<string> = this.uomEvaluator.getUnits(
    'DmlCracking_TotalCrackTipSifPhiNinety'
  );
  public materialToughnessUom: Observable<string> = this.uomEvaluator.getUnits('DmlCracking_MaterialToughness');
  public maximumToughnessRatioUom: Observable<string> = this.uomEvaluator.getUnits('DmlCracking_MaximumToughnessRatio');
  public allowableLoadRatioUom: Observable<string> = this.uomEvaluator.getUnits('DmlCracking_AllowableLoadRatio');
  public allowableToughnessRatioUom: Observable<string> = this.uomEvaluator.getUnits(
    'DmlCracking_AllowableToughnessRatio'
  );
  public plasticCollapseMarginUom: Observable<string> = this.uomEvaluator.getUnits('DmlCracking_PlasticCollapseMargin');
  public brittleFractureMarginUom: Observable<string> = this.uomEvaluator.getUnits('DmlCracking_BrittleFractureMargin');
  public maximumCrackDepthUom: Observable<string> = this.uomEvaluator.getUnits('DmlCracking_MaximumCrackDepth');
  public maximumCrackLengthUom: Observable<string> = this.uomEvaluator.getUnits('DmlCracking_MaximumCrackLength');
  public crackDepthMarginUom: Observable<string> = this.uomEvaluator.getUnits('DmlCracking_CrackDepthMargin');
  public crackLengthMarginUom: Observable<string> = this.uomEvaluator.getUnits('DmlCracking_CrackLengthMargin');

  public constructor() {
    super();
  }

  protected displaySummary(output: DmlCrackingOutputDto): void {
    if (output.crackSensitivityMessages !== undefined) {
      output.crackSensitivityMessages = this.formatSummarySection(output.crackSensitivityMessages);
    }

    if (output.crackLikeFlawApplicabilityAndLimitationsMessages !== undefined) {
      output.crackLikeFlawApplicabilityAndLimitationsMessages = this.formatSummarySection(
        output.crackLikeFlawApplicabilityAndLimitationsMessages
      );
    }
  }
}
