import { CdkDragEnter, CdkDragMove, CdkDropList, DragRef, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { faUpload } from '@fortawesome/pro-regular-svg-icons';
import fileSaver from 'file-saver';
import { from, Observable, Subject } from 'rxjs';
import { startWith, switchMap, take, takeUntil } from 'rxjs/operators';
import { ActionTypes } from 'src/app/shared-module/models/action-types';
import { DialogType } from 'src/app/shared-module/models/name-dialog-data';
import { NotificationService } from 'src/app/shared-module/services/notification.service';

import { OnDestroyBaseComponent } from '../../on-destroy-base-component/on-destroy-base-component';
import { DialogService } from '../../shared-module/dialog.service';
import { DialogButtons } from '../../shared-module/models/dialog-buttons';
import { DialogData } from '../../shared-module/models/dialog-data';
import { AttachmentDataService } from '../attachment-data-service';
import { AttachmentUploadDialogComponent } from '../attachment-upload-dialog/attachment-upload-dialog.component';
import { AttachmentDto } from '../models/attachment-dto';
import { AttachmentFileType } from '../models/attachment-file-type';
import { AttachmentReorderDto } from '../models/attachment-reorder-data';
import { AttachmentTableType } from '../models/attachment-table-type';

@Component({
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.css']
})
export class AttachmentsComponent extends OnDestroyBaseComponent implements OnInit {
  @Input() public tableType!: AttachmentTableType;
  @Input() public attachmentKey!: string;
  @Input() public readonly: boolean = false;
  @Output() public previewMode = new EventEmitter<boolean>();
  @ViewChild(CdkDropList) public placeholder!: CdkDropList;

  public previewDataReader?: (data: AttachmentDto) => Observable<Blob>;
  private uploadData?: () => Observable<{ token: string; uploadUrl: string }>;

  public containerElement!: HTMLElement;

  public target?: CdkDropList;
  public targetIndex?: number;
  public source?: CdkDropList;
  public sourceIndex?: number;
  private dragRef?: DragRef;

  public attachments!: Array<AttachmentDto>;

  private phWidth = 200;
  private phHeight = 200;

  public faUpload = faUpload;
  public fileTypePdf = AttachmentFileType.PDF;
  public fileTypeImage = AttachmentFileType.IMAGE;
  public fileTypeText = AttachmentFileType.TEXT;

  public previewData?: AttachmentDto;
  public previewText: string = '';
  private isAfterClosingPreview: boolean = false;

  //Used to make attachments in last row the same size
  public extraColumns: Array<number> = Array(5);

  private refreshAttachments = new Subject<void>();

  public constructor(
    private dialogService: DialogService,
    private dataService: AttachmentDataService,
    private notificationService: NotificationService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.previewDataReader = (data: AttachmentDto): Observable<Blob> =>
      this.dataService.getAttachmentPreview(this.tableType!, data.id, data.fileId);

    this.getAttachments()
      .pipe(takeUntil(this.destroy))
      .subscribe((x) => {
        this.attachments = x;
        setTimeout(() => this.init());
      });

    this.previewMode.next(false);
  }

  private init(): void {
    this.containerElement = this.placeholder.element.nativeElement;
    const phElement = this.placeholder.element.nativeElement;

    phElement.style.display = 'none';
    if (phElement.parentElement) {
      phElement.parentElement.removeChild(phElement);
    }
  }

  public dragMoved(e: CdkDragMove): void {
    this.phWidth = e.source.element.nativeElement.offsetWidth;
    this.phHeight = e.source.element.nativeElement.offsetHeight;
  }

  public onDropListDropped(): void {
    if (!this.target) {
      return;
    }
    const placeholderElement: HTMLElement = this.placeholder.element.nativeElement;
    const placeholderParentElement: HTMLElement = placeholderElement.parentElement!;

    placeholderElement.style.display = 'none';

    placeholderParentElement.removeChild(placeholderElement);
    placeholderParentElement.appendChild(placeholderElement);
    placeholderParentElement.insertBefore(
      this.source!.element.nativeElement,
      placeholderParentElement.children[this.sourceIndex!]
    );

    if (this.placeholder._dropListRef.isDragging()) {
      this.placeholder._dropListRef.exit(this.dragRef!);
    }

    this.target = undefined;
    this.source = undefined;
    this.dragRef = undefined;

    if (this.isAfterClosingPreview) {
      //After closing the preview window an extra div at the start of the list exists until you reorder attachments
      this.isAfterClosingPreview = false;
      this.sourceIndex! -= 1;
      this.targetIndex = this.targetIndex == 0 ? 0 : this.targetIndex! - 1;
    }

    if (this.sourceIndex !== this.targetIndex) {
      this.updateOrder();
    }
  }

  public onDropListEntered({ item, container }: CdkDragEnter): void {
    if (container === this.placeholder) {
      return;
    }

    const placeholderElement: HTMLElement = this.placeholder.element.nativeElement;
    const sourceElement: HTMLElement = item.dropContainer.element.nativeElement;
    const dropElement: HTMLElement = container.element.nativeElement;
    const dragIndex: number = Array.prototype.indexOf.call(
      dropElement.parentElement!.children,
      this.source ? placeholderElement : sourceElement
    );
    const dropIndex: number = Array.prototype.indexOf.call(dropElement.parentElement!.children, dropElement);

    if (!this.source) {
      this.sourceIndex = dragIndex;
      this.source = item.dropContainer;

      placeholderElement.style.width = `${this.phWidth}px`;
      placeholderElement.style.height = `${this.phHeight}px`;

      sourceElement.parentElement!.removeChild(sourceElement);
    }

    this.targetIndex = dropIndex;
    this.target = container;
    this.dragRef = item._dragRef;

    placeholderElement.style.display = '';

    dropElement.parentElement!.insertBefore(
      placeholderElement,
      dropIndex > dragIndex ? dropElement.nextSibling : dropElement
    );

    this.placeholder._dropListRef.enter(
      item._dragRef,
      item.element.nativeElement.offsetLeft,
      item.element.nativeElement.offsetTop
    );
  }

  public clickUpload(): void {
    const componentData = {
      tableType: this.tableType,
      attachmentKey: this.attachmentKey,
      getUploadData: this.uploadData,
      uploadComplete: this.uploadDatComplete
    };

    const dialogData: DialogData = {
      component: AttachmentUploadDialogComponent,
      componentData: componentData,
      buttons: DialogButtons.Yes,
      yesButtonText: 'Close',
      width: '530px',
      height: '635px'
    };

    this.dialogService.display(dialogData).pipe(take(1)).subscribe();
  }

  private uploadDatComplete = (success: boolean): void => {
    if (success) {
      this.refreshAttachments.next();
    }
  };

  public delete(data: AttachmentDto): void {
    this.dialogService
      .displayDelete({ dialogType: DialogType.Attachment, name: data.fileName })
      .pipe(switchMap(() => this.dataService.deleteAttachment(this.tableType!, this.attachmentKey!, data.fileId)))
      .subscribe((success: boolean) => {
        this.notificationService.showActionResult(success, ActionTypes.Delete, data.fileName);
        if (success) {
          this.refreshAttachments.next();
        }
      });
  }

  public preview(data: AttachmentDto): void {
    this.previewMode.next(true);
    this.previewData = data;

    if (data.fileType === AttachmentFileType.TEXT) {
      this.getPreviewAttachmentFile()
        .pipe(
          switchMap((blob) => from(blob.text())),
          takeUntil(this.destroy)
        )
        .subscribe((x) => (this.previewText = x));
    }
  }

  public download(data: AttachmentDto): void {
    this.getAttachmentFile(data).subscribe((resp) => {
      fileSaver.saveAs(URL.createObjectURL(resp), data.fileName);
    });
  }

  public closePreview(): void {
    this.previewMode.next(false);
    this.isAfterClosingPreview = true;
    this.previewData = undefined;
  }

  public getPreviewAttachmentFile = (): Observable<Blob> => {
    return this.getAttachmentFile(this.previewData!);
  };

  private updateOrder(): void {
    moveItemInArray(this.attachments, this.sourceIndex!, this.targetIndex!);

    let i = 0;
    this.attachments.forEach((x) => (x.sortOrder = i++));

    this.dataService
      .updateAttachmentsSortOrder(this.tableType!, this.attachmentKey!, this.getOrderObject())
      .subscribe();
  }

  private getOrderObject(): Array<AttachmentReorderDto> {
    return this.attachments.reduce((p, c) => {
      p.push({ attachmentId: c.id, sortOrder: c.sortOrder });
      return p;
    }, new Array<AttachmentReorderDto>());
  }
  private getAttachments(): Observable<Array<AttachmentDto>> {
    return this.refreshAttachments.pipe(
      startWith(null),
      switchMap(() => this.dataService.getAttachments(this.tableType!, this.attachmentKey!))
    );
  }

  private getAttachmentFile(data: AttachmentDto): Observable<Blob> {
    return this.dataService.getAttachment(this.tableType!, data.id, data.fileId);
  }
}
