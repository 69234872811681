<div *ngIf="!loading; else load">
  <div class="row mb-4 mt-2">
    <div class="col-12">
      <div class="card h-100">
        <h4 class="card-header mb-0">Geometric Limitations (Including FCA)</h4>
        <div class="card-body">
          <div class="row">
            <div class="col-6">
              <div class="d-flex align-items-middle">
                <div class="sage-label">Hoop Average Thickness Less FCA :</div>
                <div
                  id="hoopAverageThicknessLessFca"
                  class="sage-value"
                  [innerHTML]="toFixedFourFormater(dataHandler.data.hoopAverageThicknessLessFca!)"
                ></div>
                <div class="sage-units">{{ hoopAverageThicknessLessFcaUom | async }}</div>
              </div>
              <div class="d-flex align-items-middle">
                <div class="sage-label">Axial Average Thickness Less FCA :</div>
                <div
                  id="axialAverageThicknessLessFca"
                  class="sage-value"
                  [innerHTML]="toFixedFourFormater(dataHandler.data.axialAverageThicknessLessFca!)"
                ></div>
                <div class="sage-units">{{ axialAverageThicknessLessFcaUom | async }}</div>
              </div>
              <div class="d-flex align-items-middle">
                <div class="sage-label">Corroded Minimum Measured Thickness :</div>
                <div
                  id="corrodedMinimumMeasuredThickness"
                  class="sage-value"
                  [innerHTML]="toFixedFourFormater(dataHandler.data.corrodedMinimumMeasuredThickness!)"
                ></div>
                <div class="sage-units">{{ corrodedMinimumMeasuredThicknessUom | async }}</div>
              </div>
              <div class="d-flex align-items-middle">
                <div class="sage-label">Structural Minimum Thickness :</div>
                <div
                  id="structuralMinimumThickness"
                  class="sage-value"
                  [innerHTML]="toFixedFourFormater(dataHandler.data.structuralMinimumThickness!)"
                ></div>
                <div class="sage-units">{{ structuralMinimumThicknessUom | async }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-4">
    <div class="col-6">
      <div class="card h-100">
        <h4 class="card-header mb-0">Damaged MAWP (Including FCA)</h4>
        <div class="card-body">
          <div class="d-flex align-items-middle">
            <div class="sage-label">Design Pressure :</div>
            <div
              id="designPressure"
              class="sage-value"
              [innerHTML]="toFixedFourFormater(dataHandler.data.designPressure!)"
            ></div>
            <div class="sage-units">{{ designPressureUom | async }}</div>
          </div>
          <div class="d-flex align-items-middle">
            <div class="sage-label">Undamaged MAWP :</div>
            <div
              id="undamagedMawp"
              class="sage-value"
              [innerHTML]="toFixedFourFormater(dataHandler.data.undamagedMawp!)"
            ></div>
            <div class="sage-units">{{ undamagedMawpUom | async }}</div>
          </div>
          <div class="d-flex align-items-middle">
            <div class="sage-label">Average Thickness MAWP :</div>
            <div
              id="averageThicknessMawp"
              class="sage-value"
              [innerHTML]="toFixedFourFormater(dataHandler.data.averageThicknessMawp!)"
            ></div>
            <div class="sage-units">{{ averageThicknessMawpUom | async }}</div>
          </div>
          <div class="d-flex align-items-middle">
            <div class="sage-label">Minimum Thickness MAWP :</div>
            <div
              id="minimumThicknessMawp"
              class="sage-value"
              [innerHTML]="toFixedFourFormater(dataHandler.data.minimumThicknessMawp!)"
            ></div>
            <div class="sage-units">{{ minimumThicknessMawpUom | async }}</div>
          </div>
          <div class="d-flex align-items-middle">
            <div class="sage-label">Damaged MAWP :</div>
            <div
              id="damagedMawp"
              class="sage-value"
              [innerHTML]="toFixedFourFormater(dataHandler.data.damagedMawp!)"
            ></div>
            <div class="sage-units">{{ damagedMawpUom | async }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-6">
      <div class="card h-100">
        <h4 class="card-header mb-0">Damaged MAWP Messages</h4>
        <div class="card-body">
          <ng-container *ngFor="let msg of dataHandler.data.damagedMawpMessages">
            <p [innerHTML]="parseMarkdown(msg)"></p>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-4">
    <div class="col-6">
      <div class="card h-100">
        <h4 class="card-header mb-0">Remaining Life (From Current Thickness)</h4>
        <div class="card-body">
          <div class="d-flex align-items-middle">
            <div class="sage-label">Undamaged Region Maximum FCA :</div>
            <div
              id="undamagedRegionMaximumFca"
              class="sage-value"
              [innerHTML]="toFixedFourFormater(dataHandler.data.undamagedRegionMaximumFca!)"
            ></div>
            <div class="sage-units">{{ undamagedRegionMaximumFcaUom | async }}</div>
          </div>
          <div class="d-flex align-items-middle">
            <div class="sage-label">Damaged Region Maximum FCA :</div>
            <div
              id="damagedRegionMaximumFca"
              class="sage-value"
              [innerHTML]="toFixedFourFormater(dataHandler.data.damagedRegionMaximumFca!)"
            ></div>
            <div class="sage-units">{{ damagedRegionMaximumFcaUom | async }}</div>
          </div>
          <div class="d-flex align-items-middle">
            <div class="sage-label">Damage Region Minimum Thickness :</div>
            <div
              id="damageRegionMinimumThickness"
              class="sage-value"
              [innerHTML]="toFixedFourFormater(dataHandler.data.damageRegionMinimumThickness!)"
            ></div>
            <div class="sage-units">{{ damageRegionMinimumThicknessUom | async }}</div>
          </div>
          <div class="d-flex align-items-middle">
            <div class="sage-label">Remaining Life :</div>
            <div
              id="remainingLife"
              class="sage-value"
              [innerHTML]="toFixedFourFormater(dataHandler.data.remainingLife!)"
            ></div>
            <div class="sage-units">{{ remainingLifeUom | async }}</div>
          </div>
          <div class="d-flex align-items-middle">
            <div class="sage-label">Minimum Inspection Interval :</div>
            <div
              id="minimumInspectionInterval"
              class="sage-value"
              [innerHTML]="toFixedFourFormater(dataHandler.data.minimumInspectionInterval!)"
            ></div>
            <div class="sage-units">{{ minimumInspectionIntervalUom | async }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-6">
      <div class="card h-100">
        <h4 class="card-header mb-0">Remaining Life Messages</h4>
        <div class="card-body">
          <ng-container *ngFor="let msg of dataHandler.data.remainingLifeMessages">
            <p [innerHTML]="parseMarkdown(msg)"></p>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-4">
    <div class="col-12">
      <div class="card h-100">
        <h4 class="card-header mb-0">General Metal Loss Applicability and Limitations</h4>
        <div class="card-body">
          <ul class="mb-0 p-0 pl-3">
            <li *ngFor="let item of dataHandler.data.applicabilityAndLimitationsMessages!">{{ item }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #load>
  <div class="loading"></div>
</ng-template>
