import { Component, OnInit } from '@angular/core';
import { forkJoin, of, switchMap, take, takeUntil } from 'rxjs';
import { OnDestroyBaseComponent } from 'src/app/on-destroy-base-component/on-destroy-base-component';

import { DialogService } from '../../shared-module/dialog.service';
import { DialogButtons } from '../../shared-module/models/dialog-buttons';
import { DialogResult } from '../../shared-module/models/dialog-result';
import { SlideOutContainerAction } from '../models/constants/slide-out-container-action';
import { SlideOutContainerService } from '../slide-out-container.service';

@Component({
  selector: 'app-slide-out-container',
  templateUrl: './slide-out-container.component.html',
  styleUrls: ['./slide-out-container.component.css']
})
export class SlideOutContainerComponent extends OnDestroyBaseComponent implements OnInit {
  public hasBreadcrumbs: boolean = false;
  public constructor(private slideOutService: SlideOutContainerService, private dialogService: DialogService) {
    super();
  }

  public ngOnInit(): void {
    this.slideOutService.breadcrumbs.pipe(takeUntil(this.destroy)).subscribe((breadcrumbs) => {
      this.hasBreadcrumbs = breadcrumbs.length > 0;
    });
  }

  public triggerAction(action: SlideOutContainerAction): void {
    if (action !== SlideOutContainerAction.Cancel) {
      this.slideOutService.triggerAction(action);
    } else {
      forkJoin([this.slideOutService.dirty.pipe(take(1)), this.slideOutService.isFormValid.pipe(take(1))])
        .pipe(
          switchMap(([dirty, valid]) => {
            if (dirty) {
              return this.dialogService.display({
                title: 'Save Changes?',
                message: 'Unsaved changes will be lost.',
                yesButtonText: 'Save Changes',
                noButtonText: 'Abandon Changes',
                buttons: DialogButtons.YesNoCancel,
                validityCallback: () => valid
              });
            } else {
              return of(false);
            }
          }),
          take(1)
        )
        .subscribe((action) => {
          if (typeof action == 'boolean') {
            this.slideOutService.triggerAction(SlideOutContainerAction.Cancel);
          } else if (action === DialogResult.yes) {
            this.slideOutService.triggerAction(SlideOutContainerAction.SaveAndClose);
          } else if (action === DialogResult.no) {
            this.slideOutService.triggerAction(SlideOutContainerAction.Cancel);
          }
        });
    }
  }
}
