export interface RiskMatrixLayoutDto {
  cofAxisCategory: RiskMatrixCofAxisCategory;
  pofAxisCategory: RiskMatrixPofAxisCategory;
  transposeAxis: boolean;
  cofCategories: Array<RiskMatrixCofCategoryDto>;
  pofCategories: Array<RiskMatrixPofCategoryDto>;
  riskCategories: Array<RiskMatrixCategoryDto>;
  layoutMappings: Array<RiskMatrixLayoutMappingDto>;
}

export interface RiskMatrixCofCategoryDto {
  id: string;
  cofCategory: number;
  cofLowerThreshold: number;
  cofUpperThreshold: number;
}

export interface RiskMatrixPofCategoryDto {
  id: string;
  pofCategory: number;
  pofLowerThreshold: number;
  pofUpperThreshold: number;
}

export interface RiskMatrixCategoryDto {
  id: string;
  riskRank: number;
  riskCategory: string;
  riskColor: string;
}

export interface RiskMatrixLayoutMappingDto {
  cofCategoryId: string;
  pofCategoryId: string;
  riskCategoryId: string;
}

export enum RiskMatrixCofCategory {
    A = 1,
    B = 2,
    C = 3,
    D = 4,
    E = 5,
    F = 6,
    G = 7,
    H = 8,
    I = 9,
    J = 10,
    K = 11,
    L = 12,
    M = 13,
    N = 14,
    O = 15,
    P = 16,
    Q = 17,
    R = 18,
    S = 19,
    T = 20
}

export enum RiskMatrixCofAxisCategory {
  'Consequence Area (ft²)' = 0,
  'Consequence Area (m²)' = 1,
  'Financial Consequence ($)' = 2
}

export enum RiskMatrixPofAxisCategory {
  'Damage Factor' = 0,
  'Probability of Failure (events/yr)' = 1
}
