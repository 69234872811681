import { Component, Inject, inject, Input, OnInit } from '@angular/core';
import { E2gSelectOption } from '@equityeng/e2g-ng-ui';
import { forkJoin, map, Observable, of, switchMap, take, takeUntil, tap } from 'rxjs';
import { IdNameDto } from 'src/app/asset-module/models/id-name-dto';
import { CompDataService } from 'src/app/comp-data.service';
import { DmlAssetDataCache, DmlAssetDataDto } from 'src/app/dmls-module/models/dml-asset-data-dto';
import { DmlDto } from 'src/app/dmls-module/models/dml-dto';
import { DmlSlideoutInput } from 'src/app/dmls-module/models/dml-slideout-input';
import { DmlAssessmentLevels } from 'src/app/dmls-module/models/Enums/dml-assessment-levels';
import { DmlStatuses } from 'src/app/dmls-module/models/Enums/dml-statuses';
import { DmlTypes } from 'src/app/dmls-module/models/Enums/dml-types';
import { DmlDataService } from 'src/app/dmls-module/services/dml-data.service';
import { DmlService } from 'src/app/dmls-module/services/dml.service';
import { ComponentDetailsDto } from 'src/app/models/component-details-dto';
import { OnDestroyBaseComponent } from 'src/app/on-destroy-base-component/on-destroy-base-component';
import { NEW_ITEM_KEY } from 'src/app/shared-module/models/new-item-key';
import { AssetFeature } from 'src/app/shared-module/permission/permission-models';
import { PermissionService } from 'src/app/shared-module/permission/permission.service';
import { SingleDataHandlerDefault } from 'src/app/shared-module/single-data-handler-default';
import { SlideOutContainerService } from 'src/app/slide-out-module/slide-out-container.service';
import { SLIDE_OUT_DATA } from 'src/app/slide-out-module/slide-out-data-injection-token';
import { UnitsOfMeasureEvaluator } from 'src/app/units-of-measure/units-of-measure-evaluator';
import { enumToSelectOptions } from 'src/app/utilities/enum-helper';

@Component({
  template: ''
})
export abstract class DmlDetailBaseComponent<T extends DmlDto> extends OnDestroyBaseComponent implements OnInit {
  @Input() public assetCache!: DmlAssetDataCache;
  @Input() public refreshData!: Observable<void>;

  protected afterGetDml?(): void;
  protected setDefaultProperties?(): void;
  protected abstract componentsShellModuleOnly: boolean;
  protected abstract makeNewDml(): T;

  protected slideOutService: SlideOutContainerService = inject(SlideOutContainerService);
  protected uomEvaluator: UnitsOfMeasureEvaluator = inject(UnitsOfMeasureEvaluator);
  private permissionService: PermissionService = inject(PermissionService);
  private componentService: CompDataService = inject(CompDataService);
  private dmlDataService: DmlDataService = inject(DmlDataService);
  protected dmlService: DmlService = inject(DmlService);

  protected statusList: Array<E2gSelectOption> = enumToSelectOptions(DmlStatuses);
  protected assessmentLevelList: Array<E2gSelectOption> = enumToSelectOptions(DmlAssessmentLevels);

  protected loading: boolean = true;
  protected assetId: string = '';
  protected readOnly: boolean = false;
  private editing: boolean = false;

  protected dataHandler: SingleDataHandlerDefault<T>;
  protected comps: Array<ComponentDetailsDto> = [];
  protected componentsOptions: Array<E2gSelectOption> = [];

  public constructor(@Inject(SLIDE_OUT_DATA) protected slideOutData: DmlSlideoutInput) {
    super();

    this.dataHandler = new SingleDataHandlerDefault<T>(
      this.destroy,
      (dirty: boolean) => {
        this.slideOutService.setDirty(dirty);
      },
      undefined,
      () => {
        this.slideOutService.setBodyValid(this.dataHandler.valid);
      }
    );

    this.configDmlService();
    this.dmlService.clear();

    this.configureSlideOut();
  }

  public ngOnInit(): void {
    this.setDefaultProperties?.();

    this.setupDmlData();
  }

  protected initializeForm(componentId: string): void {
    this.editing = componentId !== NEW_ITEM_KEY;

    this.slideOutService.setAllowRename(this.allowRename());
    this.slideOutService.setBodyValid(this.editing);

    this.dataHandler.clear();
    this.loading = true;
  }

  private getComponents(assetId: string, useCache: boolean): Observable<Array<ComponentDetailsDto>> {
    if (useCache) {
      this.comps = this.assetCache.data!.components;
      return of(this.assetCache.data!.components);
    } else {
      return this.componentService.getComponents(assetId).pipe(
        tap((components) => {
          this.assetCache.data!.components = components;
          this.comps = components;
        }),
        take(1)
      );
    }
  }

  private mapComponentDetailsToSelectOptionForDml(
    components: Array<ComponentDetailsDto>,
    shellModuleOnly: boolean
  ): Array<E2gSelectOption> {
    return components
      .filter(
        (x) =>
          x.type === 'SHELL' ||
          x.type === 'HEAD' ||
          (!shellModuleOnly &&
            (x.type == 'PIPE' ||
              x.type == 'ELBOW' ||
              x.type == 'BOILER TUBE' ||
              x.type == 'TUBE' ||
              x.type == 'COURSE'))
      )
      .map((x) => ({
        value: x.id,
        label: `${x.name} (${x.type}, size: ${(x.size || '').replace(/_/gi, ' ')}, nom: ${
          x.nominalThickness === undefined ? '' : x.nominalThickness
        })`
      })) as Array<E2gSelectOption>;
  }

  private getNewDml(): Observable<T> {
    return of(this.makeNewDml());
  }

  protected disableStatus(): boolean {
    return !this.editing;
  }

  private allowRename(): boolean {
    return !this.readOnly;
  }

  private getPermissions(assetId: string, useCache: boolean): Observable<void> {
    if (useCache) {
      this.setPermissions(this.assetCache.data!.permissions.readOnly);

      return of(undefined);
    } else {
      return this.permissionService
        .getPermissions({
          asset: [{ feature: AssetFeature.Dml, assetId }]
        })
        .pipe(
          tap((permissions) => {
            const readOnly = !permissions.asset[AssetFeature.Dml].edit;

            this.setPermissions(readOnly);

            this.assetCache.data!.permissions = {
              readOnly
            };
          }),
          map(() => undefined),
          take(1)
        );
    }
  }

  private setPermissions(readOnly: boolean): void {
    this.readOnly = readOnly;
  }

  private loadAssetData(): Array<Observable<any>> {
    const useCache: boolean = this.assetCache.data !== undefined;

    if (!useCache) {
      this.assetCache.data = {} as DmlAssetDataDto;
    }

    return [
      this.getPermissions(this.slideOutData.assetId, useCache),
      this.getComponents(this.slideOutData.assetId, useCache)
    ];
  }

  private configureSlideOut(): void {
    this.slideOutService.nameUpdated.pipe(takeUntil(this.destroy)).subscribe((newName) => {
      this.dataHandler.data.name = newName;
    });
  }

  private setupDmlData(): void {
    this.refreshData
      .pipe(
        tap(() => {
          this.initializeForm(this.slideOutData.id);
        }),
        switchMap(() => forkJoin(this.loadAssetData())),
        switchMap(() => this.getDmlNamesForAsset(this.slideOutData.assetId)), // We don't want to cache this because on a save it needs to update.
        tap(() => {
          this.componentsOptions = this.mapComponentDetailsToSelectOptionForDml(
            this.comps,
            this.componentsShellModuleOnly
          );
        }),
        switchMap(() => this.getDml(this.slideOutData.id, this.slideOutData.type)),
        takeUntil(this.destroy)
      )
      .subscribe();
  }

  private getDmlNamesForAsset(assetId: string): Observable<void> {
    return this.dmlDataService.getDmlNamesForAsset(assetId).pipe(
      tap((dmlNames: Array<IdNameDto>) => {
        this.slideOutService.setHeaderInvalidNameList(
          dmlNames.filter((x) => x.id !== this.slideOutData.id).map((x) => x.name)
        );
      }),
      map(() => undefined),
      takeUntil(this.destroy)
    );
  }

  private getDml(id: string, type: DmlTypes): Observable<T> {
    return (this.editing ? this.dmlDataService.getDamageMonitoringLocation<T>(id, type) : this.getNewDml()).pipe(
      tap((dml: T) => {
        this.dmlService.setHasOutputAndCalculationErrors(dml);

        if (dml.fileId !== undefined && dml.fileName !== undefined) {
          this.dmlService.setGridDisplayInfo({ fileId: dml.fileId, fileName: dml.fileName });
        }

        this.dataHandler.setInitialData(dml);

        this.afterGetDml?.();

        this.loading = false;
      })
    );
  }

  protected configDmlService(): void {
    this.dmlService.retrieveDmlData.subscribe(() => {
      if (this.dataHandler.initialized) {
        this.dmlService.setDmlData(this.dataHandler.data);
      }
    });

    this.dmlService.revertDataChanges.subscribe(() => {
      if (this.dataHandler.initialized) {
        this.dataHandler.revertChanges();
      }
    });
  }
}
