export enum AttachmentTableType {
  ActivityItem = 1,
  ActivityFuturePlanning = 2,
  ActivityFutureInProgress = 3,
  Asset = 4,
  Cml = 5,
  Findings = 6,
  SageModule = 7,
  Unit = 8
}
