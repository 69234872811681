import { Component, Inject } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { DmlDetailBaseComponent } from 'src/app/dmls-module/dml-slideout/dml-detail-base-component';
import { DmlTypes } from 'src/app/dmls-module/models/Enums/dml-types';
import { SLIDE_OUT_DATA } from 'src/app/slide-out-module/slide-out-data-injection-token';

import { DmlDentDto } from '../../models/dml-dent-dto';
import { DmlSlideoutInput } from '../../models/dml-slideout-input';
import { DmlAssessmentLevels } from '../../models/Enums/dml-assessment-levels';
import { DmlStatuses } from '../../models/Enums/dml-statuses';

@Component({
  selector: 'app-dml-dent',
  templateUrl: './dml-dent.component.html'
})
export class DmlDentComponent extends DmlDetailBaseComponent<DmlDentDto> {
  protected componentsShellModuleOnly = true;

  public nameErrors = [] as string[];

  public pressurizedDentDepthUom: Observable<string> = this.uomEvaluator.getUnits('DmlDent_PressurizedDentDepth');
  public unpressurizedDentDepthUom: Observable<string> = this.uomEvaluator.getUnits('DmlDent_UnpressurizedDentDepth');
  public dentRadiusUom: Observable<string> = this.uomEvaluator.getUnits('DmlDent_DentRadius');
  public gougeDepthUom: Observable<string> = this.uomEvaluator.getUnits('DmlDent_GougeDepth');
  public nearestWeldDistanceUom: Observable<string> = this.uomEvaluator.getUnits('DmlDent_NearestWeldDistance');
  public minimumCvnUom: Observable<string> = this.uomEvaluator.getUnits('DmlDent_MinimumCvn');
  public maximumCyclicPressureUom: Observable<string> = this.uomEvaluator.getUnits('DmlDent_MaximumCyclicPressure');
  public minimumCyclicPressureUom: Observable<string> = this.uomEvaluator.getUnits('DmlDent_MinimumCyclicPressure');

  public refreshData = new Subject<void>();

  public constructor(@Inject(SLIDE_OUT_DATA) protected slideOutData: DmlSlideoutInput) {
    super(slideOutData);

    this.setupValidation();
  }

  protected afterGetDml(): void {
    this.setDefaults();
    this.dataHandler.promoteChanges();
  }

  private setupValidation(): void {
    this.dataHandler.setValidation({
      componentId: () => of(this.dataHandler.data.componentId === undefined ? 'ERROR' : undefined),
      dentRadius: () => this.validateNumber(this.dataHandler.data.dentRadius),
      nearestWeldDistance: () => this.validateNumber(this.dataHandler.data.nearestWeldDistance),
      gougeDepth: () => this.validateNumber(this.dataHandler.data.gougeDepth),
      minimumCvn: () => this.validateNumber(this.dataHandler.data.minimumCvn)
    });
  }

  private validateNumber(num: number | undefined): Observable<string | undefined> {
    return of(num === undefined || num <= 0 ? 'ERROR' : undefined);
  }

  private setDefaults(): void {
    if (this.dataHandler.data.id === undefined) {
      this.dataHandler.data.status = DmlStatuses.New;
      this.dataHandler.data.assessmentLevel = DmlAssessmentLevels['Level 1'];
    }

    if (this.dataHandler.data.pressurizedDentDepth === undefined) {
      this.dataHandler.data.pressurizedDentDepth = 0;
    }

    if (this.dataHandler.data.maximumCyclicPressure === undefined) {
      this.dataHandler.data.maximumCyclicPressure = 0;
    }

    if (this.dataHandler.data.minimumCyclicPressure === undefined) {
      this.dataHandler.data.minimumCyclicPressure = 0;
    }
  }

  protected makeNewDml(): DmlDentDto {
    return {
      id: undefined,
      name: undefined,
      type: DmlTypes['Dent'],
      status: DmlStatuses.New,
      assessmentLevel: DmlAssessmentLevels['Level 1'],
      hasCalculationErrors: false,
      dentRadius: undefined,
      nearestWeldDistance: undefined,
      gougeDepth: undefined,
      minimumCvn: undefined
    };
  }
}
