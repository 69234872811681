import { CellClassRules, ColDef, ValueGetterParams } from 'ag-grid-community';

import { doubleWithNullComparator } from '../column-data-comparers/double-with-null-comparator';
import { decimalFormatter } from '../column-formatters/decimal-formatter';
import { assignHeaderAndTooltip } from './build-default';

export function buildDoubleColDef(
  header: string,
  field: string,
  classRules: CellClassRules | undefined = undefined,
  isRate: boolean = false
): ColDef {
  return {
    ...assignHeaderAndTooltip(header, field),
    valueGetter: isRate ? rateFormatter : decimalFormatter,
    comparator: doubleWithNullComparator,
    filter: 'agNumberColumnFilter',
    filterValueGetter: (params: ValueGetterParams): any =>
      isRate ? Number(rateFormatter(params)) : Number(decimalFormatter(params)),
    cellClassRules: classRules
  };
}

export function rateFormatter(params: ValueGetterParams): string {
  const rate = params.data[params.colDef.field || ''] as number;
  return rate !== undefined ? Number(rate).toFixed(4) : '';
}
