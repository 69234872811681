import { Component, inject, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DmlTypes } from 'src/app/dmls-module/models/Enums/dml-types';
import { DmlDataService } from 'src/app/dmls-module/services/dml-data.service';
import { OnDestroyBaseComponent } from 'src/app/on-destroy-base-component/on-destroy-base-component';
import { SingleDataHandlerDefault } from 'src/app/shared-module/single-data-handler-default';
import { UnitsOfMeasureEvaluator } from 'src/app/units-of-measure/units-of-measure-evaluator';
import { parseMarkdown } from 'src/app/utilities/string-methods';

@Component({
  template: ''
})
export abstract class DmlDetailSummaryBaseComponent<T extends object> extends OnDestroyBaseComponent implements OnInit {
  @Input() public damageMonitoringLocationId!: string;

  protected uomEvaluator: UnitsOfMeasureEvaluator = inject(UnitsOfMeasureEvaluator);
  protected domSanitizer: DomSanitizer = inject(DomSanitizer);
  protected dmlDataService: DmlDataService = inject(DmlDataService);

  protected loading = true;
  protected dataHandler: SingleDataHandlerDefault<T>;
  protected abstract dmlType: DmlTypes;

  protected abstract displaySummary(output: T): void;

  public constructor() {
    super();

    this.dataHandler = new SingleDataHandlerDefault<T>(this.destroy);
  }

  public ngOnInit(): void {
    if (this.damageMonitoringLocationId !== undefined) {
      this.dmlDataService
        .getDamageMonitoringLocationOutput<T>(this.damageMonitoringLocationId, this.dmlType!)
        .subscribe((output) => {
          this.displaySummary(output as T);

          this.dataHandler.setInitialData(output as T);

          this.loading = false;
        });
    } else {
      this.loading = false;
    }
  }

  public toFixedFourFormater(value: any): string {
    if (value !== undefined) {
      return Number(value).toFixed(4);
    }

    return '';
  }

  public parseMarkdown(msg: string): SafeHtml {
    return this.domSanitizer.bypassSecurityTrustHtml(parseMarkdown(msg));
  }

  protected formatSummarySection(list: string[]): string[] {
    return list.filter((x) => x !== '').map((x) => (x[0] === '*' ? x.substring(1) : x));
  }
}
