<div class="d-flex flex-row align-items-center" *ngIf="title">
  <div class="form-section-header">{{ title }}</div>
  <div class="d-flex flex-row align-items-center" *ngIf="canSelectAll">
    <div class="form-section-header">&nbsp;-&nbsp;</div>
    <a class="e2g-link" (click)="selectAll()">Select All</a>
  </div>
</div>
<div class="row mr-0">
  <div *ngFor="let col of columns" class="col checkbox-column">
    <div *ngFor="let option of col" class="flex-row">
      <e2g-check-input
        [heading]="option.label"
        [readonly]="readOnly"
        labelPosition="right"
        [ngModel]="isSelected(getOptionValue(option))"
        (change)="onCheckboxChange($event, getOptionValue(option))"
        [showInfo]="option.infoText != undefined"
        [infoText]="option.infoText!"
        [indeterminate]="isIndeterminate(getOptionValue(option))"
      >
      </e2g-check-input>
    </div>
  </div>
</div>
