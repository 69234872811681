import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FluidStream } from '@equityeng/fluid-designer';
import { faClone, faEdit, faPlus, faTrashXmark } from '@fortawesome/pro-regular-svg-icons';
import { ReplaySubject } from 'rxjs';
import { NotificationService } from 'src/app/shared-module/services/notification.service';

import { FluidStreamDataService } from '../../fluid-data.service';
import { FluidStreamDto } from '../../models/fluid-stream-dto';
import { OnDestroyBaseComponent } from '../../on-destroy-base-component/on-destroy-base-component';
import { DESIGN_CODE_SAGE_VALUES } from '../../utilities/design-code-helper';
import { FluidModalComponent } from '../fluid-modal/fluid-modal.component';

@Component({
  selector: 'app-fluid',
  templateUrl: './fluid.component.html',
  styleUrls: ['./fluid.component.css']
})
export class FluidComponent extends OnDestroyBaseComponent implements OnInit {
  @Input() public selectedStream!: FluidStreamDto | undefined;
  @Input() public readOnly: boolean = false;
  @Input() public streams?: Array<FluidStreamDto>;
  @Output('onNewFluidStream') private onNewFluidStream: EventEmitter<string> = new EventEmitter();
  @Output('onDeleteFluidStream') private onDeleteFluidStream: EventEmitter<any> = new EventEmitter();
  @Output('onEditFluidStream') private onEditFluidStream: EventEmitter<string> = new EventEmitter();

  public specTrigger = new ReplaySubject<void>(1);
  public faTrashXmark = faTrashXmark;
  public faPlus = faPlus;
  public faCopy = faClone;
  public faEdit = faEdit;

  public constructor(
    private fluidService: FluidStreamDataService,
    private notificationService: NotificationService,
    private dialog: MatDialog
  ) {
    super();
  }

  public fluidStream!: FluidStream;
  public designCodeSageValues: Record<string, string> = DESIGN_CODE_SAGE_VALUES;

  public ngOnInit(): void {
    //
  }

  private loadStream(source: FluidStreamDto): FluidStream {
    const newFluid: FluidStream = {
      name: source.name!,
      basis: source.basis!,
      components: source.components!.map((x) => ({ id: x.id!, qty: x.qty! }))
    };
    return newFluid;
  }

  private unloadStream(source: FluidStream): FluidStreamDto {
    const unloadedFluid: FluidStreamDto = {
      id: this.selectedStream?.id,
      name: source.name,
      basis: source.basis,
      components: source.components.map((x) => ({
        streamId: this.selectedStream?.id,
        id: x.id,
        qty: x.qty
      }))
    };
    return unloadedFluid;
  }

  public editFluidStream(): void {
    if (this.selectedStream) {
      this.openDialog(this.loadStream(this.selectedStream), false, 'Edit Fluid Stream');
    }
  }

  public copyFluidStream(): void {
    if (this.selectedStream) {
      const stream = this.loadStream(this.selectedStream);
      stream.name = '';
      this.openDialog(stream, true, 'Copy Fluid Stream');
    }
  }

  public newFluidStream(): void {
    this.openDialog(
      {
        name: '',
        basis: 'Mass',
        components: []
      },
      true,
      'Create New Fluid Stream'
    );
  }

  public deleteFluidStream(): void {
    this.onDeleteFluidStream.emit();
  }

  private openDialog(stream: FluidStream, newFluid: boolean, title: string): void {
    this.dialog
      .open(FluidModalComponent, {
        data: {
          inputStream: stream,
          streams: this.streams,
          title: title
        },
        disableClose: true,
        panelClass: 'fluid-designer-modal',
        maxWidth: '95vw',
        width: '1200px',
        height: '600px'
      })
      .afterClosed()
      .subscribe((selectedFluid: FluidStream) => {
        if (selectedFluid) {
          if (newFluid) {
            this.fluidService
              .createFluidStream(this.unloadStream(selectedFluid))
              .subscribe((streamId: string | undefined) => {
                this.notificationService.showSaveResult(streamId !== undefined, 'Create New Fluid Stream');
                this.onNewFluidStream.emit(streamId);
              });
          } else {
            this.fluidService.updateFluidStream(this.unloadStream(selectedFluid)).subscribe((success: boolean) => {
              this.notificationService.showSaveResult(success, 'Update Fluid Stream');
              this.onEditFluidStream.emit(this.selectedStream?.id);
            });
          }
        }
      });
  }
}
