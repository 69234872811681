import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { AppSettingsService } from './app-settings/app-settings.service';
import { CalcUpdateDto } from './models/calculation-impacted-ids-dto';
import { JobNotificationService } from './notifications-module/services/job-notifications.service';

@Injectable({
  providedIn: 'root'
})
export class CalculationDataService {
  public constructor(
    private http: HttpClient,
    private settingsService: AppSettingsService,
    private notificationService: JobNotificationService
  ) {}

  public calculateUnit(unitId: string): Observable<boolean> {
    return this.http
      .post(`${this.settingsService.settings.apiUri}/calculations/unit/${unitId}`, {})
      .pipe((x) => this.handleResponse(x));
  }

  public calculateAsset(assetId: string): Observable<boolean> {
    return this.http
      .post(`${this.settingsService.settings.apiUri}/calculations/asset/${assetId}`, {})
      .pipe((x) => this.handleResponse(x));
  }

  public getCalculationsInProgress(): Observable<Array<CalcUpdateDto>> {
    return this.http
      .get<Array<CalcUpdateDto>>(`${this.settingsService.settings.apiUri}/calculations/inprogress`)
      .pipe(catchError(() => of([])));
  }

  public calculateSageModules(): Observable<boolean> {
    return this.http
      .post(`${this.settingsService.settings.apiUri}/calculations/sage`, {})
      .pipe((x) => this.handleResponse(x));
  }

  private handleResponse(response: Observable<any>): Observable<boolean> {
    return response.pipe(
      map(() => true),
      tap(() => this.notificationService.refreshNotifications()),
      catchError(() => of(false))
    );
  }
}
