import { ColDef } from 'ag-grid-community';
import { minimumThicknessWithMawpAndNullComparator } from 'src/app/grid-module/column-data-comparers/minimum-thickness-with-mawp-null-comparator';
import { minimumThicknessWithMawpGetter } from 'src/app/grid-module/column-formatters/minimum-thickness-formatter';

export function getMinimumThicknessColumn(): ColDef {
  return {
    headerName: 'Minimum Thickness',
    headerTooltip: 'Minimum Thickness',
    field: 'minimumThickness',
    tooltipField: 'minimumThickness',
    width: 150,
    valueGetter: minimumThicknessWithMawpGetter,
    comparator: minimumThicknessWithMawpAndNullComparator
  };
}
