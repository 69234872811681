import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ReplaySubject, switchMap, tap } from 'rxjs';
import { DmlGridDataDto } from 'src/app/dmls-module/models/dml-grid-data-dto';

import { DmlDataService } from '../services/dml-data.service';

@Component({
  selector: 'app-dml-graph',
  templateUrl: './dml-graph.component.html',
  styleUrls: ['./dml-graph.component.css']
})
export class DmlGraphComponent implements OnInit, OnChanges {
  @Input() public dmlType!: number;
  @Input() public dmlFileId?: string;

  private dmlFileId$ = new ReplaySubject<string>(1);

  public widthAndHeight: any;
  public ready: boolean = false;

  public graphData: Array<any> = [];
  public graphLayout = {
    autosize: true,
    title: 'Thickness Grid',
    yaxis: { autorange: 'reversed' }
  };
  public graphStyles = {
    position: 'relative',
    width: '100%',
    height: '100%'
  };

  public constructor(private dmlDataService: DmlDataService) {}

  public ngOnInit(): void {
    this.dmlFileId$
      .pipe(
        tap(() => (this.ready = false)),
        switchMap((fileId) => this.dmlDataService.getDamageMonitoringLocationGridData(this.dmlType, fileId))
      )
      .subscribe((gridData: DmlGridDataDto) => {
        this.setGridData(gridData);
        this.calculateGraphSize(gridData.data);
        this.ready = true;
      });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.dmlFileId) {
      this.dmlFileId$.next(changes.dmlFileId.currentValue);
    }
  }

  private setGridData(data: any): void {
    this.graphData = [
      {
        z: data,
        type: 'contour',
        colorscale: 'Jet',
        reversescale: true
      }
    ];
  }

  private calculateGraphSize(data: Array<Array<number>>): void {
    const dimensions = this.getMatrixDimensions(data);

    let aspect: number;
    if (dimensions.width > dimensions.height) {
      aspect = dimensions.height / dimensions.width;
      this.widthAndHeight = {
        width: '100%',
        height: `${100 * aspect}%`
      };
    } else {
      aspect = dimensions.width / dimensions.height;
      this.widthAndHeight = {
        width: `${100 * aspect}%`,
        height: '100%'
      };
    }
  }

  private getMatrixDimensions(data: Array<Array<number>>): { width: number; height: number } {
    if (!data || data.length === 0 || data[0].length === 0) {
      return { width: 0, height: 0 };
    }

    return { width: data[0].length, height: data.length };
  }
}
