import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import { SlideOutService } from '../slide-out-module/slide-out.service';
import { DataCardSlideoutComponent } from './data-card-slideout/data-card-slideout.component';
import { DashboardSlideoutData } from './models/dashboard-slideout-data';

@Injectable({
  providedIn: 'root'
})
export class DashboardSlideoutService {
  public slideOutSettings: any = {
    maxWidth: '100vw',
    maxHeight: '100vh',
    height: '100%',
    width: '90%',
    position: { right: '0px' }
  };

  public constructor(public dialog: MatDialog, private slideOutService: SlideOutService) {}

  public openSlideout(data: DashboardSlideoutData): Observable<any> {
    const dialogRef = this.slideOutService.open<DashboardSlideoutData, DataCardSlideoutComponent>(
      DataCardSlideoutComponent,
      {
        ...this.slideOutSettings,
        data: data
      }
    );

    return dialogRef.closed;
  }
}
