import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { Breadcrumb } from 'src/app/breadcrumb-module/breadcrumbs/breadcrumbs.component';
import { SageSavePacket } from 'src/app/sage-wrapper-module/models/sage-save-packet';

export const SAGE_MODULE_SERVICE = new InjectionToken<ISageModuleService>('SAGE_MODULE_SERVICE_TOKEN');

export interface ISageModuleService {
  save(savePacket: SageSavePacket): Observable<boolean>;
  getBreadcrumbs(name: string, moduleType: string): Observable<Breadcrumb[]>;
  getCalcStatus(): Observable<string>;
  routeBack(): void;
  readonly isForComponent?: boolean;
}
