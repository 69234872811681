import { Injectable } from '@angular/core';
import { NotificationService } from 'src/app/shared-module/services/notification.service';

import { JobNotificationService } from '../../notifications-module/services/job-notifications.service';
import { ReportingDataService } from '../reporting.data.service';

@Injectable({
  providedIn: 'root'
})
export class JointAssemblyProcedureReportService {
  public constructor(
    private jointAssemblyProcedureReportDataService: ReportingDataService,
    private notificationService: NotificationService,
    private jobNotificationsService: JobNotificationService
  ) {}

  public run(sageModuleId: string): void {
    this.notificationService.showInfo('Generating Joint Assembly Procedure Report');

    this.jointAssemblyProcedureReportDataService
      .generateJointAssemblyProcedureReport(sageModuleId)
      .subscribe(() => this.jobNotificationsService.refreshNotifications());
  }
}
