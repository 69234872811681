<div class="e2g-padding">
  <div>
    <h1>Authentication Error</h1>
  </div>
  <p *ngIf="error | async as error; else noMessage" id="p-message">Error: {{ error.message }}</p>
  <button id="btn-retry" class="btn e2g-btn-outline-primary" (click)="retry()">Retry</button>
</div>
<ng-template #noMessage>
  <p id="p-noerror">An error occurred while completing the authentication process</p>
</ng-template>
