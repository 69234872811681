<div *ngIf="alert" class="fixed w-100">
  <div class="e2g-warning-bar">
    {{ alert }}
  </div>
</div>
<app-slide-out-header (triggerAction)="triggerAction($event)"></app-slide-out-header>
<div id="slide-out-content" [ngClass]="hasBreadcrumbs ? 'slide-out-content-with-breadcrumbs' : 'slide-out-content'">
  <ng-content></ng-content>
</div>
<app-slide-out-footer header (triggerAction)="triggerAction($event)"></app-slide-out-footer>
