import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { E2gSelectOption } from '@equityeng/e2g-ng-ui';
import { OnDestroyBaseComponent } from 'src/app/on-destroy-base-component/on-destroy-base-component';

@Component({
  selector: 'app-checkbox-multiselect',
  templateUrl: './checkbox-multiselect.component.html',
  styleUrls: ['./checkbox-multiselect.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxMultiselectComponent),
      multi: true
    }
  ]
})
export class CheckboxMultiselectComponent extends OnDestroyBaseComponent implements ControlValueAccessor {
  public selectedItems?: Array<string | number>;
  private availableItems!: Array<E2gSelectOption>;

  @Input() public unselectedLabel: string = '';
  @Input() public selectedLabel: string = '';
  @Input() public readOnly: boolean = false;

  @Input() public set allAvailableItems(data: Array<E2gSelectOption>) {
    this.availableItems = data;
    this.assignOptions();
  }

  @Output() public change = new EventEmitter<Array<string | number>>();

  public itemChecked: boolean = true;
  public selected!: Array<E2gSelectOption>;
  public unselected!: Array<E2gSelectOption>;

  public constructor() {
    super();
  }

  public removeAll(): void {
    this.selectedItems = this.selectedItems?.filter(
      (x) =>
        !this.getSelected()
          .filter((z) => !z.disabled)
          .map((y) => y.value!)
          .includes(x)
    );

    this.handleChange();
  }

  public addAll(): void {
    this.selectedItems = [...this.selectedItems!, ...this.getUnselected().map((x) => x.value!)];

    this.handleChange();
  }

  public onSelectedOrUnselectedChange(id: string | number, isUnselected: boolean): void {
    if (isUnselected) {
      this.selectedItems = [...this.selectedItems!, id];
    } else {
      this.selectedItems = this.selectedItems?.filter((x) => x !== id);
    }

    this.handleChange();
  }

  public getUnselected(): Array<E2gSelectOption> {
    return (
      this.availableItems?.filter((x) => !this.selectedItems?.includes(x.value!)) ?? ([] as Array<E2gSelectOption>)
    );
  }

  public getSelected(): Array<E2gSelectOption> {
    return this.availableItems?.filter((x) => this.selectedItems?.includes(x.value!)) ?? ([] as Array<E2gSelectOption>);
  }

  private assignOptions(): void {
    this.selected = this.getSelected();
    this.unselected = this.getUnselected();
  }

  public onChange: ((value: Array<string | number>) => void) | undefined;
  public onTouched: (() => void) | undefined;

  public registerOnChange(fn: (value: Array<string | number>) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public writeValue(value: Array<string | number>): void {
    this.selectedItems = value || [];

    this.assignOptions();
  }

  private handleChange(): void {
    if (this.onChange) {
      this.assignOptions();

      this.onChange(this.selectedItems!);
      this.change.emit(this.selectedItems!);
    }
  }
}
