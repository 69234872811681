import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AppSettingsService } from './app-settings/app-settings.service';
import { ActivityTypeDto } from './models/activity-type-dto';

@Injectable({
  providedIn: 'root'
})
export class ActivityTypeDataService {
  private serverUri: string;

  public constructor(private http: HttpClient, private settingsService: AppSettingsService) {
    this.serverUri = this.settingsService.settings.apiUri as string;
  }

  public getActivityTypes(): Observable<Array<ActivityTypeDto>> {
    return this.http.get<Array<ActivityTypeDto>>(`${this.serverUri}/activity-types`).pipe(catchError(() => of([])));
  }

  public saveActivityType(data: ActivityTypeDto): Observable<string | boolean> {
    if (data.id) {
      return this.http.put<boolean>(`${this.serverUri}/activity-types/${data.id}`, data).pipe(
        map(() => true),
        catchError(() => of(false))
      );
    }
    return this.http.post<string>(`${this.serverUri}/activity-types`, data);
  }

  public deleteActivityType(id: string): Observable<boolean> {
    return this.http.delete<boolean>(`${this.serverUri}/activity-types/${id}`).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  public getActivityType(id?: string): Observable<ActivityTypeDto> {
    if (id) {
      return this.http
        .get<ActivityTypeDto>(`${this.serverUri}/activity-types/${id}`)
        .pipe(catchError(() => of({} as ActivityTypeDto)));
    } else {
      return of({} as ActivityTypeDto);
    }
  }

  public getActivityTypesForAsset(assetId: string): Observable<Array<ActivityTypeDto>> {
    return this.http
      .get<Array<ActivityTypeDto>>(`${this.serverUri}/assets/${assetId}/activity-types`)
      .pipe(catchError(() => of([])));
  }
}
