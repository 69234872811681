import { anyIdsInCommon } from '../utilities/array-helper';
import { CalculationMessageStatus } from './enums/calculation-message-status';

export interface PlantManagerImpactedIdsDto {
  assetIds?: Array<string>;
  componentIds?: Array<string>;
  cmlIds?: Array<string>;
  activityIds?: Array<string>;
}

export interface CalcUpdateDto {
  messageId: number; //Calc Message
  userKey: string; //User Making changes
  status: CalculationMessageStatus;
  impactedIds?: PlantManagerImpactedIdsDto;
}

export function hasIdsInCommon(a: PlantManagerImpactedIdsDto, b: PlantManagerImpactedIdsDto): boolean {
  return (
    anyIdsInCommon(a.assetIds, b.assetIds) ||
    anyIdsInCommon(a.componentIds, b.componentIds) ||
    anyIdsInCommon(a.cmlIds, b.cmlIds) ||
    anyIdsInCommon(a.activityIds, b.activityIds)
  );
}
