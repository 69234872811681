import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';

import { GenericActivityDto } from '../../activity-details-module/models/generic-activity-dto';
import { AppSettingsService } from '../../app-settings/app-settings.service';

@Injectable({
  providedIn: 'root'
})
export class ActivityGenericDataService {
  private serverUri: string;

  public constructor(private http: HttpClient, private settingsService: AppSettingsService) {
    this.serverUri = this.settingsService.settings.apiUri as string;
  }

  public getGenericActivity(activityId: string): Observable<GenericActivityDto> {
    return this.http.get<GenericActivityDto>(`${this.serverUri}/generic-activities/${activityId}`);
  }

  public addGenericActivity(assetId: string, dto: GenericActivityDto): Observable<string> {
    return this.http.post<string>(`${this.serverUri}/assets/${assetId}/generic-activities`, dto);
  }

  public updateGenericActivity(data: GenericActivityDto): Observable<boolean> {
    return this.http.put(`${this.serverUri}/generic-activities`, data).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  public deleteGenericActivity(activityId: string): Observable<boolean> {
    return this.http.delete<boolean>(`${this.serverUri}/generic-activities/${activityId}`).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }
}
