import { ColDef } from 'ag-grid-community';
import { doubleWithNullComparator } from 'src/app/grid-module/column-data-comparers/double-with-null-comparator';
import { DecimalEditor } from 'src/app/shared-module/ag-grid/editor-components/decimal-editor';

import { toFixedThreeFormatter } from '../column-formatters/fixed-three-formatter';

export function getDmlCreepOperatingConditionsGridColumns(): Array<ColDef> {
  return [
    {
      headerName: 'Id',
      field: 'id',
      hide: true
    },
    {
      headerName: 'Condition Duration',
      headerTooltip: 'Condition Duration',
      field: 'conditionDuration',
      tooltipField: 'conditionDuration',
      cellEditor: DecimalEditor,
      valueFormatter: toFixedThreeFormatter,
      comparator: doubleWithNullComparator,
      width: 125
    },
    {
      headerName: 'Temperature',
      headerTooltip: 'Temperature',
      field: 'temperature',
      tooltipField: 'temperature',
      cellEditor: DecimalEditor,
      valueFormatter: toFixedThreeFormatter,
      comparator: doubleWithNullComparator,
      width: 125
    },
    {
      headerName: 'Pressure',
      headerTooltip: 'Pressure',
      field: 'pressure',
      tooltipField: 'pressure',
      cellEditor: DecimalEditor,
      valueFormatter: toFixedThreeFormatter,
      comparator: doubleWithNullComparator,
      width: 125
    },
    {
      headerName: 'ID Corrosion Rate',
      headerTooltip: 'ID Corrosion Rate',
      field: 'innerDiameterCorrosionRate',
      tooltipField: 'innerDiameterCorrosionRate',
      cellEditor: DecimalEditor,
      valueFormatter: toFixedThreeFormatter,
      comparator: doubleWithNullComparator,
      width: 125
    },
    {
      headerName: 'OD Corrosion Rate',
      headerTooltip: 'OD Corrosion Rate',
      field: 'outerDiameterCorrosionRate',
      tooltipField: 'outerDiameterCorrosionRate',
      cellEditor: DecimalEditor,
      valueFormatter: toFixedThreeFormatter,
      comparator: doubleWithNullComparator,
      width: 125
    },
    {
      headerName: 'Axial Force',
      headerTooltip: 'Axial Force',
      field: 'axialForce',
      tooltipField: 'axialForce',
      cellEditor: DecimalEditor,
      valueFormatter: toFixedThreeFormatter,
      comparator: doubleWithNullComparator,
      width: 125
    },
    {
      headerName: 'Bending Moment',
      headerTooltip: 'Bending Moment',
      field: 'bendingMoment',
      tooltipField: 'bendingMoment',
      cellEditor: DecimalEditor,
      valueFormatter: toFixedThreeFormatter,
      comparator: doubleWithNullComparator,
      flex: 1
    }
  ];
}
