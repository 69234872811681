import { InternalThinningTypes } from '../asset-module/models/enums/internal-thinning-types';
import { ComponentLinkedDataDto } from './component-record-dto';

export interface CircuitDescriptorDto {
  id: string;
  name: string;
}

export interface CircuitDto {
  id?: string;
  unitId?: string;
  assetId?: string;
  name?: string;
  description?: string;
  comments?: string;
  operatingPressure?: number;
  operatingTemperature?: number;

  drawingName?: string;
  conditionMonitoringLocations: Array<string>;
  fluidStreamId?: string;

  damageMechanismId?: string;
  thinningDamageMechanismId?: string;
  crackingMechanismId?: string;

  internalThinningType?: InternalThinningTypes | string | null;
  eqEstimatedCorrosionRate?: number;
  estimatedCorrosionRate?: number;
  thinningDamageMechanisms?: Array<string>;
  equipmentLining?: boolean;

  externalEnvironment?: string;
  externalCorrosionRateBasis?: boolean;
  estimatedExternalCorrosionRate?: number;
  calculatedExternalCorrosionRate?: number;
  externalCrackingSusceptibilityBasis?: boolean;
  estimatedExternalCrackingSusceptibility?: number;
  calculatedExternalCrackingSusceptibility?: number;

  crackingMechanisms?: Array<number>;
  causticCrackingSusceptibility?: number;
  amineCrackingSusceptibility?: number;
  sscH2sCrackingSusceptibility?: number;
  hicSohicH2sCrackingSusceptibility?: number;
  carbonateCrackingSusceptibility?: number;
  polythionicAcidCrackingSusceptibility?: number;
  chlorideCrackingSusceptibility?: number;
  hicSohicHfCrackingSusceptibility?: number;
  hscHfCrackingSusceptibility?: number;
  otherCrackingSusceptibility?: number;

  componentLinkedDataDtos?: Array<ComponentLinkedDataDto>;
  rbi?: boolean;
}

export const getNewCircuitDto = (unitId: string, assetId: string): CircuitDto => {
  return {
    id: undefined,
    unitId: unitId,
    assetId: assetId,
    name: '',
    description: undefined,
    operatingPressure: undefined,
    operatingTemperature: undefined,
    drawingName: undefined,
    conditionMonitoringLocations: new Array<string>(),
    fluidStreamId: undefined,
    damageMechanismId: undefined,
    thinningDamageMechanismId: undefined,
    crackingMechanismId: undefined,
    internalThinningType: undefined,
    eqEstimatedCorrosionRate: undefined,
    estimatedCorrosionRate: undefined,
    thinningDamageMechanisms: new Array<string>(),
    equipmentLining: undefined,
    externalEnvironment: undefined,
    externalCorrosionRateBasis: true,
    estimatedExternalCorrosionRate: undefined,
    calculatedExternalCorrosionRate: undefined,
    externalCrackingSusceptibilityBasis: true,
    estimatedExternalCrackingSusceptibility: undefined,
    calculatedExternalCrackingSusceptibility: undefined,
    crackingMechanisms: new Array<number>(),
    causticCrackingSusceptibility: undefined,
    amineCrackingSusceptibility: undefined,
    sscH2sCrackingSusceptibility: undefined,
    hicSohicH2sCrackingSusceptibility: undefined,
    carbonateCrackingSusceptibility: undefined,
    polythionicAcidCrackingSusceptibility: undefined,
    chlorideCrackingSusceptibility: undefined,
    hicSohicHfCrackingSusceptibility: undefined,
    hscHfCrackingSusceptibility: undefined,
    otherCrackingSusceptibility: undefined,
    rbi: false,
    componentLinkedDataDtos: new Array<ComponentLinkedDataDto>()
  };
};
