<div class="h-100 d-flex flex-column">
  <div class="overflow-auto h-100 container-padding pl-4 pt-4">
    <ng-container class="pl-4 pr-3" *ngIf="!loading; else load">
      <app-asset-governing-inspection-settings
        [model]="assetGoverningInspectionDomainModel"
        [assetDefaultsConfiguration]="assetDefaultsConfiguration"
        [readonly]="readOnly"
        [unitOfMeasure]="inputData.unitOfMeasure!"
        (isValid)="assetDefaultsConfigurationValid = $event; isValid()"
        [hasProductSCRA]="scraEnabled"
      ></app-asset-governing-inspection-settings>

      <hr class="separator" />

      <div *ngIf="rbiEnabled">
        <div class="form-section-header">Risk Based Inspection</div>
        <e2g-check-input
          id="inp-rbi-enable"
          heading="RBI Enabled"
          labelPosition="right"
          [readonly]="readOnly"
          [(ngModel)]="dataHandler.data.rbi"
        ></e2g-check-input>
        <hr class="separator" />
      </div>

      <div class="form-section-header">Survey Temperature Corrections</div>
      <e2g-check-input
        id="inp-surveyTempCorrection-enable"
        heading="Enabled"
        labelPosition="right"
        [readonly]="readOnly"
        [(ngModel)]="dataHandler.data.hasSurveyTempCorrections"
      ></e2g-check-input>
      <hr class="separator" />
      <div class="form-section-header">Use Component Nominal in Corrosion Rate Calculation</div>
      <e2g-check-input
        id="inp-nominalInRateCalc-enable"
        heading="Enabled"
        labelPosition="right"
        [readonly]="readOnly"
        [(ngModel)]="dataHandler.data.isNominalInRateCalc"
      ></e2g-check-input>
    </ng-container>
  </div>
  <div class="control-flow-container" *ngIf="!loading">
    <button
      id="btn-calculate"
      class="btn e2g-btn-solid-primary mr-2"
      (click)="calculate()"
      *ngIf="canCalculate && !isDirty()"
      [disabled]="readOnly"
    >
      Calculate
    </button>
    <button *ngIf="isDirty()" class="btn e2g-btn-outline-primary mr-2" (click)="revertChanges()">Revert Changes</button>
    <button
      *ngIf="isDirty()"
      id="btn-save"
      class="btn e2g-btn-solid-primary"
      (click)="onSave()"
      [disabled]="!canSaveChanges()"
    >
      Save
    </button>
  </div>
</div>
<ng-template #load>
  <div class="loading"></div>
</ng-template>

<ng-template #emptyBlock>
  <div class="empty-toolbar"></div>
</ng-template>
