import { Injectable } from '@angular/core';
import { E2gSelectOption } from '@equityeng/e2g-ng-ui';
import { Observable, of } from 'rxjs';

import { getSharedRbiBusinessRules } from '../component-helper-service';
import { BusinessRulesServiceBase } from '../sage-common-module/BusinessRulesServiceBase';
import { BusinessRuleValueList } from '../sage-common-module/models/business-rule-value-list';
import { BusinessRulesDefinition } from '../sage-common-module/models/business-rules-definition';

@Injectable({
  providedIn: 'root'
})
export class TankCompBusinessRulesService extends BusinessRulesServiceBase implements BusinessRulesDefinition {
  public get valueData(): BusinessRuleValueList {
    if (this.data === undefined) {
      throw 'init must be called before valueData()';
    } else {
      return {
        ...getSharedRbiBusinessRules(this.data),
        geomType: {
          getValidValues: (): Observable<Array<E2gSelectOption>> => {
            let options: Array<E2gSelectOption> = [];
            if (this.data.compType === 'COURSE') {
              options = [...options, { value: 'CY', label: 'Course' }];
            } else if (this.data.compType === 'FLOOR') {
              options = [...options, { value: 'PW', label: 'Floor' }];
            } else if (this.data.compType === 'ROOF') {
              options = [...options, { value: 'PW', label: 'Roof' }];
            } else if (this.data.compType === 'ANNULAR PLATE') {
              options = [...options, { value: 'PW', label: 'Plate' }];
            }
            return of(options);
          },
          getRequired: () => false
        },
        innerDiameter: {
          getRequired: () => false
        },
        nominalThickness: {
          getRequired: () => true
        },
        courseHeight: {
          getRequired: () => this.data.compType === 'COURSE'
        },
        plateWidth: {
          getRequired: () => this.data.compType === 'ANNULAR PLATE'
        },
        futureCorrosionAllowance: {
          getRequired: () => true
        }
      };
    }
  }
}
