import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AppSettingsService } from './app-settings/app-settings.service';
import { UnitCopyDto } from './hierarchy-tree-module/models/unit-copy-dto';
import { InventoryGroupDto } from './models/inventory-group-dto';
import { UnitBreadcrumbsDto } from './models/unit-breadcrumbs-dto';
import { UnitDto } from './models/unit-dto';

@Injectable({
  providedIn: 'root'
})
export class UnitDataService {
  private serverUri: string;

  public constructor(private http: HttpClient, private settingsService: AppSettingsService) {
    this.serverUri = this.settingsService.settings.apiUri as string;
  }

  public addUnit(newUnit: UnitDto): Observable<string> {
    const plantId = newUnit.plantId;
    return this.http.post<string>(`${this.serverUri}/plants/${plantId}/units`, newUnit).pipe(catchError(() => of()));
  }

  public updateUnit(updateUnit: UnitDto): Observable<boolean> {
    const plantId = updateUnit.plantId;
    return this.http.put<string>(`${this.serverUri}/plants/${plantId}/units`, updateUnit).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  public deleteUnit(unitId: string, plantId: string): Observable<boolean> {
    return this.http.delete<boolean>(`${this.serverUri}/plants/${plantId}/units/${unitId}`).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  public copyUnit(plantId: string, unitId: string, name: string): Observable<boolean> {
    return this.http
      .post(`${this.serverUri}/units/copy`, { plantId: plantId, unitId: unitId, newName: name } as UnitCopyDto)
      .pipe(
        map(() => true),
        catchError(() => of(false))
      );
  }

  public getUnit(unitId: string, plantId: string): Observable<UnitDto> {
    return this.http.get<UnitDto>(`${this.serverUri}/plants/${plantId}/units/${unitId}`).pipe(catchError(() => of({})));
  }

  public getUnits(plantId: string): Observable<Array<UnitDto>> {
    return this.http
      .get<Array<UnitDto>>(`${this.serverUri}/plants/${plantId}/units`)
      .pipe(catchError(() => of(new Array<UnitDto>())));
  }

  public getInventoryGroups(unitId: string): Observable<Array<InventoryGroupDto>> {
    return this.http
      .get<Array<InventoryGroupDto>>(`${this.serverUri}/units/${unitId}/inventory-groups`)
      .pipe(catchError(() => of([])));
  }

  public updateInventoryGroups(unitId: string, inventoryGroups: Array<InventoryGroupDto>): Observable<boolean> {
    return this.http.put<string>(`${this.serverUri}/units/${unitId}/inventory-groups`, inventoryGroups).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  public getUnitBreadcrumbs(unitId: string): Observable<UnitBreadcrumbsDto> {
    return this.http
      .get<UnitBreadcrumbsDto>(`${this.serverUri}/units/${unitId}/breadcrumbs`)
      .pipe(catchError(() => of({} as UnitBreadcrumbsDto)));
  }

  public getUnitName(unitId: string): Observable<string> {
    return this.http.post<string[]>(`${this.serverUri}/units/names`, new Array<string>(unitId)).pipe(
      map((x) => x[0]),
      catchError(() => of(''))
    );
  }

  public renameUnit(id: string, name: string): Observable<boolean> {
    return this.http.put<boolean>(`${this.serverUri}/units/${id}/rename`, { id, name }).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }
}
