<div class="mx-auto pointer py-2">
  <button
    [disabled]="disabled"
    type="button"
    (click)="click()"
    class="after-loop-item card border-dark text-dark bg-light"
  >
    <div class="py-2 px-5 align-items-left text-start d-flex text-nowrap-sm">
      <fa-icon *ngIf="icon" class="icon" [icon]="icon" [ngStyle]="{ color: color }"></fa-icon>
      <div>{{ text }}</div>
    </div>
  </button>
</div>
