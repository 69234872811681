import { inject } from '@angular/core';
import { E2gSelectOption } from '@equityeng/e2g-ng-ui';
import { filter, Observable, switchMap, takeUntil, tap } from 'rxjs';
import { FindingSlideoutComponent } from 'src/app/asset-module/finding-slideout/finding-slideout.component';
import { BaseSlideoutOption } from 'src/app/asset-module/models/base-slideout-option';
import { FindingSlideoutInput } from 'src/app/asset-module/models/finding-slideout-input';
import { ActionTypes } from 'src/app/shared-module/models/action-types';
import { NEW_ITEM_KEY } from 'src/app/shared-module/models/new-item-key';
import { NotificationService } from 'src/app/shared-module/services/notification.service';
import { SlideOutContainerAction } from 'src/app/slide-out-module/models/constants/slide-out-container-action';
import { SlideOutContainerService } from 'src/app/slide-out-module/slide-out-container.service';
import { SLIDE_OUT_DATA } from 'src/app/slide-out-module/slide-out-data-injection-token';
import { SlideOutRef } from 'src/app/slide-out-module/slide-out-ref';
import { SlideOutService } from 'src/app/slide-out-module/slide-out.service';
import { StateService } from 'src/app/state.service';

import { ActivityDetailsViewModel } from '../activity-details/activity-details-vm';
import { ActivityDetailsTab } from '../activity-details/activity-details.component';
import { ActivitySlideoutInput } from '../models/activity-slideout-input';
import { ActivityDetailsDomainModel } from '../models/domain/activity-details-dm';
import { ActivityDetailsDataCache } from '../models/domain/activity-details-dm-configs';
import { ActivityDetailsDomainModelFactory, ActivityItem } from '../models/domain/activity-details-dm-factory';

export class ActivitySlideoutViewModel {
  private previousTabId?: ActivityDetailsTab;
  private dmFactory: ActivityDetailsDomainModelFactory;
  private dataCache: ActivityDetailsDataCache = {};

  private stateService = inject(StateService);
  private slideOutContainerService = inject(SlideOutContainerService);
  private slideOutService = inject(SlideOutService);
  private slideOutRef = inject(SlideOutRef);
  private slideOutData = inject<ActivitySlideoutInput>(SLIDE_OUT_DATA);
  private notificationService = inject(NotificationService);

  private domainModel?: ActivityDetailsDomainModel;

  private assetId!: string;

  public formVm?: ActivityDetailsViewModel;
  public constructor(private destroy: Observable<void>) {
    this.dmFactory = new ActivityDetailsDomainModelFactory(this.destroy);

    this.configureSlideOut();
    this.getData();
  }

  private configureSlideOut(): void {
    this.slideOutContainerService.setAllowDuplicateNames(true);

    this.slideOutContainerService.action
      .pipe(
        filter((action) => action === SlideOutContainerAction.Revert),
        takeUntil(this.destroy)
      )
      .subscribe(() => {
        this.domainModel!.revertChanges();
      });

    this.slideOutContainerService.action
      .pipe(
        filter((action) => action === SlideOutContainerAction.Save),
        switchMap(() =>
          this.saveData().pipe(
            tap((success) => {
              if (success) {
                if (this.domainModel!.isSurvey) {
                  this.invalidateSurveyCache();
                }
                this.updateHeaderDropdownOptions();
                this.slideOutContainerService.setOptionId(this.domainModel!.id);
              }
            })
          )
        ),
        takeUntil(this.destroy)
      )
      .subscribe();

    this.slideOutContainerService.action
      .pipe(
        filter((action) => action === SlideOutContainerAction.SaveAndClose),
        switchMap(() => this.saveData()),
        tap(() => this.closeSlideout()),
        takeUntil(this.destroy)
      )
      .subscribe();

    this.slideOutContainerService.action
      .pipe(
        filter((action) => action === SlideOutContainerAction.Cancel),
        takeUntil(this.destroy)
      )
      .subscribe(() => {
        this.closeSlideout();
      });

    this.slideOutContainerService.nameUpdated
      .pipe(takeUntil(this.destroy))
      .subscribe((newName) => (this.domainModel!.name = newName));
  }

  private saveData(): Observable<boolean> {
    return this.domainModel!.saveChanges().pipe(
      tap((success) => {
        this.notificationService.showActionResult(
          success,
          this.domainModel!.editing ? ActionTypes.Update : ActionTypes.Add,
          this.domainModel!.name
        );
        if (!this.domainModel!.editing && this.domainModel!.isSurvey) {
          this.slideOutRef.close(this.domainModel!.id);
        }
      })
    );
  }

  private invalidateSurveyCache(): void {
    if (this.dataCache.asset) {
      this.dataCache.asset!.surveys = undefined;
    }
  }

  private closeSlideout(): void {
    if (this.slideOutData.findingSlideoutInput) {
      this.slideOutService.open<boolean, FindingSlideoutComponent>(FindingSlideoutComponent, {
        data: this.slideOutData.findingSlideoutInput
      });
    } else {
      this.slideOutRef.close();
    }
  }

  private updateHeaderDropdownOptions(): void {
    this.slideOutData.optionsData[this.domainModel!.id] = {
      name: this.domainModel!.name,
      assetId: this.assetId,
      template: this.domainModel!.template
    };

    this.slideOutContainerService.setOptions(this.getHeaderDropdownOptions());
  }

  private getData(): void {
    this.initializeSlideoutHeader();

    this.slideOutContainerService.optionId
      .pipe(
        tap((id) => this.initializeForm(id)),
        switchMap((id) =>
          id === NEW_ITEM_KEY
            ? this.dmFactory.buildNew(
                this.slideOutData.assetId,
                this.slideOutData.initialActivityTypeId!,
                this.slideOutData.activityTemplate,
                this.dataCache
              )
            : this.dmFactory.getExisting(this.getDataFromId(id), this.dataCache)
        )
      )
      .subscribe((domainModel) => this.loadingFormComplete(domainModel));
  }

  private getDataFromId(id: string): ActivityItem {
    const data = this.slideOutData.optionsData[id];
    return {
      id: id,
      assetId: data.assetId,
      template: data.template
    };
  }

  private initializeForm(id: string): void {
    this.clearDomainAndViewModels();
    const editing = id !== NEW_ITEM_KEY;
    this.assetId = editing ? this.slideOutData.optionsData[id].assetId : this.slideOutData.assetId;

    if (this.assetId !== this.dataCache.asset?.asset.id) {
      this.dataCache.asset = undefined;
    }

    if (editing) {
      this.stateService.setSelectedGridId(this.slideOutData.gridContext!, id);
    }
  }

  private clearDomainAndViewModels(): void {
    this.domainModel = undefined;
    this.formVm = undefined;
  }

  private initializeSlideoutHeader(): void {
    this.slideOutContainerService.setOptions(this.getHeaderDropdownOptions());
    this.slideOutContainerService.setOptionId(this.slideOutData.initialActivityId);
    this.slideOutContainerService.setBreadcrumbs(this.slideOutData.breadcrumbs);
    if (this.isFromFindingsSlideout()) {
      this.slideOutContainerService.setHeaderVisible(false);
    }
  }

  private isFromFindingsSlideout(): boolean {
    return this.slideOutData.findingSlideoutInput !== undefined;
  }

  private getHeaderDropdownOptions(): Array<E2gSelectOption> {
    return Object.entries(this.slideOutData.optionsData!)
      .map(([key, value]) => ({ value: key, label: value.name }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  private loadingFormComplete(domainModel: ActivityDetailsDomainModel): void {
    this.domainModel = domainModel;
    //TODO: Update after slideout service is updated to set dirty and valid a different way
    //Subscribe in subscribe 'okay' because observables are for future event handling
    //These pipelines are not in a race condition with other pipelines in this file
    this.domainModel.dirty.pipe(tap((dirty) => this.slideOutContainerService.setDirty(dirty))).subscribe();
    this.domainModel.valid.pipe(tap((valid) => this.slideOutContainerService.setBodyValid(valid))).subscribe();

    if (this.slideOutData.currentEditData) {
      domainModel.updateData(this.slideOutData.currentEditData);
      this.slideOutData.currentEditData = undefined;
    }
    if (this.isFromFindingsSlideout()) {
      this.slideOutContainerService.setStaticHeading(domainModel.name!);
      this.slideOutContainerService.setBackButtonVisible(true);
    }

    this.formVm = new ActivityDetailsViewModel(domainModel, {
      initialTab: this.getStartingTab(),
      setTabId: this.setTabId.bind(this),
      openFindingsSlideout: this.openFindingsSlideout.bind(this),
      hideFindings: this.isFromFindingsSlideout()
    });
  }

  private openFindingsSlideout = (
    initialId: string,
    gridContext: string,
    optionsData: Record<string, BaseSlideoutOption>
  ): void => {
    this.slideOutData.initialTabId = ActivityDetailsTab.Findings;
    this.slideOutData.findingsId = initialId;
    this.slideOutData.currentEditData = this.domainModel!.dataHandler.data;
    this.slideOutData.initialActivityId = this.domainModel!.id;

    const slideoutData: FindingSlideoutInput = {
      id: initialId,
      assetId: this.assetId,
      historicalActivitySlideoutInput: this.slideOutData,
      gridContext: gridContext,
      optionsData: optionsData,
      breadcrumbs: this.slideOutData.breadcrumbs
    };

    this.slideOutService.open<boolean, FindingSlideoutComponent>(FindingSlideoutComponent, {
      data: slideoutData
    });
  };

  private getStartingTab(): ActivityDetailsTab {
    if (this.previousTabId && !(this.previousTabId == ActivityDetailsTab.Readings && !this.domainModel!.isSurvey)) {
      return this.previousTabId;
    } else if (this.slideOutData.initialTabId) {
      return this.slideOutData.initialTabId;
    } else if (this.domainModel!.isSurvey) {
      return ActivityDetailsTab.Readings;
    }

    return ActivityDetailsTab.Details;
  }

  private setTabId = (tab: ActivityDetailsTab): void => {
    this.previousTabId = tab;
  };
}
