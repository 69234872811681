<div *ngIf="!loading; else load">
  <div class="row mb-4 mt-2">
    <div class="col-6">
      <div class="card h-100">
        <h4 class="card-header mb-0">Load Ratio (Plastic Collapse)</h4>
        <div class="card-body">
          <div class="d-flex align-items-middle">
            <div class="sage-label">Primary Reference Stress :</div>
            <div
              id="primaryReferenceStress"
              class="sage-value"
              [innerHTML]="toFixedFourFormater(dataHandler.data.primaryReferenceStress!)"
            ></div>
            <div class="sage-units">{{ primaryReferenceStressUom | async }}</div>
          </div>
          <div class="d-flex align-items-middle">
            <div class="sage-label">Yield Strength :</div>
            <div
              id="yieldStrength"
              class="sage-value"
              [innerHTML]="toFixedFourFormater(dataHandler.data.yieldStrength!)"
            ></div>
            <div class="sage-units">{{ yieldStrengthUom | async }}</div>
          </div>
          <div class="d-flex align-items-middle">
            <div class="sage-label">Primary Load Ratio :</div>
            <div
              id="primaryLoadRatio"
              class="sage-value"
              [innerHTML]="toFixedFourFormater(dataHandler.data.primaryLoadRatio!)"
            ></div>
            <div class="sage-units">{{ primaryLoadRatioUom | async }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-6">
      <div class="card h-100">
        <h4 class="card-header mb-0">Toughness Ratio (Brittle Fracture)</h4>
        <div class="card-body">
          <div class="d-flex align-items-middle">
            <div class="sage-label">Total Crack Tip SIF (phi=0) :</div>
            <div
              id="totalCrackTipSifPhiZero"
              class="sage-value"
              [innerHTML]="toFixedFourFormater(dataHandler.data.totalCrackTipSifPhiZero!)"
            ></div>
            <div class="sage-units">{{ totalCrackTipSifPhiZeroUom | async }}</div>
          </div>
          <div class="d-flex align-items-middle">
            <div class="sage-label">Total Crack Tip SIF (phi=90) :</div>
            <div
              id="totalCrackTipSifPhiNinety"
              class="sage-value"
              [innerHTML]="toFixedFourFormater(dataHandler.data.totalCrackTipSifPhiNinety!)"
            ></div>
            <div class="sage-units">{{ totalCrackTipSifPhiNinetyUom | async }}</div>
          </div>
          <div class="d-flex align-items-middle">
            <div class="sage-label">Material Toughness :</div>
            <div
              id="materialToughness"
              class="sage-value"
              [innerHTML]="toFixedFourFormater(dataHandler.data.materialToughness!)"
            ></div>
            <div class="sage-units">{{ materialToughnessUom | async }}</div>
          </div>
          <div class="d-flex align-items-middle">
            <div class="sage-label">Maximum Toughness Ratio :</div>
            <div
              id="maximumToughnessRatio"
              class="sage-value"
              [innerHTML]="toFixedFourFormater(dataHandler.data.maximumToughnessRatio!)"
            ></div>
            <div class="sage-units">{{ maximumToughnessRatioUom | async }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-4">
    <div class="col-6">
      <div class="card h-100">
        <h4 class="card-header mb-0">Failure Assessment Diagram Margins</h4>
        <div class="card-body">
          <div class="d-flex align-items-middle">
            <div class="sage-label">Allowable Load Ratio :</div>
            <div
              id="allowableLoadRatio"
              class="sage-value"
              [innerHTML]="toFixedFourFormater(dataHandler.data.allowableLoadRatio!)"
            ></div>
            <div class="sage-units">{{ allowableLoadRatioUom | async }}</div>
          </div>
          <div class="d-flex align-items-middle">
            <div class="sage-label">Allowable Toughness Ratio :</div>
            <div
              id="allowableToughnessRatio"
              class="sage-value"
              [innerHTML]="toFixedFourFormater(dataHandler.data.allowableToughnessRatio!)"
            ></div>
            <div class="sage-units">{{ allowableToughnessRatioUom | async }}</div>
          </div>
          <div class="d-flex align-items-middle">
            <div class="sage-label">Plastic Collapse Margin :</div>
            <div
              id="plasticCollapseMargin"
              class="sage-value"
              [innerHTML]="toFixedFourFormater(dataHandler.data.plasticCollapseMargin!)"
            ></div>
            <div class="sage-units">{{ plasticCollapseMarginUom | async }}</div>
          </div>
          <div class="d-flex align-items-middle">
            <div class="sage-label">Brittle Fracture Margin :</div>
            <div
              id="brittleFractureMargin"
              class="sage-value"
              [innerHTML]="toFixedFourFormater(dataHandler.data.brittleFractureMargin!)"
            ></div>
            <div class="sage-units">{{ brittleFractureMarginUom | async }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-6">
      <div class="card h-100">
        <h4 class="card-header mb-0">Failure Assessment Diagram Messages</h4>
        <div class="card-body">
          <ng-container *ngFor="let msg of dataHandler.data.failureAssessmentDiagramMessages">
            <p [innerHTML]="parseMarkdown(msg)"></p>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-4">
    <div class="col-6">
      <div class="card h-100">
        <h4 class="card-header mb-0">Crack Sensitivity Margins</h4>
        <div class="card-body">
          <div class="d-flex align-items-middle">
            <div class="sage-label">Maximum Crack Depth :</div>
            <div
              id="maximumCrackDepth"
              class="sage-value"
              [innerHTML]="toFixedFourFormater(dataHandler.data.maximumCrackDepth!)"
            ></div>
            <div class="sage-units">{{ maximumCrackDepthUom | async }}</div>
          </div>
          <div class="d-flex align-items-middle">
            <div class="sage-label">Maximum Crack Length :</div>
            <div
              id="maximumCrackLength"
              class="sage-value"
              [innerHTML]="toFixedFourFormater(dataHandler.data.maximumCrackLength!)"
            ></div>
            <div class="sage-units">{{ maximumCrackLengthUom | async }}</div>
          </div>
          <div class="d-flex align-items-middle">
            <div class="sage-label">Crack Depth Margin :</div>
            <div
              id="crackDepthMargin"
              class="sage-value"
              [innerHTML]="toFixedFourFormater(dataHandler.data.crackDepthMargin!)"
            ></div>
            <div class="sage-units">{{ crackDepthMarginUom | async }}</div>
          </div>
          <div class="d-flex align-items-middle">
            <div class="sage-label">Crack Length Margin :</div>
            <div
              id="crackLengthMargin"
              class="sage-value"
              [innerHTML]="toFixedFourFormater(dataHandler.data.crackLengthMargin!)"
            ></div>
            <div class="sage-units">{{ crackLengthMarginUom | async }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-6">
      <div class="card h-100">
        <h4 class="card-header mb-0">Crack Sensitivity Messages</h4>
        <div class="card-body">
          <ul class="mb-0 p-0 pl-3">
            <li *ngFor="let item of dataHandler.data.crackSensitivityMessages!">{{ item }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-4">
    <div class="col-12">
      <div class="card h-100">
        <h4 class="card-header mb-0">Crack-Like Flaw Applicability and Limitations</h4>
        <div class="card-body">
          <ul class="mb-0 p-0 pl-3">
            <li *ngFor="let item of dataHandler.data.crackLikeFlawApplicabilityAndLimitationsMessages!">{{ item }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #load>
  <div class="loading"></div>
</ng-template>
