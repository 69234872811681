import { Component, Input } from '@angular/core';
import { ICellRendererParams } from 'ag-grid-community';

import { GridCellLinkParams } from '../grid-cell-link/grid-cell-link-params';

@Component({
  selector: 'app-grid-cell-link-label',
  templateUrl: './grid-cell-link-label.component.html',
  styleUrls: ['./grid-cell-link-label.component.css']
})
export class GridCellLinkLabelComponent {
  public isLink: boolean = false;
  public cellParams!: ICellRendererParams;
  private linkCommand?: (params: ICellRendererParams) => void;

  @Input() public label: string = '';
  @Input() public set params(value: GridCellLinkParams & ICellRendererParams) {
    this.cellParams = value;
    const isLinkResult = value.isLink !== undefined ? value.isLink(this.params) : false;

    if (typeof isLinkResult == 'boolean') {
      this.isLink = isLinkResult;
    } else {
      isLinkResult.subscribe((x) => (this.isLink = x));
    }

    this.linkCommand = value.linkCommand;
  }

  public command(): void {
    if (this.linkCommand !== undefined) {
      this.linkCommand(this.cellParams);
    }
  }
}
