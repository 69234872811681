import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { DmlDetailSummaryBaseComponent } from 'src/app/dmls-module/dml-slideout/dml-detail-summary-base-component';

import { DmlPittingOutputDto } from '../../models/dml-pitting-output-dto';
import { DmlTypes } from '../../models/Enums/dml-types';

@Component({
  selector: 'app-dml-pitting-summary',
  templateUrl: './dml-pitting-summary.component.html',
  styleUrls: ['./dml-pitting-summary.component.css']
})
export class DmlPittingSummaryComponent extends DmlDetailSummaryBaseComponent<DmlPittingOutputDto> {
  protected dmlType = DmlTypes['Pitting'];

  public corrodedMinimumMeasuredThicknessUom: Observable<string> = this.uomEvaluator.getUnits(
    'DmlPitting_CorrodedMinimumMeasuredThickness'
  );
  public thicknessLimitStructuralUom: Observable<string> = this.uomEvaluator.getUnits(
    'DmlPitting_ThicknessLimitStructural'
  );
  public remainingThicknessRatioUom: Observable<string> = this.uomEvaluator.getUnits(
    'DmlPitting_RemainingThicknessRatio'
  );
  public minimumRemainingThicknessRatioUom: Observable<string> = this.uomEvaluator.getUnits(
    'DmlPitting_MinimumRemainingThicknessRatio'
  );
  public maximumPitDiameterUom: Observable<string> = this.uomEvaluator.getUnits('DmlPitting_MaximumPitDiameter');
  public maximumAllowablePitDiameterUom: Observable<string> = this.uomEvaluator.getUnits(
    'DmlPitting_MaximumAllowablePitDiameter'
  );
  public designPressureUom: Observable<string> = this.uomEvaluator.getUnits('DmlPitting_DesignPressure');
  public undamagedMawpUom: Observable<string> = this.uomEvaluator.getUnits('DmlPitting_UndamagedMawp');
  public damagedMawpUom: Observable<string> = this.uomEvaluator.getUnits('DmlPitting_DamagedMawp');

  public constructor() {
    super();
  }

  protected displaySummary(output: DmlPittingOutputDto): void {
    if (output.pittingAssessmentMessages !== undefined) {
      output.pittingAssessmentMessages = this.formatSummarySection(output.pittingAssessmentMessages);
    }

    if (output.pittingApplicabilityAndLimitationsMessages !== undefined) {
      output.pittingApplicabilityAndLimitationsMessages = this.formatSummarySection(
        output.pittingApplicabilityAndLimitationsMessages
      );
    }
  }
}
