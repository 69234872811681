import { ComponentRecordDto } from 'src/app/models/component-record-dto';
import { ComponentStatuses } from 'src/app/models/enums/component-statuses';
import { EquipmentConfigurationDto } from 'src/app/models/equipment-configuration-dto';
import { EquipmentDto } from 'src/app/models/equipment-dto';

export function setupNewComponent(
  asset: EquipmentDto,
  assetConfig: EquipmentConfigurationDto,
  componentType: string,
  includeTminProperties: boolean,
  includeRbiProperties: boolean
): ComponentRecordDto {
  const component = getDefaultComponent();
  setComponentDefaultProperties(component, includeTminProperties, includeRbiProperties);

  component.assetId = asset.id;
  component.assetType = asset.type;
  component.compType = componentType;
  component.eqRbi = assetConfig.rbi;

  if (includeTminProperties) {
    component.isRetirementThickness = assetConfig.isRetirementThickness;
    component.isStructuralRequiredThickness = assetConfig.isStructuralRequiredThickness;
    component.isCorrodedThickness = assetConfig.isCorrodedThickness;
    component.isSpecifiedThickness = assetConfig.isSpecifiedThickness;
    component.useMawpApproach = assetConfig.useMawpApproach;
  }

  if (includeRbiProperties) {
    component.rbi = assetConfig.rbi;
  }

  component.specifiedMinimumThickness = assetConfig.specifiedMinimumThickness;

  component.designCode = asset.designCode;
  component.eqDesignCode = asset.designCode;

  component.inServiceDate = asset.inServiceDate;
  component.eqInServiceDate = asset.inServiceDate;

  component.designPressure = asset.designPressure;
  component.designTemperature = asset.designTemperature;

  component.eqDesignPressure = asset.designPressure;
  component.eqDesignTemperature = asset.designTemperature;

  component.eqUseMawpApproach = assetConfig.useMawpApproach;

  return component;
}

export function getDefaultComponent(): ComponentRecordDto {
  return {
    name: '',
    eqRbi: false,
    rbi: false,
    nonStandardTube: false,
    isRetirementThickness: false,
    isStructuralRequiredThickness: false,
    isCorrodedThickness: false,
    isSpecifiedThickness: false,
    isMinimumThicknessOverridden: false,
    clad: false,
    status: ComponentStatuses.Active,
    useAdvancedCalculation: false
  } as ComponentRecordDto;
}

export function setComponentDefaultProperties(
  component: ComponentRecordDto,
  includeTminProperties: boolean,
  includeRbiProperties: boolean
): void {
  component.defaultProperties = [];

  component.defaultProperties.push('designCode');
  component.defaultProperties.push('inServiceDate');
  component.defaultProperties.push('designPressure');
  component.defaultProperties.push('designTemperature');

  if (includeTminProperties) {
    component.defaultProperties.push('governingMinimumThickness');
    component.defaultProperties.push('useMawpApproach');
  }
  if (includeRbiProperties) {
    component.defaultProperties.push('rbi');
  }
  component.defaultProperties.push('specifiedMinimumThickness');
}
