import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

import { IGridCellRemainingLifeParams } from './grid-cell-remaining-life-params';

@Component({
  selector: 'app-grid-cell-remaining-life',
  templateUrl: './grid-cell-remaining-life.component.html',
  styleUrls: ['./grid-cell-remaining-life.component.css']
})
export class GridCellRemainingLifeComponent implements ICellRendererAngularComp {
  public id: string = '';
  public remainingLifeText: string = '';
  public remainingLifeColor: string = '';
  public textColor: string = 'var(--interactive-fg-primary-inverse)';

  public agInit(params: ICellRendererParams & IGridCellRemainingLifeParams): void {
    this.setParams(params);
  }

  public refresh(params: ICellRendererParams & IGridCellRemainingLifeParams): boolean {
    this.setParams(params);
    return true;
  }

  private setParams(params: ICellRendererParams & IGridCellRemainingLifeParams): void {
    this.id = params.data.itemId;
    this.remainingLifeText = params.remainingLifeText(params);
    this.remainingLifeColor = params.remainingLifeColor(params);

    if (params.textColor) {
      this.textColor = params.textColor!(params);
    }
  }
}
