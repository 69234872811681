import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { E2gSelectOption } from '@equityeng/e2g-ng-ui';
import { Observable } from 'rxjs';
import { DmlDetailBaseComponent } from 'src/app/dmls-module/dml-slideout/dml-detail-base-component';
import { DmlGridDataDto } from 'src/app/dmls-module/models/dml-grid-data-dto';
import { DmlThinningDto } from 'src/app/dmls-module/models/dml-thinning-dto';
import { DmlAssessmentLevels } from 'src/app/dmls-module/models/Enums/dml-assessment-levels';
import { DmlDamageLocations } from 'src/app/dmls-module/models/Enums/dml-damage-locations';
import { DmlGridOrientations } from 'src/app/dmls-module/models/Enums/dml-grid-orientations';
import { DmlStatuses } from 'src/app/dmls-module/models/Enums/dml-statuses';
import { DmlTypes } from 'src/app/dmls-module/models/Enums/dml-types';
import { SLIDE_OUT_DATA } from 'src/app/slide-out-module/slide-out-data-injection-token';
import { enumToSelectOptions } from 'src/app/utilities/enum-helper';

import { DmlSlideoutInput } from '../../models/dml-slideout-input';

@Component({
  selector: 'app-dml-thinning',
  templateUrl: './dml-thinning.component.html'
})
export class DmlThinningComponent extends DmlDetailBaseComponent<DmlThinningDto> {
  @Input() public dmlType!: DmlTypes;
  @Output() public showUpload = new EventEmitter<boolean>();
  public componentsShellModuleOnly = false;

  public damageLocationOptions: Array<E2gSelectOption> = enumToSelectOptions(DmlDamageLocations);
  public gridOrientationOptions: Array<E2gSelectOption> = enumToSelectOptions(DmlGridOrientations);

  private damageMonitoringLocationThinningDefaults: DmlThinningDto = {} as DmlThinningDto;

  public undamagedRegionFcaUom: Observable<string> = this.uomEvaluator.getUnits(
    'DmlLocalThinArea_DmlGeneralThinning_UndamagedRegionFca'
  );

  public damagedRegionFcaUom: Observable<string> = this.uomEvaluator.getUnits(
    'DmlLocalThinArea_DmlGeneralThinning_DamagedRegionFca'
  );

  public undamagedRegionCorrosionRateUom: Observable<string> = this.uomEvaluator.getUnits(
    'DmlLocalThinArea_DmlGeneralThinning_UndamagedRegionCorrosionRate'
  );

  public damagedRegionCorrosionRateUom: Observable<string> = this.uomEvaluator.getUnits(
    'DmlLocalThinArea_DmlGeneralThinning_DamagedRegionCorrosionRate'
  );

  public gridSpacingBetweenCPlanesUom: Observable<string> = this.uomEvaluator.getUnits(
    'DmlLocalThinArea_DmlGeneralThinning_GridSpacingBetweenCPlanes'
  );

  public gridSpacingBetweenMPlanesUom: Observable<string> = this.uomEvaluator.getUnits(
    'DmlLocalThinArea_DmlGeneralThinning_GridSpacingBetweenMPlanes'
  );

  public constructor(@Inject(SLIDE_OUT_DATA) public slideOutData: DmlSlideoutInput) {
    super(slideOutData);

    this.configDmlService();
    this.dmlService.clear();
  }

  public afterGetDml(): void {
    if (this.dataHandler.data.componentId !== undefined) {
      this.forComponentSetDefaultProperties(this.dataHandler.data.componentId!);
    }

    this.setDefaults();

    this.dataHandler.promoteChanges();

    if (this.dataHandler.data.componentId !== undefined) {
      this.showUpload.emit(true);
    }
  }

  public configDmlService(): void {
    this.dmlService.retrieveDmlData.subscribe(() => {
      if (this.dataHandler.initialized) {
        this.dmlService.setDmlData({
          ...this.dataHandler.data,
          defaultProperties: this.dataHandler.getDefaultPropNames()
        });
      }
    });

    this.dmlService.revertDataChanges.subscribe(() => {
      if (this.dataHandler.initialized) {
        this.dataHandler.revertChanges();
      }
    });

    this.dmlService.uploadGridData.subscribe((data: DmlGridDataDto | undefined) => {
      if (data !== undefined) {
        this.uploadGridData(data!);
      }
    });
  }

  private setDefaults(): void {
    if (this.dataHandler.data.id === undefined) {
      this.dataHandler.data.status = DmlStatuses.New;

      if (this.dataHandler.data.assessmentLevel === undefined) {
        this.dataHandler.silentClearValue('assessmentLevel');
        this.dataHandler.silentSetValue(
          'assessmentLevel',
          this.damageMonitoringLocationThinningDefaults.assessmentLevel,
          true
        );
      }

      if (this.dataHandler.data.damageLocation === undefined) {
        this.dataHandler.silentClearValue('damageLocation');
        this.dataHandler.silentSetValue(
          'damageLocation',
          this.damageMonitoringLocationThinningDefaults.damageLocation,
          true
        );
      }

      if (this.dataHandler.data.gridOrientation === undefined) {
        this.dataHandler.silentClearValue('gridOrientation');
        this.dataHandler.silentSetValue(
          'gridOrientation',
          this.damageMonitoringLocationThinningDefaults.gridOrientation,
          true
        );
      }

      if (this.dataHandler.data.gridSpacingBetweenCPlanes === undefined) {
        this.dataHandler.silentClearValue('gridSpacingBetweenCPlanes');
        this.dataHandler.silentSetValue(
          'gridSpacingBetweenCPlanes',
          this.damageMonitoringLocationThinningDefaults.gridSpacingBetweenCPlanes,
          true
        );
      }

      if (this.dataHandler.data.gridSpacingBetweenMPlanes === undefined) {
        this.dataHandler.silentClearValue('gridSpacingBetweenMPlanes');
        this.dataHandler.silentSetValue(
          'gridSpacingBetweenMPlanes',
          this.damageMonitoringLocationThinningDefaults.gridSpacingBetweenMPlanes,
          true
        );
      }
    }
  }

  public setDefaultProperties(): void {
    this.damageMonitoringLocationThinningDefaults = {
      name: '',
      assessmentLevel: DmlAssessmentLevels['Level 1'],
      damageLocation: DmlDamageLocations.Internal,
      undamagedRegionFca: '0',
      damagedRegionFca: '0',
      undamagedRegionCorrosionRate: parseFloat('0').toFixed(3).toString(),
      damagedRegionCorrosionRate: parseFloat('0').toFixed(3).toString(),
      gridOrientation: DmlGridOrientations.Longitudinal,
      gridSpacingBetweenCPlanes: '0',
      gridSpacingBetweenMPlanes: '0'
    } as DmlThinningDto;
  }

  public reset(propName: string): void {
    this.dataHandler.silentClearValue(propName);

    switch (propName) {
      case 'assessmentLevel':
        this.dataHandler.silentSetValue(propName, this.damageMonitoringLocationThinningDefaults.assessmentLevel, true);
        break;
      case 'damageLocation':
        this.dataHandler.silentSetValue(propName, this.damageMonitoringLocationThinningDefaults.damageLocation, true);
        break;
      case 'gridOrientation':
        this.dataHandler.silentSetValue(propName, this.damageMonitoringLocationThinningDefaults.gridOrientation, true);
        break;
      case 'undamagedRegionFca':
        this.dataHandler.silentSetValue(
          propName,
          this.damageMonitoringLocationThinningDefaults.undamagedRegionFca,
          true
        );
        break;
      case 'damagedRegionFca':
        this.dataHandler.silentSetValue(propName, this.damageMonitoringLocationThinningDefaults.damagedRegionFca, true);
        break;
      case 'undamagedRegionCorrosionRate':
        this.dataHandler.silentSetValue(
          propName,
          parseFloat(this.damageMonitoringLocationThinningDefaults.undamagedRegionCorrosionRate!).toFixed(3).toString(),
          true
        );
        break;
      case 'damagedRegionCorrosionRate':
        this.dataHandler.silentSetValue(
          propName,
          parseFloat(this.damageMonitoringLocationThinningDefaults.damagedRegionCorrosionRate!).toFixed(3).toString(),
          true
        );
        break;
      case 'gridSpacingBetweenCPlanes':
        this.dataHandler.silentSetValue(
          propName,
          this.damageMonitoringLocationThinningDefaults.gridSpacingBetweenCPlanes,
          true
        );
        break;
      case 'gridSpacingBetweenMPlanes':
        this.dataHandler.silentSetValue(
          propName,
          this.damageMonitoringLocationThinningDefaults.gridSpacingBetweenMPlanes,
          true
        );
        break;
    }
  }

  public forComponentSetDefaultProperties(componentId: string): void {
    const component = this.comps.find((x) => x.id === componentId);

    if (component !== undefined) {
      if (component?.corrosionAllowance !== undefined) {
        this.damageMonitoringLocationThinningDefaults.undamagedRegionFca = component.corrosionAllowance.toString();
        this.damageMonitoringLocationThinningDefaults.damagedRegionFca = component.corrosionAllowance.toString();
      } else {
        this.damageMonitoringLocationThinningDefaults.undamagedRegionFca = '0';
        this.damageMonitoringLocationThinningDefaults.damagedRegionFca = '0';
      }

      if (this.dataHandler.isDefaultValue('undamagedRegionFca')) {
        this.dataHandler.silentClearValue('undamagedRegionFca');
        this.dataHandler.silentSetValue(
          'undamagedRegionFca',
          this.damageMonitoringLocationThinningDefaults.undamagedRegionFca,
          true
        );
      }

      if (this.dataHandler.isDefaultValue('damagedRegionFca')) {
        this.dataHandler.silentClearValue('damagedRegionFca');
        this.dataHandler.silentSetValue(
          'damagedRegionFca',
          this.damageMonitoringLocationThinningDefaults.damagedRegionFca,
          true
        );
      }

      if (component?.governingRate !== undefined) {
        this.damageMonitoringLocationThinningDefaults.undamagedRegionCorrosionRate = component.governingRate;
        this.damageMonitoringLocationThinningDefaults.damagedRegionCorrosionRate = component.governingRate;
      } else {
        this.damageMonitoringLocationThinningDefaults.undamagedRegionCorrosionRate = '0';
        this.damageMonitoringLocationThinningDefaults.damagedRegionCorrosionRate = '0';
      }

      if (this.dataHandler.isDefaultValue('undamagedRegionCorrosionRate')) {
        this.dataHandler.silentClearValue('undamagedRegionCorrosionRate');
        this.dataHandler.silentSetValue(
          'undamagedRegionCorrosionRate',
          parseFloat(this.damageMonitoringLocationThinningDefaults.undamagedRegionCorrosionRate!).toFixed(3).toString(),
          true
        );
      } else {
        this.dataHandler.data.undamagedRegionCorrosionRate = parseFloat(
          this.dataHandler.data.undamagedRegionCorrosionRate!
        )
          .toFixed(3)
          .toString();
      }

      if (this.dataHandler.isDefaultValue('damagedRegionCorrosionRate')) {
        this.dataHandler.silentClearValue('damagedRegionCorrosionRate');
        this.dataHandler.silentSetValue(
          'damagedRegionCorrosionRate',
          parseFloat(this.damageMonitoringLocationThinningDefaults.damagedRegionCorrosionRate!).toFixed(3).toString(),
          true
        );
      } else {
        this.dataHandler.data.damagedRegionCorrosionRate = parseFloat(this.dataHandler.data.damagedRegionCorrosionRate!)
          .toFixed(3)
          .toString();
      }
    }
  }

  public showResetAssessmentLevel(): boolean {
    return (
      this.damageMonitoringLocationThinningDefaults.assessmentLevel !== this.dataHandler.data.assessmentLevel &&
      !this.dataHandler.isDefaultValue('assessmentLevel')
    );
  }

  public showResetDamageLocation(): boolean {
    return (
      this.damageMonitoringLocationThinningDefaults.damageLocation !== this.dataHandler.data.damageLocation &&
      !this.dataHandler.isDefaultValue('damageLocation')
    );
  }

  public showResetUndamagedRegionFca(): boolean {
    return (
      this.damageMonitoringLocationThinningDefaults.undamagedRegionFca !== this.dataHandler.data.undamagedRegionFca &&
      !this.dataHandler.isDefaultValue('undamagedRegionFca')
    );
  }

  public showResetDamagedRegionFca(): boolean {
    return (
      this.damageMonitoringLocationThinningDefaults.damagedRegionFca !== this.dataHandler.data.damagedRegionFca &&
      !this.dataHandler.isDefaultValue('damagedRegionFca')
    );
  }

  public showResetUndamagedRegionCorrosionRate(): boolean {
    return (
      this.damageMonitoringLocationThinningDefaults.undamagedRegionCorrosionRate !==
        this.dataHandler.data.undamagedRegionCorrosionRate &&
      !this.dataHandler.isDefaultValue('undamagedRegionCorrosionRate')
    );
  }

  public showResetDamagedRegionCorrosionRate(): boolean {
    return (
      this.damageMonitoringLocationThinningDefaults.damagedRegionCorrosionRate !==
        this.dataHandler.data.damagedRegionCorrosionRate &&
      !this.dataHandler.isDefaultValue('damagedRegionCorrosionRate')
    );
  }

  public showResetGridOrientation(): boolean {
    return (
      this.damageMonitoringLocationThinningDefaults.gridOrientation !== this.dataHandler.data.gridOrientation &&
      !this.dataHandler.isDefaultValue('gridOrientation')
    );
  }

  public showResetGridSpacingBetweenCPlanes(): boolean {
    return (
      this.damageMonitoringLocationThinningDefaults.gridSpacingBetweenCPlanes !==
        this.dataHandler.data.gridSpacingBetweenCPlanes && !this.dataHandler.isDefaultValue('gridSpacingBetweenCPlanes')
    );
  }

  public showResetGridSpacingBetweenMPlanes(): boolean {
    return (
      this.damageMonitoringLocationThinningDefaults.gridSpacingBetweenMPlanes !==
        this.dataHandler.data.gridSpacingBetweenMPlanes && !this.dataHandler.isDefaultValue('gridSpacingBetweenMPlanes')
    );
  }

  public uploadGridData(gridDataInfo: DmlGridDataDto): void {
    if (gridDataInfo !== undefined) {
      this.dataHandler.data.grid = {
        fileName: gridDataInfo.fileName,
        data: gridDataInfo.data
      };
    }
  }

  public componentChange(componentId: string): void {
    this.forComponentSetDefaultProperties(componentId);

    this.slideOutService.setBodyValid(true);

    this.showUpload.emit(true);
  }

  public makeNewDml(): DmlThinningDto {
    return {
      id: undefined,
      name: undefined,
      type: this.dmlType,
      status: DmlStatuses.New,
      assessmentLevel: DmlAssessmentLevels['Level 1'],
      damageLocation: DmlDamageLocations.Internal,
      hasCalculationErrors: false,
      grid: {} as DmlGridDataDto,
      fileId: undefined,
      fileName: undefined,
      defaultProperties: [
        'assessmentLevel',
        'damageLocation',
        'gridOrientation',
        'undamagedRegionFca',
        'damagedRegionFca',
        'undamagedRegionCorrosionRate',
        'damagedRegionCorrosionRate',
        'gridSpacingBetweenCPlanes',
        'gridSpacingBetweenMPlanes'
      ],
      undamagedRegionFca: '0',
      damagedRegionFca: '0',
      undamagedRegionCorrosionRate: parseFloat('0').toFixed(3).toString(),
      damagedRegionCorrosionRate: parseFloat('0').toFixed(3).toString(),
      gridOrientation: DmlGridOrientations.Longitudinal,
      gridSpacingBetweenCPlanes: '0',
      gridSpacingBetweenMPlanes: '0',
      evaluationDate: undefined
    };
  }
}
