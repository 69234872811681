import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of } from 'rxjs';

import { AppSettingsService } from '../app-settings/app-settings.service';

@Injectable({
  providedIn: 'root'
})
export class UnitsOfMeasureDataService {
  public constructor(private http: HttpClient, private settingsService: AppSettingsService) {}

  public getUnitsOfMeasure(): Observable<Record<string, string[]>> {
    return this.http
      .get<Record<string, string[]>>(`${this.settingsService.settings.apiUri}/values/unitsOfMeasure`)
      .pipe(catchError(() => of({})));
  }
}
