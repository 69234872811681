<div class="w-100 h-100">
  <div class="row">
    <div class="col-6">
      <e2g-date-input
        heading="Start Date"
        id="inp-startDate"
        [required]="true"
        placeholder="mm/dd/yyyy"
        [(ngModel)]="startDate"
        [errors]="startDateErrors"
      ></e2g-date-input>
    </div>

    <div class="col-6">
      <e2g-date-input
        heading="End Date"
        id="inp-endDate"
        [required]="true"
        placeholder="mm/dd/yyyy"
        [(ngModel)]="endDate"
        [errors]="endDateErrors"
      ></e2g-date-input>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col-12">
      <e2g-multi-select-input
        heading="Users"
        placeholder="Select Users"
        id="select-users"
        [(ngModel)]="dataHandler.data.selectedUserEmails"
        [isLoading]="loading"
        [options]="userData!"
      >
      </e2g-multi-select-input>
    </div>
  </div>
</div>
