import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IdNameDto } from 'src/app/asset-module/models/id-name-dto';

import { AppSettingsService } from './app-settings/app-settings.service';
import { GroupDetailsDto } from './asset-module/models/group-details-dto';
import { CircuitDescriptorDto, CircuitDto } from './models/circuit-dto';

@Injectable({
  providedIn: 'root'
})
export class CircuitDataService {
  private serverUri: string;

  public constructor(private http: HttpClient, private settingsService: AppSettingsService) {
    this.serverUri = this.settingsService.settings.apiUri as string;
  }

  public getCircuits(assetId: string): Observable<Array<GroupDetailsDto>> {
    return this.http
      .get<Array<GroupDetailsDto>>(`${this.serverUri}/circuits/assets/${assetId}`)
      .pipe(catchError(() => of(new Array<GroupDetailsDto>())));
  }

  public deleteCircuit(circuitId: string): Observable<boolean> {
    return this.http.delete(`${this.serverUri}/circuits/${circuitId}`).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  public getCircuit(circuitId: string): Observable<CircuitDto> {
    return this.http
      .get<CircuitDto>(`${this.serverUri}/circuits/${circuitId}`)
      .pipe(catchError(() => of({} as CircuitDto)));
  }

  public getCircuitNamesInUnit(unitId: string): Observable<Array<CircuitDescriptorDto>> {
    return this.http
      .get<Array<CircuitDescriptorDto>>(`${this.serverUri}/circuits/units/${unitId}/circuits/names`)
      .pipe(catchError(() => of(new Array<CircuitDescriptorDto>())));
  }

  public getCircuitNamesInAsset(assetId: string): Observable<Array<IdNameDto>> {
    return this.http
      .get<Array<IdNameDto>>(`${this.serverUri}/circuits/assets/${assetId}/circuits/names`)
      .pipe(catchError(() => of(new Array<IdNameDto>())));
  }

  public saveCircuit(circuit: CircuitDto): Observable<boolean | string> {
    if (circuit.id !== undefined) {
      if (circuit.internalThinningType === '') {
        circuit.internalThinningType = null;
      }

      // This is an update since we have the circuit Id.
      return this.http.put(`${this.serverUri}/circuits`, circuit).pipe(
        map(() => true),
        catchError(() => of(false))
      );
    } else {
      // This is an add since we have the circuit Id.
      return this.http.post<string>(`${this.serverUri}/circuits`, circuit).pipe(
        map((x: string) => x),
        catchError(() => of(false))
      );
    }
  }
}
