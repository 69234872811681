import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppSettingsService } from 'src/app/app-settings/app-settings.service';
import { AssetTypes } from 'src/app/models/enums/asset-types';
import { TenantSettingsDto } from 'src/app/settings-module/e2g-only/models/tenant-settings-dto';

@Injectable({
  providedIn: 'root'
})
export class HierarchyPermissionDataService {
  public constructor(private http: HttpClient, private settingsService: AppSettingsService) {}

  public isTenantUsingHierarchyPermissions(): Observable<boolean> {
    return this.http
      .get<TenantSettingsDto>(`${this.settingsService.settings.apiUri}/initialization/settings`)
      .pipe(map((x) => x.useHierarchyPermissions));
  }

  public hasAccessToAsset(assetId: string): Observable<boolean> {
    return this.http
      .get<boolean>(`${this.settingsService.settings.apiUri}/permissions/hierarchy/assets/${assetId}`)
      .pipe(catchError(() => of(false)));
  }

  public getAllowedAssetTypeList(): Observable<Array<AssetTypes>> {
    return this.http
      .get<Array<AssetTypes>>(`${this.settingsService.settings.apiUri}/permissions/hierarchy/assetTypes`)
      .pipe(catchError(() => of([])));
  }
}
