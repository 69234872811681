import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@equityeng/auth';
import { dateOnly, DateOnlyUtility } from '@equityeng/e2g-ng-ui';
import { E2gSelectOption } from '@equityeng/e2g-ng-ui/lib/e2g-select-option';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { combineLatest, filter, map, ReplaySubject, startWith, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { ComponentSlideoutInput } from 'src/app/asset-module/models/component-slideout-input';
import { ActivityEffectiveness } from 'src/app/asset-module/models/enums/activity-effectiveness';
import { CompDataService } from 'src/app/comp-data.service';
import { CompanyService } from 'src/app/company.service';
import { SAGE } from 'src/app/models/auth-constants';
import { ComponentRbiInspectionPlanDto } from 'src/app/models/component-rbi-inspection-plan-dto';
import { FluidStreamDto } from 'src/app/models/fluid-stream-dto';
import { SingleDataHandlerDefault } from 'src/app/shared-module/single-data-handler-default';
import { SLIDE_OUT_DATA } from 'src/app/slide-out-module/slide-out-data-injection-token';
import { SlideOutRef } from 'src/app/slide-out-module/slide-out-ref';
import { UnitsOfMeasureEvaluator } from 'src/app/units-of-measure/units-of-measure-evaluator';
import { makeSageModuleRouteString } from 'src/app/utilities/sage-module-route-helper';

import { FluidStreamDataService } from '../../fluid-data.service';
import { ComponentRecordDto } from '../../models/component-record-dto';
import { OnDestroyBaseComponent } from '../../on-destroy-base-component/on-destroy-base-component';
import { AVERAGE_VALUES } from '../../utilities/hardcoded-lists';
import { BUSINESS_RULES } from '../business-rules-injection-token';
import { BusinessRulesDefinition } from '../models/business-rules-definition';

@Component({
  selector: 'app-comp-rbi',
  templateUrl: './comp-rbi.component.html',
  styleUrls: ['./comp-rbi.component.css']
})
export class CompRbiComponent extends OnDestroyBaseComponent implements OnInit {
  @Input() public compDataHandler!: SingleDataHandlerDefault<ComponentRecordDto>;
  @Input() public componentId?: string;
  @Input() public readOnly: boolean = true;
  @Output() public rbiChange = new EventEmitter<boolean>();
  @Input() public rbiActiveTabId!: number;
  @Output() public rbiActiveTabIdChange = new EventEmitter<number>();

  private calcRefresh: Subject<void> = new Subject<void>();

  public selectedStream: FluidStreamDto | undefined;
  public streamValid: boolean = false;
  public prevActiveTabId?: number;
  public inspDataHandler: SingleDataHandlerDefault<ComponentRbiInspectionPlanDto>;
  public averageList: Array<E2gSelectOption> = AVERAGE_VALUES;

  public gffTypeTrigger = new ReplaySubject<void>(1);

  public areaUnits: string = '';
  public riskUnits: string = '';

  public canViewSageModule = this.companyService.selectedCompany.pipe(
    switchMap(() => this.authService.userAuthenticated$),
    map((user) => user.hasAccessToProduct(SAGE))
  );

  public constructor(
    private authService: AuthService,
    private companyService: CompanyService,
    private fluidService: FluidStreamDataService,
    private compDataService: CompDataService,
    private uomEvaluator: UnitsOfMeasureEvaluator,
    private router: Router,
    private slideOutRef: SlideOutRef,
    @Inject(SLIDE_OUT_DATA) private slideOutData: ComponentSlideoutInput,

    @Inject(BUSINESS_RULES) private businessRulesService: BusinessRulesDefinition
  ) {
    super();
    this.inspDataHandler = new SingleDataHandlerDefault<ComponentRbiInspectionPlanDto>(this.destroy);
  }

  public refreshCalc(): void {
    this.calcRefresh.next();
  }

  public recommendations = [
    {
      gradeProp: 'recommendedThinningGrade' as keyof ComponentRbiInspectionPlanDto,
      dateProp: 'recommendedThinningDate' as keyof ComponentRbiInspectionPlanDto
    },
    {
      gradeProp: 'recommendedExternalGrade' as keyof ComponentRbiInspectionPlanDto,
      dateProp: 'recommendedExternalDate' as keyof ComponentRbiInspectionPlanDto
    },
    {
      gradeProp: 'recommendedCrackingGrade' as keyof ComponentRbiInspectionPlanDto,
      dateProp: 'recommendedCrackingDate' as keyof ComponentRbiInspectionPlanDto
    }
  ];

  public detailedSections = [
    {
      riskKeys: ['thinningRiskRbiDate', 'externalRiskRbiDate', 'crackingRiskRbiDate', 'totalRiskRbiDate'] as Array<
        keyof ComponentRbiInspectionPlanDto
      >,
      dfKeys: ['thinningDfRbiDate', 'externalDfRbiDate', 'crackingDfRbiDate', 'totalDfRbiDate'] as Array<
        keyof ComponentRbiInspectionPlanDto
      >
    },
    {
      riskKeys: [
        'thinningRiskTargetDate',
        'externalRiskTargetDate',
        'crackingRiskTargetDate',
        'totalRiskTargetDate'
      ] as Array<keyof ComponentRbiInspectionPlanDto>,
      dfKeys: ['thinningDfTargetDate', 'externalDfTargetDate', 'crackingDfTargetDate', 'totalDfTargetDate'] as Array<
        keyof ComponentRbiInspectionPlanDto
      >,
      footerProp: 'targetBasis' as keyof ComponentRbiInspectionPlanDto,
      footerHeader: 'Target Basis'
    },
    {
      riskKeys: ['thinningRiskPlanDate', 'externalRiskPlanDate', 'crackingRiskPlanDate', 'totalRiskPlanDate'] as Array<
        keyof ComponentRbiInspectionPlanDto
      >,
      dfKeys: ['thinningDfPlanDate', 'externalDfPlanDate', 'crackingDfPlanDate', 'totalDfPlanDate'] as Array<
        keyof ComponentRbiInspectionPlanDto
      >
    },
    {
      riskKeys: [
        'thinningRiskPlanDateWinsp',
        'externalRiskPlanDateWinsp',
        'crackingRiskPlanDateWinsp',
        'totalRiskPlanDateWinsp'
      ] as Array<keyof ComponentRbiInspectionPlanDto>,
      dfKeys: [
        'thinningDfPlanDateWinsp',
        'externalDfPlanDateWinsp',
        'crackingDfPlanDateWinsp',
        'totalDfPlanDateWinsp'
      ] as Array<keyof ComponentRbiInspectionPlanDto>,
      footerProp: 'planStatus' as keyof ComponentRbiInspectionPlanDto,
      footerHeader: 'Recommended Inspection'
    }
  ];

  public labels = ['Thinning', 'External', 'Cracking', 'Total'];

  public sageModuleId?: string;

  public ngOnInit(): void {
    if (this.componentId) {
      //Not a new component
      this.calcRefresh
        .pipe(
          startWith(undefined),
          switchMap(() =>
            combineLatest({
              rbi: this.compDataService.getRbiOutput(this.componentId!),
              fluids: this.fluidService.getFluidStreams()
            })
          ),
          filter((x) => !!x.rbi),
          tap((x) => {
            x.rbi.rbiDate = this.ConvertDate(x.rbi.rbiDate);
            x.rbi.targetDate = this.ConvertDate(x.rbi.targetDate);
            x.rbi.planDate = this.ConvertDate(x.rbi.planDate);
            x.rbi.recommendedThinningDate = this.ConvertDate(x.rbi.recommendedThinningDate);
            x.rbi.recommendedCrackingDate = this.ConvertDate(x.rbi.recommendedCrackingDate);
            x.rbi.recommendedExternalDate = this.ConvertDate(x.rbi.recommendedExternalDate);

            this.inspDataHandler.setInitialData(x.rbi);

            if (x.rbi.fluidStreamId !== undefined) {
              this.selectedStream = x.fluids.find((z) => z.id === this.inspDataHandler.data?.fluidStreamId);
            }

            this.sageModuleId = x.rbi.sageModuleId;

            this.compDataHandler.silentSetValue('operatingTemperature', x.rbi.operatingTemperature);
            this.compDataHandler.silentSetValue('operatingPressure', x.rbi.operatingPressure);
            this.compDataHandler.silentSetValue('liquidVolume', x.rbi.liquidVolume);
            this.compDataHandler.silentSetValue('vaporVolume', x.rbi.vaporVolume);
            this.compDataHandler.silentSetValue('fluidMass', x.rbi.fluidMass);
          }),
          filter(() => this.selectedStream !== undefined),
          switchMap(() => this.fluidService.getFluidComponents(this.selectedStream!)),
          takeUntil(this.destroy)
        )
        .subscribe((x) => {
          this.streamValid = x;
        });
    }

    combineLatest([this.uomEvaluator.getUnits('consequenceArea'), this.uomEvaluator.getUnits('rbiRisk')]).subscribe(
      ([x, y]) => {
        this.areaUnits = x;
        this.riskUnits = y;
      }
    );
  }

  public getRbi(): void {
    this.router.navigate([
      makeSageModuleRouteString({
        moduleId: this.sageModuleId!,
        componentId: this.compDataHandler.data.id,
        assetId: this.compDataHandler.data.assetId,
        unitId: this.slideOutData.routeData?.unitKey,
        unitOfMeasure: this.slideOutData.routeData?.unitOfMeasure
      })
    ]);

    this.slideOutRef.close();
  }

  public GetFluidStream(): string | undefined {
    if (this.selectedStream) {
      return this.selectedStream.name!;
    }
    return undefined;
  }

  public GetGrade(prop: keyof ComponentRbiInspectionPlanDto): string {
    const grade2 = this.inspDataHandler.data[prop];
    if (typeof grade2 === 'number') {
      return ActivityEffectiveness[grade2];
    }
    return 'None';
  }

  public GetValue(prop: keyof ComponentRbiInspectionPlanDto | undefined): string | number | undefined {
    if (prop === undefined) {
      return undefined;
    }

    const propValue = this.inspDataHandler.data[prop];

    if (typeof propValue === 'number') {
      return propValue.toFixed(1);
    }
    return this.inspDataHandler.data[prop];
  }

  public getRecommendationClass(prop: keyof ComponentRbiInspectionPlanDto): string {
    const grade = this.inspDataHandler.data[prop] as ActivityEffectiveness;
    if (grade === ActivityEffectiveness.A) {
      return 'ip-high';
    } else if (grade === ActivityEffectiveness.B) {
      return 'ip-medium';
    } else if (grade === ActivityEffectiveness.C) {
      return 'ip-low';
    }
    return '';
  }

  public getRiskClass(prop: keyof ComponentRbiInspectionPlanDto): string {
    const area = this.inspDataHandler.data[prop] as number;
    if (area > 100) {
      return 'ip-high ip-number';
    } else if (area > 35) {
      return 'ip-medium ip-number';
    } else if (area > 20) {
      return 'ip-low ip-number';
    }
    return 'ip-number';
  }

  public getDfClass(prop: keyof ComponentRbiInspectionPlanDto): string {
    const df = this.inspDataHandler.data[prop] as number;
    if (df > 2500) {
      return 'ip-number ip-high';
    } else if (df > 1000) {
      return 'ip-number ip-medium';
    } else if (df > 500) {
      return 'ip-number ip-low';
    }
    return 'ip-number';
  }

  public getCofClass(): string {
    const cof = this.inspDataHandler.data['consequenceArea'] as number;
    if (cof > 500000) {
      return 'ip-number ip-high';
    } else if (cof > 100000) {
      return 'ip-number ip-medium';
    } else if (cof > 25000) {
      return 'ip-number ip-low';
    }
    return 'ip-number';
  }

  public onBeforeSwitching(event: NgbNavChangeEvent): void {
    this.rbiActiveTabIdChange.emit(event.nextId);
  }

  private ConvertDate(originalDate: dateOnly | undefined): string {
    return DateOnlyUtility.isValid(originalDate) ? DateOnlyUtility.formatAsE2gDate(originalDate!) : '---';
  }
}
