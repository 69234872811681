import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { DmlDetailSummaryBaseComponent } from 'src/app/dmls-module/dml-slideout/dml-detail-summary-base-component';

import { DmlLocalThinAreaOutputDto } from '../../models/dml-local-thin-area-output-dto';
import { DmlTypes } from '../../models/Enums/dml-types';

@Component({
  selector: 'app-dml-local-thin-area-summary',
  templateUrl: './dml-local-thin-area-summary.component.html',
  styleUrls: ['./dml-local-thin-area-summary.component.css']
})
export class DmlLocalThinAreaSummaryComponent extends DmlDetailSummaryBaseComponent<DmlLocalThinAreaOutputDto> {
  protected dmlType = DmlTypes['Local Thin Area'];

  public remainingThicknessRatioUom: Observable<string> = this.uomEvaluator.getUnits(
    'DmlLocalThinArea_DmlGeneralThinning_RemainingThicknessRatio'
  );
  public minimumRemainingThicknessRatioUom: Observable<string> = this.uomEvaluator.getUnits(
    'DmlLocalThinArea_DmlGeneralThinning_MinimumRemainingThicknessRatio'
  );
  public corrodedMinimumMeasuredThicknessUom: Observable<string> = this.uomEvaluator.getUnits(
    'DmlLocalThinArea_DmlGeneralThinning_CorrodedMinimumMeasuredThickness'
  );
  public thicknessLimitStructuralUom: Observable<string> = this.uomEvaluator.getUnits(
    'DmlLocalThinArea_DmlGeneralThinning_ThicknessLimitStructural'
  );
  public designPressureUom: Observable<string> = this.uomEvaluator.getUnits(
    'DmlLocalThinArea_DmlGeneralThinning_DesignPressure'
  );
  public undamagedMawpUom: Observable<string> = this.uomEvaluator.getUnits(
    'DmlLocalThinArea_DmlGeneralThinning_UndamagedMawp'
  );
  public damagedMawpUom: Observable<string> = this.uomEvaluator.getUnits(
    'DmlLocalThinArea_DmlGeneralThinning_DamagedMawp'
  );
  public undamagedRegionMaximumFcaUom: Observable<string> = this.uomEvaluator.getUnits(
    'DmlLocalThinArea_DmlGeneralThinning_UndamagedRegionMaximumFca'
  );
  public damagedRegionMaximumFcaUom: Observable<string> = this.uomEvaluator.getUnits(
    'DmlLocalThinArea_DmlGeneralThinning_DamagedRegionMaximumFca'
  );
  public damageRegionMinimumThicknessUom: Observable<string> = this.uomEvaluator.getUnits(
    'DmlLocalThinArea_DmlGeneralThinning_DamageRegionMinimumThickness'
  );
  public remainingLifeUom: Observable<string> = this.uomEvaluator.getUnits(
    'DmlLocalThinArea_DmlGeneralThinning_RemainingLife'
  );
  public minimumInspectionIntervalUom: Observable<string> = this.uomEvaluator.getUnits(
    'DmlLocalThinArea_DmlGeneralThinning_MinimumInspectionInterval'
  );

  public constructor() {
    super();
  }

  protected displaySummary(output: DmlLocalThinAreaOutputDto): void {
    if (output.applicabilityAndLimitationsMessages !== undefined) {
      output.applicabilityAndLimitationsMessages = this.formatSummarySection(
        output.applicabilityAndLimitationsMessages
      );
    }
  }
}
