import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AuthService } from '@equityeng/auth';
import { E2gSelectOption } from '@equityeng/e2g-ng-ui';
import { faExclamationTriangle, faUpload } from '@fortawesome/pro-regular-svg-icons';
import { BehaviorSubject, concatMap, filter, map, Observable, switchMap, take, takeUntil, tap } from 'rxjs';
import { AppSettingsService } from 'src/app/app-settings/app-settings.service';
import { DmlAssetDataCache } from 'src/app/dmls-module/models/dml-asset-data-dto';
import { DmlGridDisplayInfoDto } from 'src/app/dmls-module/models/dml-grid-display-info-dto';
import { DmlSlideoutOption } from 'src/app/dmls-module/models/dml-slideout-option';
import { UploadGridDataResponse } from 'src/app/dmls-module/models/upload-grid-data-response';
import { OnDestroyBaseComponent } from 'src/app/on-destroy-base-component/on-destroy-base-component';
import { DialogService } from 'src/app/shared-module/dialog.service';
import { ActionTypes } from 'src/app/shared-module/models/action-types';
import { DialogButtons } from 'src/app/shared-module/models/dialog-buttons';
import { DialogData } from 'src/app/shared-module/models/dialog-data';
import { DialogResult } from 'src/app/shared-module/models/dialog-result';
import { NEW_ITEM_KEY } from 'src/app/shared-module/models/new-item-key';
import { NotificationService } from 'src/app/shared-module/services/notification.service';
import { SlideOutContainerAction } from 'src/app/slide-out-module/models/constants/slide-out-container-action';
import { SlideOutFooterSection } from 'src/app/slide-out-module/models/slide-out-footer-item';
import { SlideOutContainerService } from 'src/app/slide-out-module/slide-out-container.service';
import { SLIDE_OUT_DATA } from 'src/app/slide-out-module/slide-out-data-injection-token';
import { SlideOutRef } from 'src/app/slide-out-module/slide-out-ref';
import { StateService } from 'src/app/state.service';
import { UploadItemData } from 'src/app/upload-module/upload/upload.component';
import { duplicateItemNameMessage } from 'src/app/utilities/validation-messages';

import { DmlSlideoutInput } from '../models/dml-slideout-input';
import { DmlTypes } from '../models/Enums/dml-types';
import { DmlDataService } from '../services/dml-data.service';
import { DmlService } from '../services/dml.service';

@Component({
  selector: 'app-dml-slideout',
  templateUrl: './dml-slideout.component.html',
  styleUrls: ['./dml-slideout.component.css'],
  providers: [DmlService]
})
export class DmlSlideoutComponent extends OnDestroyBaseComponent implements OnInit, OnDestroy {
  @ViewChild('templateUploadComponent', { static: true }) public templateUploadComponent!: TemplateRef<any>;
  @ViewChild('calculateButton', { static: true }) public calculateButtonTmpl!: TemplateRef<any>;
  @ViewChild('plotly', { static: false }) public plotlyDiv!: HTMLDivElement;

  public refreshData: BehaviorSubject<void> = new BehaviorSubject<void>(undefined);

  public editing: boolean = false;
  public activeTabId: number = 1;
  public fileName?: string;
  public fileId?: string;
  public reportFileName: string = '';
  public displayUpload: boolean = false;
  public displayDataChangedWarning: boolean = false;
  public dmlType?: DmlTypes;
  public errors: boolean = false;
  public uploadErrors: Array<string> = [];
  public queueLimit: number = 1;
  public allowedMimeTypes: Array<string> = [
    'text/csv',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  ];

  public assetCache: DmlAssetDataCache = new DmlAssetDataCache();

  public faUpload = faUpload;
  public faExclamationTriangle = faExclamationTriangle;

  private dirty: boolean = false;
  private assetId: string = '';

  public get readOnly(): boolean {
    return this.assetCache.data?.permissions.readOnly ?? false;
  }

  public get showReportTab(): boolean {
    return this.slideOutData.optionsData![this.slideOutData.id].reportFileId !== undefined;
  }

  public constructor(
    private authService: AuthService,
    private dialogService: DialogService,
    private settingsService: AppSettingsService,
    private dmlDataService: DmlDataService,
    private notificationService: NotificationService,
    private stateService: StateService,
    private slideOutRef: SlideOutRef,
    private slideOutContainerService: SlideOutContainerService,
    private dmlService: DmlService,
    private changeDetector: ChangeDetectorRef,
    @Inject(SLIDE_OUT_DATA) public slideOutData: DmlSlideoutInput
  ) {
    super();
    this.configureSlideOut();
  }

  public ngOnInit(): void {
    this.addCalculateButton();

    this.initializeSlideoutHeader();

    // Child component fires this after it loads the DML.
    this.dmlService.hasCalculationErrors
      .pipe(
        filter((hasErrors) => hasErrors),
        switchMap(() => this.dmlDataService.getDamageMonitoringLocationOutputStatusMessage(this.slideOutData.id))
      )
      .subscribe((error) => {
        this.slideOutContainerService.setCalcError(error);
      });

    // Child component fires this when it setHasOutputAndCalculationErrors on the DML service.
    // Displays on Output Tab if data has changed and saved, but it hasn't been recalculated.
    this.dmlService.displayDataChangedWarning.subscribe((x) => {
      this.displayDataChangedWarning = x;
    });

    // This happens when a user navigates to another DML or the slideout is initially loaded.
    this.slideOutContainerService.optionId
      .pipe(
        tap((id) => {
          this.initializeForm(id);
        }),
        takeUntil(this.destroy)
      )
      .subscribe();

    this.dmlService.displayGridInfo.subscribe((gridDisplayInfo: DmlGridDisplayInfoDto) => {
      this.fileId = gridDisplayInfo.fileId;
      this.fileName = gridDisplayInfo.fileName;
      this.slideOutData.optionsData![this.slideOutData.id].fileId = this.fileId;
      this.slideOutData.optionsData![this.slideOutData.id].fileName = this.fileName;
    });
  }

  private initializeSlideoutHeader(): void {
    this.slideOutContainerService.setUseDefaultHeaderBorder(false);
    this.slideOutContainerService.setOptions(this.buildDropdownOptions(this.slideOutData.optionsData!));
    this.slideOutContainerService.setOptionId(this.slideOutData.id);
    this.slideOutContainerService.setBreadcrumbs(this.slideOutData.breadcrumbs!);
    this.slideOutContainerService.setCustomNameError(duplicateItemNameMessage('DML'));
  }

  private initializeForm(id: string): void {
    const previousAssetId = this.assetId;
    const newDmlType: DmlTypes = this.getTypeById(id);

    this.editing = id !== NEW_ITEM_KEY;
    this.slideOutData.id = id;
    this.assetId = this.editing ? this.slideOutData.optionsData![id].assetId : this.slideOutData.assetId;

    const assetChanged: boolean = previousAssetId !== this.assetId;

    if (assetChanged) {
      // We set this here so that the child component(specific DML type) can use this.
      this.slideOutData.assetId = this.assetId;
      this.assetCache.data = undefined;
    }

    // If the DML type has not changed, do not change the base DML type.
    if (newDmlType !== this.dmlType) {
      this.dmlType = undefined;
      this.changeDetector.detectChanges();
      this.slideOutData.type = newDmlType;
      this.dmlType = newDmlType;
    }

    if (this.editing) {
      this.fileId = this.slideOutData.optionsData[id].fileId;
      this.reportFileName = `${this.slideOutData.optionsData[id].name} SAGE Report.pdf`;

      this.stateService.setSelectedGridId(this.slideOutData.gridContext!, id);
    }

    this.activeTabId = 1;
    this.showUpload(false);
    this.displayFileName();
    this.dmlService.setHasOutput(false);

    this.refreshData.next();
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this.slideOutContainerService.setFooterItems([]);
  }

  private configureSlideOut(): void {
    this.slideOutContainerService.dirty.subscribe((x) => (this.dirty = x));

    this.slideOutContainerService.action
      .pipe(
        filter((action) => action === SlideOutContainerAction.Revert),
        takeUntil(this.destroy)
      )
      .subscribe(() => {
        this.dmlService.revertChanges();
      });

    this.slideOutContainerService.action
      .pipe(
        filter((action) => action === SlideOutContainerAction.Save || action === SlideOutContainerAction.SaveAndClose),
        switchMap((action) =>
          this.dmlDataService.saveDamageMonitoringLocation(this.assetId, this.dmlService.getDmlData()).pipe(
            map((result) => {
              if (typeof result === 'string') {
                // Add new DML
                this.slideOutData.id = result;
                this.editing = true;
                this.stateService.setSelectedGridId(this.slideOutData.gridContext!, result);

                this.addNewItemToHeaderDropdown(result);

                this.slideOutContainerService.setOptionId(this.slideOutData.id);
                this.notificationService.showActionResult(
                  true,
                  ActionTypes.Add,
                  this.slideOutData.optionsData[this.slideOutData.id].name
                );
              } else {
                // Check if the update was successful.
                if (result) {
                  this.slideOutData.id = this.dmlService.getDmlData().id!;

                  this.updateItemInHeaderDropdown();

                  this.refreshData.next();
                }

                this.notificationService.showActionResult(
                  result,
                  ActionTypes.Update,
                  this.slideOutData.optionsData[this.slideOutData.id].name
                );
              }

              return action;
            })
          )
        ),
        filter((action) => action === SlideOutContainerAction.SaveAndClose),
        tap(() => this.slideOutRef.close()),
        takeUntil(this.destroy)
      )
      .subscribe();

    this.slideOutContainerService.action
      .pipe(
        filter((action) => action === SlideOutContainerAction.Cancel),
        takeUntil(this.destroy)
      )
      .subscribe(() => {
        this.slideOutRef.close();
      });

    this.dmlService.hasCalculationErrors
      .pipe(
        filter((hasErrors: boolean) => hasErrors),
        switchMap(() => this.dmlDataService.getDamageMonitoringLocationOutputStatusMessage(this.slideOutData.id))
      )
      .subscribe((error) => {
        this.slideOutContainerService.setCalcError(error);
      });
  }

  public displayDmlGraph(): boolean {
    return this.uploadErrors.length > 0;
  }

  public calculate(): void {
    this.dmlDataService
      .calcDamageMonitoringLocation(this.dmlType!, this.slideOutData.id)
      .pipe(
        tap((resp) => {
          this.slideOutContainerService.setCalcError(undefined);
          let notificationMsg: string = '';

          if (resp.success) {
            notificationMsg = 'Calculation Completed Successfully';

            this.dmlService.setHasOutput(true);
            this.displayDataChangedWarning = false;
            this.activeTabId = 2;
          } else {
            this.dmlService.setHasCalculationErrors(true);
            this.refreshData.next();
            notificationMsg = 'Calculation Failure';
          }

          this.slideOutData.optionsData![this.slideOutData.id].reportFileId = resp?.reportFileId;
          this.notificationService.showInfo(notificationMsg);
          return resp;
        }),
        takeUntil(this.destroy)
      )
      .subscribe();
  }

  public uploadDamageMonitoringLocationData(): void {
    const dialogData: DialogData = {
      title: 'Upload Damage Monitoring Location Grid Data',
      template: this.templateUploadComponent,
      buttons: DialogButtons.Yes,
      yesButtonText: 'Close',
      width: '730px'
    } as DialogData;

    if (this.slideOutData.optionsData![this.slideOutData.id].fileId === undefined) {
      this.dialogService.display(dialogData).pipe(take(1)).subscribe();
    } else {
      const replaceDialogData = {
        title: 'Do you wish to replace the current grid data?',
        message: 'Are you sure?',
        buttons: DialogButtons.YesCancel,
        yesButtonText: 'Replace'
      } as DialogData;

      this.dialogService
        .display(replaceDialogData)
        .pipe(
          filter((x) => x === DialogResult.yes),
          concatMap(() => {
            return this.dialogService.display(dialogData).pipe(take(1));
          }),
          takeUntil(this.destroy)
        )
        .subscribe();
    }
  }

  public successItem(data: UploadItemData): void {
    setTimeout(() => {
      // Close the upload dialog.
      this.dialogService.closeAll();
    });

    if (data.status === 200) {
      this.notificationService.showSaveResult(true, 'Upload');

      const successResponse = JSON.parse(data.response) as UploadGridDataResponse;

      this.uploadErrors = [];

      this.slideOutData.optionsData![this.slideOutData.id].fileName = successResponse.fileName;
      this.slideOutData.grid = successResponse.data;

      this.dmlService.setGridData({
        fileName: successResponse.fileName,
        data: successResponse.data
      });
    }
  }

  public errorItem(data: UploadItemData): void {
    // Close the upload dialog.
    this.dialogService.closeAll();

    if (data.status === 400) {
      this.notificationService.showSaveResult(false, 'Upload');

      const errorResponse = JSON.parse(data.response) as UploadGridDataResponse;

      this.uploadErrors = errorResponse.errors;

      this.slideOutData.optionsData![this.slideOutData.id].fileName = errorResponse.fileName;
      this.slideOutData.grid = undefined;

      this.dmlService.setGridData({
        fileName: errorResponse.fileName,
        data: undefined
      });
    }
  }

  public getUploadData = (): Observable<{ token: string; uploadUrl: string }> => {
    return this.authService.userAuthenticated$.pipe(
      map((user) => ({
        uploadUrl: `${this.settingsService.settings.apiUri}/damagemonitoringlocation/griddata/upload`,
        token: user.accessToken
      }))
    );
  };

  public showUpload(canDisplay: boolean): void {
    this.displayUpload = canDisplay;
  }

  public showOutputTab(): boolean {
    return this.dmlService.getHasOutput();
  }

  public showCalculate(): boolean {
    const valid =
      this.activeTabId !== 2 &&
      !this.dirty &&
      this.editing &&
      ((this.hasGridData() && this.isTypeWithGridUpload()) || !this.isTypeWithGridUpload());

    return valid;
  }

  private isTypeWithGridUpload(): boolean {
    return this.dmlType === DmlTypes['General Thinning'] || this.dmlType === DmlTypes['Local Thin Area'];
  }

  private hasGridData(): boolean {
    return (
      this.slideOutData.optionsData![this.slideOutData.id].fileId !== undefined || this.slideOutData?.grid !== undefined
    );
  }

  public tabChange(): void {
    this.showUpload(false);
  }

  public getReport = (): Observable<Blob> => {
    return this.dmlDataService.getDamageMonitoringLocationReportFile(
      this.slideOutData.optionsData![this.slideOutData.id].reportFileId!
    );
  };

  private displayFileName(): void {
    if (
      !this.editing ||
      (this.slideOutData.optionsData![this.slideOutData.id].fileName === undefined && !this.isTypeWithGridUpload())
    ) {
      this.fileName = 'N/A';
    } else {
      this.fileName = this.slideOutData.optionsData![this.slideOutData.id].fileName!;
    }
  }

  private buildDropdownOptions(dmlData: Record<string, DmlSlideoutOption>): Array<E2gSelectOption> {
    return Object.entries(dmlData).map(([key, value]) => ({ value: key, label: value.name, type: value.type }));
  }

  private getTypeById(id: string): DmlTypes {
    return id !== NEW_ITEM_KEY
      ? (this.slideOutData.optionsData as Record<string, DmlSlideoutOption>)[id].type
      : this.slideOutData.type;
  }

  private addNewItemToHeaderDropdown(id: string): void {
    this.slideOutData.optionsData[id] = {
      name: this.dmlService.getDmlData().name,
      assetId: this.assetId,
      type: this.dmlType!,
      fileId: undefined,
      fileName: undefined,
      reportFileId: undefined
    };

    this.slideOutContainerService.setOptions(this.buildDropdownOptions(this.slideOutData.optionsData));
  }

  private updateItemInHeaderDropdown(): void {
    const currentOptionData = this.slideOutData.optionsData![this.slideOutData.id];

    currentOptionData.name = this.dmlService.getDmlData().name;
    currentOptionData.fileId = this.fileId;
    currentOptionData.fileName = this.fileName;

    this.slideOutContainerService.setOptions(this.buildDropdownOptions(this.slideOutData.optionsData));
  }

  private addCalculateButton(): void {
    this.slideOutContainerService.setFooterItems([
      { position: SlideOutFooterSection.Right, template: this.calculateButtonTmpl }
    ]);
  }
}
