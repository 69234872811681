import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { faMemo, faPencilAlt } from '@fortawesome/pro-regular-svg-icons';
import { faTrashXmark } from '@fortawesome/pro-solid-svg-icons';
import { ICellRendererParams } from 'ag-grid-community';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { BaseSlideoutOption } from 'src/app/asset-module/models/base-slideout-option';
import { Breadcrumb } from 'src/app/breadcrumb-module/breadcrumbs/breadcrumbs.component';
import { GridCellLinkParams } from 'src/app/grid-module/cell-renders/grid-cell-link/grid-cell-link-params';
import { GridCellLinkComponent } from 'src/app/grid-module/cell-renders/grid-cell-link/grid-cell-link.component';
import { GridCellTooltipComponent } from 'src/app/grid-module/cell-renders/grid-cell-tooltip/grid-cell-tooltip.component';
import { getBaseGroupColumns } from 'src/app/grid-module/column-definitions/circuit-grid-columns';
import { ActionTypes } from 'src/app/shared-module/models/action-types';
import { DialogType } from 'src/app/shared-module/models/name-dialog-data';
import { NEW_ITEM_KEY } from 'src/app/shared-module/models/new-item-key';
import { AssetFeature, AssetPermissionModel, NO_PERMISSION } from 'src/app/shared-module/permission/permission-models';
import { PermissionService } from 'src/app/shared-module/permission/permission.service';
import { NotificationService } from 'src/app/shared-module/services/notification.service';
import { StateService } from 'src/app/state.service';

import { CircuitDataService } from '../../../circuit-data.service';
import { E2gMenuItem } from '../../../grid-module/cell-renders/grid-cell-menu/e2g-menu-item';
import { GridCellMenuComponent } from '../../../grid-module/cell-renders/grid-cell-menu/grid-cell-menu-component';
import { E2gAgGridComponent, GridBaseOptions } from '../../../grid-module/e2g-ag-grid/e2g-ag-grid.component';
import { EquipmentUnitInput } from '../../../models/equipment-unit-input';
import { OnDestroyBaseComponent } from '../../../on-destroy-base-component/on-destroy-base-component';
import { DialogService } from '../../../shared-module/dialog.service';
import { SlideOutService } from '../../../slide-out-module/slide-out.service';
import { CircuitSlideoutInput } from '../../models/circuit-slideout-input';
import { GroupDetailsDto } from '../../models/group-details-dto';
import { CircuitDetailComponent } from '../circuit-detail/circuit-detail.component';

@Component({
  selector: 'app-circuit',
  templateUrl: './circuit.component.html',
  styleUrls: ['./circuit.component.css']
})
export class CircuitComponent extends OnDestroyBaseComponent implements OnInit {
  @Input() public breadcrumbs: Breadcrumb[] = [];
  @ViewChild('circuitGrid', { static: false }) public circuitGrid?: E2gAgGridComponent;

  public inputData!: EquipmentUnitInput;
  public refreshData = new Subject<void>();
  public circuits: Array<GroupDetailsDto> = [];

  public circuitId?: string;

  public faTrashXmark = faTrashXmark;
  private faPencilAlt = faPencilAlt;
  private faMemo = faMemo;

  public circuitPermissions: AssetPermissionModel = NO_PERMISSION;

  private menuDefinition: Array<E2gMenuItem> = [
    {
      text: 'Edit',
      command: (params: ICellRendererParams): void => this.editCircuit(params.data.id),
      iconDefinition: { icon: this.faPencilAlt },
      visible: (): boolean => this.circuitPermissions.edit
    },
    {
      text: 'Delete',
      command: (params: ICellRendererParams): void => this.deleteCircuit(params.data.id, params.data.name),
      iconDefinition: { icon: this.faTrashXmark, color: 'var(--system-fg-error)' },
      visible: (): boolean => this.circuitPermissions.delete
    },
    {
      text: 'Show Detail',
      command: (params: ICellRendererParams): void => this.editCircuit(params.data.id),
      iconDefinition: { icon: this.faMemo },
      visible: (): boolean => !this.circuitPermissions.edit
    }
  ];

  public circuitGridOptions: GridBaseOptions = {
    exportFileName: 'Groups',
    suppressCellFocus: true,
    rowSelection: 'single',
    context: 'circuits',
    onSelectionChanged: this.onSelectionChanged.bind(this),
    defaultColDef: {
      minWidth: 100,
      resizable: true,
      sortable: true,
      tooltipComponent: GridCellTooltipComponent,
      filter: 'agSetColumnFilter'
    },
    columnDefs: [
      {
        headerName: 'Group Name',
        headerTooltip: 'Group Name',
        field: 'name',
        tooltipField: 'name',
        sortable: true,
        comparator: (a, b): number => a.localeCompare(b, 'en', { numeric: true, sensitivity: 'base' }),
        width: 200,
        cellRenderer: GridCellLinkComponent,
        cellRendererParams: {
          isLink: (): boolean => true,
          linkCommand: (params: ICellRendererParams): void => this.editCircuit(params.data.id)
        } as GridCellLinkParams
      },
      {
        headerName: 'Actions',
        headerTooltip: 'Actions',
        width: 130,
        cellStyle: { textAlign: 'center' },
        headerClass: 'ag-header-center',
        cellRenderer: GridCellMenuComponent,
        cellRendererParams: { menuItems: this.menuDefinition },
        filter: false
      },
      ...getBaseGroupColumns()
    ]
  };

  public constructor(
    private permissionService: PermissionService,
    private route: ActivatedRoute,
    private circuitService: CircuitDataService,
    private dialogService: DialogService,
    private notificationService: NotificationService,
    private stateService: StateService,
    private slideOutService: SlideOutService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.route.params
      .pipe(
        take(1),
        tap((params) => {
          this.setInputData(params);
        }),
        switchMap(() =>
          this.permissionService.getAssetPermissionModel(AssetFeature.Circuit, this.inputData.equipmentKey!)
        ),
        tap((x) => {
          this.circuitPermissions = x;
        }),
        switchMap(() => this.refreshData.pipe(startWith(null))),
        switchMap(() =>
          this.circuitService.getCircuits(this.inputData.equipmentKey).pipe(
            tap((circuits: Array<GroupDetailsDto>) => {
              this.circuits = circuits;
            })
          )
        ),
        takeUntil(this.destroy)
      )
      .subscribe(() => {
        const selectedId = this.stateService.getSelectedGridId(this.getGridContext());

        if (selectedId !== undefined) {
          this.circuitGrid?.selectRow(selectedId);
        }
      });

    this.stateService.state
      .pipe(
        map(() => this.stateService.getSelectedGridId(this.getGridContext())),
        filter((x) => x !== undefined),
        distinctUntilChanged(),
        takeUntil(this.destroy)
      )
      .subscribe((selectedId) => {
        const exists = this.circuits.find((x: GroupDetailsDto) => x.id === selectedId);

        if (exists === undefined) {
          this.refreshData.next();
        }

        this.circuitGrid?.selectRow(selectedId!);
      });
  }

  private getGridContext(): string {
    return this.circuitGridOptions.context;
  }

  private onSelectionChanged(): void {
    const selectedRow = this.circuitGrid!.api.getSelectedRows()[0];

    if (selectedRow !== undefined) {
      this.stateService.setSelectedGridId(this.getGridContext(), selectedRow.id);
    }
  }

  public addCircuit(): void {
    this.circuitGridOptions.deselectAll!();
    this.editCircuit(NEW_ITEM_KEY);
  }

  private setInputData(params: Params): void {
    this.inputData = {
      equipmentKey: params.equipmentKey,
      unitKey: params.unitKey
    };
  }

  private deleteCircuit(id: string, name: string): void {
    this.dialogService
      .displayDelete({ dialogType: DialogType.Group, name })
      .pipe(switchMap(() => this.circuitService.deleteCircuit(id)))
      .subscribe((success: boolean) => {
        this.notificationService.showActionResult(success, ActionTypes.Delete, name);
        if (success) {
          this.refreshData.next();
        }
      });
  }

  public editCircuit(id: string): void {
    const optionsData = this.circuitGrid!.convertNodesToSlideoutOptions<GroupDetailsDto, BaseSlideoutOption>((data) => {
      return {
        name: data.name,
        assetId: this.inputData.equipmentKey!
      };
    });

    const data: CircuitSlideoutInput = {
      id: id,
      unitId: this.inputData.unitKey,
      assetId: this.inputData.equipmentKey,
      gridContext: this.circuitGrid?.gridOptions.context,
      optionsData: optionsData,
      breadcrumbs: this.breadcrumbs
    };

    this.slideOutService
      .open<boolean, CircuitDetailComponent>(CircuitDetailComponent, {
        data
      })
      .closed.pipe(take(1))
      .subscribe(() => this.refreshData.next());
  }

  public downloadExcelFile(): void {
    this.circuitGridOptions.downloadExcelFile!();
  }
}
