import { Component, ComponentRef, Injector, OnInit, StaticProvider, ViewChild, ViewContainerRef } from '@angular/core';

import { OnDestroyBaseComponent } from '../../on-destroy-base-component/on-destroy-base-component';
import { SlideOutConfig } from '../models/slide-out-config';
import { SlideOutContainerService } from '../slide-out-container.service';
import { SLIDE_OUT_DATA } from '../slide-out-data-injection-token';
import { SlideOutRef } from '../slide-out-ref';
import { SlideOutDirective } from '../slide-out.directive';
import { ComponentType, SlideOutService } from '../slide-out.service';

@Component({
  selector: 'app-slide-out',
  templateUrl: './slide-out.component.html',
  styleUrls: ['./slide-out.component.css']
})
export class SlideOutComponent extends OnDestroyBaseComponent implements OnInit {
  @ViewChild(SlideOutDirective, { static: true }) private host!: SlideOutDirective;

  private compRef?: ComponentRef<any>;
  private viewContainerRef!: ViewContainerRef;
  private componentSlideOutRef?: SlideOutRef<any, any>;

  public constructor(private slideOutService: SlideOutService) {
    super();
    this.slideOutService.openCallback = this.open;
    this.slideOutService.closeCallback = this.close;
  }

  public ngOnInit(): void {
    this.viewContainerRef = this.host.viewContainerRef;
  }

  private open = <R, C>(component: ComponentType<C>, config?: SlideOutConfig): SlideOutRef<R, C> => {
    this.removeComponent();
    return this.attachComponent<R, C>(component, config);
  };

  private close = (): void => {
    this.removeComponent();
  };

  private attachComponent<R, C>(component: ComponentType<C>, config?: SlideOutConfig): SlideOutRef<R, C> {
    const slideOutRef = new SlideOutRef<R, C>();
    const injector = this.createInjector(config?.data || undefined, slideOutRef);

    this.compRef = this.viewContainerRef.createComponent<C>(component, { injector: injector });
    slideOutRef.componentInstance = this.compRef.instance;

    return slideOutRef;
  }

  private createInjector(data: any, ref: SlideOutRef): Injector {
    const providers: StaticProvider[] = [
      { provide: SLIDE_OUT_DATA, useValue: data },
      { provide: SlideOutRef, useValue: ref },
      { provide: SlideOutContainerService, useValue: new SlideOutContainerService() }
    ];

    return Injector.create({ providers });
  }

  private removeComponent(): void {
    if (this.compRef) {
      this.host.viewContainerRef.clear();
      this.compRef = undefined;
    }
  }
}
