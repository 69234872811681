<div *ngIf="!loading; else load" class="d-flex flex-row">
  <div class="col-6 flex-fill">
    <div class="row align-items-end mt-2 mb-2 m-0">
      <div class="col-6 pl-0">
        <e2g-ng-select-input
          id="select-damage-monitoring-location-status"
          heading="Status"
          placeholder="Select Status"
          [disabled]="disableStatus()"
          [required]="false"
          [options]="statusList!"
          [(ngModel)]="dataHandler.data.status"
          [readonly]="readOnly"
        ></e2g-ng-select-input>
      </div>

      <div class="col-6 pr-0">
        <app-evaluation-date-field [evaluationDate]="dataHandler.data.evaluationDate"></app-evaluation-date-field>
      </div>
    </div>

    <div class="form-section-header my-1">DML Information</div>

    <div class="row align-items-end mb-2 m-0">
      <div class="col-12 p-0">
        <e2g-ng-select-input
          id="select-damage-monitoring-location-component"
          heading="Component"
          placeholder="Select Component"
          [disabled]="false"
          [required]="true"
          [options]="componentsOptions!"
          [(ngModel)]="dataHandler.data.componentId"
          (change)="componentChange()"
          [readonly]="readOnly"
        ></e2g-ng-select-input>
      </div>
    </div>

    <div *ngIf="dataHandler.data.componentId">
      <div class="row align-items-end mb-2 m-0">
        <div class="col-6 pl-0">
          <e2g-ng-select-input
            id="select-damage-monitoring-location-assessment-level"
            heading="API 579 Assessment"
            placeholder="Select API 579 Assessment"
            [disabled]="false"
            [required]="true"
            [options]="assessmentLevelList!"
            [(ngModel)]="dataHandler.data.assessmentLevel"
            [readonly]="readOnly"
          ></e2g-ng-select-input>
        </div>

        <div class="col-6 p-0">
          <e2g-ng-select-input
            id="select-damage-monitoring-location-damage-location"
            heading="Damage Location"
            placeholder="Select Damage Location"
            [disabled]="false"
            [required]="true"
            [options]="damageLocationOptions!"
            [(ngModel)]="dataHandler.data.damageLocation"
            [readonly]="readOnly"
          ></e2g-ng-select-input>
        </div>
      </div>

      <div class="row align-items-end mb-0 m-0">
        <div class="col-12 p-0">
          <hr class="seperator" />
        </div>
      </div>

      <div class="col p-0 mb-2">
        <h3 class="title m-0"><b>Pitting Couple Region Heading</b></h3>
      </div>

      <div class="row align-items-end mb-2 m-0">
        <div class="col-6 pl-0">
          <e2g-ng-select-input
            id="select-damage-monitoring-location-pitting-type"
            heading="Pitting Type"
            placeholder="Select Pitting Type"
            [disabled]="false"
            [required]="true"
            [requiredForCalc]="true"
            [options]="pittingTypeOptions!"
            [(ngModel)]="dataHandler.data.pittingType"
            [readonly]="readOnly"
          ></e2g-ng-select-input>
        </div>
      </div>

      <div *ngIf="dataHandler.data.pittingType === 1" class="row align-items-end mb-2 m-0">
        <div class="col-6 pl-0">
          <e2g-text-input
            heading="Longitudinal Length of Pitting {{ longitudinalLengthOfPittingUom | async }}"
            id="inp-damage-monitoring-location-longitudinal-length-of-pitting"
            type="number"
            [required]="false"
            [requiredForCalc]="true"
            [(ngModel)]="dataHandler.data.longitudinalLengthOfPitting"
            [readonly]="readOnly"
          ></e2g-text-input>
        </div>

        <div class="col-6 p-0">
          <e2g-text-input
            heading="Circumferential Length of Pitting {{ circumferentialLengthOfPittingUom | async }}"
            id="inp-damage-monitoring-location-circumferential-length-of-pitting"
            type="number"
            [required]="false"
            [requiredForCalc]="true"
            [(ngModel)]="dataHandler.data.circumferentialLengthOfPitting"
            [readonly]="readOnly"
          ></e2g-text-input>
        </div>
      </div>

      <div class="row align-items-end mb-2 m-0">
        <div class="col-6 pl-0">
          <e2g-text-input
            heading="Pit Diameter FCA {{ pitDiameterFcaUom | async }}"
            id="inp-damage-monitoring-location-pit-diameter-fca"
            type="number"
            [required]="false"
            [requiredForCalc]="true"
            [(ngModel)]="dataHandler.data.pitDiameterFca"
            [readonly]="readOnly"
          ></e2g-text-input>
        </div>

        <div class="col-6 p-0">
          <e2g-text-input
            heading="Pit Depth FCA {{ pitDepthFcaUom | async }}"
            id="inp-damage-monitoring-location-pit-depth-fca"
            type="number"
            [required]="false"
            [requiredForCalc]="true"
            [(ngModel)]="dataHandler.data.pitDepthFca"
            [readonly]="readOnly"
          ></e2g-text-input>
        </div>
      </div>

      <div class="col p-0 mt-2">
        <hr class="seperator" />
      </div>

      <div class="col p-0 mb-2">
        <h3 class="title m-0"><b>Pit Couple Data Grid</b></h3>
      </div>

      <div class="d-flex mb-2 m-0">
        <button
          *ngIf="!readOnly"
          id="btnAdd"
          class="btn e2g-btn-outline-primary"
          (click)="addPitCoupleData()"
          [disabled]="false"
        >
          Add Row
        </button>
        <button
          *ngIf="!readOnly"
          id="btnDelete"
          class="btn e2g-btn-outline-primary ml-2"
          (click)="deletePitCoupleData()"
          [disabled]="!rowSelected()"
        >
          Delete Row
        </button>
      </div>
      <div class="d-flex m-0 mb-2">
        <e2g-ag-grid
          #pitCoupleDataGrid
          class="w-100"
          [options]="gridOptions"
          [data]="dataHandler.data.pitCoupleData"
        ></e2g-ag-grid>
      </div>

      <div class="row align-items-end mb-0 m-0">
        <div class="col-12 p-0">
          <hr class="seperator" />
        </div>
      </div>

      <div class="col p-0">
        <e2g-textarea-input
          id="inp-comments"
          class="w-100 pr-2"
          heading="Comments"
          [rows]="5"
          [readonly]="readOnly"
          [maxlength]="512"
          [(ngModel)]="dataHandler.data.comments"
        ></e2g-textarea-input>
      </div>
    </div>
  </div>
  <div class="col-6 flex-col flex-fill my-2"></div>
</div>

<ng-template #load>
  <div class="loading"></div>
</ng-template>
