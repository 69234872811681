import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { E2gSelectOption } from '@equityeng/e2g-ng-ui';
import { OnDestroyBaseComponent } from 'src/app/on-destroy-base-component/on-destroy-base-component';
import {
  addToArrayIfNotInArray,
  generateNumberArray,
  removeFromArrayIfExists,
  unionArrays
} from 'src/app/utilities/array-helper';

//TODO move to e2g-library?
export type e2gOptionValueType = string | number;
export interface CheckboxSelectOption extends E2gSelectOption {
  infoText?: string;
}

export interface CheckboxesUpdatedEvent {
  isChecked: boolean;
  value: Array<e2gOptionValueType>;
}

@Component({
  selector: 'app-checkbox-multi-column',
  templateUrl: './checkbox-multi-column.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxMultiColumnComponent),
      multi: true
    }
  ]
})
export class CheckboxMultiColumnComponent extends OnDestroyBaseComponent implements ControlValueAccessor, OnInit {
  public selectedItems!: Array<e2gOptionValueType>;

  @Input() public title?: string;
  @Input() public readOnly: boolean = false;
  @Input() public availableItems: Array<CheckboxSelectOption> = [];
  @Input() public indeterminateItems: Array<e2gOptionValueType> = [];
  @Input() public canSelectAll?: boolean;
  @Input() public itemsPerColumn?: number;
  @Output() public change = new EventEmitter<CheckboxesUpdatedEvent>();

  public onChange: ((value: Array<e2gOptionValueType>) => void) | undefined;
  public onTouched: (() => void) | undefined;
  public columns: Array<Array<CheckboxSelectOption>> = [];

  public ngOnInit(): void {
    this.columns = this.getColumnOptions();
  }

  private getColumnOptions(): Array<Array<CheckboxSelectOption>> {
    if (this.itemsPerColumn) {
      const columns = generateNumberArray(Math.ceil(this.availableItems.length / this.itemsPerColumn));
      return columns.map((x) => {
        const start = x * this.itemsPerColumn!;
        return this.availableItems.slice(start, Math.min(this.availableItems.length, start + this.itemsPerColumn!));
      });
    }
    return [this.availableItems];
  }

  public isSelected(value: e2gOptionValueType): boolean {
    return this.selectedItems.includes(value);
  }

  public isIndeterminate(value: e2gOptionValueType): boolean {
    return this.indeterminateItems.includes(value);
  }

  public selectAll(): void {
    this.selectedItems = unionArrays(
      this.selectedItems,
      this.availableItems.map((x) => this.getOptionValue(x))
    );
    this.indeterminateItems = [];
    this.handleChange({ isChecked: true, value: this.selectedItems });
  }

  public onCheckboxChange(isChecked: boolean, value: e2gOptionValueType): void {
    removeFromArrayIfExists(this.indeterminateItems, value);

    if (isChecked) {
      addToArrayIfNotInArray(this.selectedItems, value);
    } else {
      removeFromArrayIfExists(this.selectedItems, value);
    }

    this.handleChange({ isChecked, value: [value] });
  }

  public registerOnChange(fn: (value: Array<e2gOptionValueType>) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public writeValue(value: Array<e2gOptionValueType>): void {
    this.selectedItems = value || [];
  }

  private handleChange(event: CheckboxesUpdatedEvent): void {
    this.change.emit(event);
    if (this.onChange) {
      this.onChange(this.selectedItems!);
    }
  }

  public getOptionValue(option: CheckboxSelectOption): e2gOptionValueType {
    return option.value ?? option.label;
  }
}
