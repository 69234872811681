import { Injectable } from '@angular/core';
import { E2gSelectOption } from '@equityeng/e2g-ng-ui';
import { combineLatest, map, Observable, ReplaySubject, Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

import { Breadcrumb } from '../breadcrumb-module/breadcrumbs/breadcrumbs.component';
import { SlideOutContainerAction } from './models/constants/slide-out-container-action';
import { SlideOutFooterItem } from './models/slide-out-footer-item';

@Injectable()
export class SlideOutContainerService {
  public headerVisible: Observable<boolean> = new BehaviorSubject<boolean>(true);
  public allowDuplicateNames: Observable<boolean> = new BehaviorSubject<boolean>(false);
  public customHeaderNameError: Observable<string | undefined> = new BehaviorSubject<string | undefined>(undefined);
  public invalidNames: Observable<Array<string>> = new BehaviorSubject<Array<string>>([]);
  public action: Observable<SlideOutContainerAction> = new Subject<SlideOutContainerAction>();
  public options: Observable<Array<E2gSelectOption>> = new ReplaySubject<Array<E2gSelectOption>>(1);
  public optionId: Observable<string> = new ReplaySubject<string>(1);
  public dirty: Observable<boolean> = new BehaviorSubject<boolean>(false);
  public nameUpdated: Observable<string> = new Subject<string>();
  public allowRename: Observable<boolean> = new BehaviorSubject<boolean>(true);
  public headerValid: Observable<boolean> = new BehaviorSubject<boolean>(true);
  public bodyValid: Observable<boolean> = new BehaviorSubject<boolean>(true);
  public backButtonVisible: Observable<boolean> = new BehaviorSubject<boolean>(false);
  public staticHeading: Observable<string> = new ReplaySubject<string>(1);
  public extraFooterItems: Observable<Array<SlideOutFooterItem>> = new BehaviorSubject<Array<SlideOutFooterItem>>([]);
  public breadcrumbs: Observable<Array<Breadcrumb>> = new BehaviorSubject<Array<Breadcrumb>>([]);
  public useDefaultHeaderBorder: Observable<boolean> = new BehaviorSubject<boolean>(true);
  public saveButtonText: Observable<string> = new Subject<string>();
  public calcError: Observable<string | undefined> = new Subject<string | undefined>();
  public isFormValid: Observable<boolean> = combineLatest([this.headerVisible, this.headerValid, this.bodyValid]).pipe(
    map(([headerVisible, headerValid, body]) => (!headerVisible || headerValid) && body)
  );

  public setHeaderVisible(visible: boolean): void {
    (this.headerVisible as Subject<boolean>).next(visible);
  }

  public triggerAction(action: SlideOutContainerAction): void {
    (this.action as Subject<SlideOutContainerAction>).next(action);
  }

  public setOptions(options: Array<E2gSelectOption>): void {
    (this.options as Subject<Array<E2gSelectOption>>).next(options);
  }

  public setOptionId(id: string): void {
    (this.optionId as Subject<string>).next(id);
  }

  public setDirty(dirty: boolean): void {
    (this.dirty as Subject<boolean>).next(dirty);
  }

  public setAllowRename(allow: boolean): void {
    (this.allowRename as Subject<boolean>).next(allow);
  }

  public triggerNameUpdate(name: string): void {
    (this.nameUpdated as Subject<string>).next(name);
  }

  public setBodyValid(valid: boolean): void {
    (this.bodyValid as Subject<boolean>).next(valid);
  }

  public setBackButtonVisible(visible: boolean): void {
    (this.backButtonVisible as Subject<boolean>).next(visible);
  }

  public setStaticHeadingAction(name: string, action: string): void {
    const formattedHeading = `${action} "${name}"`;
    this.setStaticHeading(formattedHeading);
  }

  public setStaticHeading(name: string): void {
    (this.staticHeading as Subject<string>).next(name);
  }

  public setBreadcrumbs(breadcrumbs: Array<Breadcrumb>): void {
    (this.breadcrumbs as Subject<Array<Breadcrumb>>).next(breadcrumbs);
  }

  //TODO remove once all name validation is moved out of header
  public setAllowDuplicateNames(allowDuplicateNames: boolean): void {
    (this.allowDuplicateNames as Subject<boolean>).next(allowDuplicateNames);
  }

  public setHeaderInvalidNameList(invalidNames: Array<string>): void {
    (this.invalidNames as Subject<Array<string>>).next(invalidNames);
  }

  public setCustomNameError(nameError: string): void {
    (this.customHeaderNameError as Subject<string>).next(nameError);
  }

  public setFooterItems(items: Array<SlideOutFooterItem>): void {
    (this.extraFooterItems as Subject<Array<SlideOutFooterItem>>).next(items);
  }

  public setUseDefaultHeaderBorder(useDefaultHeaderBorder: boolean): void {
    (this.useDefaultHeaderBorder as Subject<boolean>).next(useDefaultHeaderBorder);
  }

  public setSaveButtonText(saveBtnText: string): void {
    (this.saveButtonText as Subject<string>).next(saveBtnText);
  }

  public setCalcError(error?: string): void {
    (this.calcError as Subject<string | undefined>).next(error);
  }
}
