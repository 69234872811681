import { ColDef } from 'ag-grid-community';

import { E2gMenuItem } from '../cell-renders/grid-cell-menu/e2g-menu-item';
import { GridCellMenuComponent } from '../cell-renders/grid-cell-menu/grid-cell-menu-component';

export function buildActionsColDef(menu: Array<E2gMenuItem>): ColDef {
  return {
    headerName: 'Actions',
    headerTooltip: 'Actions',
    width: 130,
    cellStyle: { textAlign: 'center' },
    headerClass: 'ag-header-center',
    cellRenderer: GridCellMenuComponent,
    cellRendererParams: { menuItems: menu },
    filter: false
  };
}
