import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AppSettingsService } from './app-settings/app-settings.service';
import { PlantsWithUnitsDto } from './dashboard-module/models/plant-with-units-dto';
import { PlantDto } from './models/plant-dto';

@Injectable({
  providedIn: 'root'
})
export class PlantDataService {
  private serverUri: string;

  public constructor(private http: HttpClient, private settingsService: AppSettingsService) {
    this.serverUri = this.settingsService.settings.apiUri as string;
  }

  public getPlants(): Observable<Array<PlantDto>> {
    return this.http.get<Array<PlantDto>>(`${this.serverUri}/plants`).pipe(catchError(() => of(new Array<PlantDto>())));
  }

  public getPlantsWithUnits(): Observable<Array<PlantsWithUnitsDto>> {
    return this.http
      .get<Array<PlantsWithUnitsDto>>(`${this.serverUri}/plants/units`)
      .pipe(catchError(() => of(new Array<PlantsWithUnitsDto>())));
  }

  public addPlant(newPlant: PlantDto): Observable<boolean> {
    return this.http.post<string>(`${this.serverUri}/plants/`, newPlant).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  public updatePlant(editPlant: PlantDto): Observable<boolean> {
    return this.http.put<string>(`${this.serverUri}/plants/`, editPlant).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  public deletePlant(plantId: string): Observable<boolean> {
    return this.http.delete<boolean>(`${this.serverUri}/plants/${plantId}`).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  public getPlant(plantId: string): Observable<PlantDto> {
    return this.http.get<PlantDto>(`${this.serverUri}/plants/${plantId}`).pipe(
      catchError(() => of({} as PlantDto))
    );
  }
}
