<div class="d-flex flex-row align-items-center h-100">
  <input
    *ngIf="!isReadonly(); else readonlyCheck"
    type="checkbox"
    [checked]="checked"
    (change)="onChange($event)"
    class="grid-checkbox pointer"
  />
  <app-grid-cell-link-label *ngIf="label" [label]="label" [params]="params" class="ml-1"></app-grid-cell-link-label>
</div>

<ng-template #readonlyCheck>
  <fa-icon *ngIf="checked; else unchecked" [icon]="faCheckSquare" size="lg" class="ag-grid-checkbox"></fa-icon>
</ng-template>
<ng-template #unchecked>
  <fa-icon #unchecked [icon]="faSquare" size="lg" class="ag-grid-checkbox"></fa-icon>
</ng-template>
