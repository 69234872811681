export enum UnitDetailsTabs {
  Unit = 1,
  Assets = 2,
  Components = 3,
  Groups = 4,
  Cmls = 5,
  Activities = 6,
  Findings = 7,
  PressureReliefDevices = 8,
  Comments = 9,
  Configuration = 10
}
