import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

import { GridCellLinkParams } from './grid-cell-link-params';

@Component({
  selector: 'app-grid-cell-link',
  templateUrl: './grid-cell-link.component.html',
  styleUrls: ['./grid-cell-link.component.css']
})
export class GridCellLinkComponent implements ICellRendererAngularComp {
  public params!: ICellRendererParams & GridCellLinkParams;

  public cellValue: string = '';
  public id: string = '';

  public agInit(params: ICellRendererParams & GridCellLinkParams): void {
    this.setParams(params);
  }

  public refresh(params: ICellRendererParams & GridCellLinkParams): boolean {
    this.setParams(params);
    return true;
  }

  private setParams(params: ICellRendererParams & GridCellLinkParams): void {
    this.params = params;

    this.cellValue = params.value;

    this.id = params.data.itemId;
  }
}
