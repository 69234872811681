import { Injectable } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { E2gSelectOption } from '@equityeng/e2g-ng-ui';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { EquipmentDataService } from '../equipment-data.service';
import { AssetTypes } from '../models/enums/asset-types';
import { EquipmentUnitInput } from '../models/equipment-unit-input';
import { BusinessRulesServiceBase } from '../sage-common-module/BusinessRulesServiceBase';
import { BusinessRuleValueList } from '../sage-common-module/models/business-rule-value-list';
import { BusinessRulesDefinition } from '../sage-common-module/models/business-rules-definition';
import { UnitsOfMeasureEvaluator } from '../units-of-measure/units-of-measure-evaluator';
import { PRD_DISCHARGE_LOCATION_VALUES, PrdDischargeLocation } from './models/prd-discharge-location';
import { PRD_END_TYPE_VALUES } from './models/prd-end-type';
import { PRD_TYPE_VALUES, PrdType } from './models/prd-type';

@Injectable()
export class PrdBusinessRulesService extends BusinessRulesServiceBase implements BusinessRulesDefinition {
  public inputData!: EquipmentUnitInput;
  public assetsToSelect?: Observable<Array<E2gSelectOption>>;
  public initPrdProtectedAssetId?: string;

  private RATING_VALUES: Array<E2gSelectOption> = [
    { value: 150, label: '150' },
    { value: 300, label: '300' },
    { value: 400, label: '400' },
    { value: 600, label: '600' },
    { value: 900, label: '900' },
    { value: 1500, label: '1500' },
    { value: 2500, label: '2500' }
  ];

  public constructor(
    private equipDataService: EquipmentDataService,
    private route: ActivatedRoute,
    uomEvaluator: UnitsOfMeasureEvaluator
  ) {
    super(uomEvaluator);

    this.route.params.subscribe((params) => this.setInputData(params));
  }

  public get valueData(): BusinessRuleValueList {
    if (this.data === undefined) {
      throw 'init must be called before valueData()';
    } else {
      return {
        reliefDeviceType: {
          getValidValues: () => of(PRD_TYPE_VALUES),
          getDefaultValue: () => of(PrdType.Conventional)
        },
        orificeArea: {
          getRequired: () => true
        },
        inletSize: {
          getRequired: () => true
        },
        setPressure: {
          getRequired: () => true
        },
        // inServiceDate: {
        //   getRequired: () => false
        // },
        dischargeLocation: {
          getValidValues: () => of(PRD_DISCHARGE_LOCATION_VALUES),
          getDefaultValue: () => of(PrdDischargeLocation.Flare)
        },
        inletType: {
          getValidValues: () => of(PRD_END_TYPE_VALUES)
        },
        outletType: {
          getValidValues: () => of(PRD_END_TYPE_VALUES)
        },
        inletRating: {
          getValidValues: () => of(this.RATING_VALUES)
        },
        outletRating: {
          getValidValues: () => of(this.RATING_VALUES)
        },
        prdProtectedAssetId: {
          getValidValues: (): Observable<Array<E2gSelectOption>> => {
            if (this.assetsToSelect) {
              return this.assetsToSelect;
            }
            return this.setAssetsToSelect();
          }
        },
        protectedAssets: {
          getValidValues: (): Observable<Array<E2gSelectOption>> => {
            if (this.assetsToSelect) {
              return this.assetsToSelect;
            }
            return this.setAssetsToSelect();
          }
        }
      };
    }
  }

  private setInputData(params: Params): void {
    this.inputData = {
      equipmentKey: params.equipmentKey,
      unitKey: params.unitKey
    };
  }

  public setAssetsToSelect(): Observable<Array<E2gSelectOption>> {
    this.assetsToSelect = this.equipDataService.getEquipment(this.inputData.unitKey).pipe(
      map((x) =>
        x
          .filter((y) => y.id !== this.data.assetId && y.type !== AssetTypes.PressureReliefDevice)
          .map((y) => ({ value: y.id, label: y.name } as E2gSelectOption))
          .filter((y) => y.value !== this.data.assetId)
      )
    );
    return this.assetsToSelect;
  }
}
