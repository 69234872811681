import { AssetDetailTabs } from '../asset-module/detail/detail.component';
import { UnitDetailsTabs } from '../assets/unit-details-module/models/enums/unit-details-tabs';
import { UnitOfMeasure } from '../models/enums/unit-of-measure';
import { SourceLocation } from '../shared-module/models/Enums/source-location';

//Remove/Replace with Navigation Service?
export class RouterHelper {
  public static getAssetDetailsRouteCommands(
    unitKey: string,
    assetKey: string,
    unitOfMeasure: string | UnitOfMeasure,
    activeTabId: AssetDetailTabs = AssetDetailTabs.Asset,
    sourceLocation: SourceLocation = SourceLocation.AssetTree
  ): any[] {
    return [
      'assets/detail',
      {
        unitKey,
        equipmentKey: assetKey,
        unitOfMeasure: typeof unitOfMeasure == 'string' ? unitOfMeasure : UnitOfMeasure[unitOfMeasure],
        activeTabId,
        sourceLocation
      }
    ];
  }

  public static getUnitDetailsRouteCommands(
    plantKey: string,
    unitKey: string,
    unitOfMeasure: string | UnitOfMeasure,
    activeTabId: UnitDetailsTabs = UnitDetailsTabs.Assets,
    sourceLocation: SourceLocation = SourceLocation.AssetTree
  ): any[] {
    return [
      'assets/unit/details',
      {
        plantKey,
        unitKey,
        unitOfMeasure: typeof unitOfMeasure == 'string' ? unitOfMeasure : UnitOfMeasure[unitOfMeasure],
        activeTabId,
        sourceLocation
      }
    ];
  }
}
