<ng-container *ngIf="!previewData; else previewPane">
  <div *ngIf="!readonly">
    <button id="btn-add-attachment" class="btn e2g-btn-outline-primary" size="lg" (click)="clickUpload()">
      <fa-icon class="mr-2" size="lg" [icon]="faUpload"></fa-icon> Upload File
    </button>
  </div>
  <div class="row" *ngIf="attachments; else loading" cdkDropListGroup>
    <div
      cdkDropList
      class="col card-min-width mt-3"
      (cdkDropListEntered)="onDropListEntered($event)"
      (cdkDropListDropped)="onDropListDropped()"
    ></div>
    <div
      class="col card-min-width mt-3"
      *ngFor="let attachment of attachments"
      cdkDropList
      (cdkDropListEntered)="onDropListEntered($event)"
      (cdkDropListDropped)="onDropListDropped()"
    >
      <app-attachment-display
        cdkDrag
        [cdkDragPreviewContainer]="containerElement"
        (cdkDragMoved)="dragMoved($event)"
        [data]="attachment"
        [previewDataReader]="previewDataReader!"
        [readonly]="readonly"
        (onDelete)="delete($event)"
        (onPreview)="preview($event)"
        (onDownload)="download($event)"
      >
      </app-attachment-display>
    </div>
    <div class="col card-min-width" *ngFor="let col of extraColumns"></div>
  </div>
</ng-container>
<ng-template #previewPane>
  <div class="h-100 w-100 flex-col flex-111">
    <div class="mb-2">
      <button id="btn-closeImageViewer" class="btn e2g-btn-outline-primary" (click)="closePreview()">Close</button>
    </div>
    <div class="h-100 overflow-auto">
      <ng-container [ngSwitch]="previewData!.fileType">
        <app-img-display
          *ngSwitchCase="fileTypeImage"
          id="img-preview"
          class="fit-preview-image"
          [imgData]="getPreviewAttachmentFile"
        ></app-img-display>
        <app-pdf-viewer
          *ngSwitchCase="fileTypePdf"
          [dataReader]="getPreviewAttachmentFile"
          [filename]="previewData!.fileName"
        ></app-pdf-viewer>
        <div id="text-preview" *ngSwitchCase="fileTypeText">{{ previewText }}</div>
      </ng-container>
    </div>
  </div>
</ng-template>
<ng-template #loading>
  <div class="loading"></div>
</ng-template>
