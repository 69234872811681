import { Component } from '@angular/core';
import { faEllipsis } from '@fortawesome/pro-solid-svg-icons';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { combineLatest, Observable, of, take } from 'rxjs';
import { OnDestroyBaseComponent } from 'src/app/on-destroy-base-component/on-destroy-base-component';

import { E2gMenuItem } from './e2g-menu-item';
import { GridCellMenuParams } from './grid-cell-menu-params';

@Component({
  selector: 'app-grid-cell-menu',
  templateUrl: './grid-cell-menu-component.html',
  styleUrls: ['./grid-cell-menu-component.css']
})
export class GridCellMenuComponent extends OnDestroyBaseComponent implements ICellRendererAngularComp {
  public cellValue: string = '';
  public ellipsis = faEllipsis;

  public params!: ICellRendererParams;
  public menuItems: Array<E2gMenuItem> = [];
  public isVisibleValues = false;
  public isLink: boolean = false;
  public linkCommand?: (params: ICellRendererParams) => void;
  public menuId?: string;
  //Used to load menu icons once they are needed to reduce row render time on grids with many menu items and rows.
  public isMenuIconClicked = false;

  public agInit(params: ICellRendererParams & GridCellMenuParams): void {
    this.params = params;
    this.menuId = params.idField !== undefined ? params.data[params.idField] : params.data.id;
    this.cellValue = this.getValueToDisplay(params);
    this.linkCommand = params.linkCommand;
    this.isLink = params.isLink !== undefined ? params.isLink(params) : false;
    combineLatest(params.menuItems.map((x) => this.visible(x)))
      .pipe(take(1))
      .subscribe((isVisibleList) => {
        this.isVisibleValues = isVisibleList.some((x) => x);
      });
    this.menuItems = params.menuItems;
  }

  public refresh(params: ICellRendererParams & GridCellMenuParams): boolean {
    this.cellValue = this.getValueToDisplay(params);
    this.params = params;
    return true;
  }

  public getStyle(item: E2gMenuItem): string {
    if (item.iconDefinition && item.iconDefinition.color) {
      return `color: ${item.iconDefinition.color}`;
    }
    return '';
  }

  public click(item: E2gMenuItem): void {
    if (item.command) {
      item.command(this.params);
    }
  }

  public disabled(item: E2gMenuItem): boolean {
    if (item.disabled) {
      return item.disabled(this.params);
    }

    return false;
  }

  public visible(item: E2gMenuItem): Observable<boolean> {
    if (item.visible) {
      const result = item.visible(this.params);
      if (typeof result == 'boolean') {
        return of(result);
      }
      return result;
    }

    return of(true);
  }

  public isDivider(item: E2gMenuItem): boolean {
    return item.text === '---';
  }

  public onLinkClick(): void {
    if (this.linkCommand !== undefined) {
      this.linkCommand(this.params);
    }
  }

  private getValueToDisplay(params: ICellRendererParams & GridCellMenuParams): string {
    return params.valueFormatted ? params.valueFormatted : params.value;
  }
}
