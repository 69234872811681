import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '@equityeng/auth';
import { CellClassParams, GridApi, ValueGetterParams } from 'ag-grid-community';
import { ReadingDto } from 'src/app/asset-module/models/reading-dto';
import { ActivityDataService } from 'src/app/asset-module/services/activity-data.service';
import { GridCellTooltipComponent } from 'src/app/grid-module/cell-renders/grid-cell-tooltip/grid-cell-tooltip.component';
import { GridCheckboxComponent } from 'src/app/grid-module/cell-renders/grid-checkbox/grid-checkbox.component';
import { buildDateOnlyColDef } from 'src/app/grid-module/column-builders/build-date-only';
import { doubleWithNullComparator } from 'src/app/grid-module/column-data-comparers/double-with-null-comparator';
import { stringWithNullComparer } from 'src/app/grid-module/column-data-comparers/string-with-blank-comparator';
import { decimalFormatter } from 'src/app/grid-module/column-formatters/decimal-formatter';
import { GridBaseOptions } from 'src/app/grid-module/e2g-ag-grid/e2g-ag-grid.component';
import { OnDestroyBaseComponent } from 'src/app/on-destroy-base-component/on-destroy-base-component';
import { DecimalEditor } from 'src/app/shared-module/ag-grid/editor-components/decimal-editor';
import { ListDataHandler } from 'src/app/shared-module/list-data-handler';
import { SlideOutContainerService } from 'src/app/slide-out-module/slide-out-container.service';

import { AppSettingsService } from '../../app-settings/app-settings.service';

@Component({
  selector: 'app-activity-details-readings',
  templateUrl: './activity-details-readings.component.html',
  styleUrls: ['./activity-details-readings.component.css']
})
export class ActivityDetailsReadingsComponent extends OnDestroyBaseComponent implements OnInit {
  @Input() public readingsGridDataHandler!: ListDataHandler<ReadingDto>;
  @Input() public readonly: boolean = false;

  private thicknessClassRules = {
    'ag-cell-hover-outline': (): boolean => {
      return !this.readonly;
    },
    'thickness-warning': (params: CellClassParams): boolean => {
      if (params.data.thickness !== undefined && params.data.minimumThickness !== undefined) {
        return (
          params.data.thickness !== 0 &&
          params.data.thickness <= Number(Number(params.data.minimumThickness).toFixed(3))
        );
      }

      return false;
    },
    'thickness-growth': (params: CellClassParams): boolean => {
      if (params.data?.thickness > 0 && params.data?.priorThickness > 0) {
        return params.data.thickness > params.data.priorThickness;
      }
      return false;
    }
  };

  private isReadingCheckBoxEditableCell = {
    'ag-cell-hover-outline': (): boolean => {
      return !this.readonly;
    }
  };

  public readingGridOptions?: GridBaseOptions;

  public constructor(
    protected authService: AuthService,
    protected settingsService: AppSettingsService,
    protected activityDataService: ActivityDataService,
    private slideOutContainerService: SlideOutContainerService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.readingGridOptions = {
      exportFileName: 'Readings',
      context: 'survey-readings',
      enterNavigatesVertically: true,
      enterNavigatesVerticallyAfterEdit: true,
      rowSelection: 'single',
      onCellValueChanged: this.dataChanged.bind(this),
      setDefaultSort: (columnApi: GridApi): void => {
        columnApi.applyColumnState({
          state: [
            {
              colId: 'cml',
              sort: 'asc'
            },
            {
              colId: 'circuit',
              sort: 'asc'
            },
            {
              colId: 'component',
              sort: 'asc'
            }
          ]
        });
      },
      defaultColDef: {
        resizable: true,
        sortable: true,
        minWidth: 125,
        width: 150,
        tooltipComponent: GridCellTooltipComponent,
        filter: 'agSetColumnFilter'
      },
      columnDefs: [
        {
          headerName: 'CML',
          field: 'cml'
        },
        {
          headerName: 'Group',
          field: 'circuit'
        },
        {
          headerName: 'Component',
          field: 'component'
        },
        {
          headerName: 'Inspection Group',
          headerTooltip: 'Inspection Group',
          field: 'inspectionGroup',
          tooltipField: 'inspectionGroup',
          comparator: stringWithNullComparer,
          width: 175
        },
        {
          headerName: 'Thickness',
          headerTooltip: 'Thickness',
          field: 'thickness',
          editable: !this.readonly,
          tooltipField: 'thickness',
          cellClassRules: this.thicknessClassRules,
          cellEditor: DecimalEditor,
          comparator: doubleWithNullComparator,
          valueGetter: decimalFormatter,
          filterValueGetter: (params: ValueGetterParams): any => Number(decimalFormatter(params)),
          filter: 'agNumberColumnFilter'
        },
        {
          headerName: 'Exclude Thickness',
          headerTooltip: 'Exclude Thickness',
          field: 'excludeFromCalculation',
          tooltipField: 'excludeFromCalculation',
          minWidth: 150,
          width: 150,
          cellClassRules: this.isReadingCheckBoxEditableCell,
          cellRenderer: GridCheckboxComponent,
          cellRendererParams: { invert: false, readonly: this.readonly }
        },
        {
          hide: true,
          ...buildDateOnlyColDef('Survey Date', 'date')
        }
      ]
    };
  }

  private dataChanged(event: any): void {
    if (event.type === 'cellValueChanged') {
      const changedRowData = this.readingsGridDataHandler
        .getChangedData()
        ?.find((x: ReadingDto) => x.id === event.node.id);

      if (changedRowData) {
        changedRowData.excludeFromCalculation = event.node.data.excludeFromCalculation;
      }

      this.readingsGridDataHandler.dataChanged();
      this.slideOutContainerService.setDirty(this.readingsGridDataHandler.dirty);
    }
  }
}
