import { dateOnly, DateOnlyUtility } from '@equityeng/e2g-ng-ui';
import { ColDef, IRowNode, ValueFormatterParams } from 'ag-grid-community';

import { defaultWithNullComparer } from '../column-data-comparers/default-with-null-comparator';
import { assignHeaderAndTooltip } from './build-default';

export function buildDateOnlyColDef(header: string, field: string): ColDef {
  return {
    ...assignHeaderAndTooltip(header, field),
    valueFormatter: dateFormatter,
    comparator: dateWithNullComparator,
    filter: 'agDateColumnFilter',
    filterParams: {
      comparator: dateFilterComparator
    }
  };
}

function dateFormatter(params: ValueFormatterParams): string {
  return DateOnlyUtility.formatAsE2gDate(params.data[params.colDef.field || '']);
}

function dateFilterComparator(filterLocalDateAtMidnight: Date, cellValue: dateOnly | undefined): number {
  if (!cellValue) {
    return 0;
  }

  return DateOnlyUtility.compare(DateOnlyUtility.convertDateToDateOnly(filterLocalDateAtMidnight), cellValue);
}

function dateWithNullComparator(
  valueA: dateOnly,
  valueB: dateOnly,
  nodeA: IRowNode,
  nodeB: IRowNode,
  isInverted: boolean
): number {
  return defaultWithNullComparer(valueA, valueB, isInverted, (a, b) => DateOnlyUtility.compare(a, b));
}
