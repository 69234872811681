import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';

import { AppSettingsService } from '../../app-settings/app-settings.service';
import { ComponentRecordDto } from '../../models/component-record-dto';

@Injectable({
  providedIn: 'root'
})
export class CompDetailDataService {
  private serverUri: string;

  public constructor(private http: HttpClient, private settingsService: AppSettingsService) {
    this.serverUri = this.settingsService.settings.apiUri as string;
  }

  public getComponent(id: string): Observable<ComponentRecordDto> {
    return this.http.get<ComponentRecordDto>(`${this.serverUri}/comp/${id}`);
  }

  public createComponent(dto: ComponentRecordDto): Observable<string> {
    return this.http.post<string>(`${this.serverUri}/comp`, dto);
  }

  public updateComponent(dto: ComponentRecordDto): Observable<boolean> {
    return this.http.put(`${this.serverUri}/comp/${dto.id}`, dto).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }
}
