import { Component, Inject, OnInit } from '@angular/core';
import { dateOnly, DateOnlyUtility, E2gSelectOption } from '@equityeng/e2g-ng-ui';
import { GridApi } from 'ag-grid-community';
import { combineLatest, forkJoin, Observable, of, Subject } from 'rxjs';
import { filter, map, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { AlertService } from 'src/app/alert.service';
import { CmlGraph } from 'src/app/asset-module/cmls/cml-slideout/cml-graph';
import { BaseSlideoutOption } from 'src/app/asset-module/models/base-slideout-option';
import { CmlNameDto } from 'src/app/asset-module/models/cml-name-dto';
import { AttachmentTableType } from 'src/app/attachments-module/models/attachment-table-type';
import { EquipmentDataService } from 'src/app/equipment-data.service';
import { ScraRateData } from 'src/app/grid-module/cell-renders/grid-cell-scra-calc/grid-cell-scra-rate.component';
import { buildCheckboxColDef } from 'src/app/grid-module/column-builders/build-checkbox';
import { buildDateOnlyColDef } from 'src/app/grid-module/column-builders/build-date-only';
import { buildDefaultColDef } from 'src/app/grid-module/column-builders/build-default';
import { buildDoubleColDef } from 'src/app/grid-module/column-builders/build-double';
import { buildEnumColDef } from 'src/app/grid-module/column-builders/build-enum';
import { GridBaseOptions } from 'src/app/grid-module/e2g-ag-grid/e2g-ag-grid.component';
import { ComponentDefaultsDto } from 'src/app/models/component-defaults-dto';
import { MinimumThicknessChoice } from 'src/app/models/enums/minimum-thickness-choice';
import { EquipmentConfigurationDto } from 'src/app/models/equipment-configuration-dto';
import { RefreshService } from 'src/app/notifications-module/refresh.service';
import { OnDestroyBaseComponent } from 'src/app/on-destroy-base-component/on-destroy-base-component';
import { getInspectionDriver } from 'src/app/reporting-module/models/enums/Inspection-drivers';
import { BUSINESS_RULES } from 'src/app/sage-common-module/business-rules-injection-token';
import { BusinessRulesDefinition } from 'src/app/sage-common-module/models/business-rules-definition';
import { ListDataHandler } from 'src/app/shared-module/list-data-handler';
import { ActionTypes } from 'src/app/shared-module/models/action-types';
import { AssetFeature, GeneralFeature } from 'src/app/shared-module/permission/permission-models';
import { PermissionService } from 'src/app/shared-module/permission/permission.service';
import { NotificationService } from 'src/app/shared-module/services/notification.service';
import { SlideOutContainerAction } from 'src/app/slide-out-module/models/constants/slide-out-container-action';
import { SlideOutContainerService } from 'src/app/slide-out-module/slide-out-container.service';
import { SLIDE_OUT_DATA } from 'src/app/slide-out-module/slide-out-data-injection-token';
import { SlideOutRef } from 'src/app/slide-out-module/slide-out-ref';
import { StateService } from 'src/app/state.service';
import { UnitsOfMeasureEvaluator } from 'src/app/units-of-measure/units-of-measure-evaluator';
import { anyIdsInCommon } from 'src/app/utilities/array-helper';
import { formatScraRate } from 'src/app/utilities/format-scra-rate';
import { getMinimumThicknessLabels } from 'src/app/utilities/minium-thickness-helper';

import { CircuitDataService } from '../../../circuit-data.service';
import { CompDataService } from '../../../comp-data.service';
import { ComponentDto } from '../../../models/component-dto';
import { NEW_ITEM_KEY } from '../../../shared-module/models/new-item-key';
import { SingleDataHandlerDefault } from '../../../shared-module/single-data-handler-default';
import {
  duplicateCmlMessage,
  lengthMessage,
  requiredMessage,
  validCharactersMessage,
  valuePostiveIntegerMessage
} from '../../../utilities/validation-messages';
import { CmlDataDto } from '../../models/cml-data-dto';
import { CmlFormViewModel } from '../../models/cml-form-view-model';
import { CmlSlideoutInput } from '../../models/cml-slideout-input';
import { getGoverningCorrosionRateType } from '../../models/enums/governing-corrosion-rate-types';
import { HeaderBoxPlateType } from '../../models/enums/header-box-plate-type';
import { InspectionMethodTypes } from '../../models/enums/inspection-method-types';
import { ReadingDto } from '../../models/reading-dto';
import { CmlDataService } from '../../services/cml-data.service';
import { CmlBusinessRulesService } from '../../services/cml-slideout-business-rules.service';

@Component({
  selector: 'app-cml-slideout',
  templateUrl: './cml-slideout.component.html',
  styleUrls: ['./cml-slideout.component.css'],
  providers: [
    {
      provide: BUSINESS_RULES,
      useClass: CmlBusinessRulesService
    }
  ]
})
export class CmlSlideoutComponent extends OnDestroyBaseComponent implements OnInit {
  public getInspDriver = getInspectionDriver;

  private calcRefresh: Observable<void> = this.refreshService.updates.pipe(
    filter(
      (impactedIds) =>
        !this.dataHandler.dirty &&
        !this.readingsGridDataHandler.dirty &&
        impactedIds.cmlIds!.includes(this.dataHandler.data.id ?? '')
    ),
    map(() => undefined),
    startWith(undefined),
    takeUntil(this.destroy)
  );

  private calcRefreshComponent: Observable<void> = this.refreshService.updates.pipe(
    filter(
      (impactedIds) =>
        !this.dataHandler.dirty &&
        !this.readingsGridDataHandler.dirty &&
        anyIdsInCommon(
          impactedIds.componentIds,
          this.compList?.map((x) => x.id)
        )
    ),
    map(() => undefined)
  );

  public editing: boolean = false;
  public readOnly: boolean = false;

  public cmlGraph: CmlGraph = new CmlGraph();
  private assetChanged: boolean = false;
  private assetId: string = '';
  private saveCompleted = new Subject<void>();

  public compList: Array<ComponentDto> = [];
  public compSelectOptions: Array<E2gSelectOption> = [];

  public circuitList: Array<E2gSelectOption> = [];

  public loading: boolean = true;
  public noComponents = false;
  public activeTabId = 1;

  private readonly validChars = new RegExp("^[-a-zA-Z0-9'._ ]*$");

  public readonly validCharsString = "letters, digits, a space, and the following characters: _ - ' .";

  public cmlUsingMawpApproach = false;
  public tableType = AttachmentTableType.Cml;
  public attachmentKey?: string;

  public componentErrors: Array<string> = [];
  public singleOrientationErrors = [] as string[];
  public nextInspectionDateErrors = [] as string[];
  public multipleNameErrors = [] as string[];
  public multipleOrientationsErrors = [] as string[];

  public selectedThicknessLabel = '';
  public activeThicknessValue?: number;
  public thicknessList: Array<E2gSelectOption> = [];
  public thicknessLabels: Record<MinimumThicknessChoice, string> = getMinimumThicknessLabels();

  public specifiedThicknessErrors: Array<string> = [];
  public inputThickness = MinimumThicknessChoice.CmlSpecified;

  public componentId_headerBox?: string;

  public orientationIsTouched = false;
  public selectedComponent!: ComponentDto;
  public componentDefaults: ComponentDefaultsDto = {};
  public dataHandler: SingleDataHandlerDefault<CmlFormViewModel>;
  public readingsGridDataHandler: ListDataHandler<ReadingDto>;

  public minimumThickness: string = '';
  public assetMaximumInterval?: number;
  public maximumIntervalErrors: Array<string> = [];

  public hasScra = false;

  public readingGridOptions?: GridBaseOptions;

  public minimumThicknessUom: Observable<string> = this.uomEvaluator.getUnits('minimumThickness');
  public latestThicknessUom: Observable<string> = this.uomEvaluator.getUnits('minimumThickness');
  public governingCorrosionRateUom: Observable<string> = this.uomEvaluator.getUnits('estimatedCorrosionRate');
  public longRateUom: Observable<string> = this.uomEvaluator.getUnits('longRate');
  public shortRateUom: Observable<string> = this.uomEvaluator.getUnits('shortRate');
  public scraLongRateUom: Observable<string> = this.uomEvaluator.getUnits('scraLongRate');
  public scraShortRateUom: Observable<string> = this.uomEvaluator.getUnits('scraShortRate');
  public maximumAllowableWorkingPressureUom: Observable<string> = this.uomEvaluator.getUnits(
    'maximumAllowableWorkingPressure'
  );
  public recommendedMinimumTemperatureUom: Observable<string> = this.uomEvaluator.getUnits('designTemperature');
  public scraLongRateStatisticalUom: Observable<string> = this.uomEvaluator.getUnits('scraLongRateStatistical');
  public scraShortRateStatisticalUom: Observable<string> = this.uomEvaluator.getUnits('scraShortRateStatistical');
  public linearRateUom: Observable<string> = this.uomEvaluator.getUnits('linearRate');

  public assetConfig!: EquipmentConfigurationDto;

  private cmlNamesForAsset?: Array<CmlNameDto>;

  public constructor(
    private permissionService: PermissionService,
    private cmlService: CmlDataService,
    private componentService: CompDataService,
    private circuitDataService: CircuitDataService,
    private equipmentDataService: EquipmentDataService,
    private notificationService: NotificationService,
    private stateService: StateService,
    private uomEvaluator: UnitsOfMeasureEvaluator,
    private slideOutRef: SlideOutRef,
    private slideOutContainerService: SlideOutContainerService,
    private refreshService: RefreshService,
    private alertService: AlertService,
    @Inject(SLIDE_OUT_DATA) private slideOutData: CmlSlideoutInput,
    @Inject(BUSINESS_RULES) private businessRulesService: BusinessRulesDefinition
  ) {
    super();
    this.configureSlideOut();

    this.readingsGridDataHandler = new ListDataHandler<ReadingDto>(
      'id',
      'updateType',
      this.destroy,
      undefined,
      (dirty: boolean) => {
        this.slideOutContainerService.setDirty(dirty || this.dataHandler?.dirty);
      }
    );

    this.dataHandler = new SingleDataHandlerDefault<CmlFormViewModel>(this.destroy, (dirty: boolean) => {
      this.slideOutContainerService.setDirty(dirty || this.readingsGridDataHandler.dirty);
    });

    this.businessRulesService.init(this.dataHandler);
  }

  public ngOnInit(): void {
    this.initializeSlideoutHeader();

    // This happens when a user navigates to another CML or the slideout is initially loaded.
    this.slideOutContainerService.optionId
      .pipe(
        tap((id) => this.initializeForm(id)),
        switchMap(() => forkJoin(this.loadAssetData())),
        switchMap(() => this.getCml(this.slideOutData.id)),
        takeUntil(this.destroy)
      )
      .subscribe((cml) => this.loadingFormComplete(cml));

    combineLatest([this.saveCompleted, this.calcRefresh])
      .pipe(
        tap(() => this.initializeForm(this.slideOutData.id)),
        switchMap(() => this.getCmlNamesForAsset(this.slideOutData.assetId)),
        switchMap(() => this.getCml(this.slideOutData.id)),
        takeUntil(this.destroy)
      )
      .subscribe((cml) => this.loadingFormComplete(cml));

    this.calcRefreshComponent
      .pipe(
        switchMap(() => this.getComponents(this.assetId)),
        takeUntil(this.destroy)
      )
      .subscribe();
  }

  private initializeForm(id: string): void {
    this.loading = true;
    this.slideOutData.id = id;

    const previousAssetId = this.assetId;
    this.assetId = this.editing ? this.slideOutData.optionsData[id].assetId : this.slideOutData.assetId;

    this.assetChanged = previousAssetId !== this.assetId;

    this.dataHandler.clear();

    if (this.editing) {
      this.attachmentKey = id;
      this.stateService.setSelectedGridId(this.slideOutData.gridContext!, id);
    }
  }

  private loadAssetData(): Array<Observable<any>> {
    return this.assetChanged
      ? [
          this.getPermissions(this.assetId),
          this.getAssetConfiguration(this.assetId),
          this.getCircuits(this.assetId),
          this.getComponents(this.assetId),
          this.getCmlNamesForAsset(this.assetId)
        ]
      : [of(undefined)];
  }

  private getCml(id: string): Observable<CmlDataDto> {
    return (this.editing ? this.cmlService.getCml(id) : this.getEmptyCmlDataDto()).pipe(
      tap((cml: CmlDataDto) => {
        //
        // Not a new Cml.
        //
        if (this.editing) {
          this.componentId_headerBox = `${cml.componentId}_${cml.headerBoxPlate ?? ''}`;

          if (cml.readings) {
            this.loadReadingGridOptions();
            this.readingsGridDataHandler.setInitialData(cml.readings);
            this.slideOutContainerService.setDirty(false);
          }
        } else {
          //
          // New Cml.
          //

          cml.componentId = undefined;
          cml.inServiceDate = undefined;

          cml.defaultProperties = ['inServiceDate', 'circuitId', 'accessLimitations'];

          this.readingsGridDataHandler.setInitialData([]);
        }
      }),
      takeUntil(this.destroy)
    );
  }

  private getPermissions(assetId: string): Observable<void> {
    return this.permissionService
      .getPermissions({
        asset: [{ feature: AssetFeature.Cml, assetId: assetId }],
        general: [{ feature: GeneralFeature.ScraRates }]
      })
      .pipe(
        tap((permissions) => {
          this.readOnly = !permissions.asset[AssetFeature.Cml].edit;
          this.slideOutContainerService.setAllowRename(!this.readOnly);

          this.hasScra = permissions.general[GeneralFeature.ScraRates];
        }),
        map(() => undefined),
        take(1)
      );
  }

  private getAssetConfiguration(assetId: string): Observable<void> {
    return this.equipmentDataService.getEquipmentConfiguration(assetId).pipe(
      tap((config) => {
        this.assetConfig = config;
        this.assetMaximumInterval = config.maximumInterval;
      }),
      map(() => undefined),
      takeUntil(this.destroy)
    );
  }

  private getCircuits(assetId: string): Observable<void> {
    return this.circuitDataService.getCircuits(assetId).pipe(
      tap((circuits) => {
        this.circuitList = circuits.nameIdToE2gSelectOptions();
      }),
      map(() => undefined),
      takeUntil(this.destroy)
    );
  }

  public circuitDisabled(): boolean {
    return (
      (this.circuitList.length <= 1 && this.dataHandler.data.circuitId !== undefined) ||
      (this.circuitList.length === 1 && this.dataHandler.data.circuitId !== undefined)
    );
  }

  private getComponents(assetId: string): Observable<void> {
    return this.componentService.getComponentsForAsset(assetId).pipe(
      tap((components: Array<ComponentDto>) => {
        this.compList = components;

        if (components.length === 0) {
          this.noComponents = true;
          this.componentErrors = [' No components found in equipment, please add components first'];
        }

        this.compSelectOptions = this.mapComponentDto(components);
      }),
      map(() => undefined),
      takeUntil(this.destroy)
    );
  }

  private getCmlNamesForAsset(assetId: string): Observable<void> {
    return this.cmlService.getCmlNamesForAsset(assetId).pipe(
      tap((cmlNames: Array<CmlNameDto>) => {
        this.cmlNamesForAsset = cmlNames;
      }),
      map(() => undefined),
      takeUntil(this.destroy)
    );
  }

  private loadingFormComplete(cml: CmlDataDto): void {
    if (this.editing) {
      this.setSelectedComponent(cml!.componentId, cml!.headerBoxPlate);

      if (!cml!.isMinimumThicknessOverridden) {
        cml!.governingMinimumThickness = this.selectedComponent.governingMinimumThickness;
      }
    }

    cml!.accessLimitations = this.assetConfig.accessLimitations;

    this.setupValidation();
    this.dataHandler.setInitialData(this.processModel(cml!));
    this.updateInvalidNameList();

    this.alertService.updateIdsInEdit({
      cmlIds: [this.dataHandler.data.id ?? ''],
      componentIds: this.compList.map((x) => x.id)
    });

    setTimeout(() => {
      this.businessRulesService.start();
    });

    if (this.editing) {
      setTimeout(() => {
        this.updateGraphData();
      });

      this.updateThicknessSelections();
    }

    this.loading = false;
  }

  private initializeSlideoutHeader(): void {
    this.slideOutContainerService.setOptions(this.buildDropdownOptions(this.slideOutData.optionsData));
    this.slideOutContainerService.setOptionId(this.slideOutData.id);
    this.slideOutContainerService.setBreadcrumbs(this.slideOutData.breadcrumbs);
    this.slideOutContainerService.setCustomNameError(duplicateCmlMessage);
  }

  private buildDropdownOptions(data: Record<string, BaseSlideoutOption>): Array<E2gSelectOption> {
    return Object.entries(data)
      .map(([key, value]) => ({ value: key, label: value.name }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  private configureSlideOut(): void {
    this.editing = this.slideOutData.id !== NEW_ITEM_KEY;

    this.slideOutContainerService.nameUpdated.pipe(takeUntil(this.destroy)).subscribe((newName) => {
      this.dataHandler.data.name = newName;
    });

    this.slideOutContainerService.action
      .pipe(
        filter((action) => action === SlideOutContainerAction.Revert),
        takeUntil(this.destroy)
      )
      .subscribe(() => {
        this.dataHandler.revertChanges();
        this.readingsGridDataHandler.revertChanges();

        //We can assume true since data cannot be saved with errors
        this.nextInspectionDateValidation(true);
        this.updateInvalidNameList();

        this.componentId_headerBox = `${this.dataHandler.data.componentId}_${
          this.dataHandler.data.headerBoxPlate ?? ''
        }`;

        this.setSelectedComponent(this.dataHandler.data.componentId, this.dataHandler.data.headerBoxPlate);

        setTimeout(() => {
          this.updateGraphData();
        });
      });

    //TODO refactor so we can properly separate add & edit
    this.slideOutContainerService.action
      .pipe(
        filter((action) => action === SlideOutContainerAction.Save || action === SlideOutContainerAction.SaveAndClose),
        switchMap((action) => {
          const cml = this.getFormData();
          if (this.editing) {
            return this.cmlService.updateCml(cml.id!, cml).pipe(
              tap((success: boolean) => {
                this.showResultAndRefresh(success, ActionTypes.Update, cml.name, action, cml.id);
              })
            );
          } else {
            return this.cmlService.createCml(cml).pipe(
              tap((newId) => {
                const success = newId !== undefined;
                this.showResultAndRefresh(success, ActionTypes.Add, cml.name, action, newId);
              })
            );
          }
        })
      )
      .subscribe();

    this.slideOutContainerService.action
      .pipe(
        filter((action) => action === SlideOutContainerAction.Cancel),
        takeUntil(this.destroy)
      )
      .subscribe(() => {
        this.slideOutRef.close();
      });
  }

  private addNewItemToHeaderDropdown(id: string): void {
    this.slideOutData.optionsData[id] = {
      name: this.dataHandler.data.name,
      assetId: this.assetId,
      componentId: this.dataHandler.data.componentId!
    };
  }

  private showResultAndRefresh(
    success: boolean,
    actionType: ActionTypes,
    name: string,
    action: SlideOutContainerAction,
    id?: string
  ): void {
    this.notificationService.showActionResult(success, actionType, name);
    if (success) {
      if (action === SlideOutContainerAction.SaveAndClose) {
        this.slideOutRef.close();
      } else {
        this.addNewItemToHeaderDropdown(id!);

        this.slideOutContainerService.setOptions(this.buildDropdownOptions(this.slideOutData.optionsData));

        if (this.editing) {
          this.saveCompleted.next();
        } else {
          this.editing = true;
          this.slideOutContainerService.setOptionId(id!);
        }
      }
    }
  }

  public updateInvalidNameList(): void {
    //TODO add unit tests once in MVVM format
    let names = this.cmlNamesForAsset!.filter(
      (x) =>
        x.componentId == this.dataHandler.data.componentId &&
        x.circuitId == this.dataHandler.data.circuitId &&
        x.id !== this.slideOutData.id
    ).map((x) => x.name);

    if (this.dataHandler.data.createMultipleCmls) {
      const endingsToRemove = this.toArrayFromCommaSeperatedList(this.dataHandler.data.orientationString).map(
        (_, index) => `.${index + 1}`
      );
      names = names
        .map((x) => {
          const matchingEnding = endingsToRemove.find((y) => x.endsWith(y));

          if (matchingEnding) {
            return x.slice(0, -1 * matchingEnding.length);
          }
          return '';
        })
        .filter((x) => x !== '');
    }

    this.slideOutContainerService.setHeaderInvalidNameList(names);
  }

  private getEmptyCmlDataDto(): Observable<CmlDataDto> {
    return of({
      name: '',
      orientation: '',
      InjectionPoint: false,
      soilToAir: false,
      isMinimumThicknessOverridden: false,
      defaultProperties: new Array<string>()
    });
  }

  private processModel(cmlData: CmlDataDto): CmlFormViewModel {
    return {
      ...cmlData,
      orientations: new Array<string>(),
      orientationString: cmlData.orientation ?? '',
      createMultipleCmls: false,
      overrideNextInspection: cmlData.specifiedNextInspectionDate !== undefined
    };
  }

  private mapComponentDto(dto: Array<ComponentDto>): Array<E2gSelectOption> {
    return dto.map((val) => ({
      value: `${val.id}_${val.headerBoxPlate ?? ''}`,
      label: `${val.name} (${this.getComponentTypeString(val)}, size: ${(val.size || '').replace(/_/gi, ' ')}, nom: ${
        val.nominalThickness
      })`
    }));
  }

  private getComponentTypeString(val: ComponentDto): string {
    return `${val.type}${val.headerBoxPlate !== undefined ? `-${HeaderBoxPlateType[val.headerBoxPlate]}` : ''}`;
  }

  public toggleMultipleEntry(): void {
    if (this.dataHandler.data.createMultipleCmls) {
      this.updateMultipleOrientationsErrorsArray();
    } else {
      this.updateSingleOrientationErrorsArray();
    }

    this.updateInvalidNameList();
  }

  public updateSingleOrientationErrorsArray(): void {
    this.singleOrientationErrors = [];

    if (!this.isSingleOrientationLengthValid()) {
      this.singleOrientationErrors.push(lengthMessage(16));
    }

    if (!this.isSingleOrientationValid()) {
      this.singleOrientationErrors.push(validCharactersMessage(this.validCharsString));
    }
  }

  public updateMultipleOrientationsErrorsArray(): void {
    this.multipleOrientationsErrors = [];

    if (this.dataHandler.data.orientationString.trim().length === 0 && this.orientationIsTouched) {
      this.multipleOrientationsErrors.push(requiredMessage());
    }

    if (!this.isMultipleOrientationLengthValid()) {
      this.multipleOrientationsErrors.push(lengthMessage(16));
    }

    if (!this.isMultipleOrientationValid()) {
      this.multipleOrientationsErrors.push(validCharactersMessage(this.validCharsString));
    }
  }

  public getCompLabel(comp: ComponentDto): string {
    return `${comp.name} (${comp.type}, size:${comp.size}, nom:${comp.nominalThickness})`;
  }

  public setInsulationTypeOnCheck(): void {
    if (this.dataHandler.data.insulationType === '') {
      this.dataHandler.data.insulationType = undefined;
    }

    if (this.dataHandler.data.insulationType == undefined) {
      this.dataHandler.data.insulationType = this.selectedComponent.insulationType;
    }
  }

  public onComponentChange(): void {
    const compProperties = this.componentId_headerBox!.split('_');
    this.dataHandler.data.componentId = compProperties[0];
    this.dataHandler.data.headerBoxPlate =
      compProperties[1] === '' ? undefined : (Number(compProperties[1]) as HeaderBoxPlateType);

    this.setSelectedComponent(this.dataHandler.data.componentId, this.dataHandler.data.headerBoxPlate);
    if (!this.editing && this.selectedComponent) {
      this.dataHandler.data.insulationType = this.selectedComponent!.insulationType;
    }

    if (this.dataHandler.isDefaultValue('inServiceDate')) {
      this.resetInServiceDate();
    }

    if (this.dataHandler.isDefaultValue('circuitId')) {
      this.resetCircuit();
    }

    this.updateGraphData();

    this.updateInvalidNameList();
    this.updateThicknessSelections();
    this.checkIsValid();
  }

  private updateGraphData(): void {
    this.cmlGraph.getGraphData(
      this.readingsGridDataHandler.data,
      this.dataHandler.data.inServiceDate,
      this.cmlUsingMawpApproach,
      this.selectedComponent.nominalThickness,
      this.minimumThickness
    );
  }

  public nextInspectionDateValidation(isValid: boolean): void {
    this.nextInspectionDateErrors = [];

    if (!isValid) {
      this.nextInspectionDateErrors.push('Valid date required');
    }
  }

  public resetInServiceDate(): void {
    this.dataHandler.silentClearValue('inServiceDate');
    this.dataHandler.silentSetValue('inServiceDate', this.selectedComponent.inServiceDate, true);
    this.checkIsValid();
  }

  public resetAccessLimitations(): void {
    this.dataHandler.silentClearValue('accessLimitations');
    this.dataHandler.silentSetValue('accessLimitations', this.assetConfig.accessLimitations, true);
  }

  public resetMaximumInterval(): void {
    this.dataHandler.silentClearValue('maximumInterval');
    this.dataHandler.silentSetValue('maximumInterval', this.assetMaximumInterval, true);
    this.checkIsValid();
  }

  public resetCircuit(): void {
    this.dataHandler.silentClearValue('circuitId');
    this.dataHandler.silentSetValue('circuitId', this.selectedComponent.circuitId, true);
    this.updateInvalidNameList();
  }

  public checkMaximumIntervalDefault(): void {
    if (this.dataHandler.data.injectionPoint! || this.dataHandler.data.soilToAir!) {
      if (this.dataHandler.data.maximumInterval == undefined && !this.dataHandler.isDefaultValue('maximumInterval')) {
        this.resetMaximumInterval();
      }
    }
  }

  private isSingleOrientationValid(): boolean {
    return (
      this.validChars.test(this.dataHandler.data.orientationString) &&
      !this.hasMultipleSpaces(this.dataHandler.data.orientationString)
    );
  }

  private isMultipleOrientationValid(): boolean {
    const validChars = new RegExp("^[-a-zA-Z0-9'._ ,]*$"); //Adds comma

    return (
      validChars.test(this.dataHandler.data.orientationString) &&
      this.toArrayFromCommaSeperatedList(this.dataHandler.data.orientationString).filter((o) =>
        this.hasMultipleSpaces(o)
      ).length === 0
    );
  }

  private isSingleOrientationLengthValid(): boolean {
    return this.dataHandler.data.orientationString.length <= 16;
  }

  private isMultipleOrientationLengthValid(): boolean {
    return (
      this.toArrayFromCommaSeperatedList(this.dataHandler.data.orientationString).filter((o) => o.length > 16)
        .length === 0
    );
  }

  private isSingleCmlValid(): boolean {
    if (this.dataHandler.data.createMultipleCmls) {
      return true;
    }

    return this.singleOrientationErrors.length === 0;
  }

  private isMultipleCmlValid(): boolean {
    return (
      !this.dataHandler.data.createMultipleCmls ||
      (this.dataHandler.data.createMultipleCmls &&
        this.dataHandler.data.orientationString.trim().length > 0 &&
        this.multipleNameErrors.length === 0 &&
        this.multipleOrientationsErrors.length === 0)
    );
  }

  private checkMaximumIntervalValidate(): boolean {
    return this.dataHandler.data.injectionPoint || this.dataHandler.data.soilToAir
      ? this.maximumIntervalErrors.length === 0
      : true;
  }

  public checkIsValid(): void {
    const isValid =
      this.isMinThickValid() &&
      !this.noComponents &&
      this.selectedComponent !== undefined &&
      this.isSingleCmlValid() &&
      this.isMultipleCmlValid() &&
      this.checkMaximumIntervalValidate() &&
      (!this.dataHandler.data.overrideNextInspection ||
        (this.nextInspectionDateErrors.length === 0 && this.dataHandler.data.specifiedNextInspectionDate != undefined));

    this.slideOutContainerService.setBodyValid(isValid);
  }

  private hasMultipleSpaces(testString: string): boolean {
    return (testString.trim().match(/ /g) || []).length >= 2;
  }

  private setSelectedComponent(id?: string, headerBoxPlate?: HeaderBoxPlateType): void {
    if (id && id!.length > 0) {
      const component = this.compList!.find((x) => x.id === id && x.headerBoxPlate == headerBoxPlate);

      if (component) {
        this.selectedComponent = JSON.parse(JSON.stringify(component)) as typeof component as ComponentDto;
      }

      if (this.selectedComponent) {
        this.componentDefaults.circuitId = this.selectedComponent.circuitId;
        this.componentDefaults.inServiceDate = this.selectedComponent.inServiceDate;
      }
    }
  }

  public getFormData(): CmlFormViewModel {
    const data = this.dataHandler.getRawData() as CmlFormViewModel;

    data.defaultProperties = this.dataHandler.getDefaultPropNames();

    if (!data.injectionPoint && !data.soilToAir) {
      data.maximumInterval = undefined;
      data.defaultProperties = data.defaultProperties.filter((x) => x != 'MaximumInterval');
    }

    if (!data.overrideNextInspection) {
      data.specifiedNextInspectionDate = undefined;
      data.nextInspectionDateComments = undefined;
    }

    if (data.createMultipleCmls) {
      data.orientations =
        data.orientationString.length > 0 ? this.toArrayFromCommaSeperatedList(data.orientationString) : [];
    } else {
      data.orientations = [data.orientationString ?? ''];
    }

    data.readings = this.readingsGridDataHandler.getChangedData();
    return data;
  }

  private setupValidation(): void {
    this.dataHandler.setValidation({
      orientationString: () => this.validateOrientation(),
      maximumInterval: () => this.validateMaximumInterval()
    });

    this.checkIsValid();
  }

  private validateOrientation(): Observable<undefined> {
    if (this.dataHandler.data.createMultipleCmls) {
      this.updateMultipleOrientationsErrorsArray();
    } else {
      this.updateSingleOrientationErrorsArray();
    }

    this.checkIsValid();

    return of(undefined);
  }

  private toArrayFromCommaSeperatedList(listAsString: string): Array<string> {
    return listAsString
      .split(',')
      .map((item) => {
        return item.trim();
      })
      .filter((item) => item.length > 0);
  }

  private dataChanged(event: any): void {
    if (event.type === 'cellValueChanged' && ['excludeFromCalculation', 'isBaseline'].includes(event.colDef.field)) {
      const changedRowData = this.readingsGridDataHandler
        .getChangedData()
        ?.find((x: ReadingDto) => x.id === event.node.id);

      if (changedRowData) {
        changedRowData.excludeFromCalculation = event.node.data.excludeFromCalculation;
        changedRowData.isBaseline = event.node.data.isBaseline;
      }

      this.readingsGridDataHandler.dataChanged();

      this.slideOutContainerService.setDirty(this.dataHandler.dirty || this.readingsGridDataHandler.dirty);
    }
  }

  public getLatestThickness(): string {
    let latestThickness: string = '';

    if (this.readingsGridDataHandler.data !== undefined) {
      if (this.readingsGridDataHandler.data.length !== 0) {
        latestThickness = this.readingsGridDataHandler.data
          .sort((a, b) => DateOnlyUtility.sortDesc(a.date, b.date))[0]
          .thickness?.toFixed(3);
      }
    }

    return latestThickness;
  }

  public getLatestDate(): string {
    let latestDate: string = '';

    if (this.readingsGridDataHandler.data !== undefined) {
      if (this.readingsGridDataHandler.data.length !== 0) {
        latestDate = DateOnlyUtility.formatAsE2gDate(
          this.readingsGridDataHandler.data.sort((a, b) => DateOnlyUtility.sortDesc(a.date, b.date))[0].date
        );
      }
    }

    return latestDate;
  }

  public getGoverningCorrosionRateType(): string {
    return getGoverningCorrosionRateType(this.dataHandler.data.governingCorrosionRateType!);
  }

  public getMaximumAllowableWorkingPressureFromComponent(): string {
    let maximumAllowableWorkingPressure: string = '';

    if (this.selectedComponent?.maximumAllowableWorkingPressure !== undefined) {
      maximumAllowableWorkingPressure = this.selectedComponent.maximumAllowableWorkingPressure.toFixed(3);
    }

    return maximumAllowableWorkingPressure;
  }

  public getDesignTemperatureFromComponent(): string {
    let recommendedMinimumTemperature: string = '';

    if (this.selectedComponent?.designTemperature !== undefined) {
      recommendedMinimumTemperature = this.selectedComponent.designTemperature.toFixed(3);
    }

    return recommendedMinimumTemperature;
  }

  public getNextInspectionDate(): string | undefined {
    let nextInspectionDate: dateOnly | undefined;
    if (this.dataHandler.data !== undefined) {
      if (!this.dataHandler.data?.overrideNextInspection) {
        nextInspectionDate = this.dataHandler.data?.nextInspectionDate;
      } else {
        nextInspectionDate = this.dataHandler.data?.specifiedNextInspectionDate;
      }
    }

    return DateOnlyUtility.formatAsE2gDate(nextInspectionDate!);
  }

  public getRetirementDate(): string | undefined {
    return DateOnlyUtility.formatAsE2gDate(this.dataHandler.data.retirementDate!);
  }

  public getScraRate(rate: number, rateCode: string): string {
    const scraRateData: ScraRateData = formatScraRate(rate, rateCode);

    return scraRateData.startChar + scraRateData.rate + ' ' + scraRateData.code;
  }

  private validateMaximumInterval(): Observable<undefined> {
    this.maximumIntervalErrors = [];

    if (this.dataHandler.data.injectionPoint || this.dataHandler.data.soilToAir) {
      if (this.dataHandler.data.maximumInterval != undefined) {
        if (this.dataHandler.data.maximumInterval <= 0) {
          this.maximumIntervalErrors.push(valuePostiveIntegerMessage());
        }
      }
    }

    this.checkIsValid();

    return of(undefined);
  }

  private isMinThickValid(): boolean {
    return this.specifiedThicknessErrors.length == 0;
  }

  private getFormattedThicknessValue(value: number | undefined): string {
    return value === undefined || isNaN(value)
      ? MinimumThicknessChoice.Specified
        ? ''
        : '0.000'
      : Number(value).toFixed(3);
  }

  private updateThicknessSelections(): void {
    this.thicknessList = [];
    if (this.compTypeCanCalculateTmins()) {
      this.thicknessList.push(
        ...[
          {
            value: MinimumThicknessChoice.Retirement,
            label: `${this.thicknessLabels[MinimumThicknessChoice.Retirement]} (${this.getFormattedThicknessValue(
              this.selectedComponent.retirementThickness
            )})`
          },
          {
            value: MinimumThicknessChoice.Structural,
            label: `${this.thicknessLabels[MinimumThicknessChoice.Structural]} (${this.getFormattedThicknessValue(
              this.selectedComponent.structuralRequiredThickness
            )})`
          }
        ]
      );
    }
    this.thicknessList.push(
      ...[
        {
          value: MinimumThicknessChoice.Corroded,
          label: `${this.thicknessLabels[MinimumThicknessChoice.Corroded]}(${this.getFormattedThicknessValue(
            this.selectedComponent.corrodedThickness
          )})`
        },
        {
          value: MinimumThicknessChoice.Specified,
          label: `${this.thicknessLabels[MinimumThicknessChoice.Specified]}(${this.getFormattedThicknessValue(
            this.selectedComponent.specifiedMinimumThickness
          )})`
        },
        {
          value: MinimumThicknessChoice.CmlSpecified,
          label: `${this.thicknessLabels[MinimumThicknessChoice.CmlSpecified]}`
        }
      ]
    );
    if (this.compCanCalculateMawp()) {
      this.thicknessList.push({
        value: MinimumThicknessChoice.MawpApproach,
        label: `${this.thicknessLabels[MinimumThicknessChoice.MawpApproach]}`
      });
    }

    if (this.selectedComponent.governingMinimumThickness) {
      this.selectedThicknessLabel = this.selectedComponent.useMawpApproach
        ? 'MAWP Approach'
        : this.thicknessLabels[this.selectedComponent.governingMinimumThickness as MinimumThicknessChoice];
    }

    this.updateSelectedThickness();
  }

  private compTypeCanAlwaysBeCalculated(): boolean {
    return !(
      this.selectedComponent.type == 'HEADER BOX' ||
      this.selectedComponent.type == 'ROOF' ||
      this.selectedComponent.type == 'FLOOR' ||
      this.selectedComponent.type == 'ANNULAR PLATE'
    );
  }

  private compTypeCanCalculateTmins(): boolean {
    return this.compTypeCanAlwaysBeCalculated() && this.selectedComponent.type != 'NOZZLE';
  }

  private compCanCalculateMawp(): boolean {
    return (
      this.compTypeCanAlwaysBeCalculated() &&
      !(this.selectedComponent.type == 'NOZZLE' && !this.selectedComponent.useAdvancedCalculation) &&
      this.selectedComponent.type != 'COURSE'
    );
  }

  public updateSelectedThickness(): void {
    this.setGoverningThickness();
    this.cmlUsingMawpApproach = false;

    switch (this.dataHandler.data.governingMinimumThickness) {
      case MinimumThicknessChoice.Retirement:
        this.clearSpecifiedThickness();
        this.activeThicknessValue = this.selectedComponent?.retirementThickness;
        break;
      case MinimumThicknessChoice.Structural:
        this.clearSpecifiedThickness();
        this.activeThicknessValue = this.selectedComponent?.structuralRequiredThickness;
        break;
      case MinimumThicknessChoice.Corroded:
        this.clearSpecifiedThickness();
        this.activeThicknessValue = this.selectedComponent?.corrodedThickness;
        break;
      case MinimumThicknessChoice.Specified:
        this.clearSpecifiedThickness();
        this.activeThicknessValue = this.selectedComponent?.specifiedMinimumThickness;
        break;
      case MinimumThicknessChoice.CmlSpecified:
        this.setOverrideThickness();
        break;
      case MinimumThicknessChoice.MawpApproach:
        this.cmlUsingMawpApproach = true;
        break;
    }

    this.minimumThickness = this.getFormattedThicknessValue(this.activeThicknessValue);
    this.checkIsValid();
  }

  private clearSpecifiedThickness(): void {
    if (this.specifiedThicknessErrors.length > 0) {
      this.dataHandler.data.specifiedMinimumThickness = undefined;
      this.specifiedThicknessErrors = [];
    }

    this.checkIsValid();
  }

  private updateMinThkErrors(): void {
    if (
      this.dataHandler.data.isMinimumThicknessOverridden &&
      this.dataHandler.data.governingMinimumThickness === MinimumThicknessChoice.CmlSpecified
    ) {
      if (!this.dataHandler.data.specifiedMinimumThickness) {
        this.specifiedThicknessErrors = ['Required'];
      } else if (
        isNaN(this.dataHandler.data.specifiedMinimumThickness) ||
        this.dataHandler.data.specifiedMinimumThickness <= 0
      ) {
        this.specifiedThicknessErrors = ['Only positive numbers accepted'];
      } else {
        this.specifiedThicknessErrors = [];
      }
    }
  }

  private setGoverningThickness(): void {
    if (!this.dataHandler.data.isMinimumThicknessOverridden) {
      this.dataHandler.data.governingMinimumThickness = this.selectedComponent.governingMinimumThickness;
    }
  }

  public setOverrideThickness(): void {
    if (this.dataHandler.data.isMinimumThicknessOverridden) {
      this.activeThicknessValue = this.dataHandler.data.specifiedMinimumThickness;
      this.updateMinThkErrors();
    } else {
      this.activeThicknessValue = this.selectedComponent.specifiedMinimumThickness;
    }
    this.checkIsValid();
  }

  private loadReadingGridOptions(): void {
    this.readingGridOptions = {
      exportFileName: 'Readings',
      context: 'cml-readings', //Change if grid options should not match other readings grid
      enterNavigatesVertically: true,
      enterNavigatesVerticallyAfterEdit: true,
      onCellValueChanged: this.dataChanged.bind(this),
      setDefaultSort: (columnApi: GridApi): void => {
        columnApi.applyColumnState({
          state: [
            {
              colId: 'date',
              sort: 'asc'
            }
          ]
        });
      },
      defaultColDef: {
        ...buildDefaultColDef(),
        minWidth: 150
      },
      columnDefs: [
        buildDateOnlyColDef('Survey Date', 'date'),
        buildDoubleColDef('Thickness', 'thickness'),
        buildCheckboxColDef('Exclude Thickness', 'excludeFromCalculation', {
          readonly: () => this.readOnly
        }),
        buildEnumColDef('Inspection Method', 'inspectionMethod', InspectionMethodTypes),
        buildCheckboxColDef('Baseline', 'isBaseline', {
          readonly: () => this.readOnly
        })
      ]
    };
  }
}
