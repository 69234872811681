import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AbstractAuthCallbackComponent, AuthConfigService, AuthService, User } from '@equityeng/auth';
import * as FullStory from '@fullstory/browser';
import { Observable } from 'rxjs';

import { AppSettingsService } from '../app-settings/app-settings.service';
import { CompanyService } from '../company.service';
import { CurrentUser } from '../current-user';

@Component({
  selector: 'auth-callback',
  templateUrl: './auth-callback.component.html',
  styleUrls: ['./auth-callback.component.css']
})
export class AuthCallbackComponent extends AbstractAuthCallbackComponent implements OnInit {
  private captions = ['Completing authentication process...', 'You will be redirected shortly...'];

  public randomCaption: string | undefined;

  public constructor(
    authSvc: AuthService,
    authConfig: AuthConfigService,
    router: Router,
    private companyService: CompanyService,
    private settingsService: AppSettingsService
  ) {
    super(authSvc, authConfig, router);
  }

  public ngOnInit(): void {
    this.randomCaption = this.captions[Math.floor(Math.random() * this.captions.length)];
    super.ngOnInit();
  }

  protected afterAuthentication(user: User): Observable<User> {
    this.createStaicCurrentUser(user);

    const isProduction = this.settingsService.settings.fullStoryProduction.toString().toLocaleLowerCase() == 'true';

    FullStory.init({
      orgId: this.settingsService.settings.fullStoryOrgId,
      devMode: !isProduction
    });

    FullStory.identify(user.userKey, {
      email: user.username,
      displayName: user.username,
      app: this.settingsService.settings.fullStroyAppName
    });

    return this.companyService.initialize(user);
  }

  private createStaicCurrentUser(user: User): void {
    CurrentUser.userName = user.username;
    CurrentUser.userKey = user.userKey;
  }
}
