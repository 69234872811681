import { Component } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { CellValueChangedEvent, IHeaderParams } from 'ag-grid-community';

export interface ICustomHeaderParams {
  enableSelectAll: boolean;
  invert: boolean;
  readonly?: boolean | (() => boolean);
}

@Component({
  selector: 'app-grid-header-select-all',
  template: `
    <div class="d-flex w-100">
      <div *ngIf="params.enableSelectAll">
        <input
          *ngIf="!isReadonly()"
          type="checkbox"
          class="grid-checkbox mr-2 pointer"
          [indeterminate]="indeterminate"
          [checked]="checked"
          (change)="toggleSelectAll($event)"
        />
      </div>
      <div class="overflow-ellipsis w-100">{{ params.displayName }}</div>
    </div>
  `
})
export class GridHeaderSelectAllComponent implements IHeaderAngularComp {
  public params!: IHeaderParams & ICustomHeaderParams;

  public fieldName = '';
  public checked = false;
  public indeterminate = false;

  public agInit(params: IHeaderParams & ICustomHeaderParams): void {
    this.params = params;
    this.fieldName = params?.column?.getColDef().field || '';

    params.api.addEventListener('cellValueChanged', (event: CellValueChangedEvent) => {
      if (event.colDef.field === this.fieldName) {
        this.updateCheckedState();
      }
    });
  }

  public toggleSelectAll(event: Event): void {
    const checked = (event?.target as HTMLInputElement)?.checked;

    const itemsToUpdate: any[] = [];
    this.params.api.forEachNode((node) => {
      const item = node.data;
      item[this.fieldName] = this.params.invert ? !checked : checked;
      itemsToUpdate.push(item);
    });

    this.params.api!.applyTransaction({ update: itemsToUpdate });

    this.indeterminate = false;
  }

  public refresh(): boolean {
    return false;
  }

  public isReadonly(): boolean {
    if (typeof this.params.readonly === 'function') {
      return this.params.readonly();
    }
    return this.params.readonly ?? false;
  }

  private updateCheckedState(): void {
    let anyChecked = false;
    let anyUnchecked = false;

    this.params.api.forEachNode((node) => {
      if (node.data[this.fieldName]) {
        anyChecked = true;
      } else {
        anyUnchecked = true;
      }
    });

    if (anyChecked && anyUnchecked) {
      this.indeterminate = true;
    } else if (anyChecked) {
      this.indeterminate = false;
      this.checked = this.params.invert ? false : true;
    } else {
      this.indeterminate = false;
      this.checked = this.params.invert ? true : false;
    }
  }
}
