<div class="e2g-padding h-100 flex-col flex-111">
  <ng-container *ngIf="dmlEnabled; else findings">
    <ul ngbNav #nav="ngbNav" [(activeId)]="activeTabId" class="d-inline-flex sub-nav-tabs">
      <li [ngbNavItem]="1">
        <a ngbNavLink>Findings</a>
        <ng-template ngbNavContent>
          <ng-container [ngTemplateOutlet]="findings"></ng-container>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink>DMLs</a>
        <ng-template ngbNavContent>
          <div class="h-100">
            <div class="grid-title-row">
              <div class="d-flex align-items-center">
                <div class="d-flex">
                  <button
                    *ngIf="dmlPermissions.edit"
                    id="btnAddDamageMonitoringLocation"
                    mat-button
                    [matMenuTriggerFor]="addDamageMonitoringLocationMenu"
                    class="btn e2g-btn-outline-primary"
                  >
                    Add DML <fa-icon [icon]="faChevronDown" class="ml-1"></fa-icon>
                  </button>
                  <mat-menu #addDamageMonitoringLocationMenu="matMenu" yPosition="below">
                    <button
                      mat-menu-item
                      *ngFor="let damageMonitioringLocationType of DmlTypes"
                      (click)="addOrEditDamageMonitoringLocation(damageMonitioringLocationType.value, undefined)"
                    >
                      {{ damageMonitioringLocationType.label }}
                    </button>
                  </mat-menu>
                </div>
              </div>
              <app-download-spreadsheet (onDownload)="dmlsGridOptions.downloadExcelFile!()"></app-download-spreadsheet>
            </div>
            <e2g-ag-grid
              #dmlsGrid
              class="h-100 mt-2 mb-2"
              [options]="dmlsGridOptions"
              [data]="gridDataHandlerDmls.data"
            >
            </e2g-ag-grid>
          </div>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="outlet"></div>
  </ng-container>
</div>

<ng-template #findings>
  <div class="grid-title-row">
    <div class="d-flex align-items-center">
      <div class="d-flex">
        <button
          *ngIf="findingPermissions.edit"
          id="btnAddFinding"
          class="btn e2g-btn-outline-primary"
          [disabled]="false"
          (click)="addOrEditFinding(undefined)"
        >
          Add Finding
        </button>
      </div>
    </div>
    <app-download-spreadsheet (onDownload)="findingsGridOptions.downloadExcelFile!()"></app-download-spreadsheet>
  </div>
  <e2g-ag-grid #findingsGrid class="h-100" [options]="findingsGridOptions" [data]="gridDataHandlerFindings.data">
  </e2g-ag-grid>
</ng-template>
