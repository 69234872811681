import { Component, Input } from '@angular/core';
import { LabelPosition } from '@equityeng/e2g-ng-ui/lib/e2g-ui-component-config';

import { BrBaseComponent } from '../br-base.component';

@Component({
  selector: 'app-br-text',
  templateUrl: './br-text.component.html'
})
export class BrTextComponent extends BrBaseComponent {
  @Input() public decimalPlaces?: number;
  @Input() public labelPosition?: LabelPosition;
  @Input() public type?: string;
  @Input() public debounce: boolean = false;

  public getLabelPosition(): LabelPosition {
    return this.labelPosition === undefined ? <LabelPosition>this.businessRules.labelPosition : this.labelPosition ;
  }
  private getDecimalPlaces(): number | undefined {
    return this.decimalPlaces === undefined ? this.businessRules.decimalPlaces : this.decimalPlaces ;
  }
  public getValue(): any {
    const numberValue = Number(this.value);
    if(isNaN(numberValue) || this.getDecimalPlaces()===undefined){
      return this.value;
    }
    return numberValue.toFixed(this.getDecimalPlaces());
  }

  public debounceTimeMsec(): number | undefined {
    return this.debounce ? 300 : undefined;
  }
}
