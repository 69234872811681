import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faEdit } from '@fortawesome/pro-regular-svg-icons';
import { faHistory, faPlus, faSave, faTrashXmark } from '@fortawesome/pro-solid-svg-icons';
import { Observable, Subject } from 'rxjs';
import { concatMap, startWith, switchMap } from 'rxjs/operators';
import { CommentTypes } from 'src/app/models/enums/comment-types';

import { OnDestroyBaseComponent } from '../../on-destroy-base-component/on-destroy-base-component';
import { ISaveChanges } from '../../save-changes';
import { ListDataHandler } from '../../shared-module/list-data-handler';
import { CommentsDataService } from '../comments-data.service';
import { CommentsDto } from '../models/comments-dto';

interface CommentsViewModel extends CommentsDto {
  editing?: boolean;
}

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.css']
})
export class CommentsComponent extends OnDestroyBaseComponent implements OnInit, ISaveChanges {
  @Input() public parentId?: string;
  @Input() public type?: CommentTypes;
  @Input() public readonly: boolean = false;
  private refreshData = new Subject<void>();

  public dataHandler: ListDataHandler<CommentsViewModel>;

  public saving: boolean | undefined;

  public faSave = faSave;
  public faHistory = faHistory;
  public faPlus = faPlus;
  public faTrashXmark = faTrashXmark;
  public faEdit = faEdit;

  public constructor(private route: ActivatedRoute, private dataService: CommentsDataService) {
    super();
    this.dataHandler = new ListDataHandler<CommentsViewModel>('id', 'updateType', this.destroy);
  }

  public ngOnInit(): void {
    this.route.params
      .pipe(
        concatMap(() => this.refreshData.pipe(startWith(null))),
        switchMap(() => this.dataService.getComments(this.parentId!, this.type!))
      )
      .subscribe((data) => {
        this.dataHandler.setInitialData(data);
      });
  }

  public save(): void {
    this.saving = true;
    this.saveData().subscribe((success) => {
      if (success) {
        this.refreshData.next();
      }
      this.saving = false;
    });
  }

  private saveData(): Observable<any> {
    const changes = (this.dataHandler.getChangedData() as Array<CommentsDto>).filter(
      (x) => x.comment.trim().length > 0
    );
    return this.dataService.saveComments(this.parentId!, this.type!, changes);
  }

  public isDirty(): boolean {
    return this.dataHandler.dirty;
  }

  public saveChanges(): Observable<boolean> {
    return this.saveData();
  }

  public revertChanges(): void {
    this.dataHandler.revertChanges();
  }

  public add(): void {
    this.dataHandler.add({ comment: '', editing: true } as CommentsViewModel);
  }

  public delete(index: number): void {
    this.dataHandler.delete(index);
  }

  public toggleEdit(item: CommentsViewModel): void {
    item.editing = !item.editing;
  }
}
