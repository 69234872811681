import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-top-toast',
  templateUrl: './top-toast.component.html',
  styleUrls: ['./top-toast.component.css']
})
export class TopToastComponent {
  public constructor(@Inject(MAT_SNACK_BAR_DATA) public data: string) {}
}
