import { ICellEditorComp, ICellEditorParams } from 'ag-grid-community';

export class DecimalEditor implements ICellEditorComp {
  private eInput!: HTMLInputElement;
  private cancelBeforeStart!: boolean;

  // gets called once before the renderer is used
  public init(params: ICellEditorParams): void {
    // create the cell
    this.eInput = document.createElement('input');
    this.eInput.className = 'ag-cell-input';

    if (this.isCharNumeric(params.eventKey)) {
      this.eInput.value = params.eventKey!;
    } else if (this.isCharDecimal(params.eventKey)) {
      this.eInput.value = '0.';
    } else {
      if (params.value !== undefined && params.value !== null) {
        if (params.value === 0) {
          this.eInput.value = '';
        } else {
          this.eInput.value = params.value;
        }
      }
    }

    this.eInput.addEventListener('keypress', (event) => {
      if (!this.isKeyPressedNumeric(event)) {
        this.eInput.focus();
        if (event.preventDefault) {
          event.preventDefault();
        }
      } else if (this.isKeyPressedNavigation(event)) {
        event.stopPropagation();
      }
    });

    // only start edit if key pressed is a number, not a letter
    const charPressIsNotANumber = params.eventKey && '1234567890.'.indexOf(params.eventKey) < 0;
    this.cancelBeforeStart = !!charPressIsNotANumber;
  }

  public isKeyPressedNavigation(event: any): boolean {
    return event.key === 'ArrowLeft' || event.key === 'ArrowRight';
  }

  // gets called once when grid ready to insert the element
  public getGui(): HTMLInputElement {
    return this.eInput;
  }

  // focus and select can be done after the gui is attached
  public afterGuiAttached(): void {
    this.eInput.focus();
  }

  // returns the new value after editing
  public isCancelBeforeStart(): boolean {
    return this.cancelBeforeStart;
  }

  // Gets called once after editing is complete. If your return true, then the new
  // value will not be used. The editing will have no impact on the record. Use this
  // if you do not want a new value from your gui, i.e. you want to cancel the editing.
  public isCancelAfterEnd(): boolean {
    const value = this.getValue();

    if (Number.isNaN(value)) {
      return true;
    } else {
      return false;
    }
  }

  // returns the new value after editing
  public getValue(): number {
    return Number(this.eInput.value);
  }

  // any cleanup we need to be done here
  public destroy(): void {
    // but this example is simple, no cleanup, we could  even leave this method out as it's optional
  }

  // if true, then this editor will appear in a popup
  public isPopup(): boolean {
    // and we could leave this method out also, false is the default
    return false;
  }

  public isCharNumeric(charStr: string | null): boolean {
    return (charStr && !!/\d/.test(charStr)) as boolean;
  }

  public isKeyPressedNumeric(event: any): boolean {
    const charStr = event.key;
    return this.isCharNumeric(charStr) || this.isCharDecimal(charStr);
  }

  public isCharDecimal(charStr: string | null): boolean {
    return (charStr && '.'.indexOf(charStr) === 0) as boolean;
  }
}
