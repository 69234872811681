import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AppSettingsService } from '../../app-settings/app-settings.service';
import { InspectionDto } from '../models/inspection-dto';


@Injectable({
  providedIn: 'root'
})
export class InspectionDataService {
  private serverUri: string;

  public constructor(private http: HttpClient, private settingsService: AppSettingsService) {
    this.serverUri = this.settingsService.settings.apiUri as string;
  }

  public deleteInspection(InspectionId: string): Observable<boolean> {
    return this.http.delete<boolean>(`${this.serverUri}/inspections/${InspectionId}`).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  public getInspection(InspectionId: string): Observable<InspectionDto> {
    return this.http
      .get<InspectionDto>(`${this.serverUri}/inspections/${InspectionId}`)
      .pipe(catchError(() => of({} as InspectionDto)));
  }

  public getInspections(assetId: string): Observable<Array<InspectionDto>> {
    return this.http.get<Array<InspectionDto>>(`${this.serverUri}/assets/${assetId}/inspections`);
  }

  public addInspection(assetId: string, data: InspectionDto): Observable<string> {
    return this.http.post<string>(`${this.serverUri}/assets/${assetId}/inspections`, data);
  }

  public updateInspection(data: InspectionDto): Observable<boolean> {
    return this.http.put(`${this.serverUri}/inspections`, data).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }
}
