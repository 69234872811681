import { Component, Inject, Input, OnInit } from '@angular/core';
import { E2gSelectOption } from '@equityeng/e2g-ng-ui';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, finalize, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { AlertService } from 'src/app/alert.service';
import { RefreshService } from 'src/app/notifications-module/refresh.service';
import { OnDestroyBaseComponent } from 'src/app/on-destroy-base-component/on-destroy-base-component';
import { BUSINESS_RULES } from 'src/app/sage-common-module/business-rules-injection-token';
import { BusinessRulesDefinition } from 'src/app/sage-common-module/models/business-rules-definition';
import { AssetFeature, GeneralFeature } from 'src/app/shared-module/permission/permission-models';
import { PermissionService } from 'src/app/shared-module/permission/permission.service';
import { NotificationService } from 'src/app/shared-module/services/notification.service';
import { SingleDataHandlerDefault } from 'src/app/shared-module/single-data-handler-default';

import { AttachmentTableType } from '../../attachments-module/models/attachment-table-type';
import { CompanyService } from '../../company.service';
import { EquipmentDataService } from '../../equipment-data.service';
import { ApiServiceClasses } from '../../models/enums/api-service-classes';
import { AssetTypes, getScraAssetType } from '../../models/enums/asset-types';
import { getServiceStatusOptions } from '../../models/enums/service-status';
import { EquipmentDto } from '../../models/equipment-dto';
import { ISaveChanges } from '../../save-changes';
import { getCodeValues } from '../../utilities/design-code-helper';
import { API_SERVICE_CLASS_LIST_WITH_UNKNOWN } from '../../utilities/equipment-helper';
import { AssetBusinessRulesService } from './asset-business-rules-service';

@Component({
  selector: 'app-equip-data',
  templateUrl: './equip-data.component.html',
  styleUrls: ['./equip-data.component.css'],
  providers: [
    {
      provide: ISaveChanges,
      useExisting: EquipDataComponent
    },
    {
      provide: BUSINESS_RULES,
      useClass: AssetBusinessRulesService
    }
  ]
})
export class EquipDataComponent extends OnDestroyBaseComponent implements OnInit, ISaveChanges {
  @Input() public assetId!: string;

  private calcRefresh: Observable<void> = this.refreshService.updates.pipe(
    filter((impactedIds) => !this.dataHandler.dirty && impactedIds.assetIds!.includes(this.dataHandler.data.id ?? '')),
    map(() => undefined),
    startWith(undefined),
    takeUntil(this.destroy)
  );

  private refreshData = new Subject<void>();
  public dataHandler: SingleDataHandlerDefault<EquipmentDto>;
  public saving: boolean = false;
  public equipType: string = '';
  public activeTabId: number = 1;
  public apiServiceClassList = API_SERVICE_CLASS_LIST_WITH_UNKNOWN;
  public apiClassWarnings: Array<string> = [];
  public codeValues: Array<E2gSelectOption> = [];

  public readOnly: boolean = false;
  public sageEnabled: boolean = false;

  public serviceStatusList: Array<E2gSelectOption> | undefined = [] as Array<E2gSelectOption>;
  public tableType = AttachmentTableType.Asset;

  public constructor(
    @Inject(BUSINESS_RULES) private businessRulesService: BusinessRulesDefinition,
    private equipService: EquipmentDataService,
    private companyService: CompanyService,
    private permissionService: PermissionService,
    private notificationService: NotificationService,
    private refreshService: RefreshService,
    private alertService: AlertService
  ) {
    super();
    this.dataHandler = new SingleDataHandlerDefault<EquipmentDto>(this.destroy, undefined, this.companyService);

    this.businessRulesService.init(this.dataHandler);
  }

  public ngOnInit(): void {
    this.permissionService
      .getPermissions({
        asset: [{ feature: AssetFeature.Asset, assetId: this.assetId }],
        general: [{ feature: GeneralFeature.SAGE }]
      })
      .pipe(
        tap((permissions) => {
          this.readOnly = !permissions.asset[AssetFeature.Asset].edit;
          this.sageEnabled = permissions.general[GeneralFeature.SAGE];
        }),
        switchMap(() => combineLatest([this.refreshData.pipe(startWith(undefined)), this.calcRefresh])),
        tap(() => this.dataHandler.clear()),
        switchMap(() => this.equipService.getSingleEquipment(this.assetId)),
        takeUntil(this.destroy)
      )
      .subscribe((asset) => {
        this.serviceStatusList = getServiceStatusOptions();
        this.dataHandler.setInitialData({ ...asset, fluidMass: this.getFixed(asset.fluidMass, 3) });
        this.equipType = getScraAssetType(asset.type);
        this.codeValues = getCodeValues(asset.type);
        this.alertService.updateIdsInEdit({ assetIds: [asset.id] });
      });
  }

  private getFixed(prop: any, digits: number): any {
    const numberValue = Number(prop);
    if (isNaN(numberValue)) {
      return prop;
    }
    return numberValue.toFixed(digits);
  }

  public isDirty(): boolean {
    return this.dataHandler.dirty;
  }

  public save(): void {
    this.saveChanges().subscribe((x) => {
      if (x) {
        this.refreshData.next();
      }
    });
  }

  public saveChanges(): Observable<any> {
    this.saving = true;

    return this.equipService.saveEquipment(this.dataHandler.getDataWithEmptyStringsRemoved()).pipe(
      tap((x) => this.notificationService.showSaveResult(x)),
      finalize(() => (this.saving = false))
    );
  }
  public revertChanges(): void {
    this.dataHandler.revertChanges();
  }

  public apiClassSelected(): void {
    this.apiClassWarnings = [];

    if (this.dataHandler.data.apiServiceClassId === ApiServiceClasses.Unknown) {
      this.apiClassWarnings.push('A defined pipe class is required for activity scheduling');
    }
  }

  public isTank(): boolean {
    return this.dataHandler.data.type === AssetTypes.StorageTank;
  }

  public isPipingSystem(): boolean {
    return this.dataHandler.data.type === AssetTypes.PipingSystem;
  }
}
