export function headerBoxFormatter(thickness: string): string {
  const r = /[\d.]+/g;
  const numbers = thickness.match(r)!;
  if (numbers == null || numbers.length == 0) {
    return '';
  } else {
    thickness = ' ' + thickness.replace(/ /g, '  ') + ' ';
    numbers
      .filter((n, i) => numbers.indexOf(n) === i)
      .forEach((num) => {
        const re = RegExp(` ${num} `, 'g');
        thickness = thickness.replace(re, Number(num).toFixed(3));
      });
    return thickness;
  }
}
