import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FileUploadModule } from 'ng2-file-upload';

import { UploadComponent } from './upload/upload.component';

@NgModule({
  declarations: [UploadComponent],
  imports: [CommonModule, FormsModule, FontAwesomeModule, FileUploadModule],
  exports: [UploadComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class UploadModule {}
