import { Injectable } from '@angular/core';
import { ActionTypes } from 'src/app/shared-module/models/action-types';
import { NotificationService } from 'src/app/shared-module/services/notification.service';

import { JobNotificationService } from '../../notifications-module/services/job-notifications.service';
import { ReportingDataService } from '../reporting.data.service';

@Injectable({
  providedIn: 'root'
})
export class OverdueReportService {
  public constructor(
    private overdueReportDataService: ReportingDataService,
    private notificationService: NotificationService,
    private jobNotificationsService: JobNotificationService
  ) {}

  public run(): void {
    const reportName = 'Overdue Report'; //Also used as ID for notification replacement
    this.notificationService.showActionStarting(ActionTypes.Generate, reportName, reportName);

    this.overdueReportDataService.generateOverdueReport().subscribe((success) => {
      if (success) {
        this.jobNotificationsService.refreshNotifications();
      } else {
        //Only show error if Background process fails to start
        this.notificationService.showActionResult(success, ActionTypes.Generate, reportName, reportName);
      }
    });
  }
}
