import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { E2gNgUiModule } from '@equityeng/e2g-ng-ui';

import { AuditLogReportDialogComponent } from './audit-log-report/audit-log-report-dialog/audit-log-report-dialog.component';
import { CmlDetailReportDialogComponent } from './cml-detail-report-dialog/cml-detail-report-dialog.component';
import { DueDateReportDialogComponent } from './due-date-report-dialog/due-date-report-dialog.component';

@NgModule({
  declarations: [AuditLogReportDialogComponent, CmlDetailReportDialogComponent, DueDateReportDialogComponent],
  imports: [CommonModule, FormsModule, E2gNgUiModule, MatRadioModule],
  exports: [AuditLogReportDialogComponent]
})
export class ReportingModule {}
