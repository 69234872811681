import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { DmlDetailSummaryBaseComponent } from 'src/app/dmls-module/dml-slideout/dml-detail-summary-base-component';

import { DmlCreepOutputDto } from '../../models/dml-creep-output-dto';
import { DmlTypes } from '../../models/Enums/dml-types';

@Component({
  selector: 'app-dml-creep-summary',
  templateUrl: './dml-creep-summary.component.html',
  styleUrls: ['./dml-creep-summary.component.css']
})
export class DmlCreepSummaryComponent extends DmlDetailSummaryBaseComponent<DmlCreepOutputDto> {
  protected dmlType = DmlTypes['Creep'];

  public maximumTemperatureLimitUom: Observable<string> = this.uomEvaluator.getUnits(
    'DmlCreep_MaximumTemperatureLimit'
  );
  public maximumAdjustedTemperatureUom: Observable<string> = this.uomEvaluator.getUnits(
    'DmlCreep_MaximumAdjustedTemperature'
  );
  public minimumThicknessUom: Observable<string> = this.uomEvaluator.getUnits('DmlCreep_MinimumThickness');
  public thicknessLimitStructuralUom: Observable<string> = this.uomEvaluator.getUnits(
    'DmlCreep_ThicknessLimitStructural'
  );
  public minimumYieldSafetyFactorUom: Observable<string> = this.uomEvaluator.getUnits(
    'DmlCreep_MinimumYieldSafetyFactor'
  );
  public creepDamageLimitUom: Observable<string> = this.uomEvaluator.getUnits('DmlCreep_CreepDamageLimit');
  public calculatedCreepDamageUom: Observable<string> = this.uomEvaluator.getUnits('DmlCreep_CalculatedCreepDamage');

  public constructor() {
    super();
  }

  protected displaySummary(output: DmlCreepOutputDto): void {
    if (output.creepAssessmentMessages !== undefined) {
      output.creepAssessmentMessages = this.formatSummarySection(output.creepAssessmentMessages);
    }

    if (output.creepApplicabilityAndLimitationsMessages !== undefined) {
      output.creepApplicabilityAndLimitationsMessages = this.formatSummarySection(
        output.creepApplicabilityAndLimitationsMessages
      );
    }
  }
}
