<div class="h-100 flex-col flex-111">
  <ul ngbNav #nav="ngbNav" [(activeId)]="activeTabId" class="sub-nav-tabs">
    <li [ngbNavItem]="1" *ngIf="showShell">
      <a ngbNavLink>SHELL</a>
      <ng-template ngbNavContent>
        <div
          class="m-4"
          style="font-family: 'Courier New', Courier, monospace; font-size: smaller; white-space: pre-wrap"
          [innerHTML]="outputTextTmin"
        ></div>
      </ng-template>
    </li>
    <li [ngbNavItem]="2" *ngIf="showRbi">
      <a ngbNavLink>FLASH</a>
      <ng-template ngbNavContent>
        <div
          class="m-4"
          style="font-family: 'Courier New', Courier, monospace; font-size: smaller; white-space: pre-wrap"
          [innerHTML]="outputTextFlash"
        ></div>
      </ng-template>
    </li>
    <li [ngbNavItem]="3" *ngIf="showRbi">
      <a ngbNavLink>APIRBI</a>
      <ng-template ngbNavContent>
        <div
          class="m-4"
          style="font-family: 'Courier New', Courier, monospace; font-size: smaller; white-space: pre-wrap"
          [innerHTML]="outputTextApirbi"
        ></div>
      </ng-template>
    </li>
    <li [ngbNavItem]="4">
      <a ngbNavLink>APIPE</a>
      <ng-template ngbNavContent>
        <div
          class="m-4"
          style="font-family: 'Courier New', Courier, monospace; font-size: smaller; white-space: pre-wrap"
          [innerHTML]="outputTextApipe"
        ></div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav" class="h-100 flex-col flex-111 overflow-auto"></div>
</div>
