<div class="e2g-padding h-100 flex-col flex-111">
  <div class="d-flex justify-content-between mb-2">
    <div>
      <button
        *ngIf="componentPermissions.edit"
        id="btnAddComponent"
        mat-button
        [disabled]="saving"
        class="btn e2g-btn-outline-primary"
        [matMenuTriggerFor]="addComponentMenu"
      >
        Add Component<fa-icon id="addComponentChevron" [icon]="faChevronDown" class="fa-xs ml-2"></fa-icon>
      </button>

      <mat-menu #addComponentMenu="matMenu" yPosition="below">
        <button mat-menu-item *ngFor="let componentType of componentTypes" (click)="addComponent(componentType.type)">
          {{ componentType.type }}
        </button>
      </mat-menu>
    </div>
    <div class="d-flex">
      <app-download-spreadsheet (onDownload)="componentGridOptions.downloadExcelFile!()"></app-download-spreadsheet>
    </div>
  </div>

  <e2g-ag-grid #componentGrid class="h-100" [options]="componentGridOptions" [data]="gridDataHandler.data">
  </e2g-ag-grid>
</div>
