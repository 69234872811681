<div class="h-100">
  <div *ngIf="dataHandler.initialized && !loading; else load" class="h-100 flex-col flex-111">
    <ul ngbNav #nav="ngbNav" [(activeId)]="activeTabId" class="nav-tabs header-table mt-0">
      <li [ngbNavItem]="1">
        <a ngbNavLink>Design</a>
        <ng-template ngbNavContent>
          <div class="h-100 flex-col">
            <div class="h-100 flex-111 overflow-auto mt-2 ml-4 pr-4">
              <div class="row mt-2 mb-2">
                <div id="status-col-1" class="col-sm-4">
                  <e2g-ng-select-input
                    id="select-status"
                    heading="Status"
                    placeholder="Select Status"
                    [readonly]="readOnly"
                    [options]="statusList"
                    [(ngModel)]="dataHandler.data.status"
                    [required]="false"
                  ></e2g-ng-select-input>
                </div>
                <div id="status-col-1" class="col-sm-4">&nbsp;</div>
              </div>

              <div class="row mt-2 mb-2">
                <div id="description-col-1" class="col-sm-4">
                  <e2g-textarea-input
                    id="inp-description"
                    heading="Description"
                    [rows]="2"
                    [(ngModel)]="dataHandler.data.description"
                    maxlength="255"
                    [readonly]="readOnly"
                  ></e2g-textarea-input>
                </div>
                <div id="description-col-1" class="col-sm-4">&nbsp;</div>
              </div>

              <div class="form-section-header">Design</div>
              <div class="row mt-2">
                <div id="design-col-1" class="col-sm-4">
                  <app-br-date propName="inServiceDate" heading="In Service Date"></app-br-date>
                  <div class="mt-2">
                    <app-br-select propName="designCode" heading="Design Code"></app-br-select>
                  </div>
                  <div class="mt-2">
                    <app-br-text propName="eqDesignPressure" heading="Design Pressure" [readOnly]="true"></app-br-text>
                  </div>
                  <div class="mt-2">
                    <app-br-text
                      propName="eqDesignTemperature"
                      heading="Design Temperature"
                      [readOnly]="true"
                    ></app-br-text>
                  </div>
                </div>
                <div id="design-col-2" class="col-sm-4">
                  <app-br-select propName="insulationType" heading="Insulated" placeholder="Select Insulation Type">
                  </app-br-select>
                  <div class="mt-2">
                    <div class="d-flex justify-content-between mt-2">
                      <e2g-input-heading heading="Group"></e2g-input-heading>
                      <a (click)="addNewCircuit()" class="modal-link" *ngIf="canAddCircuit && !readOnly">Add Group</a>
                    </div>
                    <e2g-ng-select-input
                      id="select-circuit"
                      placeholder="Select Group"
                      [disabled]="circuitList.length === 0"
                      [options]="circuitList || []"
                      [(ngModel)]="dataHandler.data.circuitId"
                      [required]="false"
                      [requiredForCalc]="dataHandler.data.rbi ?? false"
                      [clearable]="true"
                      [readonly]="readOnly"
                    ></e2g-ng-select-input>
                  </div>
                </div>
                <div class="mt-2" *ngIf="dataHandler.data.maximumAllowableWorkingPressure || mawpWarnings.length > 0">
                  <app-br-text
                    propName="maximumAllowableWorkingPressure"
                    heading="MAWP"
                    type="number"
                    [disabled]="true"
                    [warnings]="mawpWarnings"
                  ></app-br-text>
                </div>
              </div>
              <hr class="separator" />
              <div class="form-section-header">Geometry</div>
              <div class="row mt-2">
                <div id="geom-col-1" class="col-sm-4">
                  <app-br-text
                    propName="tankNominalDiameter"
                    heading="Tank Nominal Diameter"
                    [readOnly]="true"
                  ></app-br-text>
                  <div class="mt-2">
                    <app-br-text propName="nominalThickness" heading="Nominal Thickness" type="number"></app-br-text>
                  </div>
                  <div class="mt-2">
                    <app-br-text
                      propName="futureCorrosionAllowance"
                      heading="Corrosion Allowance"
                      type="number"
                    ></app-br-text>
                  </div>
                </div>
                <div id="geom-col-2" class="col-sm-4">
                  <app-br-text
                    [showOnlyWhenRequired]="true"
                    propName="courseHeight"
                    heading="Height"
                    type="number"
                  ></app-br-text>
                  <app-br-text
                    [showOnlyWhenRequired]="true"
                    propName="plateWidth"
                    heading="Width"
                    type="number"
                  ></app-br-text>
                </div>
              </div>

              <hr class="separator" />

              <div class="form-section-header">Material</div>
              <div class="row mt-2">
                <div class="h-100 flex-111">
                  <app-moc></app-moc>
                </div>
              </div>

              <hr class="separator" />

              <div class="d-flex flex-row mt-2 mb-2">
                <e2g-textarea-input
                  id="inp-comments"
                  class="w-100"
                  heading="Comments"
                  [rows]="5"
                  [readonly]="readOnly"
                  [maxlength]="512"
                  [(ngModel)]="dataHandler.data.comments"
                ></e2g-textarea-input>
              </div>
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink>Minimum Thickness</a>
        <ng-template ngbNavContent>
          <div class="flex-col mt-2">
            <app-governing-tmin
              [dataHandler]="dataHandler"
              [readOnly]="readOnly"
              (isValid)="setValid($event)"
              [assetConfig]="assetConfig"
            >
            </app-governing-tmin>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="3" *ngIf="rbiTabVisible()">
        <a ngbNavLink>RBI</a>
        <ng-template ngbNavContent>
          <div class="h-100 flex-col">
            <div class="h-100 flex-111 overflow-auto mt-2 ml-4">
              <app-comp-rbi
                #rbiComponent
                [compDataHandler]="dataHandler"
                [readOnly]="readOnly"
                [componentId]="dataHandler.data.id!"
                [(rbiActiveTabId)]="rbiActiveTabId"
                (rbiChange)="createCircuitWarnings()"
              ></app-comp-rbi>
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="4" *ngIf="diagVisible">
        <a ngbNavLink>Diagnostics</a>
        <ng-template ngbNavContent>
          <div class="h-100 flex-col">
            <div class="flex-111 overflow-auto mt-2 ml-4">
              <app-sage-diag [componentId]="compId" [showShell]="false" [showRbi]="rbiDiagVisible()"></app-sage-diag>
            </div>
          </div>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="flex-col h-100"></div>
  </div>
</div>

<ng-template #load>
  <div class="loading"></div>
</ng-template>
