import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of, tap } from 'rxjs';
import { NotificationService } from 'src/app/shared-module/services/notification.service';

import { AppSettingsService } from '../../../app-settings/app-settings.service';
import { ReportFormat } from '../../../models/enums/report-format';
import { ActionTypes } from '../../models/action-types';

@Injectable({
  providedIn: 'root'
})
export class ReportDataService {
  public constructor(
    private http: HttpClient,
    private settingsService: AppSettingsService,
    private notificationService: NotificationService //TODO Move this out of here and ideally into ReportService
  ) {}

  public getSageExport(
    id: string,
    saveFormat: ReportFormat = ReportFormat.Txt
  ): Observable<HttpResponse<Blob> | undefined> {
    return this.http
      .get<Blob>(`${this.settingsService.settings.apiUri}/sage/${id}/report/${saveFormat}`, {
        observe: 'response',
        responseType: 'blob' as 'json'
      })
      .pipe(
        catchError(() => {
          return of(undefined);
        })
      );
  }

  public getSageCalculationReport(id: string, index: Array<number>): Observable<HttpResponse<Blob> | undefined> {
    const notificationName = 'Sage Calculation Report';
    this.notificationService.showActionStarting(ActionTypes.Generate, notificationName, id);
    return this.http
      .post<Blob>(`${this.settingsService.settings.apiUri}/sage/${id}/calc-report`, index, {
        observe: 'response',
        responseType: 'blob' as 'json'
      })
      .pipe(
        tap((response) => {
          if (response.status == 204) {
            this.showNotAvailable(id);
          } else {
            this.notificationService.showActionResult(true, ActionTypes.Generate, notificationName, id);
          }
        }),
        catchError(() => {
          this.notificationService.showActionResult(false, ActionTypes.Generate, notificationName, id);
          return of(undefined);
        })
      );
  }

  private showNotAvailable(id: string): void {
    this.notificationService.showInfo('SAGE does not yet have any reports available for this specific analysis', id);
  }
}
