import { Component, OnInit } from '@angular/core';
import { OnDestroyBaseComponent } from 'src/app/on-destroy-base-component/on-destroy-base-component';
import { IDialogFormData } from 'src/app/shared-module/dialog/dialog-form-data';
import { DialogData } from 'src/app/shared-module/models/dialog-data';
import { requiredMessage, valueInListMessage } from 'src/app/utilities/validation-messages';

import { NameDialogData } from '../models/name-dialog-data';

@Component({
  selector: 'app-name-dialog',
  templateUrl: './name-dialog.component.html',
  styleUrls: ['./name-dialog.component.css']
})
export class NameDialogComponent extends OnDestroyBaseComponent implements OnInit, IDialogFormData {
  public dialogData!: DialogData;
  public formData!: NameDialogData;

  public invalidNames: string[] = [];
  public nameErrors: string[] = [];
  public nameIsTouched = false;

  private currentName: string = '';

  public constructor() {
    super();
  }

  public ngOnInit(): void {
    this.dialogData.validityCallback = this.isValid;
    this.dialogData.beforeCloseCallback = (): void => {
      this.formData.name = this.formData.name.trim();
    };

    this.invalidNames = this.formData.invalidNames ?? [];
  }

  public setDialogData(dialogData: DialogData): void {
    this.dialogData = dialogData;
  }

  public setFormData(formData: NameDialogData): void {
    this.formData = formData;
    this.currentName = this.formData.name;
  }

  public updateNameErrorsArray(): void {
    this.nameErrors = [];

    if (this.formData.name.trim().length === 0 && this.nameIsTouched) {
      this.nameErrors.push(requiredMessage());
    }

    if (this.isNameDuplicate()) {
      this.nameErrors.push(valueInListMessage(this.formData.name));
    }
  }

  private isNameDuplicate(): boolean {
    return (
      this.invalidNames.length > 0 &&
      this.invalidNames.map((x) => x.toLocaleLowerCase()).includes(this.formData.name.trim().toLocaleLowerCase()) &&
      this.currentName !== this.formData.name.trim()
    );
  }

  public isValid = (): boolean => {
    return (
      this.formData.name.trim().length > 0 &&
      this.nameErrors.length === 0 &&
      (this.currentName !== this.formData.name.trim() || this.formData.allowSameName === true)
    );
  };
}
