import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { AuthService, User } from '@equityeng/auth';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { PRODUCT_NAME } from '../models/auth-constants';

@Injectable({
  providedIn: 'root'
})
export class HasFeatureGuard implements CanActivate {
  public constructor(private authService: AuthService) {}

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const feature: string | undefined = route.data?.feature;
    if (feature) {
      return this.authService.userAuthenticated$.pipe(
        map((user: User) => user.hasAccessToFeature(PRODUCT_NAME, feature))
      );
    }
    return of(false);
  }
}
