import { ColDef } from 'ag-grid-community';
import { buildEditableNumberColDef } from 'src/app/grid-module/column-builders/build-editable-number';

export function getDmlPittingPitCoupleDataGridColumns(): Array<ColDef> {
  return [
    {
      headerName: 'Id',
      field: 'id',
      hide: true
    },
    buildEditableNumberColDef('Pit Couple Pitch', 'pitCouplePitch'),
    buildEditableNumberColDef('Pit Angle', 'pitAngle'),
    buildEditableNumberColDef('Pit 1 Diameter', 'pitOneDiameter'),
    buildEditableNumberColDef('Pit 1 Depth', 'pitOneDepth'),
    buildEditableNumberColDef('Pit 2 Diameter', 'pitTwoDiameter'),
    buildEditableNumberColDef('Pit 2 Depth', 'pitTwoDepth')
  ];
}
