export enum ToughnessCurveDesignations {
  TEMP = 0,
  I = 1,
  II = 2,
  III = 3,
  IIIA = 31,
  IV = 4,
  IVA = 41,
  V = 5,
  VI = 6,
  A = 101,
  B = 102,
  C = 103,
  D = 104,
  CI_20 = 201,
  CI_20_A = 202,
  CS20A = 203,
  CS0 = 204,
  CS_20 = 205,
  CS_20A = 206,
  CS_50 = 207,
  CS_55 = 208,
  CS_A = 209,
  CS_B = 210,
  CS_C = 211,
  CS_D = 212,
  CU_325 = 213,
  CU_452 = 214,
  LA20 = 215,
  LA0 = 216,
  LA_100 = 217,
  LA_150 = 218,
  LA_20 = 219,
  LA_275 = 220,
  LA_320 = 221,
  LA_40 = 222,
  LA_55 = 223,
  LA_75 = 224,
  SS_20 = 225,
  SS_325 = 226,
  SS_425 = 227,
  SS_60 = 228,
  T1_75 = 229
}
