<app-menu></app-menu>
<div class="h-100" style="position: relative">
  <div class="h-100" style="position: absolute; left: 0px; right: 0px">
    <mat-sidenav-container class="content-container" hasBackdrop="true">
      <mat-sidenav
        mode="over"
        position="end"
        [opened]="((state | async)?.slideOut)!.display"
        [ngStyle]="styles | async"
        disableClose="true"
      >
        <app-slide-out></app-slide-out>
      </mat-sidenav>
      <mat-sidenav-content>
        <mat-drawer-container class="content-container" hasBackdrop="false">
          <mat-drawer mode="over" position="end" [opened]="((state | async)?.notifications)!.display">
            <app-notifications></app-notifications>
          </mat-drawer>
          <mat-drawer-content>
            <div class="container-fluid h-100">
              <router-outlet></router-outlet>
            </div>
          </mat-drawer-content>
        </mat-drawer-container>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</div>
<app-toasts-container></app-toasts-container>
