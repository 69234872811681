import { Component } from '@angular/core';
import { faCheckSquare, faSquare } from '@fortawesome/pro-regular-svg-icons';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

import { GridCellLinkParams } from '../grid-cell-link/grid-cell-link-params';

export interface IGridCheckboxParams extends GridCellLinkParams {
  invert?: boolean;
  readonly?: boolean | (() => boolean);
  labelField?: string;
}

@Component({
  selector: 'app-grid-checkbox',
  templateUrl: './grid-checkbox.component.html'
})
export class GridCheckboxComponent implements ICellRendererAngularComp {
  public params!: ICellRendererParams & IGridCheckboxParams;
  public checked = false;
  public label?: string;

  public faCheckSquare = faCheckSquare;
  public faSquare = faSquare;

  private fieldName = '';

  public agInit(params: ICellRendererParams & IGridCheckboxParams): void {
    this.params = params;
    this.fieldName = params.colDef?.field || '';
    if (params.labelField) {
      this.label = params.data[params.labelField];
    }
    this.setChecked(params);
  }

  public onChange(event: Event): void {
    const checked = (event?.target as HTMLInputElement)?.checked;
    this.params.setValue!(this.params.invert ? !checked : checked);
  }

  public refresh(params: ICellRendererParams & IGridCheckboxParams): boolean {
    this.setChecked(params);
    return true;
  }

  public isReadonly(): boolean {
    if (typeof this.params.readonly === 'function') {
      return this.params.readonly();
    }
    return this.params.readonly ?? false;
  }

  private setChecked(params: ICellRendererParams & IGridCheckboxParams): void {
    const value = params.data[this.fieldName];
    this.checked = params.invert ? !value : value;
  }
}
