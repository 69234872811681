import { E2gSelectOption } from '@equityeng/e2g-ng-ui';

export enum PrdEndType {
  FlatFace = 1,
  RaisedFace = 2,
  RingJoint = 3,
  ConeRing = 4,
  Threaded = 5
}

export const PRD_END_TYPE_VALUES: Array<E2gSelectOption> = [
  { value: PrdEndType.FlatFace, label: 'Flat face' },
  { value: PrdEndType.RaisedFace, label: 'Raised face' },
  { value: PrdEndType.RingJoint, label: 'Ring joint' },
  { value: PrdEndType.ConeRing, label: 'Cone Ring' },
  { value: PrdEndType.Threaded, label: 'Threaded' }
];

export function getPrdEndType(type: PrdEndType): string {
  switch (type) {
    case PrdEndType.FlatFace:
      return 'Flat Face';
    case PrdEndType.RaisedFace:
      return 'Raised Face';
    case PrdEndType.RingJoint:
      return 'Ring Joint';
    case PrdEndType.ConeRing:
      return 'Cone Ring';
    case PrdEndType.Threaded:
      return 'Threaded';
  }
}
