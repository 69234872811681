import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

interface CacheData {
  timestamp: number;
  data: Observable<Blob>;
}

@Injectable({
  providedIn: 'root'
})
export class AttachmentCacheService {
  private cache: Record<string, CacheData> = {};
  private cacheSizeLimit = 6;

  public get(id: string, method: () => Observable<Blob>): Observable<Blob> {
    if (!this.cache[id]) {
      this.purgeOld();
      this.cache[id] = {
        timestamp: Date.now(),
        data: method()
      };
    }

    return this.cache[id].data;
  }

  public clearCache(): void {
    this.cache = {};
  }

  private purgeOld(): void {
    const keys = Object.keys(this.cache);
    if (keys.length >= this.cacheSizeLimit) {
      const keysSorted = keys.sort((a, b) => {
        return this.cache[b].timestamp - this.cache[a].timestamp;
      });
      delete this.cache[keysSorted[0]];
    }
  }
}
