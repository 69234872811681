<div class="form-group row">
  <div class="col-12">
    <e2g-text-input
      heading="Name"
      id="inp-name"
      [(ngModel)]="circuitFormData.name"
      [required]="true"
      [errors]="nameErrors"
      (input)="updateNameErrorsArray()"
    ></e2g-text-input>
  </div>
</div>

<div class="form-group row">
  <div class="col-12">
    <e2g-textarea-input
      heading="Description"
      [rows]="2"
      id="inp-description"
      [(ngModel)]="circuitFormData.description"
      maxlength="255"
    ></e2g-textarea-input>
  </div>
</div>

<ng-template #loadingInline>
  <div>
    <div class="loading-inline"></div>
  </div>
</ng-template>
