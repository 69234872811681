import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { DmlDetailSummaryBaseComponent } from 'src/app/dmls-module/dml-slideout/dml-detail-summary-base-component';

import { DmlGeneralThinningOutputDto } from '../../models/dml-general-thinning-output-dto';
import { DmlTypes } from '../../models/Enums/dml-types';

@Component({
  selector: 'app-dml-general-thinning-summary',
  templateUrl: './dml-general-thinning-summary.component.html',
  styleUrls: ['./dml-general-thinning-summary.component.css']
})
export class DmlGeneralThinningSummaryComponent extends DmlDetailSummaryBaseComponent<DmlGeneralThinningOutputDto> {
  protected dmlType = DmlTypes['General Thinning'];

  public hoopAverageThicknessLessFcaUom: Observable<string> = this.uomEvaluator.getUnits(
    'DmlLocalThinArea_DmlGeneralThinning_HoopAverageThicknessLessFca'
  );
  public axialAverageThicknessLessFcaUom: Observable<string> = this.uomEvaluator.getUnits(
    'DmlLocalThinArea_DmlGeneralThinning_AxialAverageThicknessLessFca'
  );
  public corrodedMinimumMeasuredThicknessUom: Observable<string> = this.uomEvaluator.getUnits(
    'DmlLocalThinArea_DmlGeneralThinning_CorrodedMinimumMeasuredThickness'
  );
  public structuralMinimumThicknessUom: Observable<string> = this.uomEvaluator.getUnits(
    'DmlLocalThinArea_DmlGeneralThinning_StructuralMinimumThickness'
  );
  public designPressureUom: Observable<string> = this.uomEvaluator.getUnits(
    'DmlLocalThinArea_DmlGeneralThinning_DesignPressure'
  );
  public undamagedMawpUom: Observable<string> = this.uomEvaluator.getUnits(
    'DmlLocalThinArea_DmlGeneralThinning_UndamagedMawp'
  );
  public averageThicknessMawpUom: Observable<string> = this.uomEvaluator.getUnits(
    'DmlLocalThinArea_DmlGeneralThinning_AverageThicknessMawp'
  );
  public minimumThicknessMawpUom: Observable<string> = this.uomEvaluator.getUnits(
    'DmlLocalThinArea_DmlGeneralThinning_MinimumThicknessMawp'
  );
  public damagedMawpUom: Observable<string> = this.uomEvaluator.getUnits(
    'DmlLocalThinArea_DmlGeneralThinning_DamagedMawp'
  );
  public undamagedRegionMaximumFcaUom: Observable<string> = this.uomEvaluator.getUnits(
    'DmlLocalThinArea_DmlGeneralThinning_UndamagedRegionMaximumFca'
  );
  public damagedRegionMaximumFcaUom: Observable<string> = this.uomEvaluator.getUnits(
    'DmlLocalThinArea_DmlGeneralThinning_DamagedRegionMaximumFca'
  );
  public damageRegionMinimumThicknessUom: Observable<string> = this.uomEvaluator.getUnits(
    'DmlLocalThinArea_DmlGeneralThinning_DamageRegionMinimumThickness'
  );
  public remainingLifeUom: Observable<string> = this.uomEvaluator.getUnits(
    'DmlLocalThinArea_DmlGeneralThinning_RemainingLife'
  );
  public minimumInspectionIntervalUom: Observable<string> = this.uomEvaluator.getUnits(
    'DmlLocalThinArea_DmlGeneralThinning_MinimumInspectionInterval'
  );

  public constructor() {
    super();
  }

  protected displaySummary(output: DmlGeneralThinningOutputDto): void {
    if (output.applicabilityAndLimitationsMessages !== undefined) {
      output.applicabilityAndLimitationsMessages = this.formatSummarySection(
        output.applicabilityAndLimitationsMessages
      );
    }
  }
}
