import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';

import { dateSort, SortOrder } from '../../utilities/date-helper';
import { JobSources } from '../models/job-sources';
import { JobStatus } from '../models/job-status';
import { JobStatusDto } from '../models/job-status-dto';
import { JobNotificationsDataService } from './job-notifications-data.service';

@Injectable({
  providedIn: 'root'
})
export class JobNotificationService {
  public jobNotifications: Observable<Array<JobStatusDto>>;
  public activeNotificationsCount: Observable<number> = new BehaviorSubject<number>(0);
  private refreshNow: BehaviorSubject<void> = new BehaviorSubject<void>(undefined);

  public constructor(private dataService: JobNotificationsDataService) {
    this.jobNotifications = this.refreshNow.pipe(
      switchMap(() => this.dataService.getStatusList()),
      tap((statusList) => {
        this.updateActiveNotificationsCount(statusList);
      }),
      map((statusList) =>
        statusList.sort((a, b) => dateSort(new Date(a.creationTime), new Date(b.creationTime), SortOrder.Descending))
      ),
      shareReplay(1)
    );
  }

  public onDelete(source: JobSources, id: number): Observable<boolean> {
    return this.dataService.deleteJob(source, id);
  }

  public onDeleteAll(): Observable<boolean> {
    return this.dataService.deleteCompletedJobs();
  }

  public getJobFile(id: number): Observable<HttpResponse<Blob>> {
    return this.dataService.getJobFile(id);
  }

  public refreshNotifications(): void {
    return this.refreshNow.next();
  }

  private updateActiveNotificationsCount(statusList: Array<JobStatusDto>): void {
    (this.activeNotificationsCount as BehaviorSubject<number>).next(
      statusList.filter((x) => x.status === JobStatus.New || x.status === JobStatus.InProgress).length
    );
  }
}
