import { Injectable } from '@angular/core';
import { BusinessRulesServiceBase } from 'src/app/sage-common-module/BusinessRulesServiceBase';
import { BusinessRuleValueList } from 'src/app/sage-common-module/models/business-rule-value-list';
import { BusinessRulesDefinition } from 'src/app/sage-common-module/models/business-rules-definition';

@Injectable({
  providedIn: 'root'
})
export class AssetBusinessRulesService extends BusinessRulesServiceBase implements BusinessRulesDefinition {
  public get valueData(): BusinessRuleValueList {
    if (this.data === undefined) {
      throw 'init must be called before valueData()';
    } else {
      return {
        designPressure: {
          getRequired: () => true
        },
        designTemperature: {
          getRequired: () => true
        },
        tankFillHeight: {
          getRequired: () => true
        },
        tankNominalDiameter: {
          getRequired: () => true
        }
      };
    }
  }
}
