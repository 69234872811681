<img
  #image
  *ngIf="!loadingData; else loading"
  [src]="imgUrl"
  [class]="class"
  [style]="style"
  [useMap]="useMap"
  (load)="loaded(image)"
/>
<ng-template #loading>
  <div class="loading"></div>
</ng-template>
