import { Component, Inject, OnInit } from '@angular/core';
import { E2gSelectOption } from '@equityeng/e2g-ng-ui';
import { faSquareInfo } from '@fortawesome/pro-regular-svg-icons';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { concatMap, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { BaseSlideoutOption } from 'src/app/asset-module/models/base-slideout-option';
import { IdNameDto } from 'src/app/asset-module/models/id-name-dto';
import { ComponentLinkedDataDto } from 'src/app/models/component-record-dto';
import { EquipmentConfigurationDto } from 'src/app/models/equipment-configuration-dto';
import { BUSINESS_RULES } from 'src/app/sage-common-module/business-rules-injection-token';
import { BusinessRulesDefinition } from 'src/app/sage-common-module/models/business-rules-definition';
import { DialogService } from 'src/app/shared-module/dialog.service';
import { ListDataHandler } from 'src/app/shared-module/list-data-handler';
import { ActionTypes } from 'src/app/shared-module/models/action-types';
import { DialogButtons } from 'src/app/shared-module/models/dialog-buttons';
import { DialogData } from 'src/app/shared-module/models/dialog-data';
import { DialogResult } from 'src/app/shared-module/models/dialog-result';
import { NEW_ITEM_KEY } from 'src/app/shared-module/models/new-item-key';
import { AssetFeature, AssetPermissionModel } from 'src/app/shared-module/permission/permission-models';
import { PermissionService } from 'src/app/shared-module/permission/permission.service';
import { NotificationService } from 'src/app/shared-module/services/notification.service';
import { SlideOutContainerAction } from 'src/app/slide-out-module/models/constants/slide-out-container-action';
import { SlideOutContainerService } from 'src/app/slide-out-module/slide-out-container.service';
import { SlideOutRef } from 'src/app/slide-out-module/slide-out-ref';
import { StateService } from 'src/app/state.service';
import { UnitsOfMeasureEvaluator } from 'src/app/units-of-measure/units-of-measure-evaluator';
import { duplicateItemNameMessage } from 'src/app/utilities/validation-messages';

import { CircuitDataService } from '../../../circuit-data.service';
import { EquipmentDataService } from '../../../equipment-data.service';
import { FluidStreamDataService } from '../../../fluid-data.service';
import { CircuitDto, getNewCircuitDto } from '../../../models/circuit-dto';
import { FluidStreamDto } from '../../../models/fluid-stream-dto';
import { OnDestroyBaseComponent } from '../../../on-destroy-base-component/on-destroy-base-component';
import { SingleDataHandlerDefault } from '../../../shared-module/single-data-handler-default';
import { SLIDE_OUT_DATA } from '../../../slide-out-module/slide-out-data-injection-token';
import { INSULATION_TYPE_VALUES, LINING_TYPE_VALUES } from '../../../utilities/hardcoded-lists';
import { CircuitSlideoutInput } from '../../models/circuit-slideout-input';
import { CmlAssignedOrUnassignedToACircuitDto } from '../../models/cml-assigned-or-unassigned-to-a-circuit-dto';
import { CrackingMechanisms } from '../../models/enums/cracking-mechanisms';
import { CrackingSusceptibilities } from '../../models/enums/cracking-susceptibilities';
import { ExternalEnvironments } from '../../models/enums/external-environments';
import { InternalThinningTypes } from '../../models/enums/internal-thinning-types';
import { ThinningDamageMechanisms } from '../../models/enums/thinning-damage-mechanisms';
import { CircuitBusinessRulesService } from '../../services/circuit-detail-business-rules.service';
import { CmlDataService } from '../../services/cml-data.service';

interface ConditionMonitoringLocation {
  id: string;
  name: string;
  checked: boolean;
  isAssociatedWithOtherCircuit: boolean;
}

@Component({
  selector: 'app-circuit-detail',
  templateUrl: './circuit-detail.component.html',
  styleUrls: ['./circuit-detail.component.css'],
  providers: [
    {
      provide: BUSINESS_RULES,
      useClass: CircuitBusinessRulesService
    }
  ]
})
export class CircuitDetailComponent extends OnDestroyBaseComponent implements OnInit {
  public editing: boolean = false;

  private saveCompleted = new Subject<void>();

  public activeTab: number = 1;
  public dataHandler: SingleDataHandlerDefault<CircuitDto>;
  public cmlDataHandler: ListDataHandler<ConditionMonitoringLocation>;
  public streams?: Array<FluidStreamDto>;
  public streamOptions?: Array<E2gSelectOption>;
  public selectedStream?: FluidStreamDto;
  public rbi: boolean = false;
  private cmls: Array<CmlAssignedOrUnassignedToACircuitDto> = [];
  private assetChanged: boolean = false;
  private assetId: string = '';

  public readOnly: boolean = false;

  public internalThinningTypeList: Array<E2gSelectOption> = new Array<E2gSelectOption>();
  public thinningDamageMechanismList: Array<E2gSelectOption> = new Array<E2gSelectOption>();
  public externalEnvironmentList: Array<E2gSelectOption> = new Array<E2gSelectOption>();

  public loading: boolean = true;

  public faSquareInfo = faSquareInfo;

  public uniqueMaterialNames?: Array<string>;
  public uniqueLiningNames?: Array<string>;
  public uniqueInsulationNames?: Array<string>;

  public externalCorrosionRateUom: Observable<string> = this.uomEvaluator.getUnits('EstimatedCorrosionRate');

  public constructor(
    private permissionService: PermissionService,
    private uomEvaluator: UnitsOfMeasureEvaluator,
    private cmlService: CmlDataService,
    private circuitService: CircuitDataService,
    private fluidService: FluidStreamDataService,
    private equipService: EquipmentDataService,
    private notificationService: NotificationService,
    private stateService: StateService,
    private slideOutRef: SlideOutRef,
    private slideOutContainerService: SlideOutContainerService,
    private dialogService: DialogService,
    @Inject(SLIDE_OUT_DATA) private slideOutData: CircuitSlideoutInput,
    @Inject(BUSINESS_RULES) private businessRulesService: BusinessRulesDefinition
  ) {
    super();
    this.configureSlideOut();

    this.dataHandler = new SingleDataHandlerDefault<CircuitDto>(this.destroy, (dirty: boolean) => {
      this.slideOutContainerService.setDirty(dirty);
    });

    this.cmlDataHandler = new ListDataHandler<ConditionMonitoringLocation>('id', 'updateType', this.destroy);

    this.businessRulesService.init(this.dataHandler);
  }

  public ngOnInit(): void {
    this.loadStaticControlsData();

    // Get the fluid streams.  Is not dependent on the circuit/asset.
    this.getFluidStreams().subscribe(() => this.initializeSlideoutHeader());

    // This happens when a user navigates to another circuit or the slideout is initially loaded.
    this.slideOutContainerService.optionId
      .pipe(
        tap((id) => this.initializeForm(id)),
        switchMap((id) => forkJoin(this.loadData(id))),
        takeUntil(this.destroy)
      )
      .subscribe(() => this.loadingFormComplete());

    // This is called when the save completes of a existing circuit.
    this.saveCompleted
      .pipe(
        tap(() => this.initializeForm(this.slideOutData.id)),
        switchMap(() => this.getGroupNamesForAsset()),
        switchMap(() => this.getCircuit(this.slideOutData.id)),
        takeUntil(this.destroy)
      )
      .subscribe(() => this.loadingFormComplete());
  }

  private configureSlideOut(): void {
    this.editing = this.slideOutData.id !== NEW_ITEM_KEY;

    this.slideOutContainerService.nameUpdated.pipe(takeUntil(this.destroy)).subscribe((newName) => {
      this.dataHandler.data.name = newName;
    });

    this.slideOutContainerService.action
      .pipe(
        filter((action) => action === SlideOutContainerAction.Revert),
        takeUntil(this.destroy)
      )
      .subscribe(() => {
        this.dataHandler.revertChanges();
        this.onStreamChange();
      });

    this.slideOutContainerService.action
      .pipe(
        filter((action) => action === SlideOutContainerAction.Save || action === SlideOutContainerAction.SaveAndClose),
        switchMap((action) =>
          this.circuitService.saveCircuit(this.dataHandler.getDataWithEmptyStringsRemoved()).pipe(
            map((result) => {
              if (typeof result === 'string') {
                // Add new circuit
                this.slideOutData.id = result;
                this.notificationService.showActionResult(true, ActionTypes.Add, this.dataHandler.data.name!);
                this.editing = true;
                this.stateService.setSelectedGridId(this.slideOutData.gridContext!, result);

                this.addNewItemToHeaderDropdown(result);

                this.slideOutContainerService.setOptions(this.buildDropdownOptions(this.slideOutData.optionsData));
                this.slideOutContainerService.setOptionId(this.slideOutData.id);
              } else {
                this.notificationService.showActionResult(result, ActionTypes.Update, this.dataHandler.data.name!);

                // Check if the update was successful.
                if (result) {
                  this.slideOutData.id = this.dataHandler.data.id!;

                  this.addNewItemToHeaderDropdown(this.slideOutData.id);

                  this.slideOutContainerService.setOptions(this.buildDropdownOptions(this.slideOutData.optionsData));

                  this.saveCompleted.next();
                }
              }

              return action;
            })
          )
        ),
        filter((action) => action === SlideOutContainerAction.SaveAndClose),
        tap(() => this.slideOutRef.close()),
        takeUntil(this.destroy)
      )
      .subscribe();

    this.slideOutContainerService.action
      .pipe(
        filter((action) => action === SlideOutContainerAction.Cancel),
        takeUntil(this.destroy)
      )
      .subscribe(() => {
        this.slideOutRef.close();
      });
  }

  private buildDropdownOptions(circuitData: Record<string, BaseSlideoutOption>): Array<E2gSelectOption> {
    return Object.entries(circuitData).map(([key, value]) => ({ value: key, label: value.name }));
  }

  private getUniqueMaterialsNames(componentLinkedData: Array<ComponentLinkedDataDto> | undefined): Array<string> {
    const result = componentLinkedData?.reduce((p, c) => {
      let materialText;
      if (c.unsGroup) {
        materialText = `${c.unsGroup}: `;
      }
      if (c.commonName) {
        materialText = `${materialText}${c.commonName}`;
      }

      if (materialText) {
        if (!p.includes(materialText)) {
          p.push(materialText);
        }
      }

      return p;
    }, new Array<string>());

    return result !== undefined ? (result!.length > 0 ? result : ['']) : [''];
  }

  private getUniqueLiningNames(componentLinkedData: Array<ComponentLinkedDataDto> | undefined): Array<string> {
    const result = componentLinkedData!.reduce((p, c) => {
      const liningText = c.liningType
        ? LINING_TYPE_VALUES.find((y) => y.value === c.liningType)?.label || 'unknown'
        : 'None';

      if (liningText) {
        if (!p.includes(liningText)) {
          p.push(liningText);
        }
      }

      return p;
    }, new Array<string>());

    return result.length > 0 ? result : [''];
  }

  private getUniqueInsulationNames(componentLinkedData: Array<ComponentLinkedDataDto> | undefined): Array<string> {
    const result = componentLinkedData!.reduce((p, c) => {
      const liningText = c.insulationType
        ? INSULATION_TYPE_VALUES.find((y) => y.value === c.insulationType)?.label || 'unknown'
        : 'None';

      if (liningText) {
        if (!p.includes(liningText)) {
          p.push(liningText);
        }
      }

      return p;
    }, new Array<string>());

    return result.length > 0 ? result : [''];
  }

  public externalCorrosion(componentLinkedData: Array<ComponentLinkedDataDto> | undefined): boolean {
    if (
      componentLinkedData === undefined ||
      componentLinkedData.length == 0 ||
      componentLinkedData.every((x) => x.unsGroup === undefined || x.unsGroup == '')
    ) {
      return true;
    }

    return componentLinkedData.findIndex((x) => x.unsGroup == 'LA' || x.unsGroup == 'CS') > -1;
  }

  public externalCracking(componentLinkedData: Array<ComponentLinkedDataDto> | undefined): boolean {
    if (
      componentLinkedData === undefined ||
      componentLinkedData.length == 0 ||
      componentLinkedData.every((x) => x.subGroup === undefined || x.subGroup == '')
    ) {
      return true;
    }
    return componentLinkedData.findIndex((x) => x.subGroup == 'AS') > -1;
  }

  public isLined(componentLinkedData: Array<ComponentLinkedDataDto> | undefined): boolean {
    return (
      componentLinkedData !== undefined &&
      componentLinkedData.length != 0 &&
      componentLinkedData.some((x) => x.liningType !== 'NONE' && x.liningType !== undefined)
    );
  }

  public isInsulated(componentLinkedData: Array<ComponentLinkedDataDto> | undefined): boolean {
    return (
      componentLinkedData !== undefined &&
      componentLinkedData.length != 0 &&
      componentLinkedData.some((x) => x.insulationType !== 'NONE')
    );
  }

  public isInsulatedAndUninsulated(componentLinkedData: Array<ComponentLinkedDataDto> | undefined): boolean {
    return (
      componentLinkedData !== undefined &&
      componentLinkedData.length != 0 &&
      componentLinkedData.some((x) => x.insulationType === 'NONE') &&
      componentLinkedData.some((x) => x.insulationType !== 'NONE')
    );
  }

  public externalTypeMessage(): string {
    if (
      this.dataHandler.data.componentLinkedDataDtos &&
      this.externalCorrosion(this.dataHandler.data.componentLinkedDataDtos) &&
      this.externalCracking(this.dataHandler.data.componentLinkedDataDtos)
    ) {
      return 'Multiple generic material types present in circuit. Cracking and corrosion are both available.';
    } else if (
      this.externalCorrosion(this.dataHandler.data.componentLinkedDataDtos) &&
      !this.externalCracking(this.dataHandler.data.componentLinkedDataDtos)
    ) {
      if (this.isInsulatedAndUninsulated(this.dataHandler.data.componentLinkedDataDtos)) {
        return 'Damage type: partial CUI and External Corrosion';
      }
      return (
        'Damage type: ' +
        (this.isInsulated(this.dataHandler.data.componentLinkedDataDtos) ? 'CUI' : 'External Corrosion')
      );
    } else if (
      !this.externalCorrosion(this.dataHandler.data.componentLinkedDataDtos) &&
      this.externalCracking(this.dataHandler.data.componentLinkedDataDtos)
    ) {
      if (this.isInsulatedAndUninsulated(this.dataHandler.data.componentLinkedDataDtos)) {
        return 'Damage type: Cracking (partially insulated)';
      }
      return (
        'Damage type: ' +
        (this.isInsulated(this.dataHandler.data.componentLinkedDataDtos) ? 'Cracking under insulation' : 'Cracking')
      );
    }

    return 'No materials are associated with components in circuit. Cracking and corrosion are both available.';
  }

  public loadConditionMonitoringLocations(circuitId: string): void {
    const conditionMonitoringLocations = this.cmls.map((x) => {
      return {
        id: x.cmlId,
        name: x.displayName,
        checked: this.dataHandler.data.conditionMonitoringLocations.includes(x.cmlId!),
        isAssociatedWithOtherCircuit: x.circuitId === undefined ? false : x.circuitId !== circuitId
      } as ConditionMonitoringLocation;
    });

    this.cmlDataHandler.setInitialData(conditionMonitoringLocations);
  }

  public onCmlChange(cmlItem: ConditionMonitoringLocation): void {
    if (cmlItem.checked) {
      if (!this.dataHandler.data.conditionMonitoringLocations?.includes(cmlItem.id)) {
        this.dataHandler.data.conditionMonitoringLocations = [
          ...this.dataHandler.data.conditionMonitoringLocations,
          cmlItem.id
        ];
      }
    } else {
      if (this.dataHandler.data.conditionMonitoringLocations?.includes(cmlItem.id)) {
        this.dataHandler.data.conditionMonitoringLocations = this.dataHandler.data.conditionMonitoringLocations.filter(
          (x) => {
            return x !== cmlItem.id;
          }
        );
      }
    }
  }

  public refreshFluidStreams(streamId: string | undefined): void {
    this.dataHandler.data.fluidStreamId = streamId;

    this.fluidService.getFluidStreams().subscribe((x) => {
      this.streams = x;
      this.streamOptions = this.streams.map((x) => ({
        value: x.id!,
        label: `${x.name!}`
      }));

      this.onStreamChange();
    });
  }

  public onStreamChange(): void {
    this.selectedStream = this.streams!.find((x) => x.id === this.dataHandler.data.fluidStreamId);
  }

  public onDeleteFluidStream(): void {
    let streamName: string | undefined = 'Fluid Stream';
    let msg: string;

    if (this.selectedStream !== undefined) {
      if (this.selectedStream.id !== undefined) {
        if (this.selectedStream.name !== undefined) {
          streamName = this.selectedStream.name;
        }
        const deleteFluidStreamDialogData = {
          title: `Delete Fluid Stream - ${streamName}`,
          message: 'Are you sure?',
          buttons: DialogButtons.YesCancel,
          yesButtonText: 'Delete'
        } as DialogData;

        this.dialogService
          .display(deleteFluidStreamDialogData)
          .pipe(
            filter((x) => x === DialogResult.yes),
            concatMap(() => {
              return this.fluidService.deleteFluidStream(this.selectedStream!.id!, this.slideOutData.id).pipe(
                tap((x: number) => {
                  if (x > 0) {
                    msg = `Unable to delete ${streamName}, it is used by ${x}${x == 1 ? ' other' : ''} circuit${
                      x > 1 ? 's' : ''
                    }.`;
                  } else if (x < 0) {
                    msg = `Unable to delete ${streamName}. An error occurred.`;
                  } else {
                    msg = `Deleted ${streamName}`;
                  }
                  return x;
                }),
                concatMap((x: number) => {
                  if (x === 0) {
                    return this.fluidService.getFluidStreams().pipe(
                      tap((x: FluidStreamDto[]) => {
                        this.streams = x;
                        this.streamOptions = this.streams.map((x) => ({
                          value: x.id!,
                          label: `${x.name!}`
                        }));

                        this.dataHandler.data.fluidStreamId = undefined;
                      }),
                      takeUntil(this.destroy)
                    );
                  }

                  return of(undefined);
                }),
                tap(() => this.notificationService.showInfo(msg)),
                takeUntil(this.destroy)
              );
            })
          )
          .subscribe();
      }
    }
  }

  private loadStaticControlsData(): void {
    this.internalThinningTypeList = [
      ...(Object.entries(InternalThinningTypes)
        .filter((e) => !isNaN(e[0] as any))
        .map((e) => ({ value: Number(e[0]), label: e[1] })) as Array<E2gSelectOption>)
    ];

    this.internalThinningTypeList.unshift({ value: '', label: '' });

    this.thinningDamageMechanismList = Object.entries(ThinningDamageMechanisms).map((e) => ({
      value: e[1],
      label: e[0]
    })) as Array<E2gSelectOption>;

    this.externalEnvironmentList = [
      ...(Object.entries(ExternalEnvironments).map((e) => ({
        value: e[1],
        label: e[0]
      })) as Array<E2gSelectOption>)
    ];

    this.externalEnvironmentList.unshift({ value: '', label: '' });
  }

  public changeSusceptibility(selection: number, mech: string): void {
    const key = CrackingMechanisms[mech as keyof typeof CrackingMechanisms];
    if (this.dataHandler.data.crackingMechanisms !== undefined) {
      if (selection > 1 && !this.dataHandler.data.crackingMechanisms.includes(key, 0)) {
        this.dataHandler.data.crackingMechanisms.push(key);
      } else if (selection == 1 && this.dataHandler.data.crackingMechanisms.includes(key, 0)) {
        const index = this.dataHandler.data.crackingMechanisms.indexOf(key, 0);
        if (index > -1) {
          this.dataHandler.data.crackingMechanisms.splice(index, 1);
        }
      }
    } else {
      this.dataHandler.data.crackingMechanisms = [key];
    }
  }

  private setCrackingDefaults(dto: CircuitDto): void {
    [
      'causticCrackingSusceptibility',
      'amineCrackingSusceptibility',
      'sscH2sCrackingSusceptibility',
      'hicSohicH2sCrackingSusceptibility',
      'carbonateCrackingSusceptibility',
      'polythionicAcidCrackingSusceptibility',
      'chlorideCrackingSusceptibility',
      'hicSohicHfCrackingSusceptibility',
      'hscHfCrackingSusceptibility',
      'otherCrackingSusceptibility'
    ].forEach((x) => this.setIfUndefined(dto, x, CrackingSusceptibilities.None));
  }

  private setIfUndefined(dto: Record<string, any>, propName: string, value: number): void {
    if (dto[propName] === undefined) {
      dto[propName] = value;
    }
  }

  private loadData(id: string): Array<Observable<any>> {
    const assetDataPossiblyUpdatedOnSave = [this.getCmls(), this.getGroupNamesForAsset()];
    return this.assetChanged
      ? [
          this.getAssetConfiguration().pipe(switchMap(() => this.getCircuit(id))),
          this.getAssetPermissions(),
          ...assetDataPossiblyUpdatedOnSave
        ]
      : [this.getCircuit(id), ...assetDataPossiblyUpdatedOnSave];
  }

  private getGroupNamesForAsset(): Observable<void> {
    return this.circuitService.getCircuitNamesInAsset(this.assetId).pipe(
      tap((groupNames: Array<IdNameDto>) => {
        this.slideOutContainerService.setHeaderInvalidNameList(
          groupNames.filter((x) => x.id !== this.slideOutData.id).map((x) => x.name)
        );
      }),
      map(() => undefined),
      takeUntil(this.destroy)
    );
  }

  private loadingFormComplete(): void {
    this.loadConditionMonitoringLocations(this.slideOutData.id);
    this.loading = false;
  }
  private getCmls(): Observable<void> {
    return this.cmlService.getCmlsAssignedOrUnassignedToACircuit(this.assetId).pipe(
      tap((cmls) => {
        this.cmls = cmls;
      }),
      map(() => undefined)
    );
  }

  private getAssetPermissions(): Observable<AssetPermissionModel> {
    return this.permissionService.getAssetPermissionModel(AssetFeature.Circuit, this.assetId).pipe(
      tap((access: AssetPermissionModel) => {
        this.readOnly = !access.edit;
        this.slideOutContainerService.setAllowRename(access.edit);
      }),
      take(1)
    );
  }

  private getAssetConfiguration(): Observable<EquipmentConfigurationDto> {
    return this.equipService.getEquipmentConfiguration(this.assetId).pipe(
      tap((config) => {
        if (config && config.rbi) {
          this.rbi = config.rbi;
        } else {
          this.rbi = false;
        }
      })
    );
  }

  private getCircuit(id: string): Observable<CircuitDto> {
    return (
      this.editing ? this.circuitService.getCircuit(id) : of(getNewCircuitDto(this.slideOutData.unitId, this.assetId))
    ).pipe(
      tap((circuit: CircuitDto) => {
        this.slideOutData.id = circuit.id!;

        if (circuit.externalEnvironment === '') {
          circuit.externalEnvironment = undefined;
        }

        this.setCrackingDefaults(circuit);

        this.uniqueMaterialNames = this.getUniqueMaterialsNames(circuit.componentLinkedDataDtos);
        this.uniqueLiningNames = this.getUniqueLiningNames(circuit.componentLinkedDataDtos);
        this.uniqueInsulationNames = this.getUniqueInsulationNames(circuit.componentLinkedDataDtos);

        if (!this.isLined(circuit.componentLinkedDataDtos)) {
          circuit.equipmentLining = false;
        }

        if (circuit.externalCorrosionRateBasis === undefined) {
          circuit.externalCorrosionRateBasis = true;
        }

        if (circuit.externalCrackingSusceptibilityBasis === undefined) {
          circuit.externalCrackingSusceptibilityBasis = true;
        }

        circuit.rbi = this.rbi;
        this.dataHandler.setInitialData(circuit);

        setTimeout(() => {
          this.businessRulesService.start();
        });

        if (this.editing) {
          this.selectedStream = this.streams!.find((x) => x.id === this.dataHandler.data.fluidStreamId);
        }
      })
    );
  }

  private initializeForm(id: string): void {
    this.loading = true;

    const previousAssetId = this.assetId;
    this.assetId = this.editing ? this.slideOutData.optionsData[id].assetId : this.slideOutData.assetId;

    this.assetChanged = previousAssetId !== this.assetId;

    this.dataHandler.clear();

    if (this.editing) {
      this.stateService.setSelectedGridId(this.slideOutData.gridContext!, id);
    }
  }

  private initializeSlideoutHeader(): void {
    this.slideOutContainerService.setUseDefaultHeaderBorder(false);
    this.slideOutContainerService.setOptions(this.buildDropdownOptions(this.slideOutData.optionsData));
    this.slideOutContainerService.setOptionId(this.slideOutData.id);
    this.slideOutContainerService.setBreadcrumbs(this.slideOutData.breadcrumbs);
    this.slideOutContainerService.setCustomNameError(duplicateItemNameMessage('Group'));
  }

  private getFluidStreams(): Observable<Array<FluidStreamDto>> {
    return this.fluidService.getFluidStreams().pipe(
      tap((streams: Array<FluidStreamDto>) => {
        this.streams = streams;

        this.streamOptions = [
          ...streams.map((x: FluidStreamDto) => ({
            value: x.id!,
            label: x.name!
          }))
        ];
      })
    );
  }

  private addNewItemToHeaderDropdown(id: string): void {
    this.slideOutData.optionsData[id] = {
      name: this.dataHandler.data.name!,
      assetId: this.dataHandler.data.assetId!
    };
  }
}
