<ng-container *ngIf="notifications | async as localNotifications; else loading">
  <div class="d-flex flex-column h-100" clickOutside (clickOutside)="onOutsideClick($event)">
    <div class="alert alert-primary mb-0 d-flex justify-content-between align-items-center">
      <div><h2 class="mb-0">Notifications</h2></div>
      <div>
        <button
          type="button"
          class="btn btn-sm btn-danger"
          (click)="onDeleteAll()"
          [disabled]="!canDeleteAll(localNotifications)"
          id="btn-clear"
        >
          Clear
        </button>
      </div>
    </div>
    <div style="overflow-y: auto">
      <ng-container *ngIf="localNotifications.length > 0; else noNotifications">
        <div id="div-notifications" class="list-group-flush">
          <ng-container *ngFor="let item of localNotifications; trackBy: trackNotification">
            <div class="notification-item">
              <div class="d-flex justify-content-between w-100 p-2">
                <div class="d-flex flex-nowrap align-items-center">
                  <ng-container *ngIf="item.hasError || item.hasWarning; else spacer">
                    <fa-icon
                      [icon]="getIcon(item)"
                      size="lg"
                      (click)="toggleExpanded($event, item)"
                      class="fa-fw mr-2 notification-details pointer"
                    ></fa-icon>
                  </ng-container>
                  <div class="font-weight-bold mr-1">
                    {{ item.name }} {{ item.creationTime | date : 'M/d/yy h:mma' }}
                  </div>
                  <em class="small" *ngIf="item.status !== inProgress; else progress">{{ item.statusText }}</em>
                  <ng-template #progress>
                    <div class="progress-container">
                      <div class="progress-grey">
                        <div id="div-progress" class="progress-green" [style]="getProgressStyle(item.progress)"></div>
                      </div>
                    </div>
                  </ng-template>
                </div>
                <div class="d-flex flex-nowrap align-items-center">
                  <fa-icon
                    *ngIf="item.hasFile"
                    [icon]="faDownload"
                    size="lg"
                    class="icon-secondary fa-fw mr-1 ml-1"
                    (click)="download(item)"
                  ></fa-icon>
                  <fa-icon
                    *ngIf="item.hasError"
                    [icon]="faTimesCircle"
                    size="lg"
                    class="fa-fw mr-1 error ml-1"
                  ></fa-icon>
                  <fa-icon
                    *ngIf="item.hasWarning"
                    [icon]="faExclamationTriangle"
                    size="lg"
                    class="fa-fw mr-1 warning ml-1"
                  ></fa-icon>
                  <fa-icon
                    *ngIf="item.status !== jobStatus.InProgress"
                    [icon]="faTrashXmark"
                    size="lg"
                    class="delete-notification pointer ml-2"
                    (click)="onDelete(item.source, item.id)"
                  >
                  </fa-icon>
                </div>
              </div>
              <div
                *ngIf="item.expanded"
                class="p-2"
                [messageId]="item.id"
                [jobSource]="item.source"
                app-notification-details
              ></div>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-template #noNotifications>
  <div class="p-2" id="div-no-notifications">No notifications at this time</div>
</ng-template>
<ng-template #loading>
  <div class="loading" id="div-loading"></div>
</ng-template>
<ng-template #spacer>
  <div class="fa-fw mr-2" size="lg" id="div-spacer"></div>
</ng-template>
