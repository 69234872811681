import { E2gSelectOption } from '@equityeng/e2g-ng-ui';

export enum ServiceStatus {
  'In Service' = 1,
  'Out Of Service' = 2,
  'Decommissioned' = 3
}

export function getServiceStatusOptions(): Array<E2gSelectOption> {
  return Object.entries(ServiceStatus)
    .filter((e) => !isNaN(e[0] as any))
    .map((e) => ({
      value: Number(e[0]),
      label: e[1]
    })) as Array<E2gSelectOption>;
}
