<div
  #breadcrumbsRow
  id="breadcrumbs-row"
  class="breadcrumb-overflow"
  [matTooltip]="getTooltip()"
  [matTooltipDisabled]="tooltipDisabled"
>
  <ng-container *ngFor="let entry of breadcrumbs; let i = index">
    <div *ngIf="!hasLink(entry, i)" class="breadcrumb-overflow breadcrumb-nolink">{{ entry.name }}</div>
    <a *ngIf="hasLink(entry, i)" (click)="entry.linkCommand!()" class="breadcrumbs breadcrumb-overflow underline">{{
      entry.name
    }}</a>
    <div *ngIf="i != breadcrumbs.length - 1" class="breadcrumb-default-cursor">&nbsp; &gt; &nbsp;</div>
  </ng-container>
</div>
