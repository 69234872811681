import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CmlNameDto } from 'src/app/asset-module/models/cml-name-dto';

import { AppSettingsService } from '../../app-settings/app-settings.service';
import { CmlAssignedOrUnassignedToACircuitDto } from '../models/cml-assigned-or-unassigned-to-a-circuit-dto';
import { CmlDataDto } from '../models/cml-data-dto';
import { CmlDto } from '../models/cml-dto';
import { CmlFormDto } from '../models/cmlForm-dto';

@Injectable({
  providedIn: 'root'
})
export class CmlDataService {
  private serverUri: string;

  public constructor(private http: HttpClient, private settingsService: AppSettingsService) {
    this.serverUri = this.settingsService.settings.apiUri as string;
  }

  public getCmls(equipmentId: string): Observable<Array<CmlDto>> {
    return this.http
      .get<Array<CmlDto>>(`${this.serverUri}/cml/func-loc/${equipmentId}/cmls`)
      .pipe(catchError(() => of(new Array<CmlDto>())));
  }

  public getCml(id: string): Observable<CmlDataDto> {
    return this.http.get<CmlDataDto>(`${this.serverUri}/cml/${id}`).pipe(catchError(() => of({} as CmlDataDto)));
  }

  public updateCmls(conditionMonitoringLocations: Array<CmlDto>): Observable<boolean> {
    const body = JSON.stringify(conditionMonitoringLocations);

    if (conditionMonitoringLocations.length == 0) {
      return of(false);
    }
    return this.http.put<boolean>(`${this.serverUri}/cml/update/batch`, body).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  public deleteCml(cmlId: string): Observable<boolean> {
    return this.http.delete<boolean>(`${this.serverUri}/cml/${cmlId}/delete`).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  public getCmlsAssignedOrUnassignedToACircuit(
    assetId: string
  ): Observable<Array<CmlAssignedOrUnassignedToACircuitDto>> {
    return this.http
      .get<Array<CmlAssignedOrUnassignedToACircuitDto>>(`${this.serverUri}/cml/assets/${assetId}/cmls`)
      .pipe(catchError(() => of(new Array<CmlAssignedOrUnassignedToACircuitDto>())));
  }

  public renameCml(id: string, name: string): Observable<boolean> {
    return this.http.put(`${this.serverUri}/cml/rename`, { id, name }).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  public copyCml(cmlId: string, name: string): Observable<string | undefined> {
    return this.http
      .post<string>(`${this.serverUri}/cml/copy/${cmlId}`, { name })
      .pipe(catchError(() => of(undefined)));
  }

  public createCml(cml: CmlFormDto): Observable<string | undefined> {
    return this.http.post<string>(`${this.serverUri}/cml/create`, cml).pipe(catchError(() => of(undefined)));
  }

  public updateCml(id: string, cml: CmlFormDto): Observable<boolean> {
    return this.http.put(`${this.serverUri}/cml/${id}`, cml).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  public getCmlNamesForAsset(assetId: string): Observable<Array<CmlNameDto>> {
    return this.http
      .get<Array<CmlNameDto>>(`${this.serverUri}/cml/assets/${assetId}/cmls-names`)
      .pipe(catchError(() => of([] as Array<CmlNameDto>)));
  }
}
