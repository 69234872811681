import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FluidStream } from '@equityeng/fluid-designer';
import { Observable, of, switchMap } from 'rxjs';
import { FluidStreamDto } from 'src/app/models/fluid-stream-dto';
import { DialogService } from 'src/app/shared-module/dialog.service';
import { DialogButtons } from 'src/app/shared-module/models/dialog-buttons';
import { DialogData } from 'src/app/shared-module/models/dialog-data';
import { DialogResult } from 'src/app/shared-module/models/dialog-result';
import { validCharactersMessage, valueInListMessage } from 'src/app/utilities/validation-messages';

@Component({
  selector: 'app-fluid-modal',
  templateUrl: './fluid-modal.component.html'
})
export class FluidModalComponent {
  public constructor(
    public dialogRef: MatDialogRef<FluidModalComponent>,
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      inputStream: FluidStream;
      title: string;
      streams: Array<FluidStreamDto>;
    }
  ) {}

  public onFluidSelected(fluidStream: any): void {
    this.dialogRef.close(fluidStream);
  }

  public onCancel(isDirty: boolean): void {
    if (!isDirty) {
      this.dialogRef.close();
      return;
    }
    this.dialogService
      .display({
        title: 'Abandon Changes',
        message: 'Unsaved changes will be lost.',
        yesButtonText: 'Abandon Changes',
        buttons: DialogButtons.YesCancel
      } as DialogData)
      .pipe(
        switchMap((result) => {
          if (result === DialogResult.yes) {
            this.dialogRef.close();
          }
          return of(false);
        })
      )
      .subscribe();
  }
  public isValid(): ((name: string) => Observable<string>) | undefined {
    return (name) => {
      if (!this.isNameValid(name)) {
        return of(validCharactersMessage(this.validCharsString));
      }
      if (this.isDuplicateComponentName(name)) {
        return of(valueInListMessage(name));
      }
      return of('');
    };
  }

  private readonly validChars = new RegExp("^[-a-zA-Z0-9'._ ]*$");
  public validCharsString = "letters, digits, a space, and the following characters: _ - ' .";

  private isNameValid(name: string): boolean {
    return this.validChars.test(name);
  }
  private isDuplicateComponentName(name: string): boolean {
    return this.data.streams.some((x) => x.name === name && x.name !== this.data.inputStream.name);
  }
}
