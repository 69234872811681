<div class="e2g-padding d-flex flex-column h-100">
  <div class="d-flex justify-content-between">
    <div>
      <ng-container *ngIf="dataHandler.data && !readonly">
        <button id="btn-add" class="btn e2g-btn-outline-primary" (click)="add()" [disabled]="saving">
          <fa-icon [icon]="faPlus" class="mr-2"></fa-icon>Add Comment
        </button>
      </ng-container>
    </div>
    <div class="align-middle">
      <ng-container *ngIf="dataHandler.dirty">
        <button id="btn-save" class="btn e2g-btn-outline-primary" (click)="save()" [disabled]="saving">
          <fa-icon [icon]="faSave" class="mr-2"></fa-icon>Save
        </button>
        <button id="btn-revert" class="btn e2g-btn-outline-primary ml-2" (click)="revertChanges()" [disabled]="saving">
          <fa-icon [icon]="faHistory" class="mr-2"></fa-icon>Revert Changes
        </button>
      </ng-container>
    </div>
  </div>
  <div id="comments-container" *ngIf="dataHandler.data; else loading">
    <div class="data-container" *ngFor="let item of dataHandler.data; let i = index">
      <div class="card shadow-sm">
        <div class="card-header">
          <div class="d-flex justify-content-between">
            <div>
              <div *ngIf="item.updateTime" class="d-flex">
                <div class="form-control-static">{{ item.updateTime | date : 'short' }}</div>
                <fa-icon
                  *ngIf="!readonly"
                  [icon]="faEdit"
                  class="icon-secondary ml-2"
                  style="font-size: 1rem"
                  (click)="toggleEdit(item)"
                ></fa-icon>
              </div>
            </div>
            <fa-icon
              *ngIf="!readonly"
              [icon]="faTrashXmark"
              size="lg"
              class="text-danger pointer"
              (click)="delete(i)"
              id="div-delete-{{ i }}"
            ></fa-icon>
          </div>
        </div>
        <div class="card-body">
          <div class="mt-2">
            <quill-editor
              *ngIf="item.editing; else readOnly"
              class="w-100"
              [(ngModel)]="item.comment"
              id="inp-comment-{{ i }}"
              (onContentChanged)="dataHandler.dataChanged()"
            ></quill-editor>
            <ng-template #readOnly><div [innerHTML]="item.comment"></div></ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #loading>
  <div class="loading"></div>
</ng-template>
