export enum ThinningDamageMechanisms {
  'Acid Sour Water Corrosion' = 'ACID SOUR WATER CORROSION',
  'Amine Corrosion' = 'AMINE CORROSION',
  'CO2 Corrosion' = 'CO2 CORROSION',
  'Cooling Water Corrosion' = 'COOLING WATER CORROSION',
  'H2S/H2 Corrosion' = 'H2S/H2 CORROSION',
  'HCL Corrosion' = 'HCL CORROSION',
  'HF Corrosion' = 'HF CORROSION',
  'Oxidation Corrosion' = 'HIGH TEMPERATURE OXIDATION CORROSION',
  'Soil Corrosion' = 'SOIL CORROSION',
  'Sour Water Corrosion' = 'SOUR WATER CORROSION',
  'Sulfidic/Naphthenic Acid' = 'SULFIDIC/NAPTHINIC ACID CORROSION',
  'Sulfuric Acid Corrosion' = 'SULFURIC ACID CORROSION',
  'Tank Floor Corrosion' = 'TANK FLOOR CORROSION',
  'Caustic Corrosion' = 'CAUSTIC CORROSION',
  'Galvanic Corrosion' = 'GALVANIC CORROSION',
  'Boiler Water Corrosion' = 'BOILER WATER CONDENSATE CORROSION',
  'Dealloying' = 'DEALLOYING',
  'Graphitic Corrosion' = 'GRAPHITIC CORROSION',
  'MIC' = 'MICROBIOLOGICALLY INDUCED CORROSION (MIC)',
  'Flue-Gas Corrosion' = 'FLUE-GAS DEW-POINT CORROSION',
  'Carburization' = 'CARBURIZATION',
  'Decarburization' = 'DECARBURIZATION',
  'Metal Dusting' = 'METAL DUSTING',
  'Fuel Ash Corrosion' = 'FUEL ASH CORROSION',
  'Nitriding' = 'NITRIDING',
  'Erosion/Erosion - Corrosion' = 'EROSION/EROSION - CORROSION',
  'Underdeposit Corrosion' = 'UNDERDEPOSIT CORROSION',
  'Unspecified Damage' = 'UNSPECIFIED THINNING DAMAGE',
  'Other' = 'OTHER'
}
