import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, take } from 'rxjs';

import { UnitOfMeasure } from '../models/enums/unit-of-measure';
import { BusinessRuleValueList } from '../sage-common-module/models/business-rule-value-list';
import { BusinessRulesDefinition } from '../sage-common-module/models/business-rules-definition';
import { SingleDataHandlerDefault } from '../shared-module/single-data-handler-default';
import { UnitsOfMeasureEvaluator } from '../units-of-measure/units-of-measure-evaluator';

@Injectable()
export abstract class BusinessRulesServiceBase implements BusinessRulesDefinition {
  public startup$: Observable<void> = new ReplaySubject<void>(1);
  public readOnly: boolean = false;
  public isLabelLeft: boolean = false;
  public labelWidth?: string;

  public unitSystem: UnitOfMeasure = UnitOfMeasure.UsStandard;

  private _dataHandler!: SingleDataHandlerDefault<any>;

  public constructor(private uomEvaluator: UnitsOfMeasureEvaluator) {
    this.uomEvaluator
      .getCurrentUnitSystem()
      .pipe(take(1))
      .subscribe((x) => (this.unitSystem = x));
  }

  public init<T extends object>(dataHandler: SingleDataHandlerDefault<T>): void {
    this._dataHandler = dataHandler;
    this.afterInit();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected afterInit(): void {}

  public start(): void {
    (this.startup$ as ReplaySubject<void>).next();
  }

  public get data(): any {
    return this._dataHandler.data;
  }

  public get dataHandler(): SingleDataHandlerDefault<any> {
    if (this.data !== undefined) {
      return this._dataHandler;
    } else {
      throw 'init must be called before dataHandler';
    }
  }

  public get isInitialized(): boolean {
    return this._dataHandler.initialized;
  }

  public abstract get valueData(): BusinessRuleValueList;
}
