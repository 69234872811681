import { E2gSelectOption } from '@equityeng/e2g-ng-ui';
import { ApiServiceClasses } from '../models/enums/api-service-classes';

export const API_SERVICE_CLASS_LIST: Array<E2gSelectOption> = [
  { value: ApiServiceClasses.Class1, label: 'Class 1' },
  { value: ApiServiceClasses.Class2, label: 'Class 2' },
  { value: ApiServiceClasses.Class3, label: 'Class 3' },
  { value: ApiServiceClasses.Class4, label: 'Class 4' }
];

export const API_SERVICE_CLASS_LIST_WITH_UNKNOWN: Array<E2gSelectOption> = [
  ...API_SERVICE_CLASS_LIST,
  { value: ApiServiceClasses.Unknown, label: 'Unknown' }
];
