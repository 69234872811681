<div *ngIf="!loading; else load">
  <div class="row mb-4 mt-2">
    <div class="col-6">
      <div class="card h-100">
        <h4 class="card-header mb-0">Pitting Geometric Limitations</h4>
        <div class="card-body">
          <div class="d-flex align-items-middle">
            <div class="sage-label">Corroded Minimum Measured Thickness :</div>
            <div
              id="corrodedMinimumMeasuredThickness"
              class="sage-value"
              [innerHTML]="toFixedFourFormater(dataHandler.data.corrodedMinimumMeasuredThickness!)"
            ></div>
            <div class="sage-units">{{ corrodedMinimumMeasuredThicknessUom | async }}</div>
          </div>

          <div class="d-flex align-items-middle">
            <div class="sage-label">Thickness Limit - Structural :</div>
            <div
              id="thicknessLimitStructural"
              class="sage-value"
              [innerHTML]="toFixedFourFormater(dataHandler.data.thicknessLimitStructural!)"
            ></div>
            <div class="sage-units">{{ thicknessLimitStructuralUom | async }}</div>
          </div>

          <div class="d-flex align-items-middle">
            <div class="sage-label">Remaining Thickness Ratio :</div>
            <div
              id="remainingThicknessRatio"
              class="sage-value"
              [innerHTML]="toFixedFourFormater(dataHandler.data.remainingThicknessRatio!)"
            ></div>
            <div class="sage-units">{{ remainingThicknessRatioUom | async }}</div>
          </div>

          <div class="d-flex align-items-middle">
            <div class="sage-label">Minimum Remaining Thickness Ratio :</div>
            <div
              id="minimumRemainingThicknessRatio"
              class="sage-value"
              [innerHTML]="toFixedFourFormater(dataHandler.data.minimumRemainingThicknessRatio!)"
            ></div>
            <div class="sage-units">{{ minimumRemainingThicknessRatioUom | async }}</div>
          </div>

          <div class="d-flex align-items-middle">
            <div class="sage-label">Maximum Pit Diameter :</div>
            <div
              id="remainingThicknessRatio"
              class="sage-value"
              [innerHTML]="toFixedFourFormater(dataHandler.data.maximumPitDiameter!)"
            ></div>
            <div class="sage-units">{{ maximumPitDiameterUom | async }}</div>
          </div>

          <div class="d-flex align-items-middle">
            <div class="sage-label">Maximum Allowable Pit Diameter :</div>
            <div
              id="maximumAllowablePitDiameter"
              class="sage-value"
              [innerHTML]="toFixedFourFormater(dataHandler.data.maximumAllowablePitDiameter!)"
            ></div>
            <div class="sage-units">{{ maximumAllowablePitDiameterUom | async }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-6">
      <div class="card h-100">
        <h4 class="card-header mb-0">Pitting MAWP (Including FCA)</h4>
        <div class="card-body">
          <div class="d-flex align-items-middle">
            <div class="sage-label">Design Pressure :</div>
            <div
              id="designPressure"
              class="sage-value"
              [innerHTML]="toFixedFourFormater(dataHandler.data.designPressure!)"
            ></div>
            <div class="sage-units">{{ designPressureUom | async }}</div>
          </div>

          <div class="d-flex align-items-middle">
            <div class="sage-label">Undamaged MAWP :</div>
            <div
              id="undamagedMawp"
              class="sage-value"
              [innerHTML]="toFixedFourFormater(dataHandler.data.undamagedMawp!)"
            ></div>
            <div class="sage-units">{{ undamagedMawpUom | async }}</div>
          </div>
          <div class="d-flex align-items-middle">
            <div class="sage-label">Damaged MAWP :</div>
            <div
              id="damagedMawp"
              class="sage-value"
              [innerHTML]="toFixedFourFormater(dataHandler.data.damagedMawp!)"
            ></div>
            <div class="sage-units">{{ damagedMawpUom | async }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-4">
    <div class="col-12">
      <div class="card h-100">
        <h4 class="card-header mb-0">Pitting Assessment Messages</h4>
        <div class="card-body">
          <ng-container *ngFor="let msg of dataHandler.data.pittingAssessmentMessages">
            <p [innerHTML]="parseMarkdown(msg)"></p>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-4">
    <div class="col-12">
      <div class="card h-100">
        <h4 class="card-header mb-0">Pitting Applicability and Limitations</h4>
        <div class="card-body">
          <ul class="mb-0 p-0 pl-3">
            <li *ngFor="let item of dataHandler.data.pittingApplicabilityAndLimitationsMessages!">{{ item }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #load>
  <div class="loading"></div>
</ng-template>
