import { Observable } from 'rxjs';

export abstract class ISaveChanges {
  public abstract isDirty(): boolean;
  public abstract saveChanges(): Observable<boolean>;
  public abstract revertChanges(): void;
  public abstract canSaveChanges?(): boolean;
  public abstract onCancel?(): void;
}


