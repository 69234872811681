import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { AppSettingsService } from './app-settings/app-settings.service';
import { UserDataDto } from './models/user-data-dto';
import { JobNotificationService } from './notifications-module/services/job-notifications.service';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {
  public constructor(
    private http: HttpClient,
    private settingsService: AppSettingsService,
    private jobNotificationsService: JobNotificationService
  ) {}

  public getUserData(): Observable<UserDataDto> {
    return this.http
      .get<UserDataDto>(`${this.settingsService.settings.apiUri}/user`)
      .pipe(catchError(() => of({} as UserDataDto)));
  }

  public updateUserData(userData: UserDataDto): Observable<boolean> {
    return this.http.put<boolean>(`${this.settingsService.settings.apiUri}/user`, userData).pipe(
      tap(() => this.jobNotificationsService.refreshNotifications()),
      map(() => true),
      catchError(() => of(false))
    );
  }
}
