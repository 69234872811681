import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { JobNotificationsDataService } from 'src/app/notifications-module/services/job-notifications-data.service';
import { OnDestroyBaseComponent } from 'src/app/on-destroy-base-component/on-destroy-base-component';

import { JobSources } from '../models/job-sources';

@Component({
  selector: '[app-notification-details], app-notification-details',
  templateUrl: './notification-details.component.html'
})
export class NotificationDetailsComponent extends OnDestroyBaseComponent implements OnInit, OnDestroy {
  @Input() public messageId: number = 0;
  @Input() public jobSource!: JobSources;

  public warnings = new Array<string>();
  public errors = new Array<string>();

  public constructor(private notificationsData: JobNotificationsDataService) {
    super();
  }

  public ngOnInit(): void {
    this.notificationsData
      .getMessages(this.jobSource, this.messageId)
      .pipe(takeUntil(this.destroy))
      .subscribe((x) => {
        this.warnings = x.warnings;
        this.errors = x.errors;
      });
  }
}
