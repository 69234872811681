import { inject } from '@angular/core';
import { forkJoin, map, Observable, of, takeUntil, tap } from 'rxjs';
import { ActivityDto } from 'src/app/models/activity-dto';
import { ActivityTemplates } from 'src/app/models/enums/activity-templates';

import { ActivityDetailsDataService } from './activity-details-data.service';
import { ActivityDetailsDomainModel } from './activity-details-dm';
import {
  ActivityDetailsAssetData,
  ActivityDetailsDataCache,
  ActivityDetailsGlobalData,
  ActivityDetailsInstanceData,
} from './activity-details-dm-configs';

export interface ActivityItem {
  id: string;
  assetId: string;
  template: ActivityTemplates;
}

export class ActivityDetailsDomainModelFactory {
  private dataService = inject(ActivityDetailsDataService);

  public constructor(private destroy: Observable<void>) {}

  public buildNew(
    assetId: string,
    activityTypeId: string,
    activityTemplate: ActivityTemplates,
    cache: ActivityDetailsDataCache = {}
  ): Observable<ActivityDetailsDomainModel> {
    return this.getDomainModel(cache, assetId, activityTemplate, () =>
      this.getNewInstanceData(assetId, activityTypeId)
    );
  }

  public getExisting(
    activity: ActivityItem,
    cache: ActivityDetailsDataCache = {}
  ): Observable<ActivityDetailsDomainModel> {
    return this.getDomainModel(cache, activity.assetId, activity.template, () =>
      this.dataService.getExistingActivityData(activity.id, activity.assetId, activity.template)
    );
  }

  private getDomainModel(
    cache: ActivityDetailsDataCache,
    assetId: string,
    activityTemplate: ActivityTemplates,
    getInstanceData: () => Observable<ActivityDetailsInstanceData>
  ): Observable<ActivityDetailsDomainModel> {
    return forkJoin([this.getGlobalData(cache), this.getAssetData(cache, assetId), getInstanceData()]).pipe(
      map(
        ([global, asset, instance]) =>
          new ActivityDetailsDomainModel({
            data: { global, asset, instance },
            destroy: this.destroy,
            template: activityTemplate,
            dataService: this.dataService
          })
      ),
      takeUntil(this.destroy)
    );
  }

  private getGlobalData(cache: ActivityDetailsDataCache): Observable<ActivityDetailsGlobalData> {
    return cache.global
      ? of(cache.global)
      : this.dataService.getGlobalData().pipe(
          tap((globalData) => {
            cache.global = globalData;
          })
        );
  }

  private getAssetData(cache: ActivityDetailsDataCache, assetId: string): Observable<ActivityDetailsAssetData> {
    if (cache.asset) {
      if (cache.asset.surveys == null) {
        return this.dataService.getAssetSurveys(assetId).pipe(
          tap((surveys) => {
            cache.asset!.surveys = surveys;
          }),
          map(() => cache.asset!)
        );
      }
      return of(cache.asset);
    }
    return this.dataService.getAssetData(assetId).pipe(tap((assetData) => (cache.asset = assetData)));
  }

  private getNewInstanceData(assetId: string, activityTypeId: string): Observable<ActivityDetailsInstanceData> {
    return this.dataService.getInitialGrades(assetId, activityTypeId).pipe(
      map((grades) => {
        const activity: ActivityDto = {
          name: '',
          campaign: '',
          description: '',
          inspectors: [],
          comments: '',
          assetId: assetId,
          activityTypeId: activityTypeId,
          inspectionGrades: grades
        };
        return { activity };
      })
    );
  }
}
