import { BusinessRulesDefinition } from '../sage-common-module/models/business-rules-definition';

interface IDataItem {
  value: any;
  isDefaultValue: boolean;
}

export class DataCancelHandler {
  private businessRules!: BusinessRulesDefinition;
  private propertyNames!: Array<string>;
  private initialData: { [index: string]: any } = {};

  public init(businessRules: BusinessRulesDefinition, propertyNames: Array<string>): void {
    this.businessRules = businessRules;
    this.propertyNames = propertyNames;
    this.saveInitialData();
  }

  public cancelChanges(): void {
    if (!this.businessRules) {
      throw 'init must be called';
    }

    this.propertyNames.forEach(name => {
      const initial = this.initialData[name];
      const current = this.buildDataItem(name);

      if (JSON.stringify(initial) !== JSON.stringify(current)) {
        // Reset value
        this.businessRules.data[name] = initial.value;
        // Additional steps to reset isDefaultValue to true
        if (initial.isDefaultValue) {
          this.businessRules.dataHandler.silentClearValue(name);
          this.businessRules.dataHandler.silentSetValue(name, initial.value, false);
        }
      }
    });
  }

  private saveInitialData(): void {
    this.propertyNames.forEach(name => {
      this.initialData[name] = this.buildDataItem(name);
    });
  }

  private buildDataItem(name: string): IDataItem {
    const value = this.businessRules.data[name];
    return {
      value: value,
      isDefaultValue: value === undefined ? true : this.businessRules.dataHandler.isDefaultValue(name)
    };
  }
}
