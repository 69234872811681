import { WeekDay } from '@angular/common';

export enum SortOrder {
  Acending = 1,
  Descending = 2
}

export const dateSort = (dateA: Date | undefined, dateB: Date | undefined, order: SortOrder): number => {
  const a = ensureDateObject(dateA);
  const b = ensureDateObject(dateB);

  if (!a) {
    return 0;
  }

  if (!b) {
    return 0;
  }

  if (order === SortOrder.Acending) {
    return a.getTime() - b.getTime();
  } else {
    return b.getTime() - a.getTime();
  }
};

export const ensureDateObject = (date: Date | undefined): Date | undefined => {
  if (date instanceof Date) {
    return date;
  }

  const realDate = dateFromString(date);
  if (realDate !== undefined) {
    return realDate;
  }

  return undefined;
};

const dateFromString = (dateString: string | undefined): Date | undefined => {
  const result = Date.parse(dateString?.replace(/-/g, '/').replace(/T.+/, '') || '');

  if (isNaN(result)) {
    return undefined;
  }

  return new Date(result);
};

export const setDateUpcomingDayOfWeek = (d: Date, dayOfWeek: WeekDay): void => {
  d.setDate(d.getDate() + ((dayOfWeek + 7 - d.getDay()) % 7));
};

export function getDateDifferenceInDays(a: Date, b: Date): number {
  const diff = Math.abs(a.getTime() - b.getTime());
  return Math.ceil(diff / (1000 * 3600 * 24));
}

const LengthOfDay = 1000 * 60 * 60 * 24;

export function addDays(d: Date, days: number): Date {
  return new Date(d.getTime() + days * LengthOfDay);
}

export function subtractDays(d: Date, days: number): Date {
  return new Date(d.getTime() - days * LengthOfDay);
}

export function changeMonths(d: Date, months: number): Date {
  const retDate = new Date(d);
  const newMonth = d.getMonth() + months;
  const daysInNewMonth = daysInMonth(newMonth % 12, d.getFullYear() + Math.floor(newMonth / 12));
  //If changing date does not exist in new month, set to the last date in new month
  if (daysInNewMonth < d.getDate()) {
    retDate.setDate(daysInNewMonth);
  }
  retDate.setMonth(d.getMonth() + months);
  return retDate;
}

export function daysInMonth(month: number, year: number): number {
  return new Date(year, month + 1, 0).getDate();
}
