export enum JobStatus {
  New = 1,
  InProgress = 2,
  Complete = 3,
  Cancelled = 4,
  Errored = 5
}

export function getJobStatsText(jogStatus: JobStatus): string {
  switch (jogStatus) {
    case JobStatus.New:
      return 'New';
    case JobStatus.InProgress:
      return 'In Progress';
    case JobStatus.Complete:
      return 'Complete';
    case JobStatus.Cancelled:
      return 'Cancelled';
    case JobStatus.Errored:
      return 'Error';
    default:
      throw new Error('Unknown JobStatus');
  }
}
