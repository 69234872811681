import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { OnDestroyBaseComponent } from 'src/app/on-destroy-base-component/on-destroy-base-component';

@Component({
  selector: 'app-slide-input',
  templateUrl: './slide-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SlideInputComponent),
      multi: true
    }
  ]
})
export class SlideInputComponent extends OnDestroyBaseComponent implements ControlValueAccessor {
  @Input() public heading?: string;
  @Input() public readOnly: boolean = false;
  @Output() public change = new EventEmitter<any>();

  public value!: boolean;

  private onChange: ((value: any) => void) | undefined;
  private onTouched: (() => void) | undefined;

  public registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public writeValue(value: any): void {
    this.value = value;
  }

  public handleChange(): void {
    // if (event && 'stopPropagation' in event) {
    //   event.stopPropagation();
    // }

    if (this.onChange) {
      this.onChange(this.value);
    }

    this.change.emit(this.value);
  }
}
