import { Component } from '@angular/core';
import { AuthService } from '@equityeng/auth';
import { map, Observable } from 'rxjs';
import { AppSettingsService } from 'src/app/app-settings/app-settings.service';
import { OnDestroyBaseComponent } from 'src/app/on-destroy-base-component/on-destroy-base-component';
import { ActionTypes } from 'src/app/shared-module/models/action-types';
import { DialogData } from 'src/app/shared-module/models/dialog-data';
import { NotificationService } from 'src/app/shared-module/services/notification.service';

import { UploadItemData } from '../../upload-module/upload/upload.component';

@Component({
  selector: 'app-attachment-upload-dialog',
  templateUrl: './attachment-upload-dialog.component.html'
})
export class AttachmentUploadDialogComponent extends OnDestroyBaseComponent {
  public dialogData!: DialogData;

  public constructor(
    private notificationService: NotificationService,
    private authService: AuthService,
    private appSettingsService: AppSettingsService
  ) {
    super();
  }

  public setDialogData(dialogData: DialogData): void {
    this.dialogData = dialogData;
  }

  public getUploadData = (): Observable<{ token: string; uploadUrl: string }> => {
    if (this.dialogData.componentData.tableType) {
      return this.authService.userAuthenticated$.pipe(
        map((user) => ({
          uploadUrl: `${this.appSettingsService.settings.apiUri}/attachments/${this.dialogData.componentData.tableType}/key/${this.dialogData.componentData.attachmentKey}`,
          token: user.accessToken
        }))
      );
    }
    return this.dialogData.componentData.getUploadData();
  };

  public successItem(data: UploadItemData): void {
    const success = data.status === 200;
    this.notificationService.showActionResult(success, ActionTypes.Upload, data.item.file.name ?? 'File');
    this.dialogData.componentData.uploadComplete(success);
  }
}
