import { Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-boolean-radio-buttons',
  templateUrl: './boolean-radio-buttons.component.html',
  styleUrls: ['./boolean-radio-buttons.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BooleanRadioButtonsComponent),
      multi: true
    }
  ]
})
export class BooleanRadioButtonsComponent implements ControlValueAccessor {
  public value!: boolean;

  @Input() public heading?: string;
  @Input() public falseLabel!: string;
  @Input() public trueLabel!: string;
  @Input() public readOnly: boolean = false;
  @Output() public change = new EventEmitter<boolean>();

  public onChange: ((value: boolean) => void) | undefined;
  public onTouched: (() => void) | undefined;

  public registerOnChange(fn: (value: boolean) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public writeValue(value: boolean): void {
    this.value = value || false;
  }

  public handleChange(): void {
    if (this.onChange) {
      this.onChange(this.value);
      this.change.emit(this.value);
    }
  }
}
