<div class="h-100 flex-col flex-111">
  <div class="header pt-3 pb-0 pl-3 pr-3 mb-3">
    <div class="d-flex flex-row mb-2">
      <div class="dashboard-slideout-header">{{ heading }}</div>
      <div class="d-flex ml-auto">
        <div id="btn-download">
          <app-download-spreadsheet (onDownload)="downloadExcelFile()"></app-download-spreadsheet>
        </div>
        <div class="align-self-center ml-4">
          <fa-icon id="btn-close" (click)="close()" class="icon-secondary" [icon]="closeIcon"></fa-icon>
        </div>
      </div>
    </div>

    <div class="d-flex flex-row flex-wrap filter">
      <div class="d-flex flex-column filter-item flex-fill mb-3">
        <e2g-multi-select-input
          id="select-plants"
          placeholder="Select Plants"
          [iconSelect]="faIndustryWindows"
          [(ngModel)]="filters.plantIds"
          [isLoading]="!plantList"
          [options]="plantList"
          [displayLimit]="displayLimit"
          [isSelectAll]="true"
          (change)="onPlantChange()"
        >
        </e2g-multi-select-input>
      </div>

      <div class="d-flex flex-column filter-item flex-fill mb-3">
        <e2g-multi-select-input
          id="select-units"
          placeholder="Select Units"
          [iconSelect]="faPipeValve"
          [(ngModel)]="filters.unitIds"
          [isLoading]="!unitList"
          [options]="unitList"
          [displayLimit]="displayLimit"
          [isSelectAll]="true"
          (change)="refreshContent()"
        >
        </e2g-multi-select-input>
      </div>

      <div class="d-flex flex-column filter-item flex-fill mb-3">
        <e2g-multi-select-input
          id="select-asset-types"
          placeholder="Select Asset Types"
          [iconSelect]="faBorderAll"
          [(ngModel)]="filters.assetTypes"
          [options]="assetTypeList"
          [displayLimit]="displayLimit"
          [isSelectAll]="true"
          (change)="refreshContent()"
        >
        </e2g-multi-select-input>
      </div>

      <div *ngIf="isChartSlideout" class="d-flex flex-column filter-item flex-fill mb-3">
        <app-planning-horizon
          [(ngModel)]="filters.planningHorizonFilterParams"
          (change)="refreshContent()"
        ></app-planning-horizon>
      </div>

      <div *ngIf="isMatrixSlideout" class="d-flex flex-column filter-item flex-fill mb-3">
        <e2g-ng-select-input
          id="select-damage-mode"
          placeholder="Damage Mode"
          [iconSelect]="faBurst"
          [(ngModel)]="filters.damageMode"
          [options]="damageModeOptions"
          (change)="refreshContent()"
        >
        </e2g-ng-select-input>
      </div>

      <div class="d-flex flex-column filter-item flex-fill mb-3">
        <e2g-multi-select-input
          id="select-service-staus"
          placeholder="Select Asset Service Status"
          [iconSelect]="faCircleHalfStroke"
          [(ngModel)]="filters.serviceStatuses"
          [options]="serviceStatusList"
          [displayLimit]="displayLimit"
          [isSelectAll]="true"
          (change)="refreshContent()"
        >
        </e2g-multi-select-input>
      </div>

      <div *ngIf="isActivity" class="d-flex flex-column filter-item flex-fill mb-3">
        <e2g-multi-select-input
          id="select-activity-type"
          placeholder="Select Activity Type"
          [iconSelect]="faHelmetSafety"
          [(ngModel)]="filters.activityTypes"
          [options]="activityTypeList"
          [displayLimit]="displayLimit"
          [isSelectAll]="true"
          (change)="refreshContent()"
        >
        </e2g-multi-select-input>
      </div>

      <div *ngIf="isMatrixSlideout && toggleVisible()" class="d-flex flex-column filter-item flex-fill mb-3">
        <e2g-check-input
          id="cb-filter-on-selection"
          heading="Filter On Selection"
          labelPosition="right"
          [(ngModel)]="filterOn"
          (change)="onChange($event)"
        >
        </e2g-check-input>
      </div>

      <div *ngIf="thresholdHeading != ''" class="d-flex flex-column filter-item flex-fill mb-3">
        <e2g-text-input
          id="inp-threshold"
          [placeholder]="thresholdHeading"
          [iconInput]="faClock"
          [(ngModel)]="filters.threshold"
          (input)="refreshContent()"
          type="number"
        >
        </e2g-text-input>
      </div>
    </div>
  </div>

  <div class="pl-3 pr-3 mb-3 h-100" [ngSwitch]="data.slideout">
    <app-critical-assets-slideout
      *ngSwitchCase="DashboardSlideoutType.CriticalAssets"
      [type]="data.slideout"
      [filters]="filters"
      [refreshData]="refreshData"
      (routeAway)="onRouteChange()"
    ></app-critical-assets-slideout>

    <app-remaining-life-slideout
      *ngSwitchCase="DashboardSlideoutType.RemainingLife"
      [type]="data.slideout"
      [filters]="filters"
      [refreshData]="refreshData"
      (routeAway)="onRouteChange()"
    ></app-remaining-life-slideout>

    <app-critical-assets-slideout
      *ngSwitchCase="DashboardSlideoutType.OverdueThickness"
      [type]="data.slideout"
      [filters]="filters"
      [refreshData]="refreshData"
      (routeAway)="onRouteChange()"
    ></app-critical-assets-slideout>

    <app-overdue-activities-slideout
      *ngSwitchCase="DashboardSlideoutType.OverdueActivities"
      [type]="data.slideout"
      [filters]="filters"
      [refreshData]="refreshData"
      (routeAway)="onRouteChange()"
    ></app-overdue-activities-slideout>

    <app-active-dmls-slideout
      *ngSwitchCase="DashboardSlideoutType.ActiveDmls"
      [type]="data.slideout"
      [filters]="filters"
      [refreshData]="refreshData"
      (routeAway)="onRouteChange()"
    ></app-active-dmls-slideout>

    <app-activities-to-accept-slideout
      *ngSwitchCase="DashboardSlideoutType.ActivitiesToAccept"
      [type]="data.slideout"
      [filters]="filters"
      [refreshData]="refreshData"
      (routeAway)="onRouteChange()"
    ></app-activities-to-accept-slideout>

    <app-critical-assets-slideout
      *ngSwitchCase="DashboardSlideoutType.UpcomingThickness"
      [type]="data.slideout"
      [filters]="filters"
      [refreshData]="refreshData"
      (routeAway)="onRouteChange()"
    ></app-critical-assets-slideout>

    <app-overdue-activities-slideout
      *ngSwitchCase="DashboardSlideoutType.UpcomingActivities"
      [type]="data.slideout"
      [filters]="filters"
      [refreshData]="refreshData"
      (routeAway)="onRouteChange()"
    ></app-overdue-activities-slideout>

    <app-completed-readings-slideout
      *ngSwitchCase="DashboardSlideoutType.CompletedReadings"
      [type]="data.slideout"
      [filters]="filters"
      [refreshData]="refreshData"
      (routeAway)="onRouteChange()"
    ></app-completed-readings-slideout>

    <app-completed-activities-slideout
      *ngSwitchCase="DashboardSlideoutType.CompletedActivities"
      [type]="data.slideout"
      [filters]="filters"
      [refreshData]="refreshData"
      (routeAway)="onRouteChange()"
    ></app-completed-activities-slideout>

    <app-dashboard-findings-slideout
      *ngSwitchCase="DashboardSlideoutType.Findings"
      [type]="data.slideout"
      [filters]="filters"
      [refreshData]="refreshData"
      (routeAway)="onRouteChange()"
    ></app-dashboard-findings-slideout>

    <app-risk-matrix-slideout
      *ngSwitchCase="DashboardSlideoutType.RiskMatrixRbiDate"
      [type]="data.slideout"
      [filters]="filters"
      [refreshData]="refreshData"
      [refreshGrid]="refreshGrid"
      [plantsWithUnits]="data.plantsWithUnits"
      [activityTypeList]="data.activityTypeList"
      (routeAway)="onRouteChange()"
    ></app-risk-matrix-slideout>

    <app-risk-matrix-slideout
      *ngSwitchCase="DashboardSlideoutType.RiskMatrixPlanDate"
      [type]="data.slideout"
      [filters]="filters"
      [refreshData]="refreshData"
      [refreshGrid]="refreshGrid"
      [plantsWithUnits]="data.plantsWithUnits"
      [activityTypeList]="data.activityTypeList"
      (routeAway)="onRouteChange()"
    ></app-risk-matrix-slideout>

    <app-risk-matrix-slideout
      *ngSwitchCase="DashboardSlideoutType.RiskMatrixWithInspection"
      [type]="data.slideout"
      [filters]="filters"
      [refreshData]="refreshData"
      [refreshGrid]="refreshGrid"
      [plantsWithUnits]="data.plantsWithUnits"
      [activityTypeList]="data.activityTypeList"
      (routeAway)="onRouteChange()"
    ></app-risk-matrix-slideout>
  </div>
</div>
