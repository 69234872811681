import { Component, Input, OnInit } from '@angular/core';
import { E2gSelectOption } from '@equityeng/e2g-ng-ui';
import { LabelPosition } from '@equityeng/e2g-ng-ui/lib/e2g-ui-component-config';
import { Observable, of } from 'rxjs';
import { shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';

import { BrBaseComponent } from '../br-base.component';

@Component({
  selector: 'app-br-select',
  templateUrl: './br-select.component.html'
})
export class BrSelectComponent extends BrBaseComponent implements OnInit {
  @Input() public labelPosition?: LabelPosition;
  @Input() public triggerChangeOnSingleValidValueSelect: boolean = false;
  public loading: boolean = false;
  public validValues$!: Observable<Array<E2gSelectOption>>;

  public ngOnInit(): void {
    super.ngOnInit();

    if (this.heading && !this.placeholder) {
      this.placeholder = `Select ${this.heading}`;
    }

    this.validValues$ = this.subjects.update$.pipe(
      tap(() => (this.loading = true)),
      switchMap(() => {
        if (this.hasBrValidValuesMethod()) {
          return this.getBrValues().getValidValues!();
        }
        return of([]);
      }),
      tap((values) => {
        if (
          this.businessRules.data[this.propName] !== undefined &&
          !values.map((x) => x.value).includes(this.businessRules.data[this.propName])
        ) {
          this.businessRules.data[this.propName] = undefined;
        } else if (values.length === 1) {
          this.businessRules.dataHandler.silentSetValue(this.propName, values[0].value, false);
          if (this.triggerChangeOnSingleValidValueSelect) {
            this.onChange();
          }
        }
        this.loading = false;
      }),
      shareReplay(1),
      takeUntil(this.destroy)
    );
  }

  public getLabelPosition(): LabelPosition {
    return this.labelPosition === undefined ? <LabelPosition>this.businessRules.labelPosition : this.labelPosition;
  }
}
