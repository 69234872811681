import { E2gSelectOption } from '@equityeng/e2g-ng-ui';

import { ActivityEffectiveness } from '../asset-module/models/enums/activity-effectiveness';

export const INSULATION_TYPE_VALUES: Array<E2gSelectOption> = [
  {
    value: 'NONE',
    label: 'None'
  },
  {
    value: 'ASBESTOS',
    label: 'Asbestos'
  },
  {
    value: 'CALCIUM SILICATE',
    label: 'Calcium Silicate'
  },
  {
    value: 'FIBERGLASS',
    label: 'Fiberglass'
  },
  {
    value: 'FOAMGLASS',
    label: 'Foamglass'
  },
  {
    value: 'MINERAL WOOL',
    label: 'Mineral Wool'
  },
  {
    value: 'PEARLITE',
    label: 'Perlite'
  },
  {
    value: 'UNKNOWN',
    label: 'Unknown'
  }
];

export const LINING_TYPE_VALUES: Array<E2gSelectOption> = [
  {
    value: 'NONE',
    label: 'None'
  },
  {
    value: 'STRIP ALLOY',
    label: 'Strip Alloy'
  },
  {
    value: 'REFRACTORY',
    label: 'Refractory'
  },
  {
    value: 'REFRACTORY-SEVERE',
    label: 'Refractory-Severe'
  },
  {
    value: 'GLASS',
    label: 'Glass'
  },
  {
    value: 'FIBERGLASS',
    label: 'Fiberglass'
  },
  {
    value: 'ACID BRICK',
    label: 'Acid Brick'
  },
  {
    value: 'ORGANIC_40',
    label: 'Organic Coating (<40 mils)'
  },
  {
    value: 'ORGANIC_80',
    label: 'Organic Coating (40-80 mils)'
  },
  {
    value: 'ORGANIC_GT80',
    label: 'Organic Coating (>80 mils)'
  }
];

export const EFFECTIVENESS_VALUES: Array<E2gSelectOption> = [
  { label: 'A', value: ActivityEffectiveness.A },
  { label: 'B', value: ActivityEffectiveness.B },
  { label: 'C', value: ActivityEffectiveness.C },
  { label: 'D', value: ActivityEffectiveness.D },
  { label: 'E', value: ActivityEffectiveness.E }
];

export const AVERAGE_VALUES: Array<E2gSelectOption> = [
  { label: 'Below Average', value: 'BELOW AVERAGE' },
  { label: 'Average', value: 'AVERAGE' },
  { label: 'Above Average', value: 'ABOVE AVERAGE' }
];

export const GFF_TYPES: Array<E2gSelectOption> = [
  { label: 'Drum', value: 'DRUM' },
  { label: 'Filter', value: 'FILTER' },
  { label: 'KO Drum', value: 'KODRUM' },
  { label: 'Reactor', value: 'REACTOR' },
  { label: 'Tubeside', value: 'HEXTS' },
  { label: 'Shellside', value: 'HEXSS' },
  { label: 'Column Top', value: 'COLTOP' },
  { label: 'Column Middle', value: 'COLMID' },
  { label: 'Column Bottom', value: 'COLBOT' },
];
