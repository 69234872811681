import { ValueGetterParams } from 'ag-grid-community';
import { MinimumThicknessChoice } from 'src/app/models/enums/minimum-thickness-choice';

import { headerBoxFormatter } from './header-box-formatter';

export const MawpApproachTmin = 'MAWP';

export function minimumThicknessWithMawpGetter(params: ValueGetterParams): string {
  const dataItem = params.data[params.colDef.field || ''] as number | string;
  if (params.data.useMawpApproach || params.data.governingMinimumThickness == MinimumThicknessChoice.MawpApproach) {
    return MawpApproachTmin;
  } else if (typeof dataItem === 'string' && dataItem.includes('x')) {
    return headerBoxFormatter(dataItem);
  }
  return dataItem !== undefined && dataItem !== 0 ? Number(dataItem).toFixed(3) : '';
}
