import { E2gSelectOption } from '@equityeng/e2g-ng-ui';

export type Enum = Record<number, string>;

export function enumToValueArray(e: any): Array<typeof e> {
  return Object.values(e)
    .filter((x) => typeof x !== 'string')
    .map((x) => x as typeof e);
}

export function enumToSelectOptions(e: Enum): Array<E2gSelectOption> {
  return Object.entries(e)
    .filter((e) => !isNaN(e[0] as any))
    .map((e) => ({ value: Number(e[0]), label: e[1] }))
    .sort((a, b) =>
      (a.label.toString() || '').localeCompare(b.label.toString(), 'en', { numeric: false, sensitivity: 'base' })
    );
}
