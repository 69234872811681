import { IRowNode } from 'ag-grid-community';
import { defaultWithNullComparer } from 'src/app/grid-module/column-data-comparers/default-with-null-comparator';

export function componentTypeComparator(
  valueA: number,
  valueB: number,
  nodeA: IRowNode,
  nodeB: IRowNode,
  isInverted: boolean
): number {
  function getTankCompOrderNumber(compType: string): number {
    switch (compType) {
      case 'ROOF':
        return 1;
      case 'COURSE':
        return 2;
      case 'FLOOR':
        return 3;
      case 'ANNULAR PLATE':
        return 4;
      default:
        return 0;
    }
  }

  function getCourseNumber(compName: string): number {
    return Number(compName.split('-').pop());
  }

  const a = getTankCompOrderNumber(nodeA?.data.type);
  const b = getTankCompOrderNumber(nodeB?.data.type);

  if (a !== 0 && b !== 0) {
    if (a == b) {
      return getCourseNumber(nodeA?.data.name) < getCourseNumber(nodeB?.data.name) ? 1 : -1;
    }
    return a > b ? 1 : -1;
  }

  return defaultWithNullComparer(valueA, valueB, isInverted, (a, b) => a.localeCompare(b));
}
