import { UnitOfMeasure } from '../models/enums/unit-of-measure';

export interface ModuleRouteData {
  moduleId: string;
  componentId?: string;
  assetId?: string;
  unitId?: string;
  unitOfMeasure?: UnitOfMeasure;
}

const delimeter = '_';
const keyValueSeparator = ':';

export function makeSageModuleRouteString(data: ModuleRouteData): string {
  let route = 'sage/module/';

  if (data.assetId) {
    if (data.componentId) {
      route = 'assets/expertview/';
    } else {
      route = 'assets/sage/module/';
    }
  }

  let key: keyof ModuleRouteData;
  for (key in data) {
    const value = data[key];

    if (value !== undefined) {
      route += key + keyValueSeparator + value + delimeter; //`${key}${keyValueSeparator}${value}${delimeter}`;
    }
  }

  return route;
}

export function parseSageModuleRouteString(data: string): ModuleRouteData {
  const routeData: any = { moduleId: '' };
  const pairs = data.split(delimeter);

  pairs
    .map((x) => x.split(keyValueSeparator))
    .forEach((x) => {
      routeData[x[0]] = x[1];
    });

  return routeData;
}
