<div class="card e2g-card">
  <div class="card-body e2g-card-body h-100 w-100">
    <ng-container *ngIf="data.canPreview; else noPreview">
      <div class="d-flex w-100 h-100 justify-content-center align-items-center" *ngIf="displayImage; else loading">
        <img
          [src]="displayImage"
          [alt]="data.fileName"
          [title]="data.fileName"
          class="previewImage"
          (click)="downloadOrPreview()"
        />
      </div>
    </ng-container>
  </div>
  <div class="card-footer e2g-card-footer align-items-center">
    <div class="d-flex justify-content-between align-items-middle">
      <div class="trunk-file fileName">{{ fileName }}</div>
      <div class="align-items-center">
        <fa-icon cdkDragHandle class="icon-secondary" [icon]="faGripDotsVertical" size="lg"></fa-icon>
        <fa-icon class="icon-secondary icon-padding" [icon]="faDownload" size="lg" (click)="download()"></fa-icon>
        <fa-icon
          *ngIf="!readonly"
          class="icon-secondary icon-padding"
          [icon]="faTrashXmark"
          size="lg"
          (click)="delete()"
        ></fa-icon>
      </div>
    </div>
  </div>
</div>
<ng-template #noPreview>
  <div class="d-flex w-100 h-100 justify-content-center align-items-center">
    <fa-icon [icon]="faFile" size="6x" class="noPreview" (click)="download()"></fa-icon>
  </div>
</ng-template>
<ng-template #loading>
  <div class="d-flex w-100 h-100 justify-content-center align-items-center">
    <div class="loading"></div>
  </div>
</ng-template>
