import { faCircleXmark } from '@fortawesome/pro-regular-svg-icons';

import { ToastDisplayProperties } from './toast';

export enum ToastType {
  //TODO: Add more types here
  // Success = 1,
  // Warning = 2,
  ActionFailed = 3,
  JobStarted = 4
}

export function getToastDisplayProperties(type: ToastType): ToastDisplayProperties {
  switch (type) {
    case ToastType.ActionFailed:
      return {
        color: 'var(--system-bg-primary-error)',
        showSpinner: false,
        icon: faCircleXmark
      };
    case ToastType.JobStarted:
      return {
        color: 'var(--system-bg-primary-info)',
        showSpinner: true
      };
  }
}
