<div class="h-100">
  <div *ngIf="dataHandler.initialized && !loading; else load" class="h-100 flex-col flex-111">
    <ul ngbNav #nav="ngbNav" [(activeId)]="activeTabId" class="nav-tabs header-table mt-0">
      <li [ngbNavItem]="1">
        <a ngbNavLink>Design</a>
        <ng-template ngbNavContent>
          <div class="h-100 flex-col">
            <div class="h-100 flex-111 overflow-auto mt-2 ml-4 pr-4">
              <div class="row mt-2 mb-2">
                <div id="status-col-1" class="col-sm-4">
                  <e2g-ng-select-input
                    id="select-status"
                    heading="Status"
                    placeholder="Select Status"
                    [options]="statusList"
                    [(ngModel)]="dataHandler.data.status"
                    [required]="false"
                    [readonly]="readOnly"
                  ></e2g-ng-select-input>
                </div>
                <div id="status-col-1" class="col-sm-4">&nbsp;</div>
              </div>

              <div class="row mt-2 mb-2">
                <div id="description-col-1" class="col-sm-4">
                  <e2g-textarea-input
                    id="inp-description"
                    heading="Description"
                    [rows]="2"
                    [(ngModel)]="dataHandler.data.description"
                    maxlength="255"
                    [readonly]="readOnly"
                  ></e2g-textarea-input>
                </div>
                <div id="description-col-1" class="col-sm-4">&nbsp;</div>
              </div>

              <div class="form-section-header">Design</div>
              <div class="row mt-2">
                <div id="design-col-1" class="col-sm-4">
                  <app-br-date propName="inServiceDate" heading="In Service Date"></app-br-date>
                  <div class="mt-2">
                    <app-br-select
                      propName="designCode"
                      heading="Design Code"
                      (change$)="codeTrigger.next()"
                    ></app-br-select>
                  </div>
                  <div class="mt-2" *ngIf="isFitting()">
                    <app-br-select
                      propName="fittingType"
                      heading="Fitting Type"
                      placeholder="Select Fitting Type"
                    ></app-br-select>
                  </div>
                  <div class="mt-2">
                    <app-br-text propName="designPressure" heading="Design Pressure" type="number"></app-br-text>
                  </div>
                  <div class="mt-2">
                    <app-br-text
                      propName="designTemperature"
                      heading="Design Temperature"
                      type="number"
                      [debounce]="true"
                    ></app-br-text>
                  </div>
                  <div class="mt-2">
                    <app-br-text propName="jointEfficiency" heading="Joint Efficiency" type="number"></app-br-text>
                  </div>
                </div>
                <div id="design-col-2" class="col-sm-4">
                  <div>
                    <app-br-select
                      propName="underTolerance"
                      heading="Undertolerance"
                      [otherTriggers]="[specTrigger]"
                    ></app-br-select>
                  </div>
                  <div class="mt-2">
                    <app-br-select propName="insulationType" heading="Insulated" placeholder="Select Insulation Type">
                    </app-br-select>
                  </div>
                  <div class="mt-2">
                    <div class="d-flex justify-content-between mt-2">
                      <e2g-input-heading heading="Group"></e2g-input-heading>
                      <a (click)="addNewCircuit()" class="modal-link" *ngIf="canAddCircuit && !readOnly">Add Group</a>
                    </div>
                    <e2g-ng-select-input
                      id="select-circuit"
                      placeholder="Select Group"
                      [disabled]="circuitList.length === 0"
                      [options]="circuitList || []"
                      [(ngModel)]="dataHandler.data.circuitId"
                      [required]="false"
                      [requiredForCalc]="dataHandler.data.rbi ?? false"
                      [warnings]="circuitWarnings"
                      (change)="createCircuitWarnings()"
                      [clearable]="true"
                      [readonly]="readOnly"
                    ></e2g-ng-select-input>
                  </div>
                  <div *ngIf="dataHandler.data.designCode == 'B31_8'">
                    <div class="mt-2">
                      <app-br-select
                        propName="facility"
                        heading="Facility"
                        (change$)="facilityTrigger.next()"
                      ></app-br-select>
                    </div>
                    <div class="mt-2">
                      <app-br-select
                        propName="locationClass"
                        heading="Location Class"
                        (change$)="locationTrigger.next()"
                      ></app-br-select>
                    </div>
                    <div class="mt-2">
                      <app-br-text
                        propName="siteFactor"
                        heading="Site Factor"
                        type="number"
                        [otherTriggers]="[facilityTrigger, locationTrigger]"
                      ></app-br-text>
                    </div>
                  </div>

                  <div class="mt-2" *ngIf="canUseAdvancedCalculaton()">
                    <app-br-check
                      propName="useAdvancedCalculation"
                      heading="Use Advanced Calculation"
                      labelPosition="right"
                    ></app-br-check>
                  </div>

                  <div class="mt-2" *ngIf="dataHandler.data.maximumAllowableWorkingPressure || mawpWarnings.length > 0">
                    <app-br-text
                      propName="maximumAllowableWorkingPressure"
                      heading="MAWP"
                      type="number"
                      [disabled]="true"
                      [warnings]="mawpWarnings"
                    ></app-br-text>
                  </div>
                </div>
              </div>
              <hr class="separator" />
              <div class="form-section-header">Geometry</div>
              <app-tube-geometry [dataHandler]="dataHandler" [readOnly]="readOnly" *ngIf="isTube()">
              </app-tube-geometry>

              <div *ngIf="!isTube()" class="row mt-2">
                <div id="geom-col-1" class="col-sm-4">
                  <div class="mb-2" *ngIf="!isFitting() && !isNozzle()">
                    <app-br-select
                      propName="geomType"
                      heading="Geometry Type"
                      (change$)="geomTrigger.next()"
                      [otherTriggers]="[codeTrigger]"
                    ></app-br-select>
                  </div>
                  <div *ngIf="!dataHandler.data.nonStandardTube">
                    <app-br-select
                      propName="nps"
                      [heading]="getGeometryLeftHeading('Nominal Pipe Size')"
                      (change$)="npsTrigger.next()"
                    ></app-br-select>

                    <div class="mt-2">
                      <app-br-select
                        propName="pipeSchedule"
                        heading="Pipe Schedule"
                        (change$)="pipeScheduleTrigger.next()"
                        [otherTriggers]="[npsTrigger]"
                      ></app-br-select>
                    </div>
                  </div>
                  <div class="mt-2">
                    <app-br-text
                      propName="outerDiameter"
                      [heading]="getGeometryLeftHeading('Outer Diameter')"
                      (change$)="odTrigger.next()"
                      [otherTriggers]="[npsTrigger, pipeScheduleTrigger, nstTrigger]"
                      [disabled]="!dataHandler.data.nonStandardTube"
                      [disableReset]="true"
                      type="number"
                    ></app-br-text>
                  </div>

                  <div class="mt-2">
                    <app-br-text
                      propName="nominalThickness"
                      [heading]="getGeometryLeftHeading('Nominal Thickness')"
                      (change$)="nominalThicknessTrigger.next()"
                      [otherTriggers]="[npsTrigger, pipeScheduleTrigger, nstTrigger]"
                      [disabled]="!(dataHandler.data.compType === 'NOZZLE') && !dataHandler.data.nonStandardTube"
                      [disableReset]="dataHandler.data.nonStandardTube!"
                      type="number"
                    ></app-br-text>
                  </div>

                  <div class="mt-2">
                    <app-br-text
                      propName="futureCorrosionAllowance"
                      heading="Corrosion Allowance"
                      type="number"
                    ></app-br-text>
                  </div>

                  <div class="mt-2">
                    <app-br-check
                      heading="Non Standard Tube"
                      propName="nonStandardTube"
                      (change$)="changeNsPipe()"
                      labelPosition="right"
                    >
                    </app-br-check>
                  </div>
                </div>
                <div id="geom-col-2" class="col-sm-4">
                  <div *ngIf="isReducer()">
                    <div *ngIf="!dataHandler.data.nonStandardTube">
                      <app-br-select
                        propName="nps2"
                        [heading]="getGeometryRightHeading('NPS')"
                        (change$)="npsTrigger.next()"
                      ></app-br-select>

                      <div class="mt-2" id="inp-ps2">
                        <app-br-select
                          propName="pipeSchedule"
                          heading="Pipe Schedule"
                          (change$)="pipeScheduleTrigger.next()"
                          [otherTriggers]="[geomTrigger]"
                        ></app-br-select>
                      </div>
                    </div>
                    <div class="mt-2">
                      <app-br-text
                        propName="outerDiameter2"
                        [heading]="getGeometryRightHeading('Outer Diameter')"
                        (change$)="odTrigger.next()"
                        [otherTriggers]="[pipeScheduleTrigger, nstTrigger]"
                        [disabled]="!dataHandler.data.nonStandardTube"
                        [disableReset]="true"
                        type="number"
                      ></app-br-text>
                    </div>

                    <div class="mt-2">
                      <app-br-text
                        propName="nominalThickness2"
                        [heading]="getGeometryRightHeading('Nominal Thickness')"
                        [otherTriggers]="[npsTrigger, pipeScheduleTrigger, nstTrigger]"
                        [disabled]="!dataHandler.data.nonStandardTube"
                        [disableReset]="true"
                        type="number"
                      ></app-br-text>
                    </div>

                    <div class="mt-2">
                      <app-br-text
                        propName="futureCorrosionAllowance"
                        heading="Corrosion Allowance"
                        type="number"
                      ></app-br-text>
                    </div>
                  </div>

                  <div *ngIf="!isFitting()">
                    <app-br-text propName="length" heading="Length" type="number"></app-br-text>
                    <div class="mt-2" *ngIf="dataHandler.data.geomType === 'PE' || dataHandler.data.geomType === 'MI'">
                      <app-br-text
                        propName="bendRadius"
                        heading="Bend Radius"
                        type="number"
                        [otherTriggers]="[npsTrigger]"
                      ></app-br-text>
                    </div>

                    <div class="mt-2" *ngIf="dataHandler.data.geomType === 'MI'">
                      <app-br-text propName="miterAngle" heading="Miter Angle" type="number"></app-br-text>
                    </div>
                  </div>
                </div>
              </div>

              <hr class="separator" />

              <div class="form-section-header">Material</div>
              <div class="row mt-2">
                <div class="h-100 flex-111">
                  <app-moc></app-moc>
                </div>
              </div>

              <hr class="separator" />

              <div class="d-flex flex-row mt-2 mb-2">
                <e2g-textarea-input
                  id="inp-comments"
                  class="w-100"
                  heading="Comments"
                  [rows]="5"
                  [readonly]="readOnly"
                  [maxlength]="512"
                  [(ngModel)]="dataHandler.data.comments"
                ></e2g-textarea-input>
              </div>
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="5" *ngIf="dataHandler.data.useAdvancedCalculation">
        <a ngbNavLink>Advanced</a>
        <ng-template ngbNavContent>
          <div class="h-100 flex-col">
            <div class="h-100 flex-111 overflow-auto mt-2 ml-4 pr-4">
              <div class="form-section-header">Parent Component</div>
              <div class="row my-2">
                <div id="nozzle-parent-col-1" class="col-sm-4">
                  <e2g-ng-select-input
                    id="select-componentAttachedTo"
                    heading="Nozzle Attached To"
                    placeholder="Select Component"
                    [readonly]="readOnly"
                    [options]="nozzleParentOptions"
                    [(ngModel)]="dataHandler.data.componentAttachedTo"
                    [requiredForCalc]="true"
                  ></e2g-ng-select-input>
                </div>
              </div>

              <hr class="separator" />
              <div class="form-section-header">Nozzle Minimum Thickness</div>
              <div class="row my-2">
                <div id="nozzle-tmin-col-1" class="col-sm-4">
                  <app-br-select
                    propName="nozzleNeckMinimumThicknessBasis"
                    heading="Nozzle Neck Minimum Thickness Basis"
                    (change$)="nozzTminBasisTrigger.next()"
                    [otherTriggers]="[codeTrigger]"
                  ></app-br-select>
                  <div class="mt-2">
                    <app-br-text
                      propName="nozzleNeckMinimumThickness"
                      heading="Nozzle Neck Minimum Thickness"
                      [otherTriggers]="[codeTrigger, odTrigger, nominalThicknessTrigger, nozzTminBasisTrigger]"
                    ></app-br-text>
                  </div>
                </div>
                <div id="nozzle-tmin-col-2" class="col-sm-4">
                  <app-br-select
                    propName="nozzleReinforcementMinimumThicknessBasis"
                    heading="Reinforcement Minimum Thickness Basis"
                    [otherTriggers]="[codeTrigger]"
                  ></app-br-select>
                </div>
              </div>
              <hr class="separator" />
              <div class="form-section-header">Nozzle Welds</div>
              <div class="row my-2">
                <div id="nozzle-tmin-col-1" class="col-sm-4">
                  <app-br-select propName="grooveWelds" heading="Groove Welds"></app-br-select>
                  <div class="mt-2">
                    <app-br-text
                      *ngIf="this.dataHandler.data.grooveWelds == 'PARTIAL'"
                      propName="nozzleGrooveWeld"
                      heading="Nozzle Groove Weld"
                      type="number"
                    ></app-br-text>
                  </div>
                </div>
                <div id="nozzle-tmin-col-2" class="col-sm-4">
                  <app-br-text propName="outsideFilletWeld" heading="Outside Fillet Weld" type="number"></app-br-text>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink>Minimum Thickness</a>
        <ng-template ngbNavContent>
          <div class="flex-col">
            <div class="h-100 flex-col overflow-auto mt-2">
              <app-governing-tmin
                [dataHandler]="dataHandler"
                [readOnly]="readOnly"
                (isValid)="setValid($event)"
                [assetConfig]="assetConfig"
              >
              </app-governing-tmin>
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="3" *ngIf="rbiTabVisible()">
        <a ngbNavLink>RBI</a>
        <ng-template ngbNavContent>
          <div class="h-100 flex-col">
            <div class="h-100 flex-111 overflow-auto mt-2 ml-4">
              <app-comp-rbi
                [compDataHandler]="dataHandler"
                [readOnly]="readOnly"
                [componentId]="dataHandler.data.id!"
                [(rbiActiveTabId)]="rbiActiveTabId"
                (rbiChange)="createCircuitWarnings()"
              ></app-comp-rbi>
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="4" *ngIf="diagVisible">
        <a ngbNavLink>Diagnostics</a>
        <ng-template ngbNavContent>
          <div class="h-100 flex-col">
            <div class="flex-111 overflow-auto mt-2 ml-4">
              <app-sage-diag
                [componentId]="compId!"
                [showShell]="isTube()"
                [showRbi]="rbiDiagVisible()"
              ></app-sage-diag>
            </div>
          </div>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="flex-col h-100"></div>
  </div>
</div>

<ng-template #load>
  <div class="loading"></div>
</ng-template>
