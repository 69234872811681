import { inject, Injectable } from '@angular/core';
import { forkJoin, map, Observable, of, take } from 'rxjs';
import { SurveyDto } from 'src/app/asset-module/models/survey-dto';
import { ActivityDataService } from 'src/app/asset-module/services/activity-data.service';
import { ActivityGenericDataService } from 'src/app/asset-module/services/activity-generic-data.service';
import { FindingDataService } from 'src/app/asset-module/services/finding-data.service';
import { InspectionDataService } from 'src/app/asset-module/services/inspection-data.service';
import { CompDataService } from 'src/app/comp-data.service';
import { EquipmentDataService } from 'src/app/equipment-data.service';
import { InspectorService } from 'src/app/inspector.service';
import { ActivityDto } from 'src/app/models/activity-dto';
import { InspectionGradesDto } from 'src/app/models/activity-effectiveness-dto';
import { ActivityTemplates } from 'src/app/models/enums/activity-templates';
import { AssetFeature } from 'src/app/shared-module/permission/permission-models';
import { PermissionService } from 'src/app/shared-module/permission/permission.service';
import { SurveyService } from 'src/app/survey.service';

import {
  ActivityDetailsAssetData,
  ActivityDetailsGlobalData,
  ActivityDetailsInstanceData,
} from './activity-details-dm-configs';

@Injectable({
  providedIn: 'root'
})
export class ActivityDetailsDataService {
  private permissionService = inject(PermissionService);
  private inspectorService = inject(InspectorService);
  private findingService = inject(FindingDataService);
  private componentService = inject(CompDataService);
  private equipService = inject(EquipmentDataService);
  private activityService = inject(ActivityDataService);
  private genericService = inject(ActivityGenericDataService);
  private inspectionService = inject(InspectionDataService);
  private surveyService = inject(SurveyService);

  public getGlobalData(): Observable<ActivityDetailsGlobalData> {
    return this.inspectorService.getInspectors().pipe(map((inspectors) => ({ inspectors })));
  }

  public getAssetData(assetId: string): Observable<ActivityDetailsAssetData> {
    return forkJoin([
      this.equipService.getSingleEquipment(assetId),
      this.componentService.getComponents(assetId),
      this.surveyService.getSurveys(assetId),
      this.permissionService.getAssetPermissionModel(AssetFeature.Activity, assetId).pipe(take(1))
    ]).pipe(
      map(([asset, components, surveys, assetPermission]) => ({
        asset,
        components,
        surveys,
        hasEditPermission: assetPermission.edit
      }))
    );
  }

  public getAssetSurveys(assetId: string): Observable<Array<SurveyDto>> {
    return this.surveyService.getSurveys(assetId);
  }

  public saveNewActivity(assetId: string, activityTemplate: ActivityTemplates, formData: any): Observable<string> {
    switch (activityTemplate!) {
      case ActivityTemplates.Generic:
        return this.genericService.addGenericActivity(assetId, formData);
      case ActivityTemplates.Inspection:
        return this.inspectionService.addInspection(assetId, formData);
      case ActivityTemplates.Survey:
        return this.surveyService.addSurvey(assetId, formData);
    }
  }

  public saveExistingActivity(activityTemplate: ActivityTemplates, formData: any): Observable<boolean> {
    switch (activityTemplate) {
      case ActivityTemplates.Generic:
        return this.genericService.updateGenericActivity(formData);
      case ActivityTemplates.Inspection:
        return this.inspectionService.updateInspection(formData);
      case ActivityTemplates.Survey: {
        return this.surveyService.updateSurvey(formData);
      }
    }
  }

  public getInitialGrades(assetId: string, activityTypeId: string): Observable<Array<InspectionGradesDto>> {
    return this.activityService.getInspectionActivation(assetId, activityTypeId, false);
  }

  public getExistingActivityData(
    id: string,
    assetId: string,
    activityTemplate: ActivityTemplates
  ): Observable<ActivityDetailsInstanceData> {
    return forkJoin([
      this.getExistingActivity(id, assetId, activityTemplate),
      this.findingService.getActivityFindings(id),
      activityTemplate === ActivityTemplates.Survey ? this.activityService.getSurveyReadings(id) : of(undefined)
    ]).pipe(map(([activity, findings, readings]) => ({ activity, findings, readings })));
  }

  private getExistingActivity(
    id: string,
    assetId: string,
    activityTemplate: ActivityTemplates
  ): Observable<ActivityDto> {
    switch (activityTemplate) {
      case ActivityTemplates.Generic:
        return this.genericService.getGenericActivity(id);
      case ActivityTemplates.Inspection:
        return this.inspectionService.getInspection(id);
      case ActivityTemplates.Survey:
        return this.surveyService.getSurvey(assetId, id);
    }
  }
}
