import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MaterialSelection } from '@equityeng/material-picker';

@Component({
  selector: 'app-moc-modal',
  templateUrl: './moc-modal.component.html'
})
export class MocModalComponent {
  public constructor(
    public dialogRef: MatDialogRef<MocModalComponent>,
    @Inject(MAT_DIALOG_DATA) public inputMaterial: MaterialSelection
  ) {}

  public materialSelected(outputMaterial: MaterialSelection): void {
    this.dialogRef.close(outputMaterial);
  }

  public cancel(): void {
    this.dialogRef.close();
  }
}
