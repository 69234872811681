import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import { DialogComponent } from './dialog/dialog.component';
import { ActionTypes } from './models/action-types';
import { DialogButtons } from './models/dialog-buttons';
import { DialogData } from './models/dialog-data';
import { DialogResult } from './models/dialog-result';
import { BaseNameDialogData, DialogType, NameDialogData } from './models/name-dialog-data';
import { NameDialogComponent } from './name-dialog/name-dialog.component';
import { NotificationService } from './services/notification.service';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  public constructor(private dialog: MatDialog, private notificationService: NotificationService) {}

  public display(dialogData: DialogData): Observable<DialogResult> {
    const dialogRef = this.dialog.open(DialogComponent, { data: dialogData, disableClose: true });
    dialogRef.updateSize(dialogData.width, dialogData.height);

    return dialogRef.beforeClosed().pipe(
      map((x) => {
        if (dialogData.beforeCloseCallback) {
          dialogData.beforeCloseCallback(x as DialogResult);
        }
        return x as DialogResult;
      })
    );
  }

  public displayDelete(data: BaseNameDialogData, message: string = 'Are you sure?'): Observable<DialogResult> {
    const dialogData: DialogData = {
      dialogId: 'dlg-delete',
      title: `Delete "${data.name}"`,
      message: message,
      yesButtonText: `Delete ${data.dialogType}`,
      buttons: DialogButtons.YesCancel
    };

    return this.display(dialogData).pipe(
      filter((x) => x === DialogResult.yes),
      tap(() => this.notificationService.showActionStarting(ActionTypes.Delete, data.name, data.id!))
    );
  }

  public displayRename(data: NameDialogData): Observable<string> {
    const title = `${ActionTypes[ActionTypes.Rename]} "${data.name}"`;
    return this.displayNameDialog(data, title, ActionTypes[ActionTypes.Rename]);
  }

  public displayAdd(data: NameDialogData): Observable<string> {
    const title = `New ${data.dialogType}`;
    return this.displayNameDialog(data, title, ActionTypes[ActionTypes.Add]);
  }

  public displayCopy(data: NameDialogData): Observable<string> {
    const originalName = data.name;
    const title = `${ActionTypes[ActionTypes.Copy]} "${originalName}"`;
    return this.displayNameDialog(data, title, ActionTypes[ActionTypes.Copy]).pipe(
      tap(() => this.notificationService.showActionStarting(ActionTypes.Copy, originalName, data.id!))
    );
  }

  private displayNameDialog(data: NameDialogData, title: string, action: string): Observable<string> {
    return this.display({
      dialogId: `dlg-${action}`,
      title: title,
      buttons: DialogButtons.YesCancel,
      component: NameDialogComponent,
      componentData: data,
      yesButtonText: `${action} ${data.dialogType}`,
      width: '530px'
    }).pipe(
      filter((result) => result === DialogResult.yes),
      map(() => data.name)
    );
  }

  public closeAll(): void {
    this.dialog.closeAll();
  }
  // This to be used when dialog has no validations (except empty string and optional allowSameName)
  public static GetDefaultDialogData(
    dialogType: DialogType,
    name: string = '',
    allowSameName: boolean = false
  ): NameDialogData {
    return {
      invalidNames: [],
      name,
      dialogType,
      allowSameName
    };
  }
}
