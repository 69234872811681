import { RiskMatrixDataDto } from '../dashboard-module/models/risk-matrix-data-dto';
import {
  RiskMatrixCofAxisCategory,
  RiskMatrixCofCategory,
  RiskMatrixLayoutDto,
  RiskMatrixPofAxisCategory
} from '../dashboard-module/models/risk-matrix-layout-dto';
import { PlotlyColors } from '../shared/constants/plotly-colors';
import { PlotlyCommonStyles } from '../shared/constants/plotly-common-styles';

export const createLayout = (layout: RiskMatrixLayoutDto): any => {
  const cof = layout.cofCategories.map((x) => x.cofCategory);
  const cofLabels = layout.cofCategories.map((x) => RiskMatrixCofCategory[x.cofCategory]);

  const pof = layout.pofCategories.map((x) => x.pofCategory);

  const cofBreakpoints = [];
  const cofBreakpointLabels = [];
  for (let i = 0; i < cof.length - 1; i++) {
    cofBreakpoints.push(layout.cofCategories[i].cofCategory + 0.5);
    cofBreakpointLabels.push(convertNumberToDisplay(layout.cofCategories[i].cofUpperThreshold));
  }

  const pofBreakpoints = [];
  const pofBreakpointLabels = [];
  for (let i = 0; i < pof.length - 1; i++) {
    pofBreakpoints.push(layout.pofCategories[i].pofCategory + 0.5);
    pofBreakpointLabels.push(convertNumberToDisplay(layout.pofCategories[i].pofUpperThreshold));
  }

  const cofAxis = {
    ...getBaseRiskMatrixAxisLayout(RiskMatrixCofAxisCategory[layout.cofAxisCategory]),
    ticktext: cofBreakpointLabels,
    tickvals: cofBreakpoints
  };

  const cofAxis2 = {
    ...getBaseRiskMatrixAxisLayout('COF Category'),
    side: layout.transposeAxis ? 'right' : 'top',
    ticktext: cofLabels,
    tickvals: cof
  };

  const pofAxis = {
    ...getBaseRiskMatrixAxisLayout(RiskMatrixPofAxisCategory[layout.pofAxisCategory]),
    ticktext: pofBreakpointLabels,
    tickvals: pofBreakpoints
  };

  const pofAxis2 = {
    ...getBaseRiskMatrixAxisLayout('POF Category'),
    side: layout.transposeAxis ? 'top' : 'right',
    ticktext: pof,
    tickvals: pof
  };

  return {
    autosize: true,
    margin: {
      t: 75,
      r: 75,
      b: 75,
      l: 75
    },
    width: Math.min(layout.transposeAxis ? pof.length * 50 + 150 : cof.length * 50 + 150, 700),
    height: Math.min(layout.transposeAxis ? cof.length * 50 + 150 : pof.length * 50 + 150, 700),
    xaxis: layout.transposeAxis ? pofAxis : cofAxis,
    xaxis2: layout.transposeAxis ? pofAxis2 : cofAxis2,
    yaxis: layout.transposeAxis ? cofAxis : pofAxis,
    yaxis2: layout.transposeAxis ? cofAxis2 : pofAxis2
  };
};

function getBaseRiskMatrixAxisLayout(title: string): any {
  return {
    title: {
      text: title,
      font: {
        family: 'Roboto',
        color: PlotlyColors.fgGrey,
        size: 14
      }
    },
    showgrid: false,
    zeroline: false,
    fixedrange: true,
    tickmode: 'array',
    ticks: '', //Hides tick marks
    ticklabelstandoff: 8,
    tickfont: PlotlyCommonStyles.tickfont
  };
}

const convertNumberToDisplay = (value: number): string => {
  value = makeNumberPercise(value);
  if (value >= 1000000000) {
    return `${value / 1000000000}B`;
  }
  if (value >= 1000000) {
    return `${value / 1000000}M`;
  }
  if (value >= 1000) {
    return `${value / 1000}k`;
  }
  if (value < 1) {
    return value.toExponential();
  }
  return value.toString();
};

const makeNumberPercise = (value: number): number => {
  value = +(+value).toPrecision(3);
  if (value === +value.toPrecision(1)) {
    return +value.toPrecision(1);
  }
  if (value === +value.toPrecision(2)) {
    return +value.toPrecision(2);
  }
  return value;
};

export const createGraphData = (
  layout: RiskMatrixLayoutDto,
  pofProp: keyof RiskMatrixDataDto,
  riskProp: keyof RiskMatrixDataDto,
  data: Array<RiskMatrixDataDto>,
  display: boolean = true
): [Array<any>, Array<{ id?: string; name: string; color: string; text: string }>] => {
  const cofCategories = layout.cofCategories.map((x) => x.cofCategory);
  const pofCategories = layout.pofCategories.map((x) => x.pofCategory);

  const colorscale: Array<any> = [];
  const colormap: Array<{ id: string; factor: number }> = [];

  const filteredList = layout.riskCategories.filter((x) =>
    layout.layoutMappings.some((y) => y.riskCategoryId === x.id)
  );

  filteredList.forEach((x, i) => {
    const factor = i / (filteredList.length - 1);
    colorscale.push([factor, x.riskColor]);
    colormap.push({ id: x.id, factor: factor });
  });

  let z: number[][] = [];
  let ztext: number[][] = [];
  let hovertext: string[][] = [];

  layout.pofCategories.forEach((pof) => {
    const factorRow: number[] = [];
    const hoverRow: string[] = [];
    const countRow: number[] = [];
    layout.cofCategories.forEach((cof) => {
      const riskId = layout.layoutMappings.find(
        (x) => x.cofCategoryId === cof.id && x.pofCategoryId === pof.id
      )!.riskCategoryId;
      const riskName = filteredList.find((x) => x.id === riskId)?.riskCategory;

      factorRow.push(colormap.find((x) => x.id === riskId)!.factor);
      hoverRow.push(`${RiskMatrixCofCategory[cof.cofCategory]}${pof.pofCategory} :${riskName}`);
      countRow.push(data.filter((x) => x.cofCategoryId === cof.id && x[pofProp] === pof.id).length);
    });

    z.push(factorRow);
    if (display) {
      ztext.push(countRow);
      hovertext.push(hoverRow);
    }
  });

  if (layout.transposeAxis) {
    z = transpose(z);
    ztext = transpose(ztext);
    hovertext = transpose(hovertext);
  }

  const riskCategoryData: Array<{ id?: string; name: string; color: string; text: string }> = [];

  layout.riskCategories.forEach((risk) => {
    const count = data.filter((x) => x[riskProp] === risk.id).length;
    const percent = (count / data.length) * 100;
    riskCategoryData.push({
      id: risk.id,
      name: risk.riskCategory,
      color: risk.riskColor,
      text: `${count} (${percent.toFixed(1)}%)`
    });
  });

  if (data.some((x) => x[riskProp] === undefined)) {
    const count = data.filter((x) => x[riskProp] === undefined).length;
    const percent = (count / data.length) * 100;
    riskCategoryData.push({
      name: 'Uncalculated',
      color: 'var(--interactive-fg-secondary-default)',
      text: `${count} (${percent.toFixed(1)}%)`
    });
  }

  const graphData = [
    {
      type: 'heatmap',
      x: layout.transposeAxis ? pofCategories : cofCategories,
      y: layout.transposeAxis ? cofCategories : pofCategories,
      z: z,
      xgap: 10,
      ygap: 10,
      showscale: false
    },
    {
      type: 'heatmap',
      x: layout.transposeAxis ? pofCategories : cofCategories,
      y: layout.transposeAxis ? cofCategories : pofCategories,
      z: z,
      texttemplate: '%{text}',
      text: ztext,
      hovertext: hovertext,
      hoverinfo: 'text',
      textfont: {
        family: 'Roboto',
        size: 14
      },
      colorscale: colorscale,
      xgap: 2.5,
      ygap: 2.5,
      showscale: false,
      xaxis: 'x2',
      yaxis: 'y2'
    }
  ];

  return [graphData, riskCategoryData];
};

const transpose = (matrix: any[][]): any[][] => {
  if (matrix.length === 0) {
    return matrix;
  }
  return matrix[0].map((_, i) => matrix.map((row) => row[i]));
};

export const getTextColor = (bgColor: string): string => {
  const color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor;
  const r = parseInt(color.substring(0, 2), 16); // hexToR
  const g = parseInt(color.substring(2, 4), 16); // hexToG
  const b = parseInt(color.substring(4, 6), 16); // hexToB
  return r * 0.299 + g * 0.587 + b * 0.114 > 186
    ? 'var(--interactive-fg-primary-default)'
    : 'var(--interactive-fg-primary-inverse)';
};
