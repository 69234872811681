import { Injectable } from '@angular/core';
import { of } from 'rxjs';

import { MinimumThicknessChoice } from '../models/enums/minimum-thickness-choice';
import { BusinessRulesServiceBase } from '../sage-common-module/BusinessRulesServiceBase';
import { BusinessRuleValueList } from '../sage-common-module/models/business-rule-value-list';
import { BusinessRulesDefinition } from '../sage-common-module/models/business-rules-definition';

@Injectable({
  providedIn: 'root'
})
export class TminBusinessRulesService extends BusinessRulesServiceBase implements BusinessRulesDefinition {
  public get valueData(): BusinessRuleValueList {
    if (this.data === undefined) {
      throw 'init must be called before valueData()';
    } else {
      return {
        specifiedMinimumThickness: {
          getDefaultValue: () => of(this.data?.eqSpecifiedMinimumThickness),
          getRequired: () => this.data.governingMinimumThickness ===  MinimumThicknessChoice.Specified
        }
      };
    }
  }
}
