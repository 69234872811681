import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ColDef } from 'ag-grid-community';
import { combineLatest, filter, forkJoin, map, Observable, of, shareReplay, startWith, switchMap, take } from 'rxjs';

import { GridBaseOptions } from '../grid-module/e2g-ag-grid/e2g-ag-grid.component';
import { UnitOfMeasure } from '../models/enums/unit-of-measure';
import { UnitsOfMeasureDataService } from './units-of-measure-data.service';
import { UnitOfMeasureFormatter } from './units-of-measure-formatter';

@Injectable({
  providedIn: 'root'
})
export class UnitsOfMeasureEvaluator {
  private unitsData: Observable<{ uomData: Record<string, string[]>; uomType: string }>;

  public constructor(
    private router: Router,
    private route: ActivatedRoute, //TODO Replace with RouteParamsService?
    private uomDataService: UnitsOfMeasureDataService
  ) {
    const navigationEndEvent = this.router.events.pipe(
      filter((e) => e instanceof NavigationEnd),
      startWith(undefined)
    );

    this.unitsData = combineLatest({
      uomData: this.uomDataService.getUnitsOfMeasure().pipe(map((unitsData) => this.convertToLowerMap(unitsData))),
      uomType: navigationEndEvent.pipe(
        switchMap(() => this.getLastChild(this.route)?.params || of([])),
        map((params) => (params as any)['unitOfMeasure'])
      )
    }).pipe(shareReplay(1));
  }

  public getUnits(propName: string): Observable<string> {
    return this.unitsData.pipe(
      switchMap((data) => {
        if (data.uomType) {
          return this.getUnitsWithUnitSystem(propName, UnitOfMeasure[data.uomType as keyof typeof UnitOfMeasure]); // `(${units})`;
        }
        return of('');
      })
    );
  }

  public getUnitsWithUnitSystem(propName: string, unitSystem: UnitOfMeasure): Observable<string> {
    const lcPropName = propName?.toLowerCase();

    return this.unitsData.pipe(
      map((data) => {
        if (lcPropName in data.uomData) {
          const units = data.uomData[lcPropName][unitSystem];

          return `(${units})`;
        }

        return '';
      })
    );
  }

  public setGridUnits(options: GridBaseOptions): Observable<GridBaseOptions> {
    if (options.columnDefs) {
      return forkJoin(
        options.columnDefs
          .filter((col) => ('field' in col && col.field) || ('headerName' in col && col.headerName == 'Actions'))
          .map((col: ColDef) =>
            this.getUnits(col.field!).pipe(
              take(1),
              map((units) => {
                col.headerName = col.headerName?.toUpperCase();
                if (units) {
                  if (!col.headerName?.toLowerCase().includes(units.toLowerCase())) {
                    col.headerName = UnitOfMeasureFormatter.addToLabel(col.headerName!, units);
                    if (col.headerTooltip !== undefined) {
                      col.headerTooltip += UnitOfMeasureFormatter.addToLabel(col.headerTooltip, units);
                    }
                  }
                }
              })
            )
          )
      ).pipe(map(() => options));
    }
    return of(options);
  }

  public getCurrentUnitSystem(): Observable<UnitOfMeasure> {
    return this.unitsData.pipe(map((data) => UnitOfMeasure[data.uomType as keyof typeof UnitOfMeasure]));
  }

  private getLastChild(route: ActivatedRoute): ActivatedRoute | undefined {
    let currentRoute = route;
    while (currentRoute.firstChild) {
      currentRoute = currentRoute.firstChild;
    }
    return currentRoute;
  }

  private convertToLowerMap(data: Record<string, string[]>): Record<string, string[]> {
    return Object.entries(data).reduce((p, c) => {
      (p as any)[c[0].toLocaleLowerCase()] = c[1];
      return p;
    }, {});
  }
}
