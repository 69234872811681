import { Component, OnInit } from '@angular/core';
import { dateOnly, DateOnlyUtility, E2gSelectOption } from '@equityeng/e2g-ng-ui';
import { Observable, of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OnDestroyBaseComponent } from 'src/app/on-destroy-base-component/on-destroy-base-component';
import { SingleDataHandler } from 'src/app/shared-module/single-data-handler';

import { IDialogFormData } from '../../../shared-module/dialog/dialog-form-data';
import { DialogData } from '../../../shared-module/models/dialog-data';
import { AuditLogReportData } from '../../models/log-report-data';
import { ReportingDataService } from '../../reporting.data.service';

@Component({
  selector: 'app-audit-log-report-dialog',
  templateUrl: './audit-log-report-dialog.component.html',
  providers: [
    {
      provide: IDialogFormData,
      useExisting: AuditLogReportDialogComponent
    }
  ]
})
export class AuditLogReportDialogComponent extends OnDestroyBaseComponent implements OnInit, IDialogFormData {
  public dataHandler: SingleDataHandler<AuditLogReportData>;

  public startDateErrors = [] as string[];
  public endDateErrors = [] as string[];

  public userData: Array<E2gSelectOption> = [];

  private dialogData!: DialogData;
  public formData!: AuditLogReportData;
  public startDate?: dateOnly;
  public endDate?: dateOnly;

  public loading: boolean = true;

  public constructor(private readonly logReportService: ReportingDataService) {
    super();

    this.dataHandler = new SingleDataHandler<AuditLogReportData>(this.destroy);
  }

  public ngOnInit(): void {
    this.dialogData.validityCallback = this.validateForm;
    this.dialogData.beforeCloseCallback = this.beforeClose;
    this.dataHandler.setInitialData(this.formData);
    this.setupValidation();
    this.logReportService
      .getAuditUsers()
      .pipe(takeUntil(this.destroy))
      .subscribe((users) => {
        this.userData = users.map((x) => ({
          label: x.userEmail,
          value: x.userEmail
        }));

        this.loading = false;
      });
  }

  public setDialogData(dialogData: DialogData): void {
    this.dialogData = dialogData;
  }

  public setFormData(formData: AuditLogReportData): void {
    this.formData = formData;
  }

  private setupValidation(): void {
    this.dataHandler.setValidation({
      startDate: () => this.validateStartDate(),
      endDate: () => this.validateEndDate()
    });
  }

  public validateStartDate(): Observable<string | undefined> {
    this.startDateErrors = [];

    if (!this.startDate || !DateOnlyUtility.isValid(this.startDate)) {
      this.startDateErrors.push('Valid date required');
    } else if (DateOnlyUtility.isInFuture(this.startDate!)) {
      this.startDateErrors.push('Cannot define future start date.');
    }

    return of(this.startDateErrors.length > 0 ? 'ERROR' : undefined);
  }

  public validateEndDate(): Observable<string | undefined> {
    this.endDateErrors = [];

    if (!this.dataHandler.data.endDate || !DateOnlyUtility.isValid(this.endDate)) {
      this.endDateErrors.push('Valid date required');
    } else if (DateOnlyUtility.compare(this.startDate!, this.endDate!) > 0) {
      this.endDateErrors.push('Cannot define end date less than the start date.');
    }

    return of(this.endDateErrors.length > 0 ? 'ERROR' : undefined);
  }

  private validateForm = (): boolean =>
    this.startDateErrors.length === 0 &&
    this.startDate !== undefined &&
    this.endDateErrors.length === 0 &&
    this.endDate !== undefined;

  private beforeClose = (): void => {
    this.formData.startDate = DateOnlyUtility.convertDateOnlyToDate(this.startDate!);
    this.formData.endDate = DateOnlyUtility.convertDateOnlyToDate(this.endDate!);
    this.formData.endDate.setHours(23, 59, 59, 999); //End Of day
  };
}
