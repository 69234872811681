import { Component } from '@angular/core';

import { SignalServiceScra } from './signal-service-scra';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  public constructor(private signalServiceScra: SignalServiceScra) {
    this.signalServiceScra.initiateSignalrConnection();
  }
}
