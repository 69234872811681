import { Inject, Injectable, TemplateRef } from '@angular/core';
import { AuthService, User } from '@equityeng/auth';
import {
  CalculatorConfiguration,
  ExtraTab,
  InputFile,
  ModuleCalculationResult,
  SageNodeData,
  UnitSystem,
  ISageEnvironment,
  SAGE_ENV_SETTINGS
} from '@equityeng/sage-forms-core';
import { sageCall, sageFunctionCallResult } from '@equityeng/sage-forms-core/functional-defaults';
import { SageFormService } from '@equityeng/sage-forms-core/sage-form';
import {
  BehaviorSubject,
  combineLatest,
  concatMap,
  filter,
  forkJoin,
  map,
  Observable,
  of,
  ReplaySubject,
  Subject,
  switchMap,
  take,
  tap,
} from 'rxjs';

import { BreadcrumbsService } from './breadcrumb-module/breadcrumbs.service';
import { Breadcrumb } from './breadcrumb-module/breadcrumbs/breadcrumbs.component';
import { CompanyService } from './company.service';
import { DataService } from './dataservice';
import { SAGE } from './models/auth-constants';
import { CalculationMessageStatus } from './models/enums/calculation-message-status';
import { CalculationOutputStatus } from './models/enums/calculation-output-status';
import { CalculationTypes } from './models/enums/calculation-types';
import { FeedbackDto } from './models/feedback-dto';
import { RefreshService } from './notifications-module/refresh.service';
import { ReportService } from './report.service';
import { KeywordHelpDlgComponent } from './sage-wrapper-module/keyword-help-dlg/keyword-help-dlg.component';
import { DialogService } from './shared-module/dialog.service';
import { DialogButtons } from './shared-module/models/dialog-buttons';
import { DialogData } from './shared-module/models/dialog-data';
import { createNotifyHandler } from './shared-module/notify-property-changed-deep';
import { ModuleRouteData, parseSageModuleRouteString } from './utilities/sage-module-route-helper';

//TODO Move these interfaces into separate files
export interface SageChildInfo {
  name: string;
  validChildren: Array<string>;
}

export interface CalcNotificationData {
  calcId: number;
  calcType: CalculationTypes;
}

export interface SageCalcNotificationData extends CalcNotificationData {
  calcId: number;
  calcType: CalculationTypes;
  moduleId: string;
}

//TODO Consolidate with ModuleFlatNode?
export interface ModuleNode {
  root: boolean;
  level: number;
  index: number[];
  name: string;
  moduleType: string;
  children: Array<ModuleNode>;
}

export interface SageSavePacket {
  input?: InputFile;
  configuration?: CalculatorConfiguration;
}

interface CachedModule {
  index: number[];
  name: string;
  moduleType: string;
}

export interface KeywordDescriptionDto {
  name: string;
  description: string;
}

export interface SageNodeDataWithVersionCreatedAs extends SageNodeData {
  versionCreatedAs: string;
}

@Injectable()
export class SageBridgeService extends SageFormService {
  public calculationRequested: Observable<void> = new Subject<void>();
  public sageCalcNotification: Observable<SageCalcNotificationData> = new ReplaySubject<SageCalcNotificationData>(1);
  public saveRequested: Observable<void> = new Subject<void>();
  public loadedNodeDesc: string = '';
  private ids: string = '';
  private moduleId: string = '';

  private nodeData: SageNodeData | undefined;
  private versionForServiceCalls: string = '';
  public moduleHierarchy: ModuleNode | undefined;
  public currentNodeHasErrors: boolean = false;
  public currentModuleErrors: string = '';
  public currentNodeHasWarnings: boolean = false;
  public currentModuleWarnings: string = '';
  public breadcrumbs: Array<Breadcrumb> = [];
  public moduleExampleCases: Array<string> = [];
  public dirty: boolean = false;

  private initialIndices: CachedModule[] = [];
  private currentIndices: CachedModule[] = [];
  private indicesCached: boolean = false;

  private sageChildIndex: SageChildInfo[] = [];
  private inputUpdated: boolean = false;
  private configUpdated: boolean = false;

  private extraTabs: ExtraTab[] = [];

  private refreshCalculation = new Subject<void>();
  private user?: User;

  public constructor(
    @Inject(SAGE_ENV_SETTINGS) private sageEnvironment: ISageEnvironment,
    private dataService: DataService,
    private refreshService: RefreshService,
    private reportService: ReportService,
    private dialogService: DialogService,
    private companyService: CompanyService,
    private authService: AuthService,
    private breadcrumbsService: BreadcrumbsService
  ) {
    super();

    this.authService.userAuthenticated$.pipe(take(1)).subscribe((user) => {
      this.user = user;
    });

    this.refreshService.updates
      .pipe(
        filter(
          (impactedIds) =>
            this.isForComponent() && impactedIds.componentIds!.includes(this.currentRouteData().componentId ?? '')
        ),
        map(() => undefined)
      )
      .subscribe(() => this.refreshCalculation.next());

    this.sageCalcNotification
      .pipe(
        switchMap((x) =>
          this.refreshService.calcUpdates.pipe(
            filter((update) => update.status === CalculationMessageStatus.Complete),
            map((updates) => ({ calcInfo: x, completedId: updates.messageId })),
            filter((data) => data.calcInfo.calcId === data.completedId && data.calcInfo.moduleId == this.moduleId),
            map(() => undefined)
          )
        )
      )
      .subscribe(() => this.refreshCalculation.next());

    this.refreshCalculation.pipe(switchMap(() => this.dataService.getOutput(this.moduleId))).subscribe((x) => {
      this.nodeData!.output = x;
      this.setCalculationResult(x);
      this.setMessages();
      this.isOutputStale = false;
      this.updateSageReportOptions();
    });
  }

  public override hasFeature(featureName: string): boolean {
    return this.user?.hasAccessToFeature(SAGE, `${SAGE}_${featureName}`) || false;
  }

  public override hasFeatureFlag(featureName: string): boolean {
    return this.sageEnvironment.featureSet.includes(featureName);
  }

  public treeLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private currentIndex: number[] = [];
  public currentRouteData(): ModuleRouteData {
    return parseSageModuleRouteString(this.ids);
  }

  public currentModule(): string {
    if (this.nodeData === undefined || this.nodeData.input === undefined) {
      return '';
    }

    if (this.currentIndex.length == 0) {
      return this.nodeData.input.module === undefined ? '' : this.nodeData.input.module.toLowerCase();
    }

    let parent = this.nodeData.input;

    this.currentIndex.forEach((i) => {
      parent = parent.children[i];
    });

    return parent.module.toLowerCase();
  }

  public topLevelModule(): string {
    if (this.nodeData === undefined || this.nodeData.input === undefined) {
      return '';
    }
    return this.nodeData.input.module === undefined ? '' : this.nodeData.input.module.toLowerCase();
  }

  public clearCache(): void {
    this.formLoaded = false;
    this.nodeData = undefined;
    this.ids = '';
    this.moduleId = '';
    this.moduleHierarchy = undefined;
    this.clearTreeCache();
    this.clearMessages();
    this.clearExtraTabs();
    this.treeLoaded.next(false);
    this.resetDirty();
  }

  private clearTreeCache(): void {
    this.indicesCached = false;
    this.initialIndices = [];
    this.currentIndices = [];
  }

  private clearMessages(): void {
    this.currentNodeHasErrors = false;
    this.currentModuleErrors = '';
    this.currentNodeHasWarnings = false;
    this.currentModuleWarnings = '';
  }

  private clearExtraTabs(): void {
    this.extraTabs = [];
  }

  private hasOutput(): boolean {
    return this.nodeData?.output?.success ?? false;
  }

  public override getData(id: string, index: number[]): Observable<SageNodeData> {
    this.currentIndex = index;
    this.updateSageReportOptions();

    if (id == this.ids && this.nodeData) {
      return of(this.nodeData);
    }
    this.clearCache();
    this.ids = id;
    this.moduleId = parseSageModuleRouteString(id).moduleId;
    return this.companyService.selectedCompany.pipe(
      switchMap(() => this.authService.userAuthenticated$.pipe(take(1))),
      switchMap(() =>
        combineLatest([this.dataService.getData(this.moduleId), this.getBreadcrumbs(this.ids), this.getModuleStatus()])
      ),
      concatMap(([nodeData, breadcrumbs, status]) =>
        forkJoin([this.getSageModuleIndex(), this.dataService.getModuleExampleCases(nodeData.input.module)]).pipe(
          map(([sageModules, exampleCases]) => ({
            nodeData: nodeData,
            sageModules: sageModules,
            breadcrumbs: breadcrumbs,
            exampleCases: exampleCases,
            status: status
          }))
        )
      ),
      map((x) => {
        this.assignNodeData(x.nodeData);
        this.versionForServiceCalls = x.nodeData.version; //x.nodeData.versionCreatedAs;
        this.sageChildIndex = x.sageModules;
        this.breadcrumbs = x.breadcrumbs;
        this.moduleExampleCases = x.exampleCases;
        this.isOutputStale = x.status === CalculationOutputStatus.Stale;
        this.setMessages();
        this.setTree();
        this.treeLoaded.next(true);
        this.updateSageReportOptions();
        return x.nodeData;
      }, take(1))
    );
  }

  public isForAsset(): boolean {
    return parseSageModuleRouteString(this.ids).assetId !== undefined;
  }

  public isForComponent(): boolean {
    return parseSageModuleRouteString(this.ids).componentId !== undefined;
  }

  private getBreadcrumbs(ids: string): Observable<Breadcrumb[]> {
    if (this.isForAsset()) {
      const assetId = parseSageModuleRouteString(ids).assetId!;
      if (this.isForComponent()) {
        //Expert View (Asset -> Component)
        return this.breadcrumbsService.getAssetBreadcrumbs(assetId);
      }

      //Asset Sage Tree
      return forkJoin([
        this.breadcrumbsService.getAssetBreadcrumbs(assetId),
        this.breadcrumbsService.getSageModuleBreadcrumbs(this.moduleId)
      ]).pipe(map(([assetBcs, moduleBcs]) => assetBcs.concat(moduleBcs)));
    }

    //User Sage Tree
    return this.breadcrumbsService.getSageModuleBreadcrumbs(this.moduleId);
  }

  private getModuleStatus(): Observable<string> {
    return this.isForComponent()
      ? of(CalculationOutputStatus.Calculated)
      : this.dataService.getModuleStatus(this.moduleId);
  }

  private assignNodeData(nodeData: SageNodeData): void {
    this.nodeData = nodeData;

    createNotifyHandler(this.nodeData, (propName: string) => {
      if (this.formLoaded) {
        const topLevelProperty = propName.split('.')[0];
        if (topLevelProperty === 'input') {
          this.setDirty();
          this.inputUpdated = true;
          if (this.hasOutput()) {
            this.isOutputStale = true;
            this.updateSageReportOptions();
          }
        } else if (topLevelProperty === 'config') {
          this.setDirty();
          this.configUpdated = true;
        }
      }
    });
  }

  private setDirty(): void {
    this.dirty = true;
    this.companyService.setDisabled(this.dirty);
  }

  public override requestKeywordDescription(keyword: string, heading: string, version: string): void {
    const module = this.currentModule();
    if (module === '') {
      return;
    }

    const dialogData: DialogData = {
      title: `${heading} [${keyword.toUpperCase()}]`,
      buttons: DialogButtons.Yes,
      component: KeywordHelpDlgComponent,
      componentData: {
        module,
        keyword,
        version: this.versionForServiceCalls
      },
      yesButtonText: 'OK',
      width: '530px'
    };

    this.dialogService.display(dialogData).pipe(take(1)).subscribe();
  }

  private setMessages(): void {
    this.clearMessages();
    if (this.nodeData === undefined || this.nodeData.output === undefined || this.nodeData.output.runTime == 0) {
      return;
    }

    if (
      !this.nodeData.output.success &&
      this.nodeData.output.statusMessage !== undefined &&
      this.nodeData.output.statusMessage != ''
    ) {
      this.currentModuleErrors += this.nodeData.output.statusMessage.replace('null', '');
    }

    if (
      this.nodeData.output.warningMessage &&
      this.nodeData.output.warningMessage !== undefined &&
      this.nodeData.output.warningMessage != ''
    ) {
      this.currentModuleWarnings += this.nodeData.output.warningMessage.replace('null', '');
    }

    this.checkResult(this.nodeData.output);
    if (this.currentModuleErrors != '') {
      this.currentNodeHasErrors = true;
    }
    if (this.currentModuleWarnings != '') {
      this.currentNodeHasWarnings = true;
    }
  }

  private checkResult(data: ModuleCalculationResult): void {
    data.children.forEach((x) => {
      if (!x.success && x.statusMessage != undefined && x.statusMessage != '') {
        this.currentModuleErrors += x.statusMessage.replace('null', '');
      }
      if (x.warningMessage && x.warningMessage != undefined && x.warningMessage != '') {
        this.currentModuleWarnings += x.warningMessage.replace('null', '');
      }

      this.checkResult(x);
    });
  }

  private setTree(): void {
    this.currentIndices = [];
    if (this.nodeData === undefined || this.nodeData.input === undefined) {
      return;
    }

    this.moduleHierarchy = {
      root: true,
      level: 0,
      name: this.nodeData.input.name,
      index: [],
      moduleType: this.nodeData.input.module,
      children: []
    };

    this.setChildren(this.nodeData.input, this.moduleHierarchy, [], 0);
    this.indicesCached = true;
  }

  private setChildren(module: InputFile, treeNode: ModuleNode, path: number[], depth: number): void {
    let i = 0;
    module.children.forEach((x) => {
      path[depth] = i;
      if (path.length > depth + 1) {
        path = path.slice(0, depth + 1);
      }
      const newTreeNode = {
        root: false,
        level: depth,
        name: x.name!,
        index: [...path],
        moduleType: x.module!,
        children: []
      };

      const cachedModule = {
        name: x.name!,
        index: [...path],
        moduleType: x.module!
      };

      if (!this.indicesCached) {
        this.initialIndices.push(cachedModule);
      }
      this.currentIndices.push(cachedModule);

      i++;
      treeNode.children.push(newTreeNode);
      if (x.children && x.children.length > 0) {
        this.setChildren(x, newTreeNode, path, depth + 1);
      }
    });
  }

  public addChild(moduleType: string, name: string, index: number[]): number {
    if (this.nodeData === undefined || this.nodeData.input === undefined) {
      return 0;
    }

    let parent = this.nodeData.input;
    index.forEach((i) => {
      parent = parent.children[i];
    });

    const newInput = {
      name: name,
      module: moduleType.toLocaleUpperCase(),
      inputUnits: parent.inputUnits,
      outputUnits: parent.outputUnits,
      comment: [],
      data: {},
      inputBase: {},
      children: [],
      parametric: []
    };

    const newOutput = {
      runTime: 0,
      success: false,
      module: moduleType.toLocaleUpperCase(),
      name: name,
      statusMessage: '',
      warningMessage: '',
      outputData: {},
      children: [],
      plots: {
        linePlots: [],
        surfacePlots: []
      }
    };

    let input = this.nodeData?.input;
    let output = this.nodeData?.output;
    let config = this.nodeData?.config;
    index.forEach((i) => {
      input = input!.children[i];
      output = output!.children[i];
      config = config!.children[i];
    });

    input?.children.push(newInput);
    output?.children.push(newOutput);
    if (config.children === undefined) {
      config.children = [];
    }
    config.children.push({ children: [] });
    this.setTree();
    this.treeLoaded.next(true);

    return input?.children.length - 1; //Index of new child
  }

  public renameNode(newName: string, index: number[]): void {
    let input = this.nodeData?.input;
    const last = index.pop();

    index.forEach((i) => {
      input = input!.children[i];
    });

    input!.children[last!].name = newName;

    this.setTree();
    this.treeLoaded.next(true);
  }

  public updateModuleName(newName: string): void {
    this.currentData!.input!.name = newName;
  }

  public getSageModuleIndex(): Observable<SageChildInfo[]> {
    const sageChildIndex: SageChildInfo[] = [];
    return this.dataService.getSageModuleList().pipe(
      map((x) => {
        x.forEach((m) => {
          if (m.validChildren && m.validChildren.length > 0) {
            sageChildIndex.push({
              name: m.name,
              validChildren: m.validChildren
            });
          }
        });
      }),
      map(() => sageChildIndex)
    );
  }

  public getSageModuleList(moduleType: string): Array<string> {
    moduleType = moduleType.toUpperCase();
    const children = this.sageChildIndex.find((y) => y.name == moduleType)?.validChildren;
    if (children) {
      return children!;
    } else {
      return [];
    }
  }

  public deleteNode(index: number[]): void {
    let input = this.nodeData?.input;
    let config = this.nodeData?.config;
    const last = index.pop();
    index.forEach((i) => {
      input = input!.children[i];
      config = config!.children[i];
    });
    input!.children.splice(last!, 1);
    config!.children.splice(last!, 1);

    this.setTree();
    this.treeLoaded.next(true);
  }

  public isOnChild(index: number[]): boolean {
    return this.currentIndex.join(',') == index.join(',');
  }

  public isSibling(index: number[]): boolean {
    return (
      this.currentIndex.length == index.length &&
      this.currentIndex.slice(0, -1).join(',') == index.slice(0, -1).join(',')
    );
  }

  public getIndexAfterDeletingChild(index: number[]): number[] | undefined {
    if (this.isOnChild(index)) {
      this.currentIndex = index.slice(0, -1);
      return this.currentIndex;
    } else if (this.isSibling(index) && this.currentIndex[this.currentIndex.length - 1] > index[index.length - 1]) {
      this.currentIndex[this.currentIndex.length - 1] = this.currentIndex[this.currentIndex.length - 1] - 1;
      return this.currentIndex;
    }
    return undefined;
  }

  public override getDefaults(
    module: string,
    version: string,
    defaults: sageCall[],
    validValues: sageCall[],
    unitSystem: UnitSystem
  ): Observable<sageFunctionCallResult> {
    return this.dataService.getDefaults(module, this.versionForServiceCalls, defaults, validValues, unitSystem);
  }

  public override setLoadedNodeDesc(nodeDesc: string): void {
    this.loadedNodeDesc = nodeDesc;
  }

  public triggerCalculate(): Observable<boolean> {
    return this.dataService.calculate(this.moduleId, this.versionForServiceCalls).pipe(
      map((jobId) => {
        const success = jobId > 0;
        if (success) {
          this.notifySageCalcId(jobId, CalculationTypes.SageModule);
        }
        return success;
      })
    );
  }

  public useExampleCase(exampleCase: string, moduleName: string): Observable<boolean> {
    return this.dataService.useExampleCase(this.moduleId, exampleCase, moduleName).pipe(
      switchMap((success) => {
        if (success) {
          return this.dataService.getData(this.moduleId);
        }
        return of(undefined);
      }),
      tap((data) => {
        if (data) {
          this.assignNodeData(data);
          this.currentData = data;
          this.setMessages();
          this.clearTreeCache();
          this.setTree();
          this.currentIndex = [];
        }
      }),
      map((x) => x !== undefined)
    );
  }

  public reloadData(): void {
    this.treeLoaded.next(true);
    if (this.nodeData!.output) {
      this.setCalculationResult(this.nodeData!.output);
    }
  }

  private notifySageCalcId(calcId: number, calcType: CalculationTypes): void {
    (this.sageCalcNotification as ReplaySubject<SageCalcNotificationData>).next({
      calcId,
      calcType,
      moduleId: this.moduleId
    });
  }

  public triggerSave(): Observable<void> {
    const sageSavePacket: SageSavePacket = {
      input: this.inputUpdated ? this.currentData!.input : undefined,
      configuration: this.configUpdated ? this.currentData!.config : undefined
    };

    const routeData = parseSageModuleRouteString(this.ids);
    return (
      this.isForComponent()
        ? this.dataService.saveComponentModule(this.moduleId, routeData.componentId!, sageSavePacket)
        : this.dataService.saveData(this.moduleId, sageSavePacket)
    ).pipe(
      tap(() => {
        this.resetDirty();
        this.clearTreeCache();
        this.setTree();
      })
    );
  }

  public resetDirty(): void {
    this.dirty = false;
    this.inputUpdated = false;
    this.configUpdated = false;
    this.companyService.setDisabled(false);
  }

  private updateSageReportOptions(): void {
    const isNotCalculated = !this.hasOutput() || this.isOutputStale;
    this.reportService.updateSageReportVariables(
      isNotCalculated,
      isNotCalculated || !this.nodeData!.hasCalculationReportTemplate,
      this.currentRouteData().moduleId,
      this.currentIndex,
      !this.nodeData?.input.data['intellijoint'] || isNotCalculated
    );
  }

  public override getExtraTabs(module: string): ExtraTab[] {
    return this.extraTabs;
  }

  public AddExtraTab(tabName: string, form: TemplateRef<any>): void {
    this.extraTabs.push(new ExtraTab(tabName, form));
  }

  public override getImageLocation(): string {
    return '../assets/images/sage-images';
  }

  public saveFeedback(feedback: string): Observable<boolean> {
    const feedBack = {
      id: undefined,
      feedback: feedback,
      data: {
        sageModuleId: this.currentRouteData(),
        sageModuleName: this.nodeData?.input.name,
        sageModuleType: this.nodeData?.input.module
      }
    } as FeedbackDto;

    return this.dataService.saveFeedback(feedBack);
  }
}
