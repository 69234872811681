<ng-container *ngIf="data; else load">
  <div class="e2g-padding h-100 flex-col flex-111">
    <div class="d-flex justify-content-between">
      <div class="d-flex align-items-center">
        <div class="d-flex flex-row h6 mb-0">
          <div class="e2g-primary-grey">CML:</div>
          <div class="e2g-blue ml-2">{{ data.name }}</div>
        </div>
        <div class="d-flex text-left ml-2">
          <button class="btn e2g-btn-outline-primary" (click)="goBack()">Go Back</button>
        </div>
      </div>

      <div>
        <div *ngIf="isDirty()">
          <button id="btn-save" class="btn e2g-btn-solid-primary mr-1" [disabled]="saving" (click)="saveChanges().subscribe()">
            Save
          </button>
          <button id="btn-revert" class="btn e2g-btn-outline-primary" [disabled]="saving" (click)="revertChanges()">
            Revert Changes
          </button>
        </div>
      </div>
    </div>

    <e2g-ag-grid class="mt-2 h-100" [options]="gridOptions" [data]="gridDataHandler.data"> </e2g-ag-grid>
  </div>
</ng-container>

<ng-template #load>
  <div class="loading"></div>
</ng-template>
