import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { DmlGridDataDto } from 'src/app/dmls-module/models/dml-grid-data-dto';
import { DmlGridDisplayInfoDto } from 'src/app/dmls-module/models/dml-grid-display-info-dto';

import { DmlDto } from '../models/dml-dto';

@Injectable({
  providedIn: 'root'
})
export class DmlService {
  public refreshFormData: Observable<void> = new Subject<void>();
  public retrieveDmlData: Observable<void> = new Subject<void>();
  public revertDataChanges: Observable<void> = new Subject<void>();
  public displayGridInfo: Subject<DmlGridDisplayInfoDto> = new Subject<DmlGridDisplayInfoDto>();

  public dmlData: BehaviorSubject<any | undefined> = new BehaviorSubject<any | undefined>(undefined);
  public uploadGridData: BehaviorSubject<DmlGridDataDto | undefined> = new BehaviorSubject<DmlGridDataDto | undefined>(
    undefined
  );
  public hasCalculationErrors: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public hasOutput: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public displayDataChangedWarning: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public clear(): void {
    this.dmlData.next(undefined);
    this.uploadGridData.next(undefined);
    this.hasCalculationErrors.next(false);
    this.hasOutput.next(false);
  }

  public setHasCalculationErrors(hasErrors: boolean): void {
    this.hasCalculationErrors.next(hasErrors);
  }

  public getHasOutput(): boolean {
    return this.hasOutput.value;
  }

  public setHasOutput(hasOutput: boolean): void {
    this.hasOutput.next(hasOutput);
  }

  public setHasOutputAndCalculationErrors(dml: DmlDto): void {
    this.hasCalculationErrors.next(dml.hasCalculationErrors!);
    this.hasOutput.next(dml.evaluationDate !== undefined && !dml.hasCalculationErrors!);
    this.displayDataChangedWarning.next(
      dml.evaluationDate !== undefined && dml.lastSaved !== undefined
        ? new Date(dml.evaluationDate).getTime() < new Date(dml.lastSaved).getTime()
        : false
    );
  }

  public setGridData(gridData: DmlGridDataDto | undefined): void {
    this.uploadGridData.next(gridData);
  }

  public refreshData(): void {
    (this.refreshFormData as Subject<void>).next();
  }

  public revertChanges(): void {
    (this.revertDataChanges as Subject<void>).next();
  }

  public setDmlData(data: any | undefined): void {
    this.dmlData.next(data);
  }

  public getDmlData(): any {
    (this.retrieveDmlData as Subject<void>).next();
    return this.dmlData.value;
  }

  public setGridDisplayInfo(data: DmlGridDisplayInfoDto): void {
    this.displayGridInfo.next(data);
  }
}
