import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AppSettingsService } from './app-settings/app-settings.service';
import { InspectorDto } from './models/inspector-dto';

@Injectable({
  providedIn: 'root'
})
export class InspectorService {
  private serverUri: string;

  public constructor(private http: HttpClient, private settingsService: AppSettingsService) {
    this.serverUri = this.settingsService.settings.apiUri as string;
  }

  public getInspectors(): Observable<Array<InspectorDto>> {
    return this.http.get<Array<InspectorDto>>(`${this.serverUri}/inspectors`);
  }

  public addInspector(newInspector: InspectorDto): Observable<boolean> {
    const body = JSON.stringify(newInspector);

    return this.http.post<boolean>(`${this.serverUri}/inspectors`, body).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  public updateInspectors(inspectors: Array<InspectorDto>): Observable<boolean> {
    const body = JSON.stringify(inspectors);
    return this.http.post<boolean>(`${this.serverUri}/inspectors/batch`, body).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }
}
