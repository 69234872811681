import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { E2gSelectOption } from '@equityeng/e2g-ng-ui';
import {
  faArrowLeft,
  faArrowRight,
  faCheck,
  faExclamationTriangle,
  faPenToSquare,
  faX
} from '@fortawesome/pro-regular-svg-icons';
import { combineLatest, filter, map, Observable, Subject, take, takeUntil } from 'rxjs';
import { Breadcrumb } from 'src/app/breadcrumb-module/breadcrumbs/breadcrumbs.component';
import { OnDestroyBaseComponent } from 'src/app/on-destroy-base-component/on-destroy-base-component';
import { DialogService } from 'src/app/shared-module/dialog.service';
import { DialogButtons } from 'src/app/shared-module/models/dialog-buttons';
import { DialogData } from 'src/app/shared-module/models/dialog-data';
import { NEW_ITEM_KEY } from 'src/app/shared-module/models/new-item-key';
import { listContainsStringCaseInsensitive } from 'src/app/utilities/string-methods';
import { lengthMessage, requiredMessage, valueMustBeUniqueMessage } from 'src/app/utilities/validation-messages';

import { SlideOutContainerAction } from '../models/constants/slide-out-container-action';
import { SlideOutContainerService } from '../slide-out-container.service';

@Component({
  selector: 'app-slide-out-header',
  templateUrl: './slide-out-header.component.html',
  styleUrls: ['./slide-out-header.component.css']
})
export class SlideOutHeaderComponent extends OnDestroyBaseComponent implements OnInit {
  @Output() public triggerAction = new EventEmitter<SlideOutContainerAction>();
  @ViewChild('calcErrorsTemplate', { static: true }) public calcErrorsTemplate!: TemplateRef<any>;

  public useDefaultHeaderBorder = this.slideOutService.useDefaultHeaderBorder;
  public headerVisible = this.slideOutService.headerVisible;
  public allowRename = this.slideOutService.allowRename;
  public staticHeading = this.slideOutService.staticHeading;
  public backButtonVisible = this.slideOutService.backButtonVisible;
  public dirty = false;
  public new = false;
  public allowDuplicateNames = false;

  public options: Array<E2gSelectOption> = [];
  public selectedOptionId: string = '';

  public editing: boolean = false;
  public editName: string = '';
  public origName: string = '';
  public nameErrors: Array<string> = [];
  private invalidNames: Array<string> = [];
  private customNameError?: string;
  public breadcrumbs: Array<Breadcrumb> = [];
  public calcError?: string;

  private nameListIndex: number = 0;
  private calculationErrorsDialog!: DialogData;
  private nameIsTouched: boolean = false;

  public faExclamationTriangle = faExclamationTriangle;
  public faArrowLeft = faArrowLeft;
  public faArrowRight = faArrowRight;
  public faPenToSquare = faPenToSquare;
  public faCheck = faCheck;
  public faX = faX;

  public constructor(
    private slideOutService: SlideOutContainerService,
    private dialogService: DialogService,
    private domSanitizer: DomSanitizer
  ) {
    super();
  }

  public ngOnInit(): void {
    this.slideOutService.action
      .pipe(
        filter((x) => x === SlideOutContainerAction.Revert),
        takeUntil(this.destroy)
      )
      .subscribe(() => this.revertName());

    combineLatest([this.slideOutService.options, this.slideOutService.optionId, this.slideOutService.breadcrumbs])
      .pipe(takeUntil(this.destroy))
      .subscribe(([options, optionId, breadcrumbs]) => {
        this.calcError = undefined;
        this.options = options;
        this.selectedOptionId = optionId;
        this.setupNameListIndex();
        this.setupName();
        this.setupNewItemData(optionId);
        this.setupBreadcrumbs(options, optionId, breadcrumbs);
      });

    combineLatest([this.slideOutService.dirty, this.slideOutService.optionId])
      .pipe(takeUntil(this.destroy))
      .subscribe(([dirty, selectedOptionId]) => {
        this.dirty = dirty || !selectedOptionId;
      });

    this.slideOutService.allowDuplicateNames.pipe(takeUntil(this.destroy)).subscribe((x) => {
      this.allowDuplicateNames = x;
    });

    this.slideOutService.invalidNames.pipe(takeUntil(this.destroy)).subscribe((x) => {
      this.invalidNames = x;
      this.validateName();
    });

    this.slideOutService.customHeaderNameError.pipe(takeUntil(this.destroy)).subscribe((x) => {
      this.customNameError = x;
      if (x !== undefined) {
        this.allowDuplicateNames = true;
      }
    });

    this.slideOutService.calcError.pipe(takeUntil(this.destroy)).subscribe((error) => {
      this.calcError = error;
    });
  }

  public onSelectionChange(): void {
    this.setupNameListIndex();
    this.updateNameId();
  }

  public next(): void {
    this.nameListIndex++;
    if (this.nameListIndex > this.options.length - 1) {
      this.nameListIndex = 0;
    }
    this.updateNameId();
  }

  public back(): void {
    this.nameListIndex--;
    if (this.nameListIndex < 0) {
      this.nameListIndex = this.options.length - 1;
    }
    this.updateNameId();
  }

  public setupName(): void {
    this.origName = this.getSelectedIndex()?.label || '';
    this.editName = this.origName;
  }

  public enterNameEdit(): void {
    this.editName = this.options.find((x) => x.value === this.selectedOptionId)?.label || '';
    this.origName = this.editName;
    this.editing = true;
  }

  public cancelNameEdit(): void {
    this.editName = this.origName;
    this.updateName();

    this.validateName();

    this.editing = false;
  }

  public editingNameChange(): void {
    this.setHeaderValid(this.validate());
    this.slideOutService.triggerNameUpdate(this.editName);
    this.nameIsTouched = true;
  }

  public validateName(): void {
    const valid = this.validate();
    this.setHeaderValid(valid);
    if (!valid) {
      this.editing = true; //Show errors if there are any
    }
  }

  public onCancel(): void {
    this.triggerAction.emit(SlideOutContainerAction.Cancel);
  }

  public getSlideoutHeaderBorder(): Observable<string> {
    return this.slideOutService.useDefaultHeaderBorder.pipe(
      take(1),
      map((x) => (x ? 'slide-out-header-border' : ''))
    );
  }

  public displayCalculationErrors(): void {
    this.calculationErrorsDialog = {
      title: 'Errors',
      buttons: DialogButtons.Yes,
      yesButtonText: 'Cancel',
      template: this.calcErrorsTemplate,
      width: '1280px',
      height: '768px'
    } as DialogData;

    this.dialogService.display(this.calculationErrorsDialog);
  }

  public parseMsg(msg: string): SafeHtml {
    return this.domSanitizer.bypassSecurityTrustHtml(msg);
  }

  private setupNewItemData(optionId: string): void {
    this.new = optionId === NEW_ITEM_KEY;
    this.editing = optionId === NEW_ITEM_KEY;

    this.setHeaderValid(this.validate());
  }

  private setupNameListIndex(): void {
    this.nameListIndex = this.options.findIndex((x) => (x.value || x.label) === this.selectedOptionId);
  }

  private setupBreadcrumbs(options: Array<E2gSelectOption>, optionId: string, breadcrumbs: Array<Breadcrumb>): void {
    this.breadcrumbs = [...breadcrumbs];
    if (breadcrumbs.length > 0 && optionId !== NEW_ITEM_KEY) {
      // Add current selected option to breadcrumb trail
      const currentOption = options.find((c) => c.value === optionId);
      if (currentOption) {
        this.breadcrumbs.push({ name: currentOption.label });
      }
    }
  }

  private updateNameId(): void {
    this.selectedOptionId =
      this.options[this.nameListIndex].value?.toString() || this.options[this.nameListIndex].label;
    this.slideOutService.setOptionId(this.selectedOptionId);
  }

  private updateName(): void {
    const option = this.getSelectedIndex();
    if (option) {
      option.label = this.editName;
    }
    this.slideOutService.triggerNameUpdate(this.editName);
  }

  private revertName(): void {
    const option = this.getSelectedIndex();
    if (option) {
      option.label = this.origName;
    }
    this.editing = false;
    this.editName = this.origName;
    this.validateName();
  }

  private getSelectedIndex(): E2gSelectOption | undefined {
    return this.options.find((x) => (x.value || x.label) === this.selectedOptionId);
  }

  private validate(): boolean {
    //TODO add unit tests
    this.nameErrors = [];

    if (this.editName.trim().length === 0) {
      if (this.nameIsTouched) {
        this.nameErrors.push(requiredMessage());
      } else {
        //Header is invalid, but error is hidden
        return false;
      }
    }

    if (this.editName.trim().length > 255) {
      this.nameErrors.push(lengthMessage(255));
    }

    const editedName = this.editName.toLocaleLowerCase().trim();
    const orgName = this.origName.toLocaleLowerCase().trim();

    if (listContainsStringCaseInsensitive(this.invalidNames, editedName)) {
      this.nameErrors.push(this.customNameError ?? valueMustBeUniqueMessage());
    } else if (
      !this.allowDuplicateNames &&
      editedName !== orgName &&
      this.options.some((x) => x.label.toLowerCase() === editedName)
    ) {
      this.nameErrors.push(valueMustBeUniqueMessage());
    }

    return this.nameErrors.length === 0;
  }

  // NOTHING outside of this header should EVER call this
  private setHeaderValid(valid: boolean): void {
    (this.slideOutService.headerValid as Subject<boolean>).next(valid);
  }
}
