import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { AppSettingsService } from 'src/app/app-settings/app-settings.service';
import { IdNameDto } from 'src/app/asset-module/models/id-name-dto';
import { DmlOutputDto } from 'src/app/dmls-module/models/dml-output-dto';
import { DmlThinningDto } from 'src/app/dmls-module/models/dml-thinning-dto';

import { DmlCalculationResponseDto } from '../models/dml-calculation-response-dto';
import { DmlCrackingDto } from '../models/dml-cracking-dto';
import { DmlDentDto } from '../models/dml-dent-dto';
import { DmlDto } from '../models/dml-dto';
import { DmlGridDataDto } from '../models/dml-grid-data-dto';
import { DmlPittingDto } from '../models/dml-pitting-dto';

@Injectable({
  providedIn: 'root'
})
export class DmlDataService {
  private serverUri: string;

  public constructor(private http: HttpClient, private settingsService: AppSettingsService) {
    this.serverUri = this.settingsService.settings.apiUri as string;
  }

  public getDamageMonitoringLocations(assetId: string): Observable<Array<DmlDto>> {
    return this.http
      .get<Array<DmlDto>>(`${this.serverUri}/assets/${assetId}/damagemonitoringlocations`)
      .pipe(catchError(() => of(new Array<DmlDto>())));
  }

  public getDamageMonitoringLocation<T extends DmlDto>(id: string, type: number): Observable<T> {
    return this.http
      .get<T>(`${this.serverUri}/damagemonitoringlocation/${id}/type/${type}`)
      .pipe(catchError(() => of({} as T)));
  }

  public getDamageMonitoringLocationOutput<T extends DmlOutputDto>(id: string, type: number): Observable<T> {
    return this.http
      .get<T>(`${this.serverUri}/damagemonitoringlocation/${id}/type/${type}/output`)
      .pipe(catchError(() => of({} as T)));
  }

  public getDamageMonitoringLocationOutputStatusMessage(id: string): Observable<string> {
    return this.http
      .get<string>(`${this.serverUri}/damagemonitoringlocation/${id}/output/statusmessage`)
      .pipe(catchError(() => of('')));
  }

  public saveDamageMonitoringLocation(
    assetId: string,
    data: DmlThinningDto | DmlCrackingDto | DmlPittingDto | DmlDentDto
  ): Observable<boolean | string> {
    if (data.id) {
      // This is an update since we have the damage monitoring location Id.
      return this.http
        .put<string>(`${this.serverUri}/assets/${assetId}/damagemonitoringlocations/types/${data.type}`, data)
        .pipe(
          map(() => true),
          catchError(() => of(false))
        );
    } else {
      // This is an add since we don't have the damage monitoring location Id.
      return this.http
        .post<string>(`${this.serverUri}/assets/${assetId}/damagemonitoringlocations/types/${data.type}`, data)
        .pipe(
          map((x) => x),
          catchError(() => of(false))
        );
    }
  }

  public deleteDamageMonitoringLocation(id: string, fileId: string | undefined): Observable<boolean> {
    if (fileId) {
      return this.http.delete(`${this.serverUri}/damagemonitoringlocation/${id}/griddata/${fileId}`).pipe(
        map(() => true),
        catchError(() => of(false))
      );
    } else {
      return this.http.delete(`${this.serverUri}/damagemonitoringlocation/${id}`).pipe(
        map(() => true),
        catchError(() => of(false))
      );
    }
  }

  public getDamageMonitoringLocationGridData(type: number, fileId: string): Observable<DmlGridDataDto> {
    return this.http
      .get<DmlGridDataDto>(`${this.serverUri}/damagemonitoringlocation/types/${type}/griddata/${fileId}`)
      .pipe(catchError(() => of({})));
  }

  public updateDamageMonitoringLocationGridData(data: DmlDto): Observable<boolean> {
    return this.http.put<string>(`${this.serverUri}/damagemonitoringlocation/griddata`, data).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  public calcDamageMonitoringLocation(type: number, id: string): Observable<DmlCalculationResponseDto> {
    return this.http
      .get<DmlCalculationResponseDto>(`${this.serverUri}/damagemonitoringlocation/type/${type}/calc/${id}`)
      .pipe(catchError(() => of({} as DmlCalculationResponseDto)));
  }

  public getDamageMonitoringLocationReportFile(reportFileId: string): Observable<Blob> {
    return this.http.get<Blob>(`${this.serverUri}/damagemonitoringlocation/report/${reportFileId}`, {
      responseType: 'blob' as 'json'
    });
  }

  public getDmlNamesForAsset(assetId: string): Observable<Array<IdNameDto>> {
    return this.http
      .get<Array<IdNameDto>>(`${this.serverUri}/assets/${assetId}/dmls-names`)
      .pipe(catchError(() => of([])));
  }
}
