import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Directive, ElementRef, EventEmitter, Inject, OnDestroy, Output } from '@angular/core';
import { filter, fromEvent, map, Subject, takeUntil } from 'rxjs';

@Directive({
  selector: '[clickOutside]'
})
export class ClickOutsideDirective implements AfterViewInit, OnDestroy {
  @Output() clickOutside = new EventEmitter<HTMLElement>();
  private destroy = new Subject<void>();

  public constructor(private element: ElementRef, @Inject(DOCUMENT) private document: Document) {}

  public ngAfterViewInit(): void {
    fromEvent(this.document, 'click')
      .pipe(
        map((event) => event.target as HTMLElement),
        filter((target) => !this.isInside(target)),
        takeUntil(this.destroy)
      )
      .subscribe((target) => {
        this.clickOutside.emit(target);
      });
  }

  public ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  private isInside(elementToCheck: HTMLElement): boolean {
    return elementToCheck === this.element.nativeElement || this.element.nativeElement.contains(elementToCheck);
  }
}
