<ng-container [ngSwitch]="rbiRenderType" *ngIf="rbiCalculated">
  <span
    *ngSwitchCase="RbiRenderTypes.rbiIcon"
    [id]="'rbi-' + id"
    class="rbiIcon"
    [ngStyle]="{ background: rbiColor, color: textColor }"
  >
    RBI
  </span>

  <span *ngSwitchCase="RbiRenderTypes.circleIcon" class="w-100">
    <div [id]="'rbi-' + id" class="circleIcon" [ngStyle]="{ background: rbiColor, color: textColor }"></div>
  </span>

  <span>
    <div
      *ngSwitchCase="RbiRenderTypes.coloredDataField"
      [id]="'rbi-' + id"
      class="coloredDataField"
      [ngStyle]="{ background: rbiColor, color: textColor }"
    >
      {{ value }}
    </div>
  </span>
</ng-container>
