import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';

import { BUSINESS_RULES } from '../../sage-common-module/business-rules-injection-token';
import { DataCancelHandler } from '../data-cancel-handler';
import { MaterialBusinessRulesService } from '../moc-business-rules';

@Component({
  selector: 'app-custom-material-modal',
  templateUrl: './custom-material-modal.component.html',
  providers: [
    {
      provide: BUSINESS_RULES,
      useExisting: MaterialBusinessRulesService // Use existing service injected from ancestors
    }
  ]
})
export class CustomMaterialModalComponent implements OnInit {
  public readOnly: boolean;
  public isMaterialChosen: boolean;

  public unsGroupTrigger = new ReplaySubject<void>(1);
  public unsSubGroupTrigger = new ReplaySubject<void>(1);
  private cancelHandler: DataCancelHandler = new DataCancelHandler();

  public constructor(
    @Inject(BUSINESS_RULES) public materialDomainModel: MaterialBusinessRulesService,
    public dialogRef: MatDialogRef<CustomMaterialModalComponent>
  ) {
    this.readOnly = materialDomainModel.readOnly;
    this.isMaterialChosen = materialDomainModel.isMaterialChosen;
  }

  public ngOnInit(): void {
    this.cancelHandler.init(this.materialDomainModel, Object.keys(this.materialDomainModel.valueData));

    this.unsGroupTrigger.subscribe(() => this.unsSubGroupTrigger.next());
  }

  public close(): void {
    this.dialogRef.close();
  }

  public cancel(): void {
    this.cancelHandler.cancelChanges();
    this.close();
  }
}
