import { Component, ElementRef, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';

export interface Breadcrumb {
  name: string;
  id?: string;
  linkCommand?: () => void;
}

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.css']
})
export class BreadcrumbsComponent {
  @HostListener('window:resize', ['$event'])
  public sizeChange(): void {
    this.updateTooltipDisabled();
  }
  @Input() public breadcrumbs!: Array<Breadcrumb>;
  @Input() public disableLinks: boolean = false;

  public tooltipDisabled = false;

  public constructor(private elementRef: ElementRef, private router: Router) {
    this.router.events.subscribe(() => {
      this.updateTooltipDisabled();
    });
  }

  public ngAfterViewInit(): void {
    this.updateTooltipDisabled();
  }

  public ngOnChanges(): void {
    this.updateTooltipDisabled();
  }

  public hasLink(breadcrumb: Breadcrumb, index: number): boolean {
    return !this.disableLinks && breadcrumb.linkCommand !== undefined && index != this.breadcrumbs.length - 1;
  }

  public getTooltip(): string {
    return this.breadcrumbs.map((x) => x.name).join(' > ');
  }

  public updateTooltipDisabled(): void {
    this.tooltipDisabled = !Array.from(
      this.elementRef.nativeElement.getElementsByClassName('breadcrumb-overflow') as HTMLCollection
    ).some((element) => element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth);
  }
}
