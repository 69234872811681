import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppSettingsService } from '../../app-settings/app-settings.service';
import { JobMessagesDto } from '../models/job-messages-dto';
import { JobSources } from '../models/job-sources';
import { JobStatusDto } from '../models/job-status-dto';

@Injectable({
  providedIn: 'root'
})
export class JobNotificationsDataService {
  public constructor(private http: HttpClient, private settingsService: AppSettingsService) {}

  public getStatusList(): Observable<Array<JobStatusDto>> {
    return this.http.get<Array<JobStatusDto>>(`${this.settingsService.settings.apiUri}/jobs`);
  }

  public getMessages(source: JobSources, id: number): Observable<JobMessagesDto> {
    return this.http.get<JobMessagesDto>(`${this.settingsService.settings.apiUri}/jobs/source/${source}/messages/${id}`);
  }

  public deleteCompletedJobs(): Observable<boolean> {
    return this.http.delete<boolean>(`${this.settingsService.settings.apiUri}/jobs`);
  }

  public deleteJob(source: JobSources, id: number): Observable<boolean> {
    return this.http.delete<boolean>(`${this.settingsService.settings.apiUri}/jobs/source/${source}/${id}`);
  }

  public getJobFile(id: number): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>(`${this.settingsService.settings.apiUri}/jobs/file/${id}`, {
      observe: 'response',
      responseType: 'blob' as 'json'
    });
  }
}
