import { HttpResponse } from '@angular/common/http';

export class ResponseHelper {
  public static getFileName(resp: HttpResponse<any>): string {
    const contentDispositionHeader = resp.headers.get('Content-Disposition') || '';
    const contentDispositionHeaderResult = contentDispositionHeader.split(';')[1].trim().split('=')[1];
    const contentDispositionFileName = contentDispositionHeaderResult.replace(/"/g, '');
    return contentDispositionFileName;
  }

  public static getFileExtension(resp: HttpResponse<any>): string {
    const fileName = this.getFileName(resp);
    const strSplit = fileName.split('.');
    if (strSplit.length <= 1) {
      throw new Error(`FileName does not have extension, value: ${fileName}`);
    }
    return strSplit.pop()!;
  }
}
