import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import fileSaver from 'file-saver';
import { filter, map, Observable, ReplaySubject } from 'rxjs';

import { MenuDropdownOption } from '../../../models/calculation-option-entry';
import { RouteData } from '../../../models/route-data';
import { AssetSummaryReportService } from '../../../reporting-module/asset-summary-report/asset-summary-report.service';
import { AuditLogReportService } from '../../../reporting-module/audit-log-report/audit-log-report.service';
import { CmlDetailReportService } from '../../../reporting-module/cml-detail-report/cml-detail-report.service';
import { DueDateReportService } from '../../../reporting-module/due-date-report/due-date-report.service';
import { JointAssemblyProcedureReportService } from '../../../reporting-module/joint-assembly-procedure-report/joint-assembly-procedure-report.service';
import { OverdueReportService } from '../../../reporting-module/overdue-report/overdue-report.service';
import { ResponseHelper } from '../../../utilities/response-helper';
import { GeneralFeature } from '../../permission/permission-models';
import { PermissionService } from '../../permission/permission.service';
import { ReportDataService } from './report-data.service';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  public reportOptionList: Observable<Array<MenuDropdownOption>> = new ReplaySubject<Array<MenuDropdownOption>>(1);
  public isSageReportDisabled: Observable<boolean> = new ReplaySubject<boolean>(1);
  public isSageCalculationReportDisabled: Observable<boolean> = new ReplaySubject<boolean>(1);
  public isJointAssemblyProcedureReportDisabled: Observable<boolean> = new ReplaySubject<boolean>(1);
  public viewingSage: Observable<boolean> = new ReplaySubject<boolean>(1);

  private configRegistry: Array<(data: RouteData) => boolean> = [this.configSage.bind(this)];
  public sageId?: string;
  public sageIndex?: Array<number>;

  public constructor(
    private permissionService: PermissionService,
    private router: Router,
    private route: ActivatedRoute,
    private assetSummaryReport: AssetSummaryReportService,
    private overdueReport: OverdueReportService,
    private cmlDetailReport: CmlDetailReportService,
    private dueDateReport: DueDateReportService,
    private jointAssemblyProcedureReport: JointAssemblyProcedureReportService,
    private auditLogReport: AuditLogReportService,
    private dataService: ReportDataService
  ) {}

  public init(): void {
    const navigationEndEvent = this.router.events.pipe(filter((e) => e instanceof NavigationEnd));

    navigationEndEvent
      .pipe(
        map((e) => ({
          path: (e as NavigationEnd).urlAfterRedirects,
          route: this.rootRoute(this.route)
        })),
        filter((x) => x.route.outlet === 'primary')
      )
      .subscribe((x: RouteData) => this.configOptions(x));
  }

  private configOptions(data: RouteData): void {
    (this.viewingSage as ReplaySubject<boolean>).next(data.path.startsWith('/sage'));
    if (
      this.configRegistry.every((x) => {
        if (x(data)) {
          return false;
        }
        return true;
      })
    ) {
      //Default options for any unspecified route
      this.reportOptionSubject.next([
        {
          id: 'asset-summary',
          label: 'Asset Summary',
          hasTopDivider: false,
          action: this.runAssetSummaryReport.bind(this),
          visible: this.permissionService.hasGeneralFeature(GeneralFeature.ScraRates)
        },
        { id: 'audit-log', label: 'Audit Log', hasTopDivider: false, action: this.runAuditLogReport.bind(this) },
        { id: 'cml-detail', label: 'CML Detail', hasTopDivider: false, action: this.runCmlDetailReport.bind(this) },
        {
          id: 'due-date',
          label: 'Due Date',
          hasTopDivider: false,
          action: this.runDueDateReport.bind(this)
        },
        {
          id: 'overdue',
          label: 'Overdue',
          hasTopDivider: false,
          action: this.runOverdueReport.bind(this)
        }
      ]);
    }
  }

  private configSage(data: RouteData): boolean {
    if (data.path.startsWith('/sage')) {
      this.reportOptionSubject.next([
        {
          id: 'sage-calc',
          label: 'Calculation',
          hasTopDivider: false,
          disabled: this.isSageCalculationReportDisabled,
          action: this.runSageCalculationReport.bind(this)
        },
        {
          id: 'joint-assembly-procedure',
          label: 'Joint Assembly Procedure',
          hasTopDivider: false,
          disabled: this.isJointAssemblyProcedureReportDisabled,
          action: this.runJointAssemblyProcedureReport.bind(this)
        }
      ]);
      return true;
    }
    return false;
  }

  public updateSageReportVariables(
    isSageReportDisabled: boolean,
    isSageCalculationReportDisabled: boolean,
    currentId: string,
    currentIndex: Array<number>,
    isJointAssemblyProcedureReportDisabled: boolean = true
  ): void {
    (this.isSageReportDisabled as ReplaySubject<boolean>).next(isSageReportDisabled);
    (this.isSageCalculationReportDisabled as ReplaySubject<boolean>).next(isSageCalculationReportDisabled);
    this.sageId = currentId;
    this.sageIndex = currentIndex;
    (this.isJointAssemblyProcedureReportDisabled as ReplaySubject<boolean>).next(
      isJointAssemblyProcedureReportDisabled
    );
  }

  private runOverdueReport(): void {
    this.overdueReport.run();
  }

  private runAssetSummaryReport(): void {
    this.assetSummaryReport.run();
  }

  private runAuditLogReport(): void {
    this.auditLogReport.run();
  }

  private runCmlDetailReport(): void {
    this.cmlDetailReport.run();
  }

  private runDueDateReport(): void {
    this.dueDateReport.run();
  }

  private runSageCalculationReport(): void {
    this.dataService.getSageCalculationReport(this.sageId!, this.sageIndex!).subscribe((resp) => this.saveFile(resp));
  }

  private runJointAssemblyProcedureReport(): void {
    this.jointAssemblyProcedureReport.run(this.sageId!);
  }

  private saveFile(resp: HttpResponse<Blob> | undefined): void {
    if (resp) {
      fileSaver.saveAs(URL.createObjectURL(resp.body as Blob), ResponseHelper.getFileName(resp));
    }
  }

  private rootRoute(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }

  private get reportOptionSubject(): ReplaySubject<Array<MenuDropdownOption>> {
    return this.reportOptionList as ReplaySubject<Array<MenuDropdownOption>>;
  }
}
