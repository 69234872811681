import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { BusinessRulesServiceBase } from 'src/app/sage-common-module/BusinessRulesServiceBase';
import { BusinessRuleValueList } from 'src/app/sage-common-module/models/business-rule-value-list';
import { BusinessRulesDefinition } from 'src/app/sage-common-module/models/business-rules-definition';

@Injectable()
export class CircuitBusinessRulesService extends BusinessRulesServiceBase implements BusinessRulesDefinition {
  public get valueData(): BusinessRuleValueList {
    if (this.data === undefined) {
      throw 'init must be called before valueData()';
    } else {
      return {
        estimatedCorrosionRate: {
          getDefaultValue: () => of(this.data.eqEstimatedCorrosionRate)
        },
        operatingPressure: {
          getRequired: () => this.data.rbi === true
        },
        operatingTemperature: {
          getRequired: () => this.data.rbi === true
        }
      };
    }
  }
}
