import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { merge, MonoTypeOperatorFunction, Observable } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';

import { OnDestroyBaseComponent } from '../on-destroy-base-component/on-destroy-base-component';
import { UnitsOfMeasureEvaluator } from '../units-of-measure/units-of-measure-evaluator';
import { BrSubjects } from './br-subjects';
import { BUSINESS_RULES } from './business-rules-injection-token';
import { BusinessRuleValues, GET_DEFAULT_VALUE, GET_REQUIRED, GET_VALID_VALUES } from './models/business-rule-values';
import { BusinessRulesDefinition } from './models/business-rules-definition';
import { SageFormActionType } from './models/sage-form-action-type';

@Component({
  selector: 'e2g-br-base',
  template: ''
})
export class BrBaseComponent extends OnDestroyBaseComponent implements OnInit {
  @Input() public propName!: string;
  @Input() public heading?: string;
  @Input() public placeholder?: string;
  @Input() public otherTriggers?: Array<Observable<void>>;
  @Input() public disabled: boolean = false;
  @Input() public showOnlyWhenRequired: boolean = false;
  @Input() public disableReset: boolean = false;
  @Input() public readOnly: boolean = false;
  @Input() public labelWidth?: string = undefined;
  @Input() public overriddenDefaultWarning: boolean = false;
  @Input() public warnings: Array<string> = [];
  @Output() public change$ = new EventEmitter<void>();

  protected subjects: BrSubjects;

  public constructor(
    private unitsOfMeasureEvaluator: UnitsOfMeasureEvaluator,
    @Inject(BUSINESS_RULES) protected businessRules: BusinessRulesDefinition
  ) {
    super();
    this.subjects = new BrSubjects();
  }

  public ngOnInit(): void {
    if (this.propName === undefined) {
      throw 'propName is required';
    }

    if (this.heading && this.unitsOfMeasureEvaluator) {
      this.unitsOfMeasureEvaluator
        .getUnits(this.propName)
        .pipe(
          filter((x) => x !== undefined),
          map((s) => `${this.heading} ${s}`)
        )
        .subscribe((headingWithUoM) => (this.heading = headingWithUoM));
    }

    if (this.otherTriggers && this.otherTriggers.length > 0) {
      merge(...this.otherTriggers)
        .pipe(takeUntil(this.destroy))
        .subscribe(() => {
          this.subjects.set();
        });
    }

    this.subjects.update$
      .pipe(
        this.getDefaultValueFilter(this.propName),
        switchMap((SageFormActionType) =>
          this.getBrValues().getDefaultValue!().pipe(map((value) => ({ SageFormActionType, value })))
        ),
        takeUntil(this.destroy)
      )
      .subscribe((data) => {
        this.businessRules.dataHandler.silentSetValue(
          this.propName,
          data.value,
          data.SageFormActionType === SageFormActionType.reset
        );
      });

    this.businessRules.startup$.subscribe(() => this.subjects.init());
  }

  public get value(): any {
    return this.businessRules.data[this.propName];
  }

  public set value(value: any) {
    this.businessRules.data[this.propName] = value;
  }

  public isDefaultValue(): boolean {
    return this.businessRules.dataHandler.isDefaultValue(this.propName);
  }

  public isRequired(): boolean {
    return this.hasBrRequiredMethod() ? this.getBrValues().getRequired!() : false;
  }

  public isReadOnly(): boolean {
    return this.businessRules.readOnly || this.readOnly;
  }

  public getWarnings(): Array<string> {
    const warnings = [...this.warnings];
    if (!this.isDefaultValue() && this.overriddenDefaultWarning) {
      warnings.push('Default value has been overridden');
    }
    return warnings;
  }

  public getLabelWidth(): string | undefined {
    return this.labelWidth === undefined ? this.businessRules.labelWidth : this.labelWidth;
  }

  public showReset(): boolean {
    return (
      !this.businessRules.readOnly &&
      this.hasBrDefaultValueMethod() &&
      !this.disableReset &&
      Object.keys(this.businessRules.dataHandler.getUserSetValues()).some((x) => x === this.propName)
    );
  }

  public set(): void {
    // set the data, as if the user typed it
  }

  public reset(): void {
    this.businessRules.dataHandler.silentClearValue(this.propName);
    this.subjects.reset();
    this.onChange();
  }

  public onChange(): void {
    this.change$.emit();
  }

  private getDefaultValueFilter(propName: string): MonoTypeOperatorFunction<SageFormActionType> {
    return filter(
      (sageFormActionType: SageFormActionType) =>
        this.hasBrDefaultValueMethod() &&
        ((sageFormActionType > SageFormActionType.init && this.businessRules.dataHandler.isDefaultValue(propName)) ||
          (this.businessRules.data as any)[propName] === undefined)
    );
  }

  protected hasBrRequiredMethod(): boolean {
    return this.hasBrProp() && GET_REQUIRED in this.getBrValues();
  }

  protected hasBrDefaultValueMethod(): boolean {
    return this.hasBrProp() && GET_DEFAULT_VALUE in this.getBrValues();
  }

  protected hasBrValidValuesMethod(): boolean {
    return this.hasBrProp() && GET_VALID_VALUES in this.getBrValues();
  }

  protected getBrValues(): BusinessRuleValues {
    return this.businessRules.valueData[this.propName] as BusinessRuleValues;
  }

  private hasBrProp(): boolean {
    return this.propName in this.businessRules.valueData;
  }
}
