import { Component } from '@angular/core';
import { map, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AlertService } from 'src/app/alert.service';
import { OnDestroyBaseComponent } from '../../on-destroy-base-component/on-destroy-base-component';
import { State, StateService } from '../../state.service';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.css']
})
export class ShellComponent extends OnDestroyBaseComponent {
  public isVisible: string = 'visible';

  public state: Observable<State>;
  public styles: Observable<{ [klass: string]: any } | null>;
  public alert = this.alertService.alert;
  public showAlert = this.alertService.showPageAlert;

  public constructor(private stateService: StateService, private alertService: AlertService) {
    super();
    this.state = this.stateService.state;

    this.styles = this.state.pipe(
      map((x) => ({ width: `${x.slideOut.widthPercent}%` })),
      takeUntil(this.destroy)
    );
  }

  public onVisible(status: string): void {
    this.isVisible = status;
  }
}
