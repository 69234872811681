<div class="d-flex flex-col align-items-center">
  <ng-container *ngFor="let toast of toastService.toasts">
    <div class="mt-2">
      <ngb-toast
        [delay]="3000"
        (hidden)="toastService.remove(toast)"
        [autohide]="toast.autohide ?? true"
        (mouseenter)="toast.autohide = false"
		    (mouseleave)="toast.autohide = true"
      >
        <app-toast [toast]="toast"></app-toast>
      </ngb-toast>
    </div>
  </ng-container>
</div>
