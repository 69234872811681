import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { faDownload, faSearchMinus, faSearchPlus } from '@fortawesome/pro-solid-svg-icons';
import fileSaver from 'file-saver';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import { Observable, of, Subject } from 'rxjs';
import { catchError, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { OnDestroyBaseComponent } from 'src/app/on-destroy-base-component/on-destroy-base-component';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.css']
})
export class E2gPdfViewerComponent extends OnDestroyBaseComponent implements OnInit, OnChanges {
  @Input() public dataReader!: () => Observable<Blob>;
  @Input() public filename!: string;

  private refresh = new Subject<void>();

  public src: Blob | undefined;
  public url: string = '';

  public documentReady: boolean | undefined;
  public hasError: boolean | undefined;

  public pageCount: number = 0;
  public page: number = 1;
  public zoom: number = 1;

  public faSearchPlus = faSearchPlus;
  public faSearchMinus = faSearchMinus;
  public faDownload = faDownload;

  public ngOnInit(): void {
    this.refresh
      .pipe(
        startWith(null),
        tap(() => (this.page = 1)),
        switchMap(() =>
          this.dataReader().pipe(
            catchError(() => of(false)),
            takeUntil(this.destroy)
          )
        )
      )
      .subscribe((data) => {
        if (typeof data === 'boolean') {
          this.hasError = true;
        } else {
          this.src = data;
          this.url = URL.createObjectURL(data);
        }
      });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataReader) {
      this.refresh.next();
    }
  }

  public loadComplete(pdf: PDFDocumentProxy): void {
    this.pageCount = pdf.numPages;
    this.documentReady = true;
  }

  public pageChanged(page: number): void {
    this.page = page;
  }

  public zoomIn(): void {
    this.zoom = Math.min(2, this.zoom + 0.2);
  }

  public zoomOut(): void {
    this.zoom = Math.max(0.1, this.zoom - 0.2);
  }

  public download(): void {
    fileSaver.saveAs(this.src!, this.filename);
  }
}
