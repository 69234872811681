<div class="e2g-padding h-100 flex-col flex-111" *ngIf="this.inputData?.unitKey; else loading">
  <ul ngbNav #nav="ngbNav" [(activeId)]="activeTabId" class="d-inline-flex sub-nav-tabs">
    <li [ngbNavItem]="1">
      <a ngbNavLink>Upcoming</a>
      <ng-template ngbNavContent>
        <div class="h-100">
          <div class="grid-title-row">
            <div class="d-flex align-items-center">
              <div class="d-flex text-left align-items-center">
                <button
                  id="btn-futureActivity"
                  class="btn e2g-btn-outline-primary"
                  mat-button
                  [matMenuTriggerFor]="addButtonMenu"
                  *ngIf="activityPermissions.edit"
                  [disabled]="activityTypes === undefined"
                >
                  Add Activity <fa-icon [icon]="faChevronDown" class="ml-1"></fa-icon>
                </button>
                <mat-menu #addButtonMenu="matMenu" yPosition="below">
                  <button
                    mat-menu-item
                    *ngFor="let button of addButtonOptions"
                    [disabled]="isAddButtonItemDisabled(button)"
                    (click)="addFutureActivity(button)"
                  >
                    {{ button }}
                  </button>
                </mat-menu>
              </div>
            </div>
            <app-download-spreadsheet
              (onDownload)="futureActivitiesGridOptions.downloadExcelFile!()"
            ></app-download-spreadsheet>
          </div>

          <e2g-ag-grid
            #futureActivitiesGrid
            class="h-100"
            [options]="futureActivitiesGridOptions"
            [data]="futureActivities"
          >
          </e2g-ag-grid>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="2">
      <a ngbNavLink>History</a>
      <ng-template ngbNavContent>
        <div class="grid-title-row">
          <div class="d-flex align-items-center">
            <div class="d-flex text-left align-items-center">
              <button
                id="btn-newActivity"
                class="btn e2g-btn-outline-primary"
                mat-button
                [matMenuTriggerFor]="addButtonMenu"
                *ngIf="activityPermissions.edit"
                [disabled]="activityTypes === undefined"
              >
                Add Activity <fa-icon [icon]="faChevronDown" class="ml-1"></fa-icon>
              </button>
              <mat-menu #addButtonMenu="matMenu" yPosition="below">
                <button mat-menu-item *ngFor="let button of addButtonOptions" (click)="addHistoricalActivity(button)">
                  {{ button }}
                </button>
              </mat-menu>
            </div>
          </div>
          <app-download-spreadsheet
            (onDownload)="activitiesHistoryGridOptions.downloadExcelFile!()"
          ></app-download-spreadsheet>
        </div>
        <e2g-ag-grid
          #activitiesHistoryGrid
          class="h-100"
          [options]="activitiesHistoryGridOptions"
          [data]="pastActivities"
        >
        </e2g-ag-grid>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav" class="outlet"></div>
</div>

<ng-template #loading>
  <div class="loading"></div>
</ng-template>
