import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ModuleCalculationResult, UnitSystem } from '@equityeng/sage-forms-core';
import { IFunctionalDefaults, sageCall, sageFunctionCallResult } from '@equityeng/sage-forms-core/functional-defaults';
import { catchError, map, Observable, of, tap } from 'rxjs';

import { AppSettingsService } from '../../app-settings/app-settings.service';
import { FeedbackDto } from '../../models/feedback-dto';
import { NameAndId } from '../../models/name-and-id';
import { JobNotificationService } from '../../notifications-module/services/job-notifications.service';
import { ExportInpFileDto } from '../../sandbox-tree-module/models/export-inp-file-dto';
import { ExportOutFileDto } from '../../sandbox-tree-module/models/export-out-file-dto';
import { SageInputFileType } from '../../sandbox-tree-module/models/sage-import-file-type';
import { SageModuleInfo } from '../../sandbox-tree-module/models/sage-module-info-dto';
import { CalcSageModuleRequest } from '../models/calculations/calc-sage-module-request';
import { KeywordDescriptionDto } from '../models/keyword-description-dto';
import { SageNodeDataScra } from '../models/module-node';
import { SageSavePacket } from '../models/sage-save-packet';

@Injectable({
  providedIn: 'root'
})
export class DataService implements IFunctionalDefaults {
  private _apiUri: string;
  public constructor(
    private http: HttpClient,
    private settingsService: AppSettingsService,
    private jobNotificationService: JobNotificationService
  ) {
    this._apiUri = this.settingsService.settings.apiUri as string;
  }

  public getData(id: string): Observable<SageNodeDataScra> {
    return this.http.get<SageNodeDataScra>(`${this._apiUri}/sage/${id}`);
  }

  public getKeywordDescription(keyword: string, module: string, version: string): Observable<string> {
    return this.http
      .get<KeywordDescriptionDto>(
        `${this._apiUri}/sage/module/${module}/keyword/${keyword}/description/version/${version}`
      )
      .pipe(map((x) => x.description));
  }

  public getOutput(id: string): Observable<ModuleCalculationResult> {
    return this.http.get<ModuleCalculationResult>(`${this._apiUri}/sage/output/${id}`);
  }

  public saveData(id: string, packet: SageSavePacket): Observable<boolean> {
    return this.http.put<void>(`${this._apiUri}/sage/${id}`, packet).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  public saveComponentModule(moduleId: string, componentId: string, packet: SageSavePacket): Observable<boolean> {
    return this.http.put<void>(`${this._apiUri}/sage/${moduleId}/${componentId}`, packet).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  public calculate(id: string, version: string): Observable<number> {
    return this.http
      .post<number>(`${this.settingsService.settings.apiUri}/calculations/sage/${id}/version/${version}`, {})
      .pipe(
        catchError(() => of(-1)),
        tap(() => {
          this.jobNotificationService.refreshNotifications();
        })
      );
  }

  public calculateSageModule(calcReq: CalcSageModuleRequest): Observable<number> {
    return this.http.post<number>(`${this.settingsService.settings.apiUri}/calculations/sage-module`, calcReq).pipe(
      catchError(() => of(-1)),
      tap(() => {
        this.jobNotificationService.refreshNotifications();
      })
    );
  }

  public getDefaults(
    module: string,
    version: string,
    defaultCalls: sageCall[],
    validValueCalls: sageCall[],
    unitSystem: UnitSystem
  ): Observable<sageFunctionCallResult> {
    const request = {
      module: module,
      defaults: defaultCalls,
      validValues: validValueCalls,
      unitSystem: unitSystem
    };
    return this.http.post<sageFunctionCallResult>(`${this._apiUri}/SageFunctions/${version}/CallFunctions`, request);
  }

  public getSageModuleList(): Observable<Array<SageModuleInfo>> {
    return this.http
      .get<Array<SageModuleInfo>>(`${this._apiUri}/sage/catalog`)
      .pipe(catchError(() => of(new Array<SageModuleInfo>())));
  }

  public getModuleBreadcrumbs(id: string): Observable<Array<NameAndId>> {
    return this.http
      .get<Array<NameAndId>>(`${this._apiUri}/sage/${id}/breadcrumbs`)
      .pipe(catchError(() => of(new Array<NameAndId>())));
  }

  public getModuleStatus(id: string): Observable<string> {
    return this.http.get<any>(`${this._apiUri}/sage/${id}/status`).pipe(
      map((x) => x.status!),
      catchError(() => of(''))
    );
  }

  public getModuleExampleCases(moduleName: string): Observable<Array<string>> {
    return this.http
      .get<Array<string>>(`${this._apiUri}/sage/modules/${moduleName}`)
      .pipe(catchError(() => of(new Array<string>())));
  }

  public useExampleCase(moduleId: string, exampleCase: string, moduleName: string): Observable<boolean> {
    return this.http
      .post(`${this._apiUri}/sage/modules/${moduleId}/example/${exampleCase}`, JSON.stringify(moduleName))
      .pipe(
        map(() => true),
        catchError(() => of(false))
      );
  }

  public exportModuleIn(id: string, fileType: SageInputFileType): Observable<ExportInpFileDto> {
    return this.http.get<ExportInpFileDto>(`${this.settingsService.settings.apiUri}/sage/${id}/export/in/${fileType}`);
  }

  public exportModuleOut(id: string): Observable<ExportOutFileDto> {
    return this.http.get<ExportOutFileDto>(`${this.settingsService.settings.apiUri}/sage/${id}/export/out`);
  }

  public saveFeedback(feedBack: FeedbackDto): Observable<boolean> {
    feedBack.data = JSON.stringify(feedBack.data);

    return this.http.post<boolean>(`${this._apiUri}/feedback/sagemodule`, feedBack).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }
}
