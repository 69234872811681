<e2g-ng-select-input
  *ngIf="!showOnlyWhenRequired || isRequired()"
  [id]="'sel-' + propName"
  [(ngModel)]="value"
  [heading]="heading"
  [placeholder]="placeholder"
  [isDefaultValue]="isDefaultValue()"
  [showReset]="showReset()"
  (reset)="reset()"
  (change)="onChange()"
  [readonly]="!!isReadOnly()"
  [requiredForCalc]="isRequired()"
  [options]="validValues$"
  [disabled]="disabled"
  [labelPosition]="getLabelPosition()"
  [labelWidth]="getLabelWidth()"
  [warnings]="getWarnings()"
>
</e2g-ng-select-input>
