import { Injectable } from '@angular/core';
import { SageCalcErrorToast, SageCalcWarningToast, SuccessToast } from 'src/app/shared/toast-module/models/toast-type';

import { SageCalculationStatus } from '../../models/enums/calculation-message-status';
import { ToastDisplayProperties } from '../../shared/toast-module/models/toast';
import { ToastService } from '../../shared/toast-module/toast.service';
import { SageCalcNotification } from '../models/sage-calc-notification';

@Injectable({
  providedIn: 'root'
})
export class SageCalcNotificationService {
  public constructor(private toastService: ToastService) {}

  public showCalcCompleted(completedCalc: SageCalcNotification): void {
    let displayProperties: ToastDisplayProperties = new SuccessToast();

    if (completedCalc.status === SageCalculationStatus.Error) {
      displayProperties = new SageCalcErrorToast();
    } else if (completedCalc.status === SageCalculationStatus.Warning) {
      displayProperties = new SageCalcWarningToast();
    }

    this.toastService.show({
      message: 'Calculated',
      moduleName: completedCalc.name,
      displayProperties,
      id: completedCalc.moduleId, //calcId?
      childIndex: completedCalc.childIndex,
      moduleRouteData: {
        moduleId: completedCalc.moduleId,
        assetId: completedCalc.assetId,
        unitOfMeasure: completedCalc.unitOfMeasure,
        unitId: completedCalc.unitId
      }
    });
  }
}
