import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppSettingsService } from '../../app-settings/app-settings.service';
import { ReadingDto } from '../models/reading-dto';

@Injectable({
  providedIn: 'root'
})
export class ReadingDataService {
  private serverUri: string;

  public constructor(private http: HttpClient, private settingsService: AppSettingsService) {
    this.serverUri = this.settingsService.settings.apiUri as string;
  }

  // Update Readings
  public updateReadings(readings: Array<ReadingDto>): Observable<boolean> {
    const body = JSON.stringify(readings);

    if (readings.length == 0) {
      return of(false);
    }

    return this.http.put<boolean>(`${this.serverUri}/readings`, body).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  public deleteReading(id: string): Observable<boolean> {
    return this.http.delete(`${this.serverUri}/readings/${id}`).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }
}
