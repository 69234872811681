import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AppSettingsService } from '../app-settings/app-settings.service';
import { AssetSummaryReportDto } from './models/asset-summary-report-dto';
import { AuditUserDto } from './models/audit-user-dto';
import { BaseReportData } from './models/base-report-dto';
import { CmlDetailReportDto } from './models/cml-detail-report-dto';
import { DueDateReportDto } from './models/due-date-report-dto';
import { JointAssemblyProcedureReportInputDto } from './models/joint-assembly-procedure-report-input-dto';
import { AuditLogReportData } from './models/log-report-data';

@Injectable({
  providedIn: 'root'
})
export class ReportingDataService {
  public constructor(private http: HttpClient, private settingsService: AppSettingsService) {}

  public generateAssetSummaryReport(reportCriteria: AssetSummaryReportDto): Observable<boolean> {
    return this.http.post(`${this.settingsService.settings.apiUri}/reports/asset-summary`, reportCriteria).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  public generateDueDateReport(reportCriteria: DueDateReportDto): Observable<boolean> {
    return this.http.post(`${this.settingsService.settings.apiUri}/reports/due-date`, reportCriteria).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  public generateCmlDetailReport(reportCriteria: CmlDetailReportDto): Observable<boolean> {
    return this.http.post(`${this.settingsService.settings.apiUri}/reports/cml-detail`, reportCriteria).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  public generateOverdueReport(): Observable<boolean> {
    return this.http
      .post(`${this.settingsService.settings.apiUri}/reports/overdue`, {
        timeZoneOffset: new Date().getTimezoneOffset()
      } as BaseReportData)
      .pipe(
        map(() => true),
        catchError(() => of(false))
      );
  }

  public getAuditUsers(): Observable<Array<AuditUserDto>> {
    return this.http
      .get<Array<AuditUserDto>>(`${this.settingsService.settings.apiUri}/reports/audit/users`)
      .pipe(catchError(() => of([] as Array<AuditUserDto>)));
  }

  public generateAuditLogReport(formData: AuditLogReportData): Observable<boolean> {
    formData.timeZoneOffset = new Date().getTimezoneOffset();

    return this.http.post<boolean>(`${this.settingsService.settings.apiUri}/reports/audit`, formData).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  public generateJointAssemblyProcedureReport(sageModuleId: string): Observable<boolean> {
    return this.http
      .post(`${this.settingsService.settings.apiUri}/reports/jointassemblyprocedure`, {
        sageModuleId: sageModuleId,
        timeZoneOffset: new Date().getTimezoneOffset()
      } as JointAssemblyProcedureReportInputDto)
      .pipe(
        map(() => true),
        catchError(() => of(false))
      );
  }
}
