import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '@equityeng/auth';
import { filter, switchMap, tap } from 'rxjs';

import { CalculationDataService } from '../../calculation-data.service';
import { CompanyService } from '../../company.service';
import { CalcUpdateDto, hasIdsInCommon, PlantManagerImpactedIdsDto } from '../../models/calculation-impacted-ids-dto';
import { CalculationMessageStatus } from '../../models/enums/calculation-message-status';
import { RefreshService } from '../../notifications-module/refresh.service';
import { WarningToast } from '../../shared/toast-module/models/toast-type';
import { ToastService } from '../../shared/toast-module/toast.service';
import { StateService } from '../../state.service';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  private calcsStarted: Array<CalcUpdateDto> = [];
  private idsInEdit?: PlantManagerImpactedIdsDto;
  private slideoutOpen: boolean = false;

  private assetRefreshToast = {
    message: 'Refreshing',
    displayProperties: new WarningToast(),
    id: 'assetRefreshToastId',
    autohide: false
  };

  public constructor(
    private router: Router,
    private refreshService: RefreshService,
    private authService: AuthService,
    private companyService: CompanyService,
    private dataService: CalculationDataService,
    private stateService: StateService,
    private toastService: ToastService
  ) {}

  public init(): void {
    this.authService.userAuthenticated$
      .pipe(
        switchMap(() => this.companyService.selectedCompany),
        switchMap(() => this.dataService.getCalculationsInProgress()),
        tap((x) => (this.calcsStarted = x)),
        switchMap(() => this.refreshService.calcUpdates)
      )
      .subscribe((x) => {
        let calc: CalcUpdateDto | undefined;
        switch (x.status) {
          case CalculationMessageStatus.New:
            this.calcsStarted.push(x);
            this.setAlert();
            break;
          case CalculationMessageStatus.InProgress:
            calc = this.calcsStarted.find((y) => x.messageId == y.messageId);
            if (calc) {
              calc.status = x.status;
            }
            break;
          default:
            //Item is finished
            this.calcsStarted = this.calcsStarted.filter((y) => y.messageId != x.messageId);
            this.setAlert();
        }
      });

    this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe(() => {
      this.clearIdsInEdit();
    });

    this.stateService.state.subscribe((x) => {
      if (this.slideoutOpen !== x.slideOut.display) {
        this.slideoutOpen = x.slideOut.display;
        this.clearIdsInEdit();
      }
    });
  }

  private clearIdsInEdit(): void {
    this.idsInEdit = undefined;
    this.setAlert();
  }

  public updateIdsInEdit(idsInEdit: PlantManagerImpactedIdsDto): void {
    this.idsInEdit = idsInEdit;
    this.setAlert();
  }

  private setAlert(): void {
    if (
      this.idsInEdit !== undefined &&
      this.calcsStarted.some((x) => hasIdsInCommon(x.impactedIds!, this.idsInEdit!))
    ) {
      this.toastService.show(this.assetRefreshToast);
    } else {
      this.toastService.remove(this.assetRefreshToast);
    }
  }
}
