import { Component, Inject, OnInit } from '@angular/core';
import { E2gSelectOption } from '@equityeng/e2g-ng-ui';
import { ReplaySubject } from 'rxjs';
import { setupNewComponent } from 'src/app/asset-module/components/component-slideout-utility';
import { AssetTypes } from 'src/app/models/enums/asset-types';
import { EquipmentDto } from 'src/app/models/equipment-dto';
import { FittingTypes } from 'src/app/pipe-module/fitting-types';
import { BUSINESS_RULES } from 'src/app/sage-common-module/business-rules-injection-token';
import { BusinessRulesDefinition } from 'src/app/sage-common-module/models/business-rules-definition';
import { ISaveChanges } from 'src/app/save-changes';

import {
  CompDetailBaseComponent,
  CompDetailBaseComponentProviders
} from '../../asset-module/components/comp-detail-base-component';
import { ComponentSlideoutInput } from '../../asset-module/models/component-slideout-input';
import { ComponentRecordDto } from '../../models/component-record-dto';
import { SLIDE_OUT_DATA } from '../../slide-out-module/slide-out-data-injection-token';
import { PipeBusinessRulesService } from '../pipe-business-rules.service';

@Component({
  selector: 'app-pipe',
  templateUrl: './pipe.component.html',
  styleUrls: ['./pipe.component.css'],
  providers: [
    {
      provide: BUSINESS_RULES,
      useClass: PipeBusinessRulesService
    },
    {
      provide: ISaveChanges,
      useExisting: PipeComponent
    },
    ...CompDetailBaseComponentProviders
  ]
})
export class PipeComponent extends CompDetailBaseComponent implements OnInit, ISaveChanges {
  public codeTrigger = new ReplaySubject<void>(1);
  public geomTrigger = new ReplaySubject<void>(1);
  public npsTrigger = new ReplaySubject<void>(1);
  public pipeScheduleTrigger = new ReplaySubject<void>(1);
  public odTrigger = new ReplaySubject<void>(1);
  public nstTrigger = new ReplaySubject<void>(1);
  public specTrigger = new ReplaySubject<void>(1);
  public facilityTrigger = new ReplaySubject<void>(1);
  public locationTrigger = new ReplaySubject<void>(1);
  public nominalThicknessTrigger = new ReplaySubject<void>(1);
  public nozzTminBasisTrigger = new ReplaySubject<void>(1);

  public nozzleParentOptions: Array<E2gSelectOption> = [];

  public constructor(
    @Inject(SLIDE_OUT_DATA) slideOutData: ComponentSlideoutInput,
    @Inject(BUSINESS_RULES) businessRulesService: BusinessRulesDefinition
  ) {
    super(slideOutData, businessRulesService);
  }

  public ngOnInit(): void {
    this.setupComponentData(() => {
      if (this.isNozzle()) {
        if (this.canUseAdvancedCalculaton()) {
          this.nozzleParentOptions = this.getNozzleParentOptions(this.slideOutData.optionsData!);
        } else {
          // Accounts for nozzles that previously could use Advanced Calculations
          this.dataHandler.data.useAdvancedCalculation = false;
        }
      }
    });

    // Used on Pipe Schedule select dropdown.
    // Triggered when geomType changes.
    this.geomTrigger.subscribe(() => {
      if (this.dataHandler.data.geomType === 'MI') {
        this.dataHandler.data.rbi = false;
      }
    });
  }

  private getNozzleParentOptions(
    optionsData: Record<string, { name: string; assetId: string; type: string }>
  ): Array<E2gSelectOption> {
    return Object.entries(optionsData)
      .filter(([, value]) => value.type === 'HEAD' || value.type == 'SHELL')
      .map(([key, value]) => ({ value: key, label: value.name }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  protected override setupNewComponent(asset: EquipmentDto): ComponentRecordDto {
    const compType = this.slideOutData.newType!;
    const calculatable = compType !== 'NOZZLE';
    const rbiCalculatable = compType !== 'NOZZLE' && compType !== 'FITTING';
    const component = setupNewComponent(asset, this.assetConfig, compType, calculatable, rbiCalculatable);

    if (!calculatable) {
      component.isSpecifiedThickness = true;
    }

    return component;
  }

  protected override modifyDtoBeforeSave(dto: ComponentRecordDto): ComponentRecordDto {
    if (this.isNozzle()) {
      dto = this.removeNozzleDefaultValues(dto);

      if (!dto.useAdvancedCalculation) {
        dto.useMawpApproach = false;
      }
    }

    return dto;
  }

  private removeNozzleDefaultValues(dto: ComponentRecordDto): ComponentRecordDto {
    //Needed because these defaults will not refresh unless advanced tab is navigated to...
    //Using default system that the sages forms uses will be the more permanent solution
    //Could be used as a way to remove all defaults before being saved
    const nozzPropsWithDefaults = [
      'nozzleNeckMinimumThicknessBasis',
      'nozzleNeckMinimumThickness',
      'nozzleReinforcementMinimumThicknessBasis',
      'grooveWelds'
    ];

    dto.defaultProperties
      ?.filter((x) => nozzPropsWithDefaults.includes(x))
      .forEach((prop) => {
        (dto as any)[prop] = undefined;
      });

    return dto;
  }

  protected afterRevertChanges(): void {
    //This will reset the nozzle defaults after a revert has been made
    setTimeout(() => {
      this.businessRulesService.start();
    });
  }

  public canUseAdvancedCalculaton(): boolean {
    return (
      this.isNozzle() && ![AssetTypes.StorageTank, AssetTypes.AirCooler].includes(this.dataHandler.data.assetType!)
    );
  }

  public isReducer(): boolean {
    return (
      this.isFitting() &&
      [FittingTypes.REDUCER, FittingTypes.RED_ELBOW, FittingTypes.RED_TEE].indexOf(this.dataHandler.data.fittingType!) >
        -1
    );
  }

  public getGeometryLeftHeading(baseHeading: string): string {
    if (this.isFitting()) {
      switch (this.dataHandler.data.fittingType) {
        case FittingTypes.REDUCER:
        case FittingTypes.RED_ELBOW:
          baseHeading = 'Input ' + baseHeading;
          break;
        case FittingTypes.RED_TEE:
          baseHeading = 'Run ' + baseHeading;
          break;
      }
    }
    return baseHeading;
  }

  public getGeometryRightHeading(baseHeading: string): string {
    switch (this.dataHandler.data.fittingType) {
      case FittingTypes.REDUCER:
      case FittingTypes.RED_ELBOW:
        baseHeading = 'Output ' + baseHeading;
        break;
      case FittingTypes.RED_TEE:
        baseHeading = 'Branch ' + baseHeading;
        break;
    }

    return baseHeading;
  }

  public changeNsPipe(): void {
    this.dataHandler.silentClearValue('outerDiameter');
    this.dataHandler.silentClearValue('nominalThickness');
    this.dataHandler.silentClearValue('outerDiameter2');
    this.dataHandler.silentClearValue('nominalThickness2');
    if (!this.dataHandler.data.nonStandardTube) {
      this.nstTrigger.next();
    }
  }

  public rbiTabVisible(): boolean {
    return (
      this.rbiEnabled &&
      (this.dataHandler.data.eqRbi ?? false) &&
      !this.isNozzle() &&
      !this.isFitting() &&
      this.dataHandler.data.geomType !== 'MI'
    );
  }

  public rbiDiagVisible(): boolean {
    return (this.dataHandler.data.rbi ?? false) && this.rbiTabVisible();
  }

  public isFitting(): boolean {
    return this.dataHandler.data.compType === 'FITTING';
  }

  public isTube(): boolean {
    return this.dataHandler.data.compType === 'TUBE';
  }

  public isNozzle(): boolean {
    return this.dataHandler.data.compType === 'NOZZLE';
  }
}
