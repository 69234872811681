import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AssetDetailTabs } from 'src/app/asset-module/detail/detail.component';
import { UnitOfMeasure } from 'src/app/models/enums/unit-of-measure';

import { SourceLocation } from '../models/Enums/source-location';
import { AssetRouteData } from './route-params.service';

@Injectable({
  providedIn: 'root'
})
export class AssetDetailsNavService {
  public constructor(private router: Router) {}

  public navAssetDetails(
    unitKey: string,
    assetKey: string,
    unitOfMeasure: string | UnitOfMeasure,
    activeTabId: AssetDetailTabs = AssetDetailTabs.Asset,
    subTab: number = 1
  ): void {
    this.router.navigate([
      'assets/detail',
      {
        unitKey,
        equipmentKey: assetKey,
        unitOfMeasure:
          typeof unitOfMeasure == 'number' || !Number.isNaN(Number(unitOfMeasure))
            ? UnitOfMeasure[unitOfMeasure as number]
            : unitOfMeasure,
        activeTabId,
        sourceLocation: SourceLocation.AssetTree,
        subTab
      }
    ]);
  }

  public resetAssetTab(assetRouteData: AssetRouteData, tabId: number, subTabId?: number): void {
    this.navAssetDetails(
      assetRouteData.unitKey,
      assetRouteData.equipmentKey,
      assetRouteData.unitOfMeasure,
      tabId,
      subTabId
    );
  }
}
