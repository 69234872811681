<app-slide-out-container>
  <ul ngbNav #nav="ngbNav" [(activeId)]="activeTabId" class="nav-tabs header-table mt-0" (navChange)="tabChange()">
    <li [ngbNavItem]="1">
      <a ngbNavLink>Input</a>
      <ng-template ngbNavContent>
        <div class="container-padding h-100">
          <div class="row h-100">
            <div class="col h-100 pr-0 overflow-auto">
              <div [ngSwitch]="dmlType" class="d-flex flex-col h-100">
                <app-dml-thinning
                  *ngSwitchCase="1"
                  id="damageMonitoringLocationLocalThinArea"
                  [dmlType]="dmlType!"
                  [assetCache]="assetCache!"
                  [refreshData]="refreshData"
                  (showUpload)="showUpload($event)"
                ></app-dml-thinning>
                <app-dml-thinning
                  *ngSwitchCase="2"
                  id="damageMonitoringLocationGeneralThinning"
                  [dmlType]="dmlType!"
                  [assetCache]="assetCache!"
                  [refreshData]="refreshData"
                  (showUpload)="showUpload($event)"
                ></app-dml-thinning>
                <app-dml-cracking
                  *ngSwitchCase="3"
                  id="damageMonitoringLocationCracking"
                  [assetCache]="assetCache!"
                  [refreshData]="refreshData"
                ></app-dml-cracking>
                <app-dml-pitting
                  *ngSwitchCase="4"
                  id="damageMonitoringLocationPitting"
                  [assetCache]="assetCache!"
                  [refreshData]="refreshData"
                ></app-dml-pitting>
                <app-dml-creep
                  *ngSwitchCase="5"
                  id="damageMonitoringLocationCreep"
                  [assetCache]="assetCache!"
                  [refreshData]="refreshData"
                ></app-dml-creep>
                <app-dml-dent
                  *ngSwitchCase="6"
                  id="damageMonitoringLocationDent"
                  [assetCache]="assetCache!"
                  [refreshData]="refreshData"
                ></app-dml-dent>

                <div *ngIf="displayUpload">
                  <div *ngIf="editing" class="d-flex flex-row align-items-center mb-2">
                    <button
                      *ngIf="!readOnly"
                      id="btnUpload"
                      class="btn e2g-btn-outline-primary mr-4"
                      (click)="uploadDamageMonitoringLocationData()"
                    >
                      <fa-icon class="icon mr-2" [icon]="faUpload"></fa-icon>Upload File
                    </button>

                    <div id="divFilename" class="mr-2">Grid Data:</div>
                    {{ fileName }}
                  </div>
                </div>
              </div>
            </div>

            <div
              id="divGraph"
              class="col-6 h-100"
              *ngIf="uploadErrors.length === 0 && (dmlType === 1 || dmlType === 2); else error"
            >
              <app-dml-graph
                *ngIf="!displayDmlGraph() && fileId"
                [dmlType]="dmlType!"
                [dmlFileId]="fileId"
              ></app-dml-graph>
            </div>
            <ng-template #error>
              <div class="col-6 h-100" *ngIf="slideOutData.type === 1 || slideOutData.type === 2">
                <h2>Errors</h2>
                <div class="d-flex mt-2">
                  <div>
                    <ul id="errors">
                      <li *ngFor="let error of uploadErrors">{{ error }}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="2" *ngIf="showOutputTab()">
      <a ngbNavLink>Output</a>
      <ng-template ngbNavContent>
        <div class="pr-3 pl-3 h-100">
          <div class="row h-100 mt-2">
            <div class="col-12 h-100">
              <div *ngIf="displayDataChangedWarning" class="ml-3">
                <fa-icon class="exclamationTriangle fa-xl" [icon]="faExclamationTriangle" [fixedWidth]="true">
                </fa-icon>
                <label class="warning-input-data-changed ml-2"
                  >Some input data has changed since the last calculation was run.</label
                >
              </div>
              <ul ngbNav #nav="ngbNav" class="sub-nav-tabs ml-3 my-2">
                <li [ngbNavItem]="1">
                  <a ngbNavLink>Summary</a>
                  <ng-template ngbNavContent>
                    <div class="container-padding h-100">
                      <div class="row h-100">
                        <div class="col-12 h-100 overflow-auto">
                          <div [ngSwitch]="dmlType">
                            <app-dml-local-thin-area-summary
                              #damageMonitoringLocationSummary
                              *ngSwitchCase="1"
                              id="damageMonitoringLocationLocalThinAreaSummary"
                              [damageMonitoringLocationId]="slideOutData.id"
                            ></app-dml-local-thin-area-summary>
                            <app-dml-general-thinning-summary
                              #damageMonitoringLocationSummary
                              *ngSwitchCase="2"
                              id="damageMonitoringLocationGeneralThinningSummary"
                              [damageMonitoringLocationId]="slideOutData.id"
                            ></app-dml-general-thinning-summary>
                            <app-dml-cracking-summary
                              #damageMonitoringLocationSummary
                              *ngSwitchCase="3"
                              id="damageMonitoringLocationCrackingSummary"
                              [damageMonitoringLocationId]="slideOutData.id"
                            ></app-dml-cracking-summary>
                            <app-dml-pitting-summary
                              #damageMonitoringLocationSummary
                              *ngSwitchCase="4"
                              id="damageMonitoringLocationPittingSummary"
                              [damageMonitoringLocationId]="slideOutData.id"
                            ></app-dml-pitting-summary>
                            <app-dml-creep-summary
                              #damageMonitoringLocationSummary
                              *ngSwitchCase="5"
                              id="damageMonitoringLocationCreepSummary"
                              [damageMonitoringLocationId]="slideOutData.id"
                            ></app-dml-creep-summary>
                            <app-dml-dent-summary
                              #damageMonitoringLocationSummary
                              *ngSwitchCase="6"
                              id="damageMonitoringLocationDentSummary"
                              [damageMonitoringLocationId]="slideOutData.id"
                            ></app-dml-dent-summary>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </li>
                <li [ngbNavItem]="2" *ngIf="showReportTab">
                  <a ngbNavLink>Report</a>
                  <ng-template ngbNavContent>
                    <app-pdf-viewer [dataReader]="getReport" [filename]="reportFileName"></app-pdf-viewer>
                  </ng-template>
                </li>
              </ul>

              <div [ngbNavOutlet]="nav" style="height: calc(100% - 62px)"></div>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav" style="height: calc(100% - 62px)"></div>
</app-slide-out-container>

<ng-template #templateUploadComponent>
  <app-upload
    [getUploadData]="getUploadData"
    [queueLimit]="queueLimit"
    [allowedMimeTypes]="allowedMimeTypes"
    (onSuccessItem)="successItem($event)"
    (onErrorItem)="errorItem($event)"
  >
  </app-upload>
</ng-template>

<ng-template #calculateButton>
  <button
    *ngIf="showCalculate() && !readOnly"
    id="btnCalculate"
    class="btn e2g-btn-outline-primary mr-2"
    (click)="calculate()"
  >
    Calculate
  </button>
</ng-template>
