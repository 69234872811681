export enum CrackingMechanisms {
  'Caustic' = 1,
  'Amine' = 2,
  'SSC H2S' = 3,
  'HIC SOHIC H2S' = 4,
  'Carbonate' = 5,
  'Polythionic Acid' = 6,
  'Chloride' = 7,
  'HIC SOHIC HF' = 8,
  'HSC HF' = 9,
  'Other' = 10
}
