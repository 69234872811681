import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams, IRowNode } from 'ag-grid-community';

export interface IGridCellButtonParams<T> extends ICellRendererParams {
  label: string;
  readOnly?: boolean;
  onClick: (event: IGridCellButtonClickEvent<T>) => void;
}

export interface IGridCellButtonClickEvent<T> {
  rowIndex: number;
  rowNode: IRowNode<T>;
}

@Component({
  selector: 'app-grid-button',
  templateUrl: './grid-cell-button.component.html',
  styleUrls: ['./grid-cell-button.component.css']
})
export class GridCellButtonComponent<T> implements ICellRendererAngularComp {
  public params!: IGridCellButtonParams<T>;
  public label: string = '';
  public disabled: boolean = false;

  public agInit(params: IGridCellButtonParams<T>): void {
    this.init(params);
  }

  public refresh(params: IGridCellButtonParams<T>): boolean {
    this.params = params;
    this.init(params);

    return true;
  }

  public onClick(): void {
    if (this.params.onClick instanceof Function) {
      const event = {
        rowIndex: this.params.rowIndex,
        rowNode: this.params.node
      };
      this.params.onClick(event);
    }
  }

  private init(params: IGridCellButtonParams<T>): void {
    this.params = params;
    this.label = this.params.label;
    this.disabled = this.params.readOnly!;
  }
}
