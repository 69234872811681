import { ComponentDetailsDto } from 'src/app/models/component-details-dto';

export class DmlAssetDataCache {
  public data?: DmlAssetDataDto;
}

export interface DmlAssetDataDto {
  permissions: {
    readOnly: boolean;
  };
  components: Array<ComponentDetailsDto>;
}
