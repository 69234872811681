import { Injectable } from '@angular/core';
import { of } from 'rxjs';

import { getSharedRbiBusinessRules } from '../component-helper-service';
import { MinimumThicknessChoice } from '../models/enums/minimum-thickness-choice';
import { BusinessRulesServiceBase } from '../sage-common-module/BusinessRulesServiceBase';
import { BusinessRuleValueList } from '../sage-common-module/models/business-rule-value-list';
import { BusinessRulesDefinition } from '../sage-common-module/models/business-rules-definition';

@Injectable()
export class NcpvCompBusinessRulesService extends BusinessRulesServiceBase implements BusinessRulesDefinition {
  public get valueData(): BusinessRuleValueList {
    if (this.data === undefined) {
      throw 'init must be called before valueData()';
    } else {
      return {
        ...getSharedRbiBusinessRules(this.data),
        shortSideJointEfficiency: {
          getDefaultValue: () => of(1)
        },
        longSideJointEfficiency: {
          getDefaultValue: () => of(1)
        },
        endPlateJointEfficiency: {
          getDefaultValue: () => of(1)
        },
        axialLength: {
          getRequired: () => true
        },
        shortPlateInsideLength: {
          getRequired: () => true
        },
        shortPlateThickness: {
          getRequired: () => true
        },
        longPlateInsideLength: {
          getRequired: () => true
        },
        longPlateThickness: {
          getRequired: () => true
        },
        endPlateThickness: {
          getRequired: () => true
        },
        shortPlateCorrosionAllowance: {
          getRequired: () => true
        },
        longPlateCorrosionAllowance: {
          getRequired: () => true
        },
        endPlateCorrosionAllowance: {
          getRequired: () => true
        },
        shortPlateSpecifiedMinimumThickness: {
          getRequired: () => this.data.governingMinimumThickness == MinimumThicknessChoice.Specified
        },
        longPlateSpecifiedMinimumThickness: {
          getRequired: () => this.data.governingMinimumThickness == MinimumThicknessChoice.Specified
        },
        endPlateSpecifiedMinimumThickness: {
          getRequired: () => this.data.governingMinimumThickness == MinimumThicknessChoice.Specified
        }
      };
    }
  }
}
