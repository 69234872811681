import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { AuditLogReportData } from 'src/app/reporting-module/models/log-report-data';
import { NotificationService } from 'src/app/shared-module/services/notification.service';

import { JobNotificationService } from '../../notifications-module/services/job-notifications.service';
import { DialogService } from '../../shared-module/dialog.service';
import { DialogButtons } from '../../shared-module/models/dialog-buttons';
import { DialogData } from '../../shared-module/models/dialog-data';
import { DialogResult } from '../../shared-module/models/dialog-result';
import { ReportingDataService } from '../reporting.data.service';
import { AuditLogReportDialogComponent } from './audit-log-report-dialog/audit-log-report-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class AuditLogReportService {
  public constructor(
    private dialogService: DialogService,
    private auditLogReportDataService: ReportingDataService,
    private notificationService: NotificationService,
    private jobNotificationService: JobNotificationService
  ) {}

  public run(): void {
    const formData = {
      startDate: undefined,
      endDate: undefined,
      selectedUsers: [],
      timeZoneOffset: new Date().getTimezoneOffset()
    } as AuditLogReportData;

    const dlgData: DialogData = {
      title: 'Audit Log Report',
      buttons: DialogButtons.YesCancel,
      component: AuditLogReportDialogComponent,
      componentData: formData,
      yesButtonText: 'Save Report',
      width: '750px',
      height: '515px'
    };

    this.dialogService
      .display(dlgData)
      .pipe(
        concatMap((dlgResult) => {
          if (dlgResult === DialogResult.yes) {
            this.notificationService.showInfo('Generating Audit Log report');
            return this.auditLogReportDataService.generateAuditLogReport(formData);
          }
          return of(false);
        })
      )
      .subscribe(() => this.jobNotificationService.refreshNotifications());
  }
}
