<e2g-date-input
  *ngIf="!showOnlyWhenRequired || isRequired()"
  [id]="'date-' + propName"
  [(ngModel)]="value"
  [heading]="heading"
  placeholder="mm/dd/yyyy"
  [isDefaultValue]="isDefaultValue()"
  [showReset]="showReset()"
  (reset)="reset()"
  (change)="onChange()"
  [readonly]="!!isReadOnly()"
  [requiredForCalc]="isRequired()"
  [disabled]="disabled"
  [labelPosition]="getLabelPosition()"
  [warnings]="getWarnings()"
>
</e2g-date-input>
