import { ColDef } from 'ag-grid-community';
import { DmlStatuses } from 'src/app/dmls-module/models/Enums/dml-statuses';
import { DmlTypes } from 'src/app/dmls-module/models/Enums/dml-types';
import { buildEnumColDef } from 'src/app/grid-module/column-builders/build-enum';

export function getDmlGridColumns(): Array<ColDef> {
  return [
    {
      headerName: 'Id',
      field: 'id',
      hide: true
    },
    {
      headerName: 'FileId',
      field: 'fileId',
      hide: true
    },
    {
      ...buildEnumColDef('Type', 'type', DmlTypes),
      width: 250
    },
    {
      ...buildEnumColDef('Status', 'status', DmlStatuses),
      width: 180
    }
  ];
}
