export const PRODUCT_NAME = 'scra'; // Product
export const ADMIN = 'admin'; // Feature
export const COMPANY_ADMIN = 'company admin'; // Feature
export const VERSION_SWITCH = 'version_switch'; // Feature
export const IMPORT_EXPORT_JSON = 'pmsage_import_export_json'; // Feature
export const SCRA_RATES = 'PM_IDMS_SCRA_RATES'; // Product
export const IDMS = 'PM_IDMS'; // Product
export const SAGE = 'PMSAGE'; // Product
export const DML = 'PM_DML'; // Product
export const RBI = 'PM_RBI'; // Product

export const CAN_DELETE_ACTIVITY = 'scra.activity.delete'; // Permissions For Product(scra)
export const CAN_EDIT_ACTIVITY = 'scra.activity.edit'; // Permissions For Product(scra)
export const CAN_DELETE_CIRCUIT = 'scra.circuit.delete'; // Permissions For Product(scra)
export const CAN_EDIT_CIRCUIT = 'scra.circuit.edit'; // Permissions For Product(scra)
export const CAN_EDIT_PLANT = 'scra.plant.edit'; // Permissions For Product(scra)
export const CAN_DELETE_PLANT = 'scra.plant.delete'; // Permissions For Product(scra)
export const CAN_EDIT_UNIT = 'scra.unit.edit'; // Permissions For Product(scra)
export const CAN_DELETE_UNIT = 'scra.unit.delete'; // Permissions For Product(scra)
export const CAN_DELETE_EQUIPMENT = 'scra.equipment.delete'; // Permissions For Product(scra)
export const CAN_EDIT_EQUIPMENT = 'scra.equipment.edit'; // Permissions For Product(scra)
export const CAN_DELETE_CML = 'scra.cml.delete'; // Permissions For Product(scra)
export const CAN_EDIT_CML = 'scra.cml.edit'; // Permissions For Product(scra)
export const CAN_DELETE_COMPONENT = 'scra.component.delete'; // Permissions For Product(scra)
export const CAN_EDIT_COMPONENT = 'scra.component.edit'; // Permissions For Product(scra)
export const CAN_DELETE_FINDINGS = 'scra.findings.delete'; // Permissions For Product(scra)
export const CAN_DELETE_DAMAGEMONITORINGLOCATION = 'scra.dml.delete'; // Permissions For Product(scra)
export const CAN_EDIT_FINDINGS = 'scra.findings.edit'; // Permissions For Product(scra)
export const CAN_EDIT_DAMAGEMONITORINGLOCATION = 'scra.dml.edit'; // Permissions For Product(scra)
export const CAN_IMPORT = 'scra.import'; // Permissions For Product(scra)
export const CAN_VIEW_DIAGS = 'scra.diags'; // Permissions For Product(scra)
export const CAN_FORCE_CALC = 'scra.calc.force'; // Permissions For Product(scra)

export const CAN_MANAGE_ROLES = 'scra.manage.roles'; // Permissions For Product(scra)
export const CAN_MANAGE_INSPECTIOR_LIST = 'scra.manage.inspectors'; // Permissions For Product(scra)
export const CAN_MANAGE_INSPECTION_ACTIVITIES = 'scra.manage.inspection.activities'; // Permissions For Product(scra)

export const CAN_MANAGE_INSPECTION_EFFECTIVENESS = 'scra.inspeff.manage'; // Permissions For Product(rbi)
export const CAN_MANAGE_RISK_MATRIX_CONFIGURATION = 'scra.matrix.manage'; // Permissions For Product(rbi)

//SAGE Role Permissions
export const CAN_ACCESS_ASSET_SAGE_FOLDER = 'scra.sage.folder.access';
export const CAN_EDIT_ASSET_SAGE_FOLDER = 'scra.sage.folder.edit';
export const CAN_APPROVE_MODULE_CALCULATIONS = 'scra.sage.calculations.approve';
