import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';

import { valuePostiveIntegerMessage } from '../../utilities/validation-messages';

@Component({
  selector: 'app-next-inspection-selector',
  templateUrl: './next-inspection-selector.component.html'
})
export class NextInspectionSelectorComponent implements OnInit, OnChanges {
  @Input() public data!: any;
  @Input() public readonly!: boolean;
  @Input() public required: boolean = false;
  @Input() public refresh!: Observable<void>;
  @Output() public isValid = new EventEmitter<boolean>();
  @Output() public change = new EventEmitter<void>();

  public maximumIntervalRequired = false;
  public maximumIntervalErrors: Array<string> = [];
  private previousMaximumInterval?: number;

  public isMaximumInterval: boolean = false;

  public ngOnInit(): void {
    this.refresh?.subscribe(() => {
      this.setData();
    });
  }

  public ngOnChanges(): void {
    this.validateMaximumInterval();
    this.setData();
  }

  private setData(): void {
    this.isMaximumInterval = this.data.maximumInterval > 0;

    this.checkValidation();
  }

  public validateMaximumInterval(): void {
    this.maximumIntervalErrors = [];

    if (this.isMaximumInterval) {
      if (
        this.data.maximumInterval == undefined ||
        isNaN(this.data.maximumInterval) ||
        this.data.maximumInterval <= 0
      ) {
        this.maximumIntervalErrors.push(valuePostiveIntegerMessage());
      }
    }
    this.checkValidation();
  }

  public checkValidation(): void {
    this.isValid.emit(
      this.maximumIntervalErrors.length === 0 && (!this.required || this.isMaximumInterval || this.data.halfLife)
    );
  }

  public checkMaximumInterval(): void {
    if (!this.isMaximumInterval) {
      this.previousMaximumInterval = this.data.maximumInterval;
      this.data.maximumInterval = undefined;
    } else {
      this.data.maximumInterval = this.previousMaximumInterval;
    }

    this.validateMaximumInterval();
  }

  public onChangeHalfLife(): void {
    this.checkValidation();

    this.change.emit();
  }

  public onChangeMaximumInterval(): void {
    this.checkMaximumInterval();

    this.change.emit();
  }

  public onChangeIsMaximumInterval(): void {
    this.change.emit();
  }
}
