<div class="required form-section-header">
  Governing Corrosion Rate
  <fa-icon
    *ngIf="showResetForDefault(defaultProperties.governingCorrosionRate)"
    id="resetIcon"
    [icon]="resetIcon"
    class="ml-1 icon-secondary"
    (click)="resetGoverningCorrosionRate()"
  ></fa-icon>
</div>

<div class="d-flex flex-row justify-content-start flex-wrap mb-2 governingCorrosionRate">
  <div class="d-flex flex-column">
    <e2g-check-input
      id="inp-long"
      heading="Long"
      [readonly]="readonly"
      labelPosition="right"
      [(ngModel)]="model.isLongRateGoverning"
      (change)="validate()"
    ></e2g-check-input>

    <e2g-check-input
      id="inp-short"
      heading="Short"
      [readonly]="readonly"
      labelPosition="right"
      [(ngModel)]="model.isShortRateGoverning"
      (change)="validate()"
    ></e2g-check-input>

    <e2g-check-input
      id="inp-linear"
      heading="Best Fit"
      [readonly]="readonly"
      labelPosition="right"
      [(ngModel)]="model.isLinearRateGoverning"
      (change)="validate()"
    ></e2g-check-input>
  </div>

  <div class="d-flex flex-column">
    <div *ngIf="hasProductSCRA">
      <e2g-check-input
        id="inp-scraMeasLong"
        heading="SCRA Measured Long"
        [readonly]="readonly"
        labelPosition="right"
        [(ngModel)]="model.isScraMeasuredLongRateGoverning"
        (change)="validate()"
      ></e2g-check-input>

      <e2g-check-input
        id="inp-scraMeasShort"
        heading="SCRA Measured Short"
        [readonly]="readonly"
        labelPosition="right"
        [(ngModel)]="model.isScraMeasuredShortRateGoverning"
        (change)="validate()"
      ></e2g-check-input>
    </div>

    <div class="d-flex flex-column">
      <e2g-check-input
        id="check-estimated-rate"
        heading="Estimated Rate {{ corrosionRateUom }}"
        [readonly]="readonly"
        labelPosition="right"
        [(ngModel)]="model.isEstimatedCorrosionRate"
        (change)="validate()"
      ></e2g-check-input>

      <e2g-text-input
        id="text-estimated-corrosion-rate"
        type="number"
        class="inp-under-check"
        [required]="false"
        [hidden]="!model.isEstimatedCorrosionRate"
        [(ngModel)]="model.estimatedCorrosionRate"
        [readonly]="readonly"
        [warnings]="getWarningsEstimatedCorrosionRate()"
        [errors]="getErrorsEstimatedCorrosionRate()"
        (change)="validate()"
      ></e2g-text-input>
    </div>
  </div>

  <div *ngIf="hasProductSCRA" class="d-flex flex-column">
    <e2g-check-input
      id="inp-scraStatLong"
      heading="SCRA Statistical Long"
      [readonly]="readonly"
      labelPosition="right"
      [(ngModel)]="model.isScraStatisticalLongRateGoverning"
      (change)="validate()"
    ></e2g-check-input>

    <e2g-check-input
      id="inp-scraStatShort"
      heading="SCRA Statistical Short"
      [readonly]="readonly"
      labelPosition="right"
      [(ngModel)]="model.isScraStatisticalShortRateGoverning"
      (change)="validate()"
    ></e2g-check-input>
  </div>
</div>

<hr class="separator" />

<div class="required form-section-header mb-2">
  Establishing Corrosion Rate
  <fa-icon
    *ngIf="showResetForDefault(defaultProperties.establishingCorrosionRate)"
    [icon]="resetIcon"
    class="ml-1 icon-secondary"
    (click)="resetEstablishingCorrosionRate()"
  ></fa-icon>
</div>

<div class="d-flex flex-col">
  <div class="radio-item">
    <input
      type="radio"
      class="e2g-radio"
      [(ngModel)]="model.establishingCorrosionRateType"
      [value]="establishingCorrosionRate.ProbableRate"
      [readonly]="readonly"
      (change)="validate()"
    />
    <label>Probable Rate {{ corrosionRateUom }}</label>
    <fa-icon
      *ngIf="showEstablishingProbableRateOverrideInfoIcon"
      [icon]="infoIcon"
      class="ml-1 icon-secondary-info"
      matTooltip="Estimated Rate will be used"
      size="lg"
    ></fa-icon>
  </div>
  <div *ngIf="showEstablishingProbableRateInput" class="radio-sub-input">
    <e2g-text-input
      type="number"
      [(ngModel)]="model.establishingProbableRate"
      [readonly]="readonly"
      [isDefaultValue]="model.isDefaultValue(defaultProperties.establishingCorrosionRate)"
      [errors]="getProbableRateErrors()"
      (change)="validate()"
    ></e2g-text-input>
  </div>
  <div class="radio-item">
    <input
      type="radio"
      class="e2g-radio"
      [(ngModel)]="model.establishingCorrosionRateType"
      [value]="establishingCorrosionRate.TimeInterval"
      [readonly]="readonly"
      (change)="validate()"
    />
    <label>Time Interval (days)</label>
  </div>
  <div *ngIf="showEstablishingTimeIntervalInput" class="radio-sub-input">
    <e2g-text-input
      type="PositiveInteger"
      [(ngModel)]="model.establishingTimeInterval"
      [readonly]="readonly"
      [isDefaultValue]="model.isDefaultValue(defaultProperties.establishingCorrosionRate)"
      [errors]="getTimeIntervalErrors()"
      (change)="validate()"
    ></e2g-text-input>
  </div>
</div>

<hr class="separator" />

<div class="required form-section-header e2g-primary-grey">
  CML Due Date Basis
  <fa-icon
    *ngIf="showResetForDefault(defaultProperties.cmlDueDateBasis)"
    id="resetIcon"
    [icon]="resetIcon"
    class="ml-1 icon-secondary"
    (click)="resetCmlDueDateBasis()"
  ></fa-icon>
</div>

<app-next-inspection-selector
  [data]="model"
  [readonly]="readonly"
  [required]="true"
  [refresh]="refreshDueDateBasis"
  (isValid)="dueDateBasisValid = $event; validate()"
></app-next-inspection-selector>

<hr class="separator" />

<div class="required form-section-header mb-2">
  Governing Minimum Thickness
  <fa-icon
    *ngIf="showResetForDefault(defaultProperties.governingMinimumThickness) && !isAssetTypeOther()"
    id="resetIcon"
    [icon]="resetIcon"
    class="ml-1 icon-secondary"
    (click)="resetGoverningMinimumThickness()"
  ></fa-icon>
</div>

<app-slide-input
  *ngIf="showMawpApproach && !isAssetTypeOther()"
  id="slide-useMawp"
  heading="Use MAWP Approach"
  [(ngModel)]="model.useMawpApproach"
  [readOnly]="readonly"
  (change)="validate()"
>
</app-slide-input>

<div class="mb-2">
  <e2g-check-input
    *ngIf="!isAssetTypeOther()"
    id="check-retirement"
    heading="Code Retirement Thickness"
    labelPosition="right"
    [readonly]="readonly"
    [disabled]="areTMinCheckboxesDisabled()"
    [(ngModel)]="model.isRetirementThickness"
    (change)="validate()"
  ></e2g-check-input>

  <e2g-check-input
    *ngIf="!isAssetTypeOther()"
    id="check-structural"
    heading="Structural Required Thickness"
    labelPosition="right"
    [readonly]="readonly"
    [disabled]="areTMinCheckboxesDisabled()"
    [(ngModel)]="model.isStructuralRequiredThickness"
    (change)="validate()"
  ></e2g-check-input>

  <e2g-check-input
    *ngIf="!isAssetTypeOther()"
    id="check-corroded"
    heading="Nominal - CA"
    labelPosition="right"
    [readonly]="readonly"
    [disabled]="areTMinCheckboxesDisabled()"
    [(ngModel)]="model.isCorrodedThickness"
    (change)="validate()"
  ></e2g-check-input>

  <e2g-check-input
    id="check-specified"
    heading="Specified Minimum Thickness {{ thicknessUom }}"
    labelPosition="right"
    [readonly]="readonly"
    [disabled]="areTMinCheckboxesDisabled() || isAssetTypeOther()"
    [(ngModel)]="model.isSpecifiedThickness"
    (change)="validate()"
  ></e2g-check-input>

  <div class="inp-under-check">
    <e2g-text-input
      *ngIf="model.isSpecifiedThickness"
      id="inp-specifiedMinimumThickness"
      [(ngModel)]="model.specifiedMinimumThickness"
      [readonly]="readonly"
      [disabled]="areTMinCheckboxesDisabled()"
      (change)="validate()"
    >
    </e2g-text-input>
  </div>
</div>
