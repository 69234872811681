import { Component, Input } from '@angular/core';
import { DateOnlyUtility, dateOnly } from '@equityeng/e2g-ng-ui';

@Component({
  selector: 'app-evaluation-date-field',
  templateUrl: './evaluation-date-field.component.html',
  styleUrls: ['./evaluation-date-field.component.css']
})
export class EvaluationDateFieldComponent {
  @Input() public set evaluationDate(date: Date | undefined) {
    this.evaluationDateonly = date ? DateOnlyUtility.convertDateToDateOnly(date!) : undefined;
  }

  public evaluationDateonly?: dateOnly;
}
