export enum InspectionDrivers {
  HalfLife = 1,
  MaximumInterval = 2,
  Specified = 3,
  Establishing = 4
}

export function getInspectionDriver(type: InspectionDrivers): string {
  switch (type) {
    case InspectionDrivers.HalfLife:
      return 'Half Life';
    case InspectionDrivers.MaximumInterval:
      return 'Maximum Interval';
    case InspectionDrivers.Specified:
      return 'Specified';
    case InspectionDrivers.Establishing:
      return 'Establishing';
  }
}
