export function anyIdsInCommon(idList: Array<string> | undefined, list2: Array<string> | undefined): boolean {
  return (idList ?? []).some((id) => (list2 ?? []).includes(id));
}

export function removeFromArrayIfExists<T>(array: Array<T>, item: T): void {
  const index = array.indexOf(item, 0);
  if (index > -1) {
    array.splice(index, 1);
  }
}

export function addToArrayIfNotInArray<T>(array: Array<T>, item: T): void {
  if (array.indexOf(item) < 0) {
    array.push(item);
  }
}

export function unionArrays<T>(array: Array<T>, array2: Array<T>): Array<T> {
  return [...new Set([...array, ...array2])];
}

export function flattenArray<T>(array: Array<Array<T>>): Array<T> {
  return array.reduce((accumulator, value) => accumulator.concat(value), []);
}

export function flattenArraysIntoDistinctArray<T>(array: Array<Array<T>>): Array<T> {
  return removeDuplicateItems(flattenArray(array));
}

export function removeDuplicateItems<T>(array: Array<T>): Array<T> {
  return [...new Set(array)];
}

export function generateNumberArray(length: number): Array<number> {
  return Array.from(Array(length).keys());
}
