import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  faArrowUp,
  faBan,
  faCheckCircle,
  faCopy,
  faFileMedical,
  faTimesCircle,
  faTrashXmark
} from '@fortawesome/pro-solid-svg-icons';
import { FileItem, FileUploader, ParsedResponseHeaders } from 'ng2-file-upload';
import { Observable } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { CompanyService } from 'src/app/company.service';
import { OnDestroyBaseComponent } from 'src/app/on-destroy-base-component/on-destroy-base-component';

export interface UploadItemData {
  item: FileItem;
  response: string;
  status: number;
  headers: ParsedResponseHeaders;
}

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css']
})
export class UploadComponent extends OnDestroyBaseComponent implements OnInit {
  @Input() public queueLimit?: number;
  @Input() public title?: string;
  @Input() public allowedMimeTypes?: Array<string>;
  @Input() public getUploadData!: () => Observable<{ token: string; uploadUrl: string }>;
  @Input() public onCompleteAll?: () => void;
  @Output() public onSuccessItem = new EventEmitter<UploadItemData>();
  @Output() public onErrorItem = new EventEmitter<UploadItemData>();

  public uploader!: FileUploader;
  public hasBaseDropZoneOver: boolean = false;
  public hasAnotherDropZoneOver: boolean = false;

  public fileText: string = 'files';
  public uploadButtonText = 'Upload All';
  public cancelButtonText = 'Cancel All';
  public removeButtonText = 'Remove All';

  public status: number = 200;

  public faBan = faBan;
  public faCopy = faCopy;
  public faArrowUp = faArrowUp;
  public faTrashXmark = faTrashXmark;
  public faCheckCircle = faCheckCircle;
  public faTimesCircle = faTimesCircle;
  public faFileMedical = faFileMedical;

  public constructor(private companyService: CompanyService) {
    super();
  }

  public ngOnInit(): void {
    this.uploader = new FileUploader({
      queueLimit: this.queueLimit,
      allowedMimeType: this.allowedMimeTypes,
      url: ''
    });

    if (this.queueLimit && this.queueLimit === 1) {
      this.fileText = 'file';
      this.uploadButtonText = 'Upload';
      this.cancelButtonText = 'Cancel';
      this.removeButtonText = 'Remove';
    }

    this.uploader.onBeforeUploadItem = (item: any): void => {
      this.getUploadData()
        .pipe(take(1))
        .subscribe((x) => {
          item.withCredentials = false;
          item.url = x.uploadUrl;
          this.uploader.authToken = 'Bearer ' + x.token;
        });
    };

    this.uploader.onCompleteAll = (): void => {
      if (this.onCompleteAll) {
        this.onCompleteAll();
      }
    };

    this.uploader.onSuccessItem = (
      item: FileItem,
      response: string,
      status: number,
      headers: ParsedResponseHeaders
    ): void => {
      this.onSuccessItem.emit({ item, response, status, headers });
    };

    this.uploader.onErrorItem = (
      item: FileItem,
      response: string,
      status: number,
      headers: ParsedResponseHeaders
    ): void => {
      item.isError = true;
      this.status = status;

      this.onErrorItem.emit({ item, response, status, headers });
    };

    this.companyService.selectedCompany.pipe(takeUntil(this.destroy)).subscribe(() => {
      this.uploader.clearQueue();
    });
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public onFileClick(event: Event): void {
    //Clears filename so you can upload the same file twice
    const element = event.target as HTMLInputElement;
    element.value = '';
  }

  public getAcceptedInputFiles(): string | undefined {
    if (this.allowedMimeTypes) {
      return this.allowedMimeTypes.join(', ');
    }
    return undefined;
  }
}
