import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@equityeng/auth';
import { take } from 'rxjs';

import { IDMS, SAGE } from './models/auth-constants';

@Component({
  selector: 'app-landing-target',
  template: ''
})
export class LandingTargetComponent {
  public constructor(private authService: AuthService, private router: Router) {
    this.authService.userAuthenticated$.pipe(take(1)).subscribe((user) => {
      const hasIdms = user.hasAccessToProduct(IDMS);
      const hasSage = user.hasAccessToProduct(SAGE);

      if ((hasSage && hasIdms) || hasIdms) {
        this.router.navigate(['assets']);
      } else if (hasSage) {
        this.router.navigate(['sage']);
      } else {
        this.router.navigate(['assets']);
      }
    });
  }
}
