import { Injectable } from '@angular/core';
import { filter, switchMap, tap } from 'rxjs/operators';
import { AuditLogReportData } from 'src/app/reporting-module/models/log-report-data';
import { ActionTypes } from 'src/app/shared-module/models/action-types';
import { NotificationService } from 'src/app/shared-module/services/notification.service';

import { JobNotificationService } from '../../notifications-module/services/job-notifications.service';
import { DialogService } from '../../shared-module/dialog.service';
import { DialogButtons } from '../../shared-module/models/dialog-buttons';
import { DialogData } from '../../shared-module/models/dialog-data';
import { DialogResult } from '../../shared-module/models/dialog-result';
import { ReportingDataService } from '../reporting.data.service';
import { AuditLogReportDialogComponent } from './audit-log-report-dialog/audit-log-report-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class AuditLogReportService {
  public constructor(
    private dialogService: DialogService,
    private auditLogReportDataService: ReportingDataService,
    private notificationService: NotificationService,
    private jobNotificationService: JobNotificationService
  ) {}

  public run(): void {
    const formData = {
      startDate: undefined,
      endDate: undefined,
      selectedUsers: [],
      timeZoneOffset: new Date().getTimezoneOffset()
    } as AuditLogReportData;

    const reportName = 'Audit Log Report'; //Also used as ID for notification replacement

    const dlgData: DialogData = {
      title: reportName,
      buttons: DialogButtons.YesCancel,
      component: AuditLogReportDialogComponent,
      componentData: formData,
      yesButtonText: 'Save Report',
      width: '750px',
      height: '515px'
    };

    this.dialogService
      .display(dlgData)
      .pipe(
        filter((dlgResult) => dlgResult === DialogResult.yes),
        tap(() => this.notificationService.showActionStarting(ActionTypes.Generate, reportName, reportName)),
        switchMap(() => this.auditLogReportDataService.generateAuditLogReport(formData))
      )
      .subscribe((success) => {
        if (success) {
          this.jobNotificationService.refreshNotifications();
        } else {
          //Only show error if Background process fails to start
          this.notificationService.showActionResult(success, ActionTypes.Generate, reportName, reportName);
        }
      });
  }
}
