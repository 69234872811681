import { E2gSelectOption } from '@equityeng/e2g-ng-ui';

export enum AssetTypes {
  AirCooler = 1,
  Boiler = 2,
  Compressor = 3,
  FiredHeater = 4,
  HorizontalVessel = 5,
  PipingSystem = 6,
  PressureReliefDevice = 7,
  Pump = 8,
  ShellTubeHeatExchanger = 9,
  Sphere = 10,
  StorageTank = 11,
  VerticalVessel = 12,
  Other = 13
}

export function getScraAssetType(type: AssetTypes): string {
  switch (type) {
    case AssetTypes.AirCooler:
      return 'Air Cooler';
    case AssetTypes.Boiler:
      return 'Boiler';
    case AssetTypes.Compressor:
      return 'Compressor';
    case AssetTypes.FiredHeater:
      return 'Fired Heater';
    case AssetTypes.HorizontalVessel:
      return 'Horizontal Vessel';
    case AssetTypes.PipingSystem:
      return 'Piping System';
    case AssetTypes.PressureReliefDevice:
      return 'Pressure Relief Device';
    case AssetTypes.Pump:
      return 'Pump';
    case AssetTypes.ShellTubeHeatExchanger:
      return 'Shell Tube Heat Exchanger';
    case AssetTypes.Sphere:
      return 'Sphere';
    case AssetTypes.StorageTank:
      return 'Storage Tank';
    case AssetTypes.VerticalVessel:
      return 'Vertical Vessel';
    case AssetTypes.Other:
      return 'Other';
    default:
      throw `Unknown Asset Type: ${type}`;
  }
}

export function getScraAssetTypes(): Array<E2gSelectOption> {
  return [
    getAssetTypeSelOpt(AssetTypes.AirCooler),
    getAssetTypeSelOpt(AssetTypes.Boiler),
    getAssetTypeSelOpt(AssetTypes.Compressor),
    getAssetTypeSelOpt(AssetTypes.FiredHeater),
    getAssetTypeSelOpt(AssetTypes.HorizontalVessel),
    getAssetTypeSelOpt(AssetTypes.PipingSystem),
    getAssetTypeSelOpt(AssetTypes.PressureReliefDevice),
    getAssetTypeSelOpt(AssetTypes.Pump),
    getAssetTypeSelOpt(AssetTypes.ShellTubeHeatExchanger),
    getAssetTypeSelOpt(AssetTypes.Sphere),
    getAssetTypeSelOpt(AssetTypes.StorageTank),
    getAssetTypeSelOpt(AssetTypes.VerticalVessel),
    getAssetTypeSelOpt(AssetTypes.Other)
  ];
}

export function getAssetTypeSelOpt(assetType: AssetTypes): E2gSelectOption {
  return <E2gSelectOption>{ label: getScraAssetType(assetType), value: assetType };
}

//This is used for pre-filtering on creation of a new sage module within an existing Asset
export function getSageAssetType(type: AssetTypes): string {
  switch (type) {
    case AssetTypes.AirCooler:
    case AssetTypes.Boiler:
    case AssetTypes.HorizontalVessel:
    case AssetTypes.Sphere:
    case AssetTypes.VerticalVessel:
      return 'Pressure Vessels';
    case AssetTypes.FiredHeater:
      return 'Fired Heaters';
    case AssetTypes.PipingSystem:
      return 'Piping and Boiler Tubes';
    case AssetTypes.PressureReliefDevice:
      return 'Pressure Relief Devices';
    case AssetTypes.ShellTubeHeatExchanger:
      return 'Heat Exchangers';
    case AssetTypes.StorageTank:
      return 'Tanks';
    default:
      return 'ALL';
  }
}
