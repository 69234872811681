import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService } from '@equityeng/auth';
import { BehaviorSubject, Observable, distinctUntilChanged, filter, switchMap, tap } from 'rxjs';

import { CalculationDataService } from './calculation-data.service';
import { CompanyService } from './company.service';
import { CalcUpdateDto, PlantManagerImpactedIdsDto, hasIdsInCommon } from './models/calculation-impacted-ids-dto';
import { CalculationMessageStatus } from './models/enums/calculation-message-status';
import { RefreshService } from './notifications-module/refresh.service';
import { StateService } from './state.service';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  private userKey!: string;
  private calcsStarted: Array<CalcUpdateDto> = [];
  private idsInEdit?: PlantManagerImpactedIdsDto;

  public alert: Observable<string | undefined> = new BehaviorSubject<string | undefined>(undefined).pipe(
    distinctUntilChanged()
  );
  public showPageAlert: Observable<boolean> = new BehaviorSubject<boolean>(false).pipe(distinctUntilChanged());
  private slideoutOpen: boolean = false;

  public constructor(
    private router: Router,
    private route: ActivatedRoute,
    private refreshService: RefreshService,
    private authService: AuthService,
    public companyService: CompanyService,
    private dataService: CalculationDataService,
    private stateService: StateService
  ) {}

  public init(): void {
    this.authService.userAuthenticated$
      .pipe(
        tap((user) => (this.userKey = user.userKey)),
        switchMap(() => this.companyService.selectedCompany),
        switchMap(() => this.dataService.getCalculationsInProgress()),
        tap((x) => {
          this.calcsStarted = x;
        }),
        switchMap(() => this.refreshService.calcUpdates)
      )
      .subscribe((x) => {
        let calc: CalcUpdateDto | undefined;
        switch (x.status) {
          case CalculationMessageStatus.New:
            this.calcsStarted.push(x);
            this.setAlert();
            break;
          case CalculationMessageStatus.InProgress:
            calc = this.calcsStarted.find((y) => x.messageId == y.messageId);
            if (calc) {
              calc.status = x.status;
            }
            break;
          default:
            //Item is finished
            this.calcsStarted = this.calcsStarted.filter((y) => y.messageId != x.messageId);
            this.setAlert();
        }
      });

    this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe(() => {
      this.clearIdsInEdit();
    });

    this.stateService.state.subscribe((x) => {
      if (this.slideoutOpen !== x.slideOut.display) {
        this.slideoutOpen = x.slideOut.display;
        this.clearIdsInEdit();
      }
    });
  }

  public clearIdsInEdit(): void {
    this.idsInEdit = undefined;
    this.setAlert();
  }

  public updateIdsInEdit(idsInEdit: PlantManagerImpactedIdsDto): void {
    this.idsInEdit = idsInEdit;
    this.setAlert();
  }

  private setAlert(): void {
    let alert: string | undefined = undefined;
    if (
      this.idsInEdit !== undefined &&
      this.calcsStarted.some((x) => hasIdsInCommon(x.impactedIds!, this.idsInEdit!)) //x.userKey != this.userKey &&
    ) {
      alert = 'Refreshing';
    }

    (this.showPageAlert as BehaviorSubject<boolean>).next(alert !== undefined && this.slideoutOpen === false);
    (this.alert as BehaviorSubject<string | undefined>).next(alert);
  }
}
