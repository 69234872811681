import { ScraRateData } from 'src/app/grid-module/cell-renders/grid-cell-scra-calc/grid-cell-scra-rate.component';

export function formatScraRate(rate: number, rateCode: string): ScraRateData {
  if (rate === undefined || rate == 0) {
    return {
      startChar: '',
      rate: '',
      code: ''
    };
  }

  let startChar = undefined;
  if (rateCode[0] == 'D') {
    startChar = '<';
    rate *= 2;
  }

  return {
    startChar: startChar ?? '',
    rate: rate !== undefined ? Number(rate).toFixed(4) : '',
    code: rateCode
  };
}
