import { Component, Inject, OnInit } from '@angular/core';
import { DateOnlyUtility, E2gSelectOption } from '@equityeng/e2g-ng-ui';
import { filter, forkJoin, map, Observable, of, Subject, switchMap, take, takeUntil, tap } from 'rxjs';
import { ActivityDetailsSlideoutComponent } from 'src/app/activity-details-module/activity-details-slideout/activity-details-slideout.component';
import { FutureActivityDetailsComponent } from 'src/app/activity-details-module/future-activity-details/future-activity-details.component';
import { ActivitySlideoutInput } from 'src/app/activity-details-module/models/activity-slideout-input';
import { ActivitySlideoutInputBase } from 'src/app/activity-details-module/models/activity-slideout-input-base';
import { ActivitySlideoutOption } from 'src/app/activity-details-module/models/activity-slideout-option';
import { FutureActivitySlideoutInput } from 'src/app/activity-details-module/models/future-activity-slideout-input';
import { BaseSlideoutOption } from 'src/app/asset-module/models/base-slideout-option';
import { AttachmentTableType } from 'src/app/attachments-module/models/attachment-table-type';
import { CompDataService } from 'src/app/comp-data.service';
import { ActivityDetailDto } from 'src/app/models/activity-detail-dto';
import { ComponentDetailsDto } from 'src/app/models/component-details-dto';
import { OnDestroyBaseComponent } from 'src/app/on-destroy-base-component/on-destroy-base-component';
import { ActionTypes } from 'src/app/shared-module/models/action-types';
import { NEW_ITEM_KEY } from 'src/app/shared-module/models/new-item-key';
import { AssetFeature, AssetPermissionModel } from 'src/app/shared-module/permission/permission-models';
import { PermissionService } from 'src/app/shared-module/permission/permission.service';
import { NotificationService } from 'src/app/shared-module/services/notification.service';
import { SingleDataHandler } from 'src/app/shared-module/single-data-handler';
import { SlideOutContainerAction } from 'src/app/slide-out-module/models/constants/slide-out-container-action';
import { SlideOutContainerService } from 'src/app/slide-out-module/slide-out-container.service';
import { SlideOutService } from 'src/app/slide-out-module/slide-out.service';

import { SLIDE_OUT_DATA } from '../../slide-out-module/slide-out-data-injection-token';
import { SlideOutRef } from '../../slide-out-module/slide-out-ref';
import { StateService } from '../../state.service';
import { FindingPriority } from '../models/enums/finding-priority';
import { FindingStatus } from '../models/enums/finding-status';
import { FindingDto } from '../models/finding-dto';
import { FindingSlideoutInput } from '../models/finding-slideout-input';
import { FutureActivityDetailDto } from '../models/future-activity-detail-dto';
import { ActivityDataService } from '../services/activity-data.service';
import { FutureActivityDataService } from '../services/activity-future-data.service';
import { FindingDataService } from '../services/finding-data.service';

@Component({
  selector: 'app-finding-slideout',
  templateUrl: './finding-slideout.component.html',
  styleUrls: ['./finding-slideout.component.css']
})
export class FindingSlideoutComponent extends OnDestroyBaseComponent implements OnInit {
  public editing: boolean = false;
  public loading: boolean = true;
  public readOnly: boolean = false;
  public tableType = AttachmentTableType.Findings;
  public attachmentKey?: string;

  public dataHandler: SingleDataHandler<FindingDto>;

  public dateErrors: Array<string> = [];

  public componentsForAssetList: Array<E2gSelectOption> | undefined = [] as Array<E2gSelectOption>;
  public priorityList: Array<E2gSelectOption> | undefined = [] as Array<E2gSelectOption>;
  public activityList: Array<E2gSelectOption> | undefined = [] as Array<E2gSelectOption>;
  public statusList: Array<E2gSelectOption> | undefined = [] as Array<E2gSelectOption>;

  public historicalActivityIds: Array<string> = [];

  private assetChanged: boolean = false;
  private assetId: string = '';
  private saveCompleted = new Subject<void>();

  private historicalActivities: Array<ActivityDetailDto> = [];
  private futureActivities: Array<FutureActivityDetailDto> = [];

  public constructor(
    private permissionService: PermissionService,
    private findingsService: FindingDataService,
    private componentService: CompDataService,
    private activityService: ActivityDataService,
    private futureActivityService: FutureActivityDataService,
    private notificationService: NotificationService,
    private stateService: StateService,
    private slideOutRef: SlideOutRef,
    private slideOutContainerService: SlideOutContainerService,
    private slideOutService: SlideOutService,
    @Inject(SLIDE_OUT_DATA) private slideOutData: FindingSlideoutInput
  ) {
    super();
    this.configureSlideOut();
    this.dataHandler = new SingleDataHandler<FindingDto>(
      this.destroy,
      (dirty: boolean) => {
        this.slideOutContainerService.setDirty(dirty);
      },
      undefined,
      () => {
        this.slideOutContainerService.setBodyValid(this.isValid());
      }
    );

    this.slideOutContainerService.setBodyValid(true);
  }

  public ngOnInit(): void {
    this.initializeSlideoutHeader();
    this.loadStaticControlsData();

    // This happens when a user navigates to another finding or the slideout is initially loaded.
    this.slideOutContainerService.optionId
      .pipe(
        tap((id) => this.initializeForm(id)),
        switchMap((id) => forkJoin(this.loadData(id))),
        takeUntil(this.destroy)
      )
      .subscribe(() => this.loadingFormComplete());

    // This is called when the save completes of a new finding.
    this.saveCompleted
      .pipe(
        tap(() => this.initializeForm(this.slideOutData.id)),
        switchMap(() => this.getFinding(this.slideOutData.id)),
        takeUntil(this.destroy)
      )
      .subscribe(() => this.loadingFormComplete());
  }

  private initializeSlideoutHeader(): void {
    this.slideOutContainerService.setOptions(this.buildDropdownOptions(this.slideOutData.optionsData));
    this.slideOutContainerService.setOptionId(this.slideOutData.id);
    this.slideOutContainerService.setBreadcrumbs(this.slideOutData.breadcrumbs);
    this.slideOutContainerService.setAllowDuplicateNames(true);
  }

  private loadStaticControlsData(): void {
    this.priorityList = Object.entries(FindingPriority)
      .filter((e) => !isNaN(e[0] as any))
      .map((e) => ({ value: Number(e[0]), label: `Priority(${e[0]})` }));

    this.statusList = Object.entries(FindingStatus)
      .filter((e) => !isNaN(e[0] as any))
      .map((e) => ({ value: Number(e[0]), label: e[1].toString() }));
  }

  private initializeForm(id: string): void {
    this.loading = true;

    const previousAssetId = this.assetId;
    this.assetId = this.editing ? this.slideOutData.optionsData[id].assetId : this.slideOutData.assetId;

    this.assetChanged = previousAssetId !== this.assetId;

    this.dataHandler.clear();

    if (this.editing) {
      this.attachmentKey = id;
      this.stateService.setSelectedGridId(this.slideOutData.gridContext!, id);
    }
  }

  private loadData(id: string): Array<Observable<any>> {
    return this.assetChanged
      ? [this.getFinding(id), this.getAssetPermissions(), this.getComponents(), this.getActivities()]
      : [this.getFinding(id)];
  }

  private getFinding(id: string): Observable<FindingDto> {
    return (this.editing ? this.findingsService.getFinding(id) : this.getNewFinding()).pipe(
      tap((finding: FindingDto) => {
        this.slideOutData.id = finding.id!;

        this.dataHandler.setInitialData(finding);
        this.setupValidation();

        if (this.isOpenedFromActivity()) {
          this.slideOutContainerService.setStaticHeading(this.dataHandler.data.name!);
          this.slideOutContainerService.setBackButtonVisible(true);
        }

        if (this.slideOutData.findingsEditData) {
          this.dataHandler.updateData(this.slideOutData.findingsEditData);
          this.slideOutData.findingsEditData = undefined;
        }
      })
    );
  }

  private getAssetPermissions(): Observable<AssetPermissionModel> {
    return this.permissionService.getAssetPermissionModel(AssetFeature.Finding, this.assetId).pipe(
      tap((access: AssetPermissionModel) => {
        this.readOnly = !access.edit;
        this.slideOutContainerService.setAllowRename(access.edit);
      }),
      take(1)
    );
  }

  private getComponents(): Observable<void> {
    return this.componentService.getComponents(this.assetId).pipe(
      tap((components: ComponentDetailsDto[]) => {
        this.componentsForAssetList = components.map((x: ComponentDetailsDto) => ({
          value: x.id,
          label: `${x.name} (${x.type}, size: ${(x.size || '').replace(/_/gi, ' ')}, nom: ${x.nominalThickness})`
        }));
      }),
      map(() => undefined)
    );
  }

  private getActivities(): Observable<void> {
    return this.activityService.getActivities(this.assetId).pipe(
      tap((activities: ActivityDetailDto[]) => {
        this.historicalActivities = activities;
        this.historicalActivityIds = activities.map((x: ActivityDetailDto) => x.id!);
      }),
      switchMap((historicalActivities: ActivityDetailDto[]) =>
        this.futureActivityService.getFutureActivities(this.assetId).pipe(
          tap((futureActivities: FutureActivityDetailDto[]) => {
            this.futureActivities = futureActivities;

            this.activityList = [
              ...historicalActivities.map((x) => ({
                value: x.id,
                label: `${x.activityType} (${DateOnlyUtility.formatAsE2gDate(x.date!)})`
              })),
              ...futureActivities.map((x) => ({
                value: x.id,
                label: `${x.activityType} (${DateOnlyUtility.formatAsE2gDate(x.dueDate!)})`
              }))
            ].sort((a, b) => a.label.localeCompare(b.label));
          })
        )
      ),
      map(() => undefined)
    );
  }

  private loadingFormComplete(): void {
    this.loading = false;
  }

  public isOpenedFromActivity(): boolean {
    return (
      this.slideOutData.futureActivitySlideoutInput !== undefined ||
      this.slideOutData.historicalActivitySlideoutInput !== undefined
    );
  }

  private buildDropdownOptions(data: Record<string, BaseSlideoutOption>): Array<E2gSelectOption> {
    return Object.entries(data)
      .map(([key, value]) => ({ value: key, label: value.name }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }

  private addNewItemToHeaderDropdown(id: string): void {
    this.slideOutData.optionsData[id] = {
      name: this.dataHandler.data.name!,
      assetId: this.dataHandler.data.assetId!
    };

    this.slideOutContainerService.setOptions(this.buildDropdownOptions(this.slideOutData.optionsData));
  }

  private configureSlideOut(): void {
    this.editing = this.slideOutData.id !== NEW_ITEM_KEY;

    this.slideOutContainerService.action
      .pipe(
        filter((action) => action === SlideOutContainerAction.Revert),
        takeUntil(this.destroy)
      )
      .subscribe(() => {
        this.dataHandler.revertChanges();
      });

    this.slideOutContainerService.action
      .pipe(
        filter((action) => action === SlideOutContainerAction.Save || action === SlideOutContainerAction.SaveAndClose),
        switchMap((action) =>
          this.findingsService.saveFinding(this.getFormData()).pipe(
            map((result) => {
              if (typeof result === 'string') {
                // Add new finding
                this.slideOutData.id = result;
                this.editing = true;
                this.stateService.setSelectedGridId(this.slideOutData.gridContext!, result);

                this.addNewItemToHeaderDropdown(result);

                this.slideOutContainerService.setOptionId(this.slideOutData.id);

                this.notificationService.showActionResult(
                  true,
                  ActionTypes.Add,
                  this.slideOutData.optionsData[this.slideOutData.id].name
                );
              } else {
                // Check if the update was successful.
                if (result) {
                  this.slideOutData.id = this.dataHandler.data.id!;

                  this.addNewItemToHeaderDropdown(this.slideOutData.id);

                  this.saveCompleted.next();
                }

                this.notificationService.showActionResult(
                  result,
                  ActionTypes.Update,
                  this.slideOutData.optionsData[this.slideOutData.id].name
                );
              }

              return action;
            })
          )
        ),
        filter((action) => action === SlideOutContainerAction.SaveAndClose),
        tap(() => this.closeSlideout()),
        takeUntil(this.destroy)
      )
      .subscribe();

    this.slideOutContainerService.action
      .pipe(
        filter((action) => action === SlideOutContainerAction.Cancel),
        takeUntil(this.destroy)
      )
      .subscribe(() => {
        this.closeSlideout();
      });

    this.slideOutContainerService.nameUpdated.pipe(takeUntil(this.destroy)).subscribe((newName) => {
      this.dataHandler.data.name = newName;
    });
  }

  private closeSlideout(): void {
    if (this.slideOutData.futureActivitySlideoutInput) {
      this.slideOutData.futureActivitySlideoutInput.findingsId = this.dataHandler.data.id;
      this.slideOutService.open<boolean, FutureActivityDetailsComponent>(FutureActivityDetailsComponent, {
        data: this.slideOutData.futureActivitySlideoutInput
      });
    } else if (this.slideOutData.historicalActivitySlideoutInput) {
      this.slideOutData.historicalActivitySlideoutInput.findingsId = this.dataHandler.data.id;
      this.slideOutService.open<boolean, ActivityDetailsSlideoutComponent>(ActivityDetailsSlideoutComponent, {
        data: this.slideOutData.historicalActivitySlideoutInput
      });
    } else {
      this.slideOutRef.close();
    }
  }

  public getFormData(): FindingDto {
    return this.dataHandler.getRawData();
  }

  public isDirty(): boolean {
    return this.dataHandler.dirty;
  }

  public revertChanges(): void {
    this.dataHandler.revertChanges();
  }

  public viewActivity(): void {
    if (this.dataHandler.data.activityId) {
      const baseInput: ActivitySlideoutInputBase = {
        assetId: this.assetId,
        initialActivityId: this.dataHandler.data.activityId!,
        findingSlideoutInput: this.slideOutData,
        optionsData: {} as Record<string, ActivitySlideoutOption>,
        breadcrumbs: this.slideOutData.breadcrumbs
      };

      this.slideOutData.id = this.dataHandler.data.id!;
      this.slideOutData.findingsEditData = this.dataHandler.getRawData();

      if (this.historicalActivityIds.includes(this.dataHandler.data.activityId!)) {
        const historicalActivity = this.historicalActivities.find((x) => x.id === this.dataHandler.data.activityId)!;
        baseInput.optionsData[historicalActivity.id!] = {
          name: historicalActivity.name!,
          assetId: this.slideOutData.assetId,
          template: historicalActivity.template!
        };
        const input: ActivitySlideoutInput = { ...baseInput, activityTemplate: historicalActivity.template! };

        this.slideOutService.open<boolean, ActivityDetailsSlideoutComponent>(ActivityDetailsSlideoutComponent, {
          data: input
        });
      } else {
        const futureActivity = this.futureActivities.find((x) => x.id === this.dataHandler.data.activityId)!;
        baseInput.optionsData[futureActivity.id!] = {
          name: futureActivity.activityType!,
          assetId: this.slideOutData.assetId,
          template: futureActivity.templateType!
        };
        const input: FutureActivitySlideoutInput = { ...baseInput };

        this.slideOutService.open<boolean, FutureActivityDetailsComponent>(FutureActivityDetailsComponent, {
          data: input
        });
      }
    }
  }

  private getNewFinding(): Observable<FindingDto> {
    return of({
      name: '',
      assetId: this.slideOutData.assetId,
      activityId: this.getActivityId(),
      date: this.isOpenedFromActivity() ? DateOnlyUtility.today() : undefined
    });
  }

  private getActivityId(): string | undefined {
    return this.slideOutData.futureActivitySlideoutInput != undefined
      ? this.slideOutData.futureActivitySlideoutInput.initialActivityId
      : this.slideOutData.historicalActivitySlideoutInput != undefined
      ? this.slideOutData.historicalActivitySlideoutInput.initialActivityId
      : undefined;
  }

  private setupValidation(): void {
    this.dataHandler.setValidation({
      date: () => this.validateDate()
    });
  }

  private validateDate(): Observable<string | undefined> {
    this.dateErrors = [];

    if (this.dataHandler.data.date !== undefined) {
      if (!DateOnlyUtility.isValid(this.dataHandler.data.date)) {
        this.dateErrors.push('Valid date required');
      }
    }

    return of(this.dateErrors.length > 0 ? 'ERROR' : undefined);
  }

  private isValid(): boolean {
    return this.dateErrors.length == 0;
  }
}
