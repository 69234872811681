import { E2gSelectOption } from '@equityeng/e2g-ng-ui';

declare global {
  interface Array<T> {
    keyValueToE2gSelectOptions(): Array<E2gSelectOption>;
    nameIdToE2gSelectOptions(): Array<E2gSelectOption>;
  }
}

export const addExtensions = (): void => {
  Array.prototype.keyValueToE2gSelectOptions = function (): Array<E2gSelectOption> {
    const keyValues = this as Array<{ key: string; value: string | number }>;
    return keyValues.map((x) => ({
      label: x.key,
      value: x.value
    }));
  };

  Array.prototype.nameIdToE2gSelectOptions = function (): Array<E2gSelectOption> {
    const keyValues = this as Array<{ name: string; id: number }>;
    return keyValues.map((x) => ({
      label: x.name,
      value: x.id
    }));
  };
};
