import { Component } from '@angular/core';
import { OnDestroyBaseComponent } from 'src/app/on-destroy-base-component/on-destroy-base-component';

import { ActivitySlideoutViewModel } from './activity-slideout-vm';

@Component({
  selector: 'app-activity-details-slideout',
  templateUrl: './activity-details-slideout.component.html',
  styleUrls: ['./activity-details-slideout.component.css']
})
export class ActivityDetailsSlideoutComponent extends OnDestroyBaseComponent {
  public vm = new ActivitySlideoutViewModel(this.destroy);

  public constructor() {
    super();
  }
}
