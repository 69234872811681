import { Component, Inject, OnInit, QueryList, ViewChildren } from '@angular/core';
import { E2gSelectOption } from '@equityeng/e2g-ng-ui';
import {
  faBorderAll,
  faBurst,
  faCircleHalfStroke,
  faClock,
  faDownload,
  faHelmetSafety,
  faIndustryWindows,
  faPipeValve,
  faX
} from '@fortawesome/pro-regular-svg-icons';
import { Subject } from 'rxjs';
import { getScraAssetTypes } from 'src/app/models/enums/asset-types';
import { getServiceStatusOptions } from 'src/app/models/enums/service-status';
import { SLIDE_OUT_DATA } from 'src/app/slide-out-module/slide-out-data-injection-token';
import { SlideOutRef } from 'src/app/slide-out-module/slide-out-ref';
import { StateService } from 'src/app/state.service';

import { PlanningHorizonOptions, toDashboardFilterSelectOption } from '../dashboard-utility';
import { DashboardChartFiltersDto } from '../models/dashboard-chart-filters-dto';
import { DashboardFiltersDto } from '../models/dashboard-filters-dto';
import { DashboardMatrixFiltersDto } from '../models/dashboard-matrix-filters-dto';
import { DashboardSlideoutData } from '../models/dashboard-slideout-data';
import { DamageModeOptions } from '../models/enums/damage-mode';
import { DashboardSlideoutType, getDashboardHeading } from '../models/enums/dashboard-slideout-type';
import { IDashboardGridSlideout } from '../models/i-dashboard-grid-slideout';

@Component({
  selector: 'app-data-card-slideout',
  templateUrl: './data-card-slideout.component.html',
  styleUrls: ['./data-card-slideout.component.css'],
  providers: [
    {
      provide: IDashboardGridSlideout,
      useExisting: DataCardSlideoutComponent
    }
  ]
})
export class DataCardSlideoutComponent implements OnInit {
  @ViewChildren(IDashboardGridSlideout) public gridComponents!: QueryList<IDashboardGridSlideout>;

  public DashboardSlideoutType = DashboardSlideoutType;
  public faDownload = faDownload;
  public heading = '';
  public thresholdHeading = '';

  public closeIcon = faX;
  public faIndustryWindows = faIndustryWindows;
  public faPipeValve = faPipeValve;
  public faBorderAll = faBorderAll;
  public faCircleHalfStroke = faCircleHalfStroke;
  public faClock = faClock;
  public faHelmetSafety = faHelmetSafety;
  public faBurst = faBurst;

  public filters!: DashboardFiltersDto | DashboardChartFiltersDto | DashboardMatrixFiltersDto;
  public displayLimit = 1;
  public refreshData = new Subject<void>();
  public refreshGrid = new Subject<void>();
  public isChartSlideout: boolean = false;
  public isMatrixSlideout: boolean = false;
  public isActivity: boolean = false;

  public assetTypeList: Array<E2gSelectOption> = getScraAssetTypes();
  public planningHorizonList: Array<E2gSelectOption> = PlanningHorizonOptions;
  public damageModeOptions: Array<E2gSelectOption> = DamageModeOptions;
  public plantList: Array<E2gSelectOption> = new Array<E2gSelectOption>();
  public unitList: Array<E2gSelectOption> = new Array<E2gSelectOption>();
  public serviceStatusList: Array<E2gSelectOption> = new Array<E2gSelectOption>();
  public activityTypeList: Array<E2gSelectOption> = new Array<E2gSelectOption>();
  public allUnits: Array<E2gSelectOption> = new Array<E2gSelectOption>();
  public allPlants: Array<E2gSelectOption> = new Array<E2gSelectOption>();

  public filterOn?: boolean = true;

  public constructor(
    private slideOutRef: SlideOutRef,
    @Inject(SLIDE_OUT_DATA) public data: DashboardSlideoutData,
    private stateService: StateService
  ) {
    this.filters = { ...data.filters };
    this.serviceStatusList = getServiceStatusOptions();
    this.resetMatrixFilters(data.filters);
  }

  public ngOnInit(): void {
    this.isChartSlideout = [
      DashboardSlideoutType.CompletedActivities,
      DashboardSlideoutType.CompletedReadings,
      DashboardSlideoutType.UpcomingActivities,
      DashboardSlideoutType.UpcomingThickness
    ].includes(this.data.slideout);

    this.isActivity = [
      DashboardSlideoutType.CompletedActivities,
      DashboardSlideoutType.UpcomingActivities,
      DashboardSlideoutType.OverdueActivities,
      DashboardSlideoutType.ActivitiesToAccept
    ].includes(this.data.slideout);

    this.isMatrixSlideout = [
      DashboardSlideoutType.RiskMatrixPlanDate,
      DashboardSlideoutType.RiskMatrixRbiDate,
      DashboardSlideoutType.RiskMatrixWithInspection
    ].includes(this.data.slideout);

    this.heading = getDashboardHeading(this.data.slideout);
    this.thresholdHeading = this.getThresholdHeading();
    this.allPlants = toDashboardFilterSelectOption(this.data.plantsWithUnits);
    this.plantList = this.allPlants;
    this.allUnits = toDashboardFilterSelectOption(
      this.data.plantsWithUnits.map((x) => x.units).reduce((accumulator, value) => accumulator.concat(value), [])
    );

    this.setUnitsList();

    this.activityTypeList = this.data.activityTypeList;

    this.refreshData.next();
  }

  private setUnitsList(): void {
    if (this.filters.plantIds.length === 0 || this.filters.plantIds.length == this.allPlants.length) {
      this.unitList = this.allUnits;
    } else {
      this.unitList = toDashboardFilterSelectOption(
        this.data.plantsWithUnits
          .filter((x) => this.filters.plantIds?.includes(x.id))
          .map((x) => x.units)
          .reduce((accumulator, value) => accumulator.concat(value), [])
      );
    }
  }

  public close(): void {
    this.slideOutRef!.close();
  }

  public downloadExcelFile = (): void => {
    this.gridComponents.first.downloadExcelFile();
  };

  public onRouteChange(): void {
    this.stateService.setFlowDashboard(
      { ...this.data, filters: this.filters } as DashboardSlideoutData,
      this.data.filters
    );
    this.close();
  }

  public onPlantChange(): void {
    this.refreshData.next();

    this.setUnitsList();
  }

  public refreshContent(): void {
    this.refreshData.next();
  }

  public toggleVisible(): boolean {
    const matrixFilters = this.filters as DashboardMatrixFiltersDto;
    return (
      this.isMatrixSlideout &&
      (matrixFilters.riskId !== 'All' ||
        (matrixFilters.pofCategory !== undefined && matrixFilters.cofCategory !== undefined))
    );
  }

  public onChange(value: boolean): void {
    this.filterOn = value;
    (this.filters as DashboardMatrixFiltersDto).filterOn = this.filterOn;

    this.refreshGrid.next();
  }

  private resetMatrixFilters(filters: DashboardMatrixFiltersDto): void {
    filters.filterOn = true;
    filters.riskId = 'All';
    filters.pofCategory = undefined;
    filters.cofCategory = undefined;
  }

  private getThresholdHeading(): string {
    switch (this.data.slideout) {
      case DashboardSlideoutType.RemainingLife:
        return 'Remaining Life (yrs)';
      default:
        return '';
    }
  }
}
