<div class="flex-col flex-111 e2g-padding pl-0 pr-0 pb-0 h-100">
  <ul ngbNav #nav="ngbNav" [(activeId)]="activeTabId" class="py-0 pl-4 d-inline-flex sub-nav-tabs w-100">
    <li [ngbNavItem]="1">
      <a ngbNavLink>Overview</a>
      <ng-template ngbNavContent>
        <div class="h-100 flex-col">
          <div class="flex-111 overflow-auto">
            <div class="d-flex row h-100 m-0 p-0 pt-2 pl-2">
              <div class="col-6">
                <e2g-text-input
                  heading="Name"
                  id="inp-name"
                  [(ngModel)]="dataHandler.data.name"
                  [disabled]="true"
                ></e2g-text-input>

                <div class="mt-2">
                  <e2g-textarea-input
                    heading="Description"
                    [rows]="2"
                    id="inp-description"
                    [(ngModel)]="dataHandler.data.description"
                    maxlength="255"
                    [readonly]="readOnly"
                  ></e2g-textarea-input>
                </div>

                <div class="mt-2">
                  <e2g-text-input
                    heading="Type"
                    id="inp-name"
                    [(ngModel)]="equipType"
                    [disabled]="true"
                  ></e2g-text-input>
                </div>

                <div class="mt-2">
                  <e2g-ng-select-input
                    id="select-service-status"
                    heading="Service Status"
                    placeholder="Select Service Status"
                    [disabled]="false"
                    [required]="false"
                    [readonly]="readOnly"
                    [isLoading]="!serviceStatusList"
                    [options]="serviceStatusList || []"
                    [(ngModel)]="dataHandler.data.serviceStatus"
                  ></e2g-ng-select-input>
                </div>

                <div class="mt-2">
                  <e2g-text-input
                    heading="External Asset ID"
                    id="inp-ext-asset-id"
                    [(ngModel)]="dataHandler.data.extAssetId"
                    [readonly]="readOnly"
                    [maxlength]="64"
                  ></e2g-text-input>
                </div>

                <div class="mt-2">
                  <e2g-text-input
                    heading="Manufacturer"
                    id="inp-manufacturer"
                    [(ngModel)]="dataHandler.data.manufacturer"
                    [readonly]="readOnly"
                    [maxlength]="255"
                  ></e2g-text-input>
                </div>

                <div class="mt-2">
                  <e2g-date-input
                    heading="In Service Date"
                    id="inp-inServiceDate"
                    [(ngModel)]="dataHandler.data.inServiceDate"
                    [readonly]="readOnly"
                    placeholder="mm/dd/yyyy"
                  ></e2g-date-input>
                </div>

                <div class="mt-2">
                  <e2g-text-input
                    heading="P&ID"
                    id="inp-pid"
                    [(ngModel)]="dataHandler.data.pid"
                    [readonly]="readOnly"
                    [maxlength]="255"
                  ></e2g-text-input>
                </div>

                <div class="mt-2">
                  <e2g-text-input
                    heading="Access Limitations"
                    id="inp-access-limitations"
                    [(ngModel)]="dataHandler.data.accessLimitations"
                    [readonly]="readOnly"
                    [maxlength]="255"
                  ></e2g-text-input>
                  <div class="mt-2">
                    <e2g-check-input
                      heading="Internal Access"
                      id="inp-internal-access"
                      labelPosition="right"
                      [(ngModel)]="dataHandler.data.hasInternalAccess"
                      [readonly]="readOnly"
                    ></e2g-check-input>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <app-attachments
                  *ngIf="dataHandler.initialized; else loading"
                  class="p-0 pt-2"
                  [tableType]="tableType"
                  [attachmentKey]="dataHandler.data.id!"
                  [readonly]="readOnly"
                ></app-attachments>
              </div>
            </div>
          </div>
          <div class="control-flow-container" *ngIf="!readOnly">
            <div class="d-flex justify-content-end">
              <div class="d-flex">
                <button class="btn e2g-btn-outline-primary mr-2" (click)="revertChanges()" *ngIf="isDirty()">
                  Revert Changes
                </button>
                <button id="btn-save" class="btn e2g-btn-solid-primary" (click)="onSave()" [disabled]="!isDirty()">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="2">
      <a ngbNavLink>PRD Design</a>
      <ng-template ngbNavContent>
        <div class="h-100 flex-col">
          <div class="flex-111 overflow-auto">
            <div class="d-flex row h-100 m-0 p-0 pt-2 pl-2 w-100">
              <div id="design-col-1" class="col-sm-4">
                <app-br-select propName="reliefDeviceType" heading="Relief Device Type"></app-br-select>
                <div class="mt-2">
                  <app-br-text propName="orificeArea" heading="Orifice Area" type="number"></app-br-text>
                </div>
                <div class="mt-2">
                  <app-br-text propName="inletSize" heading="Inlet Size" type="number"></app-br-text>
                </div>
                <div class="mt-2">
                  <app-br-text propName="setPressure" heading="Set Pressure" type="number"></app-br-text>
                </div>
                <div class="mt-2">
                  <app-br-text propName="backPressure" heading="Back Pressure" type="number"></app-br-text>
                </div>
              </div>
              <div id="design-col-2" class="col-sm-4">
                <div class="mt-2">
                  <app-br-select propName="dischargeLocation" heading="Discharge Location"></app-br-select>
                </div>
                <div class="mt-2">
                  <app-br-text
                    propName="currentInspectionInterval"
                    heading="Current Inspection Interval"
                    type="number"
                  ></app-br-text>
                </div>
              </div>
            </div>
          </div>
          <div class="control-flow-container" *ngIf="!readOnly">
            <div class="d-flex justify-content-end">
              <div class="d-flex">
                <button class="btn e2g-btn-outline-primary mr-2" (click)="revertChanges()" *ngIf="isDirty()">
                  Revert Changes
                </button>
                <button id="btn-save" class="btn e2g-btn-solid-primary" (click)="onSave()" [disabled]="!isDirty()">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="3">
      <a ngbNavLink>PRD Identification</a>
      <ng-template ngbNavContent>
        <div class="h-100 flex-col">
          <div class="flex-111 overflow-auto">
            <div class="row h-100 m-0 p-0 pl-2 pr-2 pt-2 w-100">
              <div id="iden-col-1" class="col-sm-4">
                <app-br-text propName="serialNumber" heading="Serial Number"></app-br-text>
                <div class="mt-2">
                  <app-br-text propName="makeModel" heading="Make/Model"></app-br-text>
                </div>
                <div class="mt-2">
                  <app-br-text
                    propName="jurisdictionalRegistrationNumber"
                    heading="Jurisdictional Registration Number"
                  ></app-br-text>
                </div>
                <div class="mt-2">
                  <app-br-text
                    propName="jurisdictionalSerialNumber"
                    heading="Jurisdictional Serial Number"
                  ></app-br-text>
                </div>
              </div>
              <div id="iden-col-2" class="col-sm-4">
                <app-br-select propName="inletType" heading="Inlet Type"></app-br-select>
                <div class="mt-2">
                  <app-br-select propName="inletRating" heading="Inlet Rating"></app-br-select>
                </div>
                <div class="mt-2">
                  <app-br-text propName="inletSize" heading="Inlet Size" type="number"></app-br-text>
                </div>

                <div class="mt-2">
                  <app-br-select propName="outletType" heading="Outlet Type"></app-br-select>
                </div>
                <div class="mt-2">
                  <app-br-select propName="outletRating" heading="Outlet Rating"></app-br-select>
                </div>
                <div class="mt-2">
                  <app-br-text propName="outletSize" heading="Outlet Size" type="number"></app-br-text>
                </div>
              </div>
              <div id="iden-col-3" class="col-sm-4">
                <app-br-text
                  propName="temperatureCorrection"
                  heading="Temperature Correction"
                  type="number"
                ></app-br-text>
                <div class="mt-2">
                  <app-br-text propName="cdtp" heading="CDTP" type="number"></app-br-text>
                </div>
                <div class="mt-2">
                  <app-br-text propName="springNumber" heading="Spring Number" type="number"></app-br-text>
                </div>
                <div class="mt-2">
                  <app-br-text propName="lift" heading="Lift" type="number"></app-br-text>
                </div>
                <div class="mt-2">
                  <app-br-text propName="capacity" heading="Capacity" type="number"></app-br-text>
                </div>
              </div>
            </div>
          </div>
          <div class="control-flow-container" *ngIf="!readOnly">
            <div class="d-flex justify-content-end">
              <div class="d-flex">
                <button class="btn e2g-btn-outline-primary mr-2" (click)="revertChanges()" *ngIf="isDirty()">
                  Revert Changes
                </button>
                <button id="btn-save" class="btn e2g-btn-solid-primary" (click)="onSave()" [disabled]="!isDirty()">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="4">
      <a ngbNavLink>Protected Asset</a>
      <ng-template ngbNavContent>
        <div class="h-100 flex-col">
          <div class="flex-111 overflow-auto">
            <div class="col h-100 m-0 p-0 pl-4 pr-4 pt-2">
              <div class="pb-2 prd-location">
                <app-br-select
                  propName="prdProtectedAssetId"
                  heading="PRD Location"
                  (change$)="prdProtectedAssetChange()"
                ></app-br-select>
              </div>

              <div class="d-flex flex-row pl-0 h-100 overflow-auto flex-wrap">
                <app-checkbox-multiselect
                  class="d-flex flex-row pl-0 h-100 overflow-auto flex-wrap"
                  [allAvailableItems]="getAllAvailableItems()"
                  [unselectedLabel]="unselectedLabel"
                  [selectedLabel]="selectedLabel"
                  [(ngModel)]="dataHandler.data.protectedAssets"
                  [readOnly]="readOnly"
                ></app-checkbox-multiselect>
              </div>
            </div>
          </div>
          <div class="control-flow-container" *ngIf="!readOnly">
            <div class="d-flex justify-content-end">
              <div class="d-flex">
                <button class="btn e2g-btn-outline-primary mr-2" (click)="revertChanges()" *ngIf="isDirty()">
                  Revert Changes
                </button>
                <button id="btn-save" class="btn e2g-btn-solid-primary" (click)="onSave()" [disabled]="!isDirty()">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="5" *ngIf="sageEnabled">
      <a ngbNavLink>SAGE</a>
      <ng-template ngbNavContent>
        <div class="h-100 flex-col">
          <div class="flex-111 overflow-auto">
            <div class="col h-100 pl-2 pt-2">
              <app-sage-asset-tree></app-sage-asset-tree>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav" class="h-100 flex-col flex-111"></div>
</div>

<ng-template #loading>
  <div class="loading"></div>
</ng-template>
