import { ColDef } from 'ag-grid-community';

export function getBaseGroupColumns(): Array<ColDef> {
  return [
    {
      headerName: 'Id',
      field: 'id',
      hide: true
    },
    {
      headerName: 'Description',
      headerTooltip: 'Description',
      field: 'description',
      tooltipField: 'description',
      width: 200,
      hide: true
    },
    {
      headerName: 'Comments',
      headerTooltip: 'Comments',
      field: 'comments',
      tooltipField: 'comments',
      width: 300,
      hide: true
    },
    {
      headerName: 'Operating Pressure',
      headerTooltip: 'Operating Pressure',
      field: 'operatingPressure',
      tooltipField: 'operatingPressure',
      width: 220,
      filter: 'agNumberColumnFilter'
    },
    {
      headerName: 'Operating Temperature',
      headerTooltip: 'Operating Temperature',
      field: 'operatingTemperature',
      tooltipField: 'operatingTemperature',
      width: 250,
      filter: 'agNumberColumnFilter'
    },
    {
      headerName: 'Estimated Corrosion Rate',
      headerTooltip: 'Estimated Corrosion Rate',
      field: 'estimatedCorrosionRate',
      tooltipField: 'estimatedCorrosionRate',
      width: 320,
      filter: 'agNumberColumnFilter'
    }
  ];
}
