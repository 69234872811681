import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificationService } from 'src/app/shared-module/services/notification.service';
import { Observable, catchError, map, of, tap } from 'rxjs';

import { AppSettingsService } from './app-settings/app-settings.service';
import { ReportFormat } from './models/enums/report-format';

@Injectable({
  providedIn: 'root'
})
export class ReportDataService {
  public constructor(
    private http: HttpClient,
    private settingsService: AppSettingsService,
    private notificationService: NotificationService
  ) {}

  public getSageReport(id: string, saveFormat: ReportFormat): Observable<HttpResponse<Blob> | undefined> {
    return this.http
      .get<Blob>(`${this.settingsService.settings.apiUri}/sage/${id}/report/${saveFormat}`, {
        observe: 'response',
        responseType: 'blob' as 'json'
      })
      .pipe(
        tap(() => this.showSuccessfulReportToast()),
        catchError(() => {
          this.showFailedReportToast();
          return of(undefined);
        })
      );
  }

  public getSageCalculationReport(id: string, index: Array<number>): Observable<HttpResponse<Blob> | undefined> {
    return this.http
      .post<Blob>(`${this.settingsService.settings.apiUri}/sage/${id}/calc-report`, index, {
        observe: 'response',
        responseType: 'blob' as 'json'
      })
      .pipe(
        map((x) => {
          if (x.status == 204) {
            this.showNoReportFoundToast();
            return undefined;
          }
          this.showSuccessfulReportToast();
          return x;
        }),
        catchError(() => {
          this.showFailedReportToast();
          return of(undefined);
        })
      );
  }

  private showSuccessfulReportToast(): void {
    this.notificationService.showSaveResult(true, 'Report Download');
  }

  private showFailedReportToast(): void {
    this.notificationService.showError('Report Error');
  }

  private showNoReportFoundToast(): void {
    this.notificationService.showInfo('SAGE does not yet have any reports available for this specific analysis');
  }
}
