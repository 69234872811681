import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IdNameDto } from 'src/app/asset-module/models/id-name-dto';
import { BaseDataService } from 'src/app/models/base-data-service';

import { AppSettingsService } from './app-settings/app-settings.service';
import { CalculationStatusDto } from './models/calculation-status-dto';
import { ComponentCreateDto } from './models/component-create-dto';
import { ComponentDetailsDto } from './models/component-details-dto';
import { ComponentDto } from './models/component-dto';
import { ComponentRbiInspectionPlanDto } from './models/component-rbi-inspection-plan-dto';
import { ComponentTypeDto } from './models/component-type-dto';

@Injectable({
  providedIn: 'root'
})
export class CompDataService implements BaseDataService {
  private serverUri: string;

  public constructor(private http: HttpClient, private settingsService: AppSettingsService) {
    this.serverUri = this.settingsService.settings.apiUri as string;
  }

  public getComponentsForAsset(equipmentId: string): Observable<Array<ComponentDto>> {
    return this.http
      .get<Array<ComponentDto>>(`${this.serverUri}/comp/equip/${equipmentId}/components`)
      .pipe(catchError(() => of(new Array<ComponentDto>())));
  }

  public getComponents(equipmentId: string): Observable<Array<ComponentDetailsDto>> {
    return this.http
      .get<Array<ComponentDetailsDto>>(`${this.serverUri}/comp/equip/${equipmentId}/details`)
      .pipe(catchError(() => of(new Array<ComponentDetailsDto>())));
  }

  public getComponentTypes(equipmentId: string): Observable<Array<ComponentTypeDto>> {
    return this.http
      .get<Array<ComponentTypeDto>>(`${this.serverUri}/comp/equip/${equipmentId}/component/types`)
      .pipe(catchError(() => of(new Array<ComponentTypeDto>())));
  }

  public createNewComponent(dto: ComponentCreateDto): Observable<string> {
    return this.http.post<string>(`${this.serverUri}/comp/`, JSON.stringify(dto));
  }

  public deleteComponent(id: string): Observable<boolean> {
    return this.http.delete<boolean>(`${this.serverUri}/comp/${id}`).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  public copyComponent(id: string, name: string = ''): Observable<string> {
    return this.http.post<string>(`${this.serverUri}/comp/copy`, { id, name }).pipe(catchError(() => of('')));
  }

  public renameComponent(id: string, name: string): Observable<boolean> {
    return this.http.post<boolean>(`${this.serverUri}/comp/rename`, { id, name }).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  public getNewCourseNumber(assetId: string): Observable<number> {
    return this.http.get<number>(`${this.serverUri}/comp/${assetId}/newcoursenumber`).pipe(catchError(() => of(-1)));
  }

  public getRbiOutput(componentId: string): Observable<ComponentRbiInspectionPlanDto> {
    return this.http
      .get<ComponentRbiInspectionPlanDto>(`${this.serverUri}/comp/${componentId}/rbioutput`)
      .pipe(catchError(() => of({} as ComponentRbiInspectionPlanDto)));
  }

  public getCalculationStatusComponent(componentId: string): Observable<CalculationStatusDto> {
    return this.http
      .get<CalculationStatusDto>(`${this.serverUri}/comp/${componentId}/calculationstatus`)
      .pipe(catchError(() => of({} as CalculationStatusDto)));
  }

  public getComponentNamesForAsset(assetId: string): Observable<Array<IdNameDto>> {
    return this.http
      .get<Array<IdNameDto>>(`${this.serverUri}/comp/assets/${assetId}/components-names`)
      .pipe(catchError(() => of([] as Array<IdNameDto>)));
  }
}
