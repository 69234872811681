import { E2gSelectOption } from '@equityeng/e2g-ng-ui';

import { AssetTypes } from '../models/enums/asset-types';
import { ToughnessCurveDesignations } from '../models/enums/toughness-curve-designations';

export function getToughnessCurveOptions(designCode: string, assetType: AssetTypes): Array<E2gSelectOption> {
  let options = [{ value: ToughnessCurveDesignations.TEMP, label: 'N/A' }];
  if (designCode == 'B31_1') {
    options = [
      ...options,
      { value: ToughnessCurveDesignations.CI_20, label: 'CI -20' },
      { value: ToughnessCurveDesignations.CI_20_A, label: 'CI -20(A)' },
      { value: ToughnessCurveDesignations.CS20A, label: 'CS +20(A)' },
      { value: ToughnessCurveDesignations.CS0, label: 'CS 0' },
      { value: ToughnessCurveDesignations.CS_20, label: 'CS -20' },
      { value: ToughnessCurveDesignations.CS_20A, label: 'CS -20(A)' },
      { value: ToughnessCurveDesignations.CS_50, label: 'CS -50' },
      { value: ToughnessCurveDesignations.CS_55, label: 'CS -55' },
      { value: ToughnessCurveDesignations.CS_A, label: 'CS A' },
      { value: ToughnessCurveDesignations.CS_B, label: 'CS B' },
      { value: ToughnessCurveDesignations.CS_C, label: 'CS C' },
      { value: ToughnessCurveDesignations.CS_D, label: 'CS D' },
      { value: ToughnessCurveDesignations.CU_325, label: 'CU -325' },
      { value: ToughnessCurveDesignations.CU_452, label: 'CU -452' },
      { value: ToughnessCurveDesignations.LA20, label: 'LA +20' },
      { value: ToughnessCurveDesignations.LA0, label: 'LA 0' },
      { value: ToughnessCurveDesignations.LA_100, label: 'LA -100' },
      { value: ToughnessCurveDesignations.LA_150, label: 'LA -150' },
      { value: ToughnessCurveDesignations.LA_20, label: 'LA -20' },
      { value: ToughnessCurveDesignations.LA_275, label: 'LA -275' },
      { value: ToughnessCurveDesignations.LA_320, label: 'LA -320' },
      { value: ToughnessCurveDesignations.LA_40, label: 'LA -40' },
      { value: ToughnessCurveDesignations.LA_55, label: 'LA -55' },
      { value: ToughnessCurveDesignations.LA_75, label: 'LA -75' },
      { value: ToughnessCurveDesignations.SS_20, label: 'SS -20' },
      { value: ToughnessCurveDesignations.SS_325, label: 'SS -325' },
      { value: ToughnessCurveDesignations.SS_425, label: 'SS -425' },
      { value: ToughnessCurveDesignations.SS_60, label: 'SS -60' },
      { value: ToughnessCurveDesignations.T1_75, label: 'TI -75' }
    ];
  } else if (assetType == AssetTypes.StorageTank) {
    options = [
      ...options,
      { value: ToughnessCurveDesignations.I, label: 'I' },
      { value: ToughnessCurveDesignations.II, label: 'II' },
      { value: ToughnessCurveDesignations.III, label: 'III' },
      { value: ToughnessCurveDesignations.IIIA, label: 'IIIA' },
      { value: ToughnessCurveDesignations.IV, label: 'IV' },
      { value: ToughnessCurveDesignations.IVA, label: 'IVA' },
      { value: ToughnessCurveDesignations.V, label: 'V' },
      { value: ToughnessCurveDesignations.VI, label: 'VI' }
    ];
  } else {
    options = [
      ...options,
      { value: ToughnessCurveDesignations.A, label: 'A' },
      { value: ToughnessCurveDesignations.B, label: 'B' },
      { value: ToughnessCurveDesignations.C, label: 'C' },
      { value: ToughnessCurveDesignations.D, label: 'D' }
    ];
  }
  return options;
}

export function getToughnessCurveValue(input: string | undefined, designCode: string, assetType: AssetTypes): number | undefined {
  if (input == undefined) {
    return undefined;
  }

  const matchingEnumValue = ToughnessCurveDesignations[input as keyof typeof ToughnessCurveDesignations];
  if (matchingEnumValue != undefined) {
    return matchingEnumValue;
  }

  const matchingOption = getToughnessCurveOptions(designCode, assetType)
    .find(opt => opt.label === input);
  return matchingOption == undefined ? undefined : Number(matchingOption.value);
}
