import { ColDef, ICellRendererParams, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { IGridCellRemainingLifeParams } from 'src/app/grid-module/cell-renders/grid-cell-remaining-life/grid-cell-remaining-life-params';
import { GridCellRemainingLifeComponent } from 'src/app/grid-module/cell-renders/grid-cell-remaining-life/grid-cell-remaining-life.component';
import { assignHeaderAndTooltip } from 'src/app/grid-module/column-builders/build-default';
import { doubleWithNullComparator } from 'src/app/grid-module/column-data-comparers/double-with-null-comparator';

export function buildRemainingLifeColDef(header: string, field: string): ColDef {
  return {
    ...assignHeaderAndTooltip(header, field),
    comparator: doubleWithNullComparator,
    cellRenderer: GridCellRemainingLifeComponent,
    cellRendererParams: <IGridCellRemainingLifeParams>{
      remainingLifeText: (params: ICellRendererParams): string => textFormatter(params.data[field]),
      remainingLifeColor: (params: ICellRendererParams): string => colorFormatter(params.data[field])
    },
    valueGetter: (params: ValueGetterParams): string => textFormatter(params.data[field]),
    valueFormatter: (params: ValueFormatterParams): string => textFormatter(params.data[field])
  };
}

function textFormatter(remainingLifeValue: number | undefined): string {
  if (remainingLifeValue === undefined) {
    return '';
  } else if (remainingLifeValue < 0) {
    return '0 Years';
  } else if (remainingLifeValue > 99) {
    return '>99 Years';
  }

  return remainingLifeValue.toFixed(2) + ' Years';
}

function colorFormatter(remainingLifeValue: number | undefined): string {
  if (remainingLifeValue === undefined) {
    return '';
  }

  if (remainingLifeValue >= 10) {
    return 'var(--pill-bg-success)';
  } else if (remainingLifeValue < 10 && remainingLifeValue >= 5) {
    return 'var(--pill-bg-warning)';
  } else if (remainingLifeValue > 0 && remainingLifeValue < 5) {
    return 'var(--pill-bg-error)';
  } else if (remainingLifeValue <= 0) {
    return 'var(--pill-bg-default)';
  }

  return '';
}
