import { ColDef, ValueGetterParams } from 'ag-grid-community';
import { FindingStatus } from 'src/app/asset-module/models/enums/finding-status';
import { buildEnumColDef } from 'src/app/grid-module/column-builders/build-enum';

export function getFindingGridColumns(): Array<ColDef> {
  return [
    {
      headerName: 'Id',
      field: 'id',
      hide: true
    },
    {
      headerName: 'Description',
      headerTooltip: 'Description',
      field: 'description',
      tooltipField: 'description',
      width: 250
    },
    {
      headerName: 'Component',
      headerTooltip: 'Component',
      field: 'componentName',
      tooltipField: 'componentName',
      width: 200
    },
    {
      headerName: 'Code Reference',
      headerTooltip: 'Code Reference',
      field: 'codeReference',
      tooltipField: 'codeReference',
      width: 190
    },
    {
      headerName: 'Priority',
      headerTooltip: 'Priority',
      field: 'priority',
      tooltipField: 'priority',
      width: 150,
      valueGetter: (params: ValueGetterParams): any => (params.data.priority ? params.data.priority : '')
    },
    {
      headerName: 'Activity',
      headerTooltip: 'Activity',
      field: 'activityName',
      tooltipField: 'activityName',
      width: 350
    },
    {
      headerName: 'Maintenance Reference',
      headerTooltip: 'Maintenance Reference',
      field: 'maintenanceReference',
      tooltipField: 'maintenanceReference',
      width: 250
    },
    {
      ...buildEnumColDef('Status', 'status', FindingStatus),
      width: 125
    }
  ];
}
