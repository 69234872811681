import { Component, OnInit } from '@angular/core';
import { E2gSelectOption } from '@equityeng/e2g-ng-ui';
import { forkJoin } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivityTypeDataService } from 'src/app/activity-type-data.service';
import { PlantsWithUnitsDto } from 'src/app/dashboard-module/models/plant-with-units-dto';
import { getScraAssetTypes } from 'src/app/models/enums/asset-types';
import { OnDestroyBaseComponent } from 'src/app/on-destroy-base-component/on-destroy-base-component';
import { PlantDataService } from 'src/app/plant-data.service';
import { DueDateReportDto } from 'src/app/reporting-module/models/due-date-report-dto';
import { DueDateReportBelowRetirements } from 'src/app/reporting-module/models/enums/due-date-report-below-retirements';
import { IDialogFormData } from 'src/app/shared-module/dialog/dialog-form-data';
import { DialogData } from 'src/app/shared-module/models/dialog-data';

import { DueDateReportFileTypes } from '../models/enums/due-date-report-file-types';
import { DueDateReportReportLevels } from '../models/enums/due-date-report-report-levels';
import { DueDateReportTypes } from '../models/enums/due-date-report-types';
import { InspectionDrivers } from '../models/enums/Inspection-drivers';

@Component({
  selector: 'app-due-date-report-dialog',
  templateUrl: './due-date-report-dialog.component.html',
  styleUrls: ['./due-date-report-dialog.component.css']
})
export class DueDateReportDialogComponent extends OnDestroyBaseComponent implements OnInit, IDialogFormData {
  public dialogData!: DialogData;
  public formData!: DueDateReportDto;
  public BelowRetirements = DueDateReportBelowRetirements;

  public plantsWithUnits: Array<PlantsWithUnitsDto> = [];
  public allPlants = new Array<E2gSelectOption>();
  public filteredUnits = new Array<E2gSelectOption>();

  public selectedPlants = new Array<string>();
  public selectedUnits = new Array<string>();
  public selectedActivityTypes = new Array<string>();

  public dueDateReportTypes = DueDateReportTypes;
  public reportLevelTypes = DueDateReportReportLevels;
  public exportTypes = DueDateReportFileTypes;

  public activityTypeList: Array<E2gSelectOption> = [];
  public reportLevelList: Array<E2gSelectOption> = [];
  public assetTypeList: Array<E2gSelectOption> = getScraAssetTypes();

  public isMaxInterval: boolean = true;
  public isHalfLife: boolean = true;
  public isSpecifiedDate: boolean = true;

  public displayLimit = 2;

  public constructor(private activityTypeService: ActivityTypeDataService, private plantService: PlantDataService) {
    super();
  }

  public ngOnInit(): void {
    this.dialogData.validityCallback = this.isValid;
    this.dialogData.beforeCloseCallback = this.beforeClose;

    forkJoin([this.plantService.getPlantsWithUnits(), this.activityTypeService.getActivityTypes()])
      .pipe(takeUntil(this.destroy))
      .subscribe(([plantsWithUnits, activityTypes]) => {
        this.plantsWithUnits = plantsWithUnits;
        this.allPlants = plantsWithUnits.nameIdToE2gSelectOptions();

        this.activityTypeList = activityTypes.map((x) => ({ value: x.id, label: x.name } as E2gSelectOption));
      });
  }

  public setDialogData(dialogData: DialogData): void {
    this.dialogData = dialogData;
  }

  public setFormData(formData: DueDateReportDto): void {
    this.formData = formData;
  }

  public onPlantChange(selectedPlantIds: string[]): void {
    const filtUnits = this.plantsWithUnits
      .filter((x) => selectedPlantIds.includes(x.id))
      .map((x) => x.units)
      .reduce((accumulator, value) => accumulator.concat(value), []);
    this.filteredUnits = filtUnits.nameIdToE2gSelectOptions();
    this.selectedUnits = this.selectedUnits.filter((s) => filtUnits.map((fu) => fu.id).includes(s));
  }

  private beforeClose = (): void => {
    if (this.isValid()) {
      this.selectedPlants.forEach((plantId) => {
        const plantName = this.allPlants.find((p) => p.value == plantId)?.label;
        if (plantName) {
          this.formData.plants.push({ id: plantId, name: plantName });
        }
      });
      this.selectedUnits.forEach((unitId) => {
        const unitName = this.filteredUnits.find((u) => u.value == unitId)?.label;
        if (unitName) {
          this.formData.units.push({ id: unitId, name: unitName });
        }
      });
      this.selectedActivityTypes.forEach((actTypeId) => {
        const actType = this.activityTypeList.find((atl) => atl.value == actTypeId)?.label;
        if (actType) {
          this.formData.activityTypes.push({ id: actTypeId, name: actType });
        }
      });

      if (this.formData.reportType == DueDateReportTypes.Activity) {
        this.formData.reportLevel = undefined;
      }
      if (
        this.formData.reportType == DueDateReportTypes.Thickness &&
        this.formData.reportLevel == DueDateReportReportLevels.CML
      ) {
        this.formData.inspectionDriverIds = [];
        if (this.isMaxInterval) {
          this.formData.inspectionDriverIds?.push(InspectionDrivers.MaximumInterval);
        }
        if (this.isHalfLife) {
          this.formData.inspectionDriverIds?.push(InspectionDrivers.HalfLife);
        }
        if (this.isSpecifiedDate) {
          this.formData.inspectionDriverIds?.push(InspectionDrivers.Specified);
        }
      }
    }
  };

  private isValid = (): boolean => {
    return (
      this.selectedPlants.length > 0 &&
      this.selectedUnits.length > 0 &&
      (this.formData.reportType === DueDateReportTypes.Activity
        ? this.formData.activityTypes === undefined
          ? false
          : this.selectedActivityTypes.length > 0
        : true) &&
      (this.formData.reportType !== DueDateReportTypes.Thickness ||
        this.formData.reportLevel !== DueDateReportReportLevels.CML ||
        this.isHalfLife ||
        this.isMaxInterval ||
        this.isSpecifiedDate)
    );
  };
}
