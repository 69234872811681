import { GroupDetailsDto } from 'src/app/asset-module/models/group-details-dto';
import { EquipmentConfigurationDto } from 'src/app/models/equipment-configuration-dto';

export class AssetDataCache {
  public data?: AssetDataDto;
}

export interface AssetDataDto {
  permissions: {
    readOnly: boolean;
    rbiEnabled: boolean;
    canAddCircuit: boolean;
  };
  assetConfig: EquipmentConfigurationDto;
  circuits: Array<GroupDetailsDto>;
}
