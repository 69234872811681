<div *ngIf="!loading; else load" class="row h-100 mr-0">
  <div class="col-6 flex-fill">
    <div class="row align-items-end mt-2 mb-2 m-0">
      <div class="col-6 pl-0">
        <e2g-ng-select-input
          id="select-damage-monitoring-location-status"
          heading="Status"
          placeholder="Select Status"
          [disabled]="disableStatus()"
          [required]="false"
          [options]="statusList!"
          [(ngModel)]="dataHandler.data.status"
          [readonly]="readOnly"
        ></e2g-ng-select-input>
      </div>

      <div class="col-6 pr-0">
        <app-evaluation-date-field [evaluationDate]="dataHandler.data.evaluationDate"></app-evaluation-date-field>
      </div>
    </div>

    <div class="form-section-header my-1">DML Information</div>

    <div class="row align-items-end mb-2 m-0">
      <div class="col-12 p-0">
        <e2g-ng-select-input
          id="select-damage-monitoring-location-component"
          heading="Component"
          placeholder="Select Component"
          [disabled]="false"
          [required]="true"
          [options]="componentsOptions!"
          [(ngModel)]="dataHandler.data.componentId"
          [readonly]="readOnly"
        ></e2g-ng-select-input>
      </div>
    </div>

    <div *ngIf="dataHandler.data.componentId">
      <div class="row align-items-end mb-2 m-0">
        <div class="col-12 p-0">
          <e2g-ng-select-input
            id="select-damage-monitoring-location-assessment-level"
            heading="API 579 Assessment"
            placeholder="Select API 579 Assessment"
            [disabled]="false"
            [required]="true"
            [options]="assessmentLevelList!"
            [(ngModel)]="dataHandler.data.assessmentLevel"
            [readonly]="readOnly"
          ></e2g-ng-select-input>
        </div>
      </div>

      <div class="row align-items-end mb-0 m-0">
        <div class="col-12 p-0">
          <hr class="seperator" />
        </div>
      </div>

      <div *ngIf="dataHandler.data.componentId">
        <div class="col p-0 mb-0">
          <h3 class="title m-0"><b>Inspection Data</b></h3>
        </div>

        <div class="row align-items-end mb-2 m-0">
          <div class="col-6 pl-0">
            <e2g-text-input
              heading="Pressurized Dent Depth {{ pressurizedDentDepthUom | async }}"
              id="inp-damage-monitoring-location-pressurized-dent-depth"
              type="number"
              [required]="false"
              [requiredForCalc]="true"
              [(ngModel)]="dataHandler.data.pressurizedDentDepth!"
              [readonly]="readOnly"
            ></e2g-text-input>
          </div>

          <div class="col-6 p-0">
            <e2g-text-input
              heading="Unpressurized Dent Depth {{ unpressurizedDentDepthUom | async }}"
              id="inp-damage-monitoring-location-unpressurized-dent-depth"
              type="number"
              [required]="false"
              [requiredForCalc]="false"
              [(ngModel)]="dataHandler.data.unpressurizedDentDepth!"
              [readonly]="readOnly"
            ></e2g-text-input>
          </div>
        </div>

        <div class="row align-items-end mb-2 m-0">
          <div class="col-6 pl-0">
            <e2g-text-input
              heading="Dent Radius {{ dentRadiusUom | async }}"
              id="inp-damage-monitoring-location-dent-radius"
              type="number"
              [required]="true"
              [requiredForCalc]="true"
              [(ngModel)]="dataHandler.data.dentRadius!"
              [readonly]="readOnly"
            ></e2g-text-input>
          </div>

          <div class="col-6 p-0">
            <e2g-text-input
              heading="Gouge Depth {{ gougeDepthUom | async }}"
              id="inp-damage-monitoring-location-gouge-depth"
              type="number"
              [required]="true"
              [requiredForCalc]="true"
              [(ngModel)]="dataHandler.data.gougeDepth!"
              [readonly]="readOnly"
            ></e2g-text-input>
          </div>
        </div>

        <div class="row align-items-end mb-2 m-0">
          <div class="col-6 pl-0">
            <e2g-text-input
              heading="Nearest Weld Distance {{ nearestWeldDistanceUom | async }}"
              id="inp-damage-monitoring-location-nearest-weld-distance"
              type="number"
              [required]="true"
              [requiredForCalc]="true"
              [(ngModel)]="dataHandler.data.nearestWeldDistance!"
              [readonly]="readOnly"
            ></e2g-text-input>
          </div>

          <div class="col-6 p-0">
            <e2g-text-input
              heading="Minimum CVN {{ minimumCvnUom | async }}"
              id="inp-damage-monitoring-location-minimum-cvn"
              type="number"
              [required]="true"
              [requiredForCalc]="false"
              [(ngModel)]="dataHandler.data.minimumCvn!"
              [readonly]="readOnly"
            ></e2g-text-input>
          </div>
        </div>

        <div *ngIf="dataHandler.data.assessmentLevel === 2" class="row align-items-end mb-2 m-0">
          <div class="col-6 pl-0">
            <e2g-text-input
              heading="Maximum Cyclic Pressure {{ maximumCyclicPressureUom | async }}"
              id="inp-damage-monitoring-location-maximum-cyclic-pressure"
              type="number"
              [required]="false"
              [requiredForCalc]="false"
              [(ngModel)]="dataHandler.data.maximumCyclicPressure!"
              [readonly]="readOnly"
            ></e2g-text-input>
          </div>

          <div class="col-6 p-0">
            <e2g-text-input
              heading="Minimum Cyclic Pressure {{ minimumCyclicPressureUom | async }}"
              id="inp-damage-monitoring-location-minimum-cyclic-pressure"
              type="number"
              [requiredForCalc]="false"
              [(ngModel)]="dataHandler.data.minimumCyclicPressure!"
              [readonly]="readOnly"
            ></e2g-text-input>
          </div>
        </div>

        <div class="row m-0">
          <div class="col-12 pl-0 pr-0">
            <e2g-textarea-input
              id="inp-comments"
              class="w-100 pr-2"
              heading="Comments"
              [rows]="5"
              [readonly]="readOnly"
              [maxlength]="512"
              [(ngModel)]="dataHandler.data.comments"
            ></e2g-textarea-input>
          </div>
        </div>
      </div>
      <div class="col-6 flex-col flex-fill my-2"></div>
    </div>
  </div>
</div>

<ng-template #load>
  <div class="loading"></div>
</ng-template>
