<div class="modal-header">
  <div class="form-section-header">Edit Material</div>
</div>
<div class="modal-body">
  <div *ngIf="materialDomainModel.isInitialized; else loading">
    <div class="row">
      <div class="col-sm-6">
        <app-br-text
          propName="allowableTensileStress"
          heading="Allowable Tensile Stress"
          [readOnly]="readOnly"
          [overriddenDefaultWarning]="isMaterialChosen"
          type="number"
        ></app-br-text>
        <app-br-text
          propName="yieldStress"
          heading="Yield Stress"
          [readOnly]="readOnly"
          [overriddenDefaultWarning]="isMaterialChosen"
          type="number"
        ></app-br-text>
        <app-br-text
          propName="ultimateTensileStress"
          heading="Ultimate Tensile Stress"
          [readOnly]="readOnly"
          [overriddenDefaultWarning]="isMaterialChosen"
          type="number"
        ></app-br-text>
        <app-br-text
          propName="youngsModulus"
          heading="Youngs Modulus"
          [readOnly]="readOnly"
          [overriddenDefaultWarning]="isMaterialChosen"
          type="number"
        ></app-br-text>
        <app-br-select
          propName="externalPressureChart"
          heading="External Pressure Chart"
          [readOnly]="readOnly"
          [overriddenDefaultWarning]="isMaterialChosen"
        ></app-br-select>
      </div>
      <div class="col-sm-6">
        <app-br-select
          propName="toughnessCurveDesignation"
          heading="Toughness Curve Designation"
          [readOnly]="readOnly"
          [overriddenDefaultWarning]="isMaterialChosen"
        ></app-br-select>
        <app-br-select
          propName="unsGroup"
          heading="UNS Group"
          [readOnly]="readOnly"
          (change$)="unsGroupTrigger.next()"
          [overriddenDefaultWarning]="isMaterialChosen"
        ></app-br-select>
        <app-br-select
          propName="unsSubGroup"
          heading="Subgroup"
          [readOnly]="readOnly"
          [disableReset]="materialDomainModel.unsSubGroup.getDisableReset!()"
          [otherTriggers]="[unsGroupTrigger]"
          (change$)="unsSubGroupTrigger.next()"
          [overriddenDefaultWarning]="isMaterialChosen"
        ></app-br-select>
        <app-br-select
          propName="materialCommonName"
          heading="Common Name"
          [readOnly]="readOnly"
          [disableReset]="materialDomainModel.materialCommonName.getDisableReset!()"
          [otherTriggers]="[unsSubGroupTrigger]"
          [overriddenDefaultWarning]="isMaterialChosen"
        ></app-br-select>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer d-flex justify-content-between">
  <button (click)="cancel()" id="btn-cancel" class="btn e2g-btn-outline-primary">Cancel</button>
  <button (click)="close()" id="btn-close" class="btn e2g-btn-solid-primary">Close</button>
</div>
<ng-template #loading>
  <div class="loading"></div>
</ng-template>
