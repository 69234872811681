import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AppSettingsService } from '../../app-settings/app-settings.service';
import { FindingDto } from '../models/finding-dto';


@Injectable({
  providedIn: 'root'
})
export class FindingDataService {
  private serverUri: string;

  public constructor(private http: HttpClient, private settingsService: AppSettingsService) {
    this.serverUri = this.settingsService.settings.apiUri as string;
  }

  public getFindings(assetId: string): Observable<Array<FindingDto>> {
    return this.http
      .get<Array<FindingDto>>(`${this.serverUri}/assets/${assetId}/findings`)
      .pipe(catchError(() => of(new Array<FindingDto>())));
  }

  public getActivityFindings(activityId: string): Observable<Array<FindingDto>> {
    return this.http
      .get<Array<FindingDto>>(`${this.serverUri}/activityId/${activityId}/findings`)
      .pipe(catchError(() => of(new Array<FindingDto>())));
  }

  public getFinding(id: string): Observable<FindingDto> {
    return this.http
      .get<FindingDto>(`${this.serverUri}/findings/${id}`)
      .pipe(catchError(() => of({})));
  }

  public deleteFinding(findingId: string): Observable<boolean> {
    return this.http.delete(`${this.serverUri}/findings/${findingId}`).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  public saveFinding(data: FindingDto): Observable<string | boolean> {
    if (data.id) {
      // This is an update since we have the finding Id.
      return this.http.put(`${this.serverUri}/findings`, data).pipe(
        map(() => true),
        catchError(() => of(false))
      );
    } else {
      // This is an add since we don't have the finding Id.
      return this.http.post<string>(`${this.serverUri}/findings`, data);
    }
  }
}
