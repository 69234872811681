import { Injectable } from '@angular/core';
import { AuthService } from '@equityeng/auth';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { catchError, concatMap, EMPTY, from, lastValueFrom, map, startWith, Subject, switchMap, take, tap } from 'rxjs';

import { AppSettingsService } from './app-settings/app-settings.service';
import { CompanyService } from './company.service';
import { AssetCopiedEvent } from './hierarchy-tree-module/models/equipment-copied-event';
import { UnitCopiedEvent } from './hierarchy-tree-module/models/unit-copied-event';
import { CalcUpdateDto } from './models/calculation-impacted-ids-dto';
import { Company } from './models/company.model';
import { RefreshService } from './notifications-module/refresh.service';
import { JobNotificationService } from './notifications-module/services/job-notifications.service';
import { NotificationService } from './shared-module/services/notification.service';

@Injectable({
  providedIn: 'root'
})
export class SignalServiceScra {
  private connection: any;
  private previousCompanyKey?: string;
  private userKey?: string;
  private reregister = new Subject<void>();
  public erroredEvent: Subject<string> = new Subject<string>();
  public assetCopiedEvent: Subject<AssetCopiedEvent> = new Subject<AssetCopiedEvent>();
  public unitCopiedEvent: Subject<UnitCopiedEvent> = new Subject<UnitCopiedEvent>();
  public importExportEvent: Subject<any> = new Subject<any>();

  public constructor(
    public companyService: CompanyService,
    private authService: AuthService,
    private settingsService: AppSettingsService,
    private notificationService: NotificationService,
    private jobNotificationService: JobNotificationService,
    private refreshService: RefreshService
  ) {}

  public initiateSignalrConnection(): void {
    this.connection = new HubConnectionBuilder()
      .withUrl(`${this.settingsService.settings.apiUri}/hubs/notifications`, {
        accessTokenFactory: () => this.getAccessToken()
      })
      .configureLogging(LogLevel.None)
      .withAutomaticReconnect()
      .build();

    from(this.connection.start())
      .pipe(
        tap(() => {
          this.setSignalrClientMethods();
          this.addReconnectHandler(this.connection);
        }),
        concatMap(() => this.reregister.pipe(startWith(undefined))),
        switchMap(() => this.companyService.selectedCompany),
        tap((company) => {
          this.updateTenant(this.connection, company);
        }),
        catchError((err) => {
          return EMPTY;
        })
      )
      .subscribe();
  }

  private getAccessToken(): Promise<string> {
    return lastValueFrom(
      this.authService.userAuthenticated$.pipe(
        tap((user) => (this.userKey = user.userKey)),
        map((user) => user.accessToken),
        take(1)
      )
    );
  }

  private updateTenant(connection: signalR.HubConnection, company: Company): void {
    if (this.previousCompanyKey) {
      connection.invoke('LeaveTenant', this.previousCompanyKey);
    }
    connection.invoke('JoinTenant', company.key);
    this.previousCompanyKey = company.key;
  }

  private addReconnectHandler(connection: signalR.HubConnection): void {
    connection.onreconnected(() => this.reregister.next());
  }

  private setSignalrClientMethods(): void {
    this.connection.on('erroredEvent', (eventType: string) => {
      this.notificationService.showError(`${eventType} Error Encountered`);
    });

    this.connection.on('notifyUserEvent', (message: any) => {
      this.jobNotificationService.refreshNotifications();
    });

    this.connection.on('importExportEvent', (message: any) => {
      this.importExportEvent.next(message);
    });

    this.connection.on('assetCopiedEvent', (assetCopiedEventModel: AssetCopiedEvent) => {
      this.assetCopiedEvent.next(assetCopiedEventModel);
    });

    this.connection.on('unitCopiedEvent', (event: UnitCopiedEvent) => {
      this.unitCopiedEvent.next(event);
    });

    this.connection.on('calculationUpdate', (data: CalcUpdateDto) => {
      if (data.userKey === this.userKey) {
        this.jobNotificationService.refreshNotifications();
      }
      this.refreshService.triggerDataUpdate(data);
    });
  }
}
