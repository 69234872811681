import { ColDef, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { IGridCellRbiParams, RbiRenderType } from 'src/app/grid-module/cell-renders/grid-cell-rbi/grid-cell-rbi-params';
import { GridCellRbiComponent } from 'src/app/grid-module/cell-renders/grid-cell-rbi/grid-cell-rbi.component';
import { buildComponentSizeColDef } from 'src/app/grid-module/column-builders/build-component-size';
import { buildEnumColDef } from 'src/app/grid-module/column-builders/build-enum';
import { componentTypeComparator } from 'src/app/grid-module/column-data-comparers/component-type-comparator';
import { doubleWithNullComparator } from 'src/app/grid-module/column-data-comparers/double-with-null-comparator';
import { getMinimumThicknessColumn } from 'src/app/grid-module/column-definitions/minimum-thickness-column';
import { decimalFormatter } from 'src/app/grid-module/column-formatters/decimal-formatter';
import { ComponentStatuses } from 'src/app/models/enums/component-statuses';

import { GridCheckboxComponent } from '../cell-renders/grid-checkbox/grid-checkbox.component';
import { toFixedThreeFormatter } from '../column-formatters/fixed-three-formatter';

export function getComponentGridColumns(): Array<ColDef> {
  return [
    {
      headerName: 'Id',
      field: 'id',
      hide: true
    },
    {
      headerName: 'Description',
      headerTooltip: 'Description',
      field: 'description',
      tooltipField: 'description',
      width: 200
    },
    {
      headerName: 'Comments',
      headerTooltip: 'Comments',
      field: 'comments',
      tooltipField: 'comments',
      width: 300,
      hide: true
    },
    {
      headerName: 'Rbi',
      headerTooltip: 'Rbi Active',
      field: 'rbi',
      width: 100,
      cellClass: 'checkbox-grid-cell',
      cellRenderer: GridCheckboxComponent,
      cellRendererParams: { readonly: true }
    },
    {
      headerName: 'Risk',
      headerTooltip: 'Risk Category',
      field: 'riskCategory',
      tooltipField: 'riskCategory',
      cellRenderer: GridCellRbiComponent,
      cellRendererParams: <IGridCellRbiParams>{
        rbiColor: (params) => params.data.riskColor,
        rbiRenderType: RbiRenderType.circleIcon
      }
    },
    {
      headerName: 'Type',
      headerTooltip: 'Type',
      field: 'type',
      tooltipField: 'type',
      comparator: componentTypeComparator
    },
    buildComponentSizeColDef('Size', 'size'),
    {
      headerName: 'Thickness',
      headerTooltip: 'Nominal Thickness',
      field: 'nominalThickness',
      tooltipField: 'nominalThickness',
      width: 180,
      comparator: doubleWithNullComparator,
      valueGetter: toFixedThreeFormatter,
      valueFormatter: (params: ValueFormatterParams): any => toFixedThreeFormatter(params),
      filterValueGetter: (params: ValueGetterParams): any => Number(decimalFormatter(params)),
      filter: 'agNumberColumnFilter'
    },
    {
      headerName: 'Material',
      headerTooltip: 'Material Of Construction',
      field: 'material',
      tooltipField: 'material',
      filterValueGetter: (params: ValueGetterParams): string => {
        return params.data?.material ? params.data.material.toString().trim() : '';
      }
    },
    {
      headerName: 'CA',
      headerTooltip: 'Corrosion Allowance',
      field: 'corrosionAllowance',
      tooltipField: 'corrosionAllowance',
      valueFormatter: toFixedThreeFormatter,
      filter: 'agNumberColumnFilter'
    },
    { ...getMinimumThicknessColumn(), headerName: 'T-min' },
    {
      headerName: 'MAWP',
      headerTooltip: 'Maximum Allowable Working Pressure',
      field: 'maximumAllowableWorkingPressure',
      tooltipField: 'maximumAllowableWorkingPressure',
      valueFormatter: toFixedThreeFormatter,
      comparator: doubleWithNullComparator,
      valueGetter: decimalFormatter,
      filterValueGetter: (params: ValueGetterParams): any => Number(decimalFormatter(params)),
      filter: 'agNumberColumnFilter'
    },
    {
      headerName: 'CML Count',
      headerTooltip: 'CML Count',
      field: 'conditionMonitoringLocationsCount',
      tooltipField: 'conditionMonitoringLocationsCount',
      valueGetter: (params: ValueGetterParams): any =>
        params.data.conditionMonitoringLocationsCount === 0 ? '' : params.data.conditionMonitoringLocationsCount,
      width: 150,
      filter: 'agNumberColumnFilter'
    },
    {
      ...buildEnumColDef('Status', 'status', ComponentStatuses),
      hide: true,
      width: 100
    }
  ];
}
