export interface AssetPermissionModel {
  edit: boolean;
  delete: boolean;
}

export const NO_PERMISSION: AssetPermissionModel = {
  edit: false,
  delete: false
};

export enum AssetFeature {
  Asset = 1,
  Component = 2,
  Cml = 3,
  Circuit = 4,
  Activity = 5,
  Finding = 6,
  Dml = 7,
  Comments = 8
}

export enum GeneralFeature {
  Admin = 1,
  Rbi = 2,
  Dmls = 3,
  ScraRates = 4,
  ForceCalculation = 5,
  SageDiagnostics = 6,
  ManageRoles = 7,
  ViewHierarchyPermissions = 8,
  AssignHierarchyAdmins = 9,
  SAGE = 10,
  RbiExpertView = 11
}

export interface AssetFeatureQuery {
  feature: AssetFeature;
  assetId: string;
}

export interface GeneralFeatureQuery {
  feature: GeneralFeature;
}
