import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { AuthService } from '@equityeng/auth';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { CompanyService } from '../company.service';
import { PRODUCT_NAME } from '../models/auth-constants';

@Injectable({
  providedIn: 'root'
})
export class HadAnyFeatureOrPermission implements CanActivate {
  public constructor(private authService: AuthService, private companyService: CompanyService) {}

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const features: Array<string> = route.data?.features || [];
    const permissions: Array<string> = route.data?.permissions || [];

    return this.companyService.selectedCompany.pipe(
      switchMap(() =>
        this.authService.userAuthenticated$.pipe(
          map((user) =>
            [
              ...features.map((f) => user.hasAccessToFeature(PRODUCT_NAME, f)),
              ...permissions.map((p) => user.hasPermission(p))
            ].some((x) => x === true)
          )
        )
      )
    );
  }
}
