<div *ngIf="!loading; else load" class="d-flex flex-col">
  <div class="row mt-2 mr-0">
    <div class="col-6">
      <div class="row align-items-end mb-2 m-0">
        <div class="col-6 pl-0">
          <e2g-ng-select-input
            id="select-damage-monitoring-location-status"
            heading="Status"
            placeholder="Select Status"
            [disabled]="disableStatus()"
            [required]="false"
            [options]="statusList!"
            [(ngModel)]="dataHandler.data.status"
            [readonly]="readOnly"
          ></e2g-ng-select-input>
        </div>

        <div class="col-6">
          <app-evaluation-date-field [evaluationDate]="dataHandler.data.evaluationDate"></app-evaluation-date-field>
        </div>
      </div>

      <div class="form-section-header my-1">DML Information</div>

      <div class="row align-items-end mb-2 m-0">
        <div class="col-12 pl-0">
          <e2g-ng-select-input
            id="select-damage-monitoring-location-component"
            heading="Component"
            placeholder="Select Component"
            [disabled]="false"
            [required]="true"
            [options]="componentsOptions!"
            [(ngModel)]="dataHandler.data.componentId"
            (change)="componentChange($event)"
            [readonly]="readOnly"
          ></e2g-ng-select-input>
        </div>
      </div>

      <div *ngIf="dataHandler.data.componentId">
        <div class="row align-items-end mb-2 m-0">
          <div class="col-6 pl-0">
            <e2g-ng-select-input
              id="select-damage-monitoring-location-assessment-level"
              heading="API 579 Assessment"
              placeholder="Select API 579 Assessment"
              [disabled]="false"
              [required]="true"
              [options]="assessmentLevelList!"
              [(ngModel)]="dataHandler.data.assessmentLevel"
              [readonly]="readOnly"
            ></e2g-ng-select-input>
          </div>

          <div class="col-6">
            <e2g-ng-select-input
              id="select-damage-monitoring-location-damage-location"
              heading="Damage Location"
              placeholder="Select Damage Location"
              [disabled]="false"
              [required]="true"
              [options]="damageLocationOptions!"
              [(ngModel)]="dataHandler.data.damageLocation"
              [readonly]="readOnly"
            ></e2g-ng-select-input>
          </div>
        </div>

        <div class="row align-items-end mb-1 m-0">
          <div class="col-12 p-0 pr-3">
            <hr class="seperator" />
          </div>
        </div>

        <div class="row align-items-end mb-2 m-0">
          <div class="col-6 pl-0">
            <e2g-ng-select-input
              id="select-damage-monitoring-location-crack-description"
              heading="Crack Description"
              placeholder="Select Crack Description"
              [disabled]="false"
              [required]="false"
              [requiredForCalc]="true"
              [options]="crackDescriptionOptions!"
              [(ngModel)]="dataHandler.data.crackDescription"
              [readonly]="readOnly"
            ></e2g-ng-select-input>
          </div>
        </div>

        <div class="row align-items-end mb-2 m-0">
          <div class="col-6 pl-0">
            <e2g-text-input
              heading="Flaw Size - Thickness Direction {{ flawSizeThicknessDirectionUom | async }}"
              id="inp-damage-monitoring-location-flaw-size-thickness-direction"
              type="number"
              [required]="false"
              [requiredForCalc]="true"
              [(ngModel)]="dataHandler.data.flawSizeThicknessDirection"
              [readonly]="readOnly"
            ></e2g-text-input>
          </div>

          <div class="col-6">
            <e2g-text-input
              heading="Flaw Size - Surface Direction {{ flawSizeSurfaceDirectionUom | async }}"
              id="inp-damage-monitoring-location-flaw-size-surface-direction"
              type="number"
              [required]="false"
              [requiredForCalc]="true"
              [(ngModel)]="dataHandler.data.flawSizeSurfaceDirection"
              [readonly]="readOnly"
            ></e2g-text-input>
          </div>
        </div>

        <div class="row m-0">
          <div class="col-12 pl-0">
            <e2g-textarea-input
              id="inp-comments"
              class="w-100 pr-2"
              heading="Comments"
              [rows]="5"
              [readonly]="readOnly"
              [maxlength]="512"
              [(ngModel)]="dataHandler.data.comments"
            ></e2g-textarea-input>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #load>
  <div class="loading"></div>
</ng-template>
