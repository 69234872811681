import { ColDef } from 'ag-grid-community';

import { buildArrayColDef } from '../column-builders/build-array';
import { buildDateOnlyColDef } from '../column-builders/build-date-only';
import { assignHeaderAndTooltip } from '../column-builders/build-default';

export function getActivityHistoryGridColumns(): Array<ColDef> {
  return [
    {
      headerName: 'ID',
      field: 'id',
      hide: true
    },
    {
      ...assignHeaderAndTooltip('Type', 'activityType'),
      width: 300
    },
    {
      ...buildDateOnlyColDef('Date', 'date'),
      width: 120
    },
    {
      ...buildArrayColDef('Inspector', 'inspectorNames'),
      width: 300
    },
    {
      ...assignHeaderAndTooltip('Description', 'description'),
      flex: 1
    },
    {
      ...assignHeaderAndTooltip('Component', 'componentName'),
      hide: true
    }
  ];
}
