export class PlotlyColors {
  public static gridLines = '#e4e6e7'; //Color-neutral-100
  public static titleColor = '#002d74'; //Color-primary-500

  public static fgBlack = '#000000';
  public static fgGrey = '#52575c';
  public static fgBlue = '#002d74';
  public static fgLightblue = '#0098c2';
  public static fgGreen = '#117353';
  public static fgPink = '#d951c3';
  public static fgRed = '#c21f19';
  public static fgOrange = '#d66800';
  public static fgYellow = '#b5990d';
  public static fgLightgreen = '#20b149';
  public static fgPurple = '#8330a9';
  public static fgLightyellow = '#efc328';
  public static fgLightred = '#eb716c';
  public static fgLightorange = '#ff9900';
}
