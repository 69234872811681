import { E2gSelectOption } from '@equityeng/e2g-ng-ui';
import { Observable } from 'rxjs';

import { DataViewState } from './enums/data-view-state';

export const GET_VALID_VALUES = 'getValidValues';
export const GET_DEFAULT_VALUE = 'getDefaultValue';
export const GET_REQUIRED = 'getRequired';

export interface BusinessRuleValues {
  getValidValues?: (data?: Record<string, any>) => Observable<Array<E2gSelectOption>>;
  getDefaultValue?: (data?: Record<string, any>) => Observable<any>;
  getRequired?: (data?: Record<string, any>) => boolean;
  getVisible?: (data?: Record<string, any>) => boolean;
  getDataViewState?: (data?: Record<string, any>) => DataViewState;
  getDisableReset?: (data?: Record<string, any>) => boolean;
}
