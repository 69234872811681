export enum GoverningCorrosionRateTypes {
  LongRate = 1,
  ShortRate = 2,
  BestFitRate = 3,
  ScraLongRate = 4,
  ScraShortRate = 5,
  ScraStatShortRate = 6,
  ScraStatLongRate = 7,
  EstimatedRate = 8
}

export function getGoverningCorrosionRateType(type: GoverningCorrosionRateTypes): string {
  switch (type) {
    case GoverningCorrosionRateTypes.LongRate: {
      return 'Long Rate';
    }
    case GoverningCorrosionRateTypes.ShortRate: {
      return 'Short Rate';
    }
    case GoverningCorrosionRateTypes.BestFitRate: {
      return 'Best Fit Rate';
    }
    case GoverningCorrosionRateTypes.ScraLongRate: {
      return 'SCRA Long Rate';
    }
    case GoverningCorrosionRateTypes.ScraShortRate: {
      return 'SCRA Short Rate';
    }
    case GoverningCorrosionRateTypes.ScraStatShortRate: {
      return 'SCRA Stat Short Rate';
    }
    case GoverningCorrosionRateTypes.ScraStatLongRate: {
      return 'SCRA Stat Long Rate';
    }
    case GoverningCorrosionRateTypes.EstimatedRate: {
      return 'Estimated Rate';
    }
    default:
      return '';
  }
}
