import { AssetDefaultsConfigurationDto } from 'src/app/asset-module/models/asset-defaults-configuration-dto';

import { SingleDataHandlerDefault } from '../single-data-handler-default';

export class AssetGoverningInspectionDomainModel<T extends AssetDefaultsConfigurationDto> {
  private assetGoverningInspectionDataHandler!: SingleDataHandlerDefault<T>;

  public constructor(private dataHandler: SingleDataHandlerDefault<T>) {
    this.assetGoverningInspectionDataHandler = dataHandler!;
  }

  public get isLongRateGoverning(): boolean {
    return this.assetGoverningInspectionDataHandler.data.isLongRateGoverning;
  }

  public set isLongRateGoverning(value: boolean) {
    this.assetGoverningInspectionDataHandler.data.isLongRateGoverning = value;
    this.removeGoverningCorrosionRateDefault();
  }

  public get isShortRateGoverning(): boolean {
    return this.assetGoverningInspectionDataHandler.data.isShortRateGoverning;
  }

  public set isShortRateGoverning(value: boolean) {
    this.assetGoverningInspectionDataHandler.data.isShortRateGoverning = value;
    this.removeGoverningCorrosionRateDefault();
  }

  public get isLinearRateGoverning(): boolean {
    return this.assetGoverningInspectionDataHandler.data.isLinearRateGoverning;
  }

  public set isLinearRateGoverning(value: boolean) {
    this.assetGoverningInspectionDataHandler.data.isLinearRateGoverning = value;
    this.removeGoverningCorrosionRateDefault();
  }

  public get isScraMeasuredLongRateGoverning(): boolean {
    return this.assetGoverningInspectionDataHandler.data.isScraMeasuredLongRateGoverning;
  }

  public set isScraMeasuredLongRateGoverning(value: boolean) {
    this.assetGoverningInspectionDataHandler.data.isScraMeasuredLongRateGoverning = value;
    this.removeGoverningCorrosionRateDefault();
  }

  public get isScraMeasuredShortRateGoverning(): boolean {
    return this.assetGoverningInspectionDataHandler.data.isScraMeasuredShortRateGoverning;
  }

  public set isScraMeasuredShortRateGoverning(value: boolean) {
    this.assetGoverningInspectionDataHandler.data.isScraMeasuredShortRateGoverning = value;
    this.removeGoverningCorrosionRateDefault();
  }

  public get isEstimatedCorrosionRate(): boolean {
    return this.assetGoverningInspectionDataHandler.data.isEstimatedCorrosionRate;
  }

  public set isEstimatedCorrosionRate(value: boolean) {
    this.assetGoverningInspectionDataHandler.data.isEstimatedCorrosionRate = value;
    this.removeGoverningCorrosionRateDefault();
  }

  public get estimatedCorrosionRate(): number | undefined {
    return this.assetGoverningInspectionDataHandler.data.estimatedCorrosionRate;
  }

  public set estimatedCorrosionRate(value: number | undefined) {
    this.assetGoverningInspectionDataHandler.data.estimatedCorrosionRate = value;
    this.removeGoverningCorrosionRateDefault();
  }

  public get isScraStatisticalLongRateGoverning(): boolean {
    return this.assetGoverningInspectionDataHandler.data.isScraStatisticalLongRateGoverning;
  }

  public set isScraStatisticalLongRateGoverning(value: boolean) {
    this.assetGoverningInspectionDataHandler.data.isScraStatisticalLongRateGoverning = value;
    this.removeGoverningCorrosionRateDefault();
  }

  public get isScraStatisticalShortRateGoverning(): boolean {
    return this.assetGoverningInspectionDataHandler.data.isScraStatisticalShortRateGoverning;
  }

  public set isScraStatisticalShortRateGoverning(value: boolean) {
    this.assetGoverningInspectionDataHandler.data.isScraStatisticalShortRateGoverning = value;
    this.removeGoverningCorrosionRateDefault();
  }

  public get useMawpApproach(): boolean {
    return this.assetGoverningInspectionDataHandler.data.useMawpApproach;
  }

  public set useMawpApproach(value: boolean) {
    this.assetGoverningInspectionDataHandler.data.useMawpApproach = value;
    this.removeGoverningMinimumThicknessDefault();
  }

  public get isRetirementThickness(): boolean {
    return this.assetGoverningInspectionDataHandler.data.isRetirementThickness;
  }

  public set isRetirementThickness(value: boolean) {
    this.assetGoverningInspectionDataHandler.data.isRetirementThickness = value;
    this.removeGoverningMinimumThicknessDefault();
  }

  public get isStructuralRequiredThickness(): boolean {
    return this.assetGoverningInspectionDataHandler.data.isStructuralRequiredThickness;
  }

  public set isStructuralRequiredThickness(value: boolean) {
    this.assetGoverningInspectionDataHandler.data.isStructuralRequiredThickness = value;
    this.removeGoverningMinimumThicknessDefault();
  }

  public get isCorrodedThickness(): boolean {
    return this.assetGoverningInspectionDataHandler.data.isCorrodedThickness;
  }

  public set isCorrodedThickness(value: boolean) {
    this.assetGoverningInspectionDataHandler.data.isCorrodedThickness = value;
    this.removeGoverningMinimumThicknessDefault();
  }

  public get isSpecifiedThickness(): boolean {
    return this.assetGoverningInspectionDataHandler.data.isSpecifiedThickness;
  }

  public set isSpecifiedThickness(value: boolean) {
    this.assetGoverningInspectionDataHandler.data.isSpecifiedThickness = value;
    this.removeGoverningMinimumThicknessDefault();
  }

  public get specifiedMinimumThickness(): number | undefined {
    return this.assetGoverningInspectionDataHandler.data.specifiedMinimumThickness;
  }

  public set specifiedMinimumThickness(value: number | undefined) {
    this.assetGoverningInspectionDataHandler.data.specifiedMinimumThickness = value;
    this.removeGoverningMinimumThicknessDefault();
  }

  public get isMaximumInterval(): boolean {
    return this.assetGoverningInspectionDataHandler.data.isMaximumInterval;
  }

  public set isMaximumInterval(value: boolean) {
    this.assetGoverningInspectionDataHandler.data.isMaximumInterval = value;
    this.removeCmlDueDateBasisDefault();
  }

  public get maximumInterval(): number | undefined {
    return this.assetGoverningInspectionDataHandler.data.maximumInterval;
  }

  public set maximumInterval(value: number | undefined) {
    this.assetGoverningInspectionDataHandler.data.maximumInterval = value;
    this.removeCmlDueDateBasisDefault();
  }

  public get halfLife(): boolean {
    return this.assetGoverningInspectionDataHandler.data.halfLife;
  }

  public set halfLife(value: boolean) {
    this.assetGoverningInspectionDataHandler.data.halfLife = value;
    this.removeCmlDueDateBasisDefault();
  }

  public get dirty(): boolean {
    return this.assetGoverningInspectionDataHandler.dirty;
  }

  public silentClearValue(value: string): void {
    this.assetGoverningInspectionDataHandler.silentClearValue(value);
  }

  public silentSetValue(prop: string, value: any, triggerDataChanged: boolean = false): void {
    this.assetGoverningInspectionDataHandler.silentSetValue(prop, value, triggerDataChanged);
  }

  public isDefaultValue(prop: string): boolean {
    return this.assetGoverningInspectionDataHandler.isDefaultValue(prop);
  }

  private removeGoverningCorrosionRateDefault(): void {
    (this.assetGoverningInspectionDataHandler.data as any)['governingCorrosionRate'] = 0;
  }

  private removeGoverningMinimumThicknessDefault(): void {
    (this.assetGoverningInspectionDataHandler.data as any)['governingMinimumThickness'] = 0;
  }

  private removeCmlDueDateBasisDefault(): void {
    (this.assetGoverningInspectionDataHandler.data as any)['cmlDueDateBasis'] = 0;
  }
}
