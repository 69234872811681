import { ColDef, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { assignHeaderAndTooltip } from 'src/app/grid-module/column-builders/build-default';

export function buildComponentSizeColDef(header: string, field: string): ColDef {
  return {
    ...assignHeaderAndTooltip(header, field),
    valueFormatter: formatComponentSizeWithReplacedUnderscores,
    valueGetter: formatComponentSizeWithReplacedUnderscores
  };
}

function formatComponentSizeWithReplacedUnderscores(params: ValueFormatterParams | ValueGetterParams): string {
  const dataItem = (params.data[params.colDef.field || ''] as string) || '';
  const numbers = dataItem.match(/[\d.]+/g)!;
  if (numbers == null || numbers.length == 0) {
    return '';
  }

  const searchRegExp = /_/gi;
  return dataItem ? dataItem.replace(searchRegExp, ' ') : '';
}
