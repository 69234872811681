import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { E2gSelectOption } from '@equityeng/e2g-ng-ui';
import { combineLatest, forkJoin, Observable, of, Subject } from 'rxjs';
import { concatMap, filter, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { AttachmentTableType } from 'src/app/attachments-module/models/attachment-table-type';
import { CompanyService } from 'src/app/company.service';
import { EquipmentDataService } from 'src/app/equipment-data.service';
import { AssetTypes, getScraAssetType } from 'src/app/models/enums/asset-types';
import { ServiceStatus } from 'src/app/models/enums/service-status';
import { EquipmentDto } from 'src/app/models/equipment-dto';
import { EquipmentUnitInput } from 'src/app/models/equipment-unit-input';
import { OnDestroyBaseComponent } from 'src/app/on-destroy-base-component/on-destroy-base-component';
import { BUSINESS_RULES } from 'src/app/sage-common-module/business-rules-injection-token';
import { BusinessRulesDefinition } from 'src/app/sage-common-module/models/business-rules-definition';
import { ISaveChanges } from 'src/app/save-changes';
import { AssetFeature, GeneralFeature } from 'src/app/shared-module/permission/permission-models';
import { PermissionService } from 'src/app/shared-module/permission/permission.service';
import { NotificationService } from 'src/app/shared-module/services/notification.service';
import { SingleDataHandlerDefault } from 'src/app/shared-module/single-data-handler-default';

import { PrdDto } from '../models/prd-dto';
import { PrdBusinessRulesService } from '../prd-business-rules.service';
import { PrdDataService } from '../prd-data.service';

@Component({
  selector: 'app-prd-data',
  templateUrl: './prd-data.component.html',
  styleUrls: ['./prd-data.component.css'],
  providers: [
    {
      provide: BUSINESS_RULES,
      useClass: PrdBusinessRulesService
    },
    {
      provide: ISaveChanges,
      useExisting: PrdDataComponent
    }
  ]
})
export class PrdDataComponent extends OnDestroyBaseComponent implements OnInit, ISaveChanges {
  public refreshData = new Subject<void>();
  public inputData!: EquipmentUnitInput;
  public dataHandler: SingleDataHandlerDefault<PrdDto>;
  public activeTabId: number = 1;
  public readOnly: boolean = false;
  public sageEnabled: boolean = false;

  public equipType = getScraAssetType(AssetTypes.PressureReliefDevice);
  public serviceStatusList: Array<E2gSelectOption> | undefined = [] as Array<E2gSelectOption>;

  public itemChecked: boolean = true;

  private assetsToSelect: Array<E2gSelectOption> | undefined = [] as Array<E2gSelectOption>;

  public unselectedLabel: string = 'Available Assets';
  public selectedLabel: string = 'Protected Assets';
  public tableType = AttachmentTableType.Asset;
  public attachmentKey?: string;

  private assets!: Array<EquipmentDto>;

  public constructor(
    @Inject(BUSINESS_RULES) private businessRulesService: BusinessRulesDefinition,
    private permissionService: PermissionService,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private dataService: PrdDataService,
    private equipDataService: EquipmentDataService,
    private companyService: CompanyService
  ) {
    super();

    this.dataHandler = new SingleDataHandlerDefault<PrdDto>(this.destroy, undefined, this.companyService);

    this.businessRulesService.init(this.dataHandler);
  }

  public ngOnInit(): void {
    combineLatest([this.route.params.pipe(take(1))])
      .pipe(
        tap(([params]) => {
          this.setInputData(params);
        }),
        filter(() => this.inputData.equipmentKey !== undefined && this.inputData.unitKey !== undefined),
        switchMap(() =>
          this.permissionService.getPermissions({
            asset: [{ feature: AssetFeature.Asset, assetId: this.inputData.equipmentKey! }],
            general: [{ feature: GeneralFeature.SAGE }]
          })
        ),
        tap((permission) => {
          this.readOnly = !permission.asset[AssetFeature.Asset].edit;
          this.businessRulesService.readOnly = this.readOnly;
          this.sageEnabled = permission.general[GeneralFeature.SAGE];
        }),
        switchMap(() => this.refreshData.pipe(startWith(null))),
        switchMap(() =>
          forkJoin([
            this.dataService.getPrd(this.inputData.equipmentKey),
            this.equipDataService.getEquipment(this.inputData.unitKey)
          ])
        ),
        takeUntil(this.destroy)
      )
      .subscribe(([prd, assets]) => {
        this.assets = assets;

        this.serviceStatusList = Object.entries(ServiceStatus)
          .filter((e) => !isNaN(e[0] as any))
          .map((e) => ({ value: Number(e[0]), label: e[1] })) as Array<E2gSelectOption>;

        this.attachmentKey = this.inputData.equipmentKey;

        this.dataHandler.setInitialData(prd);

        setTimeout(() => {
          this.businessRulesService.start();
        });

        if (this.dataHandler.data.assetId) {
          this.assignAssetOptions();
        }
      });
  }

  private assignAssetOptions(): void {
    this.assetsToSelect = [
      ...this.assets
        .filter((x) => x.id !== this.dataHandler.data.assetId && x.type !== AssetTypes.PressureReliefDevice)
        .map(
          (y) =>
            ({
              value: y.id,
              label: y.name,
              disabled: y.id === this.dataHandler.data.prdProtectedAssetId
            } as E2gSelectOption)
        )
    ];
  }

  public isDirty(): boolean {
    return this.dataHandler.dirty;
  }

  public onSave(): void {
    this.saveChanges().subscribe((success) => {
      this.notificationService.showSaveResult(success);
      this.refreshData.next();
    });
  }

  public saveChanges(): Observable<boolean> {
    const dto = { ...this.dataHandler.getRawData(), defaultProperties: this.dataHandler.getDefaultPropNames() };

    return this.dataService.savePrd(dto).pipe(
      concatMap((success) => {
        return of(success);
      })
    );
  }

  public revertChanges(): void {
    this.dataHandler.revertChanges();
  }

  private setInputData(params: Params): void {
    this.inputData = {
      equipmentKey: params.equipmentKey,
      unitKey: params.unitKey
    };
  }

  public getAllAvailableItems(): Array<E2gSelectOption> {
    return this.assetsToSelect! || ([] as Array<E2gSelectOption>);
  }

  public prdProtectedAssetChange(): void {
    if (!this.dataHandler.data.protectedAssets?.find((x) => x === this.dataHandler.data.prdProtectedAssetId)) {
      this.dataHandler.data.protectedAssets = [
        ...(this.dataHandler.data.protectedAssets! || []),
        this.dataHandler.data.prdProtectedAssetId!
      ];
    }

    this.assignAssetOptions();
  }
}
