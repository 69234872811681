import { ColDef } from 'ag-grid-community';

import { GridCellTooltipComponent } from '../cell-renders/grid-cell-tooltip/grid-cell-tooltip.component';

export function buildDefaultColDef(): ColDef {
  return {
    resizable: true,
    sortable: true,
    minWidth: 100,
    tooltipComponent: GridCellTooltipComponent,
    filter: 'agSetColumnFilter'
  };
}

export function assignHeaderAndTooltip(header: string, field: string): ColDef {
  return {
    headerName: header,
    headerTooltip: header,
    field: field,
    tooltipField: field
  };
}
