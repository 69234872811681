import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faChevronDown, IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { ButtonSelectOptions } from 'src/app/shared-module/models/button-select-options';

@Component({
  selector: 'app-button-select',
  templateUrl: './button-select.component.html',
  styleUrls: ['./button-select.component.css']
})
export class ButtonSelectComponent {
  @Input() public icon?: IconDefinition;
  @Input() public text!: string;
  @Input() public options!: Array<ButtonSelectOptions>;
  @Input() public canEdit: boolean = true;
  @Input() public disabled: boolean = false;
  @Output() public itemSelected = new EventEmitter<ButtonSelectOptions>();

  public faChevronDown = faChevronDown;

  public itemClick(item: ButtonSelectOptions): void {
    this.itemSelected.emit(item);
  }
}
