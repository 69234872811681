<div class="d-flex flex-column h-100">
  <div class="shadow-box" *ngIf="title">
    <div class="d-flex align-items-center justify-content-center">
      <div class="h3" style="margin: 4px">{{ title }}</div>
    </div>
  </div>
  <div>
    <div>
      <h3>Drop {{ fileText }} here</h3>
      <div
        ng2FileDrop
        [uploader]="uploader"
        class="drop-zone"
        (fileOver)="fileOverBase($event)"
        [ngClass]="{ 'nv-file-over': hasBaseDropZoneOver }"
      >
        <div class="text-light">
          <fa-icon [icon]="faCopy"></fa-icon>
        </div>
      </div>
    </div>
    <hr />
    <div>
      <h3>Upload queue</h3>
      <p>
        Queue length:
        {{ (uploader.queue || []).length }}
      </p>
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th width="50%">Name</th>
              <th>Size</th>
              <th class="text-center">Progress</th>
              <th class="text-center">Status</th>
              <th class="text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of uploader.queue; let i = index">
              <td>
                <strong>{{ item?.file?.name }}</strong>
              </td>
              <td nowrap>{{ item?.file?.size / 1024 / 1024 | number : '.2' }} MB</td>
              <td class="text-center">
                <div class="progress" style="margin-bottom: 0">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    [ngStyle]="{ width: item.progress + '%' }"
                    id="div-progress-{{ i }}"
                  ></div>
                </div>
              </td>
              <td class="text-center">
                <span *ngIf="item.isSuccess" class="text-success">
                  <fa-icon [icon]="faCheckCircle" size="2x"></fa-icon>
                </span>
                <span *ngIf="item.isCancel" class="text-warning">
                  <fa-icon [icon]="faBan" size="2x"></fa-icon>
                </span>
                <span *ngIf="item.isError" class="text-danger" style="display: inline-flex; align-items: center">
                  <fa-icon [icon]="faTimesCircle" size="2x" class="mr-2"></fa-icon>
                </span>
              </td>
              <td nowrap class="text-center">
                <button
                  type="button"
                  class="btn btn-success btn-xs mr-2"
                  (click)="item.upload()"
                  placement="top"
                  ngbTooltip="Upload"
                  [disabled]="item.isReady || item.isUploading || item.isSuccess"
                  id="btn-upload-{{ i }}"
                >
                  <fa-icon [icon]="faArrowUp"></fa-icon>
                </button>
                <button
                  type="button"
                  class="btn btn-warning btn-xs mr-2"
                  (click)="item.cancel()"
                  [disabled]="!item.isUploading"
                  placement="top"
                  ngbTooltip="Cancel"
                  id="btn-cancel-{{ i }}"
                >
                  <fa-icon [icon]="faBan"></fa-icon>
                </button>
                <button
                  type="button"
                  class="btn btn-danger btn-xs"
                  (click)="item.remove()"
                  placement="top"
                  ngbTooltip="Remove"
                  id="btn-remove-{{ i }}"
                >
                  <fa-icon [icon]="faTrashXmark"></fa-icon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <div class="mt-2">
          Queue Progress:
          <div class="progress">
            <div
              class="progress-bar"
              role="progressbar"
              [ngStyle]="{
                width: uploader.progress + '%'
              }"
              id="div-progress"
            ></div>
          </div>
        </div>
        <div class="pt-2">
          <button
            type="button"
            class="btn btn-success btn-sm mr-2 mt-2"
            (click)="uploader.uploadAll()"
            [disabled]="uploader.getNotUploadedItems().length === 0"
            id="btn-upload-all"
          >
            <fa-icon [icon]="faArrowUp" class="mr-2"></fa-icon> {{ uploadButtonText }}
          </button>
          <button
            type="button"
            class="btn btn-warning btn-sm mr-2 mt-2"
            (click)="uploader.cancelAll()"
            [disabled]="!uploader.isUploading"
            id="btn-cancel-all"
          >
            <fa-icon [icon]="faBan" class="mr-2"></fa-icon> {{ cancelButtonText }}
          </button>
          <button
            type="button"
            class="btn btn-danger btn-sm mr-2 mt-2"
            (click)="uploader.clearQueue()"
            [disabled]="!uploader.queue.length"
            id="btn-remove-all"
          >
            <fa-icon [icon]="faTrashXmark" class="mr-2"></fa-icon> {{ removeButtonText }}
          </button>

          <label class="btn btn-primary btn-sm m-0 mt-2">
            <fa-icon [icon]="faFileMedical" class="mr-2"></fa-icon><span>Select {{ fileText }}</span>
            <input
              type="file"
              style="display: none"
              [disabled]="uploader.isUploading"
              ng2FileSelect
              [uploader]="uploader"
              id="btn-select-file"
              [accept]="getAcceptedInputFiles()"
              (click)="onFileClick($event)"
            />
          </label>
        </div>
      </div>
    </div>
  </div>
</div>
