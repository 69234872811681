import { E2gSelectOption } from '@equityeng/e2g-ng-ui';

export enum PrdType {
  Conventional = 1,
  Balanced = 2,
  Pilot = 3,
  RuptureDisk = 4
}

export const PRD_TYPE_VALUES: Array<E2gSelectOption> = [
  { value: PrdType.Conventional, label: 'Conventional' },
  { value: PrdType.Balanced, label: 'Balanced' },
  { value: PrdType.Pilot, label: 'Pilot' },
  { value: PrdType.RuptureDisk, label: 'Rupture Disk' }
];
