import { Injectable } from '@angular/core';
import { concatMap, Observable, tap } from 'rxjs';
import { ActivityGenericDataService } from 'src/app/asset-module/services/activity-generic-data.service';
import { InspectionDataService } from 'src/app/asset-module/services/inspection-data.service';
import { ActivityDetailDto } from 'src/app/models/activity-detail-dto';
import { ActivityTemplates } from 'src/app/models/enums/activity-templates';
import { DialogService } from 'src/app/shared-module/dialog.service';
import { ActionTypes } from 'src/app/shared-module/models/action-types';
import { BaseNameDialogData, DialogType } from 'src/app/shared-module/models/name-dialog-data';
import { NotificationService } from 'src/app/shared-module/services/notification.service';
import { SurveyService } from 'src/app/survey.service';

@Injectable({
  providedIn: 'root'
})
export class ActivityHistoryDeleteService {
  public constructor(
    private genericService: ActivityGenericDataService,
    private inspectionService: InspectionDataService,
    private surveyService: SurveyService,
    private dialogService: DialogService,
    private notificationService: NotificationService
  ) {}

  public deleteActivity(activity: ActivityDetailDto): Observable<boolean> {
    const deleteDialogData: BaseNameDialogData = {
      name: activity.name!,
      dialogType: DialogType.Activity
    };

    return (
      activity.template === ActivityTemplates.Survey
        ? this.dialogService.displayDelete(deleteDialogData, 'All related readings will be deleted')
        : this.dialogService.displayDelete(deleteDialogData)
    ).pipe(
      concatMap(() => {
        switch (activity.template!) {
          case ActivityTemplates.Generic:
            return this.genericService.deleteGenericActivity(activity.id!);
          case ActivityTemplates.Survey:
            return this.surveyService.deleteSurvey(activity.id!);
          case ActivityTemplates.Inspection:
            return this.inspectionService.deleteInspection(activity.id!);
        }
      }),
      tap((success) => this.notificationService.showActionResult(success, ActionTypes.Delete, activity.name!))
    );
  }
}
