export function defaultWithNullComparer(
  valueA: any,
  valueB: any,
  isInverted: boolean,
  lambda: (a: any, b: any) => number
): number {
  if (!valueA && !valueB) {
    return 0;
  }

  if (!valueA) {
    return isInverted? -1 : 1;
  }

  if (!valueB) {
    return isInverted? 1 : -1;
  }

  return lambda(valueA, valueB);
}
