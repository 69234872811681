import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { E2gSelectOption } from '@equityeng/e2g-ng-ui';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AppSettingsService } from '../app-settings/app-settings.service';
import { FunctionalDefaultRequestDto } from './models/functional-default-request-dto';

@Injectable({
  providedIn: 'root'
})
export class SageDataService {
  private serverUri: string;

  public constructor(private http: HttpClient, private settingsService: AppSettingsService) {
    this.serverUri = this.settingsService.settings.apiUri as string;
  }

  public getFunctionalValidValues(dto: FunctionalDefaultRequestDto): Observable<Array<E2gSelectOption>> {
    const uri = `${this.serverUri}/sage-values/valid`;
    return this.http.post<Array<string>>(uri, JSON.stringify(dto)).pipe(
      map((x) =>
        Object.keys(x).map((y) => ({
          value: y,
          label: (x as any)[y]
        }))
      )
    );
  }

  public getValidValuesWithLabels(dto: FunctionalDefaultRequestDto): Observable<Array<E2gSelectOption>> {
    const uri = `${this.serverUri}/sage-values/valid/labels`;
    return this.http.post<Array<E2gSelectOption>>(uri, dto);
  }

  public getFunctionalDefaultValue(
    dto: FunctionalDefaultRequestDto,
    keepAsString: boolean = false
  ): Observable<string | undefined> {
    const uri = `${this.serverUri}/sage-values/default`;

    return this.http.post<string>(uri, JSON.stringify(dto)).pipe(
      map((x) => {
        if (x.length === 0) {
          return undefined;
        } else if (!isNaN(Number(x)) && !keepAsString) {
          return Number(x).toFixed(3);
        }
        return x;
      })
    );
  }

  public loadDiag(id: string, moduleName: string): Observable<string> {
    const uri = `${this.serverUri}/comp/${id}/diag/${moduleName}`;
    return this.http.get<string>(uri);
  }

  public getNpsOptions(): Observable<Array<E2gSelectOption>> {
    return this.http.get<any>(`${this.serverUri}/sage-values/npsOptions`).pipe(
      catchError(() => of(new Array<E2gSelectOption>())),
      map((x) =>
        Object.keys(x).map((y) => ({
          value: y,
          label: (x as any)[y]
        }))
      )
    );
  }
}
