import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppSettingsService } from '../app-settings/app-settings.service';
import { PrdDto } from './models/prd-dto';

@Injectable({
  providedIn: 'root'
})
export class PrdDataService {
  private serverUri: string;

  public constructor(private http: HttpClient, private settingsService: AppSettingsService) {
    this.serverUri = this.settingsService.settings.apiUri as string;
  }

  public getPrd(id: string): Observable<PrdDto> {
    return this.http.get<PrdDto>(`${this.serverUri}/assets/${id}/prd`).pipe(catchError(() => of({} as PrdDto)));
  }

  public savePrd(dto: PrdDto): Observable<boolean> {
    return this.http.put<boolean>(`${this.serverUri}/prds/${dto.id}`, dto).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }
}
