<div class="d-flex align-items-middle ml-4 mt-2 mb-2">
  <app-br-check heading="RBI Enabled" propName="rbi" labelPosition="right" [readOnly]="readOnly"></app-br-check>
  <div
    *ngIf="sageModuleId && !compDataHandler.dirty && (canViewSageModule | async)"
    class="d-flex flex-row align-items-center"
  >
    &nbsp;-
    <a (click)="getRbi()" class="m-2 link pointer">Expert View</a>
  </div>
</div>

<div class="h-100" *ngIf="compDataHandler.data.rbi">
  <ul
    ngbNav
    #nav="ngbNav"
    [(activeId)]="rbiActiveTabId"
    class="sub-nav-tabs ml-4"
    (navChange)="onBeforeSwitching($event)"
  >
    <li [ngbNavItem]="1">
      <a ngbNavLink>Configuration</a>
      <ng-template ngbNavContent>
        <div class="h-100 flex-col">
          <div class="h-100 flex-111 overflow-auto mt-2 ml-4">
            <div class="row mt-2">
              <div class="col-sm-2">
                <div class="mt-2">
                  <app-br-select
                    propName="gffType"
                    heading="RBI Component Type"
                    [readOnly]="readOnly"
                    (change$)="gffTypeTrigger.next()"
                  ></app-br-select>
                </div>
                <div class="mt-2" *ngIf="compDataHandler.data.insulationType != 'NONE'">
                  <app-br-select
                    propName="insulationCondition"
                    heading="Insulation Condition"
                    [readOnly]="readOnly"
                  ></app-br-select>
                </div>
                <div class="mt-2" *ngIf="compDataHandler.data.insulationType != 'NONE'">
                  <app-br-select
                    propName="insulationComplexity"
                    heading="Insulation Complexity"
                    [readOnly]="readOnly"
                  ></app-br-select>
                </div>
                <div class="mt-2">
                  <app-br-text
                    propName="operatingPressure"
                    heading="Operating Pressure"
                    type="number"
                    [readOnly]="true"
                  ></app-br-text>
                </div>
                <div class="mt-2">
                  <app-br-text
                    propName="operatingTemperature"
                    heading="Operating Temperature"
                    type="number"
                    [readOnly]="true"
                  ></app-br-text>
                </div>

                <div class="mt-2">
                  <e2g-text-input
                    heading="Fluid Stream"
                    id="fluid-stream"
                    [readonly]="true"
                    [ngModel]="GetFluidStream()"
                  >
                  </e2g-text-input>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="mt-4">
                  <app-br-check
                    heading="Calculate Liquid Fraction"
                    propName="calculateLiquidFraction"
                    labelPosition="right"
                    [readOnly]="readOnly"
                  ></app-br-check>
                </div>
                <div class="mt-3" *ngIf="!compDataHandler.data.calculateLiquidFraction">
                  <app-br-text
                    propName="estimatedLiquidPercent"
                    heading="Estimated Percent Liquid Volume"
                    type="number"
                    [readOnly]="readOnly"
                    [decimalPlaces]="1"
                    [otherTriggers]="[gffTypeTrigger]"
                  ></app-br-text>
                </div>
                <div class="mt-2">
                  <app-br-text
                    propName="liquidVolume"
                    heading="Liquid Volume"
                    type="number"
                    [readOnly]="true"
                    [decimalPlaces]="5"
                  ></app-br-text>
                </div>
                <div class="mt-2">
                  <app-br-text
                    propName="vaporVolume"
                    heading="Vapor Volume"
                    type="number"
                    [readOnly]="true"
                    [decimalPlaces]="5"
                  ></app-br-text>
                </div>
                <div class="mt-2">
                  <app-br-text
                    propName="fluidMass"
                    heading="Fluid Mass"
                    type="number"
                    [readOnly]="true"
                    [decimalPlaces]="5"
                  ></app-br-text>
                </div>
              </div>
              <div class="col-sm-4">
                <div *ngIf="streamValid">
                  <div class="mt-2">Fluid Components</div>
                  <div class="card p-2" style="height: 100%; width: 100%; max-width: 100%">
                    <table>
                      <thead>
                        <th>Name</th>
                        <th>Quantity</th>
                      </thead>
                      <tbody>
                        <tr *ngFor="let fc of selectedStream!.components">
                          <td>{{ fc.name }}</td>
                          <td>{{ fc.qty }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="2" *ngIf="inspDataHandler.data.id !== undefined">
      <a ngbNavLink>Inspection Plan</a>
      <ng-template ngbNavContent>
        <div class="h-100 flex-col" *ngIf="this.inspDataHandler.data.rbiDate !== undefined; else noCalculation">
          <div class="h-100 flex-111 overflow-aut mt-2 ml-4">
            <div class="d-flex flex-row">
              <div class="h-100 flex-col mt-2">
                <e2g-input-heading heading="Inspection Recommendation"></e2g-input-heading>
                <table class="table rec-table">
                  <thead>
                    <tr>
                      <td></td>
                      <td id="grade_header">GRADE</td>
                      <td id="date_header">INSPECTION DATE</td>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let rec of recommendations; let i = index">
                      <tr [class]="getRecommendationClass(rec.gradeProp)">
                        <td class="label" id="label">{{ labels[i] }}</td>
                        <td id="grade">{{ GetGrade(rec.gradeProp) }}</td>
                        <td id="date">{{ GetValue(rec.dateProp) }}</td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
              <div class="ml-5 flex-col mt-2">
                <e2g-input-heading heading="Consequence Area {{ areaUnits }}"></e2g-input-heading>
                <div [class]="getCofClass()">{{ GetValue('consequenceArea') }}</div>
              </div>
            </div>
            <hr />
            <div class="d-flex flex-row">
              <div class="h-100 flex-col mt-2">
                <e2g-input-heading heading="Calculated Risk Data"></e2g-input-heading>
                <table class="table ip-table">
                  <thead>
                    <tr>
                      <td class="pb-1"></td>
                      <td class="pb-1" id="rbidate" colspan="2">RBI Date: {{ GetValue('rbiDate') }}</td>
                      <td class="pb-1" id="targetdate" colspan="2">Target Date: {{ GetValue('targetDate') }}</td>
                      <td class="pb-1" id="plandate" colspan="2">Plan Date: {{ GetValue('planDate') }}</td>
                      <td class="pb-1" id="plandate2" colspan="2">Plan Date with Inspection</td>
                    </tr>
                    <tr>
                      <td class="pt-1"></td>
                      <ng-container *ngFor="let x of detailedSections">
                        <td class="ip-number pt-1" id="risk_label">RISK {{ riskUnits }}</td>
                        <td class="ip-number pt-1" id="df_label">DAMAGE FACTOR</td>
                      </ng-container>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let label of labels; let key = index">
                      <tr>
                        <td class="label">{{ label }}</td>
                        <ng-container *ngFor="let x of detailedSections; let detailkey = index">
                          <td [class]="getRiskClass(detailedSections[detailkey].riskKeys[key])">
                            {{ GetValue(x.riskKeys[key]) }}
                          </td>
                          <td [class]="getDfClass(detailedSections[detailkey].dfKeys[key])">
                            {{ GetValue(x.dfKeys[key]) }}
                          </td>
                        </ng-container>
                      </tr>
                    </ng-container>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td></td>
                      <ng-container *ngFor="let x of detailedSections; let key = index">
                        <td colspan="2">
                          {{ x.footerHeader }}{{ x.footerProp ? ': ' : '' }}{{ GetValue(x.footerProp) }}
                        </td>
                      </ng-container>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav" class="flex-col h-100"></div>
</div>

<ng-template #noCalculation>
  <e2g-input-heading class="m-5" heading="Component has not successfully calculated"></e2g-input-heading>
</ng-template>
