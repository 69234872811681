import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AppSettingsService } from './app-settings/app-settings.service';
import { FluidStreamDto } from './models/fluid-stream-dto';
import { StreamComponentDto } from './models/stream-component-dto';

@Injectable({
  providedIn: 'root'
})
export class FluidStreamDataService {
  private serverUri: string;

  public constructor(private http: HttpClient, private settingsService: AppSettingsService) {
    this.serverUri = this.settingsService.settings.apiUri!;
  }

  public getFluidStreams(): Observable<Array<FluidStreamDto>> {
    return this.http
      .get<Array<FluidStreamDto>>(`${this.serverUri}/fluids/streams`)
      .pipe(catchError(() => of(new Array<FluidStreamDto>())));
  }

  public createFluidStream(stream: FluidStreamDto): Observable<string | undefined> {
    const body = JSON.stringify(stream);
    return this.http.post<string>(`${this.serverUri}/fluids/create`, body);
  }

  public updateFluidStream(stream: FluidStreamDto): Observable<boolean> {
    const body = JSON.stringify(stream);
    return this.http.put<boolean>(`${this.serverUri}/fluids/update`, body).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  public deleteFluidStream(streamId: string, circuitId?: string): Observable<number> {
    return this.http.delete<number>(`${this.serverUri}/fluids/${streamId}/circuit/${circuitId ?? ''}`).pipe(
      map((x) => x),
      catchError(() => of(-1))
    );
  }

  public getFluidComponents(stream: FluidStreamDto): Observable<boolean> {
    const ids = stream.components!.map((x) => x.id);

    return this.http.post<Array<StreamComponentDto>>(`${this.serverUri}/proxy/fluids/fluids`, ids).pipe(
      map((x) => {
        stream.components!.forEach((sc) => {
          const map = x.find((pp) => pp.id === sc.id)!;
          sc.name = map.name;
          sc.molecularWeight = map.molecularWeight;
          sc.chemicalFormula = map.chemicalFormula;
        });

        return true;
      }),
      catchError(() => of(false))
    );
  }
}
