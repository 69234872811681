import { Component, Inject, OnInit } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { setupNewComponent } from 'src/app/asset-module/components/component-slideout-utility';
import { ComponentRecordDto } from 'src/app/models/component-record-dto';
import { MinimumThicknessChoice } from 'src/app/models/enums/minimum-thickness-choice';
import { EquipmentDto } from 'src/app/models/equipment-dto';
import { BUSINESS_RULES } from 'src/app/sage-common-module/business-rules-injection-token';
import { UnitsOfMeasureEvaluator } from 'src/app/units-of-measure/units-of-measure-evaluator';

import {
  CompDetailBaseComponent,
  CompDetailBaseComponentProviders
} from '../../asset-module/components/comp-detail-base-component';
import { ComponentSlideoutInput } from '../../asset-module/models/component-slideout-input';
import { BusinessRulesDefinition } from '../../sage-common-module/models/business-rules-definition';
import { ISaveChanges } from '../../save-changes';
import { SLIDE_OUT_DATA } from '../../slide-out-module/slide-out-data-injection-token';
import { NcpvCompBusinessRulesService } from '../ncpv-comp-business-rules.service';

@Component({
  selector: 'app-ncpv-comp',
  templateUrl: './ncpv-comp.component.html',
  styleUrls: ['./ncpv-comp.component.css'],
  providers: [
    {
      provide: BUSINESS_RULES,
      useClass: NcpvCompBusinessRulesService
    },
    {
      provide: ISaveChanges,
      useExisting: NcpvCompComponent
    },
    ...CompDetailBaseComponentProviders
  ]
})
export class NcpvCompComponent extends CompDetailBaseComponent implements OnInit, ISaveChanges {
  public corrosionAllowanceUom: Observable<string> = this.uomEvaluator.getUnits('corrosionAllowance');

  public thicknessUom: Observable<string> = this.uomEvaluator.getUnits('thickness');

  public codeTrigger = new ReplaySubject<void>(1);

  public specifiedThickness = MinimumThicknessChoice.Specified;

  public constructor(
    private uomEvaluator: UnitsOfMeasureEvaluator,
    @Inject(SLIDE_OUT_DATA) slideOutData: ComponentSlideoutInput,
    @Inject(BUSINESS_RULES) businessRulesService: BusinessRulesDefinition
  ) {
    super(slideOutData, businessRulesService);
  }

  public ngOnInit(): void {
    this.setupComponentData();
  }

  protected override setupNewComponent(asset: EquipmentDto): ComponentRecordDto {
    const component = setupNewComponent(asset, this.assetConfig, this.slideOutData.newType!, false, true);

    component.defaultProperties!.push('geomType');
    component.geomType = 'RECT';

    component.isCorrodedThickness = true;

    return component;
  }

  public checkIsValid(): void {
    const isValid = this.dataHandler.data.isCorrodedThickness || this.dataHandler.data.isSpecifiedThickness;
    this.slideOutService.setBodyValid(isValid);
  }

  public getTmin(): string {
    const num1: Array<number | undefined> = [];
    const num2: Array<number | undefined> = [];
    const num3: Array<number | undefined> = [];

    if (this.dataHandler.data.isCorrodedThickness) {
      num1.push(this.dataHandler.data.shortPlateCorrodedThickness);
      num2.push(this.dataHandler.data.longPlateCorrodedThickness);
      num3.push(this.dataHandler.data.endPlateCorrodedThickness);
    }
    if (this.dataHandler.data.isSpecifiedThickness) {
      num1.push(this.dataHandler.data.shortPlateSpecifiedMinimumThickness);
      num2.push(this.dataHandler.data.longPlateSpecifiedMinimumThickness);
      num3.push(this.dataHandler.data.endPlateSpecifiedMinimumThickness);
    }
    return this.formatTminLabel(this.getMaxNum(num1), this.getMaxNum(num2), this.getMaxNum(num3));
  }

  public formatTminLabel(top?: number, side?: number, end?: number): string {
    return `${this.getFixed(top)} x ${this.getFixed(side)} x ${this.getFixed(end)}`;
  }

  private getFixed(prop?: number): string {
    if (prop == null) {
      return 'N/A';
    }
    return Number(prop).toFixed(3);
  }

  private getMaxNum(nums: Array<number | undefined>): number | undefined {
    const definedNums = nums.filter((x) => x !== undefined && x > 0).map((x) => x!);
    return definedNums.length > 0 ? Math.max(...definedNums) : undefined;
  }
}
