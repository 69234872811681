<e2g-text-input
  *ngIf="(!showOnlyWhenRequired || isRequired()) && !disabled"
  [id]="'text-' + propName"
  [(ngModel)]="value"
  [heading]="heading"
  [placeholder]="placeholder"
  [isDefaultValue]="isDefaultValue()"
  [showReset]="showReset()"
  (reset)="reset()"
  (change)="onChange()"
  [debounceTimeMsec]="debounceTimeMsec()"
  [readonly]="!!isReadOnly()"
  [requiredForCalc]="isRequired()"
  [type]="type"
  [disabled]="disabled"
  [labelPosition]="getLabelPosition()"
  [labelWidth]="getLabelWidth()"
  [warnings]="getWarnings()"
>
</e2g-text-input>

<e2g-text-input
  *ngIf="(!showOnlyWhenRequired || isRequired()) && disabled"
  [id]="'text-' + propName"
  [ngModel]="getValue()"
  [heading]="heading"
  [placeholder]="placeholder"
  [isDefaultValue]="isDefaultValue()"
  [showReset]="showReset()"
  (reset)="reset()"
  (change)="onChange()"
  [readonly]="!!isReadOnly()"
  [requiredForCalc]="isRequired()"
  [type]="type"
  [disabled]="disabled"
  [labelPosition]="getLabelPosition()"
  [labelWidth]="getLabelWidth()"
  [warnings]="getWarnings()"
>
</e2g-text-input>
