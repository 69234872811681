<div class="flex-111 e2g-padding pt-0">
  <div class="row">
    <div class="col-4">
      <div class="d-flex flex-row">
        <div class="required dashboard-data-header">
          Governing Minimum Thickness {{ thicknessUom }}: {{ getTmin() }}
          <fa-icon
            *ngIf="compCanBeCalculated() && !dataHandler.isDefaultValue(governingMinimumThickness) && !readOnly"
            id="resetIcon"
            [icon]="resetIcon"
            class="ml-1 icon-secondary"
            (click)="resetGoverningMinimumThickness()"
          ></fa-icon>
        </div>
      </div>
      <div>
        <div *ngIf="showMawpApproach()" class="d-flex flex-row my-2">
          <app-slide-input
            id="slide-useMawp"
            heading="Use MAWP Approach"
            [(ngModel)]="dataHandler.data.useMawpApproach"
            [readOnly]="readOnly"
          >
          </app-slide-input>
          <fa-icon
            *ngIf="!dataHandler.isDefaultValue('useMawpApproach') && !readOnly"
            id="resetIcon-mawp"
            [icon]="resetIcon"
            class="ml-1 icon-secondary"
            (click)="resetUseMawpApproach()"
          ></fa-icon>
        </div>
        <div *ngIf="compCanBeCalculated()" class="d-flex flex-row align-items-center">
          <app-br-check
            heading="Code Retirement Thickness"
            labelPosition="right"
            propName="isRetirementThickness"
            (change$)="checkIsValid()"
            [labelWidth]="optionLabelWidth"
            [warnings]="getCodeWarnings()"
            [disabled]="dataHandler.data.useMawpApproach"
          ></app-br-check>
          <div [class]="getCodeWarnings().length > 0 ? 'number-padding-warning' : 'number-padding'"></div>
          <div class="align-items-center">{{ getFixed(dataHandler.data.retirementThickness, 3) }}</div>
        </div>
        <div *ngIf="compCanBeCalculated()" class="d-flex flex-row align-items-center">
          <app-br-check
            heading="Structural Required Thickness"
            labelPosition="right"
            propName="isStructuralRequiredThickness"
            (change$)="checkIsValid()"
            [labelWidth]="optionLabelWidth"
            [disabled]="dataHandler.data.useMawpApproach"
          ></app-br-check>
          <div class="number-padding align-items-center">
            {{ getFixed(dataHandler.data.structuralRequiredThickness, 3) }}
          </div>
        </div>
        <div class="d-flex flex-row align-items-center">
          <app-br-check
            heading="Nominal - CA"
            labelPosition="right"
            propName="isCorrodedThickness"
            (change$)="checkIsValid()"
            [labelWidth]="optionLabelWidth"
            [disabled]="dataHandler.data.useMawpApproach"
          ></app-br-check>
          <div class="number-padding align-items-center">{{ getFixed(dataHandler.data.corrodedThickness, 3) }}</div>
        </div>
        <div class="d-flex flex-row align-items-center">
          <app-br-check
            heading="Specified Minimum Thickness"
            labelPosition="right"
            propName="isSpecifiedThickness"
            (change$)="checkIsValid()"
            [labelWidth]="optionLabelWidth"
            [disabled]="dataHandler.data.useMawpApproach"
          ></app-br-check>
        </div>
      </div>

      <div class="mt-2">
        <app-br-text
          *ngIf="dataHandler.data.isSpecifiedThickness"
          propName="specifiedMinimumThickness"
          heading="Specified Minimum Thickness"
          type="number"
          [disabled]="dataHandler.data.useMawpApproach"
        ></app-br-text>
      </div>
    </div>
  </div>
</div>
