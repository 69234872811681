import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

import { OnDestroyBaseComponent } from '../../on-destroy-base-component/on-destroy-base-component';

@Component({
  selector: 'app-img-display',
  templateUrl: './img-display.component.html'
})
export class ImageDisplayComponent extends OnDestroyBaseComponent implements OnInit, OnDestroy {
  @Input() public imgData!: () => Observable<Blob>;
  @Input() public class?: string;
  @Input() public style?: { [key: string]: string };
  @Input() public useMap?: string;
  @Output() public imgLoaded: EventEmitter<HTMLImageElement> = new EventEmitter<HTMLImageElement>();

  public imgUrl?: string | ArrayBuffer;
  public loadingData: boolean = true;

  public constructor(private sanitizer: DomSanitizer) {
    super();
  }

  public ngOnInit(): void {
    const reader = new FileReader();
    reader.onload = (): void => {
      this.imgUrl = <string>this.sanitizer.bypassSecurityTrustResourceUrl(<string>reader.result);
      this.loadingData = false;
    };

    this.imgData()
      .pipe(
        tap(() => (this.loadingData = true)),
        filter((x) => !!x),
        takeUntil(this.destroy)
      )
      .subscribe((x) => {
        if (reader.readyState === 1) {
          reader.abort();
        }
        reader.readAsDataURL(x);
      });
  }

  public loaded(element: HTMLImageElement): void {
    this.imgLoaded.emit(element);
  }
}
