import { dateOnly, DateOnlyUtility } from '@equityeng/e2g-ng-ui';
import { graphReadingData } from 'src/app/asset-module/models/graph-reading-data';
import { ReadingDto } from 'src/app/asset-module/models/reading-dto';
import { PlotlyColors } from 'src/app/shared/constants/plotly-colors';
import { PlotlyCommonStyles } from 'src/app/shared/constants/plotly-common-styles';

export class CmlGraph {
  public graphLayout = {
    xaxis: {
      ...PlotlyCommonStyles.defaultAxis,
      tickangle: -45,
      tickformat: '%m/%d/%Y',
      minor: {
        tickmode: 'auto',
        ticklen: 4,
        ticks: 'outside',
        tickcolor: PlotlyColors.gridLines
      },
      type: 'date',
      autorange: true,
      showline: true
    },
    yaxis: {
      ...PlotlyCommonStyles.defaultAxis,
      hoverformat: '.3r',
      rangemode: 'tozero',
      autorange: true,
      showline: true
    },
    hoverlabel: {
      bgcolor: 'var(--bg-primary)',
      font_family: 'Roboto',
      font_size: 14
    },
    title: {
      text: '<b>Thickness Readings</b>',
      font: {
        family: 'Roboto',
        size: 16,
        color: PlotlyColors.titleColor
      }
    },
    showlegend: true,
    legend: {
      orientation: 'h',
      xanchor: 'center',
      x: 0.5,
      yanchor: 'bottom',
      y: -0.5
    }
  };

  public graphConfig = { displayModeBar: false } as any;

  public data?: Array<any> = undefined;

  public getGraphData(
    graphData: ReadingDto[] | undefined,
    inServiceDate: dateOnly | undefined,
    cmlUsingMawpApproach: boolean,
    nominalThickness: string,
    minimumThickness: string
  ): void {
    const traceReadings = {
      x: [] as Array<number>,
      y: [] as Array<number>,
      mode: 'markers',
      type: 'scatter',
      name: 'Reading',
      marker: {
        color: PlotlyColors.fgLightblue,
        symbol: 'circle'
      }
    };

    let readingData = this.getGraphReadingData(graphData, inServiceDate, false);
    traceReadings.x = readingData.x;
    traceReadings.y = readingData.y;

    const traceExcludedReadings = {
      x: [] as Array<number>,
      y: [] as Array<number>,
      mode: 'markers',
      type: 'scatter',
      name: 'Excluded',
      marker: {
        color: PlotlyColors.fgOrange,
        symbol: 'x-thin-open'
      }
    };

    readingData = this.getGraphReadingData(graphData, inServiceDate, true);
    traceExcludedReadings.x = readingData.x;
    traceExcludedReadings.y = readingData.y;

    const traceNominal = {
      x: [] as Array<number>,
      y: [] as Array<number>,
      mode: 'lines',
      type: 'scatter',
      name: 'Nominal',
      line: {
        color: PlotlyColors.fgGreen
      }
    };

    const traceTmin = {
      x: [] as Array<number>,
      y: [] as Array<number>,
      mode: 'lines',
      type: 'scatter',
      name: 'T-min',
      line: {
        color: PlotlyColors.fgRed
      }
    };

    if (graphData !== undefined) {
      if (graphData.length !== 0) {
        const sortedListDates = graphData
          .map((x) => x.date)
          .filter(
            (x) =>
              !inServiceDate ||
              DateOnlyUtility.convertDateOnlyToDate(x) >= DateOnlyUtility.convertDateOnlyToDate(inServiceDate!)
          )
          .sort((a, b) => DateOnlyUtility.sortAsc(a, b))
          .map((x) => DateOnlyUtility.convertDateOnlyToDate(x).getTime());

        if (graphData !== undefined) {
          if (inServiceDate !== undefined) {
            const startAndEndDate = [
              DateOnlyUtility.convertDateOnlyToDate(inServiceDate).getTime(),
              sortedListDates[sortedListDates.length - 1]
            ];
            traceNominal.x = startAndEndDate;
            traceTmin.x = startAndEndDate;

            if (!cmlUsingMawpApproach) {
              traceTmin.y = [Number(minimumThickness), Number(minimumThickness)];
            }
          }
        }

        traceNominal.y = [Number(nominalThickness), Number(nominalThickness)];
      }
    }

    this.data = [traceReadings, traceExcludedReadings, traceNominal, traceTmin];
  }

  private getGraphReadingData(
    graphData: ReadingDto[] | undefined,
    inServiceDate: dateOnly | undefined,
    excludedReadings: boolean
  ): graphReadingData {
    const data: graphReadingData = {
      x: [],
      y: []
    };

    if (graphData !== undefined) {
      if (graphData.length !== 0) {
        const readingData = graphData
          .filter(
            (x) => x.excludeFromCalculation === excludedReadings && DateOnlyUtility.compare(x.date, inServiceDate!) >= 0
          )
          .sort((a, b) => DateOnlyUtility.sortAsc(a.date, b.date));
        data.x = readingData.map<number>((x: ReadingDto) => {
          return DateOnlyUtility.convertDateOnlyToDate(x.date).getTime();
        });

        data.y = readingData.map<number>((x: ReadingDto) => {
          return x.thickness;
        });
      }
    }

    return data;
  }
}
