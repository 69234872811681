import { Component, Inject, ViewChild } from '@angular/core';
import { E2gSelectOption } from '@equityeng/e2g-ng-ui';
import { AgGridAngular } from 'ag-grid-angular';
import { CellEditingStoppedEvent, RowDataUpdatedEvent, StartEditingCellParams } from 'ag-grid-community';
import { Observable } from 'rxjs';
import { DmlDetailBaseComponent } from 'src/app/dmls-module/dml-slideout/dml-detail-base-component';
import { DmlAssessmentLevels } from 'src/app/dmls-module/models/Enums/dml-assessment-levels';
import { DmlStatuses } from 'src/app/dmls-module/models/Enums/dml-statuses';
import { DmlTypes } from 'src/app/dmls-module/models/Enums/dml-types';
import { buildDefaultColDef } from 'src/app/grid-module/column-builders/build-default';
import { getDmlPittingPitCoupleDataGridColumns } from 'src/app/grid-module/column-definitions/dml-pitting-pit-couple-data-grid-columns';
import { GridBaseOptions } from 'src/app/grid-module/e2g-ag-grid/e2g-ag-grid.component';
import { SLIDE_OUT_DATA } from 'src/app/slide-out-module/slide-out-data-injection-token';
import { enumToSelectOptions } from 'src/app/utilities/enum-helper';

import { DmlPittingDto } from '../../models/dml-pitting-dto';
import { DmlSlideoutInput } from '../../models/dml-slideout-input';
import { DmlDamageLocations } from '../../models/Enums/dml-damage-locations';
import { DmlPittingTypes } from '../../models/Enums/dml-pitting-types';
import { PitCouple } from '../../models/pit-couple';

@Component({
  selector: 'app-dml-pitting',
  templateUrl: './dml-pitting.component.html'
})
export class DmlPittingComponent extends DmlDetailBaseComponent<DmlPittingDto> {
  @ViewChild('pitCoupleDataGrid', { static: false }) public pitCoupleDataGrid?: AgGridAngular;

  protected componentsShellModuleOnly = false;

  public nameErrors = [] as string[];

  public damageLocationOptions: Array<E2gSelectOption> = enumToSelectOptions(DmlDamageLocations);
  public pittingTypeOptions: Array<E2gSelectOption> = enumToSelectOptions(DmlPittingTypes);

  public pinnedData: Array<PitCouple> = this.getPinnedTopData();
  public setFirstCellAsEditable: boolean = false;

  public gridOptions: GridBaseOptions = {
    exportFileName: 'Pitting DML',
    getRowId: undefined,
    context: 'pitCoupleData',
    pinnedTopRowData: this.pinnedData,
    enterNavigatesVertically: false,
    enterNavigatesVerticallyAfterEdit: false,
    enableRangeSelection: false,
    rowSelection: 'single',
    stopEditingWhenCellsLoseFocus: true,
    onCellEditingStopped: this.onCellEditingStopped.bind(this),
    onRowDataUpdated: this.onRowDataUpdated.bind(this),
    domLayout: 'autoHeight',
    defaultColDef: {
      ...buildDefaultColDef,
      width: 125,
      wrapHeaderText: true,
      autoHeaderHeight: true
    },
    columnDefs: getDmlPittingPitCoupleDataGridColumns()
  };

  public longitudinalLengthOfPittingUom: Observable<string> = this.uomEvaluator.getUnits(
    'DmlPitting_LongitudinalLengthOfPitting'
  );
  public circumferentialLengthOfPittingUom: Observable<string> = this.uomEvaluator.getUnits(
    'DmlPitting_CircumferentialLengthOfPitting'
  );
  public pitDiameterFcaUom: Observable<string> = this.uomEvaluator.getUnits('DmlPitting_PitDiameterFca');
  public pitDepthFcaUom: Observable<string> = this.uomEvaluator.getUnits('DmlPitting_PitDepthFca');

  public constructor(@Inject(SLIDE_OUT_DATA) protected slideOutData: DmlSlideoutInput) {
    super(slideOutData);
  }

  public isValid(): boolean {
    return (
      this.dataHandler.dirty &&
      this.nameErrors.length === 0 &&
      !!this.dataHandler.data.name?.trim() &&
      this.dataHandler.data.componentId !== undefined &&
      this.dataHandler.data.assessmentLevel !== undefined &&
      this.dataHandler.data.damageLocation !== undefined &&
      this.dataHandler.data.pittingType !== undefined
    );
  }

  private onCellEditingStopped(event: CellEditingStoppedEvent): void {
    this.dataHandler!.data.pitCoupleData = [...this.dataHandler!.data.pitCoupleData!];
    event.api.refreshCells({ force: true, rowNodes: [event.api.getDisplayedRowAtIndex(event.rowIndex!)!] });
  }

  private onRowDataUpdated(event: RowDataUpdatedEvent): void {
    if (this.setFirstCellAsEditable) {
      const params: StartEditingCellParams = {
        rowIndex: 0,
        colKey: 'pitCouplePitch'
      };

      event.api.startEditingCell(params);

      this.setFirstCellAsEditable = false;
    }
  }

  public addPitCoupleData(): void {
    this.setFirstCellAsEditable = true;

    this.dataHandler!.data.pitCoupleData = [
      {
        id: undefined,
        pitCouplePitch: 0.0,
        pitAngle: 0.0,
        pitOneDiameter: 0.0,
        pitOneDepth: 0.0,
        pitTwoDiameter: 0.0,
        pitTwoDepth: 0.0
      } as PitCouple,
      ...this.dataHandler!.data.pitCoupleData!
    ];
  }

  public deletePitCoupleData(): void {
    const selectedData = this.pitCoupleDataGrid!.api.getSelectedRows()[0] as PitCouple;

    if (selectedData !== undefined) {
      this.dataHandler!.data.pitCoupleData = [
        ...this.dataHandler!.data.pitCoupleData!.filter((x) => x !== selectedData)
      ];
    }
  }

  private getPinnedTopData(): Array<PitCouple> {
    return [];
  }

  public rowSelected(): boolean {
    if (this.pitCoupleDataGrid !== undefined) {
      if (this.dataHandler.data.pitCoupleData!.length > 0) {
        const selectedData = this.pitCoupleDataGrid.api?.getSelectedRows() as Array<PitCouple>;

        if (selectedData !== undefined) {
          return selectedData[0] !== undefined ? true : false;
        }
      }
    }

    return false;
  }

  public componentChange(): void {
    this.slideOutService.setBodyValid(true);
  }

  protected makeNewDml(): DmlPittingDto {
    return {
      name: '',
      type: DmlTypes['Pitting'],
      hasCalculationErrors: false,
      pitCoupleData: [] as Array<PitCouple>,
      status: DmlStatuses.New,
      assessmentLevel: DmlAssessmentLevels['Level 2'],
      damageLocation: DmlDamageLocations.Internal,
      pittingType: DmlPittingTypes['Widespread Pitting']
    };
  }
}
