import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, Observable } from 'rxjs';
import { BreadcrumbsService } from 'src/app/breadcrumb-module/breadcrumbs.service';
import { Breadcrumb } from 'src/app/breadcrumb-module/breadcrumbs/breadcrumbs.component';
import { RouteParamsService } from 'src/app/shared-module/services/route-params.service';
import { ModuleRouteData } from 'src/app/shared-module/services/sage-nav.service';

import { SageSavePacket } from '../../models/sage-save-packet';
import { DataService } from '../dataservice';
import { ISageModuleService } from '../sage-module-service';

@Injectable()
export class UserModuleService implements ISageModuleService {
  private sageRouteData!: ModuleRouteData;
  public constructor(
    private breadcrumbsService: BreadcrumbsService,
    private dataService: DataService,
    private router: Router,
    private paramsService: RouteParamsService
  ) {
    this.paramsService.getSageRouteData().subscribe((sageRouteData) => (this.sageRouteData = sageRouteData));
  }

  public getBreadcrumbs(name: string, moduleType: string): Observable<Breadcrumb[]> {
    return this.breadcrumbsService
      .getSageModuleBreadcrumbs(this.sageRouteData.moduleId)
      .pipe(map((breadcrumbs) => breadcrumbs.concat({ name: `${name} (${moduleType})` })));
  }

  public save(sageSavePacket: SageSavePacket): Observable<boolean> {
    return this.dataService.saveData(this.sageRouteData.moduleId, sageSavePacket);
  }
  public getCalcStatus(): Observable<string> {
    return this.dataService.getModuleStatus(this.sageRouteData.moduleId);
  }

  public routeBack(): void {
    //TODO set sandbox path state so path will autoselect (if navigated to sage directly)
    this.router.navigate(['sage']);
  }
}
