<ul *ngIf="warnings.length > 0 || errors.length > 0; else loading">
  <li *ngFor="let error of errors">
    <p>{{ error }}</p>
  </li>
  <li *ngFor="let warning of warnings">
    <p>{{ warning }}</p>
  </li>
</ul>

<ng-template #loading>
  <div class="loading" id="div-loading"></div>
</ng-template>
