<div *ngFor="let inspection of this.dataHandler.data.inspectionGrades">
  <div *ngIf="activityPhase === undefined || inspection.activityPhase === activityPhase">
    <div
      class="slideout-input-section-heading mt-2"
      *ngIf="activityPhase === undefined || inspection.activityPhase === activityPhase"
    >
      {{ inspection.componentName }}
    </div>
    <div class="form-group row mb-1">
      <div class="col-md-2 text-nowrap" style="min-width: 150px" *ngIf="displayRecommendation">
        <e2g-ng-select-input
          heading="Inspection Approach"
          id="inp-inspectionApproach"
          [(ngModel)]="inspection.inspectionApproach"
          [options]="inspectionApproachOptions!"
          placeholder="select"
          [readonly]="readonly"
          (change)="onApproachChange(inspection)"
        ></e2g-ng-select-input>
      </div>
      <ng-container *ngFor="let typeKeys of this.inspectionTypeKeys">
        <div class="col-md-2 text-nowrap" style="min-width: 150px" *ngIf="getActive(inspection, typeKeys.activeProp)">
          <e2g-input-heading
            [heading]="typeKeys.label"
            [showReset]="showReset(inspection, typeKeys.gradeProp)"
            (reset)="reset(inspection, typeKeys.gradeProp)"
          ></e2g-input-heading>
          <div *ngIf="!typeKeys.thicknessField">
            <div class="btn-group btn-group-toggle" data-toggle="buttons">
              <ng-container *ngFor="let item of effectivenessOptions">
                <div class="e2g-input-wrap">
                  <label
                    [class]="readonly ? 'btn e2g-btn-outline-disabled' : 'btn e2g-btn-outline-primary'"
                    [ngClass]="{ active: item.value === inspection[typeKeys.gradeProp] }"
                    [id]="typeKeys.idName"
                  >
                    <input
                      type="radio"
                      [disabled]="readonly"
                      [ngModel]="inspection[typeKeys.gradeProp]"
                      [value]="item.value"
                      (click)="onGradeChange(inspection, typeKeys.gradeProp, item.value!)"
                      name="radioOption"
                    />
                    {{ item.label }}
                  </label>
                </div>
              </ng-container>
            </div>
          </div>
          <div *ngIf="typeKeys.thicknessField">
            <e2g-text-input
              [id]="typeKeys.idName"
              [ngModel]="inspection[typeKeys.gradeProp]"
              (ngModelChange)="onValueChange($event, inspection, typeKeys.gradeProp)"
              (blur)="onClickOutside()"
              type="number"
              [disabled]="readonly"
            ></e2g-text-input>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="form-group row mb-1" *ngIf="showRecommendation(inspection.componentId!)">
      <quill-editor
        class="activity-local-quill ml-3 mr-3 w-100"
        [ngModel]="getRecommendation(inspection.componentId!)"
        id="inp-inspectionRecommendation"
        [modules]="this.editorOptions"
        disabled="true"
      ></quill-editor>
    </div>
    <div class="slideout-line-spacer mt-0"></div>
  </div>
</div>
