import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faSquareInfo } from '@fortawesome/pro-regular-svg-icons';
import { faRotateLeft } from '@fortawesome/pro-solid-svg-icons';
import { combineLatest, ReplaySubject, Subject, switchMap, takeUntil } from 'rxjs';
import { AssetDefaultsConfigurationDto } from 'src/app/asset-module/models/asset-defaults-configuration-dto';
import { EstablishingCorrosionRate } from 'src/app/asset-module/models/enums/establishing-corrosion-rate';
import { AssetTypes } from 'src/app/models/enums/asset-types';
import { UnitOfMeasure } from 'src/app/models/enums/unit-of-measure';
import { OnDestroyBaseComponent } from 'src/app/on-destroy-base-component/on-destroy-base-component';
import { UnitsOfMeasureEvaluator } from 'src/app/units-of-measure/units-of-measure-evaluator';
import { valuePostiveIntegerMessage, valuePostiveNumberMessage } from 'src/app/utilities/validation-messages';

import { AssetGoverningInspectionDefaultProperties } from './asset-governing-inspection-default-properties';
import { AssetGoverningInspectionDomainModel } from './asset-governing-inspection-domain-model';

@Component({
  selector: 'app-asset-governing-inspection-settings',
  templateUrl: './asset-governing-inspection-settings.component.html',
  styleUrls: ['./asset-governing-inspection-settings.component.scss']
})
export class AssetGoverningInspectionSettingsComponent extends OnDestroyBaseComponent implements OnInit {
  @Input() public model!: AssetGoverningInspectionDomainModel<AssetDefaultsConfigurationDto>;
  @Input() public assetDefaultsConfiguration?: AssetDefaultsConfigurationDto;
  @Input() public readonly: boolean = false;
  @Input() public hasProductSCRA: boolean = false;
  @Input() public set unitOfMeasure(value: UnitOfMeasure) {
    this.refreshUOM.next(value);
  }
  @Input() public assetType?: AssetTypes;
  @Output() public isValid: EventEmitter<boolean> = new EventEmitter<boolean>();

  private refreshUOM: ReplaySubject<UnitOfMeasure> = new ReplaySubject();
  public refreshDueDateBasis: Subject<void> = new Subject();

  public resetIcon = faRotateLeft;
  public infoIcon = faSquareInfo;

  public scraEnabled: boolean = false;

  public corrosionRateUom: string = '';
  public thicknessUom: string = '';

  public showMawpApproach: boolean = true;
  public dueDateBasisValid: boolean = false;

  public establishingCorrosionRate = EstablishingCorrosionRate;
  public defaultProperties = AssetGoverningInspectionDefaultProperties;

  public constructor(private uomEvaluator: UnitsOfMeasureEvaluator) {
    super();
  }

  public ngOnInit(): void {
    this.refreshUOM
      .pipe(
        switchMap((uom) =>
          combineLatest([
            this.uomEvaluator.getUnitsWithUnitSystem('thickness', uom),
            this.uomEvaluator.getUnitsWithUnitSystem('estimatedCorrosionRate', uom)
          ])
        ),
        takeUntil(this.destroy)
      )
      .subscribe(([th, cr]) => {
        this.thicknessUom = th;
        this.corrosionRateUom = cr;
      });
  }

  public validate(): void {
    this.isValid.emit(
      (this.dueDateBasisValid ?? false) &&
        (this.model.useMawpApproach ||
          this.model.isRetirementThickness ||
          this.model.isStructuralRequiredThickness ||
          this.model.isCorrodedThickness ||
          this.model.isSpecifiedThickness) &&
        (this.model.isLongRateGoverning ||
          this.model.isShortRateGoverning ||
          this.model.isLinearRateGoverning ||
          this.model.isScraMeasuredLongRateGoverning ||
          this.model.isScraMeasuredShortRateGoverning ||
          this.model.isScraStatisticalLongRateGoverning ||
          this.model.isScraStatisticalShortRateGoverning ||
          this.model.isEstimatedCorrosionRate) &&
        this.establishingCorrosionRateValid()
    );
  }

  private establishingCorrosionRateValid(): boolean {
    return (
      (this.model.establishingCorrosionRateType == EstablishingCorrosionRate.ProbableRate &&
        this.model.establishingProbableRate > 0) ||
      (this.model.establishingCorrosionRateType == EstablishingCorrosionRate.TimeInterval &&
        this.model.establishingTimeInterval > 0)
    );
  }

  public getWarningsEstimatedCorrosionRate(): Array<string> {
    const estimatedCorrosionRateWarnings = [] as Array<string>;

    if (this.model.estimatedCorrosionRate && Number(this.model.estimatedCorrosionRate) >= 0) {
      estimatedCorrosionRateWarnings.push(
        'This value is only used when the group estimated rate is not input or linked.'
      );
    }

    return estimatedCorrosionRateWarnings;
  }

  public getErrorsEstimatedCorrosionRate(): Array<string> {
    const estimatedCorrosionRateErrors = [] as Array<string>;

    if (Number(this.model.estimatedCorrosionRate) < 0) {
      estimatedCorrosionRateErrors.push('Estimated corrosion rate cannot be less than zero.');
    }

    return estimatedCorrosionRateErrors;
  }

  public getProbableRateErrors(): Array<string> {
    return this.showEstablishingProbableRateInput && this.model.establishingProbableRate <= 0
      ? [valuePostiveNumberMessage()]
      : [];
  }

  public getTimeIntervalErrors(): Array<string> {
    return this.showEstablishingTimeIntervalInput && this.model.establishingTimeInterval <= 0
      ? [valuePostiveIntegerMessage()]
      : [];
  }

  public areTMinCheckboxesDisabled(): boolean {
    return this.model.useMawpApproach ?? false;
  }

  public resetGoverningCorrosionRate(): void {
    if (this.assetDefaultsConfiguration) {
      this.model.isLongRateGoverning = this.assetDefaultsConfiguration.isLongRateGoverning;
      this.model.isShortRateGoverning = this.assetDefaultsConfiguration.isShortRateGoverning;
      this.model.isLinearRateGoverning = this.assetDefaultsConfiguration.isLinearRateGoverning;
      this.model.isScraMeasuredLongRateGoverning = this.assetDefaultsConfiguration.isScraMeasuredLongRateGoverning;
      this.model.isScraMeasuredShortRateGoverning = this.assetDefaultsConfiguration.isScraMeasuredShortRateGoverning;
      this.model.isScraStatisticalLongRateGoverning =
        this.assetDefaultsConfiguration.isScraStatisticalLongRateGoverning;
      this.model.isScraStatisticalShortRateGoverning =
        this.assetDefaultsConfiguration.isScraStatisticalShortRateGoverning;
      this.model.isEstimatedCorrosionRate = this.assetDefaultsConfiguration.isEstimatedCorrosionRate;
      this.model.estimatedCorrosionRate = this.assetDefaultsConfiguration.estimatedCorrosionRate;
      this.model.resetDefaultValue(AssetGoverningInspectionDefaultProperties.governingCorrosionRate);

      this.validate();
    }
  }

  public resetEstablishingCorrosionRate(): void {
    if (this.assetDefaultsConfiguration) {
      this.model.establishingCorrosionRateType = this.assetDefaultsConfiguration.establishingCorrosionRateType;
      this.model.establishingProbableRate = this.assetDefaultsConfiguration.establishingProbableRate;
      this.model.establishingTimeInterval = this.assetDefaultsConfiguration.establishingTimeInterval;
      this.model.resetDefaultValue(AssetGoverningInspectionDefaultProperties.establishingCorrosionRate);

      this.validate();
    }
  }

  public resetCmlDueDateBasis(): void {
    if (this.assetDefaultsConfiguration) {
      this.model.halfLife = this.assetDefaultsConfiguration.halfLife;
      this.model.maximumInterval = this.assetDefaultsConfiguration.maximumInterval;
      this.model.resetDefaultValue(AssetGoverningInspectionDefaultProperties.cmlDueDateBasis);

      this.refreshDueDateBasis.next();

      this.validate();
    }
  }

  public resetGoverningMinimumThickness(): void {
    if (this.assetDefaultsConfiguration) {
      this.model.useMawpApproach = this.assetDefaultsConfiguration.useMawpApproach;
      this.model.isRetirementThickness = this.assetDefaultsConfiguration.isRetirementThickness;
      this.model.isStructuralRequiredThickness = this.assetDefaultsConfiguration.isStructuralRequiredThickness;
      this.model.isCorrodedThickness = this.assetDefaultsConfiguration.isCorrodedThickness;
      this.model.isSpecifiedThickness = this.assetDefaultsConfiguration.isSpecifiedThickness;
      this.model.resetDefaultValue(AssetGoverningInspectionDefaultProperties.governingMinimumThickness);

      this.validate();
    }
  }

  public showResetForDefault(defaultProperty: string): boolean {
    return (
      !this.readonly && this.assetDefaultsConfiguration !== undefined && !this.model.isDefaultValue(defaultProperty)
    );
  }

  public isAssetTypeOther(): boolean {
    return this.assetType ? this.assetType == AssetTypes.Other : false;
  }

  public get showEstablishingProbableRateOverrideInfoIcon(): boolean {
    return (
      this.model.establishingCorrosionRateType === EstablishingCorrosionRate.ProbableRate &&
      this.model.isEstimatedCorrosionRate &&
      (this.model.estimatedCorrosionRate ?? 0) > 0
    );
  }

  public get showEstablishingProbableRateInput(): boolean {
    return (
      this.model.establishingCorrosionRateType === EstablishingCorrosionRate.ProbableRate &&
      !this.showEstablishingProbableRateOverrideInfoIcon
    );
  }

  public get showEstablishingTimeIntervalInput(): boolean {
    return this.model.establishingCorrosionRateType === EstablishingCorrosionRate.TimeInterval;
  }
}
