import { Observable } from 'rxjs';

import { CompanyService } from '../company.service';
import { unionArrays } from '../utilities/array-helper';
import { SingleDataHandler } from './single-data-handler';

export class SingleDataHandlerDefault<T extends object> extends SingleDataHandler<T> {
  private userSetProps: { [key: string]: boolean } = {};
  private rawData: T = {} as T;
  private initDefaultProps?: Array<string>;

  public constructor(
    destroy: Observable<any>,
    dirtyCallback?: (dirty: boolean) => void,
    companyService?: CompanyService,
    validationCallback?: () => void
  ) {
    super(destroy, dirtyCallback, companyService, validationCallback);
    this.initUserSetProps();
  }

  public override setInitialData(data: T): void {
    const defaultProps = (data as any)['defaultProperties'] || [];
    this.rawData = data;
    this.initDefaultProps = [...defaultProps];

    super.setInitialData(data);
    this.setupInitUserProps();
  }

  public override clear(): void {
    this.userSetProps = {};
    this.rawData = {} as T;
    this.initData = undefined;
    super.clear();
  }

  protected override onPropChange(prop: string): void {
    this.userSetProps[prop] = true;
  }

  public promoteChanges(): void {
    this.initDefaultProps = this.getDefaultPropNames();
    (this.rawData as any)['defaultProperties'] = this.initDefaultProps;
    super.promoteChanges();
    this.setupInitUserProps();
  }

  public revertChanges(): void {
    this.setInitialData(this.initData as T);
  }

  public silentSetValue(prop: string, value: any, triggerDataChanged: boolean = false): void {
    (this.rawData as any)[prop] = value;
    if (triggerDataChanged) {
      super.dataChanged();
      this.notifyListeners(prop);
    }
  }

  public silentClearValue(prop: string): void {
    delete (this.rawData as any)[prop];
    delete (this.userSetProps as any)[prop];
  }

  private setupInitUserProps(): void {
    if (this.initDefaultProps) {
      this.userSetProps = Object.keys(this.rawData).reduce((p, c) => {
        if (!this.initDefaultProps!.includes(c)) {
          p[c] = true;
        }
        return p;
      }, {} as { [key: string]: boolean });
    } else {
      this.userSetProps = {};
    }
  }

  public isDefaultValue(prop: string): boolean {
    return this.getDefaultPropNames().includes(prop);
  }

  public getUserSetValues(): T {
    return Object.keys(this.rawData).reduce((p, c) => {
      if (c in this.userSetProps) {
        p[c] = (this.rawData as any)[c];
      }
      return p;
    }, {} as any);
  }

  public getDefaultPropNames(): Array<string> {
    return unionArrays(Object.keys(this.rawData), this.initDefaultProps ?? []).reduce((p, c) => {
      if (!(c in this.userSetProps)) {
        p.push(c);
      }
      return p;
    }, [] as Array<string>);
  }

  public getPropNames(): Array<string> {
    return Object.keys(this.rawData) as Array<string>;
  }

  private initUserSetProps(): void {
    this.userSetProps = {};
  }  
}
