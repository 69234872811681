import { Component, Inject, OnInit } from '@angular/core';
import { setupNewComponent } from 'src/app/asset-module/components/component-slideout-utility';
import { ComponentRecordDto } from 'src/app/models/component-record-dto';
import { EquipmentDto } from 'src/app/models/equipment-dto';

import {
  CompDetailBaseComponent,
  CompDetailBaseComponentProviders
} from '../../asset-module/components/comp-detail-base-component';
import { ComponentSlideoutInput } from '../../asset-module/models/component-slideout-input';
import { BUSINESS_RULES } from '../../sage-common-module/business-rules-injection-token';
import { BusinessRulesDefinition } from '../../sage-common-module/models/business-rules-definition';
import { ISaveChanges } from '../../save-changes';
import { SLIDE_OUT_DATA } from '../../slide-out-module/slide-out-data-injection-token';
import { FcompBusinessRulesService } from '../fcomp-business-rules.service';

@Component({
  selector: 'app-fcomp',
  templateUrl: './fcomp.component.html',
  styleUrls: ['./fcomp.component.css'],
  providers: [
    {
      provide: BUSINESS_RULES,
      useClass: FcompBusinessRulesService
    },
    {
      provide: ISaveChanges,
      useExisting: FcompComponent
    },
    ...CompDetailBaseComponentProviders
  ]
})
export class FcompComponent extends CompDetailBaseComponent implements OnInit, ISaveChanges {
  public constructor(
    @Inject(SLIDE_OUT_DATA) slideOutData: ComponentSlideoutInput,
    @Inject(BUSINESS_RULES) businessRulesService: BusinessRulesDefinition
  ) {
    super(slideOutData, businessRulesService);
  }

  public ngOnInit(): void {
    this.setupComponentData();
  }

  protected override setupNewComponent(asset: EquipmentDto): ComponentRecordDto {
    return setupNewComponent(asset, this.assetConfig, this.slideOutData.newType!, true, true);
  }
}
