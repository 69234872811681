import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { addExtensions } from './app/utilities/extension-methods';


declare const MathJax: any;
MathJax.config.chtml.fontURL = '/assets/fonts/woff-v2';
MathJax.config.chtml.font.options.fontURL = '/assets/fonts/woff-v2';
MathJax.startup.defaultReady();

addExtensions();

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));


