import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, of } from 'rxjs';
import { InspectionGradesDto } from 'src/app/models/activity-effectiveness-dto';

import { AppSettingsService } from '../../app-settings/app-settings.service';
import { ActivityDetailDto } from '../../models/activity-detail-dto';
import { ReadingDto } from '../models/reading-dto';

@Injectable({
  providedIn: 'root'
})
export class ActivityDataService {
  private serverUri: string;

  public constructor(private http: HttpClient, private settingsService: AppSettingsService) {
    this.serverUri = this.settingsService.settings.apiUri as string;
  }

  public getActivities(assetId: string): Observable<Array<ActivityDetailDto>> {
    return this.http.get<Array<ActivityDetailDto>>(`${this.serverUri}/assets/${assetId}/activities`);
  }

  public getInspectionActivation(assetId: string, activityType: string, isFutureActivity: boolean): Observable<Array<InspectionGradesDto>> {
    return this.http.get<Array<InspectionGradesDto>>(
      `${this.serverUri}/assets/${assetId}/activityType/${activityType}/inspection-activation/${isFutureActivity}`
    );
  }

  public getSurveyReadings(activityId: string) : Observable<Array<ReadingDto>> {
    return this.http
      .get<Array<ReadingDto>>(`${this.serverUri}/surveys/${activityId}/readings`)
      .pipe(catchError((e) => {console.log(e); return of(new Array<ReadingDto>());}));
  }
}
