import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { faClone, faInputText, faMemo } from '@fortawesome/pro-regular-svg-icons';
import {
  faArrowRight,
  faCalculator,
  faChevronDown,
  faClipboard,
  faPencilAlt,
  faTrashXmark,
} from '@fortawesome/pro-solid-svg-icons';
import { GridApi, ICellRendererParams } from 'ag-grid-community';
import { combineLatest, forkJoin, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { AlertService } from 'src/app/alert.service';
import { ComponentSlideoutOption } from 'src/app/asset-module/models/component-slideout-option';
import { Breadcrumb } from 'src/app/breadcrumb-module/breadcrumbs/breadcrumbs.component';
import { GridCellLinkParams } from 'src/app/grid-module/cell-renders/grid-cell-link/grid-cell-link-params';
import { GridCellLinkComponent } from 'src/app/grid-module/cell-renders/grid-cell-link/grid-cell-link.component';
import { GridCellTooltipComponent } from 'src/app/grid-module/cell-renders/grid-cell-tooltip/grid-cell-tooltip.component';
import { getComponentGridColumns } from 'src/app/grid-module/column-definitions/component-grid-columns';
import { E2gAgGridComponent, GridBaseOptions } from 'src/app/grid-module/e2g-ag-grid/e2g-ag-grid.component';
import { removeColumnsFromGrid } from 'src/app/grid-module/grid-options/grid-options-utility-functions';
import { ComponentDetailsDto } from 'src/app/models/component-details-dto';
import { OnDestroyBaseComponent } from 'src/app/on-destroy-base-component/on-destroy-base-component';
import { ActionTypes } from 'src/app/shared-module/models/action-types';
import { DialogType, NameDialogData } from 'src/app/shared-module/models/name-dialog-data';
import {
  AssetFeature,
  AssetPermissionModel,
  GeneralFeature,
  NO_PERMISSION,
} from 'src/app/shared-module/permission/permission-models';
import { PermissionService } from 'src/app/shared-module/permission/permission.service';
import { NotificationService } from 'src/app/shared-module/services/notification.service';
import { SlideOutService } from 'src/app/slide-out-module/slide-out.service';
import { StateService } from 'src/app/state.service';

import { CompDataService } from '../../../comp-data.service';
import { CompanyService } from '../../../company.service';
import { E2gMenuItem } from '../../../grid-module/cell-renders/grid-cell-menu/e2g-menu-item';
import { GridCellMenuComponent } from '../../../grid-module/cell-renders/grid-cell-menu/grid-cell-menu-component';
import { ComponentTypeDto } from '../../../models/component-type-dto';
import { AssetTypes } from '../../../models/enums/asset-types';
import { EquipmentUnitInput } from '../../../models/equipment-unit-input';
import { RefreshService } from '../../../notifications-module/refresh.service';
import { DialogService } from '../../../shared-module/dialog.service';
import { ListDataHandler } from '../../../shared-module/list-data-handler';
import { NEW_ITEM_KEY } from '../../../shared-module/models/new-item-key';
import { ComponentSlideoutInput } from '../../models/component-slideout-input';
import { ComponentDetailComponent } from '../component-detail/component-detail.component';

interface CompViewModel extends ComponentDetailsDto {}

@Component({
  selector: 'app-comp',
  templateUrl: './comp.component.html',
  styleUrls: ['./comp.component.css']
})
export class CompComponent extends OnDestroyBaseComponent implements OnInit {
  @Input() public equipmentType!: AssetTypes;
  @Input() public breadcrumbs: Breadcrumb[] = [];
  @ViewChild('componentGrid', { static: false }) public componentGrid?: E2gAgGridComponent;

  public faTrashXmark = faTrashXmark;
  public faCalculator = faCalculator;
  public faArrowRight = faArrowRight;
  public faChevronDown = faChevronDown;
  public faClipboard = faClipboard;
  public faPencilAlt = faPencilAlt;
  public faInputText = faInputText;
  private faClone = faClone;
  private faMemo = faMemo;

  public componentName?: string;
  public componentNames?: string[];

  public refresh: boolean = false;
  public saving: boolean = false;

  public inputData!: EquipmentUnitInput;
  public refreshData = new Subject<void>();
  public compViewModel: Array<CompViewModel> = [];

  public componentTypes?: Array<ComponentTypeDto>;
  public gridDataHandler: ListDataHandler<CompViewModel>;

  public areTankComponents: boolean = false;

  private components: Array<ComponentDetailsDto> = [];

  public componentPermissions: AssetPermissionModel = NO_PERMISSION;

  public canViewDiags = false;

  private calcRefresh: Observable<void> = this.refreshService.updates.pipe(
    filter((impactedIds) =>
      impactedIds.componentIds!.some((id) => (this.gridDataHandler.data ?? []).map((x) => x.id).includes(id))
    ),
    map(() => undefined),
    startWith(undefined),
    takeUntil(this.destroy)
  );

  private menuDefinition: Array<E2gMenuItem> = [
    {
      text: this.componentPermissions.edit ? 'Edit' : 'Show Detail',
      command: (params: ICellRendererParams): void => this.editComponent(params.data.id),
      iconDefinition: { icon: this.componentPermissions.edit ? this.faPencilAlt : this.faMemo },
      visible: (): boolean => true
    },
    {
      text: 'Rename',
      command: (params: ICellRendererParams): void => this.renameComponent(params.data.id, params.data.name),
      iconDefinition: { icon: this.faInputText },
      visible: (params: ICellRendererParams): boolean => this.isMenuRenameVisible(params)
    },
    {
      text: 'Copy',
      command: (params: ICellRendererParams): void =>
        params.data.type == 'COURSE'
          ? this.copyComponentCourse(params.data.id, params.data.name)
          : this.copyComponent(params.data.id, params.data.name),
      iconDefinition: { icon: this.faClone },
      visible: (params: ICellRendererParams): boolean => this.isMenuCopyVisible(params)
    },
    {
      text: 'Delete',
      command: (params: ICellRendererParams): void => this.delete(params.data.id, params.data.name),
      iconDefinition: { icon: this.faTrashXmark, color: 'var(--system-fg-error)' },
      visible: (): boolean => this.componentPermissions.delete,
      disabled: (params: ICellRendererParams): boolean => this.isMenuDeleteDisabled(params)
    },
    {
      text: 'Show Calculation Diagnostics',
      command: (params: ICellRendererParams): void => this.editComponent(params.data.id, true),
      iconDefinition: { icon: this.faCalculator, color: 'var(--input-fg-icon)' },
      visible: (): boolean => this.canViewDiags,
      disabled: (): boolean => false
    }
  ];

  public componentGridOptions: GridBaseOptions = {
    exportFileName: 'Components',
    context: 'components',
    suppressCellFocus: true,
    rowSelection: 'single',
    onSelectionChanged: this.onSelectionChanged.bind(this),
    onRowDataUpdated: this.onRowDataUpdated.bind(this),
    defaultColDef: {
      resizable: true,
      sortable: true,
      minWidth: 100,
      tooltipComponent: GridCellTooltipComponent,
      filter: 'agSetColumnFilter'
    },
    columnDefs: [
      {
        headerName: 'Name',
        headerTooltip: 'Name',
        field: 'name',
        tooltipField: 'name',
        width: 160,
        comparator: (a, b): number => a.localeCompare(b, 'en', { numeric: true, sensitivity: 'base' }),
        cellRenderer: GridCellLinkComponent,
        cellRendererParams: {
          isLink: (): boolean => true,
          linkCommand: (params: ICellRendererParams): void => this.editComponent(params.data.id)
        } as GridCellLinkParams
      },
      {
        headerName: 'Actions',
        headerTooltip: 'Actions',
        width: 130,
        cellStyle: { textAlign: 'center' },
        headerClass: 'ag-header-center',
        cellRenderer: GridCellMenuComponent,
        cellRendererParams: { menuItems: this.menuDefinition },
        filter: false
      },
      ...getComponentGridColumns()
    ]
  };

  public constructor(
    private permissionService: PermissionService,
    private route: ActivatedRoute,
    private companyService: CompanyService,
    private compService: CompDataService,
    private dialogService: DialogService,
    private notificationService: NotificationService,
    private slideOutService: SlideOutService,
    private refreshService: RefreshService,
    private alertService: AlertService,
    private stateService: StateService
  ) {
    super();

    this.gridDataHandler = new ListDataHandler<CompViewModel>('id', 'updateType', this.destroy, this.companyService);
  }

  public ngOnInit(): void {
    this.configGrid();

    this.route.params
      .pipe(
        take(1),
        tap((params) => {
          this.setInputData(params);
        }),
        switchMap(() => this.assignPermissions()),
        switchMap(() => combineLatest([this.refreshData.pipe(startWith(null)), this.calcRefresh])),
        tap(() => {
          this.gridDataHandler.clearData();
        }),
        switchMap(() =>
          forkJoin([this.compService.getComponentTypes(this.inputData.equipmentKey), this.getDataForGrid()])
        ),
        takeUntil(this.destroy)
      )
      .subscribe(([componentTypes]) => {
        this.componentTypes = this.filterComponentTypes(componentTypes);

        const selectedId = this.stateService.getSelectedGridId(this.getGridContext());

        if (selectedId !== undefined) {
          this.componentGrid?.selectRow(selectedId);
        }
      });

    this.stateService.state
      .pipe(
        map(() => this.stateService.getSelectedGridId(this.getGridContext())),
        filter((x) => x !== undefined),
        distinctUntilChanged(),
        takeUntil(this.destroy)
      )
      .subscribe((selectedId) => {
        const exists = this.componentGrid?.data?.find((x: ComponentDetailsDto) => x.id === selectedId);

        if (exists === undefined) {
          this.refreshData.next();
        }

        this.componentGrid?.selectRow(selectedId!);
      });
  }

  private assignPermissions(): Observable<any> {
    return this.permissionService
      .getPermissions({
        asset: [{ feature: AssetFeature.Component, assetId: this.inputData.equipmentKey! }],
        general: [{ feature: GeneralFeature.SageDiagnostics }, { feature: GeneralFeature.Rbi }]
      })
      .pipe(
        tap((permissions) => {
          this.componentPermissions = permissions.asset[AssetFeature.Component] || NO_PERMISSION;
          this.canViewDiags = permissions.general[GeneralFeature.SageDiagnostics] || false;

          if (!permissions.general[GeneralFeature.Rbi]) {
            removeColumnsFromGrid(this.componentGridOptions, ['rbi', 'risk']);
          }
        })
      );
  }

  private getGridContext(): string {
    return this.componentGridOptions.context;
  }

  private onSelectionChanged(): void {
    const selectedRow = this.componentGrid!.api!.getSelectedRows()[0];

    if (selectedRow !== undefined) {
      this.stateService.setSelectedGridId(this.componentGridOptions.context, selectedRow.id);
    }
  }

  public addComponent(type: string): void {
    this.editComponent(NEW_ITEM_KEY, false, type);
  }

  private editComponent(id: string, showDiags: boolean = false, newType: string = ''): void {
    const optionsData = this.componentGrid!.convertNodesToSlideoutOptions<CompViewModel, ComponentSlideoutOption>(
      (data) => {
        return {
          name: data.name,
          assetId: this.inputData.equipmentKey!,
          type: data.type
        };
      }
    );

    this.openSlideOut({
      id: id,
      newType: newType,
      assetId: this.inputData.equipmentKey,
      showDiags: showDiags,
      routeData: this.inputData,
      gridContext: this.componentGrid?.gridOptions.context,
      optionsData: optionsData,
      breadcrumbs: this.breadcrumbs
    });
  }

  private openSlideOut(data: ComponentSlideoutInput): void {
    this.slideOutService
      .open<boolean, ComponentDetailComponent>(ComponentDetailComponent, {
        data
      })
      .closed.pipe(take(1))
      .subscribe(() => this.refreshData.next());
  }

  private configGrid(): void {
    this.areTankComponents = this.equipmentType === AssetTypes.StorageTank;
    this.componentGridOptions.context = this.areTankComponents ? 'components-tank' : 'components';

    if (this.areTankComponents) {
      this.componentGridOptions.defaultColDef!.sortable = false;
    }

    this.componentGridOptions.setDefaultSort = (api: GridApi): void => {
      if (this.areTankComponents) {
        api.applyColumnState({
          state: [
            {
              colId: 'type',
              sort: 'asc'
            }
          ]
        });
      } else {
        api.applyColumnState({
          state: [
            {
              colId: 'name',
              sort: 'asc'
            }
          ]
        });
      }
    };
  }

  private filterComponentTypes(componentTypes: Array<ComponentTypeDto>): Array<ComponentTypeDto> {
    const indexOfTube = componentTypes.findIndex((x) => x.type === 'TUBE');

    if (
      indexOfTube > -1 &&
      this.equipmentType !== AssetTypes.ShellTubeHeatExchanger &&
      this.equipmentType !== AssetTypes.AirCooler
    ) {
      componentTypes.splice(indexOfTube, 1);
    } else if (this.areTankComponents) {
      const limitedComponents = ['FLOOR', 'ROOF', 'ANNULAR PLATE'];

      this.components!.map((x) => x.type)
        .filter((value) => limitedComponents.includes(value))
        .forEach((option) => {
          componentTypes.splice(
            componentTypes.findIndex((x) => x.type === option),
            1
          );
        });
    }

    const indexOfHeaderBox = componentTypes.findIndex((x) => x.type === 'HEADER BOX');

    if (indexOfHeaderBox > -1 && this.equipmentType !== AssetTypes.AirCooler) {
      componentTypes.splice(indexOfHeaderBox, 1);
    }

    if (this.equipmentType === AssetTypes.AirCooler) {
      componentTypes = componentTypes.filter((x) => x.type !== 'SHELL' && x.type !== 'HEAD');
    }

    return componentTypes;
  }

  private delete(id: string, name: string): void {
    this.dialogService
      .displayDelete({ dialogType: DialogType.Component, name })
      .pipe(
        tap(() => (this.saving = true)),
        switchMap(() => this.compService.deleteComponent(id))
      )
      .subscribe((success) => this.showResultAndRefresh(success, ActionTypes.Delete, name));
  }

  private isMenuDeleteDisabled(params: ICellRendererParams): boolean {
    return params.data.conditionMonitoringLocationsCount > 0;
  }

  public getDataForGrid(): Observable<ComponentDetailsDto[]> {
    return this.compService.getComponents(this.inputData.equipmentKey).pipe(
      tap((components) => {
        this.components = components;
        this.processViewModel();
        this.gridDataHandler.setInitialData(this.compViewModel!);
        this.componentNames = this.components!.map((component) => component.name);
        this.alertService.updateIdsInEdit({ componentIds: this.components.map((x) => x.id) });
      })
    );
  }

  private isMenuRenameVisible(params: ICellRendererParams): boolean {
    return this.componentPermissions.edit && params.data.type !== 'COURSE';
  }

  private isMenuCopyVisible(params: ICellRendererParams): boolean {
    return (
      this.componentPermissions.edit &&
      !(params.data.type === 'ROOF' || params.data.type === 'FLOOR' || params.data.type === 'ANNULAR PLATE')
    );
  }

  private selectNewComponent(compId: string, name: string): void {
    const success = compId !== undefined;
    if (success) {
      this.stateService.setSelectedGridId(this.componentGridOptions.context, compId);
    }
    this.showResultAndRefresh(success, ActionTypes.Copy, name);
  }

  private processViewModel(): void {
    if (!this.components) {
      return;
    }

    this.components.map((x) => {
      x.maximumAllowableWorkingPressure =
        x.maximumAllowableWorkingPressure !== undefined
          ? Number(Number(x.maximumAllowableWorkingPressure).toFixed(3))
          : undefined;
      x.nominalThickness =
        x.nominalThickness !== undefined
          ? !isNaN(Number(x.nominalThickness))
            ? Number(x.nominalThickness).toFixed(3)
            : x.nominalThickness
          : undefined;
      x.corrosionAllowance =
        x.corrosionAllowance !== undefined ? Number(Number(x.corrosionAllowance).toFixed(3)) : undefined;
      x.minimumThickness =
        x.minimumThickness !== undefined
          ? !isNaN(Number(x.minimumThickness))
            ? Number(x.minimumThickness).toFixed(3)
            : x.minimumThickness
          : undefined;
    });

    this.compViewModel = this.components
      .map((x) => {
        const result = { ...x } as CompViewModel;
        return result;
      })
      .sort((a, b) => a.id.localeCompare(b.id));
  }

  private setInputData(params: Params): void {
    this.inputData = {
      equipmentKey: params.equipmentKey,
      unitKey: params.unitKey,
      unitOfMeasure: params.unitOfMeasure
    };
  }

  private copyComponentCourse(id: string, name: string): void {
    this.compService
      .copyComponent(id) //Course name + number logic on backend
      .subscribe((compId) => this.selectNewComponent(compId, name));
  }

  private onRowDataUpdated(): void {
    // This is for when you go to export view from the RBI tab of the component form
    // and then come back to the component page.
    if (this.stateService.staticState.flow.componentKey) {
      this.editComponent(this.stateService.staticState.flow.componentKey);

      this.stateService.setFlowComponent(undefined);
    }
  }

  private copyComponent(id: string, name: string): void {
    const copyDialogData = this.buildDialogData(name);
    this.dialogService
      .displayCopy(copyDialogData)
      .pipe(switchMap((newName) => this.compService.copyComponent(id, newName)))
      .subscribe((compId) => this.selectNewComponent(compId, name));
  }

  private renameComponent(id: string, name: string): void {
    const renameDialogData = this.buildDialogData(name);
    this.dialogService
      .displayRename(renameDialogData)
      .pipe(switchMap((newName) => this.compService.renameComponent(id, newName)))
      .subscribe((success) => this.showResultAndRefresh(success, ActionTypes.Rename, name));
  }

  private buildDialogData(name: string): NameDialogData {
    return {
      name: name,
      invalidNames: this.components!.map((component) => component.name),
      dialogType: DialogType.Component
    };
  }

  private showResultAndRefresh(success: boolean, actionType: ActionTypes, itemName: string): void {
    this.notificationService.showActionResult(success, actionType, itemName);
    if (success) {
      this.refreshData.next();
    }
    this.saving = false;
  }
}
