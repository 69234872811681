<div *ngIf="!loading; else load">
  <div class="row mb-4 mt-2">
    <div class="col-12">
      <div class="card h-100">
        <h4 class="card-header mb-0">Creep Assessment Limits</h4>
        <div class="card-body">
          <div class="d-flex align-items-middle">
            <div class="sage-label">Maximum Temperature Limit :</div>
            <div
              id="maximumTemperatureLimit"
              class="sage-value"
              [innerHTML]="toFixedFourFormater(dataHandler.data.maximumTemperatureLimit)"
            ></div>
            <div class="sage-units">{{ maximumTemperatureLimitUom | async }}</div>
          </div>

          <div class="d-flex align-items-middle">
            <div class="sage-label">Maximum Adjusted Temperature :</div>
            <div
              id="maximumAdjustedTemperature"
              class="sage-value"
              [innerHTML]="toFixedFourFormater(dataHandler.data.maximumAdjustedTemperature)"
            ></div>
            <div class="sage-units">{{ maximumAdjustedTemperatureUom | async }}</div>
          </div>

          <div class="d-flex align-items-middle">
            <div class="sage-label">Minimum Thickness :</div>
            <div
              id="minimumThickness"
              class="sage-value"
              [innerHTML]="toFixedFourFormater(dataHandler.data.minimumThickness)"
            ></div>
            <div class="sage-units">{{ minimumThicknessUom | async }}</div>
          </div>

          <div class="d-flex align-items-middle">
            <div class="sage-label">Thickness Limit - Structural :</div>
            <div
              id="thicknessLimitStructural"
              class="sage-value"
              [innerHTML]="toFixedFourFormater(dataHandler.data.thicknessLimitStructural)"
            ></div>
            <div class="sage-units">{{ thicknessLimitStructuralUom | async }}</div>
          </div>

          <div class="d-flex align-items-middle">
            <div class="sage-label">Minimum Yield Safety Factor :</div>
            <div
              id="minimumYieldSafetyFactor"
              class="sage-value"
              [innerHTML]="toFixedFourFormater(dataHandler.data.minimumYieldSafetyFactor)"
            ></div>
            <div class="sage-units">{{ minimumYieldSafetyFactorUom | async }}</div>
          </div>

          <div class="d-flex align-items-middle">
            <div class="sage-label">Creep Damage Limit :</div>
            <div
              id="creepDamageLimit"
              class="sage-value"
              [innerHTML]="toFixedFourFormater(dataHandler.data.creepDamageLimit)"
            ></div>
            <div class="sage-units">{{ creepDamageLimitUom | async }}</div>
          </div>

          <div class="d-flex align-items-middle">
            <div class="sage-label">Calculated Creep Damage :</div>
            <div
              id="calculatedCreepDamage"
              class="sage-value"
              [innerHTML]="toFixedFourFormater(dataHandler.data.calculatedCreepDamage)"
            ></div>
            <div class="sage-units">{{ calculatedCreepDamageUom | async }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-4">
    <div class="col-12">
      <div class="card h-100">
        <h4 class="card-header mb-0">Creep Assessment Messages</h4>
        <div class="card-body">
          <ng-container *ngFor="let msg of dataHandler.data.creepAssessmentMessages">
            <p [innerHTML]="parseMarkdown(msg)"></p>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-4">
    <div class="col-12">
      <div class="card h-100">
        <h4 class="card-header mb-0">Creep Applicability and Limitations</h4>
        <div class="card-body">
          <ul class="mb-0 p-0 pl-3">
            <li *ngFor="let item of dataHandler.data.creepApplicabilityAndLimitationsMessages!">{{ item }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #load>
  <div class="loading"></div>
</ng-template>
