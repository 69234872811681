import { IRowNode } from 'ag-grid-community';
import { defaultWithNullComparer } from 'src/app/grid-module/column-data-comparers/default-with-null-comparator';
import { MawpApproachTmin } from 'src/app/grid-module/column-formatters/minimum-thickness-formatter';

export function minimumThicknessWithMawpAndNullComparator(
  valueA: any,
  valueB: any,
  nodeA: IRowNode,
  nodeB: IRowNode,
  isInverted: boolean
): number {
  return defaultWithNullComparer(valueA, valueB, isInverted, (a, b) => {
    if (a == MawpApproachTmin) {
      return isInverted ? -1 : 1;
    }
    return a < b ? -1 : 1;
  });
}
