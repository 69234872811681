import { Component } from '@angular/core';
import { ITooltipAngularComp } from 'ag-grid-angular';
import { ITooltipParams } from 'ag-grid-community';

@Component({
  selector: 'app-grid-cell-tooltip',
  templateUrl: './grid-cell-tooltip.component.html',
  styleUrls: ['./grid-cell-tooltip.component.css']
})
export class GridCellTooltipComponent implements ITooltipAngularComp {
  public data!: any;
  public toolTip?: string;

  public agInit(params: unknown & ITooltipParams): void {
    this.toolTip = params.location === 'header' ? this.getToolTipForHeader(params) : this.getToolTipForCell(params);

    if (params.location !== 'header') {
      this.data = params.api!.getDisplayedRowAtIndex(params.rowIndex!)!.data;
    }
  }

  private getToolTipForHeader(params: unknown & ITooltipParams): string | undefined {
    return params.colDef?.headerTooltip ?? params.colDef?.headerName;
  }

  private getToolTipForCell(params: unknown & ITooltipParams): string {
    return params.valueFormatted === null ? params.value : params.valueFormatted?.toString();
  }
}
