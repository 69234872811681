import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { faRotateLeft } from '@fortawesome/pro-solid-svg-icons';
import { ComponentRecordDto } from 'src/app/models/component-record-dto';
import { AssetTypes } from 'src/app/models/enums/asset-types';
import { EquipmentConfigurationDto } from 'src/app/models/equipment-configuration-dto';
import { OnDestroyBaseComponent } from 'src/app/on-destroy-base-component/on-destroy-base-component';
import { SingleDataHandlerDefault } from 'src/app/shared-module/single-data-handler-default';
import { UnitsOfMeasureEvaluator } from 'src/app/units-of-measure/units-of-measure-evaluator';

import { BUSINESS_RULES } from '../business-rules-injection-token';
import { BusinessRulesDefinition } from '../models/business-rules-definition';
import { TminBusinessRulesService } from '../tmin-business-rules.service';

@Component({
  selector: 'app-governing-tmin',
  templateUrl: './governing-tmin.component.html',
  styleUrls: ['./governing-tmin.component.css'],
  providers: [
    {
      provide: BUSINESS_RULES,
      useClass: TminBusinessRulesService
    }
  ]
})
export class GoverningTminComponent extends OnDestroyBaseComponent implements OnInit {
  @Input() public dataHandler!: SingleDataHandlerDefault<ComponentRecordDto>;
  @Input() public readOnly: boolean = false;
  @Input() public assetConfig!: EquipmentConfigurationDto;
  @Output() public isValid = new EventEmitter<boolean>();

  public thicknessUom: string = '';
  public resetIcon = faRotateLeft;
  public governingMinimumThickness = 'governingMinimumThickness';
  public codeRetirementThicknessWarnings: Array<string> = [];
  public optionLabelWidth = '200px';

  public constructor(
    @Inject(BUSINESS_RULES) private businessRulesService: BusinessRulesDefinition,
    private uomEvaluator: UnitsOfMeasureEvaluator
  ) {
    super();
  }

  public ngOnInit(): void {
    this.uomEvaluator.getUnits('thickness').subscribe((thick) => (this.thicknessUom = thick));
    this.businessRulesService.init(this.dataHandler);
    this.businessRulesService.readOnly = this.readOnly;
    this.businessRulesService.start();
  }

  public getFixed(prop: number | undefined, digits: number): string {
    if (prop == null) {
      return 'N/A';
    }
    return Number(prop).toFixed(digits);
  }

  public resetGoverningMinimumThickness(): void {
    this.dataHandler.silentClearValue(this.governingMinimumThickness);
    this.dataHandler.silentSetValue(this.governingMinimumThickness, 1, true);
    this.dataHandler.data.isRetirementThickness = this.assetConfig.isRetirementThickness;
    this.dataHandler.data.isStructuralRequiredThickness = this.assetConfig.isStructuralRequiredThickness;
    this.dataHandler.data.isCorrodedThickness = this.assetConfig.isCorrodedThickness;
    this.dataHandler.data.isSpecifiedThickness = this.assetConfig.isSpecifiedThickness;
  }

  public resetUseMawpApproach(): void {
    this.dataHandler.silentClearValue('useMawpApproach');
    this.dataHandler.silentSetValue('useMawpApproach', this.dataHandler.data.eqUseMawpApproach, true);
  }

  public getTmin(): string {
    if (this.dataHandler.data.useMawpApproach) {
      return 'MAWP';
    }
    const nums: Array<number | undefined> = [];
    if (this.compCanBeCalculated()) {
      if (this.dataHandler.data.isRetirementThickness) {
        nums.push(this.dataHandler.data.retirementThickness);
      }
      if (this.dataHandler.data.isStructuralRequiredThickness) {
        nums.push(this.dataHandler.data.structuralRequiredThickness);
      }
    }
    if (this.dataHandler.data.isCorrodedThickness) {
      nums.push(this.dataHandler.data.corrodedThickness);
    }
    if (this.dataHandler.data.isSpecifiedThickness) {
      nums.push(this.dataHandler.data.specifiedMinimumThickness);
    }
    const definedNums = nums.filter((x) => x !== undefined && x > 0).map((x) => x!);
    return this.getFixed(definedNums.length > 0 ? Math.max(...definedNums) : undefined, 3);
  }

  public checkIsValid(): void {
    (this.dataHandler.data as any)[this.governingMinimumThickness] = 0;
    this.isValid.emit(
      (this.compCanBeCalculated() && this.dataHandler.data.isRetirementThickness) ||
        (this.compCanBeCalculated() && this.dataHandler.data.isStructuralRequiredThickness) ||
        this.dataHandler.data.isCorrodedThickness ||
        this.dataHandler.data.isSpecifiedThickness
    );
  }

  public compCanBeCalculated(): boolean {
    return (
      !(
        this.dataHandler.data.compType == 'NOZZLE' ||
        this.dataHandler.data.compType == 'ROOF' ||
        this.dataHandler.data.compType == 'FLOOR' ||
        this.dataHandler.data.compType == 'ANNULAR PLATE'
      ) && this.dataHandler.data.geomType != 'PB' //FLAT Bolted Plate
    );
  }

  public showMawpApproach(): boolean {
    return (
      (this.compCanBeCalculated() || this.dataHandler.data.useAdvancedCalculation) &&
      this.dataHandler.data.assetType !== AssetTypes.StorageTank
    );
  }

  public getCodeWarnings(): Array<string> {
    if (
      this.dataHandler.data.maximumFutureCorrosionAllowance !== undefined &&
      this.dataHandler.data.maximumFutureCorrosionAllowance <= 0
    ) {
      return ['Review Design Data'];
    }
    return [];
  }
}
