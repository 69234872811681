import { Injectable } from '@angular/core';
import { AuthService } from '@equityeng/auth';
import { Observable, ReplaySubject, switchMap } from 'rxjs';

import { CompanyService } from './company.service';
import { DashboardFiltersDto } from './dashboard-module/models/dashboard-filters-dto';
import { HierarchyTreeFiltersDto } from './hierarchy-tree-module/models/hierarchy-tree-filters-dto';
import { UserDataDto } from './models/user-data-dto';
import { UserDataService } from './user-data.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private staticUserData!: UserDataDto;

  public userData: Observable<UserDataDto> = new ReplaySubject<UserDataDto>();

  public constructor(
    private authService: AuthService,
    private companyService: CompanyService,
    private dataService: UserDataService
  ) {}

  public init(): void {
    this.companyService.selectedCompany
      .pipe(
        switchMap(() => this.authService.userAuthenticated$),
        switchMap(() => this.dataService.getUserData())
      )
      .subscribe((x) => {
        this.staticUserData = x;
        this.setUserData(false);
      });
  }

  public setUserData(update: boolean): void {
    (this.userData as ReplaySubject<UserDataDto>).next(this.staticUserData);

    if (update) {
      this.dataService.updateUserData(this.staticUserData).subscribe();
    }
  }

  public viewReleaseNotes(): void {
    this.staticUserData.latestReleaseNotesView = new Date();
    this.setUserData(true);
  }

  public setHighlightDrivingCml(value: boolean): void {
    this.staticUserData.highlightDrivingCml = value;
    this.setUserData(true);
  }

  public setAsDefaultFilterDashboard(filters: DashboardFiltersDto): void {
    this.staticUserData.dashboardFilters = filters;
    this.setUserData(true);
  }

  public setAsDefaultFilterHierarchyTree(filters: HierarchyTreeFiltersDto): void {
    this.staticUserData.hierarchyTreeFilters = filters;
    this.setUserData(true);
  }
}
