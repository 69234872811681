import { WeekDay } from '@angular/common';
import { DateOnlyUtility, E2gSelectOption } from '@equityeng/e2g-ng-ui';
import { ValueGetterParams } from 'ag-grid-community';
import { buildEnumColDef } from 'src/app/grid-module/column-builders/build-enum';
import { getMinimumThicknessColumn } from 'src/app/grid-module/column-definitions/minimum-thickness-column';

import {
  getGoverningCorrosionRateType,
  GoverningCorrosionRateTypes
} from '../asset-module/models/enums/governing-corrosion-rate-types';
import { buildDateOnlyColDef } from '../grid-module/column-builders/build-date-only';
import { doubleWithNullComparator } from '../grid-module/column-data-comparers/double-with-null-comparator';
import { decimalFormatter } from '../grid-module/column-formatters/decimal-formatter';
import { AssetTypes, getScraAssetType } from '../models/enums/asset-types';
import { ServiceStatus } from '../models/enums/service-status';
import { getInspectionDriver, InspectionDrivers } from '../reporting-module/models/enums/Inspection-drivers';
import { addDays, setDateUpcomingDayOfWeek, subtractDays } from '../utilities/date-helper';
import { DashboardChartFiltersDto } from './models/dashboard-chart-filters-dto';
import { DashboardFiltersDto } from './models/dashboard-filters-dto';
import { DateOnlyRange } from './models/date-only-range';
import { DashboardSlideoutType } from './models/enums/dashboard-slideout-type';

export function getDateRangeForNumberOfWeeks(planningHorizon: number): DateOnlyRange {
  return getDateRangeForPlanningHorizonWeeks(planningHorizon, false);
}

export function toDashboardFilterSelectOption(data: Array<any>): Array<E2gSelectOption> {
  return data
    .map((d) => ({
      value: d.id,
      label: d.name
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
}

export const PlanningHorizonOptions = [4, 13, 26, 52].map((x) => ({ label: `Planning Horizon: ${x} Weeks`, value: x }));

export function getEndOfWeekBasis(): Date {
  const endOfWeekBasisDate = new Date();
  setDateUpcomingDayOfWeek(endOfWeekBasisDate, WeekDay.Saturday);
  return endOfWeekBasisDate;
}

export function ConvertToChartFilters(filters: DashboardFiltersDto, isPast: boolean): DashboardChartFiltersDto {
  let dateRange: DateOnlyRange | undefined = undefined;

  if (filters.planningHorizonFilterParams!.planningHorizon) {
    dateRange = getDateRangeForPlanningHorizonWeeks(filters.planningHorizonFilterParams!.planningHorizon, isPast);
  } else if (filters.planningHorizonFilterParams!.planningHorizonDateRange) {
    const endOfWeekDateOnly = DateOnlyUtility.convertDateToDateOnly(getEndOfWeekBasis());
    const start = filters.planningHorizonFilterParams!.planningHorizonDateRange!.start!;
    const end = filters.planningHorizonFilterParams!.planningHorizonDateRange!.end!;
    if (isPast) {
      if (DateOnlyUtility.compare(start, DateOnlyUtility.today()) <= 0) {
        dateRange = {
          start: start,
          end: DateOnlyUtility.compare(end, endOfWeekDateOnly) < 0 ? end : endOfWeekDateOnly
        };
      }
    } else if (DateOnlyUtility.compare(end, DateOnlyUtility.today()) > 0) {
      dateRange = {
        start: DateOnlyUtility.compare(start, DateOnlyUtility.today()) > 0 ? start : DateOnlyUtility.today(),
        end: end
      };
    }
  }

  return {
    ...filters,
    dateRange: dateRange
  };
}

function getDateRangeForPlanningHorizonWeeks(planningHorizon: number, isPast: boolean): DateOnlyRange {
  if (isPast) {
    return {
      start: DateOnlyUtility.convertDateToDateOnly(subtractDays(getEndOfWeekBasis(), 7 * planningHorizon)),
      end: DateOnlyUtility.convertDateToDateOnly(getEndOfWeekBasis())
    };
  }
  return {
    start: DateOnlyUtility.today(),
    end: DateOnlyUtility.convertDateToDateOnly(addDays(getEndOfWeekBasis(), 7 * planningHorizon))
  };
}

export const criticalAssetColumnDef = [
  {
    headerName: 'Id',
    field: 'cmlId',
    hide: true
  },
  {
    headerName: 'Plant',
    headerTooltip: 'Plant',
    field: 'plant',
    tooltipField: 'plant'
  },
  {
    headerName: 'Unit',
    headerTooltip: 'Unit',
    field: 'unit',
    tooltipField: 'unit'
  },
  {
    headerName: 'Description',
    headerTooltip: 'Description',
    field: 'description',
    tooltipField: 'description',
    width: 200
  },
  {
    ...buildEnumColDef('Asset Type', 'type', AssetTypes, getScraAssetType),
    width: 125
  },
  buildEnumColDef('Service Status', 'serviceStatus', ServiceStatus),
  {
    headerName: 'CML Name',
    headerTooltip: 'CML Name',
    field: 'cmlName',
    tooltipField: 'cmlName',
    width: 125
  },
  {
    headerName: 'CML Line Number',
    headerTooltip: 'CML Line Number',
    field: 'cmlLineNumber',
    tooltipField: 'cmlLineNumber',
    hide: true,
    width: 175
  },
  getMinimumThicknessColumn(),
  {
    headerName: 'Minimum Thickness Units',
    headerTooltip: 'Minimum Thickness Units',
    field: 'minimumThicknessUnits',
    tooltipField: 'minimumThicknessUnits'
  },
  {
    headerName: 'Last Thickness',
    headerTooltip: 'Last Thickness',
    field: 'lastThickness',
    tooltipField: 'lastThickness',
    width: 150,
    comparator: doubleWithNullComparator,
    valueGetter: decimalFormatter,
    filterValueGetter: (params: ValueGetterParams): any => Number(decimalFormatter(params)),
    filter: 'agNumberColumnFilter'
  },
  {
    headerName: 'Last Thickness Units',
    headerTooltip: 'Last Thickness Units',
    field: 'lastThicknessUnits',
    tooltipField: 'lastThicknessUnits'
  },
  {
    ...buildDateOnlyColDef('Last Survey Date', 'lastSurveyDate'),
    width: 150
  },
  {
    ...buildDateOnlyColDef('Next Inspection Date', 'nextInspectionDate'),
    width: 175
  },
  {
    ...buildEnumColDef('Next Insp. Date Driver', 'inspectionDriver', InspectionDrivers, getInspectionDriver),
    width: 150
  },
  {
    headerName: 'Governing Corrosion Rate',
    headerTooltip: 'Governing Corrosion Rate',
    field: 'governingCorrosionRate',
    tooltipField: 'governingCorrosionRate',
    comparator: doubleWithNullComparator,
    valueGetter: decimalFormatter,
    filterValueGetter: (params: ValueGetterParams): any => Number(decimalFormatter(params)),
    filter: 'agNumberColumnFilter'
  },
  {
    headerName: 'Gov. Corr. Rate Units',
    headerTooltip: 'Gov. Corr. Rate Units',
    field: 'governingCorrosionRateUnits',
    tooltipField: 'governingCorrosionRateUnits'
  },
  {
    ...buildEnumColDef(
      'Gov. Corr. Rate Type',
      'governingCorrosionRateType',
      GoverningCorrosionRateTypes,
      getGoverningCorrosionRateType
    ),
    width: 150
  },
  {
    headerName: 'MAWP',
    headerTooltip: 'Maximum Allowable Working Pressure',
    field: 'maximumAllowableWorkingPressure',
    tooltipField: 'maximumAllowableWorkingPressure',
    comparator: doubleWithNullComparator,
    valueGetter: decimalFormatter,
    filterValueGetter: (params: ValueGetterParams): any => Number(decimalFormatter(params)),
    filter: 'agNumberColumnFilter'
  },
  {
    headerName: 'MAWP Units',
    headerTooltip: 'MAWP Units',
    field: 'mawpUnits',
    tooltipField: 'mawpUnits'
  },
  {
    headerName: 'Design Pressure',
    headerTooltip: 'Design Pressure',
    field: 'designPressure',
    tooltipField: 'designPressure',
    comparator: doubleWithNullComparator,
    valueGetter: decimalFormatter,
    filterValueGetter: (params: ValueGetterParams): any => Number(decimalFormatter(params)),
    filter: 'agNumberColumnFilter'
  },
  {
    headerName: 'Design Pressure Units',
    headerTooltip: 'Design Pressure Units',
    field: 'designPressureUnits',
    tooltipField: 'designPressureUnits'
  },
  {
    headerName: 'Component Name',
    headerTooltip: 'Component Name',
    field: 'compName',
    tooltipField: 'compName',
    hide: true,
    width: 175
  },
  {
    headerName: 'Group Name',
    headerTooltip: 'Group Name',
    field: 'circuitName',
    tooltipField: 'circuitName',
    hide: true,
    width: 175
  }
];

export function isPastChart(slideoutType: DashboardSlideoutType): boolean {
  return [DashboardSlideoutType.CompletedActivities, DashboardSlideoutType.CompletedReadings].includes(slideoutType);
}
