import { Component, Inject } from '@angular/core';
import { E2gSelectOption } from '@equityeng/e2g-ng-ui';
import { Observable } from 'rxjs';
import { DmlDetailBaseComponent } from 'src/app/dmls-module/dml-slideout/dml-detail-base-component';
import { DmlAssessmentLevels } from 'src/app/dmls-module/models/Enums/dml-assessment-levels';
import { DmlStatuses } from 'src/app/dmls-module/models/Enums/dml-statuses';
import { DmlTypes } from 'src/app/dmls-module/models/Enums/dml-types';
import { SLIDE_OUT_DATA } from 'src/app/slide-out-module/slide-out-data-injection-token';
import { enumToSelectOptions } from 'src/app/utilities/enum-helper';

import { DmlCrackingDto } from '../../models/dml-cracking-dto';
import { DmlSlideoutInput } from '../../models/dml-slideout-input';
import { DmlCrackDescriptions } from '../../models/Enums/dml-crack-descriptions';
import { DmlDamageLocations } from '../../models/Enums/dml-damage-locations';

@Component({
  selector: 'app-dml-cracking',
  templateUrl: './dml-cracking.component.html'
})
export class DmlCrackingComponent extends DmlDetailBaseComponent<DmlCrackingDto> {
  protected componentsShellModuleOnly = false;

  public damageLocationOptions: Array<E2gSelectOption> = enumToSelectOptions(DmlDamageLocations);
  public crackDescriptionOptions: Array<E2gSelectOption> = enumToSelectOptions(DmlCrackDescriptions);

  public flawSizeThicknessDirectionUom: Observable<string> = this.uomEvaluator.getUnits(
    'DmlCracking_FlawSizeThicknessDirection'
  );
  public flawSizeSurfaceDirectionUom: Observable<string> = this.uomEvaluator.getUnits(
    'DmlCracking_FlawSizeSurfaceDirection'
  );

  public constructor(@Inject(SLIDE_OUT_DATA) protected slideOutData: DmlSlideoutInput) {
    super(slideOutData);
  }

  public componentChange(componentId: string): void {
    const component = this.comps.find((x) => x.id === componentId);

    if (component !== undefined) {
      this.slideOutService.setBodyValid(true);
      if (component.operatingTemperature !== undefined) {
        if (component.operatingTemperature?.length === 0) {
          if (component.designTemperature !== undefined) {
            this.dataHandler.data.assessmentTemperature = component.designTemperature;
          }
        } else {
          this.dataHandler.data.assessmentTemperature = component.operatingTemperature;
        }
      }
    }
  }

  protected makeNewDml(): DmlCrackingDto {
    return {
      name: '',
      type: DmlTypes['Cracking'],
      hasCalculationErrors: false,
      status: DmlStatuses.New,
      assessmentLevel: DmlAssessmentLevels['Level 1'],
      damageLocation: DmlDamageLocations.Internal,
      crackDescription: DmlCrackDescriptions['Circumferential, no weld']
    };
  }
}
