import { Component, Inject, Input, OnInit } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { ComponentRecordDto } from 'src/app/models/component-record-dto';
import { OnDestroyBaseComponent } from 'src/app/on-destroy-base-component/on-destroy-base-component';
import { SingleDataHandlerDefault } from 'src/app/shared-module/single-data-handler-default';

import { BUSINESS_RULES } from '../business-rules-injection-token';
import { BusinessRulesDefinition } from '../models/business-rules-definition';
import { TubeBusinessRulesService } from '../tube-business-rules.service';

@Component({
  selector: 'app-tube-geometry',
  templateUrl: './tube-geometry.component.html',
  styleUrls: ['./tube-geometry.component.css'],
  providers: [
    {
      provide: BUSINESS_RULES,
      useClass: TubeBusinessRulesService
    }
  ]
})
export class TubeGeometryComponent extends OnDestroyBaseComponent implements OnInit {
  @Input() public dataHandler!: SingleDataHandlerDefault<ComponentRecordDto>;
  @Input() public readOnly!: boolean;

  public odTrigger = new ReplaySubject<void>(1);
  public odTextTrigger = new ReplaySubject<void>(1);
  public bwgTrigger = new ReplaySubject<void>(1);
  public nominalTrigger = new ReplaySubject<void>(1);
  public outerDiameterLoading: boolean = false;

  public requiredConditions: Record<string, Array<string>> = {};

  public outerDiameterUom?: string;
  public thicknessUom?: string;
  public innerDiameterUom?: string;
  public lengthUom?: string;

  public initialized: boolean = false;

  private lastSelectedBwg?: number;
  private lastSelectedOd?: number;
  public constructor(@Inject(BUSINESS_RULES) private businessRulesService: BusinessRulesDefinition) {
    super();
  }

  public ngOnInit(): void {
    this.businessRulesService.init(this.dataHandler);
    this.businessRulesService.readOnly = this.readOnly;
    setTimeout(() => {
      this.businessRulesService.start();
      this.initialized = true;
    });
  }

  public changeNsTube(): void {
    if (this.dataHandler.data.nonStandardTube) {
      this.lastSelectedOd = this.dataHandler.data.outerDiameter;
      this.lastSelectedBwg = this.dataHandler.data.bwg;
      this.dataHandler.silentClearValue('bwg');
    } else {
      this.dataHandler.silentClearValue('innerDiameter');
      this.dataHandler.silentClearValue('nominalThickness');
      if (this.lastSelectedOd == this.dataHandler.data.outerDiameter) {
        this.dataHandler.data.bwg = this.lastSelectedBwg;
        this.bwgTrigger.next();
      }
    }
  }
}
