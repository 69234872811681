export enum CalculationMessageStatus {
  New = 1,
  InProgress = 2,
  Complete = 3,
  Cancelled = 4,
  Errored = 5
}

export enum SageCalculationStatus {
  New = 1,
  Success = 2,
  Warning = 3,
  Error = 4
}
