import { ColDef } from 'ag-grid-community';
import { ActivityStatus, getActivityStatus } from 'src/app/asset-module/models/enums/activity-status';

import { buildArrayColDef } from '../column-builders/build-array';
import { buildDateOnlyColDef } from '../column-builders/build-date-only';
import { assignHeaderAndTooltip } from '../column-builders/build-default';
import { buildEnumColDef } from '../column-builders/build-enum';

export function getActivityUpcomingGridColumns(): Array<ColDef> {
  return [
    {
      headerName: 'ID',
      field: 'id',
      hide: true
    },
    assignHeaderAndTooltip('Description', 'description'),
    {
      ...buildDateOnlyColDef('Scheduled Date', 'scheduledDate'),
      width: 120
    },
    {
      ...buildDateOnlyColDef('Due Date', 'dueDate'),
      width: 120
    },
    buildArrayColDef('Assigned To', 'inspectorNames'),
    {
      ...buildEnumColDef('Status', 'status', ActivityStatus, getActivityStatus),
      width: 150
    },
    {
      ...assignHeaderAndTooltip('Component', 'componentName'),
      hide: true
    }
  ];
}
