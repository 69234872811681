import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { E2gNgUiModule } from '@equityeng/e2g-ng-ui';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { BreadcrumbModule } from '../breadcrumb-module/breadcrumb.module';
import { UploadModule } from '../upload-module/upload.module';
import { SlideOutContainerComponent } from './slide-out-container/slide-out-container.component';
import { SlideOutFooterComponent } from './slide-out-footer/slide-out-footer.component';
import { SlideOutHeaderComponent } from './slide-out-header/slide-out-header.component';
import { SlideOutDirective } from './slide-out.directive';
import { SlideOutComponent } from './slide-out/slide-out.component';

@NgModule({
  declarations: [SlideOutComponent, SlideOutDirective, SlideOutHeaderComponent, SlideOutFooterComponent, SlideOutContainerComponent],
  imports: [CommonModule, FormsModule, UploadModule, E2gNgUiModule, FontAwesomeModule, BreadcrumbModule],
  exports: [SlideOutComponent, SlideOutHeaderComponent, SlideOutFooterComponent, SlideOutContainerComponent],
})
export class SlideOutModule {}
