export enum DashboardSlideoutType {
  //Numbers
  CriticalAssets = 1,
  OverdueThickness = 2,
  OverdueActivities = 3,
  ActiveDmls = 4,
  ActivitiesToAccept = 5,
  Findings = 6,
  RemainingLife = 7,

  //Charts
  UpcomingThickness = 101,
  UpcomingActivities = 102,
  CompletedReadings = 103,
  CompletedActivities = 104,

  //RBI
  RiskMatrixRbiDate = 997,
  RiskMatrixPlanDate = 998,
  RiskMatrixWithInspection = 999
}

export function getDashboardHeading(type: DashboardSlideoutType): string {
  switch (type) {
    case DashboardSlideoutType.CriticalAssets:
      return 'Critical Assets';
    case DashboardSlideoutType.OverdueThickness:
      return 'Overdue Thickness';
    case DashboardSlideoutType.OverdueActivities:
      return 'Overdue Activities';
    case DashboardSlideoutType.ActiveDmls:
      return 'DMLs';
    case DashboardSlideoutType.ActivitiesToAccept:
      return 'Activities - Acceptance Needed';
    case DashboardSlideoutType.Findings:
      return 'Open Findings';
    case DashboardSlideoutType.RemainingLife:
      return 'Remaining Life';
    case DashboardSlideoutType.UpcomingThickness:
      return 'Upcoming Thickness';
    case DashboardSlideoutType.UpcomingActivities:
      return 'Upcoming Activities';
    case DashboardSlideoutType.CompletedReadings:
      return 'Completed Thickness Readings';
    case DashboardSlideoutType.CompletedActivities:
      return 'Completed Activities';
    case DashboardSlideoutType.RiskMatrixRbiDate:
    case DashboardSlideoutType.RiskMatrixPlanDate:
    case DashboardSlideoutType.RiskMatrixWithInspection:
      return 'Risk Matrix';
  }
}
