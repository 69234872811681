import { dateOnly, DateOnlyUtility } from '@equityeng/e2g-ng-ui';
import { CellClassParams, CellClassRules } from 'ag-grid-community';
import { ReadingDto } from 'src/app/asset-module/models/reading-dto';

export function columnCellThicknessRule(
  isDateCurrentlySelectedFunc: () => boolean,
  canEdit: boolean,
  selectedDateFunc?: () => dateOnly
): CellClassRules {
  return {
    'ag-cell-hover-outline': (): boolean => {
      return isDateCurrentlySelectedFunc() && canEdit;
    },
    'thickness-warning': (params: CellClassParams): boolean => {
      if (params.data.thickness !== undefined && params.data.minimumThickness !== undefined) {
        return (
          params.data.thickness !== 0 &&
          params.data.thickness <= Number(Number(params.data.minimumThickness).toFixed(3))
        );
      }

      return false;
    },
    'thickness-growth': (params: CellClassParams): boolean => {
      if (params.data.readings.length > 0) {
        let currentReading: ReadingDto = {} as ReadingDto;

        if (params.data.readingId !== undefined) {
          currentReading = params.data.readings.find((x: ReadingDto) => x.id === params.data.readingId);

          if (currentReading === undefined) {
            return false;
          }
        } else if (params.data.thickness !== undefined && isDateCurrentlySelectedFunc()) {
          currentReading = {
            date: selectedDateFunc!(),
            thickness: params.data.thickness
          } as ReadingDto;
        } else {
          return false;
        }

        const clonedReadings: Array<ReadingDto> = [...params.data.readings];

        const previousDateIndex =
          (
            clonedReadings.sort((a: ReadingDto, b: ReadingDto) =>
              DateOnlyUtility.sortDesc(a.date, b.date)
            ) as Array<ReadingDto>
          ).findIndex((x: ReadingDto) => {
            return DateOnlyUtility.compare(x.date, currentReading.date) == 0;
          }) + 1;

        const previousReading = clonedReadings[previousDateIndex];

        if (previousReading === undefined) {
          return false;
        }

        return currentReading.thickness > previousReading.thickness;
      } else {
        return false;
      }
    }
  };
}
