import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '../shared-module/shared.module';
import { NotificationDetailsComponent } from './notification-details/notification-details.component';
import { NotificationsComponent } from './notifications/notifications.component';

@NgModule({
  declarations: [NotificationsComponent, NotificationDetailsComponent],
  imports: [CommonModule, SharedModule],
  exports: [NotificationsComponent, NotificationDetailsComponent]
})
export class NotificationsModule {}
