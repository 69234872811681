import { Injectable } from '@angular/core';
import { NotificationService } from 'src/app/shared-module/services/notification.service';

import { JobNotificationService } from '../../notifications-module/services/job-notifications.service';
import { ReportingDataService } from '../reporting.data.service';

@Injectable({
  providedIn: 'root'
})
export class OverdueReportService {
  public constructor(
    private overdueReportDataService: ReportingDataService,
    private notificationService: NotificationService,
    private jobNotificationsService: JobNotificationService
  ) {}

  public run(): void {
    this.notificationService.showInfo('Generating overdue report');

    this.overdueReportDataService
      .generateOverdueReport()
      .subscribe(() => this.jobNotificationsService.refreshNotifications());
  }
}
