import { E2gSelectOption } from '@equityeng/e2g-ng-ui';

import { ComponentRecordDto } from '../models/component-record-dto';
import { AssetTypes } from '../models/enums/asset-types';

const pipeCompTypes = ['PIPE', 'ELBOW', 'FITTING']; //[ComponentTypes.PIPE, ComponentTypes.ELBOW, ComponentTypes.FITTING];

export function getCodeValues(equipType?: AssetTypes, compType?: string): Array<E2gSelectOption> {
  const pipeDesignCodeOptions = [
    {
      value: 'B31_1',
      label: 'ASME B31.1 Power Piping'
    },
    {
      value: 'B31_3',
      label: 'ASME B31.3 Process Piping'
    },
    {
      value: 'B31_4',
      label: 'ASME B31.4 Liquid Pipelines'
    },
    {
      value: 'B31_8',
      label: 'ASME B31.8 Gas Pipelines'
    }
  ];
  if (compType && pipeCompTypes.includes(compType)) {
    return pipeDesignCodeOptions;
  }

  switch (equipType) {
    case AssetTypes.Boiler:
      return [
        {
          value: 'SectionI',
          label: 'ASME Section I'
        }
      ];
    case AssetTypes.PipingSystem:
      return pipeDesignCodeOptions;
    case AssetTypes.StorageTank:
      return [
        {
          value: 'API_620',
          label: 'API 620 Low Pressure Tanks'
        },
        {
          value: 'API_650',
          label: 'API 650 Atmospheric Storage Tanks'
        }
      ];
    case AssetTypes.Other:
      return [];
    default:
      return [
        {
          value: 'Section8D1',
          label: 'ASME Section VIII, Division 1'
        },
        {
          value: 'Section8D2',
          label: 'ASME Section VIII, Division 2'
        }
      ];
  }
}

export const DESIGN_CODE_SAGE_VALUES: Record<string, string> = {
  SectionI: 'S1',
  Section8D1: 'S8-DIV1',
  Section8D2: 'S8-DIV2',
  B31_1: 'B31.1',
  B31_3: 'B31.3',
  B31_4: 'B31.4',
  B31_8: 'B31.8',
  API_650: 'API-650',
  API_653: 'API-653',
  API_620: 'API-620',
  API_530: 'API-530'
};

export function getDefaultDesignCode(component: ComponentRecordDto): string {
  if (component.assetType !== AssetTypes.PipingSystem && pipeCompTypes.includes(component.compType!)) {
    return 'B31_3';
  }
  return component.eqDesignCode!;
}

export function getDesignCodeLabel(assetType: AssetTypes, designCode: string): string {
  return getCodeValues(assetType).find((x) => x.value === designCode)?.label ?? '';
}
