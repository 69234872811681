<ng-container *ngIf="!loadingGridOptions; else loading">
  <ng-container *ngIf="!allReadingsData; else allReadings">
    <div class="e2g-padding h-100 flex-col flex-111">
      <div class="d-flex justify-content-between">
        <div class="d-flex">
          <e2g-ng-select-input
            id="select-date"
            [options]="dateSelectOptions"
            [(ngModel)]="selectedDate"
            [disabled]="isDirty()"
            class="mr-2"
            (change)="dateSelected()"
          ></e2g-ng-select-input>

          <div
            class="ml-2"
            *ngIf="canEditThickness && hasSurveyTempCorrections && selectedDate !== 'Latest' && selectedDate !== 'All'"
          >
            <e2g-text-input
              id="inp-temperatureCorrection"
              [disabled]="!selectedDate"
              [(ngModel)]="surveyTempDataHandler.data.temperatureCorrection"
              heading="Survey Temperature {{ temperatureUom }}"
              labelPosition="left"
              type="number"
              [readonly]="!canEditActivity"
              (input)="updateThicknessFromTemp()"
            ></e2g-text-input>
          </div>
          <div *ngIf="selectedDate === 'Latest'" class="d-flex flex-column justify-content-center">
            <app-slide-input
              id="mst-highlight-driving-cml"
              class="ml-2"
              heading="Highlight Driving CML"
              [(ngModel)]="bHighlightDrivingCml"
              (change)="onChangeHighlightDrivingCml()"
            >
            </app-slide-input>
          </div>
        </div>
        <div *ngIf="!isDirty()">
          <button
            *ngIf="cmlPermissions.edit"
            id="btn-newCml"
            class="btn e2g-btn-outline-primary mr-2"
            [disabled]="saving"
            (click)="addCml()"
          >
            Add CML
          </button>
          <app-download-spreadsheet (onDownload)="cmlGridOptions.downloadExcelFile!()"></app-download-spreadsheet>
        </div>

        <div class="text-left" *ngIf="isDirty()">
          <button
            id="btn-save"
            class="btn e2g-btn-solid-primary mr-1"
            [disabled]="saving"
            (click)="saveChanges().subscribe()"
          >
            Save
          </button>
          <button id="btn-revert" class="btn e2g-btn-outline-primary" [disabled]="saving" (click)="revertChanges()">
            Revert Changes
          </button>
        </div>
      </div>
      <e2g-ag-grid
        #cmlGrid
        id="cmlGrid"
        class="h-100 mt-2"
        [options]="cmlGridOptions"
        [data]="gridDataHandler.data"
        (gridReady)="gridReady.next()"
      >
      </e2g-ag-grid>
    </div>
  </ng-container>
</ng-container>

<ng-template #allReadings>
  <app-all-cml-readings
    [data]="allReadingsData"
    [readonly]="!cmlPermissions.edit"
    (clearAllReadingsData)="clearAllReadingsData()"
  ></app-all-cml-readings>
</ng-template>

<ng-template #loading>
  <div class="loading"></div>
</ng-template>

<ng-template #deleteForm> </ng-template>
