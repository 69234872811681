import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs';

import { DataService } from '../../dataservice';
import { OnDestroyBaseComponent } from '../../on-destroy-base-component/on-destroy-base-component';
import { IDialogFormData } from '../../shared-module/dialog/dialog-form-data';
import { DialogData } from '../../shared-module/models/dialog-data';

export interface KeywordHelpDialogData {
  module: string;
  keyword: string;
  version: string;
}

@Component({
  selector: 'app-keyword-help-dlg',
  templateUrl: './keyword-help-dlg.component.html',
  styleUrls: ['./keyword-help-dlg.component.css'],
  providers: [
    {
      provide: IDialogFormData,
      useExisting: KeywordHelpDlgComponent
    }
  ]
})
export class KeywordHelpDlgComponent extends OnDestroyBaseComponent implements OnInit, IDialogFormData {
  private dialogData!: DialogData;
  private formData!: KeywordHelpDialogData;
  private noHelpAvailable = 'No Description Available.';

  public helpText: string = '';

  public constructor(private dataService: DataService) {
    super();
  }

  public ngOnInit(): void {
    this.dataService
      .getKeywordDescription(this.formData.keyword, this.formData.module, this.formData.version)
      .pipe(takeUntil(this.destroy))
      .subscribe((x) => (this.helpText = x ? x : this.noHelpAvailable));
  }

  public setDialogData(dialogData: DialogData): void {
    this.dialogData = dialogData;
  }

  public setFormData(formData: KeywordHelpDialogData): void {
    this.formData = formData;
  }
}
