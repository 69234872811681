import { dateOnly, DateOnlyUtility, E2gSelectOption } from '@equityeng/e2g-ng-ui';
import { map, Observable, ReplaySubject } from 'rxjs';
import { InspectionMethodTypes } from 'src/app/asset-module/models/enums/inspection-method-types';
import { InspectorStatus } from 'src/app/asset-module/models/enums/inspector-status';
import { ReadingDto } from 'src/app/asset-module/models/reading-dto';
import { SurveyDto } from 'src/app/asset-module/models/survey-dto';
import { ActivityDto } from 'src/app/models/activity-dto';
import { InspectionGradesDto } from 'src/app/models/activity-effectiveness-dto';
import { ActivityTemplates } from 'src/app/models/enums/activity-templates';
import { AssetTypes } from 'src/app/models/enums/asset-types';
import { InspectorDto } from 'src/app/models/inspector-dto';
import { ISaveChanges } from 'src/app/save-changes';
import { ListDataHandler } from 'src/app/shared-module/list-data-handler';
import { SingleDataHandler } from 'src/app/shared-module/single-data-handler';

import { ActivityDetailsDomainModelConfig } from './activity-details-dm-configs';

export class ActivityDetailsDomainModel implements ISaveChanges {
  //TODO make datahandlers private once they are not needed by child components
  public readingsGridDataHandler?: ListDataHandler<ReadingDto>;
  public readonly dataHandler: SingleDataHandler<ActivityDto>;

  private _dirty = new ReplaySubject<boolean>(1);
  public dirty: Observable<boolean> = this._dirty.asObservable();
  private _valid = new ReplaySubject<boolean>(1);
  public valid: Observable<boolean> = this._valid.asObservable();

  private _activeInspectorIds!: Array<string>;
  private readonly _inactiveInspectors: Array<InspectorDto>;
  public readonly inspectorOptions: Array<E2gSelectOption> = this.config.data.global.inspectors
    .filter((x) => x.status === InspectorStatus.Active)
    .map((x) => ({
      label: x.name!,
      value: x.id!
    }));

  public readonly editing: boolean = Boolean(this.config.data.instance.activity.id);
  public readonly template: ActivityTemplates = this.config.template;
  public readonly isSurvey: boolean = this.config.template === ActivityTemplates.Survey;
  public readonly isOnPrd: boolean = this.config.data.asset.asset.type === AssetTypes.PressureReliefDevice;
  public readonly findingsData = this.config.data.instance.findings;
  public readonly canEdit = this.config.data.asset.hasEditPermission;
  public readonly componentSelectOptions: Array<E2gSelectOption> = this.config.data.asset.components.map((x) => ({
    value: x.id,
    label: `${x.name} (${x.type}, size: ${(x.size || '').replace(/_/gi, ' ')}, nom: ${x.nominalThickness})`
  }));

  public constructor(private config: ActivityDetailsDomainModelConfig) {
    this.dataHandler = new SingleDataHandler<ActivityDto>(this.config.destroy, (dirty: boolean) => {
      this._dirty.next(dirty || (this.readingsGridDataHandler?.dirty ?? false));
    });

    this.dataHandler.setInitialData(this.config.data.instance.activity);

    if (this.config.data.instance.readings !== undefined) {
      this.createReadingsGridDataHandler(this.config.data.instance.readings);
    }

    this.setInspectorIds();
    this._inactiveInspectors = this.getInspectorsWithStatus(InspectorStatus.Inactive);

    this.updateValidation();
  }

  public saveChanges(): Observable<boolean> {
    const formData = this.getFormData();
    return this.editing
      ? this.config.dataService.saveExistingActivity(this.template, formData)
      : this.config.dataService.saveNewActivity(this.config.data.asset.asset.id, this.template, formData).pipe(
          map((newId) => {
            this.dataHandler.data.id = newId;
            return true;
          })
        );
  }

  public isDirty(): boolean {
    return this.dataHandler.dirty || (this.readingsGridDataHandler?.dirty ?? false);
  }

  public revertChanges(): void {
    this.dataHandler.revertChanges();
    this.setInspectorIds();
  }

  private getFormData(): ActivityDto | SurveyDto {
    const data = this.dataHandler.getRawData();
    if (this.readingsGridDataHandler !== undefined) {
      (data as SurveyDto).readings = this.readingsGridDataHandler!.getChangedData();
    }
    return data;
  }

  private createReadingsGridDataHandler(readings: Array<ReadingDto>): void {
    this.readingsGridDataHandler = new ListDataHandler<ReadingDto>(
      'id',
      'updateType',
      this.config.destroy,
      undefined,
      (dirty: boolean) => {
        this._dirty.next(dirty || (this.dataHandler.dirty ?? false));
      }
    );
    this.readingsGridDataHandler.setInitialData(readings);
  }

  public updateData(data: ActivityDto | SurveyDto): void {
    this.dataHandler.updateData(data);
  }

  public get id(): string {
    return this.dataHandler.data.id ?? '';
  }

  public get name(): string {
    return this.dataHandler.data.name ?? '';
  }
  public set name(value: string) {
    this.dataHandler.data.name = value;
  }

  public get inspectors(): Array<string> {
    return this._activeInspectorIds;
  }
  public set inspectors(value: Array<string>) {
    this.dataHandler.data.inspectors = this.config.data.global.inspectors
      .filter((x) => value.includes(x.id!))
      .concat(this._inactiveInspectors);
  }
  private setInspectorIds(): void {
    this._activeInspectorIds = this.getInspectorsWithStatus(InspectorStatus.Active).map((x) => x.id!);
  }

  public get date(): dateOnly | undefined {
    return this.dataHandler.data.date;
  }
  public set date(value: dateOnly | undefined) {
    this.dataHandler.data.date = value;
    this.updateValidation();
  }

  public get campaign(): string {
    return this.dataHandler.data.campaign ?? '';
  }
  public set campaign(value: string) {
    this.dataHandler.data.campaign = value;
  }

  public get description(): string {
    return this.dataHandler.data.description ?? '';
  }
  public set description(value: string) {
    this.dataHandler.data.description = value;
  }

  public get componentId(): string {
    return this.dataHandler.data.componentId ?? '';
  }
  public set componentId(value: string) {
    this.dataHandler.data.componentId = value;
  }

  public get comments(): string {
    return this.dataHandler.data.comments ?? '';
  }
  public set comments(value: string) {
    this.dataHandler.data.comments = value;
  }

  public get inspectionGrades(): Array<InspectionGradesDto> | undefined {
    return this.dataHandler.data.inspectionGrades;
  }
  // public set inspectionGrades(value: Array<InspectionGradesDto> | undefined) {
  //   this.dataHandler.data.inspectionGrades = value;
  // }

  //TODO Split out survey data
  private get surveyDataDto(): SurveyDto {
    return this.dataHandler.data as SurveyDto;
  }

  public get primaryInspectionMethod(): InspectionMethodTypes | undefined {
    return this.surveyDataDto.primaryInspectionMethod;
  }
  public set primaryInspectionMethod(value: InspectionMethodTypes | undefined) {
    this.surveyDataDto.primaryInspectionMethod = value;
    this.updateValidation();
  }

  public get isBaseline(): boolean {
    return this.surveyDataDto.isBaseline ?? false;
  }
  public set isBaseline(value: boolean) {
    this.surveyDataDto.isBaseline = value;
  }

  public get instrumentId(): string {
    return this.surveyDataDto.instrumentId ?? '';
  }
  public set instrumentId(value: string) {
    this.surveyDataDto.instrumentId = value;
  }

  public get probeId(): string {
    return this.surveyDataDto.probeId ?? '';
  }
  public set probeId(value: string) {
    this.surveyDataDto.probeId = value;
  }

  public get dateErrors(): Array<string> {
    const errors: Array<string> = [];

    if (!DateOnlyUtility.isValid(this.date)) {
      errors.push('Valid date required');
    } else {
      if (this.isSurvey) {
        if (this.config.data.asset.surveys?.find((x) => x.date === this.date && x.id !== this.dataHandler.data.id)) {
          errors.push('Survey Already Exists for the Selected Date');
        }
      }
    }

    return errors;
  }

  private updateValidation(): void {
    this._valid.next(this.isValid());
  }

  private isValid(): boolean {
    return (
      this.dateErrors.length == 0 &&
      this.date !== undefined &&
      !(this.isSurvey && this.primaryInspectionMethod == undefined)
    );
  }

  private getInspectorsWithStatus(status: InspectorStatus): Array<InspectorDto> {
    const selectedInspectorIds = this.dataHandler.data.inspectors.map((x) => x.id!);
    return this.config.data.global.inspectors.filter(
      (x) => x.status === status && selectedInspectorIds.includes(x.id!)
    );
  }
}
