<app-slide-out-container>
  <ng-container [ngSwitch]="baseComponentType">
    <app-pipe *ngSwitchCase="'pipe'" [refreshComponentData]="refreshComponentData" [assetCache]="assetCache"></app-pipe>
    <app-fcomp
      *ngSwitchCase="'fcomp'"
      [refreshComponentData]="refreshComponentData"
      [assetCache]="assetCache"
    ></app-fcomp>
    <app-tank-comp
      *ngSwitchCase="'tank'"
      [refreshComponentData]="refreshComponentData"
      [assetCache]="assetCache"
    ></app-tank-comp>
    <app-ncpv-comp
      *ngSwitchCase="'ncpv'"
      [refreshComponentData]="refreshComponentData"
      [assetCache]="assetCache"
    ></app-ncpv-comp>
  </ng-container>
</app-slide-out-container>
