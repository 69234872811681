<div *ngIf="!loading; else load" class="d-flex flex-col">
  <div class="row align-items-end mt-2 mb-2 m-0">
    <div class="col-6 pl-0">
      <e2g-ng-select-input
        id="select-damage-monitoring-location-status"
        heading="Status"
        placeholder="Select Status"
        [disabled]="disableStatus()"
        [required]="false"
        [options]="statusList!"
        [(ngModel)]="dataHandler.data.status"
        [readonly]="readOnly"
      ></e2g-ng-select-input>
    </div>

    <div class="col-6">
      <app-evaluation-date-field [evaluationDate]="dataHandler.data.evaluationDate"></app-evaluation-date-field>
    </div>
  </div>

  <div class="form-section-header my-1">DML Information</div>

  <div class="row align-items-end mb-2 m-0">
    <div class="col-12 pl-0">
      <e2g-ng-select-input
        id="select-damage-monitoring-location-component"
        heading="Component"
        placeholder="Select Component"
        [disabled]="false"
        [required]="true"
        [options]="componentsOptions!"
        [(ngModel)]="dataHandler.data.componentId"
        (change)="componentChange($event)"
        [readonly]="readOnly"
      ></e2g-ng-select-input>
    </div>
  </div>

  <div *ngIf="dataHandler.data.componentId" class="mb-2 mt-2">
    <div class="row align-items-end mb-2 m-0">
      <div class="col-6 pl-0">
        <e2g-ng-select-input
          id="select-damage-monitoring-location-assessment-level"
          heading="API 579 Assessment"
          placeholder="Select API 579 Assessment"
          [disabled]="false"
          [required]="false"
          [isDefaultValue]="dataHandler.isDefaultValue('assessmentLevel')"
          [showReset]="showResetAssessmentLevel()"
          (reset)="reset('assessmentLevel')"
          [options]="assessmentLevelList!"
          [(ngModel)]="dataHandler.data.assessmentLevel"
          [readonly]="readOnly"
        ></e2g-ng-select-input>
      </div>

      <div class="col-6">
        <e2g-ng-select-input
          id="select-damage-monitoring-location-damage-location"
          heading="Damage Location"
          placeholder="Select Damage Location"
          [disabled]="false"
          [required]="false"
          [isDefaultValue]="dataHandler.isDefaultValue('damageLocation')"
          [showReset]="showResetDamageLocation()"
          (reset)="reset('damageLocation')"
          [options]="damageLocationOptions!"
          [(ngModel)]="dataHandler.data.damageLocation"
          [readonly]="readOnly"
        ></e2g-ng-select-input>
      </div>
    </div>

    <div class="row align-items-end mb-1 m-0">
      <div class="col-12 p-0 pr-3">
        <hr class="seperator" />
      </div>
    </div>

    <div class="row align-items-end mb-2 m-0">
      <div class="col-6 pl-0">
        <e2g-text-input
          heading="Undamaged Region FCA {{ undamagedRegionFcaUom | async }}"
          id="inp-damage-monitoring-location-undamaged-region-fca"
          type="number"
          [required]="false"
          [isDefaultValue]="dataHandler.isDefaultValue('undamagedRegionFca')"
          [showReset]="showResetUndamagedRegionFca()"
          (reset)="reset('undamagedRegionFca')"
          [(ngModel)]="dataHandler.data.undamagedRegionFca"
          [readonly]="readOnly"
        ></e2g-text-input>
      </div>

      <div class="col-6">
        <e2g-text-input
          heading="Damaged Region FCA {{ damagedRegionFcaUom | async }}"
          id="inp-damage-monitoring-location-damaged-region-fca"
          type="number"
          [required]="false"
          [isDefaultValue]="dataHandler.isDefaultValue('damagedRegionFca')"
          [showReset]="showResetDamagedRegionFca()"
          (reset)="reset('damagedRegionFca')"
          [(ngModel)]="dataHandler.data.damagedRegionFca"
          [readonly]="readOnly"
        ></e2g-text-input>
      </div>
    </div>

    <div class="row align-items-end mb-2 m-0">
      <div class="col-6 pl-0">
        <e2g-text-input
          heading="Undamaged Region Corrosion Rate {{ undamagedRegionCorrosionRateUom | async }}"
          id="inp-damage-monitoring-location-undamaged-region-corrosion-rate"
          type="number"
          [required]="false"
          [isDefaultValue]="dataHandler.isDefaultValue('undamagedRegionCorrosionRate')"
          [showReset]="showResetUndamagedRegionCorrosionRate()"
          (reset)="reset('undamagedRegionCorrosionRate')"
          [(ngModel)]="dataHandler.data.undamagedRegionCorrosionRate"
          [readonly]="readOnly"
        ></e2g-text-input>
      </div>

      <div class="col-6">
        <e2g-text-input
          heading="Damaged Region Corrosion Rate {{ damagedRegionCorrosionRateUom | async }}"
          id="inp-damage-monitoring-location-damaged-region-corrosion-rate"
          type="number"
          [required]="false"
          [isDefaultValue]="dataHandler.isDefaultValue('damagedRegionCorrosionRate')"
          [showReset]="showResetDamagedRegionCorrosionRate()"
          (reset)="reset('damagedRegionCorrosionRate')"
          [(ngModel)]="dataHandler.data.damagedRegionCorrosionRate"
          [readonly]="readOnly"
        ></e2g-text-input>
      </div>
    </div>

    <div class="row align-items-end mb-1 m-0">
      <div class="col-12 p-0 pr-3">
        <hr class="seperator" />
      </div>
    </div>

    <div class="row align-items-end mb-2 m-0">
      <div class="col-12 pl-0">
        <e2g-ng-select-input
          id="select-damage-monitoring-location-grid-orientation"
          heading="Grid Orientation"
          placeholder="Select Grid Orientation"
          [disabled]="false"
          [required]="false"
          [isDefaultValue]="dataHandler.isDefaultValue('gridOrientation')"
          [showReset]="showResetGridOrientation()"
          (reset)="reset('gridOrientation')"
          [options]="gridOrientationOptions!"
          [(ngModel)]="dataHandler.data.gridOrientation"
          [readonly]="readOnly"
        ></e2g-ng-select-input>
      </div>
    </div>

    <div class="row align-items-end mb-2 m-0">
      <div class="col-6 pl-0">
        <e2g-text-input
          heading="Grid Spacing Between Vert. Planes {{ gridSpacingBetweenCPlanesUom | async }}"
          id="inp-damage-monitoring-location-grid-spacing-between-vertical-planes"
          type="number"
          [required]="false"
          [isDefaultValue]="dataHandler.isDefaultValue('gridSpacingBetweenCPlanes')"
          [showReset]="showResetGridSpacingBetweenCPlanes()"
          (reset)="reset('gridSpacingBetweenCPlanes')"
          [requiredForCalc]="true"
          [(ngModel)]="dataHandler.data.gridSpacingBetweenCPlanes"
          [readonly]="readOnly"
        ></e2g-text-input>
      </div>

      <div class="col-6">
        <e2g-text-input
          heading="Grid Spacing Between Horiz. Planes {{ gridSpacingBetweenMPlanesUom | async }}"
          id="inp-damage-monitoring-location-grid-spacing-between-horizontal-planes"
          type="number"
          [required]="false"
          [isDefaultValue]="dataHandler.isDefaultValue('gridSpacingBetweenMPlanes')"
          [showReset]="showResetGridSpacingBetweenMPlanes()"
          (reset)="reset('gridSpacingBetweenMPlanes')"
          [requiredForCalc]="true"
          [(ngModel)]="dataHandler.data.gridSpacingBetweenMPlanes"
          [readonly]="readOnly"
        ></e2g-text-input>
      </div>
    </div>

    <div class="d-flex flex-row m-0">
      <div class="d-flex flex-column mr-2 w-100">
        <e2g-textarea-input
          id="inp-comments"
          class="w-100 pr-2"
          heading="Comments"
          [rows]="5"
          [readonly]="readOnly"
          [maxlength]="512"
          [(ngModel)]="dataHandler.data.comments"
        ></e2g-textarea-input>
      </div>
    </div>
  </div>
</div>

<ng-template #load>
  <div class="loading"></div>
</ng-template>
