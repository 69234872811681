import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { CompanyService } from '../company.service';
import { UpdateType } from '../models/enums/update-type';

export class ListDataHandler<T> {
  public data: Array<T> | undefined;
  public dirty: boolean = false;
  public dirty$: Observable<boolean> = new BehaviorSubject<boolean>(false).pipe(distinctUntilChanged());

  private initData: Array<T> | undefined;
  private hasDeletes: boolean = false;
  private dataChange = new Subject<void>();

  public constructor(
    private keyField: string,
    private updateField: string,
    private destroy: Observable<any>,
    private companyService?: CompanyService,
    public dirtyCallback?: (dirty: boolean) => void,
    private isEqual?: (a: T, b: T) => boolean
  ) {
    this.dataChange.pipe(takeUntil(this.destroy)).subscribe(() => {
      this.triggerDirty(this.isDirty());
    });

    if (this.dirtyCallback !== undefined || this.companyService !== undefined) {
      this.dirty$.pipe(takeUntil(this.destroy)).subscribe((dirty) => {
        if (this.dirtyCallback) {
          this.dirtyCallback(dirty);
        }
        if (this.companyService) {
          this.companyService.setDisabled(dirty);
        }
      });
    }
  }

  public dataChanged(): void {
    this.dataChange.next();
  }

  public clearData(): void {
    this.data = undefined;
  }

  public setInitialData(data: Array<T>): void {
    this.data = data;
    this.setupInitialData(data);
    this.hasDeletes = false;
    this.triggerDirty(false);
  }

  public add(data: T, toEnd: boolean = true): void {
    if (this.data) {
      if (toEnd) {
        this.data = [...this.data, data];
      } else {
        this.data = [data, ...this.data];
      }
    }

    this.dataChanged();
  }

  public delete(index: number): void {
    if (this.data) {
      const deleted = this.data.splice(index, 1);
      this.data = [...this.data];

      // olny mark deleted if it had an ID
      if ((deleted[0] as any)[this.keyField]) {
        this.hasDeletes = true;
      }

      this.dataChanged();
    }
  }

  public getChangedData(): Array<any> {
    let changes = new Array<T>();

    if (this.data && this.initData) {
      const newItems = this.data
        .filter((x) => !(x as any)[this.keyField])
        .map((y) => ({ ...y, [this.updateField]: UpdateType.Add }));
      changes = newItems;

      const remainingData = this.data.filter((x) => !!(x as any)[this.keyField]);
      const initLookup = this.createUpdateLookup(this.initData);

      const updates = remainingData
        .filter((a) => {
          const b = initLookup[(a as any)[this.keyField] as number];
          return this.isEqual ? !this.isEqual(a, b) : !this.isEqualInternal(a, b);
        })
        .map((y) => ({ ...y, updateType: UpdateType.Update }));

      changes = [...changes, ...updates];

      const dataLookup = this.createUpdateLookup(this.data);
      const deletes = this.initData
        .filter((a) => {
          return !(((a as any)[this.keyField] as number) in dataLookup);
        })
        .map(
          (y) =>
            ({ ...y, id: (y as any)[this.keyField], search: '-', replace: '-', updateType: UpdateType.Delete } as T)
        );

      changes = [...changes, ...deletes];
    }

    return changes;
  }

  public promoteChanges(): void {
    if (this.data) {
      this.setupInitialData(this.data);
      this.hasDeletes = false;
      this.dataChanged();
    }
  }

  public revertChanges(): void {
    if (this.initData) {
      this.data = this.initData;
      this.setupInitialData(this.initData);
      this.hasDeletes = false;
      this.dataChanged();
    }
  }

  public getCount(): number {
    return this.data ? this.data.length : 0;
  }

  public isNew(index: number): boolean {
    if (this.data) {
      return !!(this.data[index] as any)[this.keyField];
    }
    return true;
  }

  private triggerDirty(dirty: boolean): void {
    this.dirty = dirty;
    (this.dirty$ as BehaviorSubject<boolean>).next(dirty);
  }

  private isDirty(): boolean {
    if (this.hasDeletes) {
      return true;
    }

    if (this.initData !== undefined && this.data !== undefined) {
      for (let i = 0; i < this.initData.length; i++) {
        const a = this.initData[i];
        const b = this.data[i];

        if (this.isEqual ? !this.isEqual(a, b) : !this.isEqualInternal(a, b)) {
          return true;
        }
      }

      if (this.data.filter((x) => !(x as any)[this.keyField]).length > 0) {
        return true;
      }
    }

    return false;
  }

  private setupInitialData(data: Array<T>): void {
    this.initData = data.reduce((p, c) => {
      p.push(JSON.parse(JSON.stringify(c)) as typeof c);
      return p;
    }, new Array<T>());
  }

  private createUpdateLookup(data: Array<T>): { [key: number]: T } {
    return data.reduce((p, c) => {
      p[(c as any)[this.keyField] as number] = { ...c };
      return p;
    }, {} as { [key: number]: T });
  }

  private isEqualInternal(a: T, b: T): boolean {
    return JSON.stringify(a) === JSON.stringify(b);
  }
}
