import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { dateOnly } from '@equityeng/e2g-ng-ui';
import { AppSettingsService } from './app-settings/app-settings.service';
import { SurveyDto } from './asset-module/models/survey-dto';

@Injectable({
  providedIn: 'root'
})
export class SurveyService {
  public newSurveyDate?: dateOnly;
  private serverUri: string;

  public constructor(private http: HttpClient, private settingsService: AppSettingsService) {
    this.serverUri = this.settingsService.settings.apiUri as string;
  }

  public deleteSurvey(surveyId: string): Observable<boolean> {
    return this.http.delete<boolean>(`${this.serverUri}/surveys/${surveyId}`).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  public getSurvey(assetId: string, surveyId: string): Observable<SurveyDto> {
    return this.http
      .get<SurveyDto>(`${this.serverUri}/surveys/assets/${assetId}/surveys/${surveyId}`)
      .pipe(catchError(() => of({} as SurveyDto)));
  }

  public getSurveys(assetId: string): Observable<Array<SurveyDto>> {
    return this.http
      .get<Array<SurveyDto>>(`${this.serverUri}/surveys/assets/${assetId}/surveys`)
      .pipe(catchError(() => of([])));
  }

  public addSurvey(assetId: string, data: SurveyDto): Observable<string> {
    this.newSurveyDate = data.date;
    return this.http.post<string>(`${this.serverUri}/surveys/assets/${assetId}/surveys`, data);
  }

  public updateSurvey(data: SurveyDto): Observable<boolean> {
    return this.http.put(`${this.serverUri}/surveys`, data).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }
}
