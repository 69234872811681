import { formatDate } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import fileSaver from 'file-saver';

import { ResponseHelper } from './response-helper';

export class FileDownloadHelper {
  public static downloadFile(resp: HttpResponse<any>, name: string): void {
    const extension = ResponseHelper.getFileExtension(resp);
    fileSaver.saveAs(URL.createObjectURL(resp.body as Blob), `${name}.${extension}`);
  }

  public static getTimestampForFile(date: Date): string {
    return formatDate(date, 'dd-MMM-yyyy HH-mm', 'en-US');
  }
}
