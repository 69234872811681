import { E2gSelectOption } from '@equityeng/e2g-ng-ui';


export enum DamageMode {
  All = 0,
  Thinning = 1,
  External = 2,
  Cracking = 3
}

export const DamageModeOptions: Array<E2gSelectOption> = [
  { label: `Damage Mode: ${DamageMode[DamageMode.All]}`, value: DamageMode.All },
  { label: `Damage Mode: ${DamageMode[DamageMode.Thinning]}`, value: DamageMode.Thinning },
  { label: `Damage Mode: ${DamageMode[DamageMode.External]}`, value: DamageMode.External },
  { label: `Damage Mode: ${DamageMode[DamageMode.Cracking]}`, value: DamageMode.Cracking }
];
